import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  PathwayStatus,
  type IPathway,
  type ITag,
} from '@principle-theorem/level-up-core';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CreatePathwayActionService } from '../../../components/search-dialog/create-pathway-action.service';
import { TagFilter } from '../../../components/tags/tag-filter';
import { PRODUCT_TOUR_CONFIG } from '../../../models/product-tour-config';
import { CachedListsService } from '../../../services/cached-lists.service';
import { groupBy } from 'lodash';

interface IGroupedPathway {
  status: PathwayStatus;
  pathways: WithRef<IPathway>[];
}

@Component({
  selector: 'lu-pathways',
  templateUrl: './pathways.component.html',
  styleUrls: ['./pathways.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwaysComponent {
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  trackByStatus = TrackByFunctions.variable<PathwayStatus>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IPathway>>;
  filteredPathways$: Observable<IGroupedPathway[]>;
  selectedTagFilters$: BehaviorSubject<WithRef<ITag>[]> = new BehaviorSubject<
    WithRef<ITag>[]
  >([]);
  tourId: number = PRODUCT_TOUR_CONFIG.pathways;

  constructor(
    cachedLists: CachedListsService,
    private _createPathway: CreatePathwayActionService
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IPathway>>(
      cachedLists.pathways$,
      this.search.valueChanges.pipe(startWith('')),
      ['name', 'description']
    );

    this.filteredPathways$ = new TagFilter(
      this.searchFilter.results$,
      this.selectedTagFilters$
    ).results$.pipe(
      map((pathways) => {
        const grouped = groupBy(pathways, (pathway) => pathway.status);
        const statuses = [
          PathwayStatus.Published,
          PathwayStatus.Draft,
          PathwayStatus.Archived,
        ];
        return statuses.map((status) => {
          return {
            status,
            pathways: grouped[status] || [],
          };
        });
      })
    );
  }

  async addPathway(): Promise<void> {
    await this._createPathway.do();
  }

  filterResults(selectedTagFilters: WithRef<ITag>[]): void {
    this.selectedTagFilters$.next(selectedTagFilters);
  }
}
