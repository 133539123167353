import {
  type AngularRenderer,
  moduleMetadata,
  applicationConfig,
} from '@storybook/angular';
import { type DecoratorFunction } from '@storybook/csf';
import { StorybookCommonModule } from '../modules/storybook-common';
import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function decorateStorybookCommon(): DecoratorFunction<AngularRenderer>[] {
  return [
    moduleMetadata({
      imports: [StorybookCommonModule],
    }),
    applicationConfig({
      providers: [importProvidersFrom(BrowserAnimationsModule)],
    }),
  ];
}
