import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pt-workflow-update-button',
    templateUrl: './workflow-update-button.component.html',
    styleUrls: ['./workflow-update-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkflowUpdateButtonComponent {
  checked$ = new ReplaySubject<boolean>(1);
  disablePrefix$ = new BehaviorSubject<boolean>(false);
  @Input() color: ThemePalette;
  @Input() type: 'stroked' | 'flat' | 'raised' = 'stroked';
  @Input() currentState: string;
  @Input() nextState?: string;
  @Output() buttonClick = new EventEmitter<void>();

  @Input()
  set checked(checked: BooleanInput) {
    this.checked$.next(coerceBooleanProperty(checked));
  }

  @Input()
  set disablePrefix(disablePrefix: BooleanInput) {
    this.disablePrefix$.next(coerceBooleanProperty(disablePrefix));
  }

  buildTooltip$(label?: string): Observable<string | undefined> {
    return this.disablePrefix$.pipe(
      map((disablePrefix) => (disablePrefix ? label : `Next Status: ${label}`))
    );
  }
}
