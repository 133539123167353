import { get, isNumber } from 'lodash';
import {
  ValueComparison,
  compareValues,
  type ObjectOfType,
} from '@principle-theorem/shared';

export interface IAchievementRequirement {
  statPath: string;
  comparison: ValueComparison;
  value: number;
}

export class AchievementRequirement {
  static init(
    overrides: Partial<IAchievementRequirement>
  ): IAchievementRequirement {
    return {
      statPath: '',
      comparison: ValueComparison.GreaterThanOrEqualTo,
      value: 0,
      ...overrides,
    };
  }

  static getRequirementProgress(
    req: IAchievementRequirement,
    stats: Partial<ObjectOfType<number>>
  ): number {
    const value = get(stats, req.statPath);
    if (!isNumber(value)) {
      return 0;
    }
    return value;
  }

  static isRequirementSatisfied(
    req: IAchievementRequirement,
    stats: Partial<ObjectOfType<number>>
  ): boolean {
    const current = this.getRequirementProgress(req, stats);
    return compareValues(current, req.comparison, req.value);
  }
}
