import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WARNING_DAYS_UNTIL_DUE } from '@principle-theorem/level-up-core';
import {
  COMPACT_DATE_FORMAT,
  ISODateType,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lu-goal-due-date-indicator',
    templateUrl: './goal-due-date-indicator.component.html',
    styleUrls: ['./goal-due-date-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GoalDueDateIndicatorComponent {
  readonly warningDaysUntilDue = WARNING_DAYS_UNTIL_DUE;
  readonly dateFormat = COMPACT_DATE_FORMAT;
  dueDate$ = new ReplaySubject<ISODateType | undefined>(1);
  isOverdue$: Observable<boolean>;
  daysUntilDue$: Observable<number | undefined>;

  @Input()
  set dueDate(dueDate: ISODateType | undefined) {
    this.dueDate$.next(dueDate);
  }

  constructor() {
    this.daysUntilDue$ = this.dueDate$.pipe(
      map((dueDate) => {
        if (!dueDate) {
          return;
        }
        const now = moment();
        const due = toMoment(dueDate);
        return due.diff(now, 'days');
      })
    );

    this.isOverdue$ = this.daysUntilDue$.pipe(
      map((daysUntilDue) => (daysUntilDue ? daysUntilDue < 0 : false))
    );
  }
}
