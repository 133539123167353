import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { type IVendor } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export type VendorFormData = Pick<IVendor, 'name'>;

@Component({
    selector: 'lu-add-vendor-dialog',
    templateUrl: './add-vendor-dialog.component.html',
    styleUrls: ['./add-vendor-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddVendorDialogComponent {
  form: TypedFormGroup<VendorFormData> = new TypedFormGroup<VendorFormData>({
    name: new TypedFormControl<string>('', Validators.required),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      AddVendorDialogComponent,
      VendorFormData | undefined
    >
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
