import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CardImageService } from './card-image.service';
import { switchMap } from 'rxjs/operators';

export enum ImageCardPlaceholder {
  Pathway = '/assets/images/pathway-placeholder.jpg',
  Bundle = '/assets/images/bundle-placeholder.jpg',
}

@Component({
    selector: 'lu-card-image',
    templateUrl: './card-image.component.html',
    styleUrls: ['./card-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardImageComponent {
  @Input() placeholderImage: ImageCardPlaceholder = ImageCardPlaceholder.Bundle;
  image$: ReplaySubject<string | undefined> = new ReplaySubject(1);
  imageSrc$: Observable<string | undefined>;

  @Input()
  set image(image: string | undefined) {
    if (image) {
      this.image$.next(image);
    }
  }

  constructor(private _cardImageService: CardImageService) {
    this.imageSrc$ = this.image$.pipe(
      switchMap((image) =>
        this._cardImageService.getCardImage$({ imageUrl: image })
      )
    );
  }
}
