import {
  asyncForEach,
  collectionGroupQuery,
  DocumentReference,
  getDocs,
  ISODateType,
  isSameRef,
  toMoment,
  toTimestamp,
  where,
  WithRef,
} from '@principle-theorem/shared';
import { compact, last } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { OrganisationCollection } from '../models/organisation/organisation-collections';
import {
  ISkill,
  ISkillLevelRequest,
  SkillLevelRequestStatus,
  SkillStatus,
} from '../models/skill/skill';
import { SkillCollection } from '../models/skill/skill-collections';
import {
  ISkillProgress,
  skillGoalAchieved,
} from '../models/skill/skill-progress';
import {
  ISkillLevelRequirement,
  IUserGroup,
  WARNING_DAYS_UNTIL_DUE,
} from '../models/user-group/user-group';
import { IUser, User } from '../models/user/user';

interface IStat<T> {
  label: string;
  items: T[];
}

export interface IWeeklyUpdateStats {
  goalsMet: IStat<WithRef<ISkillProgress>>;
  goalsOverdueThisWeek: IStat<IUserGoalUnmet>;
  goalsDueThisWeek: IStat<IUserGoalUnmet>;
  trainingRequestedLastWeek: IStat<WithRef<ISkillLevelRequest>>;
  skillsPublishedLastWeek: IStat<WithRef<ISkill>>;
  creatorSkillsOverdueThisWeek: IStat<WithRef<ISkill>>;
  creatorSkillsDueThisWeek: IStat<WithRef<ISkill>>;
  trainingApprovedLastWeek: IStat<IUserGoalMet>;
  trainingRequestsApprovedLastWeek: IStat<WithRef<ISkillLevelRequest>>;
  trainingRequestsOutstanding: IStat<WithRef<ISkillLevelRequest>>;
  trainingUserGoalsOverdue: IStat<IUserGoalUnmet>;
  trainingUserGoalsDueThisWeek: IStat<IUserGoalUnmet>;
}

interface IAllUserProgress {
  goalsUnmet: IUserGoalUnmet[];
  goalsMet: IUserGoalMet[];
}

export interface IUserGoalUnmet {
  skillRef: DocumentReference<ISkill>;
  userRef: DocumentReference<IUser>;
  trainers: DocumentReference<IUser>[];
  dueDate: ISODateType;
}

export interface IUserGoalMet {
  skillRef: DocumentReference<ISkill>;
  userRef: DocumentReference<IUser>;
  accreditor: DocumentReference<IUser>;
  dueDate: ISODateType;
}

export class WeeklyUpdateStats {
  static async getAllUserProgress(
    users: WithRef<IUser>[]
  ): Promise<IAllUserProgress> {
    const startOfLastWeek = moment().startOf('week').subtract(1, 'week');
    return getAllUserProgress(users, startOfLastWeek);
  }

  static async getStats(
    user: WithRef<IUser>,
    userGroups: WithRef<IUserGroup>[],
    allUserProgress: IAllUserProgress
  ): Promise<IWeeklyUpdateStats> {
    const startOfLastWeek = moment().startOf('week').subtract(1, 'week');

    const { creatorSkillsOverdueThisWeek, creatorSkillsDueThisWeek } =
      await getCreatorSkillDueDates(user, startOfLastWeek);

    const updateStats: IWeeklyUpdateStats = {
      skillsPublishedLastWeek: {
        label: 'Skills Published Last Week',
        items: await getSkillsPublishedLastWeek(user.ref, startOfLastWeek),
      },
      goalsMet: {
        label: 'Goals Acheived Last Week',
        items: await getGoalsAcheivedLastWeek(
          user,
          startOfLastWeek,
          userGroups
        ),
      },
      trainingRequestsApprovedLastWeek: {
        label: 'Training Requests Approved Last Week',
        items: await getTrainingRequestsApprovedLastWeek(user, startOfLastWeek),
      },
      trainingRequestsOutstanding: {
        label: 'Training Requests Outstanding',
        items: await getTrainingRequestsOutstanding(user),
      },
      goalsOverdueThisWeek: {
        label: 'Goals Overdue',
        items: allUserProgress.goalsUnmet
          .filter((skill) => isSameRef(skill.userRef, user.ref))
          .filter((skill) => toMoment(skill.dueDate).isBefore(moment())),
      },
      goalsDueThisWeek: {
        label: 'Goals Coming Up This Week',
        items: allUserProgress.goalsUnmet
          .filter((skill) => isSameRef(skill.userRef, user.ref))
          .filter((skill) => toMoment(skill.dueDate).isAfter(moment())),
      },
      trainingRequestedLastWeek: {
        label: 'Training Requested Last Week',
        items: await getTrainingRequestedLastWeek(user, startOfLastWeek),
      },
      trainingApprovedLastWeek: {
        label: 'Training Approved Last Week',
        items: allUserProgress.goalsMet.filter((progress) =>
          isSameRef(progress.accreditor, user)
        ),
      },
      creatorSkillsOverdueThisWeek: {
        label: 'Creator Skills Overdue',
        items: creatorSkillsOverdueThisWeek,
      },
      creatorSkillsDueThisWeek: {
        label: 'Creator Skills Coming Up This Week',
        items: creatorSkillsDueThisWeek,
      },
      trainingUserGoalsOverdue: {
        label: `Trainer's User Goals Overdue`,
        items: allUserProgress.goalsUnmet.filter(
          (progress) =>
            progress.trainers.some((trainer) => isSameRef(trainer, user)) &&
            toMoment(progress.dueDate).isBefore(moment())
        ),
      },
      trainingUserGoalsDueThisWeek: {
        label: `Trainer's User Goals Coming Up This Week`,
        items: allUserProgress.goalsUnmet.filter(
          (progress) =>
            progress.trainers.some((trainer) => isSameRef(trainer, user)) &&
            toMoment(progress.dueDate).isAfter(moment())
        ),
      },
    };

    return updateStats;
  }
}

async function getAllUserProgress(
  users: WithRef<IUser>[],
  startOfLastWeek: Moment
): Promise<IAllUserProgress> {
  return (
    await asyncForEach(users, async (user) => {
      const progressSkills = await getDocs(
        User.skillProgressCol(user),
        where('updatedAt', '>=', toTimestamp(startOfLastWeek))
      );

      const requirementsWithDueDate = user.levelRequirements.filter(
        (levelRequirement) =>
          levelRequirement.dueDate &&
          toMoment(levelRequirement.dueDate).isBefore(
            moment().add(WARNING_DAYS_UNTIL_DUE, 'days')
          )
      );

      const goalsMet = getUserGoalsMet(
        requirementsWithDueDate,
        progressSkills,
        user
      );

      const goalsUnmet = await getUserGoalsUnmet(
        requirementsWithDueDate,
        progressSkills,
        user,
        users
      );

      return {
        goalsMet,
        goalsUnmet,
      };
    })
  ).reduce(
    (goals, newGoals) => ({
      goalsMet: [...goals.goalsMet, ...newGoals.goalsMet],
      goalsUnmet: [...goals.goalsUnmet, ...newGoals.goalsUnmet],
    }),
    {
      goalsMet: [],
      goalsUnmet: [],
    }
  );
}

async function getUserGoalsUnmet(
  requirementsWithDueDate: ISkillLevelRequirement[],
  progressSkills: WithRef<ISkillProgress>[],
  user: WithRef<IUser>,
  users: WithRef<IUser>[]
): Promise<IUserGoalUnmet[]> {
  return compact(
    await asyncForEach(requirementsWithDueDate, async (levelRequirement) => {
      const dueDate = levelRequirement.dueDate;
      const skillGoalAcheived = progressSkills.find((progressSkill) =>
        isSameRef(progressSkill.skillRef, levelRequirement.skill)
      );

      if (skillGoalAcheived || !dueDate) {
        return;
      }

      return {
        skillRef: levelRequirement.skill.ref,
        userRef: user.ref,
        trainers: compact(
          await asyncForEach(users, async (trainerUser) =>
            (await User.isTrainerForSkill(trainerUser, levelRequirement.skill))
              ? trainerUser.ref
              : undefined
          )
        ),
        dueDate,
      };
    })
  );
}

function getUserGoalsMet(
  requirementsWithDueDate: ISkillLevelRequirement[],
  progressSkills: WithRef<ISkillProgress>[],
  user: WithRef<IUser>
): IUserGoalMet[] {
  return compact(
    requirementsWithDueDate.map((levelRequirement) => {
      const dueDate = levelRequirement.dueDate;
      const skillGoalAcheived = progressSkills.find((progressSkill) =>
        isSameRef(progressSkill.skillRef, levelRequirement.skill)
      );

      if (!skillGoalAcheived || !dueDate) {
        return;
      }

      const accreditor = last(skillGoalAcheived.history)?.accreditor;

      if (!accreditor) {
        return;
      }

      return {
        skillRef: levelRequirement.skill.ref,
        userRef: user.ref,
        accreditor,
        dueDate,
      };
    })
  );
}

async function getCreatorSkillDueDates(
  user: WithRef<IUser>,
  startOfLastWeek: Moment
): Promise<{
  creatorSkillsOverdueThisWeek: WithRef<ISkill>[];
  creatorSkillsDueThisWeek: WithRef<ISkill>[];
}> {
  const authorSkillsDueSinceLastWeek = await getDocs(
    collectionGroupQuery<ISkill>(OrganisationCollection.Skills),
    where('author', '==', user.ref),
    where('dueDate', '>=', toTimestamp(startOfLastWeek)),
    where('status', 'in', [SkillStatus.Draft, SkillStatus.Review])
  );

  const creatorSkillsOverdueThisWeek = authorSkillsDueSinceLastWeek.filter(
    (skill) => skill.dueDate && toMoment(skill.dueDate).isBefore(moment())
  );

  const creatorSkillsDueThisWeek = authorSkillsDueSinceLastWeek.filter(
    (skill) => skill.dueDate && toMoment(skill.dueDate).isAfter(moment())
  );

  return { creatorSkillsOverdueThisWeek, creatorSkillsDueThisWeek };
}

async function getTrainingRequestedLastWeek(
  user: WithRef<IUser>,
  startOfLastWeek: Moment
): Promise<WithRef<ISkillLevelRequest>[]> {
  return getDocs(
    collectionGroupQuery<ISkillLevelRequest>(SkillCollection.TrainingRequests),
    where('userRef', '==', user.ref),
    where('createdAt', '>=', toTimestamp(startOfLastWeek)),
    where('status', 'in', [
      SkillLevelRequestStatus.Approved,
      SkillLevelRequestStatus.Pending,
    ])
  );
}

async function getTrainingRequestsApprovedLastWeek(
  user: WithRef<IUser>,
  startOfLastWeek: Moment
): Promise<WithRef<ISkillLevelRequest>[]> {
  return getDocs(
    collectionGroupQuery<ISkillLevelRequest>(SkillCollection.TrainingRequests),
    where('mentorRef', '==', user.ref),
    where('createdAt', '>=', toTimestamp(startOfLastWeek)),
    where('status', '==', SkillLevelRequestStatus.Approved)
  );
}

async function getTrainingRequestsOutstanding(
  user: WithRef<IUser>
): Promise<WithRef<ISkillLevelRequest>[]> {
  return getDocs(
    collectionGroupQuery<ISkillLevelRequest>(SkillCollection.TrainingRequests),
    where('mentorRef', '==', user.ref),
    where('status', '==', SkillLevelRequestStatus.Pending)
  );
}

async function getGoalsAcheivedLastWeek(
  user: WithRef<IUser>,
  startOfLastWeek: Moment,
  userGroups: WithRef<IUserGroup>[]
): Promise<WithRef<ISkillProgress>[]> {
  const userSkillProgress = await getDocs(
    User.skillProgressCol(user),
    where('updatedAt', '>=', toTimestamp(startOfLastWeek))
  );

  return userSkillProgress.filter((progress) =>
    skillGoalAchieved(User.getGoals(user, userGroups), progress)
  );
}

async function getSkillsPublishedLastWeek(
  userRef: DocumentReference<IUser>,
  startOfLastWeek: Moment
): Promise<WithRef<ISkill>[]> {
  return getDocs(
    collectionGroupQuery<ISkill>(OrganisationCollection.Skills),
    where('author', '==', userRef),
    where('updatedAt', '>=', toTimestamp(startOfLastWeek)),
    where('status', '==', SkillStatus.Published)
  );
}
