import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type IPathway } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';

export interface IPathwayViewDialogData {
  pathway: WithRef<IPathway>;
}

@Component({
    selector: 'lu-pathway-view-dialog',
    templateUrl: './pathway-view-dialog.component.html',
    styleUrls: ['./pathway-view-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PathwayViewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPathwayViewDialogData,
    public dialogRef: MatDialogRef<PathwayViewDialogComponent>
  ) {}
}
