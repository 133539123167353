import { type WithRef } from '@principle-theorem/shared';
import {
  type IUser,
  MentionResourceType,
} from '@principle-theorem/level-up-core';
import { LoadUserActionService } from '../../services/contextual-actions/routing-actions/load-user-action.service';
import { type ISearchAction } from './search-action';

export class UserLoader implements ISearchAction {
  icon = 'person';
  name: string;
  details: string;
  type = MentionResourceType.User;

  constructor(
    public user: WithRef<IUser>,
    public loader: LoadUserActionService
  ) {
    this.name = user.name;
    this.details = user.email;
  }

  async do(): Promise<void> {
    await this.loader.do(this.user.ref);
  }
}
