<mat-toolbar color="primary">Edit Bundle</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex flex-col p-4">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>
    <div class="flex-col pb-6">
      <mat-checkbox class="!flex" formControlName="addToNewWorkspaces"
        >Subscribe New Workspaces</mat-checkbox
      >
      <mat-checkbox class="!flex" formControlName="assignToDefaultGroup"
        >Assign to default group</mat-checkbox
      >
      <mat-checkbox class="!flex" formControlName="readOnly"
        >Read Only</mat-checkbox
      >
    </div>
    <mat-form-field class="pb-6">
      <mat-label>Visibility</mat-label>
      <mat-select formControlName="visibility">
        <mat-option
          [value]="visibilityOption"
          *ngFor="
            let visibilityOption of visibilityOptions;
            trackBy: trackByVisibility
          "
          >{{ visibilityOption | titlecase }}</mat-option
        >
      </mat-select>
      <mat-hint>Public Bundles allow users to subscribe to it.</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </div>
  <div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button [disabled]="!canSave()">Save</button>
  </div>
</form>
