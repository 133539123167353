import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Organisation,
  type IOrganisation,
  type IVendorBundle,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  multiSortBy$,
  nameSorter,
  type WithRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface IAddSubscriberDialogData {
  associatedWorkspaces: DocumentReference<IOrganisation>[];
  bundle: WithRef<IVendorBundle>;
}

@Component({
  selector: 'lu-add-subscriber-dialog',
  templateUrl: './add-subscriber-dialog.component.html',
  styleUrls: ['./add-subscriber-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSubscriberDialogComponent {
  trackByWorkspace = TrackByFunctions.ref<WithRef<IOrganisation>>();
  associatedWorkspaces$: BehaviorSubject<DocumentReference<IOrganisation>[]> =
    new BehaviorSubject<DocumentReference<IOrganisation>[]>([]);
  workspaces$: Observable<WithRef<IOrganisation>[]>;

  inputFilter: TypedFormControl<string | WithRef<IOrganisation>> =
    new TypedFormControl();
  filteredWorkspaces$: Observable<WithRef<IOrganisation>[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IAddSubscriberDialogData,
    private _dialogRef: MatDialogRef<
      AddSubscriberDialogComponent,
      WithRef<IOrganisation> | undefined
    >
  ) {
    this.associatedWorkspaces$.next(data.associatedWorkspaces);
    this.workspaces$ = Organisation.all$().pipe(
      map((workspaces) =>
        workspaces.filter((workspace) => !this.isAssociated(workspace))
      ),
      multiSortBy$(nameSorter())
    );
    this.filteredWorkspaces$ = combineLatest([
      this.inputFilter.valueChanges.pipe(startWith('')),
      this.workspaces$,
    ]).pipe(map(([value, workspaces]) => this._filter(value, workspaces)));
  }

  displayFn(workspace?: IOrganisation): string {
    return workspace ? `${workspace.name}` : '';
  }

  isAssociated(skill: WithRef<IOrganisation>): boolean {
    return this.associatedWorkspaces$.value.find(
      (currentSkillRef) => currentSkillRef.path === skill.ref.path
    )
      ? true
      : false;
  }

  add(workspace?: WithRef<IOrganisation>): void {
    if (!workspace) {
      return;
    }
    this._dialogRef.close(workspace);
  }

  private _filter(
    value: string | WithRef<IOrganisation>,
    workspaces: WithRef<IOrganisation>[]
  ): WithRef<IOrganisation>[] {
    if (typeof value !== 'string') {
      return [];
    }

    return workspaces.filter((workspace: WithRef<IOrganisation>) => {
      return workspace.name.toLowerCase().includes(value.toLowerCase());
    });
  }
}
