<div class="flex h-full flex-col overflow-hidden">
  <mat-toolbar [color]="data.toolbarColor">{{ data.title }}</mat-toolbar>
  <div class="flex grow flex-col overflow-scroll">
    <div class="flex flex-col p-4">
      @for (group of data.groups; track group.name) {
        @if (group.options.length > 0) {
          <div>
            <h3 class="!m-0 !font-bold">{{ group.name }}</h3>
            @for (option of group.options; track $index) {
              <ng-container
                [ngTemplateOutlet]="optionButton"
                [ngTemplateOutletContext]="{ option: option }"
              />
            }
          </div>
        }
      } @empty {
        @for (option of data.options; track $index) {
          <ng-container
            [ngTemplateOutlet]="optionButton"
            [ngTemplateOutletContext]="{ option: option }"
          />
        }
      }
    </div>
  </div>

  <div class="p-4">
    <button mat-stroked-button type="button" class="w-full" mat-dialog-close>
      Cancel
    </button>
  </div>
</div>

<ng-template #optionButton let-option="option">
  <div
    class="flex select-none flex-col gap-1 p-4"
    matRipple
    [ngClass]="{
      'cursor-pointer hover:bg-slate-50': !option.isDisabled,
      'opacity-50': option.isDisabled
    }"
    [matRippleDisabled]="option.isDisabled"
    [matTooltip]="option.disabledReason"
    [matTooltipDisabled]="!option.isDisabled || !option.disabledReason"
    (click)="submit(option)"
  >
    <div class="text-sm">{{ option.label }}</div>
    @if (option.info) {
      <div class="text-xs opacity-50">{{ option.info }}</div>
    }
  </div>
</ng-template>
