import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  initVersionedSchema,
  type VersionedSchema,
} from '@principle-theorem/editor';
import { type IPageTemplate } from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  deleteDoc,
  filterUndefined,
  findProp,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganisationService } from '../../../../services/organisation.service';

type TemplateFormData = Pick<IPageTemplate, 'name' | 'content'>;

@Component({
    selector: 'lu-page-template-edit',
    templateUrl: './page-template-edit.component.html',
    styleUrls: ['./page-template-edit.component.scss'],
    standalone: false
})
export class PageTemplateEditComponent {
  pageTemplate$: Observable<WithRef<IPageTemplate>>;
  form: TypedFormGroup<TemplateFormData> = new TypedFormGroup<TemplateFormData>(
    {
      name: new TypedFormControl<string>(''),
      content: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
    }
  );

  constructor(
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: BasicDialogService,
    public org: OrganisationService
  ) {
    this.pageTemplate$ = this._route.data.pipe(
      findProp<WithRef<IPageTemplate>>('pageTemplate'),
      filterUndefined(),
      tap((pageTemplate) => this.form.patchValue(pageTemplate))
    );
  }

  async delete(pageTemplate: WithRef<IPageTemplate>): Promise<void> {
    const confirm = await this._dialog.confirm({
      title: 'Remove Template',
      prompt: 'Are you sure you want to delete this template?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }
    await deleteDoc(pageTemplate.ref);
    this._snackBar.open('Template Removed');
    await this._router.navigate(['../', 'templates']);
  }

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  async save(pageTemplate: WithRef<IPageTemplate>): Promise<void> {
    await patchDoc(pageTemplate.ref, this.form.getRawValue());
    this.form.markAsPristine();
    this._snackBar.open('Template Updated');
  }

  async handleContentError(): Promise<void> {
    const template = await snapshot(this.pageTemplate$);
    const user = await snapshot(this.org.user$.pipe(filterUndefined()));
    const organisation = await snapshot(
      this.org.organisation$.pipe(filterUndefined())
    );
    throw new Error(
      JSON.stringify({
        error: `Couldn't render content for template`,
        template: template.name,
        path: template.ref.path,
        workspace: organisation.slug,
        user: user.name,
      })
    );
  }
}
