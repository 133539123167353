import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-skill-placeholder',
    templateUrl: './skill-placeholder.component.html',
    styleUrls: ['./skill-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillPlaceholderComponent {}
