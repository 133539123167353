<ng-container *ngIf="!multiSelect; else addUser">
  <ng-container *ngIf="selectedUser$ | async as selectedUser; else addUser">
    <div [ngClass]="{ disabled: disabled }" class="user-container">
      <pt-user-icon
        [src]="userImage$ | async"
        [name]="userName$ | async"
        [diameter]="32"
       />

      <ng-container *ngIf="!disableClear; else clearDisabled">
        <div
          *ngIf="disabled === false"
          class="menu-button"
          [matTooltip]="clearTooltip"
          [matTooltipDisabled]="disableTooltip"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <button mat-icon-button (click)="clearAssignee()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </ng-container>

      <ng-template #clearDisabled>
        <div
          class="menu-button"
          [matTooltip]="changeTooltip"
          [matMenuTriggerFor]="menu"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <mat-icon>person</mat-icon>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-template #addUser>
  <div
    [ngClass]="{ disabled: disabled }"
    class="add-user-button"
    fxLayout="column"
    fxLayoutAlign="center center"
    [matMenuTriggerFor]="menu"
    [matTooltip]="selectTooltip"
  >
    <mat-icon>person_add</mat-icon>
  </div>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngIf="users$ | async as users">
      <ng-container *ngIf="users.length; else noUsers">
        <mat-selection-list
          #userList
          hideSingleSelectionIndicator
          [multiple]="false"
          (selectionChange)="selectUser(userList.selectedOptions.selected)"
        >
          <mat-list-option *ngIf="multiSelect" (click)="clearAssignee()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
              <mat-icon color="warn">close</mat-icon>
              <span>{{ clearSelectedTooltip }}</span>
            </div>
          </mat-list-option>
          <mat-list-option
            *ngFor="let user of users; trackBy: trackByUser"
            [value]="user"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
              <pt-user-icon
                [diameter]="30"
                [src]="user | profileImage$ | async"
                [name]="user.name"
               />
              <div>{{ user.name }}</div>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-template #noUsers>
        <div fxLayout="row" fxLayoutAlign="center center">
          <span class="no-users">{{ emptyUsersMessage }}</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</mat-menu>
