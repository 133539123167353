<ng-container *ngIf="favourites$ | async as favourites">
  <ng-container *ngIf="favourites.length">
    <ng-container
      *ngFor="let favourite of favourites; trackBy: trackByFavourite"
    >
      <ng-container *ngIf="favourite | map : isSkill">
        <lu-skill-list-card
          *ngIf="favourite | map : asSkill as skill"
          [skill]="skill"
          [user]="user$ | async"
          [compact]="true"
          [link]="['/explore/skill', favourite.ref.id]"
        />
      </ng-container>

      <ng-container *ngIf="favourite | map : isPathway">
        <lu-pathway-list-card
          *ngIf="favourite | map : asPathway as pathway"
          [pathway]="pathway"
          [user]="user$ | async"
          compact
          [link]="['/explore/pathway', favourite.ref.id]"
        />
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
