<ng-container *ngIf="skills$ | async as skills">
  <div fxLayout="column" fxLayoutGap="20px">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      *ngFor="let skill of skills; index as index; trackBy: trackBySkill"
    >
      <div fxFlex="20" fxLayoutAlign="center center">
        <div fxLayoutAlign="center center" class="pathway-skill-number">
          <span>{{ index + 1 }}</span>
        </div>
      </div>
      <div fxFlex fxLayout="row" class="pathway-skill-container">
        <div class="skill-banner"></div>
        <div fxFlex class="skill-info">
          {{ skill.name }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
