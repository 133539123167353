import { type WithRef } from '@principle-theorem/shared';
import { SkillLevelRequestStatus } from '../../skill/skill';
import {
  type ISkillProgress,
  currentSkillLevelValue,
} from '../../skill/skill-progress';
import { type IUser } from '../user';
import {
  type IUserStatValue,
  statValue,
  type IUserStatisticsResult,
} from './user-statistics-result';
import { SkillLevel } from '../../skill/skill-level';
import { resolverBuilders, type ISkillPair } from './helpers';

type BuilderFn = (
  owner: WithRef<IUser>,
  progress: ISkillProgress,
  skillPair: ISkillPair
) => IUserStatValue[];

export function skillAndSkillProgressResults(
  owner: WithRef<IUser>,
  progress: ISkillProgress,
  skillPair: ISkillPair
): IUserStatisticsResult[] {
  return resolverBuilders(
    {
      levelUpRequestsPending,
    },
    (builder: BuilderFn) => builder(owner, progress, skillPair)
  );
}

export function levelUpRequestsPending(
  owner: WithRef<IUser>,
  progress: ISkillProgress,
  skillPair: ISkillPair
): IUserStatValue[] {
  const isTrainer = currentSkillLevelValue(progress) === SkillLevel.Trainer;
  const pendingRequests = skillPair.levelUpRequests.filter(
    (request) => request.status === SkillLevelRequestStatus.Pending
  ).length;
  return [statValue(owner.ref, isTrainer ? pendingRequests : 0)];
}
