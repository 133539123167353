import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ISkill,
  findRequirementBySkill,
  type ISkillLevelRequirement,
  type SkillLevel,
} from '@principle-theorem/level-up-core';
import {
  ISODateType,
  filterUndefined,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SkillDueDateBloc } from '../../../../models/skill-due-date';
import { CachedListsService } from '../../../../services/cached-lists.service';

@Component({
  selector: 'lu-skill-associate',
  templateUrl: './skill-associate.component.html',
  styleUrls: ['./skill-associate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillAssociateComponent {
  skillRef$ = new ReplaySubject<DocumentReference<ISkill>>(1);
  skill$: Observable<WithRef<ISkill>>;
  currentLevel$: Observable<SkillLevel | undefined>;
  skillLevels$ = new BehaviorSubject<ISkillLevelRequirement[]>([]);
  skillLevel$: Observable<ISkillLevelRequirement | undefined>;
  showDueDate$ = new BehaviorSubject<boolean>(false);
  showRelativeDueDate$ = new BehaviorSubject<boolean>(false);
  dueDateBloc: SkillDueDateBloc;
  @Input() removeEnabled = true;
  @Output() removeSkill = new EventEmitter<DocumentReference<ISkill>>();
  @Output() levelChange = new EventEmitter<SkillLevel>();
  @Output() dueDateChange = new EventEmitter<ISODateType | undefined>();
  @Output() relativeDueDateChange = new EventEmitter<number | undefined>();

  @Input()
  set showDueDate(showDueDate: BooleanInput) {
    this.showDueDate$.next(coerceBooleanProperty(showDueDate));
  }

  @Input()
  set showRelativeDueDate(showRelativeDueDate: BooleanInput) {
    this.showRelativeDueDate$.next(coerceBooleanProperty(showRelativeDueDate));
  }

  @Input()
  set skillLevels(skillLevels: ISkillLevelRequirement[]) {
    this.skillLevels$.next(skillLevels || []);
  }

  @Input()
  set skillRef(skillRef: DocumentReference<ISkill>) {
    if (skillRef) {
      this.skillRef$.next(skillRef);
    }
  }

  constructor(cache: CachedListsService) {
    this.skill$ = this.skillRef$.pipe(
      switchMap((skillRef) => cache.getLocalSkill$(skillRef)),
      filterUndefined()
    );

    this.skillLevel$ = combineLatest([this.skill$, this.skillLevels$]).pipe(
      map(([skill, skillLevels]) => findRequirementBySkill(skill, skillLevels))
    );

    this.currentLevel$ = this.skillLevel$.pipe(
      map((skillLevel) => skillLevel?.level)
    );

    this.dueDateBloc = new SkillDueDateBloc(this.skillLevel$, of(false));
  }

  updateDueDate(dueDate?: ISODateType): void {
    this.dueDateChange.emit(dueDate);
  }

  updateRelativeDueDate(dueDate?: number): void {
    this.relativeDueDateChange.emit(dueDate);
  }
}
