import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type IPathway } from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  ProfileImageService,
} from '@principle-theorem/ng-shared';
import { deleteDoc, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
  selector: 'lu-pathway-list-item',
  templateUrl: './pathway-list-item.component.html',
  styleUrls: ['./pathway-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayListItemComponent {
  profileImage = inject(ProfileImageService);
  pathway$: ReplaySubject<WithRef<IPathway>> = new ReplaySubject(1);

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: BasicDialogService,
    public organisation: OrganisationService
  ) {}

  async remove(pathway: WithRef<IPathway>): Promise<void> {
    if (pathway.readOnly) {
      return;
    }

    const confirm = await this._dialog.confirm({
      title: 'Remove Pathway',
      prompt: 'Are you sure you want to delete this pathway?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }

    await deleteDoc(pathway.ref);
    this._snackBar.open('Pathway Removed');
  }
}
