import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { type ThemePalette } from '@angular/material/core';
import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';

@Component({
    selector: 'pt-loader-button-with-menu',
    templateUrl: './loader-button-with-menu.component.html',
    styleUrls: ['./loader-button-with-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoaderButtonWithMenuComponent {
  color$ = new ReplaySubject<ThemePalette | undefined>(1);
  checked$ = new ReplaySubject<boolean>(1);
  loading$ = new BehaviorSubject<boolean>(false);
  @Input() label = '';
  @Input() type?: 'stroked' | 'flat' | 'raised' = 'stroked';
  @Output() buttonClick = new EventEmitter<void>();

  @Input()
  set checked(checked: BooleanInput) {
    this.checked$.next(coerceBooleanProperty(checked));
  }

  @Input()
  set color(color: ThemePalette | undefined) {
    this.color$.next(color);
  }

  @Input()
  set loading(loading: boolean) {
    this.loading$.next(loading);
  }
}
