<form
  [formGroup]="form"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  (ngSubmit)="submit()"
>
  <ng-content></ng-content>
  <mat-form-field>
    <input
      matInput
      type="password"
      placeholder="Password"
      formControlName="password"
      [required]="true"
    />
    <mat-error *ngIf="form.controls.password.hasError('required')">
      Password is required
    </mat-error>
    <mat-error *ngIf="form.controls.password.hasError('minlength')">
      Password is too short
    </mat-error>
  </mat-form-field>
  <button type="submit" hidden></button>
</form>
