import { Injectable } from '@angular/core';
import {
  type ISkill,
  type ITag,
  type IUser,
  Skill,
} from '@principle-theorem/level-up-core';
import {
  InputSearchFilter,
  toSearchInput$,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  isSameRef,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagFilter } from '../../../components/tags/tag-filter';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorService {
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  selectedTagFilters$: BehaviorSubject<WithRef<ITag>[]> = new BehaviorSubject<
    WithRef<ITag>[]
  >([]);
  skills$: Observable<WithRef<ISkill>[]>;
  user$: Observable<WithRef<IUser>>;

  constructor(
    organisation: OrganisationService,
    cachedLists: CachedListsService
  ) {
    this.user$ = organisation.user$.pipe(filterUndefined());
    const allSkills$ = this._getUserSkills$(this.user$, cachedLists.skills$);
    const searchFilter = new InputSearchFilter<WithRef<ISkill>>(
      allSkills$,
      toSearchInput$(this.search),
      ['name']
    );
    const tagFilter = new TagFilter(
      searchFilter.results$,
      this.selectedTagFilters$
    );
    this.skills$ = tagFilter.results$;
  }

  private _getUserSkills$(
    user$: Observable<WithRef<IUser>>,
    skills$: Observable<WithRef<ISkill>[]>
  ): Observable<WithRef<ISkill>[]> {
    return combineLatest([user$, skills$]).pipe(
      map(([user, skills]: [WithRef<IUser>, WithRef<ISkill>[]]) =>
        skills.filter((skill: WithRef<ISkill>) => this._userCanSee(user, skill))
      )
    );
  }

  private _userCanSee(user: WithRef<IUser>, skill: WithRef<ISkill>): boolean {
    return (
      isSameRef(skill.author, user) ||
      user.isAdmin ||
      Skill.canReview(skill, user)
    );
  }
}
