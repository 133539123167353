import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  EditorMenuItemComponent,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';

@Component({
    selector: 'pt-menu-spacer',
    templateUrl: './menu-spacer.component.html',
    styleUrls: ['./menu-spacer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MenuSpacerComponent extends EditorMenuItemComponent {}

export function createMenuSpacer(): MenuButtonLoaderFn {
  return () => ({
    component: MenuSpacerComponent,
    data: {},
  });
}
