<ng-container *ngIf="breakpoint.isMobile$ | async; else desktop">
  <mat-toolbar class="mobile-nav">
    <a href="{{ websiteURL }}"
      ><img src="/assets/icons/lu-logo-horizontal-purple.png"
    /></a>
    <span class="spacer"></span>
    <ng-container *ngIf="user$ | async as user">
      <lu-user-profile-menu [user]="user" />
    </ng-container>
    <button mat-icon-button class="mobile-button" (click)="toggleMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>

  <div *ngIf="showMobileMenu" class="mobile-dropdown">
    <mat-list>
      <ng-container *ngIf="user$ | async as user; else loggedOut">
        <mat-list-item>
          <a routerLinkActive="active-link" routerLink="/home">Home</a>
        </mat-list-item>
      </ng-container>
      <ng-template #loggedOut>
        <mat-list-item>
          <a routerLinkActive="active-link" routerLink="/login">Sign In</a>
        </mat-list-item>
        <mat-list-item>
          <a routerLinkActive="active-link" routerLink="/register">Register</a>
        </mat-list-item>
        <mat-list-item>
          <a href="{{ websiteURL }}/pricing" class="navbar-cta">Start Today</a>
        </mat-list-item>
      </ng-template>
    </mat-list>
  </div>
</ng-container>

<ng-template #desktop>
  <div [ngClass]="navbarColour">
    <mat-toolbar class="desktop-nav">
      <a href="{{ websiteURL }}">
        <ng-container *ngIf="lightNavbar; else whiteLogo">
          <img src="/assets/icons/lu-logo-horizontal-purple.png" />
        </ng-container>
        <ng-template #whiteLogo>
          <img src="/assets/icons/lu-logo-horizontal-white.png" />
        </ng-template>
      </a>
      <span class="spacer"></span>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="26px"
        class="link-container flex flex-col items-center gap-6"
      >
        <ng-container *ngIf="user$ | async as user; else loggedOut">
          <a routerLinkActive="active-link" routerLink="/home">Home</a>
          <lu-user-profile-menu [user]="user" />
        </ng-container>
        <ng-template #loggedOut>
          <a routerLinkActive="active-link" routerLink="/login">Sign In</a>
          <a routerLinkActive="active-link" routerLink="/register">Register</a>
          <a href="{{ websiteURL }}/pricing" class="navbar-cta">Start Today</a>
        </ng-template>
      </div>
    </mat-toolbar>
  </div>
</ng-template>
