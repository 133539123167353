<ng-container *ngIf="notifications$ | async as notifications">
  <mat-toolbar color="primary">
    <span>Notifications</span>
    <div fxFlex></div>
    <div>
      <button
        mat-icon-button
        *ngIf="notifications.length"
        (click)="markAllRead(notifications)"
      >
        <mat-icon>done_all</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div fxLayout="column" class="layout-padding">
    <ng-container *ngIf="notifications.length; else noNotifications">
      <div
        class="notification"
        *ngFor="let notification of notifications; trackBy: trackByNotification"
      >
        <ng-container *ngIf="!notification.viewed">
          <pt-notification
            [currentUser]="organisation.user$ | async"
            [notification]="notification"
            [preview]="true"
            [extensions]="notificationExtensions"
           />
        </ng-container>
      </div>
    </ng-container>

    <ng-template #noNotifications>
      <div fxLayout="row" fxLayoutAlign="center">
        <small class="no-notifications">No Notifications</small>
      </div>
    </ng-template>

    <a
      class="notifications-link"
      [routerLink]="['/', 'notifications']"
      (click)="closed.next()"
    >
      See All Notifications
    </a>
  </div>
</ng-container>
