import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PathwaySectionStep,
  isSkill,
  type ISkill,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  isSameRef,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PathwayAddDialogComponent } from '../../../../components/skills/pathway-add-dialog/pathway-add-dialog.component';
import { CachedListsService } from '../../../../services/cached-lists.service';

export interface ISkillAssociatedData {
  associatedContent: DocumentReference<PathwaySectionStep>[];
}

@Component({
    selector: 'lu-skill-associate-dialog',
    templateUrl: './skill-associate-dialog.component.html',
    styleUrls: ['./skill-associate-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillAssociateDialogComponent {
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  associatedContent$ = new BehaviorSubject<
    DocumentReference<PathwaySectionStep>[]
  >([]);
  skills$: Observable<WithRef<ISkill>[]>;

  inputFilter: TypedFormControl<string | WithRef<ISkill>> =
    new TypedFormControl();
  filteredSkills$: Observable<WithRef<ISkill>[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISkillAssociatedData,
    cachedLists: CachedListsService,
    private _dialogRef: MatDialogRef<
      PathwayAddDialogComponent,
      WithRef<ISkill> | undefined
    >
  ) {
    this.associatedContent$.next(data.associatedContent);
    this.skills$ = cachedLists.skills$.pipe(
      map((skills) => {
        return skills.filter((skill) => !this.isAssociated(skill));
      })
    );
    this.filteredSkills$ = combineLatest([
      this.inputFilter.valueChanges.pipe(startWith('')),
      this.skills$,
    ]).pipe(map(([value, skills]) => this._filter(value, skills)));
  }

  displayFn(skill?: ISkill): string {
    return skill ? `${skill.name}` : '';
  }

  isAssociated(skill: WithRef<ISkill>): boolean {
    return this.associatedContent$.value.find((currentContentRef) =>
      isSameRef(currentContentRef, skill)
    )
      ? true
      : false;
  }

  add(skill?: WithRef<ISkill>): void {
    if (!skill) {
      return;
    }
    this._dialogRef.close(skill);
  }

  private _filter(
    value: string | WithRef<ISkill>,
    skills: WithRef<ISkill>[]
  ): WithRef<ISkill>[] {
    if (isSkill(value)) {
      return [];
    }

    return skills.filter((condition: WithRef<ISkill>) => {
      return condition.name.toLowerCase().includes(value.toLowerCase());
    });
  }
}
