import {
  IGoals,
  IPathwayAssociation,
  ISkill,
  ISkillLevelRequirement,
  Pathway,
  UserGroup,
  type IPathway,
  type IUser,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import {
  asyncForEach,
  doc$,
  getDoc,
  isSameRef,
  multiMap,
  multiSwitchMap,
  patchDoc,
  reduceToSingleArray,
  saveDoc,
  shareReplayCold,
  shareReplayHot,
  snapshot,
  toISODate,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { Subject, type Observable } from 'rxjs';
import {
  map,
  repeat,
  scan,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { type GroupFormData } from '../group-add-dialog/group-add-dialog.component';

export class GroupEditBloc {
  private _changes$ = new Subject<Partial<IUserGroup>>();
  group$: Observable<WithRef<IUserGroup>>;
  hasUnsavedChanges$: Observable<boolean>;
  save$ = new Subject<void>();

  constructor(
    private _initialGroup$: Observable<WithRef<IUserGroup>>,
    destroy$: Observable<void>
  ) {
    this.group$ = this._initialGroup$.pipe(
      take(1),
      switchMap((initialGroup) =>
        this._changes$.pipe(
          scan(
            (accumulated, changes) => ({ ...accumulated, ...changes }),
            initialGroup
          ),
          startWith(initialGroup)
        )
      ),
      shareReplayHot(destroy$)
    );

    this.hasUnsavedChanges$ = this._changes$.pipe(
      map(() => true),
      startWith(false),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this.save$),
      repeat(),
      shareReplayHot(destroy$)
    );
  }

  async addUser(userRef: DocumentReference<IUser>): Promise<void> {
    const group = await snapshot(this.group$);
    const users = [...group.users, userRef];
    this._changes$.next({ users });
    await this._updateUserDueDates(userRef, group);
  }

  async removeUser(userRef: DocumentReference<IUser>): Promise<void> {
    const group = await snapshot(this.group$);
    const users = group.users.filter((user) => !isSameRef(user, userRef));
    this._changes$.next({ users });
  }

  async addGoals(goals: IGoals): Promise<void> {
    const group = await snapshot(this.group$);
    const { skillAssociations, levelRequirements, pathwayAssociations } =
      UserGroup.mergeGoals(group, goals);
    this._changes$.next({
      pathwayAssociations,
      skillAssociations,
      levelRequirements,
    });
  }

  async removeSkill(skillRef: DocumentReference<ISkill>): Promise<void> {
    const group = await snapshot(this.group$);
    this._changes$.next({
      levelRequirements: await this._updateLevelRequirements(skillRef, group),
      skillAssociations: UserGroup.removeSkill(group, skillRef),
    });
  }

  async removePathway(pathwayRef: DocumentReference<IPathway>): Promise<void> {
    const group = await snapshot(this.group$);
    const pathwayAssociations = group.pathwayAssociations.filter(
      (pathwayAssociation) => !isSameRef(pathwayAssociation, pathwayRef)
    );
    this._changes$.next({ pathwayAssociations });
  }

  async save(): Promise<void> {
    const group = await snapshot(this.group$);
    await patchDoc<IUserGroup>(group.ref, { ...group });
    await asyncForEach(group.users, async (userRef) =>
      this._updateUserDueDates(userRef, group)
    );
    this.save$.next();
  }

  updateForm(fromData: Partial<GroupFormData>): void {
    this._changes$.next({ ...fromData });
  }

  updateSkillLevels(levelRequirements: ISkillLevelRequirement[]): void {
    this._changes$.next({ levelRequirements });
  }

  updatePathwayLevels(pathwayAssociations: IPathwayAssociation[]): void {
    this._changes$.next({ pathwayAssociations });
  }

  private async _updateLevelRequirements(
    skillRef: DocumentReference<ISkill>,
    userGroup: IUserGroup
  ): Promise<ISkillLevelRequirement[]> {
    const pathwaySkills = await snapshot(this._getPathwaySkills$());
    if (pathwaySkills.includes(skillRef.path)) {
      return userGroup.levelRequirements;
    }
    return userGroup.levelRequirements.filter(
      (levelRequirement) => !isSameRef(levelRequirement.skill, skillRef)
    );
  }

  private _getPathwaySkills$(): Observable<string[]> {
    return this.group$.pipe(
      map((userGroup) => userGroup.pathwayAssociations),
      multiSwitchMap((pathwayRef) => doc$(pathwayRef.ref)),
      multiMap((pathway) => Pathway.skillRefs(pathway)),
      map(reduceToSingleArray),
      multiMap((skillRef) => skillRef.path),
      shareReplayCold()
    );
  }

  private async _updateUserDueDates(
    userRef: DocumentReference<IUser>,
    userGroup: IUserGroup
  ): Promise<void> {
    const user = await getDoc(userRef);
    const { levelRequirements, pathwayAssociations } = userGroup;
    const levelRequirementsWithNewDueDates = compact(
      levelRequirements.map((levelRequirement) => {
        if (!levelRequirement.relativeDueDate) {
          return;
        }

        const userLevelRequirement = user.levelRequirements.find(
          (currentLevelRequirement) =>
            isSameRef(currentLevelRequirement.skill, levelRequirement.skill)
        );

        if (userLevelRequirement?.dueDate) {
          return;
        }

        const dueDate = moment().add(levelRequirement.relativeDueDate, 'days');
        return {
          ...levelRequirement,
          ...userLevelRequirement,
          dueDate: toISODate(dueDate),
        };
      })
    );

    const pathwayAssociationsWithNewDueDates = compact(
      pathwayAssociations.map((pathwayAssociation) => {
        if (!pathwayAssociation.relativeDueDate) {
          return;
        }

        const userPathwayAssociation = user.pathwayAssociations.find(
          (currentPathwayAssociation) =>
            isSameRef(currentPathwayAssociation, pathwayAssociation)
        );

        if (userPathwayAssociation?.dueDate) {
          return;
        }

        const dueDate = moment().add(
          pathwayAssociation.relativeDueDate,
          'days'
        );
        return {
          ...pathwayAssociation,
          ...userPathwayAssociation,
          dueDate: toISODate(dueDate),
        };
      })
    );

    const mergedGoals = UserGroup.mergeGoals(user, {
      levelRequirements: levelRequirementsWithNewDueDates,
      pathwayAssociations: pathwayAssociationsWithNewDueDates,
    });
    await saveDoc(mergedGoals);
  }
}
