import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-template-placeholder',
    templateUrl: './template-placeholder.component.html',
    styleUrls: ['./template-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TemplatePlaceholderComponent {}
