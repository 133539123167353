import { Reffable, SystemActors, WithRef } from '../interfaces';
import {
  CollectionReference,
  DocumentReference,
  Timestamp,
  Transaction,
} from './adaptor';
import {
  IDocUpdateOptions,
  addDoc,
  deleteDoc,
  undeleteDoc,
  updateDoc,
  upsertDoc,
} from './document';
import { Firestore } from './firestore';
import { upsertBulk } from './firestore-resolver';

export class FirestoreMigrate {
  static addDoc<T extends object>(
    collection: CollectionReference<T>,
    item: T & Partial<ICreatedAt>,
    uid?: string,
    transaction?: Transaction
  ): Promise<DocumentReference<T>> {
    return addDoc(collection, item, uid, transaction, SystemActors.Migration);
  }

  static upsertDoc<T extends object>(
    collection: CollectionReference<T>,
    item: T & Partial<ICreatedAt>,
    uid?: string | DocumentReference<T>,
    transaction?: Transaction
  ): Promise<DocumentReference<T>> {
    return upsertDoc(
      collection,
      item,
      uid,
      transaction,
      undefined,
      SystemActors.Migration
    );
  }

  static patchDoc<T extends object>(
    ref: DocumentReference<T>,
    data: Partial<T>,
    options?: IDocUpdateOptions,
    transaction?: Transaction
  ): Promise<DocumentReference<Partial<T>>> {
    return Firestore.patchDoc(
      ref,
      data,
      transaction,
      options,
      SystemActors.Migration
    );
  }

  static updateDoc<T extends object>(
    ref: DocumentReference<T>,
    data: Partial<T>,
    transaction?: Transaction
  ): Promise<void> {
    return updateDoc(ref, data, transaction, SystemActors.Migration);
  }

  static saveDoc<T extends object>(
    item: Reffable<T>,
    transaction?: Transaction
  ): Promise<DocumentReference<T>> {
    return Firestore.saveDoc(
      item,
      undefined,
      transaction,
      undefined,
      SystemActors.Migration
    );
  }

  static deleteDoc<T>(
    itemRef: DocumentReference<T>,
    transaction?: Transaction
  ): Promise<void> {
    return deleteDoc(itemRef, transaction, SystemActors.Migration);
  }

  static undeleteDoc<T>(
    itemRef: DocumentReference<T>,
    transaction?: Transaction
  ): Promise<void> {
    return undeleteDoc(itemRef, transaction, SystemActors.Migration);
  }

  static upsertBulk<T extends object>(
    collection: CollectionReference<T>,
    data: T[],
    uidPath: keyof T
  ): Promise<void> {
    return upsertBulk(collection, data, uidPath, SystemActors.Migration);
  }

  static isMigratedDocument<T extends object>(item: WithRef<T>): boolean {
    return (
      item.createdBy === SystemActors.Migration &&
      item.updatedBy === SystemActors.Migration
    );
  }
}

interface ICreatedAt {
  createdAt: Timestamp;
}
