import { Injectable } from '@angular/core';
import {
  type IOrganisation,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  type IIntercomUser,
  IntercomService,
} from '@principle-theorem/ng-intercom';
import {
  filterUndefined,
  isChanged$,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { OrganisationService } from '../services/organisation.service';
import { IntercomBootInput } from '@supy-io/ngx-intercom';

const intercomBootSettings: IntercomBootInput = {
  custom_launcher_selector: '#intercom-launcher',
  hide_default_launcher: true,
};

@Injectable({
  providedIn: 'root',
})
export class IntercomSyncService {
  private _sync$: Observable<IIntercomUser | undefined>;

  constructor(
    private _intercom: IntercomService,
    private _organisation: OrganisationService
  ) {
    this._intercom.boot(intercomBootSettings);

    this._sync$ = combineLatest([
      this._organisation.user$.pipe(filterUndefined()),
      this._organisation.organisation$.pipe(filterUndefined()),
    ]).pipe(
      debounceTime(500),
      map(([user, organisation]) => this._toIntercomUser(user, organisation)),
      isChanged$(),
      shareReplayCold()
    );
  }

  init(): void {
    this._sync$.subscribe((user) => this._intercom.updateUser(user));
  }

  private _toIntercomUser(
    user?: WithRef<IUser>,
    organisation?: WithRef<IOrganisation>
  ): IIntercomUser | undefined {
    if (!user || !organisation) {
      return undefined;
    }

    return {
      name: user.name,
      email: user.email,
      company: {
        company_id: organisation.ref.id,
        name: organisation.name,
      },
      createdAt: user.createdAt.toDate(),
      customData: {
        workspace: organisation.slug,
        lu_is_admin: user.isAdmin,
        lu_is_owner: user.isOwner,
      },
    };
  }
}
