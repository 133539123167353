import { type WithRef } from '@principle-theorem/shared';
import {
  type IMarketplaceSubscription,
  type IPathway,
  type ISkill,
  type ITag,
  type IUser,
} from '@principle-theorem/level-up-core';
import { toSearchInput$, TypedFormControl } from '@principle-theorem/ng-shared';
import { BehaviorSubject, type Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { type IBundleWithSubscription } from '../../../services/cached-lists.service';
import { PathwayListBloc } from '../pathway-list/pathway-list-bloc';
import { SkillListBloc } from '../skill-list/skill-list-bloc';
import { SubscriptionListBloc } from '../subscription-list/subscription-list-bloc';

export class PathwayAndSkillsListBloc {
  filteredSubscriptions$: Observable<WithRef<IMarketplaceSubscription>[]>;
  filteredPathways$: Observable<WithRef<IPathway>[]>;
  filteredSkills$: Observable<WithRef<ISkill>[]>;
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');
  selectedTagFilters$: BehaviorSubject<WithRef<ITag>[]> = new BehaviorSubject<
    WithRef<ITag>[]
  >([]);

  constructor(
    subscriptionBundlePair$: Observable<IBundleWithSubscription[]>,
    pathways$: Observable<WithRef<IPathway>[]>,
    skills$: Observable<WithRef<ISkill>[]>,
    public user$: Observable<WithRef<IUser>>
  ) {
    const search$: Observable<string> = toSearchInput$(this.searchCtrl).pipe(
      startWith('')
    );

    const skillBloc = new SkillListBloc(
      skills$,
      search$,
      this.selectedTagFilters$,
      this.user$
    );

    this.filteredSkills$ = skillBloc.filteredSkills$;

    const pathwayBloc = new PathwayListBloc(
      pathways$,
      search$,
      this.selectedTagFilters$,
      this.user$
    );
    this.filteredPathways$ = pathwayBloc.filteredPathways$;

    const subscriptionBloc = new SubscriptionListBloc(
      subscriptionBundlePair$,
      search$,
      this.user$
    );
    this.filteredSubscriptions$ = subscriptionBloc.filteredSubscriptions$;
  }
}
