import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { HeadingMenuComponent } from './heading-menu/heading-menu.component';
import { BlockNodes, IMenuButton } from '@principle-theorem/editor';

export function createHeadingMenuButton(): MenuButtonLoaderFn<
  HeadingMenuComponent,
  IMenuButton
> {
  return () => ({
    component: HeadingMenuComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Heading',
      tooltip: 'Heading',
      icon: 'format_size',
      command: () => {
        //
      },
      isActiveFn: (editor) => editor.isActive(BlockNodes.Heading),
      canActivateFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph) ||
        editor.isActive(BlockNodes.Heading),
    },
  });
}
