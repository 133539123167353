<mat-toolbar color="primary">Add Member</mat-toolbar>

<div class="layout-margin" fxLayout="column">
  <mat-form-field>
    <mat-label>User</mat-label>
    <input matInput [matAutocomplete]="auto" [formControl]="inputFilter" />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="add($event.option.value)"
  >
    <mat-option
      *ngFor="let user of filteredUsers$ | async; trackBy: trackByUser"
      [value]="user"
    >
      {{ user.name }}
    </mat-option>
  </mat-autocomplete>
</div>

<div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
  <button mat-stroked-button mat-dialog-close>Close</button>
</div>
