import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  type ISkill,
  type ISkillLevelRequest,
  type IUser,
  type IUserSkillLevelRequest,
  SkillLevelRequestStatus,
  User,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  getDoc,
  multiSortBy$,
  nameSorter,
  patchDoc,
  type Reffable,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { findTrainerUsersForSkill$ } from '../../models/user';
import { OrganisationService } from '../../services/organisation.service';

@Component({
    selector: 'lu-user-level-up-requests',
    templateUrl: './user-level-up-requests.component.html',
    styleUrls: ['./user-level-up-requests.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserLevelUpRequestsComponent {
  trainingRequests$: Observable<IUserSkillLevelRequest[]>;
  trackByRequest = TrackByFunctions.ref<IUserSkillLevelRequest>('request.ref');
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  @Input() showTitle = false;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this.trainingRequests$ = this.user$.pipe(
      switchMap((user) =>
        User.trainingRequests$(user, [SkillLevelRequestStatus.Pending])
      ),
      multiSortBy$((request) => request.createdAt.seconds),
      map((requests) => requests.reverse())
    );
  }

  async cancelTrainingRequest(
    trainingRequest: Reffable<ISkillLevelRequest>
  ): Promise<void> {
    await patchDoc(trainingRequest.ref, {
      status: SkillLevelRequestStatus.Cancelled,
    });
  }

  getSkill(skillRef: DocumentReference<ISkill>): Promise<WithRef<ISkill>> {
    return getDoc(skillRef);
  }

  getTrainer(
    mentorRef: DocumentReference<IUser>
  ): Promise<WithRef<IUser> | undefined> {
    return getDoc(mentorRef);
  }

  getTrainersForSkill$(skill: WithRef<ISkill>): Observable<WithRef<IUser>[]> {
    return this._organisation.enabledUsers$.pipe(
      findTrainerUsersForSkill$(skill),
      multiSortBy$(nameSorter())
    );
  }

  async canApproveTrainingRequest(skill: WithRef<ISkill>): Promise<boolean> {
    const currentUser = await snapshot(
      this._organisation.user$.pipe(filterUndefined())
    );

    return User.canApproveTrainingRequest(currentUser, skill);
  }

  async reassignTrainingRequest(
    trainingRequestRef: DocumentReference<ISkillLevelRequest>,
    mentor?: WithRef<IUser>
  ): Promise<void> {
    if (!mentor) {
      return;
    }

    await patchDoc(trainingRequestRef, {
      mentorRef: mentor.ref,
    });

    this._snackBar.open('Training Request reassigned');
  }
}
