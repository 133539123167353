import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ISkillReview } from '@principle-theorem/level-up-core';
import { BehaviorSubject } from 'rxjs';
import { WithRef } from '@principle-theorem/shared';

@Component({
    selector: 'lu-skill-reviews',
    templateUrl: './skill-reviews.component.html',
    styleUrl: './skill-reviews.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillReviewsComponent {
  reviews$ = new BehaviorSubject<WithRef<ISkillReview>[]>([]);

  @Output() handleContentError = new EventEmitter<string>();

  @Input()
  set reviews(reviews: WithRef<ISkillReview>[]) {
    if (reviews) {
      this.reviews$.next(reviews);
    }
  }
}
