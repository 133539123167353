import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  type OnDestroy,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  type IOptionGroup,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  reduceToSingleArray,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { combineLatest, Subject, type Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { type ISearchAction } from './search-action';
import { SearchService } from './search.service';

export type SearchOptionGroup = IOptionGroup<ISearchAction>;

@Component({
    selector: 'lu-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByGroup = TrackByFunctions.field<SearchOptionGroup>('name');
  trackByOption = TrackByFunctions.field<ISearchAction>('name');
  filteredGroups$: Observable<SearchOptionGroup[]>;
  searchCtrl = new TypedFormControl<string>('');
  readonly iconSize: number = 30;

  @ViewChild('searchInput', { read: ElementRef, static: true })
  searchInput: ElementRef<HTMLElement>;

  constructor(
    private _dialogRef: MatDialogRef<SearchComponent>,
    public search: SearchService
  ) {
    this.filteredGroups$ = combineLatest([
      this.search.groups$,
      this.searchCtrl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([groups, val]) => (val ? this.filter(val, groups) : groups.slice())),
      shareReplayCold()
    );

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.search.setValue(value));
  }

  ngOnInit(): void {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.clearResults();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('keyup.escape')
  closeDialog(): void {
    this._dialogRef.close();
  }

  filter(
    val: string | SearchOptionGroup,
    groups: SearchOptionGroup[]
  ): SearchOptionGroup[] {
    if (typeof val !== 'string') {
      return [];
    }

    const search: string = val.toLowerCase();

    return groups
      .map((group: SearchOptionGroup) => ({ ...group }))
      .map((group: SearchOptionGroup): SearchOptionGroup => {
        group.options = group.options.filter((item) => {
          if (group.skipFilter) {
            return true;
          }

          const searchKeys: string[] = [item.name];
          if (item.details) {
            searchKeys.push(item.details);
          }
          const searchIndex: number = searchKeys
            .join('')
            .toLowerCase()
            .indexOf(search);
          return searchIndex >= 0;
        });
        return group;
      });
  }

  displayFn(item?: ISearchAction): string {
    return item ? item.name : '';
  }

  async optionSelected(option: ISearchAction): Promise<void> {
    this.searchInput.nativeElement.blur();
    await option.do(this.searchCtrl.value);
    this.searchCtrl.reset();
    this._dialogRef.close();
  }

  clearResults(): void {
    this.searchCtrl.setValue('');
  }

  async selectFirstOption(): Promise<void> {
    const firstOption = await snapshot(
      this.filteredGroups$.pipe(
        map((groups) =>
          reduceToSingleArray(groups.map((group) => group.options))
        ),
        map((options) => first(options))
      )
    );
    if (firstOption) {
      await this.optionSelected(firstOption);
    }
  }

  toInitial(value: string): string {
    return value.charAt(0).toUpperCase();
  }
}
