import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type IAttachment, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { type IUploadStartedEvent } from '../file-upload-to-storage/file-upload-to-storage.component';
import { TrackByFunctions } from '../track-by';
import * as moment from 'moment-timezone';
import { Storage, getDownloadURL } from '@angular/fire/storage';
import { ref } from 'firebase/storage';

@Component({
    selector: 'pt-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AttachmentsComponent {
  trackByAttachment = TrackByFunctions.ref<WithRef<IAttachment>>();
  @Input() editable = true;
  uploadPath$: ReplaySubject<string> = new ReplaySubject(1);
  attachments$: BehaviorSubject<WithRef<IAttachment>[]> = new BehaviorSubject<
    WithRef<IAttachment>[]
  >([]);
  @Output()
  attachmentAdded: EventEmitter<IAttachment> = new EventEmitter<IAttachment>();
  @Output() attachmentRemoved: EventEmitter<WithRef<IAttachment>> =
    new EventEmitter<WithRef<IAttachment>>();

  constructor(
    private _snackBar: MatSnackBar,
    private _storage: Storage
  ) {}

  @Input()
  set attachments(attachments: WithRef<IAttachment>[]) {
    if (!attachments) {
      return;
    }
    this.attachments$.next(attachments);
  }

  @Input()
  set uploadPath(path: string) {
    if (path) {
      this.uploadPath$.next(path);
    }
  }

  async getAttachmentUrl(attachment: WithRef<IAttachment>): Promise<string> {
    try {
      return await getDownloadURL(ref(this._storage, attachment.link));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return attachment.link;
    }
  }

  async startedUpload(upload: IUploadStartedEvent): Promise<void> {
    const taskSnapshot = await upload.task;
    const unixTimestamp = moment().unix();

    const attachment: IAttachment = {
      link: await getDownloadURL(taskSnapshot.ref),
      path: taskSnapshot.ref.toString(),
      name: `${unixTimestamp}_${upload.file.name}`,
      type: upload.file.type,
      size: upload.file.size,
      lastModified: upload.file.lastModified,
      deleted: false,
    };

    this.attachmentAdded.next(attachment);
    this._snackBar.open('Attachment Added');
  }

  removeAttachment(attachment: WithRef<IAttachment>): void {
    this.attachmentRemoved.next(attachment);
    this._snackBar.open('Attachment Removed');
  }
}
