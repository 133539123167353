import { toInt } from './common';

/**
 * Converts any numbers in the string to the humanised version.
 */
export function numberToWords(value: string): string {
  const ones = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  return value.replace(/\d+/g, (match) => {
    // Convert the numeric string to a number
    const num = toInt(match);

    if (num === 0) {
      return 'zero';
    }

    if (num < 20) {
      return ones[num];
    }

    if (match.length === 2) {
      return `${tens[toInt(match[0])]} ${ones[toInt(match[1])]}`.trim();
    }

    if (match.length === 3) {
      if (match[0] === '0') {
        return numberToWords(`${match[1]}${match[2]}`);
      }
      if (match[1] === '0' && match[2] === '0') {
        return `${ones[toInt(match[0])]} hundred`;
      }
      return `${ones[toInt(match[0])]} hundred and ${numberToWords(
        `${match[1]}${match[2]}`
      )}`.trim();
    }

    if (match.length === 4) {
      const end = `${match[1]}${match[2]}${match[3]}`;
      if (toInt(end) === 0) {
        return `${ones[toInt(match[0])]} thousand`;
      }
      if (toInt(end) < 100) {
        return `${ones[toInt(match[0])]} thousand and ${numberToWords(
          end
        )}`.trim();
      }

      return `${ones[toInt(match[0])]} thousand ${numberToWords(end)}`.trim();
    }

    throw new Error('Number too large to convert to words');
  });
}
