import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IMarketplaceSubscription } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { type SubscriptionListBloc } from './subscription-list-bloc';

@Component({
    selector: 'lu-subscription-list',
    templateUrl: './subscription-list.component.html',
    styleUrls: ['./subscription-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubscriptionListComponent {
  trackBySubscription =
    TrackByFunctions.ref<WithRef<IMarketplaceSubscription>>();
  @Input() bloc: SubscriptionListBloc;
}
