import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'lu-status-label',
  template: '{{ label | uppercase }}',
  styleUrls: ['./status-label.component.scss'],
})
export class StatusLabelComponent {
  @Input() label = '';

  @Input()
  @HostBinding('attr.dense')
  dense = false;

  @Input()
  @HostBinding('class')
  color = 'default';
}
