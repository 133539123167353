import { Achievement, type IAchievement } from './achievement';
import { reduceToSingleArray } from '@principle-theorem/shared';
import { AchievementRequirement } from './achievement-requirement';

const GROW_ACHIEVEMENTS: IAchievement[] = [
  Achievement.init({
    key: 'self-level-up-1',
    priority: 10,
    name: `Earn your first star`,
    requirements: [
      AchievementRequirement.init({ statPath: 'totalStarsEarned', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'self-level-up-2',
    priority: 20,
    name: `Read 3 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'beginnerStarsEarned',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'self-level-up-3',
    priority: 30,
    name: `Read 10 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'beginnerStarsEarned',
        value: 10,
      }),
    ],
  }),
  Achievement.init({
    key: 'self-level-up-4',
    priority: 40,
    name: `Read 25 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'beginnerStarsEarned',
        value: 25,
      }),
    ],
  }),
  Achievement.init({
    key: 'grow-apprentice-1',
    priority: 11,
    name: `Become an Apprentice in a skill`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsEarned',
        value: 1,
      }),
    ],
  }),
  Achievement.init({
    key: 'grow-apprentice-2',
    priority: 21,
    name: `Become an Apprentice in 3 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsEarned',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'grow-apprentice-3',
    priority: 31,
    name: `Become an Apprentice in 10 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsEarned',
        value: 10,
      }),
    ],
  }),
  Achievement.init({
    key: 'grow-apprentice-4',
    priority: 41,
    name: `Become an Apprentice in 25 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsEarned',
        value: 25,
      }),
    ],
  }),
  Achievement.init({
    key: 'grow-pro-1',
    priority: 12,
    name: `Become a Pro in a skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsEarned', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'grow-pro-2',
    priority: 22,
    name: `Become a Pro in 3 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsEarned', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'grow-pro-3',
    priority: 32,
    name: `Become a Pro in 10 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsEarned', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'grow-pro-3',
    priority: 42,
    name: `Become a Pro in 25 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsEarned', value: 25 }),
    ],
  }),
  Achievement.init({
    key: 'grow-mentor-1',
    priority: 13,
    name: `Become a Trainer in a skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsEarned', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'grow-mentor-2',
    priority: 23,
    name: `Become a Trainer in 3 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsEarned', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'grow-mentor-3',
    priority: 33,
    name: `Become a Trainer in 10 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsEarned', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'grow-mentor-4',
    priority: 43,
    name: `Become a Trainer in 25 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsEarned', value: 25 }),
    ],
  }),
  Achievement.init({
    key: 'reach-goal-1',
    priority: 11,
    name: `Reach your goal for a skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillGoalsReached', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'reach-goal-2',
    priority: 21,
    name: `Reach your goal for 3 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillGoalsReached', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'reach-goal-2',
    priority: 31,
    name: `Reach your goal for 10 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillGoalsReached', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'reach-goal-4',
    priority: 41,
    name: `Reach your goal for 25 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillGoalsReached', value: 25 }),
    ],
  }),
  Achievement.init({
    key: 'reset-own-1',
    priority: 11,
    name: `Reset your progress in a skill`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'resetOwnSkillProgress',
        value: 1,
      }),
    ],
  }),
  Achievement.init({
    key: 'read-non-goal-1',
    priority: 12,
    name: `Read a skill outside your goals`,
    requirements: [
      AchievementRequirement.init({ statPath: 'nonGoalStarsEarned', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'request-level-up-1',
    priority: 12,
    name: `Request a level up on a skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'levelUpsRequested', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'request-level-up-2',
    priority: 22,
    name: `Request a level up on 3 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'levelUpsRequested', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'request-level-up-3',
    priority: 32,
    name: `Request a level up on 10 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'levelUpsRequested', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'request-level-up-4',
    priority: 42,
    name: `Request a level up on 25 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'levelUpsRequested', value: 25 }),
    ],
  }),
];

const MENTOR_ACHIEVEMENTS: IAchievement[] = [
  Achievement.init({
    key: 'mentor-apprentice-1',
    priority: 14,
    name: `Award someone an Apprentice star`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsGiven',
        value: 1,
      }),
    ],
  }),
  Achievement.init({
    key: 'mentor-apprentice-2',
    priority: 24,
    name: `Award 3 people an Apprentice star`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsGiven',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'mentor-apprentice-3',
    priority: 34,
    name: `Award 10 people an Apprentice star`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsGiven',
        value: 10,
      }),
    ],
  }),
  Achievement.init({
    key: 'mentor-apprentice-4',
    priority: 44,
    name: `Award 25 people an Apprentice star`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'apprenticeStarsGiven',
        value: 25,
      }),
    ],
  }),
  Achievement.init({
    key: 'mentor-pro-1',
    priority: 15,
    name: `Award someone a Pro star`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsGiven', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-pro-2',
    priority: 25,
    name: `Award 3 people a Pro star`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsGiven', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-pro-3',
    priority: 25,
    name: `Award 10 people a Pro star`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsGiven', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-pro-4',
    priority: 45,
    name: `Award 25 people a Pro star`,
    requirements: [
      AchievementRequirement.init({ statPath: 'proStarsGiven', value: 25 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-mentor-1',
    priority: 19,
    name: `Award someone as a Trainer`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsGiven', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-mentor-2',
    priority: 29,
    name: `Award 3 people as a Trainer`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsGiven', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-mentor-3',
    priority: 39,
    name: `Award 10 people as a Trainer`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsGiven', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'mentor-mentor-4',
    priority: 49,
    name: `Award 25 people as a Trainer`,
    requirements: [
      AchievementRequirement.init({ statPath: 'mentorStarsGiven', value: 25 }),
    ],
  }),
  Achievement.init({
    key: 'reset-other-1',
    priority: 19,
    name: `Reset someone's progress in a skill`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'resetOthersSkillProgress',
        value: 1,
      }),
    ],
  }),
];

const AUTHOR_ACHIEVEMENTS: IAchievement[] = [
  Achievement.init({
    key: 'skill-author-1',
    priority: 10,
    name: `Draft a skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsDrafted', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'skill-author-2',
    priority: 20,
    name: `Draft 3 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsDrafted', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'skill-author-3',
    priority: 30,
    name: `Draft 10 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsDrafted', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'skill-author-4',
    priority: 40,
    name: `Draft 25 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsDrafted', value: 25 }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-1',
    priority: 11,
    name: `Mark a skill as ready for review`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'skillsMarkedForReview',
        value: 1,
      }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-2',
    priority: 21,
    name: `Mark 3 skills as ready for review`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'skillsMarkedForReview',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-3',
    priority: 31,
    name: `Mark 10 skills as ready for review`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'skillsMarkedForReview',
        value: 10,
      }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-4',
    priority: 41,
    name: `Mark 25 skills as ready for review`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'skillsMarkedForReview',
        value: 25,
      }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-1',
    priority: 12,
    name: `Author an approved skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsApproved', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-2',
    priority: 22,
    name: `Author 3 approved skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsApproved', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-3',
    priority: 32,
    name: `Author 10 approved skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsApproved', value: 10 }),
    ],
  }),
  Achievement.init({
    key: 'skill-review-4',
    priority: 42,
    name: `Author 25 approved skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'skillsApproved', value: 25 }),
    ],
  }),
];

const REVIEWER_ACHIEVEMENTS: IAchievement[] = [
  Achievement.init({
    key: 'review-approve-1',
    priority: 13,
    name: `Review and approve a skill`,
    requirements: [
      AchievementRequirement.init({ statPath: 'reviewAndApprovals', value: 1 }),
    ],
  }),
  Achievement.init({
    key: 'review-approve-2',
    priority: 23,
    name: `Review and approve 3 skills`,
    requirements: [
      AchievementRequirement.init({ statPath: 'reviewAndApprovals', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'review-approve-3',
    priority: 33,
    name: `Review and approve 10 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'reviewAndApprovals',
        value: 10,
      }),
    ],
  }),
  Achievement.init({
    key: 'review-approve-4',
    priority: 43,
    name: `Review and approve 25 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'reviewAndApprovals',
        value: 25,
      }),
    ],
  }),
  Achievement.init({
    key: 'review-change-1',
    priority: 13,
    name: `Review and request changes on a skill`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'reviewAndChangeRequests',
        value: 1,
      }),
    ],
  }),
  Achievement.init({
    key: 'review-change-2',
    priority: 23,
    name: `Review and request changes on 3 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'reviewAndChangeRequests',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'review-change-3',
    priority: 33,
    name: `Review and request changes on 10 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'reviewAndChangeRequests',
        value: 10,
      }),
    ],
  }),
  Achievement.init({
    key: 'review-change-4',
    priority: 43,
    name: `Review and request changes on 25 skills`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'reviewAndChangeRequests',
        value: 25,
      }),
    ],
  }),
];

const USER_ACHIEVEMENTS: IAchievement[] = [
  Achievement.init({
    key: 'user-invite-1',
    priority: 10,
    name: `Invite a user`,
    requirements: [
      AchievementRequirement.init({ statPath: 'invitedUsers', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'user-invite-2',
    priority: 20,
    name: `Invite 3 users`,
    requirements: [
      AchievementRequirement.init({ statPath: 'invitedUsers', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'user-invite-3',
    priority: 30,
    name: `Invite 10 users`,
    requirements: [
      AchievementRequirement.init({ statPath: 'invitedUsers', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'user-invite-4',
    priority: 40,
    name: `Invite 25 users`,
    requirements: [
      AchievementRequirement.init({ statPath: 'invitedUsers', value: 3 }),
    ],
  }),
  Achievement.init({
    key: 'user-activated-1',
    priority: 11,
    name: `Invite an activated user`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'invitedActivatedUsers',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'user-activated-2',
    priority: 21,
    name: `Invite 3 activated users`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'invitedActivatedUsers',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'user-activated-3',
    priority: 31,
    name: `Invite 10 activated users`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'invitedActivatedUsers',
        value: 3,
      }),
    ],
  }),
  Achievement.init({
    key: 'user-activated-4',
    priority: 41,
    name: `Invite 25 activated users`,
    requirements: [
      AchievementRequirement.init({
        statPath: 'invitedActivatedUsers',
        value: 3,
      }),
    ],
  }),
];

export const ACHIEVEMENTS: IAchievement[] = reduceToSingleArray([
  GROW_ACHIEVEMENTS,
  MENTOR_ACHIEVEMENTS,
  AUTHOR_ACHIEVEMENTS,
  REVIEWER_ACHIEVEMENTS,
  USER_ACHIEVEMENTS,
]);
