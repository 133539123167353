<mat-toolbar color="primary">Select a Trainer</mat-toolbar>
<form
  [formGroup]="form"
  (ngSubmit)="submit()"
  class="layout-margin"
  fxLayout="column"
  fxLayoutGap="16px"
>
  <div>
    <mat-form-field fxFill>
      <mat-label>Trainer</mat-label>
      <mat-select
        formControlName="mentor"
        placeholder="Select a Trainer"
        [compareWith]="compareWithFn"
      >
        <mat-option
          *ngFor="let user of users$ | async; trackBy: trackByUser"
          [value]="user.ref"
        >
          {{ user.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button [disabled]="isDisabled$ | async">
      Submit Request
    </button>
  </div>
</form>
