import {
  getTextOverlayColour,
  type AtLeast,
  isObject,
  type ISoftDelete,
} from '@principle-theorem/shared';

export interface ITag extends ISoftDelete {
  name: string;
  hexColour: string;
}

export class Tag {
  static init(overrides: AtLeast<ITag, 'name'>): ITag {
    return {
      hexColour: '',
      deleted: false,
      ...overrides,
    };
  }

  static textColour(tag: ITag): string {
    return getTextOverlayColour(tag.hexColour);
  }
}

export function isTag(item: unknown): item is ITag {
  return isObject(item) && 'name' in item && 'hexColour' in item;
}
