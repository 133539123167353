import {
  type IUser,
  type IUserMetadata,
} from '@principle-theorem/level-up-core';
import { type AuthUser } from '@principle-theorem/ng-auth';
import { type CollectionReference, where } from '@principle-theorem/shared';
import {
  filterUndefined,
  find$,
  isChanged$,
  isPathChanged$,
  LAST_ACTIVE_SYNC_INTERVAL,
  patchDoc,
  runTransaction,
  shareReplayCold,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { noop } from 'lodash';
import * as moment from 'moment-timezone';
import {
  combineLatest,
  interval,
  merge,
  type Observable,
  of,
  type Subject,
} from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

export class UserSyncBloc {
  user$: Observable<WithRef<IUser> | undefined>;

  constructor(
    usersCol$: Observable<CollectionReference<IUser>>,
    authUser$: Observable<AuthUser | undefined>,
    private _onDestroy$: Subject<void>
  ) {
    this.user$ = combineLatest([
      usersCol$,
      authUser$.pipe(
        map((user) => user?.email ?? undefined),
        isChanged$()
      ),
    ]).pipe(
      switchMap(([userCol, email]) => {
        if (!email) {
          return of(undefined);
        }
        return find$(userCol, where('email', '==', email));
      }),
      switchMap((user) => {
        if (user && user.isActivated) {
          return of(user);
        }
        return of(undefined);
      }),
      shareReplayCold()
    );

    combineLatest([
      this.user$.pipe(
        filterUndefined(),
        filter((user: WithRef<IUser>) => !user.firstSignedInAt)
      ),
      authUser$.pipe(filterUndefined()),
    ])
      .pipe(
        filter(([user, authUser]) => user.email === authUser.email),
        switchMap(([user, authUser]: [WithRef<IUser>, AuthUser]) => {
          if (!authUser || !authUser.metadata.creationTime) {
            return of(undefined);
          }
          return this._syncMetadata(user, {
            firstSignedInAt: toTimestamp(
              moment(authUser.metadata.creationTime)
            ),
          });
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => noop());

    this.user$
      .pipe(
        isPathChanged$('ref.path'),
        switchMap((user) => {
          if (!user) {
            return of(undefined);
          }
          return merge(of(undefined), interval(LAST_ACTIVE_SYNC_INTERVAL)).pipe(
            switchMap(() =>
              this._syncMetadata(user, {
                lastActiveAt: toTimestamp(),
              })
            )
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => noop());
  }

  private async _syncMetadata(
    user: WithRef<IUser>,
    metadata: IUserMetadata
  ): Promise<void> {
    await runTransaction((transaction) =>
      patchDoc(user.ref, metadata, transaction)
    );
  }
}
