import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
} from '@angular/core';
import { TypedFormControl } from '../forms/typed-form-group';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { findIndex } from 'lodash';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type MultiChipInputValidatorFn = (value: string) => boolean;

@Component({
  selector: 'pt-multi-chip-input',
  imports: [NgMaterialModule, ReactiveFormsModule],
  templateUrl: './multi-chip-input.component.html',
  styleUrls: ['./multi-chip-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiChipInputComponent),
      multi: true,
    },
  ],
})
export class MultiChipInputComponent implements ControlValueAccessor {
  validatorFn = input<MultiChipInputValidatorFn>();
  itemName = input<string>('Item');
  label = input<string>('');
  uniqueItems = input<boolean>(true);
  emitEvent = input<boolean>(true);

  formCtrl = new TypedFormControl<string[]>([]);
  separatorKeysCodes = [ENTER, COMMA];

  onChange?: (value: string[]) => void;
  onTouched?: () => void;

  constructor() {
    this.formCtrl.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const validator = this.validatorFn();
    if (!value || (validator && !validator(value))) {
      return;
    }

    const currentValue = this.formCtrl.value;
    if (this.uniqueItems() && currentValue.includes(value)) {
      event.chipInput.clear();
      return;
    }

    this.formCtrl.setValue([...currentValue, value]);
    event.chipInput.clear();
  }

  remove(value: string): void {
    const currentValue = this.formCtrl.value;
    this.formCtrl.setValue(currentValue.filter((item) => item !== value));
  }

  edit(event: MatChipEditedEvent, value: string): void {
    const newValue = event.value.trim();
    if (!newValue) {
      this.remove(value);
      return;
    }

    const currentValues = this.formCtrl.value;
    const foundIndex = findIndex(currentValues, (item) => item === value);
    if (foundIndex === -1) {
      return;
    }
    currentValues[foundIndex] = newValue;
    this.formCtrl.setValue(currentValues);
  }

  writeValue(value: string[]): void {
    this.formCtrl.setValue(value, { emitEvent: this.emitEvent() });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.formCtrl.disable({ emitEvent: this.emitEvent() })
      : this.formCtrl.enable({ emitEvent: this.emitEvent() });
  }
}
