import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SkillLevel,
  User,
  hasReachedLevel,
  updateSkillLevelRequirement,
  type ISkill,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  ISODateType,
  WithRef,
  filterUndefined,
  patchDoc,
  snapshot,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { findUserSkillProgress } from '../../../models/user';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-user-skill-list-item',
    templateUrl: './user-skill-list-item.component.html',
    styleUrls: ['./user-skill-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserSkillListItemComponent {
  user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject(1);
  skill$: ReplaySubject<WithRef<ISkill>> = new ReplaySubject(1);
  canEdit$: Observable<boolean>;
  progress: UserSkillProgress;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.canEdit$ = this.skill$.pipe(
      switchMap((skill: WithRef<ISkill>) => this._isTrainer(skill))
    );

    this.progress = new UserSkillProgress(
      this._organisation.userGroups$,
      this.user$,
      this.skill$
    );
  }

  async updateDueDate(dueDate?: ISODateType): Promise<void> {
    const skill = await snapshot(this.skill$);
    const user = await snapshot(this.user$);
    const userGroups = await snapshot(this._organisation.userGroups$);
    const { levelRequirements } = User.getGoals(user, userGroups);
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { dueDate },
      levelRequirements
    );

    this.user$.next({ ...user, levelRequirements: updatedSkillLevels });
    await patchDoc(user.ref, {
      levelRequirements: updatedSkillLevels,
    });
  }

  private _isTrainer(skill: WithRef<ISkill>): Observable<boolean> {
    return this._organisation.user$.pipe(
      filterUndefined(),
      findUserSkillProgress(skill),
      map((progress) =>
        progress ? hasReachedLevel(progress, SkillLevel.Trainer) : false
      )
    );
  }
}
