<ng-container *ngIf="pathway$ | async as pathway">
  <mat-list-item>
    <div class="flex flex-row place-content-between items-center">
      <div class="flex flex-col gap-1">
        <div>
          <ng-container *ngIf="!pathway.readOnly; else readOnly">
            <a
              [routerLink]="['/admin/pathways', pathway.ref.id]"
              ptIntercomTarget="pathways.pathway.name"
              >{{ pathway.name }}</a
            >
            <lu-privacy-icon
              *ngIf="pathway.restrictAccessTo.length"
              [item]="pathway"
            />
          </ng-container>
          <ng-template #readOnly>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <span>{{ pathway.name }}</span>
              <lu-read-only-icon *ngIf="pathway.readOnly" />
            </div>
          </ng-template>
        </div>

        <lu-tag-display [tagRefs]="pathway.tags" />
      </div>

      <div class="flex flex-row items-center">
        <ng-container *ngIf="pathway.author">
          <ng-container *ngIf="pathway.author | refItem$ | async as author">
            <div class="user-icon-container">
              <pt-user-icon
                [src]="author | profileImage$ | async"
                [diameter]="30"
                [name]="author.name"
                [matTooltip]="author.name"
              />
            </div>
          </ng-container>
        </ng-container>

        <button
          mat-icon-button
          fxHide.lt-sm
          color="warn"
          [disabled]="pathway.readOnly"
          (click)="remove(pathway)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
</ng-container>
