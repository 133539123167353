import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  getSchemaSize,
  initVersionedSchema,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  Organisation,
  Skill,
  SkillLevel,
  SkillStatus,
  reviewerHasApproved,
  type ISkill,
  type ISkillReview,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  DialogPresets,
  NgFireMediaUploader,
  TrackByFunctions,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  firstResult,
  getDoc,
  shareReplayCold,
  snapshot,
  sortByCreatedAt,
  toQuery,
  undeletedQuery,
  where,
  type DocumentReference,
  type IAttachment,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  from,
  of,
  type Observable,
} from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { MarketplaceCopyService } from '../../../services/marketplace-copy.service';
import { OrganisationService } from '../../../services/organisation.service';
import { SkillAttachmentsService } from '../../../services/skill-attachments.service';
import { SkillProgressUpdaterService } from '../../../services/skill-progress-updater.service';
import { SkillStatusUpdaterService } from '../../../services/skill-status-updater.service';
import {
  UsersSkillProgressSummaryDialogComponent,
  type IUsersWithSkillData,
} from '../users-skill-progress-summary-dialog/users-skill-progress-summary-dialog.component';

@Component({
    selector: 'lu-skill-display',
    templateUrl: './skill-display.component.html',
    styleUrls: ['./skill-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillDisplayComponent {
  trackByReviewer = TrackByFunctions.uniqueId<DocumentReference<IUser>>();
  trackByReview = TrackByFunctions.ref<WithRef<ISkillReview>>();
  skill$: ReplaySubject<WithRef<ISkill>> = new ReplaySubject(1);
  attachments$: Observable<WithRef<IAttachment>[]>;
  comments: TypedFormControl<VersionedSchema> = new TypedFormControl(
    initVersionedSchema()
  );
  pendingReviewers$: Observable<DocumentReference<IUser>[]>;
  uploader: NgFireMediaUploader;
  reviews$: Observable<WithRef<ISkillReview>[]>;
  isInReview$: Observable<boolean>;
  isMarketplaceVersion$: Observable<boolean>;
  isLocalSkill$: Observable<boolean>;
  isAlreadyCopied$: Observable<boolean>;
  hasApproved$: Observable<boolean>;
  copiedSkillTooltip$: Observable<string | undefined>;
  disableRequest$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(false);
  skillProgress: UserSkillProgress;
  skillStatus = SkillStatus;

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (!skill) {
      return;
    }
    this.skill$.next(skill);
  }

  constructor(
    public skillAttachments: SkillAttachmentsService,
    public organisation: OrganisationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _skillStatus: SkillStatusUpdaterService,
    private _progressUpdater: SkillProgressUpdaterService,
    private _storage: Storage,
    private _dialog: MatDialog,
    private _marketplaceCopy: MarketplaceCopyService
  ) {
    this.skillProgress = new UserSkillProgress(
      this.organisation.userGroups$,
      this.organisation.user$.pipe(filterUndefined()),
      this.skill$
    );

    this.attachments$ = this.skill$.pipe(
      switchMap((skill) => Skill.attachments$(skill))
    );

    this.pendingReviewers$ = this.skill$.pipe(
      switchMap((skill: WithRef<ISkill>) => Skill.pendingReviewers(skill))
    );

    this.uploader = new NgFireMediaUploader(
      this._storage,
      this.organisation.storagePath$
    );

    this.reviews$ = this.skill$.pipe(
      switchMap((skill) => Skill.reviews$(skill)),
      map((reviews) => reviews.sort(sortByCreatedAt))
    );

    this.isInReview$ = this.skill$.pipe(map((skill) => Skill.inReview(skill)));

    this.hasApproved$ = combineLatest([
      this.organisation.user$.pipe(filterUndefined()),
      this.reviews$,
    ]).pipe(
      map(([user, reviews]) => {
        if (!reviews.length) {
          return false;
        }
        return reviewerHasApproved(reviews, user.ref);
      })
    );

    this.isMarketplaceVersion$ = this.skill$.pipe(
      map((skill) => Skill.isMarketplaceRelease(skill)),
      shareReplayCold()
    );

    this.isLocalSkill$ = this.isMarketplaceVersion$.pipe(
      map((isMarketplaceVersion) => !isMarketplaceVersion),
      shareReplayCold()
    );

    const copiedSkill$ = combineLatest([
      this.skill$,
      this.organisation.organisation$.pipe(filterUndefined()),
      this.isMarketplaceVersion$,
    ]).pipe(
      switchMap(([skill, org, isMarketplaceVersion]) =>
        isMarketplaceVersion && skill.vendorSkillRef
          ? from(
              firstResult(
                toQuery(
                  undeletedQuery(Organisation.skillCol(org)),
                  where('vendorSkillRef', '==', skill.vendorSkillRef)
                )
              )
            )
          : of(undefined)
      ),
      shareReplayCold()
    );

    this.copiedSkillTooltip$ = copiedSkill$.pipe(
      switchMap((skill) => {
        if (!skill?.folderRef) {
          return of(undefined);
        }
        return from(getDoc(skill.folderRef)).pipe(
          map(
            (folder) =>
              `Skill has already been copied to folder: ${folder.name}`
          )
        );
      }),
      shareReplayCold()
    );

    this.isAlreadyCopied$ = copiedSkill$.pipe(
      map((skill) => !!skill),
      startWith(true),
      shareReplayCold()
    );

    this.disableRequest$ = validFormControlChanges$(this.comments).pipe(
      map((comments) => !getSchemaSize(comments)),
      startWith(true)
    );
  }

  async approve(skill: WithRef<ISkill>): Promise<void> {
    await this._catchError(async () => {
      await this._skillStatus.manageTrainersForSkill(skill);
    }, 'Trainers are required to publish a trainer verified skill');

    await this._catchError(async () => {
      await this._skillStatus.approve(skill, skill);
      this._snackBar.open(`${skill.name} approved`);
      await this._router.navigate(['/create']);
    });
  }

  async manageTrainersForSkill(skill: WithRef<ISkill>): Promise<void> {
    await this._catchError(async () => {
      await this._skillStatus.manageTrainersForSkill(skill);
    }, 'Trainers are required to publish a trainer verified skill');
  }

  async viewTeamProgress(skill: WithRef<ISkill>): Promise<void> {
    const users = await snapshot(this.organisation.enabledUsers$);
    const data: IUsersWithSkillData = { users, skill };

    this._dialog.open<
      UsersSkillProgressSummaryDialogComponent,
      IUsersWithSkillData
    >(UsersSkillProgressSummaryDialogComponent, DialogPresets.large({ data }));
  }

  async addReview(
    skill: WithRef<ISkill>,
    user: WithRef<IUser>,
    isApproved: boolean = false
  ): Promise<void> {
    const message: string = isApproved ? 'Review Added' : 'Changes Requested';
    await this._catchError(async () => {
      await this._skillStatus.addReview(skill, {
        comments: this.comments.value,
        reviewer: user.ref,
        isApproved,
      });
      this.comments.reset(initVersionedSchema());
      this._snackBar.open(message);

      if (!(await Skill.approvedByAllReviewers(skill))) {
        return;
      }
      await this.approve(skill);
    });
  }

  async handleContentError(error?: string): Promise<void> {
    const skill = await snapshot(this.skill$);
    const user = await snapshot(
      this.organisation.user$.pipe(filterUndefined())
    );
    const organisation = await snapshot(
      this.organisation.organisation$.pipe(filterUndefined())
    );
    throw new Error(
      JSON.stringify({
        error: error || `Couldn't render content`,
        skill: skill.name,
        path: skill.ref.path,
        workspace: organisation.slug,
        user: user.name,
      })
    );
  }

  async reset(): Promise<void> {
    await this._setSkillLevel(SkillLevel.None);
  }

  async copyToFolder(skill: WithRef<ISkill>): Promise<void> {
    await this._marketplaceCopy.copySkill(skill);
  }

  private async _catchError(
    callback: () => Promise<void>,
    message: string = 'Oops! There was a problem saving'
  ): Promise<void> {
    try {
      await callback();
    } catch (e) {
      this._snackBar.open(message);
      throw e;
    }
  }

  private async _setSkillLevel(level: SkillLevel): Promise<void> {
    const user: WithRef<IUser> = await snapshot(
      this.organisation.user$.pipe(filterUndefined())
    );
    const skill: WithRef<ISkill> = await snapshot(this.skill$);
    await this._progressUpdater.upsert(user, skill, level);
  }
}
