import { DOCUMENT } from '@angular/common';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NavigationFocusService } from '../navigation-focus/navigation-focus.service';
import { TrackByFunctions } from '../track-by';
import { TABLE_OF_CONTENTS_CLASS } from './table-of-contents-heading.directive';

interface ILinkSection {
  name: string;
  links: ILink[];
}

interface ILink {
  element: HTMLElement;

  /* id of the section*/
  id: string;

  /* header type h3/h4 */
  type: string;

  /* If the anchor is in view of the page */
  active: boolean;

  /* name of the anchor */
  name: string;
}

@Component({
    selector: 'pt-table-of-contents',
    styleUrls: ['./table-of-contents.component.scss'],
    templateUrl: './table-of-contents.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TableOfContentsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  private _scrollContainer?: HTMLElement | Window;
  private _urlFragment = '';
  private _document: Document = inject(DOCUMENT);

  trackBySection = TrackByFunctions.field<ILinkSection>('name');
  trackByLink = TrackByFunctions.field<ILink>('id');
  linkSections$ = new BehaviorSubject<ILinkSection[]>([]);
  links: ILink[] = [];
  rootUrl: string;
  @Input() container?: string;
  @Input() offset: number = 0;
  contents$ = new ReplaySubject<HTMLElement>(1);
  title$ = new ReplaySubject<string>(1);

  @Input()
  set title(title: string) {
    if (title) {
      this.title$.next(title);
    }
  }

  @Input()
  set contents(contents: HTMLElement) {
    if (contents) {
      this.contents$.next(contents);
    }
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _element: ElementRef<HTMLElement>,
    private _navigationFocusService: NavigationFocusService,
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.rootUrl = this._router.url.split('#')[0];

    this._navigationFocusService.navigationEndEvents
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        const rootUrl = this._router.url.split('#')[0];
        if (rootUrl !== this.rootUrl) {
          this.rootUrl = rootUrl;
        }
      });

    this._route.fragment
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((fragment) => {
        if (fragment) {
          this._urlFragment = fragment;

          const target = document.getElementById(this._urlFragment);
          if (target) {
            target.scrollIntoView();
          }
        }
      });

    combineLatest([this.title$, this.contents$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([title, contents]) => this.addHeaders(title, contents));
  }

  ngOnInit(): void {
    // On init, the sidenav content element doesn't yet exist, so it's not possible
    // to subscribe to its scroll event until next tick (when it does exist).
    this._ngZone.runOutsideAngular(() => {
      void Promise.resolve().then(() => {
        this._scrollContainer = this.container
          ? (this._document.querySelector(this.container) as HTMLElement)
          : window;

        if (this._scrollContainer) {
          fromEvent(this._scrollContainer, 'scroll')
            .pipe(debounceTime(10), takeUntil(this._onDestroy$))
            .subscribe(() => this.onScroll());
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.updateScrollPosition();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateScrollPosition(): void {
    this._document.getElementById(this._urlFragment)?.scrollIntoView();
  }

  resetHeaders(): void {
    this.linkSections$.next([]);
    this.links = [];
  }

  addHeaders(
    sectionName: string,
    docViewerContent: HTMLElement,
    sectionIndex = 0
  ): void {
    const links = Array.from(
      docViewerContent.querySelectorAll(
        `div.${TABLE_OF_CONTENTS_CLASS}, h3.${TABLE_OF_CONTENTS_CLASS}, h4.${TABLE_OF_CONTENTS_CLASS}`
      ),
      (header) => {
        // remove the 'link' icon name from the inner text
        const name = (header as HTMLElement).innerText
          .trim()
          .replace(/^link/, '');
        return {
          element: header as HTMLElement,
          name,
          type: header.tagName.toLowerCase(),
          id: header.id,
          active: false,
        };
      }
    );

    const linkSections = this.linkSections$.value;
    linkSections[sectionIndex] = { name: sectionName, links };
    this.linkSections$.next(linkSections);
    this.links.push(...links);
    this._changeDetectorRef.markForCheck();
  }

  /** Gets the scroll offset of the scroll container */
  private getScrollOffset(): number | void {
    const { top } = this._element.nativeElement.getBoundingClientRect();
    const container = this._scrollContainer;

    if (container instanceof HTMLElement) {
      return container.scrollTop + top + this.offset;
    }

    if (container) {
      return container.pageYOffset + top + this.offset;
    }
  }

  private onScroll(): void {
    const scrollOffset = this.getScrollOffset();
    let hasChanged = false;

    for (let i = 0; i < this.links.length; i++) {
      // A link is considered active if the page is scrolled past the
      // anchor without also being scrolled passed the next link.
      const currentLink = this.links[i];
      const nextLink = this.links[i + 1];
      const isActive =
        (scrollOffset ?? 0) >= currentLink.element.offsetTop &&
        (!nextLink || nextLink.element.offsetTop >= (scrollOffset ?? 0));

      if (isActive !== currentLink.active) {
        currentLink.active = isActive;
        hasChanged = true;
      }
    }

    if (hasChanged) {
      // The scroll listener runs outside of the Angular zone so
      // we need to bring it back in only when something has changed.
      this._ngZone.run(() => this._changeDetectorRef.markForCheck());
    }
  }
}
