import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { type MatListOption } from '@angular/material/list';
import { NotificationType } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { getEnumValues } from '@principle-theorem/shared';
import { difference } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserSettingsStoreService } from '../../../services/user-settings-store.service';

@Component({
  selector: 'lu-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPreferencesComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByPreference = TrackByFunctions.field<INotificationPreference>('id');
  preferences$ = new ReplaySubject<INotificationPreference[]>(1);

  constructor(private _settings: UserSettingsStoreService) {
    this._settings.notifications$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((notifications) => {
        this.preferences$.next(
          defaultNotificationPreferences.map((preference) => ({
            ...preference,
            enabled: !notifications.disabled.includes(preference.id),
          }))
        );
      });
  }

  updateEnabledNotifications(enabledNotifications: MatListOption[]): void {
    this._settings.updateUserSettings({
      notifications: {
        disabled: difference(
          getEnumValues(NotificationType),
          enabledNotifications.map(
            (notification) => notification.value as NotificationType
          )
        ),
      },
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

interface INotificationPreference {
  id: NotificationType;
  label: string;
  description: string;
  enabled: boolean;
}

const defaultNotificationPreferences: INotificationPreference[] = [
  {
    id: NotificationType.TrainingGoalsChanged,
    label: 'Training Goals Changed',
    description: `Receive a notification when you've been given new training goals`,
    enabled: true,
  },
  {
    id: NotificationType.TrainingApproved,
    label: 'Training Completed',
    description:
      'Receive a notification when your trainer has approved your training for a skill',
    enabled: true,
  },
  {
    id: NotificationType.TrainingDeclined,
    label: 'Training Request Declined',
    description:
      'Receive a notification when your trainer has declined your training for a skill',
    enabled: true,
  },
  {
    id: NotificationType.SkillReviewRequested,
    label: 'Training Requested',
    description: `Receive a notification when you'be been requested to train a user for a skill`,
    enabled: true,
  },
  {
    id: NotificationType.TrainingCancelled,
    label: 'Training Request Cancelled',
    description:
      'Receive a notification when a user has cancelled their training request with you',
    enabled: true,
  },
  {
    id: NotificationType.SkillPublished,
    label: 'Skill Published',
    description: 'Receive a notification when a skill you created is published',
    enabled: true,
  },
  {
    id: NotificationType.SkillReviewRequested,
    label: 'Skill Review Requested',
    description: `Receive a notification when you've been requested to review a skill`,
    enabled: true,
  },
  {
    id: NotificationType.SkillReviewApproved,
    label: 'Skill Review Approved',
    description:
      'Receive a notification when your skill has been approved by a reviewer',
    enabled: true,
  },
  {
    id: NotificationType.SkillReviewSubmitted,
    label: 'Skill Review Submitted',
    description:
      'Receive a notification when your skill has had a review submitted',
    enabled: true,
  },
];
