import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IPathway, type ISkill } from '@principle-theorem/level-up-core';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IRoutingAction } from '@principle-theorem/shared';

@Injectable()
export class LoadSkillActionService implements IRoutingAction<ISkill> {
  icon = 'open_in_new';
  name = 'View Skill';

  constructor(private _router: Router) {}

  async do(skillRef: DocumentReference<ISkill>): Promise<void> {
    await this._router.navigate(['/', 'explore', 'skill', skillRef.id]);
  }

  url(
    skillRef: DocumentReference<ISkill>,
    parentPathwayRef?: DocumentReference<IPathway>
  ): string {
    if (parentPathwayRef) {
      return this._router
        .createUrlTree([
          '/',
          'explore',
          'pathway',
          parentPathwayRef.id,
          'steps',
          skillRef.id,
        ])
        .toString();
    }

    return this._router
      .createUrlTree(['/', 'explore', 'skill', skillRef.id])
      .toString();
  }
}
