import { chunk, last, startsWith, take, trim } from 'lodash';

export function getDocUid(path: string): string | undefined {
  if (!isDocPath(path)) {
    throw new Error(`${path} is not a document path`);
  }
  return last(path.split('/'));
}

export function isDocPath(path: string): boolean {
  const segments = path.split('/');
  return segments.length % 2 === 0;
}

interface IParentPathSegment {
  index: number;
  colId?: string;
  docId?: string;
}

export function findParentDocPath(
  path: string,
  parentCollection: string
): string | undefined {
  const slashPrefix = startsWith(path, '/');
  const safePath = slashPrefix ? trim(path, '/') : path;
  const pairs = chunk(safePath.split('/'), 2);
  const match = pairs
    .map(
      ([colId, docId], index): IParentPathSegment => ({ index, colId, docId })
    )
    .find((segment) => segment.colId === parentCollection);

  if (!match || !match.colId) {
    return;
  }
  const parentPath = take(pairs, match.index + 1)
    .flat()
    .join('/');
  return slashPrefix ? `/${parentPath}` : parentPath;
}
