import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@principle-theorem/ng-auth';
import { httpsCallable, shareReplayHot } from '@principle-theorem/shared';
import { Subject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TipTapService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  aiToken$: Observable<string | undefined>;

  constructor(auth: AuthService) {
    this.aiToken$ = auth.authUser$.pipe(
      switchMap(() =>
        httpsCallable<void, string | undefined>('http-editor-getAIToken')()
      ),
      shareReplayHot(this._onDestroy$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
