import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { ISODateType, toISODate, toMoment } from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'lu-user-goal-due-date-indicator',
    templateUrl: './user-goal-due-date-indicator.component.html',
    styleUrls: ['./user-goal-due-date-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class UserGoalDueDateIndicatorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  canEdit$ = new BehaviorSubject<boolean>(false);
  dueDate$ = new ReplaySubject<ISODateType | undefined>(1);
  dueDateCtrl = new TypedFormControl<Moment>();
  @Input() placement: 'before' | 'after' = 'before';
  @Output() dateChange = new EventEmitter<ISODateType | undefined>();

  @Input()
  set dueDate(dueDate: ISODateType | undefined) {
    this.dueDate$.next(dueDate);
  }

  @Input()
  set canEdit(canEdit: BooleanInput) {
    this.canEdit$.next(coerceBooleanProperty(canEdit));
  }

  constructor() {
    this.dueDate$.pipe(takeUntil(this._onDestroy$)).subscribe((dueDate) => {
      if (dueDate) {
        this.dueDateCtrl.setValue(toMoment(dueDate));
        return;
      }
      this.dueDateCtrl.reset(undefined);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDueDate(event: MatDatepickerInputEvent<Moment>): void {
    const dueDate = event.value ? toISODate(event.value) : undefined;
    this.dateChange.emit(dueDate);
  }
}
