import { type DocumentReference } from '@principle-theorem/shared';
import { type IReffable, type WithRef } from '@principle-theorem/shared';
import { type ITag } from '@principle-theorem/level-up-core';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IHasTagRefs {
  tags: DocumentReference<ITag>[];
}

export class TagFilter<T extends IHasTagRefs & IReffable> {
  results$: Observable<T[]>;

  constructor(
    items$: Observable<T[]>,
    selectedTags$: Observable<WithRef<ITag>[]>
  ) {
    this.results$ = combineLatest([selectedTags$, items$]).pipe(
      map(([tags, pathways]: [WithRef<ITag>[], T[]]) => {
        const selectedTagPaths: string[] = tags.map((tag) => tag.ref.path);
        if (!tags.length) {
          return pathways;
        }
        return pathways.filter((pathway: T) => {
          const pathwayTagPaths: string[] = pathway.tags.map(
            (tagRef) => tagRef.path
          );
          return selectedTagPaths.every((selectedTagPath: string) => {
            return pathwayTagPaths.includes(selectedTagPath);
          });
        });
      })
    );
  }
}
