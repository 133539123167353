<mat-list-item *ngIf="pathway$ | async as pathway">
  <span matListItemTitle>
    <button mat-icon-button (click)="content.toggle()">
      <mat-icon>{{
        content.expanded ? 'expand_less' : 'expand_more'
      }}</mat-icon>
    </button>
    <a [routerLink]="['/explore/pathway', pathway.ref.id]">{{
      pathway.name
    }}</a>
  </span>
  <div matListItemLine>
    <lu-tag-display [tagRefs]="pathway.tags" />
  </div>

  <ng-container *ngIf="pathway.author">
    <ng-container *ngIf="pathway.author | refItem$ | async as author">
      <div class="user-icon-container">
        <pt-user-icon
          [src]="author | profileImage$ | async"
          [diameter]="30"
          [name]="author.name"
          [matTooltip]="author.name"
         />
      </div>
    </ng-container>
  </ng-container>

  <lu-user-pathway-progress
    [user]="user$ | async"
    [pathway]="pathway"
   />
</mat-list-item>

<pt-collapsible #content>
  <ng-template ptCollapsibleContent>
    <mat-list
      class="with-dividers"
      *ngFor="let skill of pathwaySkills$ | async; trackBy: trackBySkill"
    >
      <lu-skill-list-item [skill]="skill" [user]="user$ | async" />
    </mat-list>
  </ng-template>
</pt-collapsible>
