import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type ISkill } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';

export interface ISkillViewDialogData {
  skill: WithRef<ISkill>;
}

@Component({
    selector: 'lu-skill-view-dialog',
    templateUrl: './skill-view-dialog.component.html',
    styleUrls: ['./skill-view-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillViewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISkillViewDialogData,
    public dialogRef: MatDialogRef<SkillViewDialogComponent>
  ) {}
}
