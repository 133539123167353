import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
  selector: 'lu-search-placeholder',
  templateUrl: './search-placeholder.component.html',
  styleUrls: ['./search-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPlaceholderComponent {
  trackByIndex = TrackByFunctions.index<number>();
  rows = new Array(20);
}
