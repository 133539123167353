import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFolder } from '@principle-theorem/level-up-core';
import {
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';

export type FolderFormData = Pick<IFolder, 'name' | 'parentFolderRef'>;

export interface IFolderAddDialogData {
  name?: string;
  parentFolderRef?: DocumentReference<IFolder>;
}

@Component({
    selector: 'lu-folder-add-dialog',
    templateUrl: './folder-add-dialog.component.html',
    styleUrls: ['./folder-add-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FolderAddDialogComponent {
  form: TypedFormGroup<FolderFormData> = new TypedFormGroup<FolderFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    parentFolderRef: new TypedFormControl<DocumentReference<IFolder>>(),
  });
  isDisabled$ = isDisabled$(this.form);

  constructor(
    private _dialogRef: MatDialogRef<
      FolderAddDialogComponent,
      FolderFormData | undefined
    >,
    @Inject(MAT_DIALOG_DATA) data: IFolderAddDialogData
  ) {
    this.form.patchValue(data);
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
