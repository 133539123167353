import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pt-dynamic-sidebar-footer',
    templateUrl: './dynamic-sidebar-footer.component.html',
    styleUrls: ['./dynamic-sidebar-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DynamicSidebarFooterComponent {}
