import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { IUserGroup } from '../../models/user-group/user-group';
import { IUser } from '../../models/user/user';
import { ISkill } from '../../models/skill/skill';
import { IPathway } from '../../models/pathway/pathway';
import { SkillLevel } from '../../models/skill/skill-level';

export interface IIncreasedSkillLevel {
  skill: INamedDocument<ISkill>;
  level: SkillLevel;
}

export interface IUserGroupChangeResources {
  userGroup: INamedDocument<IUserGroup>;
  allUsers: INamedDocument<IUser>[];
  addedUsers: INamedDocument<IUser>[];
  addedSkills: INamedDocument<ISkill>[];
  addedPathways: INamedDocument<IPathway>[];
  increasedSkillLevels: IIncreasedSkillLevel[];
  groupSkillAssociationCount: number;
  groupPathwayAssociationCount: number;
}

export function isUserGroupChangeData(
  data: unknown
): data is IUserGroupChangeResources {
  return (
    isObject(data) &&
    isINamedDocument(data.userGroup) &&
    Array.isArray(data.allUsers) &&
    Array.isArray(data.addedUsers) &&
    Array.isArray(data.addedSkills) &&
    Array.isArray(data.addedPathways) &&
    Array.isArray(data.increasedSkillLevels) &&
    isNumber(data.groupSkillAssociationCount) &&
    isNumber(data.groupPathwayAssociationCount)
  );
}
