import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type ObjectOfType } from '@principle-theorem/shared';
import { sum } from 'lodash';
import {
  AchievementRequirement,
  type IAchievementRequirement,
} from './achievement-requirement';

export interface IAchievement {
  name: string;
  key: string;
  priority: number;
  requirements: IAchievementRequirement[];
}

export interface IAchievementUnlock {
  achievementRef: DocumentReference<IAchievement>;
  date: Timestamp;
}

export class Achievement {
  static init(overrides: Partial<IAchievement>): IAchievement {
    return {
      key: '',
      name: '',
      requirements: [],
      priority: 0,
      ...overrides,
    };
  }

  static getAchievementProgress(
    achievement: IAchievement,
    stats: Partial<ObjectOfType<number>>
  ): number {
    return sum(
      achievement.requirements.map((req) =>
        AchievementRequirement.getRequirementProgress(req, stats)
      )
    );
  }

  static getAchievementTotal(achievement: IAchievement): number {
    return sum(achievement.requirements.map((req) => req.value));
  }

  static isAchievementSatisfied(
    achievement: IAchievement,
    stats: Partial<ObjectOfType<number>>
  ): boolean {
    return achievement.requirements.every((req) =>
      AchievementRequirement.isRequirementSatisfied(req, stats)
    );
  }
}
