<button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Filter by Type">
  <mat-icon>filter_list</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <mat-selection-list (selectionChange)="updateSelected($event)">
    <ng-container
      *ngFor="
        let notificationType of filters$ | async;
        trackBy: trackByNotificationType
      "
    >
      <mat-list-option
        checkboxPosition="before"
        [value]="notificationType"
        (click)="$event.stopPropagation()"
      >
        {{ notificationType | splitCamel | titlecase }}
      </mat-list-option>
    </ng-container>
  </mat-selection-list>
</mat-menu>
