import { Pipe, type PipeTransform } from '@angular/core';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'inArray$',
    standalone: false
})
export class InArrayPipe implements PipeTransform {
  transform<T, R>(
    value: T,
    array$: Observable<R[]>,
    includeFn?: (value: T, array: R[]) => boolean
  ): Observable<boolean> {
    return array$.pipe(
      map((array) =>
        includeFn
          ? includeFn(value, array)
          : array.map((arrayItem) => String(arrayItem)).includes(String(value))
      )
    );
  }
}
