<ng-container *ngIf="pathway$ | async as pathway">
  <div>
    <h2 class="mat-headline-6">Teams</h2>
    <p>
      Select which teams require this pathway and set their expected competency
      level.
    </p>
  </div>

  <lu-group-association-selector
    [existingGroups]="existingGroups$ | async"
    (add)="addGroup($event, pathway)"
  />

  <ng-container *ngIf="groups$ | async as groups">
    <mat-list *ngIf="groups.length">
      <mat-list-item *ngFor="let group of groups; trackBy: trackByGroup">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <div>
            <a [routerLink]="['/admin/teams', group.ref.id]">
              {{ group.name }}
            </a>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button
              mat-icon-button
              color="warn"
              (click)="removeGroup(group, pathway)"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ng-container>
