import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type AtLeast, isArray } from '@principle-theorem/shared';
import {
  alertDialogData,
  type IAlertDialogData,
} from '../alert-dialog/alert-dialog.component';
import { TrackByFunctions } from '../track-by';

export interface IConfirmationDialogData extends IAlertDialogData {
  cancelLabel: string;
}

export type IConfirmationDialogInput = AtLeast<
  IConfirmationDialogData,
  'title' | 'prompt'
>;

export function confirmationDialogData(
  data: IConfirmationDialogInput
): IConfirmationDialogData {
  return {
    ...alertDialogData(data),
    cancelLabel: 'Cancel',
    ...data,
  };
}

@Component({
    selector: 'pt-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmDialogComponent {
  data: IConfirmationDialogData;
  trackByLine = TrackByFunctions.variable<string>();
  lines: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: IConfirmationDialogInput
  ) {
    this.data = confirmationDialogData(data);
    this.lines = isArray(data.prompt) ? data.prompt : [data.prompt];
  }
}
