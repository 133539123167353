import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-content-error',
    templateUrl: './content-error.component.html',
    styleUrls: ['./content-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContentErrorComponent {}
