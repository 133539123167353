import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ReplaySubject, type Observable } from 'rxjs';

@Component({
  selector: 'lu-read-only-icon',
  templateUrl: './read-only-icon.component.html',
  styleUrls: ['./read-only-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadOnlyIconComponent {
  allowedGroupsSummary$: Observable<string | undefined>;
  readOnly$ = new ReplaySubject<boolean>(1);
  @Input()
  size: string = '16px';

  @Input()
  @HostBinding('style.color')
  colour: string;
}
