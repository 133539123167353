import {
  animate,
  sequence,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { type ThemePalette } from '@angular/material/core';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pt-loader-button',
    templateUrl: './loader-button.component.html',
    styleUrls: ['./loader-button.component.scss'],
    animations: [
        trigger('loaderAnimation', [
            state('hide', style({
                width: '0px',
                opacity: '0',
                'margin-right': '0px',
            })),
            state('show', style({
                width: '20px',
                opacity: '1',
                'margin-right': '8px',
            })),
            transition('hide => show', sequence([
                animate('200ms', style({
                    width: '20px',
                    'margin-right': '8px',
                })),
                animate('200ms', style({
                    opacity: '1',
                })),
            ])),
            transition('show => hide', sequence([
                animate('200ms', style({
                    opacity: '0',
                })),
                animate('200ms', style({
                    width: '0px',
                    'margin-right': '0px',
                })),
            ])),
        ]),
    ],
    standalone: false
})
export class LoaderButtonComponent {
  private _disabledChange$ = new ReplaySubject<boolean>(1);
  loading$ = new ReplaySubject<boolean>(1);
  disabled$: Observable<boolean>;
  color$ = new ReplaySubject<ThemePalette | undefined>(1);
  @Input() type?: 'stroked' | 'flat' | 'raised';
  @Input() @HostBinding('class.cta') cta = false;
  @ViewChild(MatButton) button: MatButton;
  @Output() clicked = new EventEmitter<void>();

  constructor() {
    this.disabled$ = combineLatest([this._disabledChange$, this.loading$]).pipe(
      map(([disabled, loading]) => (disabled ? disabled : loading))
    );
  }

  @Input()
  set color(color: ThemePalette | undefined) {
    this.color$.next(color);
  }

  @Input()
  set loading(loading: boolean) {
    this.loading$.next(loading);
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabledChange$.next(disabled);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.button.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }

    this.clicked.emit();
  }
}
