<div *ngIf="skill$ | async as skill" fxLayout="column" fxLayoutGap="16px">
  <lu-toolbar>
    <div
      fxFlex.gt-xs
      fxFill.lt-sm
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutGap.gt-xs="32px"
    >
      <ng-container *ngIf="isLocalSkill$ | async">
        <lu-toolbar-segment *ngIf="skillProgress.goalLevel$ | async as goal">
          <label>Goal</label>
          {{ goal | splitCamel | titlecase }}
        </lu-toolbar-segment>

        <lu-toolbar-segment
          *ngIf="skill.status === skillStatus.Draft; else otherStatuses"
        >
          <label>Status</label>
          <span
            class="rounded-lg bg-red-100 px-2 py-1 font-extrabold text-red-500"
          >
            {{ skill.status | titlecase }}
          </span>
        </lu-toolbar-segment>
        <ng-template #otherStatuses>
          <lu-toolbar-segment>
            <label>Status</label>
            {{ skill.status | titlecase }}
          </lu-toolbar-segment>
        </ng-template>
      </ng-container>

      <lu-toolbar-segment>
        <label>Last Updated</label>
        {{ skill.updatedAt | toDate | date }}
      </lu-toolbar-segment>

      <lu-toolbar-segment *ngIf="skill.author | refItem$ | async as author">
        <label>Creator</label>
        {{ author.name || 'Unknown' }}
      </lu-toolbar-segment>
    </div>

    <div class="flex items-center justify-between gap-4">
      <ng-container *luIsAdmin>
        <div
          class="flex items-stretch"
          [matTooltip]="copiedSkillTooltip$ | async"
          [matTooltipDisabled]="(isAlreadyCopied$ | async) === false"
        >
          <button
            *ngIf="isMarketplaceVersion$ | async"
            mat-flat-button
            color="primary"
            [disabled]="isAlreadyCopied$ | async"
            (click)="copyToFolder(skill)"
          >
            <mat-icon>drive_file_move</mat-icon>
            <span>Copy to Folder</span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="isLocalSkill$ | async">
        <ng-container *ngIf="skill | canEdit$ | async">
          <button mat-stroked-button [routerLink]="['/create', skill.ref.id]">
            Edit
          </button>
        </ng-container>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="font-bold">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button class="warn" mat-menu-item (click)="reset()">
            <span>Reset Progress</span>
          </button>
          <button
            *ngIf="skill | isApproved"
            mat-menu-item
            (click)="viewTeamProgress(skill)"
          >
            View Team Progress
          </button>
          <ng-container *luIsAdmin>
            <button
              *ngIf="skill | isApproved"
              mat-menu-item
              (click)="manageTrainersForSkill(skill)"
            >
              View Trainers
            </button>
            <button
              *ngIf="skill | inReview"
              mat-menu-item
              color="primary"
              (click)="approve(skill)"
            >
              Approve and Publish
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </div>
  </lu-toolbar>

  <div>
    <lu-tag-display [responsive]="false" [tagRefs]="skill.tags" />
  </div>

  <lu-content-editor-view
    [content]="skill.content"
    (contentError)="handleContentError('Issue rendering skill')"
  />

  <ng-container *ngIf="attachments$ | async as attachments">
    <div *ngIf="attachments.length">
      <h3 class="mat-headline-6">Attachments</h3>
      <pt-attachments
        [uploadPath]="organisation.storagePath$ | async"
        [editable]="false"
        [attachments]="attachments"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="organisation.user$ | async as user">
    <div
      *ngIf="skill | canReview$ | async"
      fxLayout="column"
      fxLayoutGap="16px"
    >
      <h3 class="mat-headline-6">Add Review</h3>
      <div>
        <lu-content-editor
          fxFlex
          [storagePath]="organisation.storagePath$ | async"
          [control]="comments"
          #editor="luContentEditor"
          (contentError)="handleContentError()"
        />
      </div>
      <div
        fxLayout="column-reverse"
        fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center"
      >
        <div *ngIf="pendingReviewers$ | async as pendingReviewers">
          <p>
            Pending Reviewers:
            <span
              fxHide.lt-sm
              *ngFor="
                let reviewer of pendingReviewers;
                last as isLast;
                trackBy: trackByReviewer
              "
            >
              <ng-container *ngIf="reviewer | refItem$ | async as reviewer">
                {{ reviewer.name || 'Unknown' }}<span *ngIf="!isLast">,</span>
              </ng-container>
            </span>
          </p>

          <ul class="list-inside list-disc" fxHide.gt-xs>
            <li
              *ngFor="
                let reviewer of pendingReviewers;
                trackBy: trackByReviewer
              "
            >
              <ng-container *ngIf="reviewer | refItem$ | async as reviewer">
                {{ reviewer.name || 'Unknown' }}
              </ng-container>
            </li>
          </ul>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
          <button
            mat-button
            (click)="addReview(skill, user)"
            [disabled]="disableRequest$ | async"
          >
            Request Changes
          </button>
          <button
            [disabled]="hasApproved$ | async"
            mat-flat-button
            color="primary"
            (click)="addReview(skill, user, true)"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isInReview$ | async">
    <ng-container *ngIf="reviews$ | async as reviews">
      <ng-container *ngIf="reviews.length">
        <h3 class="mat-headline-6">Reviews</h3>
        <lu-skill-reviews
          [reviews]="reviews"
          (handleContentError)="handleContentError($event)"
        />
      </ng-container>
    </ng-container>
  </ng-container>
</div>
