import { Pipe, PipeTransform } from '@angular/core';
import { splitSnake } from '@principle-theorem/shared';

@Pipe({
  name: 'splitSnake',
  standalone: false,
})
export class SplitSnakePipe implements PipeTransform {
  transform(value: string): string {
    return splitSnake(value);
  }
}
