<mat-sidenav-container *ngIf="sidebar.state$ | async as state">
  <mat-sidenav
    class="navigaton !rounded-r-lg"
    [mode]="state.mode"
    [opened]="state.opened"
    [fixedInViewport]="true"
    (closedStart)="sidebar.close()"
  >
    <lu-sidebar />
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
