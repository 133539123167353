import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  inject,
  Output,
} from '@angular/core';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-user-progress-list-item',
  templateUrl: './user-progress-list-item.component.html',
  styleUrls: ['./user-progress-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProgressListItemComponent {
  profileImage = inject(ProfileImageService);
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  expanded$ = new BehaviorSubject<boolean>(false);
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  skills$ = new ReplaySubject<WithRef<ISkill>[]>(1);
  @Input() label: string;
  @Output() expand = new EventEmitter<void>();
  @Output() collapse = new EventEmitter<void>();

  @Input()
  set expanded(expanded: BooleanInput) {
    this.expanded$.next(coerceBooleanProperty(expanded));
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set skills(skills: WithRef<ISkill>[]) {
    if (skills) {
      this.skills$.next(skills);
    }
  }

  setExpand(): void {
    this.expand.next();
  }

  setCollapse(): void {
    this.collapse.next();
  }
}
