<div fxLayout="row wrap" fxLayoutGap="16px">
  <ng-content select="[luPageToolbarAction]"></ng-content>

  <div class="search" fxFlex>
    <ng-content select="pt-search-field"></ng-content>
    <ng-content select="lu-tag-filter"></ng-content>
  </div>

  <ng-content></ng-content>
</div>
