<div class="wrapper">
  <div fxLayout="column" fxLayoutGap="16px">
    <div class="search-bar" fxLayout="row" fxLayoutGap="16px">
      <pt-search-field fxFlex>
        <input
          matInput
          type="text"
          placeholder="Search"
          [formControl]="search"
        />
      </pt-search-field>
      <lu-notification-type-filter
        (selectionChange)="filterResults($event)"
       />
    </div>

    <div class="mat-elevation-z1">
      <div fxLayout="column">
        <ng-container
          *ngIf="
            filteredNotifications$ | async as notifications;
            else noNotifications
          "
        >
          <pt-notifications
            [currentUser]="organisation.user$ | async"
            [notifications]="notifications"
            [extensions]="notificationExtensions"
           />
        </ng-container>

        <ng-template #noNotifications>
          <div fxLayout="row" fxLayoutAlign="center">
            <small class="no-notifications">No Notifications</small>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
