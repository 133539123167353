<ng-container *ngIf="(disabled$ | async) === false; else isDisabled">
  <div *ngIf="skill$ | async as skill" class="h-full">
    <!-- Desktop -->
    <mat-drawer-container
      *ngIf="sidebar.isDesktop$ | async; else mobileLayout"
      fxFill
    >
      <mat-drawer mode="side" position="end" opened disableClose>
        <div class="w-96 max-w-sm">
          <ng-container
            [ngTemplateOutlet]="skillEditForm"
            [ngTemplateOutletContext]="{ skill }"
          />
        </div>
      </mat-drawer>
      <mat-drawer-content class="flex-1 grow !overflow-visible bg-white">
        <ng-container [ngTemplateOutlet]="editor" />
      </mat-drawer-content>
    </mat-drawer-container>

    <!-- Mobile -->
    <ng-template #mobileLayout>
      <div class="flex h-full grow flex-col gap-4">
        <ng-container
          [ngTemplateOutlet]="skillEditForm"
          [ngTemplateOutletContext]="{ skill }"
        />
        <ng-container [ngTemplateOutlet]="editor" />
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #isDisabled>
  <h3 class="mat-subtitle-1">
    Skill is marked as read only so cannot be edited
  </h3>
</ng-template>

<ng-template #editor>
  <div
    class="mb-20 flex h-full flex-1 grow flex-col pb-2 pr-2 pt-1"
    [ngClass]="{ 'min-h-screen': (sidebar.isDesktop$ | async) === false }"
    ptIntercomTarget="skill-edit-form.editor"
  >
    <lu-content-editor
      class="grow"
      [storagePath]="org.storagePath$ | async"
      [control]="skillForm.controls.content"
      (contentError)="handleContentError()"
    />
  </div>
</ng-template>

<ng-template #skillEditForm let-skill="skill">
  <form [formGroup]="skillForm" class="flex flex-col">
    <div class="content flex w-full grow flex-col p-2">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>

      <lu-folder-selector
        [selectedRef]="skillForm.controls.folderRef.value"
        (add)="selectFolder($event)"
      />

      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex ptIntercomTarget="skill-edit-form.option-author">
          <mat-label>Creator</mat-label>
          <mat-select formControlName="author" [compareWith]="compareWithFn">
            <mat-option
              *ngFor="let author of authors$ | async; trackBy: trackByAuthor"
              [value]="author.ref"
            >
              {{ author.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            matIconSuffix
            matTooltip="Author this skill yourself or click to delegate to another user"
            >help_outline</mat-icon
          >
        </mat-form-field>
      </div>

      <div ptIntercomTarget="skill-edit-form.tag-autocomplete">
        <lu-tag-autocomplete
          [tags]="namedDocsToTags.tags$ | async"
          (tagsChange)="updateTags($event)"
        />
      </div>

      <div fxLayout="row">
        <mat-form-field
          fxFill
          ptIntercomTarget="skill-edit-form.option-reviewer"
        >
          <mat-label>Select Reviewers</mat-label>
          <mat-select
            formControlName="reviewers"
            [compareWith]="compareWithFn"
            multiple
          >
            <mat-option
              *ngFor="let reviewer of reviewers; trackBy: trackByUser"
              [value]="reviewer.ref"
            >
              {{ reviewer.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            matIconSuffix
            matTooltip="This skill will be verified and approved by the reviewer(s)"
            >help_outline</mat-icon
          >
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field
          fxFill
          ptIntercomTarget="skill-edit-form.option-reviewer"
        >
          <mat-label>Is a Trainer Required?</mat-label>
          <mat-select formControlName="requiresTrainerVerification">
            <mat-option [value]="false"
              >No. A User can verify their own training by marking that they've
              viewed the skill.</mat-option
            >
            <mat-option [value]="true"
              >Yes. A Trainer needs to sign off on a user's training for this
              skill.</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div ptIntercomTarget="skill-edit-form.attachments" class="pb-4">
        <h2 class="mat-headline-6">Attachments</h2>
        <pt-attachments
          [uploadPath]="org.storagePath$ | async"
          [attachments]="attachments$ | async"
          (attachmentAdded)="skillAttachments.addAttachment(skill, $event)"
          (attachmentRemoved)="skillAttachments.removeAttachment($event)"
        />
      </div>

      <div ptIntercomTarget="skill-edit-form.group-association" class="pb-4">
        <lu-group-association
          [skill]="skill"
          [requiresTrainerVerification]="
            skillForm.controls.requiresTrainerVerification.value
          "
        />
      </div>

      <ng-container *luIsAdmin>
        <div ptIntercomTarget="skill-edit.permission-selector">
          <lu-permission-selector [item]="skill" />
        </div>
      </ng-container>

      <pt-page-footer class="!fixed bottom-0 right-0 w-full sm:absolute">
        <ng-content />
      </pt-page-footer>
    </div>
  </form>
</ng-template>
