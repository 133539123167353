<div>
  <lu-stat [colour]="colour">{{ stats.goals.skills.current$ | async }}</lu-stat>
  of
  <lu-stat [colour]="colour">{{ stats.goals.skills.total$ | async }}</lu-stat>
  skills completed
</div>
<mat-progress-bar
  mode="determinate"
  [color]="colour"
  [value]="stats.goals.skills.progress$ | async"
 />
