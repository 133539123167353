import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFolder } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { DocumentReference } from '@principle-theorem/shared';

export interface IMarketplaceCopyDialogData {
  copyType: 'skill' | 'pathway' | 'bundle';
}

export interface IMarketplaceCopyReturnData {
  folderRef?: DocumentReference<IFolder>;
  createSubFolders: boolean;
}

@Component({
    selector: 'lu-marketplace-copy-to-folder-dialog',
    templateUrl: './marketplace-copy-to-folder-dialog.component.html',
    styleUrls: ['./marketplace-copy-to-folder-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MarketplaceCopyToFolderDialogComponent {
  form = new TypedFormGroup<IMarketplaceCopyReturnData>({
    folderRef: new TypedFormControl<DocumentReference<IFolder>>(),
    createSubFolders: new TypedFormControl<boolean>(true),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMarketplaceCopyDialogData,
    private _dialogRef: MatDialogRef<
      MarketplaceCopyToFolderDialogComponent,
      IMarketplaceCopyReturnData
    >
  ) {}

  save(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
