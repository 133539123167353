import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgNotificationsModule } from '@principle-theorem/ng-notifications';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AuthModule } from '../auth/auth.module';
import { PipesModule } from '../pipes/pipes.module';
import { AchievementProgressComponent } from './achievements/achievement-progress/achievement-progress.component';
import { AchievementComponent } from './achievements/achievement/achievement.component';
import { AssigneeUserSelectorComponent } from './assignee-user-selector/assignee-user-selector.component';
import { AuthorSkillListComponent } from './author-skill-list/author-skill-list.component';
import { AuthorComponent } from './card/author/author.component';
import { CardActionsComponent } from './card/card-actions/card-actions.component';
import { TagsComponent } from './card/card-actions/tags/tags.component';
import { CardBannerComponent } from './card/card-banner/card-banner.component';
import { CardContainerComponent } from './card/card-container/card-container.component';
import { CardImageComponent } from './card/card-image/card-image.component';
import { CardLayoutComponent } from './card/card-layout/card-layout.component';
import { PathwayCardComponent } from './card/card-type/pathway-card/pathway-card.component';
import { PublicPathwayCardComponent } from './card/card-type/public-pathway-card/public-pathway-card.component';
import { PublicSkillCardComponent } from './card/card-type/public-skill-card/public-skill-card.component';
import { SkillCardComponent } from './card/card-type/skill-card/skill-card.component';
import { SubscriptionCardComponent } from './card/card-type/subscription-card/subscription-card.component';
import { VendorBundleCardComponent } from './card/card-type/vendor-bundle-card/vendor-bundle-card.component';
import { CardComponent } from './card/card.component';
import { ProgressBarComponent } from './card/progress-bar/progress-bar.component';
import { ContentEditorViewComponent } from './content-editor-view/content-editor-view.component';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { ContentErrorComponent } from './content-error/content-error.component';
import { ContentTypeIconComponent } from './content-type-icon/content-type-icon.component';
import { FavouriteIndicatorComponent } from './favourite-indicator/favourite-indicator.component';
import { FavouritesListComponent } from './favourites/favourites-list/favourites-list.component';
import { FolderAddDialogComponent } from './folders/folder-add-dialog/folder-add-dialog.component';
import { FolderEditDialogComponent } from './folders/folder-edit-dialog/folder-edit-dialog.component';
import { FolderSelectorComponent } from './folders/folder-selector/folder-selector.component';
import { FolderListMultiActionToolbarComponent } from './folders/folders-list/folder-list-multi-action-toolbar/folder-list-multi-action-toolbar.component';
import { FoldersListComponent } from './folders/folders-list/folders-list.component';
import { GoalDueDateIndicatorComponent } from './goal-due-date-indicator/goal-due-date-indicator.component';
import { GroupAssociationSelectorComponent } from './group-association-selector/group-association-selector.component';
import { LeaderboardEntryComponent } from './leaderboard/leaderboard-entry/leaderboard-entry.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { LevelConfirmProgressButtonComponent } from './level-confirm-progress-button/level-confirm-progress-button.component';
import { LevelIndicatorComponent } from './level-indicator/level-indicator.component';
import { LevelResetProgressButtonComponent } from './level-reset-progress-button/level-reset-progress-button.component';
import { LevelSelectorComponent } from './level-selector/level-selector.component';
import { ListHeadingComponent } from './list-heading/list-heading.component';
import { ListTitleComponent } from './list-title/list-title.component';
import { LogoComponent } from './logo/logo.component';
import { AddMarketplaceItemDialogComponent } from './marketplace/add-marketplace-item-dialog/add-marketplace-item-dialog.component';
import { AddSubscriberDialogComponent } from './marketplace/add-subscriber-dialog/add-subscriber-dialog.component';
import { AddVendorDialogComponent } from './marketplace/add-vendor-dialog/add-vendor-dialog.component';
import { EditVendorBundleDialogComponent } from './marketplace/edit-vendor-bundle/edit-vendor-bundle-dialog.component';
import { MarketplaceCopyToFolderDialogComponent } from './marketplace/marketplace-copy-to-folder-dialog/marketplace-copy-to-folder-dialog.component';
import { ReleaseStatusComponent } from './marketplace/release-status/release-status.component';
import { SubscriptionManagerComponent } from './marketplace/subscription-manager/subscription-manager.component';
import { VendorBundleIconsComponent } from './marketplace/vendor-bundle-icons/vendor-bundle-icons.component';
import { VendorBundleListComponent } from './marketplace/vendor-bundle-list/vendor-bundle-list.component';
import { VendorSkillAssociateDialogComponent } from './marketplace/vendor-skill-associate-dialog/vendor-skill-associate-dialog.component';
import { MentionActionResolverService } from './mention/mention-action-resolver.service';
import { MentionButtonsComponent } from './mention/mention-buttons/mention-buttons.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UserProfileMenuComponent } from './navbar/user-profile-menu/user-profile-menu.component';
import { NotificationDialogComponent } from './notifications/notification-dialog/notification-dialog.component';
import { NotificationTypeFilterComponent } from './notifications/notification-type-filter/notification-type-filter.component';
import { NotificationsSidebarComponent } from './notifications/notifications-sidebar/notifications-sidebar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OrganisationStatusComponent } from './organisation-status/organisation-status.component';
import { HeaderButtonComponent } from './page-header/header-button/header-button.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageSimpleComponent } from './page-simple/page-simple.component';
import { PageSplashComponent } from './page-splash/page-splash.component';
import { PageTemplatesModule } from './page-templates/page-templates.module';
import { PageToolbarActionDirective } from './page-toolbar/page-toolbar-action.directive';
import { PageToolbarComponent } from './page-toolbar/page-toolbar.component';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { PathwayListCardComponent } from './pathway-list-card/pathway-list-card.component';
import { UserPathwayGoalsIndicatorComponent } from './pathway-list-card/user-pathway-goals-indicator/user-pathway-goals-indicator.component';
import { PathwayProgressComponent } from './pathway-progress/pathway-progress.component';
import { PathwaySkillListComponent } from './pathway-skill-list/pathway-skill-list.component';
import { PermissionSelectorComponent } from './permission-selector/permission-selector.component';
import { PlaceholdersModule } from './placeholders/placeholders.module';
import { PrivacyIconComponent } from './privacy-icon/privacy-icon.component';
import { RecentlyViewedListComponent } from './recently-viewed-list/recently-viewed-list.component';
import { SearchButtonComponent } from './search-button/search-button.component';
import { CreateSkillActionService } from './search-dialog/create-skill-action.service';
import { SearchComponent } from './search-dialog/search.component';
import { SearchPlaceholderComponent } from './search-placeholder/search-placeholder.component';
import { SectionEditComponent } from './sections/section-edit/section-edit.component';
import { SectionViewComponent } from './sections/section-view/section-view.component';
import { SkillStatusComponent } from './skill-status/skill-status.component';
import { GroupAssociationComponent } from './skills/group-association/group-association.component';
import { LevelUpRequestMentorDialogComponent } from './skills/level-up-request-mentor-dialog/level-up-request-mentor-dialog.component';
import { MentorUserItemComponent } from './skills/mentor-user-item/mentor-user-item.component';
import { PathwayAddDialogComponent } from './skills/pathway-add-dialog/pathway-add-dialog.component';
import { ExploreBundlesListComponent } from './skills/pathway-and-skills-list/explore-bundles-list/explore-bundles-list.component';
import { ExplorePathwaysListComponent } from './skills/pathway-and-skills-list/explore-pathways-list/explore-pathways-list.component';
import { ExploreSkillsListComponent } from './skills/pathway-and-skills-list/explore-skills-list/explore-skills-list.component';
import { PathwayAndSkillsListService } from './skills/pathway-and-skills-list/pathway-and-skills-list.service';
import { PathwayDisplayComponent } from './skills/pathway-display/pathway-display.component';
import { PathwayGroupAssociationComponent } from './skills/pathway-group-association/pathway-group-association.component';
import { PathwayListComponent } from './skills/pathway-list/pathway-list.component';
import { PathwayViewComponent } from './skills/pathway-view/pathway-view.component';
import { SkillAddDialogComponent } from './skills/skill-add-dialog/skill-add-dialog.component';
import { SkillDisplayComponent } from './skills/skill-display/skill-display.component';
import { SkillEditFormComponent } from './skills/skill-edit-form/skill-edit-form.component';
import { SkillListCardPlaceholderComponent } from './skills/skill-list-card-placeholder/skill-list-card-placeholder.component';
import { SkillListCardComponent } from './skills/skill-list-card/skill-list-card.component';
import { SkillListItemComponent } from './skills/skill-list-item/skill-list-item.component';
import { SkillListComponent } from './skills/skill-list/skill-list.component';
import { SkillMentorDialogComponent } from './skills/skill-mentor-dialog/skill-mentor-dialog.component';
import { MyProgressSummaryComponent } from './skills/skill-progress-actions/my-progress-summary/my-progress-summary.component';
import { SkillLevelToLabelPipe } from './skills/skill-progress-actions/my-progress-summary/skill-level-to-label.pipe';
import { SkillLevelToNumberPipe } from './skills/skill-progress-actions/my-progress-summary/skill-level-to-number.pipe';
import { SkillProgressActionsComponent } from './skills/skill-progress-actions/skill-progress-actions.component';
import { SkillViewComponent } from './skills/skill-view/skill-view.component';
import { SubscriptionListCardComponent } from './skills/subscription-list-card/subscription-list-card.component';
import { SubscriptionListComponent } from './skills/subscription-list/subscription-list.component';
import { UserProgressListItemComponent } from './skills/user-progress-list-item/user-progress-list-item.component';
import { UserSkillListItemComponent } from './skills/user-skill-list-item/user-skill-list-item.component';
import { UsersSkillListComponent } from './skills/users-skill-list/users-skill-list.component';
import { UsersSkillProgressSummaryDialogComponent } from './skills/users-skill-progress-summary-dialog/users-skill-progress-summary-dialog.component';
import { UsersSkillSummaryComponent } from './skills/users-skill-summary/users-skill-summary.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { TagsModule } from './tags/tags.module';
import { TeamProgressComponent } from './team-progress/team-progress.component';
import { ToolbarSegmentComponent } from './toolbar/toolbar-segment/toolbar-segment.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserActionComponent } from './user-actions/user-action/user-action.component';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { UserGoalDueDateIndicatorComponent } from './user-goal-due-date-indicator/user-goal-due-date-indicator.component';
import { UserGroupGoalDueDateIndicatorComponent } from './user-group-due-date-indicator/user-group-goal-due-date-indicator.component';
import { UserInviteComponent } from './user-invite/user-invite.component';
import { UserLevelUpRequestsComponent } from './user-level-up-requests/user-level-up-requests.component';
import { UserListItemComponent } from './user-list-item/user-list-item.component';
import { UserPathwayProgressComponent } from './user-pathway-progress/user-pathway-progress.component';
import { UserSkillLevelIndicatorComponent } from './user-skill-level-indicator/user-skill-level-indicator.component';
import { UserSkillProgressComponent } from './user-skill-progress/user-skill-progress.component';
import { AuthorStatComponent } from './user-statistics/author-stat/author-stat.component';
import { GoalsStatComponent } from './user-statistics/goals-stat/goals-stat.component';
import { MentorStatComponent } from './user-statistics/mentor-stat/mentor-stat.component';
import { ReviewerStatComponent } from './user-statistics/reviewer-stat/reviewer-stat.component';
import { StatComponent } from './user-statistics/stat/stat.component';
import { UserStatisticsComponent } from './user-statistics/user-statistics.component';
import { UsersListComponent } from './users-list/users-list.component';
import { OrganisationWorkspaceSelectorComponent } from './workspace/organisation-workspace-selector/organisation-workspace-selector.component';
import { VendorWorkspaceSelectorComponent } from './workspace/vendor-workspace-selector/vendor-workspace-selector.component';
import { RelativeDueDateDialogComponent } from './skills/relative-due-date-dialog/relative-due-date-dialog.component';
import { FolderLeafNodeComponent } from './folders/folder-leaf-node/folder-leaf-node.component';
import { ReadOnlyIconComponent } from './read-only-icon/read-only-icon.component';
import { NoGoalsStateComponent } from './skills/no-goals-state/no-goals-state.component';
import { SkillReviewsComponent } from './skills/skill-reviews/skill-reviews.component';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    RouterModule,
    NgIntercomComponentsModule,
    TagsModule,
    NgSharedModule,
    PageTemplatesModule,
    ReactiveFormsModule,
    NgFirebaseModule,
    PlaceholdersModule,
    NgEditorModule,
    NgNotificationsModule,
    PipesModule,
    LetDirective,
    PushPipe,
    AuthModule,
  ],
  declarations: [
    PageSimpleComponent,
    PageToolbarComponent,
    StatusLabelComponent,
    PathwayProgressComponent,
    LogoComponent,
    SkillStatusComponent,
    OrganisationStatusComponent,
    UserSkillProgressComponent,
    UserPathwayProgressComponent,
    PageSplashComponent,
    PasswordFormComponent,
    UserStatisticsComponent,
    UserActionsComponent,
    UserActionComponent,
    TeamProgressComponent,
    LeaderboardEntryComponent,
    AuthorStatComponent,
    MentorStatComponent,
    ReviewerStatComponent,
    GoalsStatComponent,
    StatComponent,
    LeaderboardComponent,
    UsersListComponent,
    UserInviteComponent,
    AddMarketplaceItemDialogComponent,
    ContentEditorComponent,
    VendorSkillAssociateDialogComponent,
    ReleaseStatusComponent,
    AddSubscriberDialogComponent,
    PageToolbarActionDirective,
    ToolbarSegmentComponent,
    ToolbarComponent,
    AddVendorDialogComponent,
    EditVendorBundleDialogComponent,
    AuthorSkillListComponent,
    AchievementProgressComponent,
    AchievementComponent,
    ListTitleComponent,
    ListHeadingComponent,
    PageHeaderComponent,
    HeaderButtonComponent,
    NavbarComponent,
    CardComponent,
    TagsComponent,
    ProgressBarComponent,
    AuthorComponent,
    CardContainerComponent,
    SkillCardComponent,
    PathwayCardComponent,
    CardBannerComponent,
    CardComponent,
    CardLayoutComponent,
    CardActionsComponent,
    MentionButtonsComponent,
    NotificationTypeFilterComponent,
    NotificationsSidebarComponent,
    NotificationsComponent,
    SubscriptionCardComponent,
    PageWrapperComponent,
    NotificationDialogComponent,
    ContentErrorComponent,
    ContentEditorViewComponent,
    VendorBundleIconsComponent,
    VendorBundleCardComponent,
    SubscriptionManagerComponent,
    VendorWorkspaceSelectorComponent,
    OrganisationWorkspaceSelectorComponent,
    VendorBundleListComponent,
    LevelIndicatorComponent,
    CardImageComponent,
    UserProfileMenuComponent,
    PublicSkillCardComponent,
    PublicPathwayCardComponent,
    PathwaySkillListComponent,
    PermissionSelectorComponent,
    GroupAssociationSelectorComponent,
    PrivacyIconComponent,
    UserListItemComponent,
    SearchPlaceholderComponent,
    SearchComponent,
    UserLevelUpRequestsComponent,
    LevelUpRequestMentorDialogComponent,
    AssigneeUserSelectorComponent,
    SearchButtonComponent,
    LevelResetProgressButtonComponent,
    LevelConfirmProgressButtonComponent,
    LevelSelectorComponent,
    UserSkillLevelIndicatorComponent,
    SkillListCardComponent,
    SkillListCardPlaceholderComponent,
    PathwayListCardComponent,
    UserPathwayGoalsIndicatorComponent,
    PathwayViewComponent,
    SkillListItemComponent,
    SkillDisplayComponent,
    PathwayDisplayComponent,
    UsersSkillListComponent,
    UserSkillListItemComponent,
    SkillEditFormComponent,
    SkillAddDialogComponent,
    SkillProgressActionsComponent,
    GroupAssociationComponent,
    PathwayGroupAssociationComponent,
    UsersSkillSummaryComponent,
    MyProgressSummaryComponent,
    SkillLevelToNumberPipe,
    SkillLevelToLabelPipe,
    SkillMentorDialogComponent,
    SkillListComponent,
    SubscriptionListComponent,
    PathwayListComponent,
    SkillViewComponent,
    MentorUserItemComponent,
    UserProgressListItemComponent,
    ExploreBundlesListComponent,
    ExploreSkillsListComponent,
    ExplorePathwaysListComponent,
    UsersSkillProgressSummaryDialogComponent,
    GoalDueDateIndicatorComponent,
    UserGoalDueDateIndicatorComponent,
    UserGroupGoalDueDateIndicatorComponent,
    SectionEditComponent,
    SectionViewComponent,
    FavouritesListComponent,
    FoldersListComponent,
    FolderSelectorComponent,
    FolderAddDialogComponent,
    PathwayAddDialogComponent,
    FolderEditDialogComponent,
    MarketplaceCopyToFolderDialogComponent,
    RecentlyViewedListComponent,
    ContentTypeIconComponent,
    FolderListMultiActionToolbarComponent,
    FavouriteIndicatorComponent,
    SubscriptionListCardComponent,
    RelativeDueDateDialogComponent,
    FolderLeafNodeComponent,
    ReadOnlyIconComponent,
    NoGoalsStateComponent,
    SkillReviewsComponent,
  ],
  exports: [
    TagsModule,
    PageSimpleComponent,
    PageSplashComponent,
    PageToolbarComponent,
    StatusLabelComponent,
    PageTemplatesModule,
    PathwayProgressComponent,
    LogoComponent,
    SkillStatusComponent,
    OrganisationStatusComponent,
    UserSkillProgressComponent,
    UserPathwayProgressComponent,
    PasswordFormComponent,
    UserStatisticsComponent,
    UserActionsComponent,
    TeamProgressComponent,
    LeaderboardEntryComponent,
    AuthorStatComponent,
    MentorStatComponent,
    ReviewerStatComponent,
    GoalsStatComponent,
    LeaderboardComponent,
    PlaceholdersModule,
    UsersListComponent,
    UserInviteComponent,
    ContentEditorComponent,
    VendorSkillAssociateDialogComponent,
    ReleaseStatusComponent,
    CardImageComponent,
    ToolbarComponent,
    ToolbarSegmentComponent,
    EditVendorBundleDialogComponent,
    AuthorSkillListComponent,
    AchievementProgressComponent,
    AchievementComponent,
    ListTitleComponent,
    ListHeadingComponent,
    PageHeaderComponent,
    HeaderButtonComponent,
    NavbarComponent,
    CardComponent,
    CardLayoutComponent,
    MentionButtonsComponent,
    NotificationsSidebarComponent,
    NotificationDialogComponent,
    NotificationsComponent,
    ContentErrorComponent,
    ContentEditorViewComponent,
    VendorBundleIconsComponent,
    SubscriptionManagerComponent,
    VendorWorkspaceSelectorComponent,
    OrganisationWorkspaceSelectorComponent,
    VendorBundleListComponent,
    LevelIndicatorComponent,
    UserProfileMenuComponent,
    PublicSkillCardComponent,
    PublicPathwayCardComponent,
    PathwaySkillListComponent,
    PermissionSelectorComponent,
    GroupAssociationSelectorComponent,
    PageWrapperComponent,
    PrivacyIconComponent,
    UserLevelUpRequestsComponent,
    AssigneeUserSelectorComponent,
    SearchButtonComponent,
    LevelSelectorComponent,
    UserSkillLevelIndicatorComponent,
    SkillListCardComponent,
    SkillListCardPlaceholderComponent,
    PathwayListCardComponent,
    PathwayViewComponent,
    SkillListItemComponent,
    SkillDisplayComponent,
    PathwayDisplayComponent,
    UsersSkillListComponent,
    UserSkillListItemComponent,
    SkillEditFormComponent,
    SkillProgressActionsComponent,
    UsersSkillSummaryComponent,
    SkillListComponent,
    SubscriptionListComponent,
    PathwayListComponent,
    SkillViewComponent,
    MentorUserItemComponent,
    UserProgressListItemComponent,
    ExploreBundlesListComponent,
    ExploreSkillsListComponent,
    ExplorePathwaysListComponent,
    GoalDueDateIndicatorComponent,
    UserGoalDueDateIndicatorComponent,
    UserGroupGoalDueDateIndicatorComponent,
    SectionEditComponent,
    SectionViewComponent,
    FavouritesListComponent,
    FoldersListComponent,
    FolderSelectorComponent,
    FolderAddDialogComponent,
    PathwayAddDialogComponent,
    FolderEditDialogComponent,
    MarketplaceCopyToFolderDialogComponent,
    RecentlyViewedListComponent,
    ContentTypeIconComponent,
    FavouriteIndicatorComponent,
    GroupAssociationComponent,
    PathwayGroupAssociationComponent,
    SubscriptionListCardComponent,
    RelativeDueDateDialogComponent,
    ReadOnlyIconComponent,
    NoGoalsStateComponent,
    SkillReviewsComponent,
  ],
  providers: [
    MentionActionResolverService,
    CreateSkillActionService,
    PathwayAndSkillsListService,
  ],
})
export class ComponentsModule {}
