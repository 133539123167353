<mat-toolbar color="primary">Add Organisation</mat-toolbar>

<form class="flex flex-col gap-4 p-4" [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex flex-col" formGroupName="organisation">
    <mat-form-field>
      <mat-label>Workspace Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>

  <div class="flex flex-col gap-4" formGroupName="owner">
    <mat-form-field>
      <mat-label>Owner Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Owner Email</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>
  </div>

  <pt-buttons-container>
    <button mat-stroked-button [disabled]="!canSave()">Save</button>
  </pt-buttons-container>
</form>
