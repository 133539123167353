import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BlockNodes,
  IMenuButton,
  fromEditorEvents,
  getActiveNode,
  nodeTypeIsActive,
} from '@principle-theorem/editor';
import { EditorMenuItemComponent } from '@principle-theorem/ng-prosemirror';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { snapshot, toInt } from '@principle-theorem/shared';
import { Level } from '@tiptap/extension-heading';
import { setBlockType } from '@tiptap/pm/commands';
import { get } from 'lodash';
import { type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-heading-menu',
    templateUrl: './heading-menu.component.html',
    styleUrls: ['./heading-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeadingMenuComponent extends EditorMenuItemComponent {
  trackByLevel = TrackByFunctions.variable<Level>();
  override tooltip = 'Heading';
  levels: Level[] = [1, 2, 3, 4];
  activeTextStyle$: Observable<string>;
  activeHeadingSize$: Observable<number>;
  isActive$: Observable<boolean>;

  constructor() {
    super();
    this.isActive$ = this.editor$.pipe(
      switchMap((editor) =>
        fromEditorEvents(editor).pipe(
          startWith(undefined),
          map(() =>
            nodeTypeIsActive(
              editor.state,
              editor.state.schema.nodes[BlockNodes.Heading]
            )
          )
        )
      )
    );

    this.activeHeadingSize$ = this.editor$.pipe(
      switchMap((editor) => {
        return fromEditorEvents(editor).pipe(
          map(() =>
            getActiveNode(
              editor.state,
              editor.state.schema.nodes[BlockNodes.Heading]
            )
          )
        );
      }),
      map((activeNode) => (activeNode ? toInt(activeNode.attrs.level) : 0))
    );

    this.activeTextStyle$ = this.activeHeadingSize$.pipe(
      map((size) => (size ? `Heading ${size}` : 'Normal Text'))
    );
  }

  async toggleHeading(level: Level): Promise<void> {
    const editor = await snapshot(this.editor$);
    const activeNode =
      getActiveNode(
        editor.state,
        editor.state.schema.nodes[BlockNodes.Paragraph]
      ) ??
      getActiveNode(
        editor.state,
        editor.state.schema.nodes[BlockNodes.Heading]
      );

    const align = String(get(activeNode?.attrs, 'align', 'left'));
    setBlockType(editor.state.schema.nodes[BlockNodes.Heading], {
      level,
      align: align || 'left',
    })(editor.state, editor.view.dispatch);
  }

  async resetFormat(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const activeNode = getActiveNode(
      editor.state,
      editor.state.schema.nodes[BlockNodes.Heading]
    );
    const align = String(get(activeNode?.attrs, 'align', 'left'));

    setBlockType(editor.state.schema.nodes[BlockNodes.Paragraph], {
      align: align || 'left',
    })(editor.state, editor.view.dispatch);
  }
}

export function textDropdownButtons(): IMenuButton[] {
  const levels: Level[] = [1, 2, 3, 4];
  const headingButtons: IMenuButton[] = levels.map((level) => {
    const buttonText = `Heading ${level}`;
    return {
      buttonType: 'submenu-detail',
      buttonText,
      template: `<h${level}>${buttonText}</h${level}>`,
      icon: 'title',
      tooltip: `Shift-Mod-${level}`,
      shortcut: `Shift-Mod-${level}`,
      command: (editor) =>
        editor.chain().focus().toggleHeading({ level }).focus().run(),
      isActiveFn: (editor) => editor.isActive(BlockNodes.Heading, { level }),
    };
  });

  return [
    {
      buttonType: 'submenu-detail',
      buttonText: 'Normal Text',
      tooltip: `Shift-Mod-0`,
      shortcut: `Shift-Mod-0`,
      icon: 'title',
      command: (editor) => editor.chain().focus().setParagraph().focus().run(),
      isActiveFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
    ...headingButtons,
  ];
}
