import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { IUser } from '@principle-theorem/level-up-core';
import { WithRef, filterUndefined } from '@principle-theorem/shared';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';
import { UserSettingsStoreService } from '../../services/user-settings-store.service';
import { WithSidebarMainService } from './with-sidebar-main.service';
import { ILink } from '../sidebar/sidebar-links';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'lu-with-sidebar-main',
    templateUrl: './with-sidebar-main.component.html',
    styleUrls: ['./with-sidebar-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WithSidebarMainComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  user$: Observable<WithRef<IUser>>;
  sidebarTabIndex?: number;
  trackByLink = TrackByFunctions.field<ILink>('name');
  navLinks: ILink[] = [
    {
      name: 'My Learning',
      path: ['/learn'],
      intercomTarget: 'homepage.learn-link',
    },
    {
      name: 'Teach',
      path: ['/teach'],
      intercomTarget: 'homepage.teach-link',
    },
    {
      name: 'Create',
      path: ['/create'],
      intercomTarget: 'homepage.create-link',
    },
  ];

  constructor(
    private _settings: UserSettingsStoreService,
    public sidebar: WithSidebarMainService,
    organisation: OrganisationService
  ) {
    this.user$ = organisation.user$.pipe(filterUndefined());

    this._settings.sidebar$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((sidebarSettings) => {
        const index =
          sidebarSettings.selectedTab === 'folders'
            ? 0
            : sidebarSettings.selectedTab === 'favourites'
            ? 1
            : undefined;

        this.sidebarTabIndex = index;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setSelectedSidebarTab(tabIndex: number): void {
    const selectedTab =
      tabIndex === 0 ? 'folders' : tabIndex === 1 ? 'favourites' : undefined;
    if (!selectedTab) {
      return;
    }

    this._settings.updateUserSettings({
      sidebar: {
        selectedTab,
      },
    });
  }
}
