import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { type DocumentReference } from '@principle-theorem/shared';
import { addDoc, all$, type WithRef } from '@principle-theorem/shared';
import { type IVendor, Vendor } from '@principle-theorem/level-up-core';
import {
  DialogPresets,
  InputSearchFilter,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { startWith } from 'rxjs/operators';
import { ManagementService } from '../../../auth/management.service';
import {
  AddMarketplaceItemDialogComponent,
  type IAddMarketplaceItemData,
  type IMarketplaceItemFormData,
} from '../../../components/marketplace/add-marketplace-item-dialog/add-marketplace-item-dialog.component';

@Component({
    selector: 'lu-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MarketplaceComponent {
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IVendor>>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private _router: Router,
    private _management: ManagementService
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IVendor>>(
      all$(this._management.vendorsCol),
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );
  }

  async add(): Promise<void> {
    const vendor: Pick<IVendor, 'name'> | undefined = await this._dialog
      .open<
        AddMarketplaceItemDialogComponent,
        IAddMarketplaceItemData,
        IMarketplaceItemFormData
      >(
        AddMarketplaceItemDialogComponent,
        DialogPresets.small({
          data: {
            label: 'Vendor',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!vendor) {
      return;
    }

    const vendorRef: DocumentReference<IVendor> = await addDoc(
      this._management.vendorsCol,
      Vendor.init(vendor)
    );

    await this._router.navigate(['./', vendorRef.id], {
      relativeTo: this._route,
    });
    this._snackBar.open('Vendor Added');
  }
}
