import { Pipe, type PipeTransform } from '@angular/core';
import {
  type SkillLevel,
  skillLevelToLabel,
} from '@principle-theorem/level-up-core';

@Pipe({
    name: 'skillLevelToLabel',
    standalone: false
})
export class SkillLevelToLabelPipe implements PipeTransform {
  transform(value: SkillLevel): string {
    return skillLevelToLabel(value);
  }
}
