import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { FormControl } from '@angular/forms';
import { Content, VersionedSchema } from '@principle-theorem/editor';
import {
  BasicMenuButtonComponent,
  EditorComponent,
} from '@principle-theorem/ng-editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import {
  NgFireMediaUploader,
  type TypedAbstractControl,
  type TypedFormControl,
} from '@principle-theorem/ng-shared';
import { shareReplayCold } from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { INgLevelUpConfig, NG_LEVEL_UP_CONFIG } from '../../ng-level-up-config';
import { EditorPresetsService } from '../../services/editor-presets.service';
import { TipTapService } from '../../services/tip-tap.service';

export interface IStorageProvider {
  storagePath$: Observable<string>;
}

@Component({
  selector: 'lu-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'luContentEditor',
})
export class ContentEditorComponent implements OnDestroy {
  private _storagePath$: ReplaySubject<string> = new ReplaySubject(1);
  private _onDestroy$: Subject<void> = new Subject();
  formControl$ = new ReplaySubject<TypedFormControl<VersionedSchema | Content>>(
    1
  );
  extensions$: Observable<AnyExtension[]>;
  menuItems: MenuButtonLoaderFn[] = [];
  slashMenuItems: MenuButtonLoaderFn<BasicMenuButtonComponent>[] = [];
  uploader: NgFireMediaUploader;
  contentValid$: Observable<boolean>;
  @Output() contentError: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(EditorComponent) editor: EditorComponent;

  constructor(
    private _storage: Storage,
    editorPresets: EditorPresetsService,
    tipTap: TipTapService,
    @Inject(NG_LEVEL_UP_CONFIG) private _config: INgLevelUpConfig
  ) {
    this.uploader = new NgFireMediaUploader(this._storage, this._storagePath$);
    this.extensions$ = tipTap.aiToken$.pipe(
      map((aiToken) =>
        editorPresets.defaultExtensions(this._config.tipTap.appId, aiToken)
      ),
      shareReplayCold()
    );
    this.menuItems = editorPresets.defaultMenuItems(this.uploader);
    this.slashMenuItems = editorPresets.blockMenuItems(this.uploader);
    this.contentValid$ = this.contentError.pipe(
      map(() => false),
      startWith(true),
      shareReplayCold()
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  @Input()
  set storagePath(storagePath: string) {
    if (storagePath) {
      this._storagePath$.next(storagePath);
    }
  }

  @Input()
  set control(control: TypedAbstractControl<VersionedSchema | Content>) {
    if (control && control instanceof FormControl) {
      this.formControl$.next(
        control as TypedFormControl<VersionedSchema | Content>
      );
    }
  }
}
