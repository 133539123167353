import { type ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IEditorMentionAttributes } from '@principle-theorem/editor';
import { type MentionResourceType } from '@principle-theorem/level-up-core';
import { type IMentionRenderComponent } from '@principle-theorem/ng-editor';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  getDocRef,
  type IProvider,
  type IRoutingAction,
  snapshot,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MentionActionResolverService } from '../mention-action-resolver.service';

export const MENTION_BUTTON_PROVIDER: IProvider<
  IEditorMentionAttributes,
  ComponentType<IMentionRenderComponent>
> = {
  canProvide: (_data) => true,
  execute: (_data) => MentionButtonsComponent,
};

@Component({
  selector: 'lu-mention-buttons',
  templateUrl: './mention-buttons.component.html',
  styleUrls: ['./mention-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionButtonsComponent implements IEditorMentionAttributes {
  trackByAction = TrackByFunctions.field<IRoutingAction>('name');
  key$: ReplaySubject<string> = new ReplaySubject(1);
  path$: ReplaySubject<string> = new ReplaySubject(1);
  type$: ReplaySubject<MentionResourceType> = new ReplaySubject(1);

  actions$: Observable<IRoutingAction[]>;

  constructor(private _mentionActionResolver: MentionActionResolverService) {
    this.actions$ = this.type$.pipe(
      map((type) => this._mentionActionResolver.resolveActions(type))
    );
  }

  @Input()
  set key(key: string) {
    if (key) {
      this.key$.next(key);
    }
  }

  @Input()
  set path(path: string) {
    if (path) {
      this.path$.next(path);
    }
  }

  @Input()
  set type(type: MentionResourceType) {
    if (type) {
      this.type$.next(type);
    }
  }

  async runAction(action: IRoutingAction): Promise<void> {
    const path = await snapshot(this.path$);

    action.do(getDocRef(path));
  }
}
