<mat-toolbar color="primary">
  <h2>Add to Folder</h2>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form">
    <ng-container
      *ngIf="data.copyType !== 'bundle'; else bundleSelector"
      [ngTemplateOutlet]="folderSelector"
      [ngTemplateOutletContext]="{ form }"
    />

    <ng-template #bundleSelector>
      <mat-slide-toggle
        class="pb-4"
        formControlName="createSubFolders"
        color="primary"
      >
        Auto-create Folders for Bundle Content
      </mat-slide-toggle>
      <ng-container
        *ngIf="!form.controls.createSubFolders.value; else autoFolder"
        [ngTemplateOutlet]="folderSelector"
        [ngTemplateOutletContext]="{ form }"
      />
    </ng-template>

    <ng-template #folderSelector let-form="form">
      <lu-folder-selector
        placeholder="Select a Folder"
        [selectedRef]="form.controls.folderRef.value"
        (add)="form.controls.folderRef.setValue($event)"
      />
    </ng-template>

    <ng-template #autoFolder>
      <div>
        Bundles can automatically add organised folders to your Workspace.
      </div>
    </ng-template>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.invalid"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
