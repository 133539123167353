import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidebarManagerService } from '@principle-theorem/ng-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WithSidebarMainService } from '../../layout/with-sidebar-main/with-sidebar-main.service';

@Component({
    selector: 'lu-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageHeaderComponent {
  @Input() label: string;
  @Input() pageInfo: string;
  @Input() pageTour: number;
  hasSidebarMain$: Observable<boolean>;

  constructor(
    public sidebar: SidebarManagerService,
    public contentSidebar: WithSidebarMainService,
    route: ActivatedRoute
  ) {
    this.hasSidebarMain$ = route.data.pipe(
      map((data) => data['hasSidebarMain'] as boolean)
    );
  }
}
