import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IPathway,
  IFavourite,
  ISkill,
  IUser,
  MentionResourceType,
  isPathway,
  isSkill,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  WithRef,
  getDoc,
  multiConcatMap,
  multiFilter,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { FavouritesService } from '../../../services/favourites.service';

@Component({
    selector: 'lu-favourites-list',
    templateUrl: './favourites-list.component.html',
    styleUrls: ['./favourites-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FavouritesListComponent {
  trackByFavourite = TrackByFunctions.ref<WithRef<IFavourite>>();
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  favourites$: Observable<WithRef<ISkill | IPathway>[]>;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(public favourites: FavouritesService) {
    this.favourites$ = this.favourites.favourites$.pipe(
      multiFilter(
        (favourite) =>
          favourite.type === MentionResourceType.Skill ||
          favourite.type === MentionResourceType.Pathway
      ),
      multiConcatMap(
        async (favourite) =>
          (await getDoc(favourite.ref)) as unknown as
            | WithRef<ISkill>
            | WithRef<IPathway>
      )
    );
  }

  isSkill(favourite: WithRef<ISkill | IPathway>): boolean {
    return isSkill(favourite);
  }

  asSkill(favourite: WithRef<ISkill | IPathway>): WithRef<ISkill> {
    return favourite as WithRef<ISkill>;
  }

  isPathway(favourite: WithRef<ISkill | IPathway>): boolean {
    return isPathway(favourite);
  }

  asPathway(favourite: WithRef<ISkill | IPathway>): WithRef<IPathway> {
    return favourite as WithRef<IPathway>;
  }
}
