<div class="flex h-full min-h-full flex-row items-center px-2">
  <button
    mat-icon-button
    class="square"
    [matMenuTriggerFor]="menu"
    matTooltip="Filter by Tags"
  >
    <mat-icon>filter_list</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <mat-selection-list (selectionChange)="updateSelected($event)">
      <ng-container *ngFor="let tag of filters$ | async; trackBy: trackByTag">
        <mat-list-option
          #listOption="matListOption"
          checkboxPosition="before"
          [value]="tag"
          (click)="$event.stopPropagation()"
        >
          <mat-chip-listbox class="mat-typography">
            <mat-chip
              [style.--mdc-chip-label-text-color]="tag | tagTextColour"
              [style.background-color]="tag.hexColour"
            >
              {{ tag.name }}
            </mat-chip>
          </mat-chip-listbox>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </mat-menu>
</div>
