import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeStorageUid',
    standalone: false
})
export class RemoveStorageUidPipe implements PipeTransform {
  transform(name: string): string {
    return name.replace(/^[0-9]+_/, '');
  }
}
