import {
  Directive,
  Input,
  TemplateRef,
  type AfterContentChecked,
  ElementRef,
  ViewContainerRef,
  Renderer2,
} from '@angular/core';

@Directive({
    selector: '[ptDefaultTemplate]',
    standalone: false
})
export class DefaultTemplateDirective implements AfterContentChecked {
  private _isEmptyClass = 'is-empty';
  private _templateRendered = false;
  private _element: HTMLElement;
  @Input('ptDefaultTemplate') template: TemplateRef<unknown>;

  constructor(
    element: ElementRef<HTMLElement>,
    private _container: ViewContainerRef,
    private _renderer: Renderer2
  ) {
    this._element = element.nativeElement;
  }

  ngAfterContentChecked(): void {
    const hasContent: boolean = Array.from(this._element.childNodes).some(
      (node: ChildNode) => this._isConsideredContent(node)
    );
    const showTemplate = !hasContent;
    this._update(showTemplate);
  }

  private _isConsideredContent(node: ChildNode): boolean {
    const contentTypes: number[] = [Node.ELEMENT_NODE, Node.TEXT_NODE];
    return contentTypes.includes(node.nodeType);
  }

  private _update(showTemplate: boolean): void {
    if (this._templateRendered === showTemplate) {
      return;
    }
    if (showTemplate) {
      return this._addTemplate();
    }
    this._removeTemplate();
  }

  private _removeTemplate(): void {
    this._renderer.removeClass(this._element, this._isEmptyClass);
    this._container.clear();
    this._templateRendered = false;
  }

  private _addTemplate(): void {
    this._renderer.addClass(this._element, this._isEmptyClass);
    this._container.createEmbeddedView(this.template);
    this._templateRendered = true;
  }
}
