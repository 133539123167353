import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CachedListsService } from '../../services/cached-lists.service';
import { TeamStatistics } from './team-statistics';

@Component({
    selector: 'lu-team-progress',
    templateUrl: './team-progress.component.html',
    styleUrls: ['./team-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TeamProgressComponent {
  stats: TeamStatistics;

  constructor(cachedLists: CachedListsService) {
    this.stats = new TeamStatistics(cachedLists);
  }
}
