import {
  getEnumValues,
  isSameRef,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import {
  combineLatest,
  of,
  type Observable,
  type OperatorFunction,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ISkill } from '../skill/skill';
import { SkillLevel } from '../skill/skill-level';
import { type IUser } from '../user/user';
import {
  filterGroupsByUser,
  type IGoals,
  type IPathwayAssociation,
  type ISkillAssociation,
  type ISkillLevelRequirement,
  type IUserGroup,
} from './user-group';

export function filterUserGroupsByUser$(
  user$: Observable<WithRef<IUser>>
): OperatorFunction<WithRef<IUserGroup>[], WithRef<IUserGroup>[]> {
  return (userGroups$: Observable<WithRef<IUserGroup>[]>) =>
    combineLatest([userGroups$, user$]).pipe(
      switchMap(([groups, user]: [WithRef<IUserGroup>[], WithRef<IUser>]) => {
        return filterGroupsByUser(user)(of(groups));
      })
    );
}

export function userGroupsToGoals(groups: IGoals[]): IGoals {
  return {
    skillAssociations: reduceSkillAssociations(groups),
    levelRequirements: reduceLevelRequirements(groups),
    pathwayAssociations: reducePathwayAssociations(groups),
  };
}

export function filterGoals(
  goals: IGoals,
  skillRefs: DocumentReference<ISkill>[]
): IGoals {
  const skillPaths = skillRefs.map((skillRef) => skillRef.path);
  const skillAssociations = goals.skillAssociations.filter(
    (association: ISkillAssociation) =>
      skillPaths.length ? skillPaths.includes(association.skill.ref.path) : true
  );
  const levelRequirements = goals.levelRequirements.filter(
    (levelRequirement: ISkillLevelRequirement) =>
      skillPaths.length
        ? skillPaths.includes(levelRequirement.skill.ref.path)
        : true
  );
  return {
    skillAssociations,
    levelRequirements,
    pathwayAssociations: goals.pathwayAssociations,
  };
}

export function getSkillLevelRequirement$(
  skill$: Observable<WithRef<ISkill>>
): OperatorFunction<IGoals, ISkillLevelRequirement | undefined> {
  return (goals$: Observable<IGoals>) =>
    combineLatest([goals$, skill$]).pipe(
      map(([goals, skill]) =>
        goals.levelRequirements.find((req) => isSameRef(req.skill.ref, skill))
      )
    );
}

export function getSkillLevelGoal$(
  skill$: Observable<WithRef<ISkill>>
): OperatorFunction<IGoals, SkillLevel> {
  return (goals$: Observable<IGoals>) =>
    goals$.pipe(
      getSkillLevelRequirement$(skill$),
      map((goal) => (goal ? goal.level : SkillLevel.None))
    );
}

function reduceSkillAssociations(userGroups: IGoals[]): ISkillAssociation[] {
  return uniqBy(
    userGroups.reduce((skills: ISkillAssociation[], group: IGoals) => {
      return [...skills, ...group.skillAssociations];
    }, []),
    (association) => association.skill.ref.path
  );
}

function reducePathwayAssociations(
  userGroups: IGoals[]
): IPathwayAssociation[] {
  return uniqBy(
    userGroups.reduce((pathways: IPathwayAssociation[], group: IGoals) => {
      return [...pathways, ...group.pathwayAssociations];
    }, []),
    (pathway: IPathwayAssociation) => pathway.ref
  );
}

function reduceLevelRequirements(
  userGroups: IGoals[]
): ISkillLevelRequirement[] {
  const all: ISkillLevelRequirement[] = userGroups.reduce(
    (requirements: ISkillLevelRequirement[], group: IGoals) => {
      return [...requirements, ...group.levelRequirements];
    },
    []
  );

  const sortedDesc: ISkillLevelRequirement[] = sortBy(
    all,
    (requirement: ISkillLevelRequirement) =>
      getEnumValues(SkillLevel).indexOf(requirement.level)
  ).reverse();

  return uniqBy(
    sortedDesc,
    (requirement: ISkillLevelRequirement) => requirement.skill.ref.path
  );
}
