import {
  type INamedDocument,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { type ITag } from '@principle-theorem/level-up-core';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TagsInput {
  tags$: BehaviorSubject<WithRef<ITag>[]> = new BehaviorSubject<
    WithRef<ITag>[]
  >([]);
  namedDocs$: Observable<INamedDocument[]>;

  constructor() {
    this.namedDocs$ = this._getNamedDocuments$();
  }

  add(tag: WithRef<ITag>): void {
    this.tags$.next([...this.tags$.value, tag]);
  }

  remove(tag: WithRef<ITag>): void {
    const tags: WithRef<ITag>[] = this.tags$.value.filter(
      (item) => item !== tag
    );
    this.tags$.next(tags);
  }

  private _getNamedDocuments$(): Observable<INamedDocument[]> {
    return this.tags$.pipe(
      map((tags) => tags.map((tag) => toNamedDocument(tag)))
    );
  }
}
