import {
  type ISkill,
  type ITag,
  type IUser,
} from '@principle-theorem/level-up-core';
import { InputSearchFilter } from '@principle-theorem/ng-shared';
import { shareReplayCold, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { TagFilter } from '../../tags/tag-filter';

export class SkillListBloc {
  searchFilter: InputSearchFilter<WithRef<ISkill>>;
  filteredSkills$: Observable<WithRef<ISkill>[]>;

  constructor(
    skills$: Observable<WithRef<ISkill>[]>,
    search$: Observable<string>,
    selectedTagFilters$: Observable<WithRef<ITag>[]>,
    public user$: Observable<WithRef<IUser>>
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<ISkill>>(
      skills$,
      search$,
      ['name']
    );

    this.filteredSkills$ = new TagFilter(
      this.searchFilter.results$,
      selectedTagFilters$
    ).results$.pipe(shareReplayCold());
  }
}
