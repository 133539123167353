import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'toPixels',
    standalone: false
})
export class ToPixelsPipe implements PipeTransform {
  transform(value: number): string {
    return `${value}px`;
  }
}
