import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Folder,
  IFolder,
  IntercomEvent,
  Organisation,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchInput$,
} from '@principle-theorem/ng-shared';
import {
  DocumentReference,
  INamedDocument,
  WithRef,
  addDoc,
  filterUndefined,
  isSameRef,
  snapshot,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';
import {
  FolderAddDialogComponent,
  FolderFormData,
  IFolderAddDialogData,
} from '../folder-add-dialog/folder-add-dialog.component';
import { buildFolderOptionsList } from '../folder-options-list';

@Component({
    selector: 'lu-folder-selector',
    templateUrl: './folder-selector.component.html',
    styleUrls: ['./folder-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FolderSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByFolder = TrackByFunctions.ref<WithRef<IFolder>>();
  @Input() placeholder = 'Select a Folder';
  @Output() add = new EventEmitter<DocumentReference<IFolder>>();
  folderFilter = new TypedFormControl<string>();
  availableFolders$: Observable<INamedDocument<IFolder>[]>;
  folderSearch: InputSearchFilter<INamedDocument<IFolder>>;
  filteredRef$ = new ReplaySubject<DocumentReference<IFolder>>(1);
  selectedRef$ = new ReplaySubject<DocumentReference<IFolder>>(1);
  parentOnly$ = new BehaviorSubject<boolean>(false);

  @Input()
  set parentOnly(parentOnly: BooleanInput) {
    this.parentOnly$.next(coerceBooleanProperty(parentOnly));
  }

  @Input()
  set filteredRef(filteredRef: DocumentReference<IFolder>) {
    if (filteredRef) {
      this.filteredRef$.next(filteredRef);
    }
  }

  @Input()
  set selectedRef(selectedRef: DocumentReference<IFolder>) {
    if (selectedRef) {
      this.selectedRef$.next(selectedRef);
    }
  }

  constructor(
    cachedLists: CachedListsService,
    private _organisation: OrganisationService,
    private _dialog: BasicDialogService,
    private _intercom: Intercom,
    private _snackBar: MatSnackBar
  ) {
    this.availableFolders$ = combineLatest([
      cachedLists.folders$,
      this.parentOnly$,
    ]).pipe(
      map(([folders, parentOnly]) =>
        buildFolderOptionsList(
          folders.filter((folder) =>
            parentOnly ? !folder.parentFolderRef : true
          )
        )
      )
    );

    this.folderSearch = new InputSearchFilter<INamedDocument<IFolder>>(
      combineLatest([
        this.filteredRef$.pipe(startWith(undefined)),
        this.availableFolders$,
      ]).pipe(
        map(([filteredRef, availableFolders]) =>
          availableFolders.filter(
            (availableFolder) => !isSameRef(availableFolder, filteredRef)
          )
        )
      ),
      toSearchInput$(this.folderFilter),
      ['name']
    );

    combineLatest([this.selectedRef$, this.availableFolders$])
      .pipe(
        take(1),
        map(([selectedRef, availableFolders]) =>
          availableFolders.find((availableFolder) =>
            isSameRef(availableFolder, selectedRef)
          )
        ),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((selectedFolder) =>
        this.folderFilter.setValue(selectedFolder as unknown as string)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  folderSelected(folder?: INamedDocument<IFolder>): void {
    if (!folder) {
      return;
    }
    this.add.emit(folder.ref);
  }

  displayFn(folder?: IFolder): string {
    return folder ? folder.name : '';
  }

  async addNewFolder(): Promise<void> {
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );

    const folder = await this._dialog.mobileFullscreen<
      FolderAddDialogComponent,
      IFolderAddDialogData,
      FolderFormData
    >(
      FolderAddDialogComponent,
      DialogPresets.small({
        data: { name: this.folderFilter.value },
      })
    );

    if (!folder) {
      return;
    }

    const folderRef = await addDoc(
      Organisation.folderCol(organisation),
      Folder.init(folder)
    );

    this._intercom.trackEvent(IntercomEvent.AddedFolder, {
      name: folder.name,
    });

    this._snackBar.open('Folder Added');
    this.selectedRef = folderRef;
    this.add.emit(folderRef);
  }
}
