import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgAuthModule } from '@principle-theorem/ng-auth';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../../components/components.module';
import { AdminModule } from '../../admin/admin.module';
import { OrgUserEditComponent } from '../org-user-edit/org-user-edit.component';
import { MarketplaceComponent } from './marketplace.component';
import { VendorProfileComponent } from './vendors/vendor/vendor-profile/vendor-profile.component';
import { VendorComponent } from './vendors/vendor/vendor.component';
import { VendorsComponent } from './vendors/vendors/vendors.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    OrgUserEditComponent,
    MarketplaceComponent,
    VendorComponent,
    VendorsComponent,
    VendorProfileComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSharedModule,
    NgMaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    AdminModule,
    NgAuthModule,
    NgEditorModule,
    NgFirebaseModule,
  ],
})
export class MarketplaceModule {}
