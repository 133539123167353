import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import { type IUser, type IUserGroup } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  find,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserGroupAssociation } from '../../../../models/user-group-associations';
import { OrganisationService } from '../../../../services/organisation.service';
import { where } from '@principle-theorem/shared';

export interface IEditUserDialogData {
  user: WithRef<IUser>;
}

type EditUserFormData = Pick<IUser, 'name' | 'email' | 'isAdmin'>;

@Component({
  selector: 'lu-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditDialogComponent {
  canChangeAdminStatus$: Observable<boolean>;
  userEditForm: TypedFormGroup<EditUserFormData> =
    new TypedFormGroup<EditUserFormData>({
      name: new TypedFormControl<string>('', Validators.required),
      email: new TypedFormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      isAdmin: new TypedFormControl<boolean>(false),
    });
  assignedGroups$ = new BehaviorSubject<WithRef<IUserGroup>[]>([]);
  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditUserDialogData,
    private _dialogRef: MatDialogRef<UserEditDialogComponent>,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    private _functions: AuthFirebaseFunctionsService
  ) {
    this.userEditForm.patchValue(this.data.user);

    this.canChangeAdminStatus$ = this._organisation.user$.pipe(
      filterUndefined(),
      map(
        (user) =>
          !this.data.user.isOwner &&
          user.isAdmin &&
          !isSameRef(user, this.data.user)
      )
    );
  }

  async submit(sendInvite: boolean = false): Promise<void> {
    if (this.userEditForm.invalid) {
      return;
    }

    this.submitting = true;
    const data: EditUserFormData = this.userEditForm.getRawValue();
    const emailChanged: boolean = data.email !== this.data.user.email;

    if (emailChanged) {
      const emailInUse: boolean = await snapshot(this._emailInUse$(data.email));
      if (emailInUse) {
        this.submitting = false;
        this._snackBar.open(
          'Sorry that Email is already in use! Please Try again.'
        );
        return;
      }
    }

    const groupAssociations = new UserGroupAssociation(
      this._organisation.userGroupsCol$
    );

    await groupAssociations.setUserAssociations(
      this.data.user,
      this.assignedGroups$.value
    );

    await patchDoc(this.data.user.ref, {
      name: data.name,
      email: data.email.toLowerCase(),
      isAdmin: data.isAdmin,
    });

    if (emailChanged) {
      const existingFirebaseUser = await this._functions.updateUserEmail(
        this.data.user.email,
        data.email
      );
      if (!existingFirebaseUser) {
        sendInvite = true;
      }
    }

    if (sendInvite) {
      const organisation = await snapshot(
        this._organisation.organisation$.pipe(filterUndefined())
      );
      await this._functions.sendOrganisationInviteEmail(organisation, {
        ...this.data.user,
        ...data,
      });
    }

    this._snackBar.open(
      sendInvite
        ? `User updated. An email verification link has been sent to ${data.email}`
        : 'User updated'
    );
    this._dialogRef.close(emailChanged);
  }

  private _emailInUse$(email: string): Observable<boolean> {
    return this._organisation.usersCol$.pipe(
      find(where('email', '==', email.toLowerCase())),
      map((user) => (user ? true : false))
    );
  }
}
