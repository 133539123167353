import { type WithRef } from '@principle-theorem/shared';
import { clamp } from 'lodash';
import {
  getGoalProgress,
  hasReachedGoal,
  type ISkillProgress,
} from '../../skill/skill-progress';
import { type IGoals } from '../../user-group/user-group';
import { type IUser } from '../user';
import { boolToNum, resolverBuilders } from './helpers';
import {
  type IUserStatisticsResult,
  type IUserStatValue,
  statValue,
} from './user-statistics-result';

type IBuilderFn = (
  user: WithRef<IUser>,
  goals: IGoals,
  progress: ISkillProgress
) => IUserStatValue[];

export function skillGoalProgressResults(
  user: WithRef<IUser>,
  goals: IGoals,
  progress: ISkillProgress
): IUserStatisticsResult[] {
  return resolverBuilders(
    {
      goalStarsEarned,
      nonGoalStarsEarned,
      skillGoalsReached,
    },
    (builder: IBuilderFn) => builder(user, goals, progress)
  );
}

/**
 * Stars earned that are required of the user's assigned groups
 */
function goalStarsEarned(
  owner: WithRef<IUser>,
  goals: IGoals,
  progress: ISkillProgress
): IUserStatValue[] {
  const goalProgress = getGoalProgress(goals, progress);
  const value = clamp(goalProgress.starsEarned, 0, goalProgress.starsGoal);
  return [statValue(owner.ref, value)];
}

/**
 * Stars earned that are NOT required of the user's assigned groups
 */
function nonGoalStarsEarned(
  owner: WithRef<IUser>,
  goals: IGoals,
  progress: ISkillProgress
): IUserStatValue[] {
  const goalProgress = getGoalProgress(goals, progress);
  const diff = goalProgress.starsEarned - goalProgress.starsGoal;
  const value = clamp(diff, 0, goalProgress.starsEarned);
  return [statValue(owner.ref, value)];
}

/**
 * Stars earned that are required of the user's assigned groups.
 * Completed goal in a skill
 */
function skillGoalsReached(
  owner: WithRef<IUser>,
  goals: IGoals,
  progress: ISkillProgress
): IUserStatValue[] {
  return [statValue(owner.ref, boolToNum(hasReachedGoal(goals, progress)))];
}
