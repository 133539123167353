import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Folder,
  IPathway,
  ISkill,
  IVendorBundle,
  MarketplaceContentCopier,
  Organisation,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  WithRef,
  addDoc,
  filterUndefined,
  snapshot,
} from '@principle-theorem/shared';
import {
  IMarketplaceCopyDialogData,
  IMarketplaceCopyReturnData,
  MarketplaceCopyToFolderDialogComponent,
} from '../components/marketplace/marketplace-copy-to-folder-dialog/marketplace-copy-to-folder-dialog.component';
import { OrganisationService } from './organisation.service';

@Injectable({ providedIn: 'root' })
export class MarketplaceCopyService {
  constructor(
    private _dialog: BasicDialogService,
    private _organisation: OrganisationService,
    private _snackbar: MatSnackBar
  ) {}

  async isAllContentCopied(bundle: WithRef<IVendorBundle>): Promise<boolean> {
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );
    return MarketplaceContentCopier.isAllCopied(bundle, organisation);
  }

  async copyBundle(bundle: WithRef<IVendorBundle>): Promise<void> {
    const data = await this._dialog
      .open<
        MarketplaceCopyToFolderDialogComponent,
        IMarketplaceCopyDialogData,
        IMarketplaceCopyReturnData
      >(
        MarketplaceCopyToFolderDialogComponent,
        DialogPresets.small({
          data: {
            copyType: 'bundle',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!data) {
      return;
    }

    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );

    if (!data.folderRef) {
      data.folderRef = await addDoc(
        Organisation.folderCol(organisation),
        Folder.init({ name: bundle.name })
      );
    }

    await MarketplaceContentCopier.copyBundle(
      bundle,
      organisation,
      data.createSubFolders,
      data.folderRef
    );
    this._snackbar.open(`${bundle.name} copied to folder`);
  }

  async copyPathway(pathway: WithRef<IPathway>): Promise<void> {
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );

    const data = await this._dialog
      .open<
        MarketplaceCopyToFolderDialogComponent,
        IMarketplaceCopyDialogData,
        IMarketplaceCopyReturnData
      >(
        MarketplaceCopyToFolderDialogComponent,
        DialogPresets.small({
          data: {
            copyType: 'pathway',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!data?.folderRef) {
      return;
    }

    await MarketplaceContentCopier.copyPathway(
      pathway,
      organisation,
      undefined,
      data.folderRef
    );

    this._snackbar.open(`${pathway.name} copied to folder`);
  }

  async copySkill(skill: WithRef<ISkill>): Promise<void> {
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );

    const data = await this._dialog
      .open<
        MarketplaceCopyToFolderDialogComponent,
        IMarketplaceCopyDialogData,
        IMarketplaceCopyReturnData
      >(
        MarketplaceCopyToFolderDialogComponent,
        DialogPresets.small({
          data: {
            copyType: 'skill',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!data?.folderRef) {
      return;
    }

    await MarketplaceContentCopier.copySkill(
      skill,
      organisation,
      undefined,
      data.folderRef
    );
    this._snackbar.open(`${skill.name} copied to folder`);
  }
}
