import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pt-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ImagePreviewComponent {
  @Input() src = '';
  size$: Observable<string>;
  width$: BehaviorSubject<number> = new BehaviorSubject<number>(200);

  @Input()
  set width(width: number) {
    if (width) {
      this.width$.next(width);
    }
  }

  constructor() {
    this.size$ = this.width$.pipe(map((width) => `${width}px`));
  }
}
