import {
  Component,
  ViewEncapsulation,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const NO_ICON_SYMBOL = 'none';

@Component({
    selector: 'pt-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchFieldComponent {
  icon$ = new BehaviorSubject<string>('search');

  @HostBinding('class.has-icon')
  hasIcon = true;

  @Input()
  set icon(icon: string) {
    if (icon) {
      this.icon$.next(icon);
    }
    this.hasIcon = icon !== NO_ICON_SYMBOL;
  }

  @Input()
  @HostBinding('class.slim')
  slim = false;
}
