<mat-toolbar color="primary">Edit Folder</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name"
        [required]="true"
        ptIntercomTarget="skill-edit-dialog.name"
      />
    </mat-form-field>

    <lu-folder-selector
      placeholder="Select a Parent Folder"
      parentOnly
      [filteredRef]="data.folder.ref"
      [selectedRef]="form.controls.parentFolderRef.value"
      (add)="form.controls.parentFolderRef.setValue($event)"
    />
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container
      alignment="space-between"
      padding="none"
      class="w-full"
    >
      <button
        mat-stroked-button
        color="warn"
        matTooltip="Folder must be empty to be deleted"
        [matTooltipDisabled]="canDelete$ | async"
        [disabled]="(canDelete$ | async) !== true"
        (click)="delete()"
      >
        Delete
      </button>

      <div>
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button
          color="primary"
          mat-flat-button
          ptIntercomTarget="skill-edit-dialog.save-button"
        >
          Save
        </button>
      </div>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
