<mat-toolbar color="primary">
  <span>Generate an Image</span>
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content class="flex flex-col">
    <mat-form-field class="!flex">
      <mat-label>Describe the image you want</mat-label>
      <textarea matInput formControlName="prompt" rows="5"></textarea>
      <mat-hint>Write a description of what image you want created</mat-hint>
    </mat-form-field>

    <mat-form-field class="!flex">
      <mat-label>Image Style</mat-label>
      <mat-select formControlName="imageStyle">
        <mat-option
          *ngFor="let style of imageStyles; trackBy: trackByOption"
          [value]="style"
          >{{ style | splitSnake | titlecase }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="!pt-0">
    <pt-buttons-container padding="none">
      <button mat-flat-button color="primary" [disabled]="!form.valid">
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
