<ng-container *ngIf="pathway$ | async as pathway">
  <ng-container *ngIf="(compact$ | async) !== true; else showCompact">
    <div
      class="grid-rows-12 relative grid cursor-pointer grid-cols-1 overflow-hidden rounded-lg border border-solid border-gray-200 transition-colors hover:border-black sm:max-h-48 sm:grid-cols-12"
      [routerLink]="link$ | async"
    >
      <div class="relative row-span-3 sm:col-span-3">
        <lu-card-image
          [image]="pathway.imageUrl"
          [placeholderImage]="pathwayCardPlaceholderImage"
        />
        <lu-user-pathway-progress
          *ngIf="isLocalPathway$ | async"
          class="absolute bottom-0 left-0 mb-2 ml-2 text-sm"
          [user]="user$ | async"
          [pathway]="pathway"
        />
      </div>

      <div
        class="row-span-6 flex flex-col justify-stretch px-3 py-2 sm:col-span-6 sm:border-r sm:border-solid sm:border-gray-200"
      >
        <div class="flex grow flex-col overflow-hidden">
          <div class="title">
            <div class="line-clamp-2 text-[16px] font-bold">
              {{ pathway.name }}
            </div>
          </div>
          <div class="description max-h-20 grow sm:max-h-16">
            <lu-content-editor-view
              *ngIf="pathway.description"
              [content]="pathway.description"
            />
          </div>
        </div>

        <ng-container
          *ngIf="isMarketplacePathway$ | async; else isLocalPathway"
        >
          <ng-container *luIsAdmin>
            <ng-container *ngIf="isAlreadyCopied$ | async; else copyPathway">
              <div
                class="next-step disabled relative z-10 flex items-center justify-center p-2 text-center"
                (click)="$event.stopPropagation()"
              >
                <div class="next-up disabled absolute -top-3 flex self-center">
                  <mat-icon>drive_file_move</mat-icon>
                  <span>Copy to Folder</span>
                </div>
                <div class="flex grow gap-2 self-stretch text-left text-black">
                  <span>Pathway Already Copied</span>
                </div>
              </div>
            </ng-container>

            <ng-template #copyPathway>
              <a
                href="javascript:void(0);"
                class="next-step relative z-10 flex cursor-pointer items-center justify-center p-2 text-center"
                (click)="$event.stopPropagation(); copyToFolder(pathway)"
              >
                <div class="next-up absolute -top-3 flex self-center">
                  <mat-icon>drive_file_move</mat-icon>
                  <span>Copy to Folder</span>
                </div>
                <div class="flex grow gap-2 self-stretch text-left text-black">
                  <span>Copy Pathway to Folder</span>
                </div>
              </a>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-template #isLocalPathway>
          <a
            *ngIf="nextStep$ | async as nextStep; else canStartPathway"
            class="next-step relative z-10 flex cursor-pointer items-center justify-center p-2 text-center"
            [routerLink]="nextStep.link"
            (click)="$event.stopPropagation()"
          >
            <div class="next-up absolute -top-3 flex self-center">
              <mat-icon>playlist_play</mat-icon>
              <span>Next Up</span>
            </div>
            <div class="flex grow gap-2 self-stretch text-left text-black">
              <lu-content-type-icon contentType="skill" />
              <span>
                {{ nextStep.title }}
              </span>
            </div>
          </a>
        </ng-template>

        <ng-template #canStartPathway>
          <a
            *ngIf="canStartPathway$ | async"
            class="next-step relative z-10 flex cursor-pointer items-center justify-center p-2 text-center"
            [routerLink]="link$ | async"
            (click)="$event.stopPropagation(); startLearning(pathway)"
          >
            <div class="next-up absolute -top-3 flex self-center">
              <mat-icon>playlist_add</mat-icon>
              <span>Start Learning</span>
            </div>
            <div class="flex grow gap-2 self-stretch text-left text-black">
              <lu-content-type-icon contentType="skill" />
              <span>Start Pathway</span>
            </div>
          </a>
        </ng-template>
      </div>

      <div
        class="row-span-3 flex flex-col justify-between px-3 py-2 sm:col-span-3"
      >
        <div *ngIf="isLocalPathway$ | async" class="flex justify-between">
          <lu-goal-due-date-indicator
            class="absolute left-2 top-2 sm:static"
            [dueDate]="dueDate$ | async"
            (click)="$event.stopPropagation()"
          />
          <div
            class="absolute right-2 top-2 flex items-center justify-end rounded-lg bg-white p-1 sm:static"
          >
            <lu-favourite-indicator
              [item]="pathway"
              (click)="$event.stopPropagation()"
            />

            <ng-container *luIsAdmin>
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="font-bold">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="pathway | canEdit$ | async"
                  mat-menu-item
                  [routerLink]="['/admin', 'pathways', pathway.ref.id]"
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
              </mat-menu>
            </ng-container>
          </div>
        </div>

        <div class="mb-2 flex flex-col gap-4">
          <lu-author
            *ngIf="pathway.author | map: getAuthor$ : this | async as author"
            [author]="author"
          />

          <lu-tag-display
            *ngIf="pathway.tags.length"
            [tagRefs]="pathway.tags"
            [compact]="true"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #showCompact>
    <div
      class="header flex cursor-pointer items-center gap-2 rounded-lg border border-solid border-gray-300 transition-colors hover:border-black"
      [routerLink]="link$ | async"
    >
      <div class="flex min-w-0 grow items-center gap-2 pl-2">
        <lu-content-type-icon contentType="pathway" />

        <lu-user-pathway-progress
          *ngIf="isLocalPathway$ | async"
          class="mx-2 text-[12px]"
          compact
          [user]="user$ | async"
          [pathway]="pathway"
        />

        <div
          class="flex min-w-0 grow items-start gap-1 sm:items-center sm:gap-2"
          [ngClass]="{
            'flex-col': bloc.isMobile$ | async,
            'flex-row': bloc.isNotMobile$ | async
          }"
        >
          <span
            class="w-44 grow truncate"
            [ptTruncateTooltip]="pathway.name"
            [ngClass]="{
              'basis-7/12': bloc.isNotMobile$ | async
            }"
          >
            {{ pathway.name }}
          </span>

          <div *ngIf="isLocalPathway$ | async" class="flex">
            <lu-goal-due-date-indicator [dueDate]="dueDate$ | async" />
          </div>
        </div>
      </div>

      <button
        *ngIf="isLocalPathway$ | async"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon class="font-bold">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngIf="
            pathway.ref | map: favourites.isFavourite$ : favourites | async;
            else addFavourite
          "
          mat-menu-item
          (click)="favourites.togglePathway(pathway)"
        >
          <mat-icon>heart_minus</mat-icon>
          <span>Remove from favourites</span>
        </button>

        <ng-template #addFavourite>
          <button mat-menu-item (click)="favourites.togglePathway(pathway)">
            <mat-icon>favorite</mat-icon>
            <span>Add to favourites</span>
          </button>
        </ng-template>

        <button
          *ngIf="pathway | canEdit$ | async"
          mat-menu-item
          [routerLink]="['/admin', 'pathways', pathway.ref.id]"
        >
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
      </mat-menu>
    </div>
  </ng-template>
</ng-container>
