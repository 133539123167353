import { Injectable, Injector } from '@angular/core';
import {
  DEFAULT_EXTENSIONS,
  IMention,
  coreEditorExtensions,
} from '@principle-theorem/editor';
import {
  IPathway,
  IRecentlyViewed,
  ISkill,
  ITypesensePathway,
  ITypesenseSkill,
  ITypesenseVendorSkill,
  IUser,
  IUserGroup,
  IVendor,
  MentionResourceType,
  Organisation,
  Pathway,
  Skill,
  isTypesensePathway,
  isTypesenseSkill,
  toMention,
} from '@principle-theorem/level-up-core';
import {
  AILoader,
  BasicMenuButtonComponent,
  ISnippetOptionGroup,
  aiTabShortcutExtension,
  angularEditorExtensions,
  createBulletedListBlockMenuButton,
  createCodeBlockBlockMenuButton,
  createHeadingMenuBlockButtons,
  createHorizontalRuleBlockMenuButton,
  createImagePromptBlockButton,
  createImagePromptButton,
  createLayoutColumnBlockMenuButton,
  createMenuDivider,
  createMenuSpacer,
  createOrderedListBlockMenuButton,
  createTableBlockMenuButton,
  createTextPromptBlockButton,
  createTextPromptButton,
  createVideoEmbedBlockMenuButton,
  editorAISubmenuButtons,
  editorAlignSubmenuButtons,
  editorInsertSubmenuButtons,
  editorMediaSubmenuButtons,
  editorMenuButtons,
} from '@principle-theorem/ng-editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { IOptionGroup, IUploader } from '@principle-theorem/ng-shared';
import {
  DocumentReference,
  WithRef,
  asDocRef,
  filterUndefined,
  shareReplayCold,
} from '@principle-theorem/shared';
import { Ai } from '@tiptap-pro/extension-ai';
import { AnyExtension } from '@tiptap/core';
import { compact, isString } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { MENTION_BUTTON_PROVIDER } from '../components/mention/mention-buttons/mention-buttons.component';
import { TypesenseSearchService } from '../typesense/typesense-search.service';
import { LastViewedService } from './last-viewed.service';
import { OrganisationService } from './organisation.service';

@Injectable()
export class EditorPresetsService {
  constructor(
    private _injector: Injector,
    private _search: TypesenseSearchService,
    private _organisation: OrganisationService,
    private _lastViewed: LastViewedService
  ) {}

  defaultExtensions(tipTapAppId?: string, aiToken?: string): AnyExtension[] {
    return [
      ...this._setupAIExtension(tipTapAppId, aiToken),
      ...DEFAULT_EXTENSIONS,
      coreEditorExtensions.dragAndDrop(),
      angularEditorExtensions.image(this._injector),
      angularEditorExtensions.imageUploading(this._injector),
      angularEditorExtensions.video(this._injector),
      angularEditorExtensions.videoUploading(this._injector),
      angularEditorExtensions.videoEmbed(this._injector),
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
      angularEditorExtensions.snippetAutocomplete(this._injector, {
        snippets$: this._loadSnippetOptions$(),
      }),
      angularEditorExtensions.mentionAutocomplete(this._injector, {
        mentions$: (search$) => this._loadMentionOptions$(search$),
      }),
    ];
  }

  defaultMenuItems(uploader: IUploader): MenuButtonLoaderFn[] {
    return [
      editorMenuButtons.ai([
        createTextPromptButton(),
        createImagePromptButton(),
        createMenuDivider(false),
        editorAISubmenuButtons.autocomplete(),
        editorAISubmenuButtons.extend(),
        editorAISubmenuButtons.shorten(),
        editorAISubmenuButtons.rephrase(),
        editorAISubmenuButtons.simplify(),
        editorAISubmenuButtons.summarize(),
        editorAISubmenuButtons.tldr(),
        createMenuDivider(false),
        editorAISubmenuButtons.fixSpellingAndGrammar(),
        editorAISubmenuButtons.emojify(),
        editorAISubmenuButtons.adjustTone(),
      ]),
      createMenuDivider(),
      editorMenuButtons.heading(),
      editorMenuButtons.textBadge(),
      editorMenuButtons.textStyling(),
      editorMenuButtons.bold(),
      editorMenuButtons.italic(),
      editorMenuButtons.underline(),
      editorMenuButtons.strike(),
      editorMenuButtons.link(),
      editorMenuButtons.horizontalRule(),
      editorMenuButtons.bulletedList(),
      editorMenuButtons.orderedList(),
      editorMenuButtons.align([
        editorAlignSubmenuButtons.leftAlign(),
        editorAlignSubmenuButtons.centerAlign(),
        editorAlignSubmenuButtons.rightAlign(),
        editorAlignSubmenuButtons.justifyAlign(),
      ]),
      editorMenuButtons.code(),
      createMenuDivider(),
      editorMenuButtons.media([
        editorMediaSubmenuButtons.imageUpload({
          uploader,
        }),
        editorMediaSubmenuButtons.videoUpload({
          uploader,
        }),
        editorMediaSubmenuButtons.videoEmbed(),
      ]),
      editorMenuButtons.insert([
        editorInsertSubmenuButtons.blockquote(),
        editorInsertSubmenuButtons.codeBlock(),
        editorInsertSubmenuButtons.table(),
        editorInsertSubmenuButtons.layoutColumn(),
      ]),
      createMenuSpacer(),
      editorMenuButtons.undo(),
      editorMenuButtons.redo(),
    ];
  }

  blockMenuItems(
    uploader: IUploader
  ): MenuButtonLoaderFn<BasicMenuButtonComponent>[] {
    return [
      createTextPromptBlockButton(),
      createImagePromptBlockButton(),
      ...createHeadingMenuBlockButtons(),
      createBulletedListBlockMenuButton(),
      createOrderedListBlockMenuButton(),
      createHorizontalRuleBlockMenuButton(),
      createTableBlockMenuButton(),
      ...createLayoutColumnBlockMenuButton(),
      createVideoEmbedBlockMenuButton(),
      editorMediaSubmenuButtons.imageUpload({
        uploader,
      }),
      editorMediaSubmenuButtons.videoUpload({
        uploader,
      }),
      createCodeBlockBlockMenuButton(),
    ];
  }

  private _setupAIExtension(
    tipTapAppId?: string,
    aiToken?: string
  ): AnyExtension[] {
    return [
      Ai.configure({
        appId: tipTapAppId,
        token: aiToken,
        autocompletion: true,
        onLoading: () => {
          AILoader.isRunning$.next(true);
        },
        onSuccess: () => {
          AILoader.isRunning$.next(false);
        },
        onError: (error: Error) => {
          AILoader.error$.next();
          AILoader.isRunning$.next(false);
          // eslint-disable-next-line no-console
          console.error('error', error);
        },
      }),
      aiTabShortcutExtension.configure(),
    ];
  }

  private _loadMentionOptions$(
    search$: Observable<string>
  ): Observable<IOptionGroup<IMention>[]> {
    return combineLatest([
      this._lastViewed.recentlyViewed$,
      this._organisation.user$.pipe(filterUndefined()),
      this._organisation.associatedGroups$,
      this._search
        .query$(
          search$.pipe(
            filter((value) => isString(value)),
            startWith('')
          ),
          of([]),
          of([])
        )
        .pipe(startWith([])),
    ]).pipe(
      map(([recentlyViewed, user, groups, results]) => {
        const skills = buildSkillOptions(results, user, groups);
        const pathways = buildPathwayOptions(results, user, groups);

        return {
          recentlyViewed,
          skills,
          pathways,
        };
      }),
      map(({ recentlyViewed, skills, pathways }) => [
        this._buildRecentlyViewed(recentlyViewed),
        {
          name: 'Skills',
          options: skills.map((skill) =>
            toMention(
              { ...skill, ref: asDocRef<ISkill>(skill.ref) },
              MentionResourceType.Skill
            )
          ),
          skipFilter: true,
        },
        {
          name: 'Pathways',
          options: pathways.map((pathway) =>
            toMention(
              { ...pathway, ref: asDocRef<IPathway>(pathway.ref) },
              MentionResourceType.Pathway
            )
          ),
          skipFilter: true,
        },
      ]),
      shareReplayCold()
    );
  }

  private _loadSnippetOptions$(): Observable<ISnippetOptionGroup[]> {
    return this._organisation.organisation$
      .pipe(
        filterUndefined(),
        switchMap((organisation) => Organisation.pageTemplates$(organisation))
      )
      .pipe(
        map((templates) => [
          {
            name: 'Templates',
            options: templates.map((template) => ({
              name: template.name,
              body: template.content,
              deleted: false,
            })),
            skipFilter: false,
          },
        ]),
        shareReplayCold()
      );
  }

  private _buildRecentlyViewed(
    recentlyViewed: IRecentlyViewed[]
  ): IOptionGroup<IMention> {
    const options = compact(
      recentlyViewed.map((recentItem) => {
        if (recentItem.type === MentionResourceType.Skill) {
          return toMention(
            {
              name: recentItem.title,
              ref: recentItem.ref as DocumentReference<ISkill>,
            },
            MentionResourceType.Skill
          );
        }

        if (recentItem.type === MentionResourceType.Pathway) {
          return toMention(
            {
              name: recentItem.title,
              ref: recentItem.ref as DocumentReference<IPathway>,
            },
            MentionResourceType.Pathway
          );
        }
      })
    );

    return {
      name: 'Recently Viewed',
      options,
      skipFilter: false,
    };
  }
}

export interface IEditorPresets {
  extensions: AnyExtension[];
  menuItems: MenuButtonLoaderFn[];
}

export function buildSkillOptions(
  results: (ITypesensePathway | ITypesenseSkill | ITypesenseVendorSkill)[],
  user: WithRef<IUser>,
  groups: WithRef<IUserGroup>[]
): ITypesenseSkill[] {
  return results
    .filter((item): item is ITypesenseSkill => isTypesenseSkill(item))
    .filter((skill) =>
      Skill.canView(
        {
          ...skill,
          author: asDocRef<IUser | IVendor>(skill.authorRef),
          restrictAccessTo: skill.restrictAccessTo.map((ref) =>
            asDocRef<IUserGroup>(ref)
          ),
        },
        user,
        groups
      )
    );
}

export function buildPathwayOptions(
  results: (ITypesensePathway | ITypesenseSkill | ITypesenseVendorSkill)[],
  user: WithRef<IUser>,
  groups: WithRef<IUserGroup>[]
): ITypesensePathway[] {
  return results
    .filter((item): item is ITypesensePathway => isTypesensePathway(item))
    .filter((pathway) =>
      Pathway.canView(
        {
          ...pathway,
          restrictAccessTo: pathway.restrictAccessTo.map((ref) =>
            asDocRef<IUserGroup>(ref)
          ),
        },
        user,
        groups
      )
    );
}
