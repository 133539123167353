import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type IUser, User } from '@principle-theorem/level-up-core';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  multiFilter,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';

@Component({
    selector: 'lu-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UsersListComponent {
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  users$: ReplaySubject<WithRef<IUser>[]> = new ReplaySubject(1);
  activeUsers$: Observable<WithRef<IUser>[]>;
  pendingUsers$: Observable<WithRef<IUser>[]>;
  disabledUsers$: Observable<WithRef<IUser>[]>;
  activatedUserCount$: Observable<number>;
  allocatedUserCount$: Observable<number>;
  willExceedUserCount$: Observable<boolean>;
  cost$: Observable<string | undefined>;
  badgeColor: ThemePalette = 'warn';
  @Input() routePrefix = '';

  constructor(
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _dialog: MatDialog
  ) {
    this.activatedUserCount$ = this._organisation.enabledUsers$.pipe(
      map((users) => users.length)
    );
    this.allocatedUserCount$ = this._organisation.organisation$.pipe(
      filterUndefined(),
      map((org) => org.subscription),
      filterUndefined(),
      map((sub) => parseInt(sub.quantity, 10)),
      startWith(0)
    );

    this.willExceedUserCount$ = combineLatest([
      this.activatedUserCount$,
      this.allocatedUserCount$,
    ]).pipe(map(([activated, allotted]) => activated >= allotted));

    this.cost$ = this._organisation.organisation$.pipe(
      filterUndefined(),
      map((org) => org.subscription),
      filterUndefined(),
      map((subscription) =>
        subscription.id.includes('monthly')
          ? '$5 a month'
          : '$54 a year pro rata'
      ),
      startWith(undefined)
    );

    this.activeUsers$ = this.users$.pipe(
      multiFilter((user) => user.isActivated && !!User.getLastActiveAt(user))
    );

    this.pendingUsers$ = this.users$.pipe(
      multiFilter((user) => user.isActivated && !User.getLastActiveAt(user))
    );

    this.disabledUsers$ = this.users$.pipe(
      multiFilter((user) => !user.isActivated)
    );
  }

  @Input()
  set users(users: WithRef<IUser>[]) {
    if (users) {
      this.users$.next(users);
    }
  }

  async toggleUserEnabled(user: WithRef<IUser>): Promise<void> {
    const willExceedCount = await snapshot(this.willExceedUserCount$);
    const isActivated = user.isActivated ? false : true;

    if (isActivated && willExceedCount) {
      const confirmed = await this._confirmUpgrade();
      if (!confirmed) {
        return;
      }
    }

    await patchDoc(user.ref, {
      isActivated,
    });

    let message = 'User Access ';
    message += isActivated ? 'Enabled' : 'Disabled';
    this._snackBar.open(message);
  }

  private async _confirmUpgrade(): Promise<boolean> {
    const cost = await snapshot(this.cost$);
    if (!cost) {
      return true;
    }
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Enable User',
      prompt: `Click confirm to enable user at ${cost}`,
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    return !!confirmed;
  }
}
