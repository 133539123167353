<lu-card-container
  class="grow"
  *ngIf="bundle$ | async as bundle"
  [title]="bundle.name"
  [description]="bundle.description"
  [author]="author$ | async"
  [link]="link"
>
  <lu-card-image [image]="bundle.imageUrl" />
  <lu-card-actions />
</lu-card-container>
