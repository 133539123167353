<div fxLayout="column">
  <div *ngFor="let userGroup of userGroups$ | async; trackBy: trackByGroup">
    <mat-checkbox
      [disabled]="userGroup | map : isDefaultGroup"
      [checked]="userGroup | map : isInGroup$ : this | async"
      (change)="handleGroupChange($event, userGroup)"
      ptIntercomTarget="group-assignment-checkbox"
    >
      {{ userGroup.name }}
    </mat-checkbox>
  </div>
</div>
