<ng-container *ngIf="user$ | async as user">
  <mat-list-item class="mentor-row">
    <div
      matListItemLine
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="16px"
      >
        <pt-user-icon
          [src]="user | profileImage$ | async"
          [diameter]="30"
          [name]="user.name"
          [matTooltip]="user.name"
         />

        <a
          [routerLink]="['/admin/users', user.ref.id]"
          *ngIf="isAdmin$ | async; else userName"
        >
          {{ user.name }}
        </a>

        <ng-template #userName>
          <span>{{ user.name }}</span>
        </ng-template>
      </div>

      <lu-goal-due-date-indicator
        [dueDate]="dueDate$ | async"
       />

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span *ngIf="skills$ | async as skills"
          >{{ skills.length }} skills</span
        >
      </div>

      <button mat-icon-button (click)="userSkillList.toggle()">
        <mat-icon>
          {{ userSkillList.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>
    </div>
  </mat-list-item>
  <pt-collapsible
    #userSkillList
    [expanded]="expanded$ | async"
    (opened)="setExpand()"
    (closed)="setCollapse()"
  >
    <ng-template ptCollapsibleContent>
      <mat-list>
        <ng-container
          *ngFor="let skill of skills$ | async; trackBy: trackBySkill"
        >
          <lu-skill-list-item
            [showAuthor]="false"
            [skill]="skill"
            [user]="user"
            [canEdit]="true"
           />
        </ng-container>
      </mat-list>
    </ng-template>
  </pt-collapsible>
</ng-container>
