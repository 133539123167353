<lu-achievement
  class="h-full w-full"
  [achievement]="achievement$ | async"
  [locked]="isLocked$ | async"
>
  <span class="mat-caption" *ngIf="unlocked$ | async as unlock; else locked">
    Unlocked {{ unlock.date | toDate | date }}
  </span>
  <ng-template #locked>
    <lu-pathway-progress
      [completed]="progress$ | async"
      [total]="total$ | async"
    />
  </ng-template>
</lu-achievement>
