import { FirestoreSerialiser } from '@principle-theorem/shared';
import {
  SkillSerialiser,
  SkillUnserialiser,
} from './models/skill/skill-serialiser';

export const APP_NAME = 'Level Up';

export const LEVEL_UP_SERIALISERS = [
  new SkillSerialiser(),
  new SkillUnserialiser(),
  new FirestoreSerialiser(),
];
