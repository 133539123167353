<ng-container *ngIf="pathways$ | async as pathways; else loadingPathways">
  <ng-container *ngIf="pathways.length; else noPathways">
    <lu-pathway-list-card
      *ngFor="let pathway of pathways; trackBy: trackByPathway"
      [pathway]="pathway"
      [user]="user$ | async"
      [link]="['/explore/pathway', pathway.ref.id]"
    />
  </ng-container>
</ng-container>

<ng-template #loadingPathways>
  <div class="flex flex-col gap-2">
    <lu-skill-list-card-placeholder />
    <lu-skill-list-card-placeholder />
    <lu-skill-list-card-placeholder />
  </div>
</ng-template>

<ng-template #noPathways>
  <div class="mat-caption flex items-center p-4">No Pathways</div>
</ng-template>
