<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout.gt-xs="column"
>
  <div class="mat-caption">
    <ng-content select="label"></ng-content>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
