import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../services/organisation.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthorisedGuard extends AuthGuard {
  constructor(
    private _router: Router,
    private _organisation: OrganisationService,
    auth: AuthService
  ) {
    super(auth);
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._organisation.user$.pipe(
      map((user) => {
        if (user) {
          return true;
        }
        return this._router.createUrlTree(['/unauthorised']);
      })
    );
  }
}
