import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
  type OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { snapshot } from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { default as SignaturePad } from 'signature_pad';
import { formControlChanges$ } from '../forms/form';
import { TypedFormControl } from '../forms/typed-form-group';

const CANVAS_WIDTH = 400;
const CANVAS_HEIGHT = 150;

@Component({
    selector: 'pt-signature-input',
    templateUrl: './signature-input.component.html',
    styleUrls: ['./signature-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MatFormFieldControl, useExisting: SignatureInputComponent },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SignatureInputComponent),
            multi: true,
        },
    ],
    imports: [RouterModule, CommonModule]
})
export class SignatureInputComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _changed$: Subject<string> = new Subject();
  private _touched$: Subject<void> = new Subject();
  private _canvas$ = new BehaviorSubject<HTMLCanvasElement | undefined>(
    undefined
  );
  private _signaturePad$ = new BehaviorSubject<SignaturePad | undefined>(
    undefined
  );
  private _formCtrl = new TypedFormControl<string | undefined>(undefined);
  disabled$ = new BehaviorSubject<boolean>(false);
  required$ = new BehaviorSubject<boolean>(false);
  canClear$: Observable<boolean>;

  @Input() label: string = 'Signature';
  @Input() hint: string = 'Draw your signature above';

  @Input()
  set required(required: boolean) {
    this.required$.next(required);
  }

  @Input({ transform: coerceBooleanProperty })
  set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  @ViewChild('signatureCanvas', { static: true })
  set canvas(canvas: ElementRef<HTMLCanvasElement>) {
    canvas.nativeElement.width = CANVAS_WIDTH;
    canvas.nativeElement.height = CANVAS_HEIGHT;
    this._canvas$.next(canvas.nativeElement);

    const signaturePad = new SignaturePad(canvas.nativeElement);
    this._signaturePad$.next(signaturePad);
  }

  constructor() {
    combineLatest([this.disabled$, this._signaturePad$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([isDisabled, signaturePad]) =>
        this._updateDisabled(isDisabled, signaturePad)
      );

    const formChanges$ = formControlChanges$(this._formCtrl).pipe(
      startWith(this._formCtrl.value)
    );
    this.canClear$ = combineLatest([this.disabled$, formChanges$]).pipe(
      map(([disabled, signature]) => !disabled && !!signature)
    );
    formChanges$.pipe(takeUntil(this._onDestroy$)).subscribe((signature) => {
      void this._setSignatureUrl(signature);
      this._changed$.next(signature);
      this._touched$.next();
    });

    this._signaturePad$
      .pipe(
        switchMap((signaturePad) =>
          this._toEventListener('endStroke', signaturePad)
        ),
        switchMap(() => this._getSignatureUrl()),
        takeUntil(this._onDestroy$)
      )
      .subscribe((signature) =>
        this._formCtrl.setValue(signature, { emitEvent: true })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._changed$.complete();
    this._touched$.complete();
  }

  writeValue(signature: string | undefined): void {
    void this._setSignatureUrl(signature);
    this._formCtrl.setValue(signature, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  clear(): void {
    this._formCtrl.setValue(undefined, { emitEvent: true });
  }

  private async _setSignatureUrl(signature?: string): Promise<void> {
    const signaturePad = await snapshot(this._signaturePad$);
    signaturePad?.clear();

    if (signaturePad && signature) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const canvas = await snapshot(this._canvas$);
      const width = canvas?.width ?? CANVAS_WIDTH;
      const height = canvas?.height ?? CANVAS_HEIGHT;
      await signaturePad.fromDataURL(signature, { ratio, width, height });
    }
  }

  private async _getSignatureUrl(): Promise<string | undefined> {
    const signaturePad = await snapshot(this._signaturePad$);
    return signaturePad?.toDataURL();
  }

  private _updateDisabled(
    isDisabled: boolean,
    signaturePad?: SignaturePad
  ): void {
    if (isDisabled) {
      this._formCtrl.disable();
      signaturePad?.off();
      return;
    }
    this._formCtrl.enable();
    signaturePad?.on();
  }

  private _toEventListener(
    eventName: string,
    signaturePad?: SignaturePad
  ): Observable<SignaturePad | undefined> {
    if (!signaturePad) {
      return of();
    }
    return new Observable((subscriber) => {
      const listener = (): void => subscriber.next(signaturePad);
      signaturePad.addEventListener(eventName, listener);
      return () => signaturePad.removeEventListener(eventName, listener);
    });
  }
}
