import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { filterNil } from '@principle-theorem/shared';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lu-pathway-progress',
    templateUrl: './pathway-progress.component.html',
    styleUrls: ['./pathway-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PathwayProgressComponent {
  private _current$ = new BehaviorSubject<number>(0);
  private _total$ = new BehaviorSubject<number>(0);
  isInProgress$: Observable<boolean>;
  isCompleted$: Observable<boolean>;
  summary$: Observable<string>;
  compact$ = new BehaviorSubject<boolean>(false);

  @Input()
  set compact(compact: BooleanInput) {
    this.compact$.next(coerceBooleanProperty(compact));
  }

  @Input()
  set completed(completed: number) {
    this._current$.next(completed);
  }

  @Input()
  set total(total: number) {
    this._total$.next(total);
  }

  constructor() {
    this.summary$ = combineLatest([
      this._current$.pipe(filterNil()),
      this._total$.pipe(filterNil()),
    ]).pipe(map(([current, total]) => `${current}/${total}`));

    this.isInProgress$ = combineLatest([
      this._current$.pipe(filterNil()),
      this._total$.pipe(filterNil()),
    ]).pipe(map(([current, total]) => current !== total));

    this.isCompleted$ = combineLatest([
      this._current$.pipe(filterNil()),
      this._total$.pipe(filterNil()),
    ]).pipe(map(([current, total]) => current === total && total > 0));
  }
}
