<div class="progress-wrapper" *ngIf="progress.goal$ | async">
  <div
    class="progress-container"
    [matTooltip]="progressTooltip$ | async"
    matTooltipClass="multi-line-tooltip"
  >
    <mat-progress-bar
      mode="determinate"
      [value]="completedPercentage$ | async"
     />
    <div class="goalMarker" [ngStyle]="{ left: goalMarkerPercentage$ | async }">
      <mat-icon [ngStyle]="{ color: goalMarkerColour$ | async }"
        >check_circle</mat-icon
      >
    </div>
  </div>
</div>
