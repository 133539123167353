import { type IIntegration } from '@principle-theorem/integrations';
import {
  all$,
  type AtLeast,
  getColRef,
  type IReffable,
  isObject,
  shareReplayCold,
  slugify,
  subCollection,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { isString, sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootCollection } from '../../root-collection';
import { IFolder } from '../folder';
import { type IMarketplaceSubscription } from '../marketplace/marketplace-subscription';
import { type IPageTemplate } from '../page-template/page-template';
import { type IPathway } from '../pathway/pathway';
import { type ISkill } from '../skill/skill';
import { type ITag } from '../tag/tag';
import { type IUserGroup } from '../user-group/user-group';
import { type IUser } from '../user/user';
import { OrganisationCollection } from './organisation-collections';

export enum OrganisationStatus {
  Active = 'active',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Suspended = 'suspended',
}

export interface IOrganisation {
  name: string;
  slug: string;
  status: OrganisationStatus;
  subscription?: IOrganisationSubscription;
}

export interface IOrganisationSubscription {
  id: string;
  customerId: string;
  quantity: string;
}

export function isOrganisationSubscription(
  data: unknown
): data is IOrganisationSubscription {
  return (
    isObject(data) &&
    'id' in data &&
    isString(data.id) &&
    'customerId' in data &&
    isString(data.customerId) &&
    'quantity' in data &&
    isString(data.quantity)
  );
}

export class Organisation {
  static init(overrides: AtLeast<IOrganisation, 'name'>): IOrganisation {
    return {
      status: OrganisationStatus.Pending,
      ...overrides,
      slug: slugify(overrides.name.toLowerCase()),
    };
  }

  static col(): CollectionReference<IOrganisation> {
    return getColRef<IOrganisation>(RootCollection.Organisations);
  }

  static all$(): Observable<WithRef<IOrganisation>[]> {
    return all$(this.col()).pipe(
      map((organisations) => sortBy(organisations, ['name'])),
      shareReplayCold()
    );
  }

  static skillCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<ISkill> {
    return subCollection<ISkill>(
      organisation.ref,
      OrganisationCollection.Skills
    );
  }

  static skills$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<ISkill>[]> {
    return all$(undeletedQuery(Organisation.skillCol(organisation)));
  }

  static userCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IUser> {
    return subCollection<IUser>(organisation.ref, OrganisationCollection.Users);
  }

  static users$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IUser>[]> {
    return all$(Organisation.userCol(organisation));
  }

  static userGroupCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IUserGroup> {
    return subCollection<IUserGroup>(
      organisation.ref,
      OrganisationCollection.UserGroups
    );
  }

  static userGroups$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IUserGroup>[]> {
    return all$(undeletedQuery(Organisation.userGroupCol(organisation)));
  }

  static subscriptionCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IMarketplaceSubscription> {
    return subCollection<IMarketplaceSubscription>(
      organisation.ref,
      OrganisationCollection.MarketplaceSubscriptions
    );
  }

  static subscriptions$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IMarketplaceSubscription>[]> {
    return all$(Organisation.subscriptionCol(organisation));
  }

  static pathwayCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IPathway> {
    return subCollection<IPathway>(
      organisation.ref,
      OrganisationCollection.Pathways
    );
  }

  static pathways$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IPathway>[]> {
    return all$(undeletedQuery(Organisation.pathwayCol(organisation)));
  }

  static tagCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<ITag> {
    return subCollection<ITag>(organisation.ref, OrganisationCollection.Tags);
  }

  static tags$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<ITag>[]> {
    return all$(undeletedQuery(Organisation.tagCol(organisation)));
  }

  static pageTemplateCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IPageTemplate> {
    return subCollection<IPageTemplate>(
      organisation.ref,
      OrganisationCollection.PageTemplates
    );
  }

  static pageTemplates$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IPageTemplate>[]> {
    return all$(undeletedQuery(Organisation.pageTemplateCol(organisation)));
  }

  static integrationCol<T extends object = object>(
    organisation: WithRef<IOrganisation>
  ): CollectionReference<IIntegration<T>> {
    return subCollection<IIntegration<T>>(
      organisation.ref,
      OrganisationCollection.Integrations
    );
  }

  static folderCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IFolder> {
    return subCollection<IFolder>(
      organisation.ref,
      OrganisationCollection.Folders
    );
  }

  static folders$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IFolder>[]> {
    return all$(undeletedQuery(Organisation.folderCol(organisation)));
  }
}

export const ORGANISATION_STATUS_MAP: {
  [key in OrganisationStatus]: string;
} = {
  [OrganisationStatus.Pending]: 'default',
  [OrganisationStatus.Active]: 'primary',
  [OrganisationStatus.Cancelled]: 'default',
  [OrganisationStatus.Suspended]: 'warn',
};
