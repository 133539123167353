import { Component, Input } from '@angular/core';
import { IntercomService } from '../../intercom.service';

@Component({
    selector: 'pt-intercom-product-tour-trigger',
    templateUrl: './intercom-product-tour-trigger.component.html',
    styleUrls: ['./intercom-product-tour-trigger.component.scss'],
    standalone: false
})
export class IntercomProductTourTriggerComponent {
  @Input() tourId: number;

  constructor(private _intercomService: IntercomService) {}

  startTour(tourId: number): void {
    this._intercomService.startTour(tourId);
  }
}
