import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import {
  IRelativeDueDateDialogData,
  RelativeDueDateDialogComponent,
} from '../skills/relative-due-date-dialog/relative-due-date-dialog.component';
import { snapshot } from '@principle-theorem/shared';

@Component({
  selector: 'lu-user-group-goal-due-date-indicator',
  templateUrl: './user-group-goal-due-date-indicator.component.html',
  styleUrls: ['./user-group-goal-due-date-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupGoalDueDateIndicatorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  canEdit$ = new BehaviorSubject<boolean>(false);
  dueDate$ = new ReplaySubject<number | undefined>(1);
  @Input() placement: 'before' | 'after' = 'before';
  @Output() dateChange = new EventEmitter<number | undefined>();

  @Input()
  set dueDate(dueDate: number | undefined) {
    this.dueDate$.next(dueDate ?? undefined);
  }

  @Input()
  set canEdit(canEdit: BooleanInput) {
    this.canEdit$.next(coerceBooleanProperty(canEdit));
  }

  constructor(private _dialog: BasicDialogService) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async openDueDateDialog(): Promise<void> {
    const dueDate = await this._dialog
      .open<RelativeDueDateDialogComponent, IRelativeDueDateDialogData, number>(
        RelativeDueDateDialogComponent,
        DialogPresets.small({
          data: { dueDate: await snapshot(this.dueDate$) },
        })
      )
      .afterClosed()
      .toPromise();

    if (dueDate) {
      this.dateChange.emit(dueDate);
    }
  }
}
