import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  IMenuButton,
  TextFormatting,
  fromEditorEvents,
} from '@principle-theorem/editor';
import { EditorMenuItemComponent } from '@principle-theorem/ng-prosemirror';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-text-styling-menu',
    templateUrl: './text-styling-menu.component.html',
    styleUrls: ['./text-styling-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextStylingMenuComponent extends EditorMenuItemComponent {
  trackByItem = TrackByFunctions.field<ColourMapWithButton>('buttonText');
  override tooltip = 'Text Colour';
  textColours = textColourButtons();
  highlightColours = textHighlightButtons();
  activeTextColour$: Observable<string | undefined>;
  activeHighlightColour$: Observable<string | undefined>;
  isActive$: Observable<boolean>;

  constructor() {
    super();
    this.activeTextColour$ = this.editor$.pipe(
      switchMap((editor) =>
        fromEditorEvents(editor).pipe(
          startWith(undefined),
          map(() =>
            editor.state.selection.$head
              .marks()
              .find(
                (mark) => mark.type.name === String(TextFormatting.TextStyle)
              )
          ),
          map((mark) => (mark?.attrs?.color as string) ?? undefined)
        )
      )
    );

    this.activeHighlightColour$ = this.editor$.pipe(
      switchMap((editor) =>
        fromEditorEvents(editor).pipe(
          startWith(undefined),
          map(() =>
            editor.state.selection.$head
              .marks()
              .find(
                (mark) => mark.type.name === String(TextFormatting.Highlight)
              )
          ),
          map((mark) => (mark?.attrs?.color as string) ?? undefined)
        )
      )
    );

    this.isActive$ = combineLatest([
      this.activeTextColour$,
      this.activeHighlightColour$,
    ]).pipe(
      map(([activeTextColour, activeHighlightColour]) => {
        return activeTextColour || activeHighlightColour ? true : false;
      })
    );
  }

  async runCommand(colour: ColourMapWithButton): Promise<void> {
    const editor = await snapshot(this.editor$);
    colour.command(editor);
  }
}

interface IColourMap {
  label: string;
  colour?: string;
}

type ColourMapWithButton = IMenuButton & Pick<IColourMap, 'colour'>;

const textColours: IColourMap[] = [
  {
    label: 'Default',
  },
  {
    label: 'Red',
    colour: '#e04f44',
  },
  {
    label: 'Orange',
    colour: '#bc5f1c',
  },
  {
    label: 'Yellow',
    colour: '#cf940a',
  },
  {
    label: 'Blue',
    colour: '#4c68d6',
  },
  {
    label: 'Purple',
    colour: '#5f55ee',
  },
  {
    label: 'Pink',
    colour: '#c75485',
  },
  {
    label: 'Green',
    colour: '#259646',
  },
  {
    label: 'Grey',
    colour: '#87909e',
  },
];

export function textColourButtons(): ColourMapWithButton[] {
  return textColours.map((colour) => {
    return {
      buttonType: 'submenu-detail',
      buttonText: colour.label,
      icon: 'title',
      colour: colour.colour,
      tooltip: '',
      command: (editor) => {
        if (
          !colour.colour ||
          editor.isActive(TextFormatting.TextStyle, { color: colour.colour })
        ) {
          return editor.chain().focus().unsetColor().run();
        }
        return editor.chain().focus().setColor(colour.colour).run();
      },
      isActiveFn: (editor) =>
        editor.isActive(TextFormatting.TextStyle, { color: colour.colour }),
    };
  });
}

const highlightColours: IColourMap[] = [
  {
    label: 'Default',
  },
  {
    label: 'Red',
    colour: '#fae3e1',
  },
  {
    label: 'Orange',
    colour: '#fae7da',
  },
  {
    label: 'Yellow',
    colour: '#fef2d6',
  },
  {
    label: 'Blue',
    colour: '#e4e9ff',
  },
  {
    label: 'Purple',
    colour: '#e5e4fc',
  },
  {
    label: 'Pink',
    colour: '#fce5ef',
  },
  {
    label: 'Green',
    colour: '#dcf2e2',
  },
  {
    label: 'Grey',
    colour: '#f0f1f3',
  },
];

export function textHighlightButtons(): ColourMapWithButton[] {
  return highlightColours.map((colour) => {
    return {
      buttonType: 'submenu-detail',
      buttonText: colour.label,
      icon: 'title',
      colour: colour.colour,
      tooltip: '',
      command: (editor) => {
        if (
          !colour.colour ||
          editor.isActive(TextFormatting.Highlight, { color: colour.colour })
        ) {
          return editor.chain().focus().unsetHighlight().run();
        }
        return editor
          .chain()
          .focus()
          .setHighlight({ color: colour.colour })
          .run();
      },
      isActiveFn: (editor) =>
        editor.isActive(TextFormatting.Highlight, { color: colour.colour }),
    };
  });
}
