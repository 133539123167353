import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Editor } from '@tiptap/core';
import type { NodeSpec, ParseRule } from '@tiptap/pm/model';
import { Node } from '@tiptap/pm/model';
import { AngularNodeViewComponent } from 'ngx-tiptap';
import { ReplaySubject, Subject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IEditorNodeComponent<T extends object = object>
  extends AngularNodeViewComponent {
  selected: boolean;
  elementRef: ElementRef;
  update: Observable<T>;
  node: Node;
  schema: NodeSpec;
  addEvent(event: Event): void;
}

@Component({
    template: '',
    standalone: false
})
export abstract class EditorNodeComponent<T extends object = object>
  extends AngularNodeViewComponent
  implements IEditorNodeComponent<T>
{
  private _node: Node;
  private _editor!: Editor;
  editor$ = new ReplaySubject<Editor>(1);
  editable = true;
  selected$: Subject<boolean> = new ReplaySubject<boolean>(1);
  node$ = new ReplaySubject<Node>(1);
  event$ = new Subject<Event>();
  editable$: Observable<boolean>;

  @Input()
  schema: NodeSpec;

  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override set editor(editor: Editor) {
    if (editor) {
      this._editor = editor;
      this.editor$.next(editor);
    }
  }

  override get editor(): Editor {
    return this._editor;
  }

  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override set node(node: Node) {
    this._node = node;
    this.node$.next(node);
  }

  override get node(): Node {
    return this._node;
  }

  @Output()
  update = new EventEmitter<T>();

  constructor(public elementRef: ElementRef) {
    super();
    this.editable$ = this.editor$.pipe(map((editor) => editor.isEditable));
  }

  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override set selected(selected: boolean) {
    if (!this.editable) {
      this.selected$.next(false);
      return;
    }
    this.selected$.next(selected);
  }

  parseHTML(): ParseRule[] {
    return [];
  }

  addEvent(event: Event): void {
    this.event$.next(event);
  }
}
