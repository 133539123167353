import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  ISkill,
  ISkillLevelRequirement,
  IUser,
  SkillLevel,
  User,
  updateSkillLevelRequirement,
} from '@principle-theorem/level-up-core';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  DocumentReference,
  ISODateType,
  WithRef,
  filterUndefined,
  patchDoc,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { UserSkillProgress } from '../../../../models/user-skill-progress';
import { CachedListsService } from '../../../../services/cached-lists.service';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
    selector: 'lu-user-skill-associate',
    templateUrl: './user-skill-associate.component.html',
    styleUrls: ['./user-skill-associate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class UserSkillAssociateComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  skillRef$ = new ReplaySubject<DocumentReference<ISkill>>(1);
  skill$: Observable<WithRef<ISkill>>;
  skillLevels$ = new BehaviorSubject<ISkillLevelRequirement[]>([]);
  skillProgress: UserSkillProgress;
  dueDateCtrl = new TypedFormControl<Moment>();
  @Output() removeSkill = new EventEmitter<DocumentReference<ISkill>>();
  @Output()
  goalChange = new EventEmitter<SkillLevel>();

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set skillLevels(skillLevels: ISkillLevelRequirement[]) {
    if (skillLevels) {
      this.skillLevels$.next(skillLevels);
    }
  }

  @Input()
  set skillRef(skillRef: DocumentReference<ISkill>) {
    if (skillRef) {
      this.skillRef$.next(skillRef);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    cache: CachedListsService
  ) {
    this.skill$ = this.skillRef$.pipe(
      switchMap((skillRef) => cache.getLocalSkill(skillRef)),
      filterUndefined()
    );

    this.skillProgress = new UserSkillProgress(
      this._organisation.userGroups$,
      this.user$,
      this.skill$
    );

    this.skillProgress.dueDateBloc.dueDate$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((dueDate) => this.dueDateCtrl.setValue(toMoment(dueDate)));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async updateDueDate(dueDate?: ISODateType): Promise<void> {
    const skill = await snapshot(this.skill$);
    const user = await snapshot(this.user$);
    const userGroups = await snapshot(this._organisation.userGroups$);
    const { levelRequirements } = User.getGoals(user, userGroups);
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { dueDate },
      levelRequirements
    );

    this.user$.next({ ...user, levelRequirements: updatedSkillLevels });
    await patchDoc(user.ref, {
      levelRequirements: updatedSkillLevels,
    });
  }
}
