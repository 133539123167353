import {
  WithRef,
  all$,
  asColRef,
  shareReplayCold,
} from '@principle-theorem/shared';
import { CollectionReference } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { IManagementUser } from '../management/management-user';
import { RootCollection } from '../../root-collection';
import { IUser } from './user';

export class ManagementUser {
  static col(): CollectionReference<IManagementUser> {
    return asColRef<IUser>(RootCollection.ManagementUsers);
  }

  static all$(): Observable<WithRef<IManagementUser>[]> {
    return all$(this.col()).pipe(shareReplayCold());
  }
}
