import {
  SkillLevelRequestStatus,
  SkillStatus,
  User,
  filterSkillsByStatus,
  findProgressBySkill,
  resolvePathwaySkills,
  skillGoalAchieved,
  skillHasGoal,
  type IPathway,
  type IUser,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import { count, isSameRef, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CachedListsService } from '../services/cached-lists.service';

export class UserPathwayProgress {
  completed$: Observable<number>;
  total$: Observable<number>;
  goal$: Observable<number>;
  hasGoal$: Observable<boolean>;
  inProgress$: Observable<boolean>;
  isComplete$: Observable<boolean>;

  constructor(
    private _usersGroups$: Observable<WithRef<IUserGroup>[]>,
    private _user$: Observable<WithRef<IUser>>,
    pathway$: Observable<WithRef<IPathway>>,
    cache: CachedListsService
  ) {
    const skills$ = pathway$.pipe(
      resolvePathwaySkills((skillRef) => cache.getLocalSkill(skillRef)),
      filterSkillsByStatus(SkillStatus.Published)
    );

    const goals$ = combineLatest([this._user$, this._usersGroups$]).pipe(
      map(([user, userGroup]) => User.getGoals(user, userGroup))
    );

    const progress$ = this._user$.pipe(
      switchMap((user) => User.skillProgress$(user))
    );

    const requests$ = combineLatest([
      this._user$.pipe(
        switchMap((user) =>
          User.trainingRequests$(user, [SkillLevelRequestStatus.Pending])
        )
      ),
      skills$,
    ]).pipe(
      map(([requests, skills]) =>
        requests.filter((request) =>
          skills.some((skill) => isSameRef(skill, request.skillRef))
        )
      )
    );

    this.goal$ = combineLatest([skills$, goals$]).pipe(
      map(([skills, goals]) =>
        skills.filter((skill) => skillHasGoal(goals, skill))
      ),
      count()
    );

    this.total$ = skills$.pipe(count());

    this.completed$ = combineLatest([skills$, goals$, progress$]).pipe(
      map(([skills, goals, progress]) =>
        skills.filter((skill) =>
          skillGoalAchieved(goals, findProgressBySkill(skill, progress))
        )
      ),
      count()
    );

    this.hasGoal$ = this.goal$.pipe(map((goal) => goal > 0));

    this.isComplete$ = combineLatest([this.goal$, this.completed$]).pipe(
      map(([goal, completed]) => goal !== 0 && completed >= goal)
    );

    this.inProgress$ = combineLatest([
      this.goal$,
      this.completed$,
      requests$,
    ]).pipe(
      map(
        ([goal, completed, requests]) =>
          requests.length > 0 || (completed < goal && completed > 0)
      )
    );
  }
}
