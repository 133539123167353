<ng-container *luIsAdmin>
  <ng-container *ngIf="(isSubscribed$ | async) !== undefined">
    <div *ngIf="isSubscribed$ | async; else notSubscribed">
      <button
        *ngIf="subscription$ | async as subscription"
        mat-stroked-button
        color="warn"
        (click)="unsubscribe(subscription)"
      >
        Unsubscribe
      </button>
    </div>
    <ng-template #notSubscribed>
      <button mat-stroked-button (click)="subscribe()">Subscribe</button>
    </ng-template>
  </ng-container>
</ng-container>
