import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTemplateSelectorComponent } from './page-template-selector/page-template-selector.component';
import { PageTemplateAddDialogComponent } from './page-template-add-dialog/page-template-add-dialog.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PageTemplateSelectorComponent, PageTemplateAddDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSharedModule,
  ],
  exports: [PageTemplateSelectorComponent, PageTemplateAddDialogComponent],
})
export class PageTemplatesModule {}
