import * as moment from 'moment-timezone';
import { isObject } from '../../common';
import { type Timestamp } from '../../firebase/firestore/adaptor';
import { ISO_DATE_FORMAT } from '../date-time-formatting';
import { toMoment, toMomentTz, toTimestamp } from '../time';
import { type Timezone } from '../timezone';
import { isTimestamp } from '../../firebase/timestamp';
import { type ITimePeriod, type ITimestampRange } from './interfaces';

export function isTimePeriod(item: unknown): item is ITimePeriod {
  return (
    isObject(item) && moment.isMoment(item.from) && moment.isMoment(item.to)
  );
}

export function isTimestampRange(item: unknown): item is ITimestampRange {
  return isObject(item) && isTimestamp(item.from) && isTimestamp(item.to);
}

/**
 * Creates an ITimePeriod interface from the given moments
 *
 * @export
 * @param {moment.Moment | Timestamp} from
 * @param {moment.Moment | Timestamp} to
 * @returns {ITimePeriod}
 */
export function toTimePeriod(
  from: moment.Moment | Timestamp,
  to: moment.Moment | Timestamp,
  timezone?: Timezone
): ITimePeriod {
  return {
    from: timezone ? toMomentTz(from, timezone) : moment(toMoment(from)),
    to: timezone ? toMomentTz(to, timezone) : moment(toMoment(to)),
  };
}

export function toTimestampRange(timePeriod: ITimePeriod): ITimestampRange {
  return {
    from: toTimestamp(timePeriod.from),
    to: toTimestamp(timePeriod.to),
  };
}

export function timePeriodsIntersect(
  rangeA: ITimePeriod,
  rangeB: ITimePeriod,
  includeBounds: boolean,
  granularity: moment.unitOfTime.StartOf
): boolean {
  const startOfABeforeEndOfB = includeBounds
    ? rangeA.from.isSameOrBefore(rangeB.to, granularity)
    : rangeA.from.isBefore(rangeB.to, granularity);
  const endOfAAfterStartOfB = includeBounds
    ? rangeA.to.isSameOrAfter(rangeB.from, granularity)
    : rangeA.to.isAfter(rangeB.from, granularity);
  return startOfABeforeEndOfB && endOfAAfterStartOfB;
}

export function timePeriodWithin(
  rangeA: ITimePeriod,
  rangeB: ITimePeriod,
  includeBounds: boolean
): boolean {
  const startWithin = includeBounds
    ? rangeA.from.isSameOrAfter(rangeB.from)
    : rangeA.from.isAfter(rangeB.from);
  const endWithin = includeBounds
    ? rangeA.to.isSameOrBefore(rangeB.to)
    : rangeA.to.isBefore(rangeB.to);
  return startWithin && endWithin;
}

export function getTimePeriodDuration(
  timePeriod: ITimePeriod,
  granularity: moment.unitOfTime.Base,
  precise: boolean = false
): number {
  const diff: number = timePeriod.to.diff(
    timePeriod.from,
    granularity,
    precise
  );
  const duration: moment.Duration = moment.duration(diff, granularity);
  return duration.as(granularity);
}

export function timePeriodsAdjacent(
  rangeA: ITimePeriod,
  rangeB: ITimePeriod,
  maxDistance: number,
  distanceUnit: moment.unitOfTime.Base
): boolean {
  let distance = getTimePeriodDuration(
    toTimePeriod(rangeA.to, rangeB.from),
    distanceUnit
  );
  if (distance < 0) {
    distance = getTimePeriodDuration(
      toTimePeriod(rangeB.to, rangeA.from),
      distanceUnit
    );
  }
  return distance >= 0 && distance <= maxDistance;
}

/**
 * Get a TimePeriod at the start & end of the given unit. The new TimePeriod dates will be
 * generated from ISO_DATE_FORMAT and will not persist the times from the original object.
 * @param range ITimePeriod
 * @param unit moment.unitOfTime.Base
 * @param timezone Timezone
 * @returns ITimePeriod
 */
export function getTimePeriodStartEnd(
  range: ITimePeriod,
  timezone?: Timezone,
  unit: moment.unitOfTime.Base = 'day'
): ITimePeriod {
  const from = timezone
    ? moment.tz(range.from.format(ISO_DATE_FORMAT), timezone).startOf(unit)
    : moment(range.from.format(ISO_DATE_FORMAT)).startOf(unit);
  const to = timezone
    ? moment.tz(range.to.format(ISO_DATE_FORMAT), timezone).endOf(unit)
    : moment(range.to.format(ISO_DATE_FORMAT)).endOf(unit);
  return {
    from,
    to,
  };
}
