import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IntercomEvent,
  type ITag,
  Tag,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { type Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  EditTagDialogComponent,
  type IEditTagDialogData,
  type TagFormData,
} from '../../../components/tags/edit-tag-dialog/edit-tag-dialog.component';
import { TagSearch } from '../../../components/tags/tag-search';
import { PRODUCT_TOUR_CONFIG } from '../../../models/product-tour-config';
import { CachedListsService } from '../../../services/cached-lists.service';
import { DeleteTagService } from '../../../services/delete-tag.service';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    standalone: false
})
export class TagsComponent {
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  availableTags$: Observable<WithRef<ITag>[]>;
  searchInput: TypedFormControl<string> = new TypedFormControl<string>('');
  tagSearch: TagSearch;
  tourId: number = PRODUCT_TOUR_CONFIG.tags;
  @Output() tagAdded: EventEmitter<ITag> = new EventEmitter<ITag>();

  constructor(
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _deleteTagService: DeleteTagService,
    private _intercom: Intercom,
    cachedLists: CachedListsService
  ) {
    this.availableTags$ = cachedLists.tags$;

    this.tagSearch = new TagSearch(
      this.availableTags$,
      this.searchInput.valueChanges.pipe(startWith(''))
    );
  }

  async add(): Promise<void> {
    const config: MatDialogConfig<IEditTagDialogData> = {
      data: {
        action: 'Create',
      },
    };

    const tag = await this._dialog
      .open<EditTagDialogComponent, IEditTagDialogData, TagFormData>(
        EditTagDialogComponent,
        DialogPresets.small<IEditTagDialogData>(config)
      )
      .afterClosed()
      .toPromise();

    if (!tag) {
      return;
    }

    const tagCol: CollectionReference<ITag> = await snapshot(
      this._organisation.tagCol$
    );
    await addDoc(tagCol, Tag.init(tag));

    this._intercom.trackEvent(IntercomEvent.AddedTag, {
      name: tag.name,
    });
  }

  async remove(tag: WithRef<ITag>): Promise<void> {
    const confirm = await this._basicDialog.confirm({
      title: 'Remove Tag',
      prompt: 'Are you sure you want to delete this Tag?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }
    await this._deleteTagService.delete(tag);
    this._snackBar.open('Tag Removed');
  }

  async edit(tag: WithRef<ITag>): Promise<void> {
    const updateData: Partial<ITag> | undefined = await this._openEditDialog(
      tag
    );
    if (!updateData) {
      return;
    }
    await patchDoc(tag.ref, updateData);
  }

  private async _openEditDialog(
    tag: WithRef<ITag>
  ): Promise<TagFormData | undefined> {
    const config: MatDialogConfig<IEditTagDialogData> = {
      data: {
        action: 'Edit',
        tag,
      },
    };
    return this._dialog
      .open<EditTagDialogComponent, IEditTagDialogData, TagFormData>(
        EditTagDialogComponent,
        DialogPresets.small<IEditTagDialogData>(config)
      )
      .afterClosed()
      .toPromise();
  }
}
