import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillListComponent {
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  skills$ = new ReplaySubject<WithRef<ISkill>[]>(1);
  user$ = new ReplaySubject<WithRef<IUser>>(1);

  @Input()
  set skills(skills: WithRef<ISkill>[]) {
    if (skills) {
      this.skills$.next(skills);
    }
  }
  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }
}
