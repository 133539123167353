<ng-container *ngIf="selectionList.selected$ | async as selected">
  <ng-container *ngIf="selected.length">
    <div class="toolbar flex items-center">
      <button
        mat-icon-button
        matTooltip="Move Selected"
        aria-label="Move Selected"
        [matMenuTriggerFor]="moveItemsMenu"
      >
        <mat-icon>shuffle</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Dismiss Selected / ESC"
        aria-label="Dismiss Selected or click the escape key"
        (click)="dismissSelected()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>

<mat-menu #moveItemsMenu="matMenu">
  <div
    *ngFor="let folder of folders$ | async; trackBy: trackByFolder"
    mat-menu-item
    (click)="moveItems(folder.ref)"
  >
    <span class="truncate" [ptTruncateTooltip]="folder.name">{{
      folder.name
    }}</span>
  </div>
</mat-menu>
