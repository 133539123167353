import {
  filterByStatus,
  type IUser,
  SkillStatus,
  User,
  UserAuthorStats,
  UserGoalsProgress,
  UserMentorStats,
  UserReviewStats,
} from '@principle-theorem/level-up-core';
import {
  multiMap,
  reduceToSingleArray,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CachedListsService } from '../services/cached-lists.service';
import { OrganisationService } from '../services/organisation.service';

export class UserStatistics {
  goals: UserGoalsProgress;
  author: UserAuthorStats;
  reviewer: UserReviewStats;
  mentor: UserMentorStats;

  constructor(
    user$: Observable<WithRef<IUser>>,
    organisation: OrganisationService,
    cachedLists: CachedListsService
  ) {
    const approvedSkills$ = cachedLists.skills$.pipe(
      filterByStatus(SkillStatus.Published)
    );

    this.goals = new UserGoalsProgress(
      organisation.userGroups$,
      user$,
      approvedSkills$
    );
    this.author = new UserAuthorStats(user$, cachedLists.skills$);
    this.reviewer = new UserReviewStats(user$, cachedLists.skills$);

    const mentorSkillPairs$ = organisation.users$.pipe(
      multiMap((user) =>
        User.skillProgress$(user).pipe(
          multiMap((progress) => ({
            userRef: user.ref,
            progress,
          }))
        )
      ),
      switchMap((usersSkills) => combineLatest(usersSkills)),
      map((skillProgresses) => reduceToSingleArray(skillProgresses))
    );
    this.mentor = new UserMentorStats(
      user$,
      cachedLists.skills$,
      mentorSkillPairs$
    );
  }
}
