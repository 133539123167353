import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';

export interface IUsersWithSkillData {
  users: WithRef<IUser>[];
  skill: WithRef<ISkill>;
}

@Component({
    selector: 'lu-users-skill-progress-summary-dialog',
    templateUrl: './users-skill-progress-summary-dialog.component.html',
    styleUrls: ['./users-skill-progress-summary-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UsersSkillProgressSummaryDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsersWithSkillData,
    public dialogRef: MatDialogRef<
      UsersSkillProgressSummaryDialogComponent,
      IUsersWithSkillData
    >
  ) {}
}
