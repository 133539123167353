import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ThemePalette } from '@angular/material/core';

@Component({
  selector: 'lu-user-action',
  templateUrl: './user-action.component.html',
  styleUrls: ['./user-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActionComponent {
  @Input() colour?: ThemePalette;
  @Input() heading: string;
  @Input({ required: true }) count: number;
  @Input() routeLabel: string;
  @Input() route: string | unknown[];
}
