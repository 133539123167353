import { Directive } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { type ResolverResult } from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { BaseResolver } from './base-resolver';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractResolver<T> extends BaseResolver<T | undefined> {
  private _onDestroy$ = new Subject<void>();
  protected idKey = 'uid';
  public defaultPath = '/';
  public logNotFound = true;

  constructor(protected _router: Router) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): ResolverResult<T | undefined> {
    const uid: string = route.paramMap.get(this.idKey) || '';
    return this.getItemById(uid, route).pipe(
      take(1),
      tap((item?: T) => {
        if (this.logNotFound && !item) {
          // eslint-disable-next-line no-console
          console.error(`No item found for id: ${uid}`);
        }
      }),
      takeUntil(this._onDestroy$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  abstract getItemById(
    id: string,
    route: ActivatedRouteSnapshot
  ): Observable<T | undefined>;
}
