<lu-page-simple>
  <lu-page-header label="Marketplace">
    <lu-page-toolbar>
      <lu-header-button
        buttonIcon="add"
        buttonText="Create"
        luPageToolbarAction
        (click)="add()"
       />
      <pt-search-field fxFlex>
        <input matInput placeholder="Search" [formControl]="search" />
      </pt-search-field>
    </lu-page-toolbar>
  </lu-page-header>

  <div>
    <lu-list-heading>
      <lu-list-title color="primary">Vendors</lu-list-title>
    </lu-list-heading>
    <lu-vendors [vendors]="searchFilter.results$ | async" />
  </div>
</lu-page-simple>
