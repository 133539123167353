import { isError, pick } from 'lodash';
import { isObservable } from 'rxjs';
import { take } from 'rxjs/operators';
import { type JSONSerialisable, type ResolverResult } from './utility-types';

export async function snapshotResolverResult<T>(
  resolverResult: ResolverResult<T>
): Promise<T> {
  return isObservable(resolverResult)
    ? resolverResult.pipe(take(1)).toPromise()
    : resolverResult;
}

/**
 * Use on an enum to return an array of it's values
 * @param item C
 * @returns C[]
 */
export function getEnumValues<C>(item: C): C[keyof C][] {
  return Object.keys(item as object).map(
    (name: string) => item[name as keyof C]
  );
}

export function isEnumValue<T>(
  enumValue: T,
  item: unknown
): item is T[keyof T] {
  const values = getEnumValues(enumValue);
  return values.includes(item as T[keyof T]);
}

export function isObject(item: unknown): item is Record<string, unknown> {
  return item && typeof item === 'object' ? true : false;
}

export function isArray<T = unknown>(item: unknown): item is T[] {
  return Array.isArray(item);
}

export function isJSONSerialisableType<T>(
  item: unknown
): item is JSONSerialisable<T> {
  return (
    isObject(item) &&
    'data' in item &&
    'type' in item &&
    item.type === 'jsonSerialisable'
  );
}

export function joinArrayWithItem<T>(array: T[], separator: T): T[] {
  return array.reduce((result: T[], item, index) => {
    const isFirst = index < 1;
    return isFirst ? [item] : [...result, separator, item];
  }, []);
}

export function getError(error: unknown): string {
  return isError(error) ? error.message : String(error);
}

export function toInt(value: unknown): number {
  return parseInt(String(value), 10);
}

export function toFloat(value: unknown): number {
  return parseFloat(String(value));
}

export function omitByKeys<T extends object, U extends keyof T>(
  item: T,
  keysToOmit: (keyof T)[]
): Pick<T, U> {
  const allKeys = Object.keys(item) as (keyof T)[];
  const keys = allKeys.filter((key) => !keysToOmit.includes(key));
  return pick(item, keys);
}

export type Nil = null | undefined;
