import { DocumentReference, uid } from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { ISkill } from '../skill/skill';

export interface IPathwaySection {
  uid: string;
  name: string;
  steps: DocumentReference<PathwaySectionStep>[];
  dueDate?: Moment;
}

export class PathwaySection {
  static init(overrides?: Partial<IPathwaySection>): IPathwaySection {
    return {
      uid: uid(),
      name: 'New Section',
      steps: [],
      ...overrides,
    };
  }
}

export type PathwaySectionStep = ISkill;
// We'll soon add other types of steps, such as:
// | IQuiz
// | IAttachment
// | ITrainerSignOff
// | IComplianceDocument
// | IReferenceDocument
// | ISimpleTask;
