import { getApps, initializeApp } from 'firebase/app';
import {
  connectFunctionsEmulator,
  getFunctions,
  type HttpsCallableOptions,
  httpsCallable as httpsCallableFirebase,
} from 'firebase/functions';
import { from, type Observable, of, type SchedulerLike } from 'rxjs';
// eslint-disable-next-line no-restricted-imports
import { map, shareReplay, switchMap } from 'rxjs/operators';

export class FirebaseFunctionsScheduler {
  static appName?: string;
  static region: string;
  static useEmulator?: [host: string, port: number];
  static options?: object;
  static scheduler: SchedulerLike;

  static init(
    scheduler: SchedulerLike,
    region: string,
    options: object = {},
    useEmulator: false | [host: string, port: number] = false,
    appName?: string
  ): void {
    FirebaseFunctionsScheduler.setScheduler(scheduler);
    FirebaseFunctionsScheduler.setRegion(region);
    FirebaseFunctionsScheduler.setOptions(options);
    if (useEmulator) {
      FirebaseFunctionsScheduler.setUseEmulator(useEmulator);
    }
    if (appName) {
      FirebaseFunctionsScheduler.setAppName(appName);
    }
  }

  static setScheduler(scheduler: SchedulerLike): void {
    FirebaseFunctionsScheduler.scheduler = scheduler;
  }

  static setAppName(name: string): void {
    FirebaseFunctionsScheduler.appName = name;
  }

  static setRegion(region: string): void {
    FirebaseFunctionsScheduler.region = region;
  }

  static setUseEmulator(useEmulator: [host: string, port: number]): void {
    FirebaseFunctionsScheduler.useEmulator = useEmulator;
  }

  static setOptions(options: object): void {
    FirebaseFunctionsScheduler.options = options;
  }
}

const functions = of(undefined).pipe(
  switchMap(() => import('firebase/functions')),
  map(() => {
    const name = FirebaseFunctionsScheduler.appName
      ? FirebaseFunctionsScheduler.appName
      : '[DEFAULT]';
    const existingApp = getApps().find((app) => app && app.name === name);
    return (
      existingApp ?? initializeApp(FirebaseFunctionsScheduler.options ?? {})
    );
  }),
  map((app) => {
    const region = FirebaseFunctionsScheduler.region;
    const useEmulator = FirebaseFunctionsScheduler.useEmulator;
    const functionsInstance = getFunctions(app, region);
    if (useEmulator) {
      connectFunctionsEmulator(functionsInstance, ...useEmulator);
    }
    return functionsInstance;
  }),
  shareReplay({ bufferSize: 1, refCount: false })
);

export function httpsCallable<T = unknown, R = unknown>(
  name: string,
  options?: HttpsCallableOptions
): (data: T) => Observable<R> {
  return (data: T) =>
    from(functions).pipe(
      switchMap((functionsInstance) =>
        httpsCallableFirebase(functionsInstance, name, options)(data)
      ),
      map((response) => response.data as R)
    );
}
