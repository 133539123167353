import { Injectable } from '@angular/core';
import {
  filterPathwaysByStatus,
  filterSkillsByStatus,
  pathwayStatusByUserStatus,
  SkillStatus,
} from '@principle-theorem/level-up-core';
import { filterUndefined } from '@principle-theorem/shared';
import { combineLatest } from 'rxjs';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';
import { PathwayAndSkillsListBloc } from './pathway-and-skills-list-bloc';

@Injectable()
export class PathwayAndSkillsListService {
  bloc: PathwayAndSkillsListBloc;

  constructor(
    organisation: OrganisationService,
    cachedLists: CachedListsService
  ) {
    const pathways$ = combineLatest([
      organisation.user$,
      cachedLists.pathways$,
    ]).pipe(pathwayStatusByUserStatus(), filterPathwaysByStatus());

    this.bloc = new PathwayAndSkillsListBloc(
      cachedLists.subscriptionBundlePair$,
      pathways$,
      cachedLists.skills$.pipe(filterSkillsByStatus(SkillStatus.Published)),
      organisation.user$.pipe(filterUndefined())
    );
  }
}
