import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Organisation,
  Pathway,
  SkillLevelRequestStatus,
  SkillStatus,
  User,
  filterNotAssignedPathways,
  filterNotStartedPathways,
  filterNotStartedSkills,
  filterSkillsWithDueDate,
  getSkillGoalDueDate$,
  resolvePathwaySkills,
  sortSkillsByDueDate,
  type IPathway,
  type ISkill,
  type IUser,
  PathwayStatus,
  UserGroup,
  SkillLevel,
} from '@principle-theorem/level-up-core';
import {
  BreakpointService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  DocumentReference,
  IProfile,
  ISODateType,
  WithRef,
  filterUndefined,
  firstResult,
  getDoc,
  isSameRef,
  multiFilter,
  multiFind,
  safeCombineLatest,
  saveDoc,
  shareReplayCold,
  snapshot,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
  of,
} from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CachedListsService } from '../../services/cached-lists.service';
import { FavouritesService } from '../../services/favourites.service';
import { MarketplaceCopyService } from '../../services/marketplace-copy.service';
import { OrganisationService } from '../../services/organisation.service';
import { ImageCardPlaceholder } from '../card/card-image/card-image.component';
import { ListCardBloc } from '../skills/skill-list-card/skill-list-card.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

interface INextStep {
  title: string;
  link: string[];
}

@Component({
    selector: 'lu-pathway-list-card',
    templateUrl: './pathway-list-card.component.html',
    styleUrls: ['./pathway-list-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PathwayListCardComponent {
  bloc: ListCardBloc;
  pathway$ = new ReplaySubject<WithRef<IPathway>>(1);
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  skills$: Observable<WithRef<ISkill>[]>;
  dueDate$: Observable<ISODateType | undefined>;
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  link$ = new ReplaySubject<string[]>(1);
  nextStep$: Observable<INextStep>;
  canStartPathway$: Observable<boolean>;
  isMarketplacePathway$: Observable<boolean>;
  isLocalPathway$: Observable<boolean>;
  pathwayCardPlaceholderImage = ImageCardPlaceholder.Pathway;
  compact$ = new ReplaySubject<boolean>(1);
  isPathwayFocused$ = new BehaviorSubject<boolean>(false);
  copiedPathwayTooltip$: Observable<string | undefined>;
  isAlreadyCopied$: Observable<boolean>;
  pathwayStatus = PathwayStatus;

  @Input()
  set compact(isCompact: BooleanInput) {
    this.compact$.next(coerceBooleanProperty(isCompact));
  }

  @Input()
  set link(link: string[]) {
    if (link) {
      this.link$.next(link);
    }
  }

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(
    breakpoint: BreakpointService,
    public favourites: FavouritesService,
    private _organisation: OrganisationService,
    private _cachedList: CachedListsService,
    private _marketplaceCopy: MarketplaceCopyService,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) {
    this.bloc = new ListCardBloc(breakpoint);
    this.skills$ = this.pathway$.pipe(
      resolvePathwaySkills((skillRef) =>
        this._cachedList.getLocalSkill(skillRef)
      ),
      multiFilter((skill) => skill.status === SkillStatus.Published),
      shareReplayCold()
    );

    const goals$ = combineLatest([
      this.user$,
      this._organisation.userGroups$,
    ]).pipe(
      map(([user, userGroup]) => User.getGoals(user, userGroup)),
      shareReplayCold()
    );

    const progress$ = this.user$.pipe(
      switchMap((user) => User.skillProgress$(user))
    );

    this.dueDate$ = this.skills$.pipe(
      filterSkillsWithDueDate(goals$, progress$),
      sortSkillsByDueDate(goals$),
      map((skills) => first(skills)),
      filterUndefined(),
      switchMap((skill) => getSkillGoalDueDate$(skill, goals$))
    );

    const trainingRequests$ = this.user$.pipe(
      switchMap((user) =>
        User.trainingRequests$(user, [SkillLevelRequestStatus.Pending])
      ),
      shareReplayCold()
    );

    this.nextStep$ = combineLatest([this.pathway$, this.link$]).pipe(
      switchMap(([pathway, link]) => {
        return safeCombineLatest(
          Pathway.skillRefs(pathway).map((step) =>
            this._cachedList.getLocalSkill$(step)
          )
        ).pipe(
          map(compact),
          filterNotStartedSkills(trainingRequests$, goals$, progress$),
          map(first),
          filterUndefined(),
          map((nextSkill) => ({
            title: nextSkill.name,
            link: [...link, 'steps', nextSkill.ref.id],
          }))
        );
      })
    );

    this.isMarketplacePathway$ = this.pathway$.pipe(
      map((pathway) => Pathway.isMarketplaceRelease(pathway)),
      shareReplayCold()
    );

    this.isLocalPathway$ = this.isMarketplacePathway$.pipe(
      map((isMarketplacePathway) => !isMarketplacePathway),
      shareReplayCold()
    );

    this.canStartPathway$ = this.pathway$.pipe(
      switchMap((pathway) =>
        combineLatest([
          of([pathway]).pipe(
            filterNotStartedPathways(trainingRequests$, goals$, progress$)
          ),
          of([pathway]).pipe(filterNotAssignedPathways(goals$)),
        ]).pipe(
          map(
            ([notStartedPathways, notAssignedPathways]) =>
              !notStartedPathways.length && !!notAssignedPathways.length
          )
        )
      )
    );

    const copiedPathway$ = combineLatest([
      this.pathway$,
      this._organisation.organisation$.pipe(filterUndefined()),
      this.isMarketplacePathway$,
    ]).pipe(
      switchMap(([pathway, org, isMarketplaceVersion]) =>
        isMarketplaceVersion && pathway.vendorPathwayRef
          ? from(
              firstResult(
                undeletedQuery(Organisation.pathwayCol(org)),
                where('vendorPathwayRef', '==', pathway.vendorPathwayRef)
              )
            )
          : of(undefined)
      ),
      shareReplayCold()
    );

    this.copiedPathwayTooltip$ = copiedPathway$.pipe(
      switchMap((pathway) => {
        if (!pathway?.folderRef) {
          return of(undefined);
        }
        return from(getDoc(pathway.folderRef)).pipe(
          map(
            (folder) =>
              `Skill has already been copied to folder: ${folder.name}`
          )
        );
      }),
      shareReplayCold()
    );

    this.isAlreadyCopied$ = copiedPathway$.pipe(
      map((pathway) => !!pathway),
      startWith(true),
      shareReplayCold()
    );
  }

  pathwayFocused(isFocused: boolean): void {
    this.isPathwayFocused$.next(isFocused);
  }

  getAuthor$(
    authorRef?: DocumentReference<IProfile>
  ): Observable<IProfile | undefined> {
    return this._organisation.users$.pipe(
      multiFind((user) => isSameRef(user, authorRef))
    );
  }

  async copyToFolder(pathway: WithRef<IPathway>): Promise<void> {
    await this._marketplaceCopy.copyPathway(pathway);
  }

  async startLearning(pathway: WithRef<IPathway>): Promise<void> {
    const user = await snapshot(this.user$);
    await saveDoc(
      UserGroup.addPathwayAssociation(user, pathway, SkillLevel.Viewed)
    );
    await this._router.navigate(await snapshot(this.link$));
    this._snackBar.open('Pathway added to your learning list');
  }
}
