import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IntercomEvent,
  UserGroup,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  addDoc,
  deleteDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { startWith } from 'rxjs/operators';
import { PRODUCT_TOUR_CONFIG } from '../../../models/product-tour-config';
import { OrganisationService } from '../../../services/organisation.service';
import {
  GroupAddDialogComponent,
  type GroupFormData,
} from './group-add-dialog/group-add-dialog.component';

@Component({
  selector: 'lu-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsComponent {
  trackByGroup = TrackByFunctions.ref<WithRef<IUserGroup>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IUserGroup>>;
  tourId: number = PRODUCT_TOUR_CONFIG.groups;

  constructor(
    private _organisation: OrganisationService,
    private _dialog: MatDialog,
    private _basicdDialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _intercom: Intercom
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IUserGroup>>(
      this._organisation.userGroups$,
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );
  }

  async addGroup(): Promise<void> {
    const group: GroupFormData | undefined = await this._dialog
      .open<GroupAddDialogComponent, undefined, GroupFormData>(
        GroupAddDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();

    if (!group) {
      return;
    }

    await addDoc(
      await snapshot(this._organisation.userGroupsCol$),
      UserGroup.init(group)
    );

    this._intercom.trackEvent(IntercomEvent.AddedGroup, {
      name: group.name,
    });

    this._snackBar.open('Group Added');
  }

  async remove(userGroup: WithRef<IUserGroup>): Promise<void> {
    const confirm = await this._basicdDialog.confirm({
      title: 'Remove Group',
      prompt: 'Are you sure you want to delete this group?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }
    await deleteDoc(userGroup.ref);
    this._snackBar.open('Group Removed');
  }
}
