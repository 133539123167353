import { Injectable, type OnDestroy } from '@angular/core';
import {
  IFolder,
  MentionResourceType,
  type IFavourite,
  type IPathway,
  type ISkill,
  FavouriteType,
} from '@principle-theorem/level-up-core';
import {
  DocumentReference,
  filterUndefined,
  isSameRef,
  patchDoc,
  shareReplayHot,
  type WithRef,
} from '@principle-theorem/shared';
import { noop, xorWith } from 'lodash';
import { Subject, from, merge, type Observable } from 'rxjs';
import {
  auditTime,
  concatMap,
  map,
  mergeMap,
  scan,
  startWith,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root',
})
export class FavouritesService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _favouriteToggled$ = new Subject<IFavourite>();
  favourites$: Observable<IFavourite[]>;

  constructor(private _organisation: OrganisationService) {
    const savedRecentlyViewed$ = this._organisation.user$.pipe(
      take(1),
      filterUndefined(),
      map((user) => user.favourites ?? []),
      mergeMap((favourites) => from(favourites), 1)
    );

    this.favourites$ = merge(
      savedRecentlyViewed$,
      this._favouriteToggled$
    ).pipe(
      scan(
        (allFavourites, newToggledFavourite) =>
          this._mergeFavourites(newToggledFavourite, allFavourites),
        [] as IFavourite[]
      ),
      startWith([]),
      shareReplayHot(this._onDestroy$)
    );

    this.favourites$
      .pipe(
        auditTime(1000),
        withLatestFrom(this._organisation.user$.pipe(filterUndefined())),
        concatMap(([favourites, user]) =>
          patchDoc(user.ref, {
            favourites,
          })
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toggleFolder(folder: Pick<WithRef<IFolder>, 'ref' | 'name'>): void {
    this._favouriteToggled$.next({
      name: folder.name,
      ref: folder.ref,
      type: MentionResourceType.Folder,
    });
  }

  toggleSkill(skill: Pick<WithRef<ISkill>, 'ref' | 'name'>): void {
    this._favouriteToggled$.next({
      name: skill.name,
      ref: skill.ref,
      type: MentionResourceType.Skill,
    });
  }

  togglePathway(pathway: Pick<WithRef<IPathway>, 'ref' | 'name'>): void {
    this._favouriteToggled$.next({
      name: pathway.name,
      ref: pathway.ref,
      type: MentionResourceType.Pathway,
    });
  }

  isFavourite$(item: DocumentReference<FavouriteType>): Observable<boolean> {
    return this.favourites$.pipe(
      map((favourites) =>
        favourites.length
          ? favourites.some((fav) => isSameRef(fav, item))
          : false
      )
    );
  }

  private _mergeFavourites(
    newToggledFavourite: IFavourite,
    allFavourites: IFavourite[]
  ): IFavourite[] {
    return xorWith([...allFavourites], [newToggledFavourite], isSameRef);
  }
}
