import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanEditPipe } from './can-edit.pipe';
import { CanReviewPipe } from './can-review.pipe';
import { IsApprovedPipe } from './is-approved.pipe';
import { IsDraftPipe } from './is-draft.pipe';
import { InReviewPipe } from './in-review.pipe';
import { TagTextColourPipe } from './tag-text-colour.pipe';

@NgModule({
  declarations: [
    CanEditPipe,
    CanReviewPipe,
    IsApprovedPipe,
    InReviewPipe,
    IsDraftPipe,
    TagTextColourPipe,
  ],
  imports: [CommonModule],
  exports: [
    CanEditPipe,
    CanReviewPipe,
    IsApprovedPipe,
    InReviewPipe,
    IsDraftPipe,
    TagTextColourPipe,
  ],
})
export class PipesModule {}
