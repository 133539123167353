import { getEnumValues } from '@principle-theorem/shared';
import { get } from 'lodash';

export enum SkillLevel {
  None = 'none',
  Viewed = 'viewed',
  VerifiedByTrainer = 'verifiedByTrainer',
  Trainer = 'trainer',
}

export enum GoalProgress {
  Incomplete = 'incomplete',
  Request = 'request',
  Complete = 'complete',
}

export const SKILL_LEVEL_LABEL_MAP: { [key in SkillLevel]: string } = {
  [SkillLevel.None]: 'None',
  [SkillLevel.Viewed]: 'Viewed',
  [SkillLevel.VerifiedByTrainer]: 'Verified By Trainer',
  [SkillLevel.Trainer]: 'Trainer',
};

export const SKILL_LEVEL_COLOR_MAP: { [key in SkillLevel]: string } = {
  [SkillLevel.None]: '#000000',
  [SkillLevel.Viewed]: '#3EC26B',
  [SkillLevel.VerifiedByTrainer]: '#10A5CE',
  [SkillLevel.Trainer]: '#7B42F5',
};

export function skillLevelToNumber(skillLevel: SkillLevel): number {
  const index = getEnumValues(SkillLevel).indexOf(skillLevel);
  return index < 0 ? 0 : index;
}

export function numberToSkillLevel(num: number): SkillLevel | undefined {
  return getEnumValues(SkillLevel)[num];
}

export function skillLevelToLabel(skillLevel: SkillLevel): string {
  return get(SKILL_LEVEL_LABEL_MAP, skillLevel, 'None');
}

export function skillLevelToColor(skillLevel: SkillLevel): string {
  return get(SKILL_LEVEL_COLOR_MAP, skillLevel, 'None');
}
