import { Component, Input } from '@angular/core';
import {
  type OrganisationStatus,
  ORGANISATION_STATUS_MAP,
} from '@principle-theorem/level-up-core';

@Component({
  selector: 'lu-organisation-status',
  templateUrl: './organisation-status.component.html',
  styleUrls: ['./organisation-status.component.scss'],
})
export class OrganisationStatusComponent {
  @Input() status: OrganisationStatus;
  statusColourMap: {
    [key in OrganisationStatus]: string;
  } = ORGANISATION_STATUS_MAP;
}
