import { NgModule } from '@angular/core';
import { TagListItemComponent } from './tag-list-item/tag-list-item.component';
import { EditTagDialogComponent } from './edit-tag-dialog/edit-tag-dialog.component';
import { ColourPickerModule } from '@principle-theorem/ng-shared';
import { TagsInputComponent } from './tags-input/tags-input.component';
import { SearchFieldModule } from '@principle-theorem/ng-shared';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TagDisplayComponent } from './tag-display/tag-display.component';
import { TagAutocompleteComponent } from './tag-autocomplete/tag-autocomplete.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSharedModule,
    ColourPickerModule,
    SearchFieldModule,
    PipesModule,
  ],
  declarations: [
    TagListItemComponent,
    EditTagDialogComponent,
    TagsInputComponent,
    TagDisplayComponent,
    TagAutocompleteComponent,
    TagFilterComponent,
  ],
  exports: [
    TagListItemComponent,
    TagsInputComponent,
    TagDisplayComponent,
    TagAutocompleteComponent,
    TagFilterComponent,
  ],
})
export class TagsModule {}
