<mat-toolbar color="primary">Add new Workspace subscription</mat-toolbar>

<div class="layout-margin" fxLayout="column">
  <mat-form-field>
    <mat-label>Workspace</mat-label>
    <input
      matInput
      placeholder="Select a workspace"
      [matAutocomplete]="auto"
      [formControl]="inputFilter"
    />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="add($event.option.value)"
  >
    <mat-option
      *ngFor="
        let workspace of filteredWorkspaces$ | async;
        trackBy: trackByWorkspace
      "
      [value]="workspace"
    >
      {{ workspace.name }}
    </mat-option>
  </mat-autocomplete>
</div>

<div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
  <button mat-stroked-button mat-dialog-close>Close</button>
</div>
