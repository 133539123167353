import { type ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Injector,
  ViewChild,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import {
  NOTIFICATION_PROVIDERS,
  User,
  type IUser,
} from '@principle-theorem/level-up-core';
import { AuthService, WorkspaceService } from '@principle-theorem/ng-auth';
import { hasProvider$ } from '@principle-theorem/ng-notifications';
import {
  BasicDialogService,
  BreakpointService,
  ConnectedDialogConfig,
  ProfileImageService,
} from '@principle-theorem/ng-shared';
import { type INotification } from '@principle-theorem/notifications';
import {
  asBoolean,
  filterUndefined,
  limit,
  multiFilter,
  multiSwitchMap,
  orderBy,
  query$,
  reduceToSingleArray,
  shareReplayCold,
  slugify,
  snapshot,
  toTimestamp,
  where,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, merge, of, ReplaySubject, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ManagementService } from '../../auth/management.service';
import {
  INotificationDialogData,
  NotificationDialogComponent,
} from '../../components/notifications/notification-dialog/notification-dialog.component';
import { type ISearchAction } from '../../components/search-dialog/search-action';
import { OrganisationService } from '../../services/organisation.service';
import { VendorService } from '../../services/vendor.service';
import {
  ADMIN_LINK_GROUP,
  MANAGEMENT_LINK_GROUP,
  MARKETPLACE_LINK_GROUP,
  SKILLS_LINK_GROUP,
  type ILinkGroup,
} from './sidebar-links';

@Component({
  selector: 'lu-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 300,
        position: 'after',
      },
    },
  ],
})
export class SidebarComponent {
  private _connectedTo$: ReplaySubject<ElementRef> = new ReplaySubject(1);
  profileImage = inject(ProfileImageService);
  groups$: Observable<ILinkGroup[]>;
  orgUser$: Observable<WithRef<IUser> | undefined>;
  workspace$: Observable<string>;
  canChangeWorkspace$: Observable<boolean>;
  notifications$: Observable<WithRef<INotification>[]>;
  notificationsOpen = false;
  notificationsCount$: Observable<number>;

  constructor(
    public auth: AuthService,
    organisationService: OrganisationService,
    vendor: VendorService,
    management: ManagementService,
    workspace: WorkspaceService,
    private _dialog: BasicDialogService,
    private _breakpoint: BreakpointService,
    private _injector: Injector
  ) {
    this.orgUser$ = merge(organisationService.user$, vendor.user$);
    this.workspace$ = merge(
      organisationService.organisation$,
      vendor.vendor$
    ).pipe(
      filterUndefined(),
      map((currentWorkspace) => slugify(currentWorkspace.name))
    );

    const orgLinks$: Observable<ILinkGroup[]> = combineLatest([
      organisationService.organisation$.pipe(filterUndefined()),
      organisationService.user$.pipe(filterUndefined()),
    ]).pipe(
      map(([organisation, user]) => {
        const links: ILinkGroup[] = [];
        if (organisation) {
          links.push(SKILLS_LINK_GROUP);
          if (user.isAdmin) {
            links.push(ADMIN_LINK_GROUP);
          }
        }
        return links;
      })
    );

    const vendorLinks$: Observable<ILinkGroup[]> = vendor.vendor$.pipe(
      asBoolean(),
      map((hasVendor) => (hasVendor ? [MARKETPLACE_LINK_GROUP] : []))
    );

    const managementLinks$: Observable<ILinkGroup[]> = management.user$.pipe(
      asBoolean(),
      map((isManager) => (isManager ? [MANAGEMENT_LINK_GROUP] : []))
    );

    this.groups$ = combineLatest([
      orgLinks$.pipe(startWith([])),
      vendorLinks$.pipe(startWith([])),
      managementLinks$.pipe(startWith([])),
    ]).pipe(
      map((groups) => reduceToSingleArray(groups)),
      shareReplayCold()
    );

    this.canChangeWorkspace$ = workspace.workspaces$.pipe(
      map((workspaces) => workspaces.length > 1)
    );

    this.notifications$ = organisationService.user$.pipe(
      filterUndefined(),
      switchMap((user) =>
        query$(
          User.notificationCol(user),
          where('createdAt', '>=', toTimestamp(moment().subtract(1, 'year'))),
          where('viewed', '==', false),
          orderBy('createdAt', 'desc'),
          limit(30)
        )
      ),
      shareReplayCold()
    );

    this.notificationsCount$ = organisationService.user$.pipe(
      filterUndefined(),
      switchMap((user) =>
        this.notifications$.pipe(
          multiSwitchMap((notification) =>
            of(notification).pipe(hasProvider$(NOTIFICATION_PROVIDERS, user))
          ),
          multiFilter((notification) => notification),
          map((notifications) => notifications.length)
        )
      ),
      shareReplayCold()
    );
  }

  @ViewChild('notificationButton', { static: false, read: ElementRef })
  set connectedTo(connectedTo: ElementRef) {
    this._connectedTo$.next(connectedTo);
  }

  async openNotifications($event: Event): Promise<void> {
    $event.stopPropagation();
    const connectedTo = await snapshot(this._connectedTo$);
    const isMobile = await snapshot(this._breakpoint.isMobile$);
    this._dialog.connected(
      NotificationDialogComponent,
      this._getNotificaitonDialogConfig(connectedTo, isMobile, {
        notifications$: this.notifications$,
      })
    );
  }

  async runAction(
    $event: MouseEvent,
    action: ComponentType<ISearchAction>
  ): Promise<void> {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    const actionInstance = this._injector.get<ISearchAction>(action);
    await actionInstance.do();
  }

  private _getNotificaitonDialogConfig(
    connectedTo: ElementRef,
    isMobile: boolean,
    data: INotificationDialogData
  ): ConnectedDialogConfig<INotificationDialogData> {
    if (isMobile) {
      return {
        data,
        hasBackdrop: true,
        width: '90vh',
        height: '90vh',
      };
    }

    return {
      connectedTo,
      data,
      hasBackdrop: true,
      width: '380px',
      maxHeight: '80vh',
      positions: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ],
    };
  }
}
