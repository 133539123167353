import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'lu-card-actions',
    templateUrl: './card-actions.component.html',
    styleUrls: ['./card-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardActionsComponent {}
