import { type ITag, Tag } from '@principle-theorem/level-up-core';
import {
  addDoc,
  getDoc$,
  randomHexColour,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';

export class RandomTagFactory {
  constructor(private _collection: CollectionReference<ITag>) {}

  async create(name: string): Promise<WithRef<ITag>> {
    const newTag: ITag = this._randomNewTag(name);
    const docRef: DocumentReference<ITag> = await addDoc(
      this._collection,
      newTag
    );
    return snapshot(getDoc$(this._collection, docRef.id));
  }

  private _randomNewTag(name: string): ITag {
    return Tag.init({
      name,
      hexColour: randomHexColour(),
    });
  }
}
