import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { snapshot, toInt } from '@principle-theorem/shared';
import { TrackByFunctions } from '../../track-by';
import { TypedFormControl } from '../../forms/typed-form-group';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, withLatestFrom, map } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { Colord, colord } from 'colord';

@Component({
    selector: 'pt-event-colour-picker',
    templateUrl: './event-colour-picker.component.html',
    styleUrls: ['./event-colour-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventColourPickerComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  selectedColour$ = new ReplaySubject<Colord>(1);
  hexColour$: Observable<string>;
  palette$ = new ReplaySubject<string[]>(1);
  initialColour$ = new ReplaySubject<string>(1);
  opacityCtrl = new TypedFormControl<number>();
  opacityMin = 0.2;
  opacityMax = 1;
  trackByColour = TrackByFunctions.variable<string>();
  trackByIndex = TrackByFunctions.index();
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  @Input() disableOpacity = false;
  @Output() colourSelected = new EventEmitter<string>();

  @Input()
  set colour(colour: string) {
    if (colour) {
      this.selectColour(colour);
    }
  }

  @Input()
  set palette(palette: string[]) {
    if (palette) {
      this.palette$.next(palette);
    }
  }

  constructor() {
    this.opacityCtrl.valueChanges
      .pipe(withLatestFrom(this.selectedColour$), takeUntil(this._onDestroy$))
      .subscribe(([alpha, colour]) =>
        this.selectedColour$.next(colour.alpha(alpha))
      );

    this.hexColour$ = this.selectedColour$.pipe(
      map((colour) => colour.toHex())
    );
  }

  formatPercentage(alpha: number): string {
    return `${toInt(alpha * 100)}%`;
  }

  selectColour(colour: string, $event?: MouseEvent): void {
    if ($event) {
      $event.stopPropagation();
    }
    const convertedColour = colord(colour);
    this.selectedColour$.next(convertedColour);
    this.opacityCtrl.setValue(convertedColour.alpha());
  }

  async save(): Promise<void> {
    const colour = await snapshot(this.selectedColour$);
    this.colourSelected.emit(colour.toHex());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
