import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  type IVendorBundle,
  BundleVisibility,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { getEnumValues } from '@principle-theorem/shared';

export interface IEditVendorBundleData {
  bundle: IVendorBundle;
}

export type EditVendorBundleFormData = Omit<
  IVendorBundle,
  'imageUrl' | 'deleted'
>;

@Component({
    selector: 'lu-edit-vendor-dialog-bundle',
    templateUrl: './edit-vendor-bundle-dialog.component.html',
    styleUrls: ['./edit-vendor-bundle-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditVendorBundleDialogComponent {
  trackByVisibility = TrackByFunctions.variable<BundleVisibility>();
  visibilityOptions: BundleVisibility[] = getEnumValues(BundleVisibility);
  form = new TypedFormGroup<EditVendorBundleFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    addToNewWorkspaces: new TypedFormControl<boolean>(
      false,
      Validators.required
    ),
    assignToDefaultGroup: new TypedFormControl<boolean>(false),
    readOnly: new TypedFormControl<boolean>(false, Validators.required),
    visibility: new TypedFormControl<BundleVisibility>(
      BundleVisibility.Private,
      Validators.required
    ),
    description: new TypedFormControl<string>(''),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      EditVendorBundleDialogComponent,
      EditVendorBundleFormData | undefined
    >,
    @Inject(MAT_DIALOG_DATA) data: IEditVendorBundleData
  ) {
    this.form.patchValue(data.bundle);
  }

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
