import {
  type ObjectOfType,
  reduceToSingleArray,
} from '@principle-theorem/shared';
import {
  type MarkSpec,
  type NodeSpec,
  type SchemaSpec,
} from '@tiptap/pm/model';
import { type Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { Editor } from '@tiptap/core';

export type DispatchFn = (tr: Transaction) => void;
export type CommandReturn = (editor: Editor) => void;

export type MenuButtonType = 'basic' | 'block' | 'submenu' | 'submenu-detail';

export interface IMenuButton {
  buttonType: MenuButtonType;
  buttonText: string;
  icon: string;
  tooltip: string;
  shortcut?: string;
  command: CommandReturn;
  canActivateFn?: (editor: Editor) => boolean;
  isActiveFn?: (editor: Editor) => boolean;
  isDisabledFn?: (editor: Editor) => boolean | string;
}

export type GetAttrsFn<T = string> = (data: T) => object | undefined;

export type MarkSpecStrict = Pick<
  MarkSpec,
  | 'attrs'
  | 'inclusive'
  | 'excludes'
  | 'group'
  | 'spanning'
  | 'toDOM'
  | 'parseDOM'
>;

export type NodeSpecStrict = Pick<
  NodeSpec,
  | 'content'
  | 'marks'
  | 'group'
  | 'inline'
  | 'atom'
  | 'attrs'
  | 'selectable'
  | 'draggable'
  | 'code'
  | 'defining'
  | 'isolating'
  | 'toDOM'
  | 'parseDOM'
  | 'toDebugString'
>;

export interface IExtensionSchemaOptions<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  N extends string = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  M extends string = any,
> extends Pick<SchemaSpec<N, M>, 'marks' | 'nodes'> {
  nodes: { [name in N]: NodeSpecStrict };
  marks: { [name in M]: MarkSpecStrict };
  plugins: ExtensionRegisterReturnFn<Plugin>[];
}

export type ExtensionRegisterReturnFn<ReturnType = void> = (
  editor: Editor
) => ReturnType;

export function labelPlugin(label: string, plugin: Plugin): Plugin {
  plugin.spec.key = new PluginKey(label);
  return plugin;
}

export function mergeExtensions(
  ...extensions: Partial<IExtensionSchemaOptions>[]
): Partial<IExtensionSchemaOptions> {
  return {
    nodes: extensions.reduce(
      (nodes, extension) => ({ ...nodes, ...(extension.nodes || {}) }),
      {} as ObjectOfType<NodeSpecStrict>
    ),
    marks: extensions.reduce(
      (marks, extension) => ({ ...marks, ...extension.marks }),
      {} as ObjectOfType<MarkSpecStrict>
    ),
    plugins: reduceToSingleArray(
      extensions.map((extension) => extension.plugins || [])
    ),
  };
}
