import {
  Component,
  ChangeDetectionStrategy,
  Input,
  inject,
} from '@angular/core';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import { type IProfile } from '@principle-theorem/shared';

@Component({
    selector: 'lu-author',
    templateUrl: './author.component.html',
    styleUrls: ['./author.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AuthorComponent {
  profileImage = inject(ProfileImageService);
  @Input() author: IProfile;
}
