import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  type MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { type MatChipInputEvent } from '@angular/material/chips';
import { isTag, type ITag, Tag } from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  addDoc,
  getDoc,
  isSameRef,
  randomHexColour,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-tag-autocomplete',
    templateUrl: './tag-autocomplete.component.html',
    styleUrls: ['./tag-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagAutocompleteComponent {
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  selectedTags$: BehaviorSubject<WithRef<ITag>[]> = new BehaviorSubject<
    WithRef<ITag>[]
  >([]);
  inputFilter: TypedFormControl<string> = new TypedFormControl<string>('');
  availableTags$: Observable<WithRef<ITag>[]>;
  filteredTags$: Observable<WithRef<ITag>[]>;
  isFocused: boolean = false;

  @Output() tagsChange: EventEmitter<WithRef<ITag>[]> = new EventEmitter<
    WithRef<ITag>[]
  >();

  @ViewChild('tagInput')
  tagInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto')
  matAutocomplete: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger)
  matAutocompleteTrigger: MatAutocompleteTrigger;

  @Input()
  set tags(tags: WithRef<ITag>[]) {
    if (!tags) {
      return;
    }
    this.selectedTags$.next(tags);
  }

  constructor(
    private _organisation: OrganisationService,
    cachedLists: CachedListsService
  ) {
    this.availableTags$ = cachedLists.tags$;

    this.filteredTags$ = combineLatest([
      this.inputFilter.valueChanges.pipe(startWith('')),
      combineLatest([this.availableTags$, this.selectedTags$]).pipe(
        map(([availableTags, selectedTags]) => {
          return compact(
            availableTags.map((tag) => {
              return selectedTags.find((selectedTag) => {
                return isSameRef(selectedTag, tag);
              })
                ? undefined
                : tag;
            })
          );
        })
      ),
    ]).pipe(
      map(([value, tags]: [string, WithRef<ITag>[]]) =>
        this._filter(value, tags)
      )
    );
  }

  selectTag(tag?: WithRef<ITag>): void {
    if (!tag) {
      return;
    }
    this.tagInput.nativeElement.value = '';
    this.inputFilter.setValue('');
    this.matAutocompleteTrigger.closePanel();
    const tags: WithRef<ITag>[] = this.selectedTags$.value.slice();
    tags.push(tag);
    this.selectedTags$.next(tags);
    this.tagsChange.emit(tags);
  }

  async createTag(event: MatChipInputEvent): Promise<void> {
    const newTagName: string = event.value.trim();
    if (this.matAutocomplete.isOpen || !newTagName) {
      return;
    }
    event.input.value = '';

    const tag: ITag = Tag.init({
      name: newTagName,
      hexColour: randomHexColour(),
    });

    const tagCol = await snapshot(this._organisation.tagCol$);
    const ref = await addDoc(tagCol, tag);
    const newTag: WithRef<ITag> = await getDoc(ref);
    this.selectTag(newTag);
    this.inputFilter.setValue('');
  }

  isAssociated(task: WithRef<ITag>): boolean {
    return this.selectedTags$.value.find((currentTagRef: WithRef<ITag>) => {
      return isSameRef(currentTagRef, task);
    })
      ? true
      : false;
  }

  remove(tag: WithRef<ITag>): void {
    const tags: WithRef<ITag>[] = this.selectedTags$.value
      .slice()
      .filter((oldTag: WithRef<ITag>) => !isSameRef(oldTag, tag));
    this.selectedTags$.next(tags);
    return this.tagsChange.emit(tags);
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }

  private _filter(
    value: string | WithRef<ITag>,
    tags: WithRef<ITag>[]
  ): WithRef<ITag>[] {
    if (isTag(value)) {
      return [];
    }

    return tags
      .filter((tag: WithRef<ITag>) => !this.isAssociated(tag))
      .filter((tag: WithRef<ITag>) => {
        return tag.name.toLowerCase().includes(value.toLowerCase());
      });
  }
}
