import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPathway, type IUser } from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { doc$, type IProfile, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, of, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImageCardPlaceholder } from '../../card-image/card-image.component';

@Component({
    selector: 'lu-pathway-card',
    templateUrl: './pathway-card.component.html',
    styleUrls: ['./pathway-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PathwayCardComponent {
  pathway$: ReplaySubject<WithRef<IPathway>> = new ReplaySubject(1);
  author$: Observable<WithRef<IProfile> | undefined>;
  pathwayCardPlaceholderImage: ImageCardPlaceholder =
    ImageCardPlaceholder.Pathway;
  @Input() link: RouterLinkData;
  @Input() user: WithRef<IUser>;

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  constructor() {
    this.author$ = this.pathway$.pipe(
      switchMap((data) => (data.author ? doc$(data.author) : of(undefined)))
    );
  }
}
