<mat-list-item>
  <lu-user-skill-level-indicator
    class="!h-8 !w-8"
    matListItemIcon
    [user]="user$ | async"
    [skill]="skill$ | async"
  />
  <span matListItemTitle>
    <div *ngIf="user$ | async as user">
      {{ user.name }}
    </div>
  </span>

  <div matListItemMeta>
    <div class="flex flex-row items-center">
      <lu-user-goal-due-date-indicator
        [canEdit]="canEdit$ | async"
        [dueDate]="progress.dueDateBloc.dueDate$ | async"
        (dateChange)="updateDueDate($event)"
      />

      <lu-user-skill-progress
        [user]="user$ | async"
        [skill]="skill$ | async"
        [canEdit]="canEdit$ | async"
      />
    </div>
  </div>
</mat-list-item>
