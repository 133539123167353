<ng-container *ngIf="pathway$ | async as pathway">
  <ng-container *ngIf="section$ | async as section">
    <div class="flex flex-col">
      <div class="flex items-stretch py-4 pr-2">
        <div class="flex grow items-center gap-4 px-2">
          <mat-icon cdkDragHandle class="!cursor-move">drag_handle</mat-icon>
          <div>
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Section Name</mat-label>
              <input
                matInput
                autocomplete="off"
                type="text"
                placeholder="Set a Section name (leave blank for no label)"
                [formControl]="nameCtrl"
              />
            </mat-form-field>
          </div>
        </div>
        <button
          type="button"
          mat-icon-button
          color="warn"
          (click)="deleteSection(section, pathway)"
        >
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </div>

      <mat-list
        class="!-mx-2"
        *ngIf="steps$ | async as steps"
        cdkDropList
        [cdkDropListData]="section"
        (cdkDropListDropped)="drop($event, pathway)"
        ptIntercomTarget="section.steps"
      >
        <ng-container *ngFor="let step of steps; trackBy: trackByStep">
          <mat-list-item
            cdkDrag
            cdkDragLockAxis="y"
            class="group/list-item mat-mdc-list-item-interactive mat-mdc-list-option"
            (click)="editStep(step)"
          >
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
              <div fxLayout="row" fxLayoutGap="16px">
                <mat-icon
                  class="group/drag-handle invisible !cursor-move group-hover/list-item:visible"
                  cdkDragHandle
                  (click)="$event.stopPropagation()"
                  >drag_handle</mat-icon
                >
                <div>{{ step.name }}</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center">
                <button
                  class="group/remove invisible group-hover/list-item:visible"
                  mat-icon-button
                  color="warn"
                  (click)="
                    $event.stopPropagation(); deleteStep(step, pathway, section)
                  "
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
        </ng-container>

        <div class="mt-2 flex gap-2 px-4">
          <button
            mat-stroked-button
            class="flex-1"
            (click)="addExistingSkill(pathway, section)"
          >
            <mat-icon>insert_drive_file</mat-icon>
            <span>Add Existing Skill</span>
          </button>
          <button
            mat-stroked-button
            class="flex-1"
            (click)="addNewStep(pathway, section)"
          >
            <mat-icon>note_add</mat-icon>
            <span>Add New Skill</span>
          </button>
        </div>
      </mat-list>
    </div>
  </ng-container>
</ng-container>
