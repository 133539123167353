<h3>Email Notification Preferences</h3>

<mat-selection-list
  #selectedPreferences
  (selectionChange)="
    updateEnabledNotifications(selectedPreferences.selectedOptions.selected)
  "
>
  <mat-list-option
    *ngFor="let preference of preferences$ | async; trackBy: trackByPreference"
    [selected]="preference.enabled"
    [value]="preference.id"
  >
    <span matListItemTitle>{{ preference.label }}</span>
    <small class="description" matListItemLine>{{
      preference.description
    }}</small>
  </mat-list-option>
</mat-selection-list>
