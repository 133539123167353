<lu-page-simple *ngIf="user$ | async as user; else loader">
  <lu-page-header [label]="user.name" />
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <p class="mat-body-2">
      {{ user.email }}
    </p>
    <mat-icon *ngIf="user.isOwner" matTooltip="Owner">
      verified_user
    </mat-icon>
    <mat-icon *ngIf="user.isAdmin" matTooltip="Admin User">
      security
    </mat-icon>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button mat-button color="warn" (click)="toggleUserEnabled(user)">
      {{ user.isActivated ? 'Disable' : 'Enable' }}
    </button>
    <button
      *ngIf="canInvite(user)"
      mat-button
      color="accent"
      (click)="invite(user)"
    >
      Resend Invitation
    </button>
  </div>
</lu-page-simple>

<ng-template #loader>
  <mat-progress-bar mode="indeterminate" />
</ng-template>
