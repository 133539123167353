import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPublicPathway } from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { doc$, type IProfile, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, of, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImageCardPlaceholder } from '../../card-image/card-image.component';

@Component({
    selector: 'lu-public-pathway-card',
    templateUrl: './public-pathway-card.component.html',
    styleUrls: ['./public-pathway-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PublicPathwayCardComponent {
  pathway$: ReplaySubject<WithRef<IPublicPathway>> = new ReplaySubject(1);
  author$: Observable<WithRef<IProfile> | undefined>;
  pathwayCardPlaceholderImage: ImageCardPlaceholder =
    ImageCardPlaceholder.Pathway;
  @Input() link: RouterLinkData;

  @Input()
  set pathway(pathway: WithRef<IPublicPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  constructor() {
    this.author$ = this.pathway$.pipe(
      switchMap((data) => (data.author ? doc$(data.author) : of(undefined)))
    );
  }
}
