import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'lu-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(public location: Location) {}
}
