<lu-vendor-bundle-card
  class="grow"
  *ngIf="vendorBundle$ | async as vendorBundle"
  [link]="link"
  [vendorBundle]="vendorBundle"
/>

<lu-subscription-card
  class="grow"
  *ngIf="subscription$ | async as subscription"
  [link]="link"
  [subscription]="subscription"
  [user]="user"
/>

<lu-pathway-card
  class="grow"
  *ngIf="pathway$ | async as pathway"
  [link]="link"
  [pathway]="pathway"
  [user]="user"
/>

<lu-skill-card
  class="grow"
  *ngIf="skill$ | async as skill"
  [link]="link"
  [skill]="skill"
  [user]="user"
/>
