import { IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { AITextPromptMenuComponent } from './ai-text-prompt-menu/ai-text-prompt-menu.component';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
import { Editor } from '@tiptap/core';

export const TEXT_PROMPT_DATA: IMenuButton = {
  buttonType: 'submenu',
  buttonText: 'Generate Text',
  tooltip: `Generate text from your prompt`,
  icon: 'edit_note',
  canActivateFn: () => true,
  command: generateTextCommand,
};

function generateTextCommand(editor: Editor): boolean {
  const hasContent = editor.state.doc.textContent.trim().length > 0;
  const chain = editor.chain();

  if (!editor.isFocused) {
    chain.focus();

    if (!hasContent) {
      chain.setParagraph();
    } else {
      chain.setTextSelection(0);
    }
  }

  return chain.aiTextPrompt().run();
}

export function createTextPromptButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: AITextPromptMenuComponent,
    data: TEXT_PROMPT_DATA,
  });
}

export function createTextPromptBlockButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: AITextPromptMenuComponent,
    data: {
      ...TEXT_PROMPT_DATA,
      buttonType: 'block',
    },
  });
}
