/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DialogRef } from '@angular/cdk/dialog';
import { type NodeGroup } from '@principle-theorem/editor';
import { isObject } from '@principle-theorem/shared';
import { NodeConfig } from '@tiptap/core';
import { isString } from 'lodash';
import {
  type DOMOutputSpec,
  type Node,
  type ParseRule,
} from '@tiptap/pm/model';
import 'reflect-metadata';

/**
 * Decorator for Angular components that will be used as Prosemirror Nodes
 */
// eslint-disable-next-line @typescript-eslint/ban-types,  @typescript-eslint/naming-convention
export function EditorNode(config: IExtensionConfig & INodeConfig): Function {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (constructor: Function): void {
    Reflect.defineMetadata('name', config.name, constructor.prototype);

    const schema: NodeConfig = {
      ...config,
      ...config.metadata,
    };

    if (hasCommands(constructor.prototype)) {
      schema.addCommands = constructor.prototype.addCommands;
    }

    if (hasProsemirrorPlugins(constructor.prototype)) {
      schema.addProseMirrorPlugins =
        constructor.prototype.addProseMirrorPlugins;
    }

    if (hasGlobalAttributes(constructor.prototype)) {
      schema.addGlobalAttributes = constructor.prototype.addGlobalAttributes;
    }

    if (hasParseDom(constructor.prototype)) {
      schema.parseHTML = constructor.prototype.parseHTML;
    }

    if (hasToText(constructor.prototype)) {
      // eslint-disable-next-line
      schema.toDebugString = (node: Node) => constructor.prototype.toText(node);
    }

    if (hasSerialisingDom(constructor.prototype)) {
      schema.renderHTML = (props: IRenderHTMLArguments) =>
        // eslint-disable-next-line
        constructor.prototype.renderHTML(props);
    }

    Reflect.defineMetadata('schema', schema, constructor.prototype);
  };
}

export interface IRenderHTMLArguments {
  node: Node;
  HTMLAttributes: object;
}

export interface IExtensionConfig {
  name: string;
  metadata?: object;
}

export interface INodeConfig extends NodeConfig {
  group: NodeGroup;
}

export interface IDomParsing {
  parseHTML(): ParseRule[];
}

export interface ITextSerialising {
  toText(): string;
}

export function hasParseDom(item: object): item is IDomParsing {
  return (
    isObject(item) && 'parseHTML' in item && typeof item.parseDOM === 'function'
  );
}

export function hasToText(item: object): item is ITextSerialising {
  return (
    isObject(item) && 'toText' in item && typeof item.toText === 'function'
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHasCommands extends Pick<NodeConfig, 'addCommands'> {}

export function hasCommands(item: object): item is IHasCommands {
  return (
    isObject(item) &&
    'addCommands' in item &&
    typeof item.addCommands === 'function'
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHasProsemirrorPlugins
  extends Pick<NodeConfig, 'addProseMirrorPlugins'> {}

export function hasProsemirrorPlugins(
  item: object
): item is IHasProsemirrorPlugins {
  return (
    isObject(item) &&
    'addProseMirrorPlugins' in item &&
    typeof item.addProseMirrorPlugins === 'function'
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGlobalAttributes
  extends Pick<NodeConfig, 'addGlobalAttributes'> {}

export function hasGlobalAttributes(item: object): item is IGlobalAttributes {
  return (
    isObject(item) &&
    'addGlobalAttributes' in item &&
    typeof item.addGlobalAttributes === 'function'
  );
}

export interface IDomSerialising {
  renderHTML(data: { node: Node }): DOMOutputSpec;
}

export function hasSerialisingDom(item: object): item is IDomSerialising {
  return (
    isObject(item) &&
    'renderHTML' in item &&
    typeof item.renderHTML === 'function'
  );
}

export interface IHasFloatingDialog<ReturnData = unknown, Component = unknown> {
  dialogRef: DialogRef<ReturnData, Component> | undefined;
}

export function hasFloatingDialog(item: object): item is IHasFloatingDialog {
  return (
    isObject(item) && 'dialogRef' in item && item.dialogRef instanceof DialogRef
  );
}

export interface IConfigurable<T> {
  configure(data: T): void;
}

export function canConfigureExtension(
  item: object
): item is IConfigurable<unknown> {
  return (
    isObject(item) &&
    'configure' in item &&
    typeof item.configure === 'function'
  );
}

export interface IHasUid {
  uid: string;
}

export function hasUid(item: unknown): item is IHasUid {
  return isObject(item) && isString(item.uid);
}
