import { Pipe, type PipeTransform } from '@angular/core';
import { toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { Observable, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({
    name: 'fromNow$',
    standalone: false
})
export class FromNowAsyncPipe implements PipeTransform {
  transform(
    value: Timestamp | Moment,
    withoutPrefix?: boolean
  ): Observable<string> {
    return interval(10000).pipe(
      map(() => toMoment(value).fromNow(withoutPrefix)),
      startWith(toMoment(value).fromNow(withoutPrefix))
    );
  }
}
