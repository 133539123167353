import { type IEnvironment } from './ienvironment';
import npmPackage from '../../../../package.json';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: IEnvironment = {
  production: false,
  useEmulator: false,
  version: npmPackage.version,
  appName: 'Level Up',
  firebase: {
    apiKey: 'AIzaSyDMlVUGN2PPm84bQY8Id2MrCOi53zFu4mE',
    authDomain: 'dev.levelup.dental',
    databaseURL: 'https://level-up-7104c.firebaseio.com',
    projectId: 'level-up-7104c',
    storageBucket: 'level-up-7104c.appspot.com',
    messagingSenderId: '180092972665',
    appId: '1:180092972665:web:cba1710eb4bfb50a',
  },
  ngxAuthFirebaseUI: {
    enableFirestoreSync: false,
    toastMessageOnAuthSuccess: true,
    toastMessageOnAuthError: false,
    authGuardFallbackURL: '/login',
    authGuardLoggedInURL: '/',
    passwordMaxLength: 60,
    passwordMinLength: 7,
    nameMaxLength: 50,
    nameMinLength: 2,
    guardProtectedRoutesUntilEmailIsVerified: true,
  },
  passwordlessLogin: {
    url: 'http://localhost:4200/',
    handleCodeInApp: true,
  },
  intercom: {
    appId: 'lqlvhoc5',
    secretKey: 'zVetS5KyrDMwsTUMID_3a1uoVULVfcH1OMJZgkW5',
    appName: 'Level Up',
  },
  analytics: {
    gst: {
      trackingIds: ['UA-142996921-4'],
    },
  },
  stackdriver: {
    key: 'AIzaSyCg1XX_osTJxkjbNGvdK3T_A7i2sfXQ__A',
    projectId: 'level-up-7104c',
  },
  chargebee: {
    accountId: 'principle-test',
    apiKey: 'test_xyZ5gQwcdUsctAfdzuVv157AX2cdv6IkIT',
    planIds: {
      monthly: 'level-up-monthly-v2',
      yearly: 'level-up-yearly-v2',
    },
  },
  appUrl: 'https://dev.levelup.dental',
  websiteURL: 'https://level-up.flywheelstaging.com/',
  logRocketKey: 'h6s5jg/level-up-dev',
  typesense: {
    nodes: [
      {
        host: '4yjqomlhrpb195iap-1.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
    apiKey: 'NM5XDeTqftuewBs8qzvm2BCxgTQgRdpd',
  },
  tipTap: {
    appId: '8dwony02',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
