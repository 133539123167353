import {
  type AtLeast,
  type IReffable,
  type WithRef,
  all$,
  find$,
  randomHexColour,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { type CollectionReference, where } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { type IPathway, type IPublicPathway } from '../pathway/pathway';
import { type IPublicSkill, type ISkill } from '../skill/skill';
import { type ITag } from '../tag/tag';
import {
  BundleReleaseCollection,
  BundleReleaseStatus,
  type IBundlePathway,
  type IBundleRelease,
  type IBundleTag,
  type IBundleTemplate,
} from './bundle';

export class VendorTag {
  static init(overrides: AtLeast<IBundleTag, 'name'>): IBundleTag {
    return {
      hexColour: randomHexColour(),
      deleted: false,
      ...overrides,
    };
  }
}

export class VendorBundleRelease {
  static init(overrides?: Partial<IBundleRelease>): IBundleRelease {
    return {
      name: '',
      description: '',
      status: BundleReleaseStatus.Draft,
      content: {
        pathways: [],
        skills: [],
        templates: [],
        tags: [],
      },
      ...overrides,
    };
  }

  static resolvePathway$(
    release: WithRef<IBundleRelease>,
    bundlePathway: WithRef<IBundlePathway>
  ): Observable<WithRef<IPathway> | undefined> {
    const pathwayCol = subCollection<IPathway>(
      release,
      BundleReleaseCollection.Pathways
    );
    return find$(
      pathwayCol,
      where('deleted', '!=', true),
      where('vendorPathwayRef', '==', bundlePathway.ref)
    );
  }

  static skillCol(
    release: IReffable<IBundleRelease>
  ): CollectionReference<ISkill> {
    return subCollection<ISkill>(release.ref, BundleReleaseCollection.Skills);
  }

  static skills$(
    release: IReffable<IBundleRelease>
  ): Observable<WithRef<ISkill>[]> {
    return all$(VendorBundleRelease.skillCol(release));
  }

  static publicSkillCol(
    release: IReffable<IBundleRelease>
  ): CollectionReference<IPublicSkill> {
    return subCollection<IPublicSkill>(
      release.ref,
      BundleReleaseCollection.PublicSkills
    );
  }

  static publicSkills$(
    release: IReffable<IBundleRelease>
  ): Observable<WithRef<IPublicSkill>[]> {
    return all$(VendorBundleRelease.publicSkillCol(release));
  }

  static pathwayCol(
    release: IReffable<IBundleRelease>
  ): CollectionReference<IPathway> {
    return subCollection<IPathway>(
      release.ref,
      BundleReleaseCollection.Pathways
    );
  }

  static pathways$(
    release: IReffable<IBundleRelease>
  ): Observable<WithRef<IPathway>[]> {
    return all$(VendorBundleRelease.pathwayCol(release));
  }

  static publicPathwayCol(
    release: IReffable<IBundleRelease>
  ): CollectionReference<IPublicPathway> {
    return subCollection<IPublicPathway>(
      release.ref,
      BundleReleaseCollection.PublicPathways
    );
  }

  static publicPathways$(
    release: IReffable<IBundleRelease>
  ): Observable<WithRef<IPublicPathway>[]> {
    return all$(undeletedQuery(VendorBundleRelease.publicPathwayCol(release)));
  }

  static tagCol(release: IReffable<IBundleRelease>): CollectionReference<ITag> {
    return subCollection<ITag>(release.ref, BundleReleaseCollection.Tags);
  }

  static tags$(
    release: IReffable<IBundleRelease>
  ): Observable<WithRef<ITag>[]> {
    return all$(VendorBundleRelease.tagCol(release));
  }

  static templateCol(
    release: IReffable<IBundleRelease>
  ): CollectionReference<IBundleTemplate> {
    return subCollection<IBundleTemplate>(
      release.ref,
      BundleReleaseCollection.Templates
    );
  }

  static templates$(
    release: IReffable<IBundleRelease>
  ): Observable<WithRef<IBundleTemplate>[]> {
    return all$(VendorBundleRelease.templateCol(release));
  }
}
