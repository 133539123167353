import {
  type ISkill,
  type ITag,
  MentionResourceType,
  IPathway,
} from '@principle-theorem/level-up-core';
import { type DocumentReference } from '@principle-theorem/shared';
import { type AtLeast, type IReffable } from '@principle-theorem/shared';
import { LoadSkillActionService } from '../../services/contextual-actions/routing-actions/load-skill-action.service';
import { type ISearchAction } from './search-action';

export class SkillLoader<
  T extends IReffable<ISkill> & AtLeast<Pick<ISkill, 'name' | 'tags'>, 'name'>
> implements ISearchAction
{
  icon = 'task';
  name: string;
  details: string;
  ref: DocumentReference<ISkill>;
  type = MentionResourceType.Skill;
  tags?: DocumentReference<ITag>[];

  constructor(public skill: T, public loader: LoadSkillActionService) {
    this.name = skill.name;
    this.tags = skill.tags;
    this.ref = skill.ref;
    this.details = '';
  }

  async do(): Promise<void> {
    await this.loader.do(this.skill.ref);
  }

  url(parentPathwayRef?: DocumentReference<IPathway>): string {
    return this.loader.url(this.skill.ref, parentPathwayRef);
  }
}
