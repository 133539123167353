import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IUser } from '@principle-theorem/level-up-core';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserProfileComponent {
  user$: Observable<WithRef<IUser>>;

  constructor(public org: OrganisationService) {
    this.user$ = this.org.user$.pipe(filterUndefined());
  }
}
