import { type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { type IVendor } from '../marketplace/vendor';
import {
  type BundleVisibility,
  type IVendorBundle,
} from '../marketplace/vendor-bundle';
import { type ISkill } from '../skill/skill';
import { MarketplaceTypesenseCollection } from './typesense';
import {
  SKILL_COLLECTION_SCHEMA,
  TypesenseSkill,
  type ITypesenseSkill,
} from './typesense-skill';

export const VENDOR_SKILL_COLLECTION_SCHEMA: CollectionCreateSchema = {
  ...SKILL_COLLECTION_SCHEMA,
  name: MarketplaceTypesenseCollection.Skills,
  fields: [
    ...compact(SKILL_COLLECTION_SCHEMA.fields),
    {
      name: 'bundleName',
      type: 'string',
      facet: true,
    },
    {
      name: 'bundleRef',
      type: 'string',
    },
    {
      name: 'bundleImageUrl',
      type: 'string',
      index: false,
      optional: true,
    },
    {
      name: 'marketplaceVendorName',
      type: 'string',
      sort: true,
      facet: true,
    },
    {
      name: 'marketplaceVendorRef',
      type: 'string',
    },
    {
      name: 'visibility',
      type: 'string',
      facet: true,
    },
  ],
};

export interface ITypesenseVendorSkill extends ITypesenseSkill {
  bundleName: string;
  bundleRef: string;
  marketplaceVendorName: string;
  marketplaceVendorRef: string;
  visibility: BundleVisibility;
  bundleImageUrl?: string;
}

export class TypesenseVendorSkill {
  static async fromVendorSkill(
    skill: WithRef<ISkill>,
    vendor: WithRef<IVendor>,
    bundle: WithRef<IVendorBundle>
  ): Promise<ITypesenseVendorSkill> {
    if (!skill.vendorSkillRef) {
      throw new Error(`Release Skill missing vendorSkillRef ${skill.ref.path}`);
    }

    return {
      ...(await TypesenseSkill.fromSkill(skill, vendor)),
      id: skill.vendorSkillRef.id,
      bundleName: bundle.name,
      bundleRef: bundle.ref.path,
      visibility: bundle.visibility,
      bundleImageUrl: bundle.imageUrl,
      marketplaceVendorName: vendor.name,
      marketplaceVendorRef: vendor.ref.path,
    };
  }
}
