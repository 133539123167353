import { Pipe, type PipeTransform } from '@angular/core';
import { combineLatest, isObservable, type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'pairMap$',
    standalone: false
})
export class PairMapAsyncPipe implements PipeTransform {
  transform<T, R, Q, S>(
    valueA: T | Observable<T>,
    valueB: R | Observable<R>,
    mapFn: (valueA: T, valueB: R) => Q,
    thisArg?: S
  ): Observable<Q> {
    if (thisArg) {
      mapFn = mapFn.bind(thisArg);
    }
    if (isObservable<T>(valueA) && isObservable<R>(valueB)) {
      return combineLatest([valueA, valueB]).pipe(
        map(([resolvedA, resolvedB]) => mapFn(resolvedA, resolvedB))
      );
    }
    if (isObservable<T>(valueA) && !isObservable<R>(valueB)) {
      return valueA.pipe(map((resolvedA) => mapFn(resolvedA, valueB)));
    }
    if (!isObservable<T>(valueA) && isObservable<R>(valueB)) {
      return valueB.pipe(map((resolvedB) => mapFn(valueA, resolvedB)));
    }
    return of(mapFn(valueA as T, valueB as R));
  }
}
