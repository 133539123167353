import {
  all$,
  asColRef,
  shareReplayCold,
  subCollection,
  type AtLeast,
  type IProfile,
  type WithRef,
  IReffable,
  undeletedQuery,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootCollection } from '../../root-collection';
import { type IUser } from '../user/user';
import { type IVendorBundle } from './vendor-bundle';

export interface IVendor extends IProfile {
  description: string;
  status: VendorStatus;
}

export enum VendorCollection {
  Bundles = 'bundles',
  Users = 'vendorUsers',
}

export enum VendorStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Suspended = 'suspended',
}

export const LEVEL_UP_VENDOR_KEY = 'level-up';

export class Vendor {
  static init(overrides: AtLeast<IVendor, 'name'>): IVendor {
    return {
      description: '',
      status: VendorStatus.Active,
      ...overrides,
    };
  }

  static col(): CollectionReference<IVendor> {
    return asColRef<IVendor>(RootCollection.MarketplaceVendors);
  }

  static all$(): Observable<WithRef<IVendor>[]> {
    return all$(this.col()).pipe(
      map((vendors) => sortBy(vendors, ['name'])),
      shareReplayCold()
    );
  }

  static storagePath(vendorRef: DocumentReference<IVendor>): string {
    return `${RootCollection.MarketplaceVendors}/${vendorRef.id}`;
  }

  static bundleCol(
    vendor: IReffable<IVendor>
  ): CollectionReference<IVendorBundle> {
    return subCollection<IVendorBundle>(vendor.ref, VendorCollection.Bundles);
  }

  static bundles$(
    vendor: WithRef<IVendor>
  ): Observable<WithRef<IVendorBundle>[]> {
    return all$(undeletedQuery(Vendor.bundleCol(vendor)));
  }

  static userCol(vendor: WithRef<IVendor>): CollectionReference<IUser> {
    return subCollection<IUser>(vendor.ref, VendorCollection.Users);
  }

  static users$(vendor: WithRef<IVendor>): Observable<WithRef<IUser>[]> {
    return all$(Vendor.userCol(vendor));
  }
}
