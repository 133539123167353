import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  ISkill,
  IUser,
  User,
  updateSkillLevelRequirement,
} from '@principle-theorem/level-up-core';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import {
  ISODateType,
  WithRef,
  patchDoc,
  snapshot,
} from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-skill-list-item',
    templateUrl: './skill-list-item.component.html',
    styleUrls: ['./skill-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillListItemComponent {
  profileImage = inject(ProfileImageService);
  canEdit$ = new BehaviorSubject<boolean>(false);
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  progress: UserSkillProgress;
  @Input() withTags = true;
  @Input() showAuthor = true;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  @Input()
  set canEdit(canEdit: BooleanInput) {
    this.canEdit$.next(coerceBooleanProperty(canEdit));
  }

  constructor(private _organisation: OrganisationService) {
    this.progress = new UserSkillProgress(
      this._organisation.userGroups$,
      this.user$,
      this.skill$
    );
  }

  async updateDueDate(dueDate?: ISODateType): Promise<void> {
    const skill = await snapshot(this.skill$);
    const user = await snapshot(this.user$);
    const userGroups = await snapshot(this._organisation.userGroups$);
    const { levelRequirements } = User.getGoals(user, userGroups);
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { dueDate },
      levelRequirements
    );

    this.user$.next({ ...user, levelRequirements: updatedSkillLevels });
    await patchDoc(user.ref, {
      levelRequirements: updatedSkillLevels,
    });
  }
}
