import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  type IMarketplaceSubscription,
  type IVendor,
  type IVendorBundle,
  MarketplaceSubscriptionStatus,
  Organisation,
} from '@principle-theorem/level-up-core';
import {
  addDoc,
  filterUndefined,
  find$,
  patchDoc,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';
import { where } from '@principle-theorem/shared';

@Component({
  selector: 'lu-subscription-manager',
  templateUrl: './subscription-manager.component.html',
  styleUrls: ['./subscription-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionManagerComponent {
  bundle$: ReplaySubject<WithRef<IVendorBundle>> = new ReplaySubject(1);
  vendor$: ReplaySubject<WithRef<IVendor>> = new ReplaySubject(1);
  subscription$: Observable<WithRef<IMarketplaceSubscription> | undefined>;
  isSubscribed$: Observable<boolean | undefined>;
  @Output() subscribed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set bundle(bundle: WithRef<IVendorBundle>) {
    if (bundle) {
      this.bundle$.next(bundle);
    }
  }

  @Input()
  set vendor(vendor: WithRef<IVendor>) {
    if (vendor) {
      this.vendor$.next(vendor);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _org: OrganisationService
  ) {
    this.subscription$ = this._getSubscription$();
    this.isSubscribed$ = this.subscription$.pipe(
      map((sub) =>
        sub && sub.status === MarketplaceSubscriptionStatus.Subscribed
          ? true
          : false
      ),
      startWith(undefined),
      shareReplayCold()
    );
  }

  async subscribe(): Promise<void> {
    const bundle = await snapshot(this.bundle$);
    const vendor = await snapshot(this.vendor$);
    const subscription = await snapshot(this.subscription$);
    const subscriber = await snapshot(
      this._org.organisation$.pipe(filterUndefined())
    );

    if (
      subscription &&
      subscription.status === MarketplaceSubscriptionStatus.Unsubscribed
    ) {
      await patchDoc(subscription.ref, {
        status: MarketplaceSubscriptionStatus.Subscribed,
      });
      this._snackBar.open(
        `${subscriber.name} resubscribed to ${bundle.name} bundle`
      );
      this.subscribed.next();
      return;
    }

    await addDoc(Organisation.subscriptionCol(subscriber), {
      bundleRef: bundle.ref,
      vendorRef: vendor.ref,
      status: MarketplaceSubscriptionStatus.Subscribed,
    });

    this._snackBar.open(
      `${subscriber.name} subscribed to ${bundle.name} bundle`
    );
    this.subscribed.next();
  }

  async unsubscribe(
    subscription: WithRef<IMarketplaceSubscription>
  ): Promise<void> {
    const bundle = await snapshot(this.bundle$);
    const subscriber = await snapshot(
      this._org.organisation$.pipe(filterUndefined())
    );
    await patchDoc<IMarketplaceSubscription>(subscription.ref, {
      status: MarketplaceSubscriptionStatus.Unsubscribed,
    });
    this._snackBar.open(
      `${subscriber.name} unsubscribed from ${bundle.name} bundle`
    );
  }

  private _getSubscription$(): Observable<
    WithRef<IMarketplaceSubscription> | undefined
  > {
    return combineLatest([
      this.bundle$,
      this.vendor$,
      this._org.organisation$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([bundle, vendor, org]) =>
        find$(
          Organisation.subscriptionCol(org),
          where('bundleRef', '==', bundle.ref),
          where('vendorRef', '==', vendor.ref)
        )
      )
    );
  }
}
