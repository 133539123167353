import {
  count,
  getDocs,
  multiFilter,
  multiMap,
  multiSort,
  multiSwitchMap,
  sortTimestamp,
  toQuery,
  type Timestamp,
  type WithRef,
  where,
  multiReduce,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ISkillLevelRequest,
  type ISkill,
  Skill,
  SkillLevelRequestStatus,
} from '../models/skill/skill';
import {
  currentSkillLevelValue,
  getLevelUps,
  getTrainerVerifiedLevelUps,
  type ILevelUp,
  type ISkillProgress,
  type IUserSkillProgressPair,
} from '../models/skill/skill-progress';
import { type IUser } from '../models/user/user';
import { StatView } from './stat-view';
import { SkillLevel } from '../models/skill/skill-level';

export class UserMentorStats {
  lastTrainedAt$: Observable<Timestamp>;
  levelUps: StatView;
  requestedLevelUps: StatView;

  constructor(
    user$: Observable<WithRef<IUser>>,
    allSkills$: Observable<WithRef<ISkill>[]>,
    allUserProgress$: Observable<IUserSkillProgressPair[]>
  ) {
    const myLevelUps$ = this._myLevelUps(user$, allUserProgress$);
    this.levelUps = new StatView(
      myLevelUps$.pipe(count()),
      myLevelUps$.pipe(count())
    );

    const trainingRequests$ = this._trainingRequests(
      allSkills$,
      allUserProgress$.pipe(multiMap((progressPair) => progressPair.progress))
    );
    this.requestedLevelUps = new StatView(of(0), trainingRequests$);

    this.lastTrainedAt$ = myLevelUps$.pipe(
      multiSort((levelUpA, levelUpB) =>
        sortTimestamp(levelUpA.event.achievedAt, levelUpB.event.achievedAt)
      ),
      multiMap((levelUp) => levelUp.event.achievedAt),
      multiFilter((timestamp) => timestamp !== undefined),
      map((levelUps) => levelUps[0])
    );
  }

  private _myLevelUps(
    user$: Observable<WithRef<IUser>>,
    allUserProgress$: Observable<IUserSkillProgressPair[]>
  ): Observable<ILevelUp[]> {
    return combineLatest([
      user$,
      allUserProgress$.pipe(
        multiMap(getLevelUps),
        map((items) =>
          items
            .reduce((acc, item) => [...acc, ...item], [])
            .filter((levelUpPair) => levelUpPair.levelUp.difference > 0)
        )
      ),
    ]).pipe(
      map(([user, levelUpPairs]) =>
        getTrainerVerifiedLevelUps(user, levelUpPairs)
      )
    );
  }

  private _trainingRequests(
    allSkills$: Observable<WithRef<ISkill>[]>,
    allUserProgress$: Observable<WithRef<ISkillProgress>[]>
  ): Observable<number> {
    const userMentorRefs$ = allUserProgress$.pipe(
      multiFilter((userSkill) => {
        return currentSkillLevelValue(userSkill) === SkillLevel.Trainer;
      }),
      multiMap((userSkill) => userSkill.skillRef.ref.path),
      catchError(() => of([] as string[]))
    );
    return combineLatest([allSkills$, userMentorRefs$]).pipe(
      map(([allSkills, mentorRefs]) =>
        allSkills.filter((skill) => mentorRefs.includes(skill.ref.path))
      ),
      multiSwitchMap((skill) =>
        getDocs<ISkillLevelRequest>(
          toQuery(
            Skill.trainingRequestCol(skill),
            where('status', '==', SkillLevelRequestStatus.Pending)
          )
        )
      ),
      multiReduce(
        (total, skillLevelRequests) => total + skillLevelRequests.length,
        0
      )
    );
  }
}
