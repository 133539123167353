import { getSchemaText } from '@principle-theorem/editor';
import {
  TypeGuard,
  asyncForEach,
  getDoc,
  toMoment,
  type IProfile,
  type WithRef,
} from '@principle-theorem/shared';
import { isArray, isBoolean, isNumber, isString, isUndefined } from 'lodash';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { type IPathway } from '../pathway/pathway';
import { LevelUpTypesenseCollection } from './typesense';

export const PATHWAY_COLLECTION_SCHEMA: CollectionCreateSchema = {
  name: LevelUpTypesenseCollection.Pathways,
  fields: [
    {
      name: 'id',
      type: 'string',
    },
    {
      name: 'createdAt',
      type: 'int64',
    },
    {
      name: 'name',
      type: 'string',
      sort: true,
    },
    {
      name: 'description',
      type: 'string',
      sort: true,
    },
    {
      name: 'readOnly',
      type: 'bool',
    },
    {
      name: 'tagNames',
      type: 'string[]',
    },
    {
      name: 'tagRefs',
      type: 'string[]',
    },
    {
      name: 'ref',
      type: 'string',
    },
    {
      name: 'deleted',
      type: 'bool',
      facet: true,
    },
    {
      name: 'restrictAccessTo',
      type: 'string[]',
    },
    {
      name: 'authorName',
      type: 'string',
      facet: true,
      optional: true,
    },
    {
      name: 'authorProfileImageUrl',
      type: 'string',
      index: false,
      optional: true,
    },
    {
      name: 'authorRef',
      type: 'string',
      index: false,
      optional: true,
    },
    {
      name: 'vendorPathwayRef',
      type: 'string',
      index: false,
      optional: true,
    },
  ],
  default_sorting_field: 'createdAt',
};

export interface IPathwayIndexedProperties {
  id: string;
  name: string;
  description: string;
  createdAt: number;
  readOnly: boolean;
  tagNames: string[];
  tagRefs: string[];
  restrictAccessTo: string[];
  ref: string;
  deleted: boolean;
}

export interface ITypesensePathway extends IPathwayIndexedProperties {
  imageUrl: string | undefined;
  vendorPathwayRef: string | undefined;
  authorName: string | undefined;
  authorProfileImageUrl: string | undefined;
  authorRef: string | undefined;
}

export class TypesensePathway {
  static async fromPathway(
    pathway: WithRef<IPathway>,
    author?: WithRef<IProfile>
  ): Promise<ITypesensePathway> {
    const tagNames = await asyncForEach(pathway.tags, async (tagRef) => {
      const tag = await getDoc(tagRef);
      return tag.name;
    });

    return {
      id: pathway.ref.id,
      name: pathway.name,
      description: isString(pathway.description)
        ? pathway.description
        : getSchemaText(pathway.description),
      createdAt: toMoment(pathway.createdAt).unix(),
      readOnly: !!pathway.readOnly,
      deleted: pathway.deleted,
      imageUrl: pathway.imageUrl,
      tagNames,
      tagRefs: pathway.tags.map((tag) => tag.path),
      restrictAccessTo: pathway.restrictAccessTo.map((doc) => doc.path),
      ref: pathway.ref.path,
      authorName: author?.name,
      authorProfileImageUrl: author?.profileImageURL,
      authorRef: author?.ref ? author.ref.path : undefined,
      vendorPathwayRef: pathway.vendorPathwayRef
        ? pathway.vendorPathwayRef.path
        : undefined,
    };
  }
}

export function isTypesensePathway(item: unknown): item is ITypesensePathway {
  return TypeGuard.interface<ITypesensePathway>({
    id: isString,
    name: isString,
    description: isString,
    ref: isString,
    createdAt: isNumber,
    readOnly: isBoolean,
    deleted: isBoolean,
    tagNames: TypeGuard.arrayOf(isString),
    tagRefs: TypeGuard.arrayOf(isString),
    restrictAccessTo: isArray,
    imageUrl: [isString, isUndefined],
    authorName: [isString, isUndefined],
    authorProfileImageUrl: [isString, isUndefined],
    authorRef: [isString, isUndefined],
    vendorPathwayRef: [isString, isUndefined],
  })(item);
}
