import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  type IUserGroup,
  type IWithPermissions,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { isSameRef, patchDoc, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';

@Component({
    selector: 'lu-permission-selector',
    templateUrl: './permission-selector.component.html',
    styleUrls: ['./permission-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PermissionSelectorComponent {
  trackByGroup = TrackByFunctions.ref<WithRef<IUserGroup>>();
  item$ = new ReplaySubject<WithRef<IWithPermissions>>(1);
  groups$: Observable<WithRef<IUserGroup>[]>;

  @Input()
  set item(item: WithRef<IWithPermissions>) {
    if (item) {
      this.item$.next(item);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this.groups$ = combineLatest([
      this.item$.pipe(
        map(
          (item) => item.restrictAccessTo.map((groupRef) => groupRef.path) ?? []
        )
      ),
      this._organisation.userGroups$,
    ]).pipe(
      map(([existingGroupPaths, groups]) => {
        if (existingGroupPaths.length === 0) {
          return [];
        }
        return groups.filter((group: WithRef<IUserGroup>) =>
          existingGroupPaths.includes(group.ref.path)
        );
      })
    );
  }

  async addGroup(
    group: WithRef<IUserGroup>,
    item: WithRef<IWithPermissions>
  ): Promise<void> {
    await patchDoc(item.ref, {
      restrictAccessTo: [...item.restrictAccessTo, group.ref],
    });
    this._snackBar.open(`Group ${group.name} added`);
  }

  async removeGroup(
    group: WithRef<IUserGroup>,
    item: WithRef<IWithPermissions>
  ): Promise<void> {
    await patchDoc(item.ref, {
      restrictAccessTo: item.restrictAccessTo.filter(
        (ref) => !isSameRef(ref, group.ref)
      ),
    });
    this._snackBar.open(`Group ${group.name} removed`);
  }
}
