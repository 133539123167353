import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFolder, Organisation } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  WithRef,
  deleteDoc,
  filterUndefined,
  getCount,
  toQuery,
  undeletedQuery,
  where,
  type DocumentReference,
} from '@principle-theorem/shared';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';

export type FolderFormData = Pick<IFolder, 'name' | 'parentFolderRef'>;

export interface IFolderEditDialogData {
  folder: WithRef<IFolder>;
}

@Component({
  selector: 'lu-folder-edit-dialog',
  templateUrl: './folder-edit-dialog.component.html',
  styleUrls: ['./folder-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderEditDialogComponent {
  form: TypedFormGroup<FolderFormData> = new TypedFormGroup<FolderFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    parentFolderRef: new TypedFormControl<DocumentReference<IFolder>>(),
  });
  canDelete$: Observable<boolean>;

  constructor(
    private _dialogRef: MatDialogRef<
      FolderEditDialogComponent,
      FolderFormData | undefined
    >,
    @Inject(MAT_DIALOG_DATA) public data: IFolderEditDialogData,
    organisation: OrganisationService
  ) {
    this.form.patchValue(this.data.folder);

    this.canDelete$ = organisation.organisation$.pipe(
      filterUndefined(),
      switchMap((org) =>
        combineLatest([
          getCount(
            toQuery(
              undeletedQuery(Organisation.pathwayCol(org)),
              where('folderRef', '==', data.folder.ref)
            )
          ),
          getCount(
            toQuery(
              undeletedQuery(Organisation.skillCol(org)),
              where('folderRef', '==', data.folder.ref)
            )
          ),
          getCount(
            toQuery(
              undeletedQuery(Organisation.folderCol(org)),
              where('parentFolderRef', '==', data.folder.ref)
            )
          ),
        ])
      ),
      map((countResults) => countResults.every((result) => result === 0)),
      startWith(false)
    );
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }

  async delete(): Promise<void> {
    await deleteDoc(this.data.folder.ref);
  }
}
