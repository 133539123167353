<ng-container *ngIf="pageTemplates$ | async as pageTemplates">
  <div fxLayout="column">
    <label>Select a Start Template:</label>
    <mat-radio-group
      (change)="templateSelected.emit($event.value)"
      fxLayout="column"
    >
      <mat-radio-button>Empty</mat-radio-button>
      <mat-radio-button
        *ngFor="let pageTemplate of pageTemplates; trackBy: trackByTemplate"
        [value]="pageTemplate"
      >
        {{ pageTemplate.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-container>
