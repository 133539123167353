import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { type IUser } from '@principle-theorem/level-up-core';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IRoutingAction } from '@principle-theorem/shared';

@Injectable()
export class LoadUserActionService implements IRoutingAction<IUser> {
  icon = 'open_in_new';
  name = 'View User';

  constructor(private _router: Router) {}

  async do(skillRef: DocumentReference<IUser>): Promise<void> {
    await this._router.navigate(['/', 'admin', 'users', skillRef.id]);
  }
}
