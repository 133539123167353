import { type IUserWorkspace } from '@principle-theorem/shared';

export type IWorkspace = IUserWorkspace<WorkspaceType>;

export enum WorkspaceType {
  Organisation = 'organisation',
  Vendor = 'vendor',
}

export function isOrganisationWorkspace(
  workspace: IUserWorkspace
): workspace is IUserWorkspace<WorkspaceType.Organisation> {
  return workspace.type === String(WorkspaceType.Organisation);
}

export function isVendorWorkspace(
  workspace: IUserWorkspace
): workspace is IUserWorkspace<WorkspaceType.Vendor> {
  return workspace.type === String(WorkspaceType.Vendor);
}
