import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'lu-card-placeholder',
    templateUrl: './card-placeholder.component.html',
    styleUrls: ['./card-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardPlaceholderComponent {}
