import { Component, Input } from '@angular/core';

@Component({
    selector: 'lu-header-button',
    templateUrl: 'header-button.component.html',
    styleUrls: ['header-button.component.scss'],
    standalone: false
})
export class HeaderButtonComponent {
  @Input() buttonText: string;
  @Input() buttonIcon: string;
}
