import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  BlockNodes,
  MenuButtonType,
  findNodeRange,
  fromEditorEvents,
  getActiveNode,
  type CommandReturn,
  type IMenuButton,
} from '@principle-theorem/editor';
import {
  EditorMenuItemComponent,
  MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { snapshot } from '@principle-theorem/shared';
import { Editor } from '@tiptap/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pt-basic-menu-button',
    templateUrl: './basic-menu-button.component.html',
    styleUrls: ['./basic-menu-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BasicMenuButtonComponent
  extends EditorMenuItemComponent
  implements IMenuButton, OnDestroy
{
  protected _onDestroy$ = new Subject<void>();
  buttonType$ = new BehaviorSubject<MenuButtonType>('basic');
  @Input() compact = true;
  @Input() textOnly = false;
  @Input() override buttonText = '';
  @Input() override tooltip = '';
  @Input() shortcut = '';
  @Input() icon = '';
  @Input() command: CommandReturn;
  @Input() isActiveFn?: (editor: Editor) => boolean;
  @Input() isDisabledFn?: (editor: Editor) => boolean;
  isActiveOverride$ = new BehaviorSubject<boolean>(false);
  isDisabledOverride$ = new BehaviorSubject<boolean>(false);
  isActive$ = new BehaviorSubject<boolean>(false);
  isDisabled$ = new BehaviorSubject<boolean>(false);
  submenuItems$ = new ReplaySubject<MenuButtonLoaderFn[]>(1);

  set submenuItems(submenuItems: MenuButtonLoaderFn[]) {
    if (submenuItems) {
      this.submenuItems$.next(submenuItems);
    }
  }

  @Input()
  set buttonType(buttonType: MenuButtonType) {
    if (buttonType) {
      this.buttonType$.next(buttonType);
    }
  }

  set selected(selected: boolean) {
    this.isActiveOverride$.next(selected);
  }

  get selected(): boolean {
    return this.isActive$.value;
  }

  set disabled(selected: boolean) {
    this.isDisabledOverride$.next(selected);
  }

  get disabled(): boolean {
    return this.isDisabled$.value;
  }

  constructor() {
    super();
    this.buttonType$
      .pipe(
        switchMap((buttonType) =>
          buttonType === 'block'
            ? this.isActiveOverride$
            : this.editor$.pipe(
                switchMap((editor) =>
                  fromEditorEvents(editor).pipe(
                    startWith(undefined),
                    map(() =>
                      this.isActiveFn ? this.isActiveFn(editor) : false
                    )
                  )
                )
              )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isActive) => this.isActive$.next(isActive));

    this.buttonType$
      .pipe(
        switchMap((buttonType) =>
          buttonType === 'block'
            ? this.isDisabledOverride$
            : this.editor$.pipe(
                switchMap((editor) =>
                  fromEditorEvents(editor).pipe(
                    startWith(undefined),
                    map(() =>
                      this.isDisabledFn ? this.isDisabledFn(editor) : false
                    )
                  )
                )
              )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isDisabled) => this.isDisabled$.next(isDisabled));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async runCommand(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    this.command(editor);
    editor.view.focus();
  }
}

export function cleanUpBlockCommand(editor: Editor): void {
  editor.commands.command((props) => {
    const currentParagraph = getActiveNode(props.state, BlockNodes.Paragraph);
    if (!currentParagraph) {
      return false;
    }
    const nodeRange = findNodeRange(props.state.doc, currentParagraph);
    if (!nodeRange) {
      return false;
    }

    props.tr.deleteRange(nodeRange.$from.pos, nodeRange.$to.pos);

    props.dispatch?.(props.tr);
    return true;
  });
}
