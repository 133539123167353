import { type ComponentType } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type MatTabChangeEvent } from '@angular/material/tabs';
import { filterUndefined } from '@principle-theorem/shared';
import { AuthProvider, Layout, Theme } from 'ngx-auth-firebaseui';
import { type Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { type IWorkspaceSelector } from '../workspace-selector/workspace-selector.component';

export enum AuthTabIndex {
  SignIn = 0,
  Register = 1,
  PasswordReset = 2,
}

@Component({
    selector: 'pt-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  providers: AuthProvider[] = [AuthProvider.Google];
  providersTheme: Theme = Theme.DEFAULT;
  providersLayout: Layout = Layout.ROW;
  tabIndex: AuthTabIndex = AuthTabIndex.SignIn;
  userIsVerified$: Observable<boolean>;
  @Input() workspaceSelectors: ComponentType<IWorkspaceSelector>[];
  @Output() afterSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() afterError: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _snackbar: MatSnackBar, public auth: AuthService) {
    this.userIsVerified$ = this.auth.authUser$.pipe(
      filterUndefined(),
      map((user) => user.emailVerified),
      startWith(false)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  async onSuccess(): Promise<void> {
    await this.auth.updateClaims();
    this.afterSuccess.emit();
  }

  onError(event: Error): void {
    this._snackbar.open(event.message);
    this.afterError.emit();
  }

  updateSelectedTab($event: MatTabChangeEvent): void {
    this.tabIndex = $event.index;
  }

  trackByProvider(_index: number, item: AuthProvider): string {
    return item;
  }
}
