import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { type IPathway } from '@principle-theorem/level-up-core';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IRoutingAction } from '@principle-theorem/shared';

@Injectable()
export class LoadPathwayActionService implements IRoutingAction<IPathway> {
  icon = 'open_in_new';
  name = 'View Pathway';

  constructor(private _router: Router) {}

  async do(pathwayRef: DocumentReference<IPathway>): Promise<void> {
    await this._router.navigate(['/', 'explore', 'pathway', pathwayRef.id]);
  }

  url(pathwayRef: DocumentReference<IPathway>): string {
    return this._router
      .createUrlTree(['/', 'explore', 'pathway', pathwayRef.id])
      .toString();
  }
}
