import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import {
  Achievement,
  type IAchievement,
  type IAchievementUnlock,
  type IUserStatistics,
} from '@principle-theorem/level-up-core';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lu-achievement-progress',
    templateUrl: './achievement-progress.component.html',
    styleUrls: ['./achievement-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AchievementProgressComponent {
  userStats$: ReplaySubject<Partial<IUserStatistics>> = new ReplaySubject(1);
  achievement$: ReplaySubject<WithRef<IAchievement>> = new ReplaySubject(1);
  unlocked$: ReplaySubject<IAchievementUnlock | undefined> = new ReplaySubject(
    1
  );
  total$: Observable<number>;
  progress$: Observable<number>;
  isLocked$: Observable<boolean>;

  @Input()
  set achievement(achievement: WithRef<IAchievement>) {
    if (achievement) {
      this.achievement$.next(achievement);
    }
  }

  @Input()
  set unlock(unlock: IAchievementUnlock) {
    this.unlocked$.next(unlock);
  }

  @Input()
  set userStats(userStats: Partial<IUserStatistics>) {
    if (userStats) {
      this.userStats$.next(userStats);
    }
  }

  constructor() {
    this.progress$ = combineLatest([this.achievement$, this.userStats$]).pipe(
      map(([achievement, stats]) =>
        Achievement.getAchievementProgress(achievement, stats)
      )
    );

    this.total$ = this.achievement$.pipe(
      map((achievement) => Achievement.getAchievementTotal(achievement))
    );

    this.isLocked$ = this.unlocked$.pipe(map((unlock) => !unlock));
  }
}
