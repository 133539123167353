import {
  ISODateType,
  getEnumValues,
  isSameRef,
  toISODate,
  type DocumentReference,
  type IReffable,
  type Reffable,
  type WithRef,
  ISoftDelete,
} from '@principle-theorem/shared';
import { differenceBy, intersectionBy, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pathway, type IPathway } from '../pathway/pathway';
import { ISkill } from '../skill/skill';
import { SkillLevel } from '../skill/skill-level';
import { SkillRefType, type ISkillRef } from '../skill/skill-type';
import { type IUser } from '../user/user';

export const DEFAULT_USER_GROUP_UID = 'default';

export interface IPathwayAssociation {
  ref: DocumentReference<IPathway>;
  stepDueDates?: { uid: string; dueDate?: ISODateType }[];
  dueDate?: ISODateType;
  level?: SkillLevel;
  relativeDueDate?: number;
}

export interface ISkillAssociation {
  skill: ISkillRef<ISkill>;
}

export interface ISkillLevelRequirement extends ISkillAssociation {
  level: SkillLevel;
  dueDate?: ISODateType;
  relativeDueDate?: number;
  addedBy?: DocumentReference<IUser>;
}

export interface IGoals {
  skillAssociations: ISkillAssociation[];
  pathwayAssociations: IPathwayAssociation[];
  levelRequirements: ISkillLevelRequirement[];
}

export const WARNING_DAYS_UNTIL_DUE = 7;

export interface IUserGroup extends IGoals, ISoftDelete {
  name: string;
  description: string;
  users: DocumentReference<IUser>[];
  readOnly: boolean;
}

export class UserGroup {
  static init(overrides?: Partial<IUserGroup>): IUserGroup {
    return {
      name: '',
      description: '',
      users: [],
      readOnly: false,
      skillAssociations: [],
      pathwayAssociations: [],
      levelRequirements: [],
      deleted: false,
      ...overrides,
    };
  }

  static hasUser(group: IUserGroup, user: IReffable<IUser>): boolean {
    return group.users
      .map((userRef: DocumentReference<IUser>) => userRef.path)
      .includes(user.ref.path);
  }

  static getLevelForSkill(
    goals: IGoals,
    skill: WithRef<ISkill>
  ): ISkillLevelRequirement | undefined {
    return goals.levelRequirements.find((level) =>
      isSameRef(level.skill, skill)
    );
  }

  static mergeGoals<T extends IGoals>(goals: T, newGoals: Partial<T>): T {
    return {
      ...goals,
      skillAssociations: uniqBy(
        [...(newGoals.skillAssociations ?? []), ...goals.skillAssociations],
        (association) => association.skill.ref.path
      ),
      pathwayAssociations: uniqBy(
        [...(newGoals.pathwayAssociations ?? []), ...goals.pathwayAssociations],
        (association) => association.ref.path
      ),
      levelRequirements: uniqBy(
        [...(newGoals.levelRequirements ?? []), ...goals.levelRequirements],
        (goal) => goal.skill.ref.path
      ),
    };
  }

  static updateNewGoals<T extends IGoals>(goals: T, newGoals: Partial<T>): T {
    const commonSkillAssociations = intersectionBy(
      newGoals.skillAssociations ?? [],
      goals.skillAssociations,
      (association) => association.skill.ref.path
    );
    const newSkillAssociations = differenceBy(
      newGoals.skillAssociations ?? [],
      goals.skillAssociations,
      (association) => association.skill.ref.path
    );

    const commonPathwayAssociations = intersectionBy(
      newGoals.pathwayAssociations ?? [],
      goals.pathwayAssociations,
      (association) => association.ref.path
    );
    const newPathwayAssociations = differenceBy(
      newGoals.pathwayAssociations ?? [],
      goals.pathwayAssociations,
      (association) => association.ref.path
    );

    const commonLevelRequirements = intersectionBy(
      newGoals.levelRequirements ?? [],
      goals.levelRequirements,
      (goal) => goal.skill.ref.path
    );
    const newLevelRequirements = differenceBy(
      newGoals.levelRequirements ?? [],
      goals.levelRequirements,
      (goal) => goal.skill.ref.path
    );

    return {
      ...goals,
      skillAssociations: uniqBy(
        [...commonSkillAssociations, ...newSkillAssociations],
        (association) => association.skill.ref.path
      ),
      pathwayAssociations: uniqBy(
        [...commonPathwayAssociations, ...newPathwayAssociations],
        (association) => association.ref.path
      ),
      levelRequirements: uniqBy(
        [...commonLevelRequirements, ...newLevelRequirements],
        (goal) => goal.skill.ref.path
      ),
    };
  }

  static convertRelativeDueDatesToAbsolute<T extends IGoals>(goals: T): T {
    const levelRequirements = goals.levelRequirements.map(
      (levelRequirement) => ({
        ...levelRequirement,
        dueDate: levelRequirement.relativeDueDate
          ? toISODate(moment().add(levelRequirement.relativeDueDate, 'days'))
          : undefined,
      })
    );
    const pathwayAssociations = goals.pathwayAssociations.map(
      (pathwayAssociation) => ({
        ...pathwayAssociation,
        dueDate: pathwayAssociation.relativeDueDate
          ? toISODate(moment().add(pathwayAssociation.relativeDueDate, 'days'))
          : undefined,
      })
    );

    return {
      ...goals,
      levelRequirements,
      pathwayAssociations,
    };
  }

  static addPathwayAssociation<T extends IGoals>(
    goals: T,
    pathway: Reffable<IPathway>,
    level: SkillLevel = SkillLevel.None,
    addedBy?: DocumentReference<IUser>
  ): T {
    return {
      ...goals,
      pathwayAssociations: [
        ...goals.pathwayAssociations,
        { ref: pathway.ref, level },
      ],
      levelRequirements: [
        ...goals.levelRequirements,
        ...Pathway.skillRefs(pathway)
          .filter((ref) => {
            return !goals.levelRequirements
              .map((skillRequirement) => skillRequirement.skill.ref.path)
              .includes(ref.path);
          })
          .map((ref) => {
            return {
              skill: {
                ref,
                type: SkillRefType.Skill,
              },
              level,
              addedBy,
            };
          }),
      ],
    };
  }

  static addSkillAssociation<T extends IGoals>(
    goals: T,
    skill: WithRef<ISkill>,
    level: SkillLevel = SkillLevel.None
  ): T {
    const skillAssociation = {
      skill: {
        ref: skill.ref,
        type: SkillRefType.Skill,
      },
    };

    return {
      ...goals,
      skillAssociations: [...goals.skillAssociations, skillAssociation],
      levelRequirements: [
        ...goals.levelRequirements,
        { ...skillAssociation, level },
      ],
    };
  }

  static updateSkillAssociation<T extends IGoals>(
    goals: T,
    skill: WithRef<ISkill>,
    level: SkillLevel
  ): T {
    const currentRequirement = UserGroup.getLevelForSkill(goals, skill);

    if (!currentRequirement) {
      return goals;
    }

    currentRequirement.level = level;
    return goals;
  }

  static hasSkillLevelRequirement(
    goals: IGoals,
    skill: WithRef<ISkill>
  ): boolean {
    return goals.levelRequirements.some((levelRequirement) =>
      isSameRef(levelRequirement.skill, skill)
    );
  }

  static hasPathwayAssociation(
    goals: IGoals,
    pathway: WithRef<IPathway>
  ): boolean {
    return goals.pathwayAssociations.some((pathwayAssociation) =>
      isSameRef(pathwayAssociation, pathway)
    );
  }

  static hasSkillAssociation(goals: IGoals, skill: IReffable<ISkill>): boolean {
    return goals.skillAssociations.some((skillAssociation) =>
      isSameRef(skillAssociation.skill, skill)
    );
  }

  static removeSkillAssociation(goals: IGoals, skill: WithRef<ISkill>): void {
    goals.skillAssociations = goals.skillAssociations.filter(
      (association) => !isSameRef(association.skill, skill)
    );
    goals.levelRequirements = goals.levelRequirements.filter(
      (association) => !isSameRef(association.skill, skill)
    );
  }

  static removeSkill(
    goals: IGoals,
    skillRef: DocumentReference<ISkill>
  ): ISkillAssociation[] {
    return goals.skillAssociations.filter((currentSkillRequirement) => {
      return !isSameRef(currentSkillRequirement.skill, skillRef);
    });
  }

  static removePathway(
    goals: IGoals,
    pathwayRef: DocumentReference<IPathway>
  ): IPathwayAssociation[] {
    return goals.pathwayAssociations.filter((pathwayAssociation) => {
      return !isSameRef(pathwayAssociation, pathwayRef);
    });
  }
}

export function filterGroupsByUser(
  user: WithRef<IUser>
): OperatorFunction<WithRef<IUserGroup>[], WithRef<IUserGroup>[]> {
  return map((groups: WithRef<IUserGroup>[]) =>
    groups.filter((group: WithRef<IUserGroup>) =>
      UserGroup.hasUser(group, user)
    )
  );
}

export function sortRequirementsByLevel(
  aReq: ISkillLevelRequirement,
  bReq: ISkillLevelRequirement
): number {
  const aRank: number = getEnumValues(SkillLevel).indexOf(aReq.level);
  const bRank: number = getEnumValues(SkillLevel).indexOf(bReq.level);
  return aRank < bRank ? -1 : 1;
}

export function getDefaultGroup(
  users: DocumentReference<IUser>[] = []
): IUserGroup {
  return UserGroup.init({
    name: 'Everyone',
    description: 'A team containing all of your users',
    users,
    readOnly: true,
  });
}
