<ng-container *ngIf="pathway$ | async as pathway">
  <ng-container *ngIf="section$ | async as section">
    <div class="font-bold">{{ section.name }}</div>

    <mat-nav-list class="!-mx-4" ptIntercomTarget="section.steps">
      <ng-container *ngFor="let step of steps$ | async; trackBy: trackByStep">
        <a
          mat-list-item
          [routerLink]="[skillPrefix, pathway.ref.id, 'steps', step.ref.id]"
          routerLinkActive="active"
        >
          <div
            class="flex items-center gap-2"
            *ngIf="step | map : getSkillProgress : this as skillProgress"
          >
            <lu-level-indicator
              [diameter]="30"
              [level]="skillProgress.current$ | async"
              [goal]="skillProgress.goalLevel$ | async"
              [isRequest]="skillProgress.hasRequest$ | async"
            />
            <span class="min-w-0 grow truncate">
              {{ step.name }}
            </span>

            <lu-user-goal-due-date-indicator
              *ngIf="skillProgress.dueDateBloc.shouldShowDueDate$ | async"
              [dueDate]="skillProgress.dueDateBloc.dueDate$ | async"
            />
          </div>
        </a>
      </ng-container>
    </mat-nav-list>
  </ng-container>
</ng-container>
