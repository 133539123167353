import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SkillLevel } from '@principle-theorem/level-up-core';
import { isBoolean, isUndefined } from 'lodash';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lu-level-selector',
    templateUrl: './level-selector.component.html',
    styleUrls: ['./level-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LevelSelectorComponent {
  goal$ = new ReplaySubject<SkillLevel>(1);
  requiresTrainerVerification$ = new BehaviorSubject<boolean | undefined>(
    undefined
  );
  isReadEnabled$: Observable<boolean>;
  isTrainEnabled$: Observable<boolean>;
  @Output() levelChange = new EventEmitter<SkillLevel>();
  readonly type = SkillLevel;

  @Input()
  set goal(goal: SkillLevel | undefined) {
    this.goal$.next(goal ?? SkillLevel.None);
  }

  @Input()
  set requiresTrainerVerification(
    requiresTrainerVerification: boolean | undefined
  ) {
    if (isBoolean(requiresTrainerVerification)) {
      this.requiresTrainerVerification$.next(requiresTrainerVerification);
    }
  }

  constructor() {
    this.isReadEnabled$ = this.requiresTrainerVerification$.pipe(
      map((requiresTrainerVerification) => !requiresTrainerVerification)
    );
    this.isTrainEnabled$ = this.requiresTrainerVerification$.pipe(
      map(
        (requiresTrainerVerification) =>
          requiresTrainerVerification ||
          isUndefined(requiresTrainerVerification)
      )
    );
  }
}
