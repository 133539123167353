import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BundleReleaseStatus } from '@principle-theorem/level-up-core';

@Component({
  selector: 'lu-release-status',
  templateUrl: './release-status.component.html',
  styleUrls: ['./release-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseStatusComponent {
  @Input() status: BundleReleaseStatus = BundleReleaseStatus.Draft;
  @Input() dense = false;
  statusColourMap: { [key in BundleReleaseStatus]: string } = {
    [BundleReleaseStatus.Draft]: 'default',
    [BundleReleaseStatus.QueueRelease]: 'accent',
    [BundleReleaseStatus.Released]: 'primary',
    [BundleReleaseStatus.Failed]: 'warn',
  };
}
