import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgCoreModule } from '@principle-theorem/ng-core';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AuthModule } from '../../auth/auth.module';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { GroupAddDialogComponent } from './groups/group-add-dialog/group-add-dialog.component';
import { GroupAddPathwayDialogComponent } from './groups/group-add-pathway-dialog/group-add-pathway-dialog.component';
import { GroupEditComponent } from './groups/group-edit/group-edit.component';
import { GroupsComponent } from './groups/groups.component';
import { MemberAddDialogComponent } from './groups/member-add-dialog/member-add-dialog.component';
import { PathwayAndSkillsAssociateComponent } from './groups/pathway-and-skills-associate/pathway-and-skills-associate.component';
import { SkillAssociateComponent } from './groups/skill-associate/skill-associate.component';
import { PageTemplateEditComponent } from './page-templates/page-template-edit/page-template-edit.component';
import { PageTemplatesComponent } from './page-templates/page-templates/page-templates.component';
import { PathwayEditComponent } from './pathways/pathway-edit/pathway-edit.component';
import { PathwayListItemComponent } from './pathways/pathway-list-item/pathway-list-item.component';
import { PathwaysComponent } from './pathways/pathways.component';
import { SkillAssociateDialogComponent } from './pathways/skill-associate-dialog/skill-associate-dialog.component';
import { TagsComponent } from './tags/tags.component';
import { InviteUserDialogComponent } from './users/invite-user-dialog/invite-user-dialog.component';
import { UserEditDialogComponent } from './users/user-edit-dialog/user-edit-dialog.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserGoalProgressComponent } from './users/user-goal-progress/user-goal-progress.component';
import { UserGroupAssignmentComponent } from './users/user-group-assignment/user-group-assignment.component';
import { UserPathwayAndSkillsAssociateComponent } from './users/user-pathway-and-skills-associate/user-pathway-and-skills-associate.component';
import { UserSkillAssociateComponent } from './users/user-skill-associate/user-skill-associate.component';
import { UsersComponent } from './users/users.component';

@NgModule({
  declarations: [
    UsersComponent,
    GroupsComponent,
    PathwaysComponent,
    UserEditComponent,
    GroupEditComponent,
    PathwayEditComponent,
    UserGroupAssignmentComponent,
    GroupAddDialogComponent,
    SkillAssociateDialogComponent,
    PathwayListItemComponent,
    MemberAddDialogComponent,
    GroupAddPathwayDialogComponent,
    PathwayAndSkillsAssociateComponent,
    PageTemplatesComponent,
    PageTemplateEditComponent,
    InviteUserDialogComponent,
    UserEditDialogComponent,
    TagsComponent,
    SkillAssociateComponent,
    UserGoalProgressComponent,
    UserPathwayAndSkillsAssociateComponent,
    UserSkillAssociateComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ComponentsModule,
    NgSharedModule,
    NgCoreModule,
    NgIntercomComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgFirebaseModule,
    NgEditorModule,
    PipesModule,
    AuthModule,
  ],
})
export class AdminModule {}
