import { Component, Input } from '@angular/core';
import { type UserStatistics } from '../../../models/user-statistics';
import { type ThemePalette } from '@angular/material/core';

@Component({
    selector: 'lu-author-stat',
    templateUrl: './author-stat.component.html',
    styleUrls: ['./author-stat.component.scss'],
    standalone: false
})
export class AuthorStatComponent {
  @Input() stats: UserStatistics;
  @Input() colour: ThemePalette = 'accent';
}
