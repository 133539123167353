<lu-page-simple marginBottom="32">
  <lu-page-header label="Organisations">
    <lu-page-toolbar>
      <lu-header-button
        buttonIcon="add"
        buttonText="Create"
        luPageToolbarAction
        (click)="addOrganisation()"
       />
      <pt-search-field fxFlex>
        <input matInput placeholder="Search" [formControl]="search" />
      </pt-search-field>
    </lu-page-toolbar>
  </lu-page-header>

  <mat-list class="with-dividers">
    <ng-container *ngIf="searchFilter.results$ | async as organisations">
      <mat-list-item
        *ngFor="let organisation of organisations; trackBy: trackByOrganisation"
      >
        <span matListItemTitle>
          <a [routerLink]="['./', organisation.ref.id]">{{
            organisation.name
          }}</a>
        </span>
        <div matListItemMeta>
          <lu-organisation-status
            [status]="organisation.status"
           />
        </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
</lu-page-simple>
