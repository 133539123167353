<lu-page-simple marginBottom="32">
  <lu-page-header label="Tools" />
  <lu-tool-card [loading]="revokeAuthSessionsLoading$ | async" colour="warn">
    <h3 class="mat-headline-6">Log All Users Out</h3>
    <p class="mat-body-2">
      Force all users to re-authenticate by logging in again. Useful for
      resetting user sessions after an update which affects auth code.
    </p>
    <button
      mat-flat-button
      color="warn"
      [disabled]="revokeAuthSessionsLoading$ | async"
      (click)="revokeAuthSessions()"
    >
      Revoke Auth Sessions
    </button>
  </lu-tool-card>

  <lu-tool-card [loading]="seedingKeys$ | async">
    <h3 class="mat-headline-6">Typesense</h3>
    <p class="mat-body-2">Seed API Typesense Resources</p>
    <div fxLayout="row" fxLayoutGap="8px">
      <button
        mat-flat-button
        color="primary"
        [disabled]="seedingKeys$ | async"
        (click)="seedTypesenseKeys()"
      >
        Generate Keys
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="seedingSkills$ | async"
        (click)="seedTypesenseSkills()"
      >
        Sync Skills
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="seedingPathways$ | async"
        (click)="seedTypesensePathways()"
      >
        Sync Pathways
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="seedingMarketplaceSkills$ | async"
        (click)="seedMarketplaceSkills()"
      >
        Sync Marketplace Skills
      </button>
    </div>
  </lu-tool-card>
</lu-page-simple>
