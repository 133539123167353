import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
} from '@angular/forms';
import { isNil } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';
import { SubscriptSizing } from '@angular/material/form-field';

@Component({
  selector: 'pt-settings-menu-text-field',
  templateUrl: './settings-menu-text-field.component.html',
  styleUrl: './settings-menu-text-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsMenuTextFieldComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class SettingsMenuTextFieldComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  private _onDestroy$: Subject<void> = new Subject();
  private _onChange: (value?: string) => void;
  disabled$ = new BehaviorSubject<boolean>(false);
  emitEvent$ = new BehaviorSubject<boolean>(true);
  @Input() label: string;
  @Input() placeholder?: string;
  @Input() tooltip?: string;
  @Input() validatorFn?: ValidatorFn | ValidatorFn[];
  @Input() errorMessage?: string;
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  formCtrl = new TypedFormControl<string>();

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  @Input()
  set emitEvent(emitEvent: boolean) {
    if (!isNil(emitEvent)) {
      this.emitEvent$.next(emitEvent);
    }
  }

  constructor() {
    this.formCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((newValue) => {
        if (this._onChange) {
          this._onChange(newValue);
        }
      });
  }

  ngOnInit(): void {
    if (this.validatorFn) {
      this.formCtrl.setValidators(this.validatorFn);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: string): void {
    this.formCtrl.setValue(value ?? '', { emitEvent: this.emitEvent$.value });
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formCtrl.disable();
      return;
    }
    this.formCtrl.enable();
  }
}
