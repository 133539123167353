import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IPublicSkill,
  type ISkill,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'lu-pathway-skill-list',
    templateUrl: './pathway-skill-list.component.html',
    styleUrls: ['./pathway-skill-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PathwaySkillListComponent {
  trackBySkill = TrackByFunctions.ref<WithRef<IPublicSkill | ISkill>>();
  skills$: ReplaySubject<WithRef<IPublicSkill | ISkill>[]> = new ReplaySubject(
    1
  );

  @Input()
  set skills(skills: WithRef<IPublicSkill | ISkill>[]) {
    if (skills) {
      this.skills$.next(skills);
    }
  }
}
