import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { UserSkillProgress } from '../../../../models/user-skill-progress';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
    selector: 'lu-my-progress-summary',
    templateUrl: './my-progress-summary.component.html',
    styleUrls: ['./my-progress-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MyProgressSummaryComponent {
  private _user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject<
    WithRef<IUser>
  >(1);
  private _skill$: ReplaySubject<WithRef<ISkill>> = new ReplaySubject<
    WithRef<ISkill>
  >(1);
  progress: UserSkillProgress;

  constructor(organisation: OrganisationService) {
    this.progress = new UserSkillProgress(
      organisation.userGroups$,
      this._user$,
      this._skill$
    );
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this._skill$.next(skill);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }
}
