<mat-list-item *ngIf="user$ | async as user">
  <pt-user-icon
    matListItemIcon
    [diameter]="40"
    [src]="user | profileImage$ | async"
    [name]="user.name"
  />

  <span matListItemTitle>
    <a
      [routerLink]="['./' + routePrefix, user.ref.id]"
      ptIntercomTarget="users.user.name"
      >{{ user.name }}</a
    >
    <mat-icon class="admin-label" *ngIf="user.isOwner" matTooltip="Owner">
      verified_user
    </mat-icon>
    <mat-icon class="admin-label" *ngIf="user.isAdmin" matTooltip="Admin User">
      security
    </mat-icon>
  </span>

  <span matListItemLine class="mat-caption">{{
    user.email || 'No Email'
  }}</span>

  <button
    matLineItemMeta
    color="primary"
    *ngIf="!user.email"
    (click)="addEmail(user)"
  >
    Add email
  </button>

  <small matListItemLine class="last-login mat-caption"
    >Last Active:
    <ng-container
      *ngIf="user | map : getLastActiveAt as lastActiveAt; else notActivated"
      >{{ lastActiveAt | moment | amDateFormat : dateFormat }}</ng-container
    >
    <ng-template #notActivated>Never</ng-template>
  </small>

  <div matListItemMeta>
    <div class="buttons-container flex flex-row items-center">
      <button
        mat-button
        fxHide.lt-sm
        [color]="user.isActivated ? 'warn' : 'primary'"
        (click)="toggleUserEnabled.emit()"
      >
        {{ user.isActivated ? 'Disable' : 'Enable' }}
      </button>

      <div fxHide.gt-xs>
        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
          <mat-icon class="font-bold">more_vert</mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu">
          <button
            mat-menu-item
            [ngClass]="{ warn: user.isActivated }"
            (click)="toggleUserEnabled.emit()"
          >
            <mat-icon [color]="user.isActivated ? 'warn' : 'primary'">{{
              user.isActivated ? 'unpublished' : 'check_circle'
            }}</mat-icon>
            <span>{{ user.isActivated ? 'Disable' : 'Enable' }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-list-item>
