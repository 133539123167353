import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { doc$, type IProfile, type WithRef } from '@principle-theorem/shared';
import {
  type ISkill,
  type IUser,
  skillLevelToColor,
} from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserSkillProgress } from '../../../../models/user-skill-progress';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
    selector: 'lu-skill-card',
    templateUrl: './skill-card.component.html',
    styleUrls: ['./skill-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillCardComponent {
  skill$: ReplaySubject<WithRef<ISkill>> = new ReplaySubject(1);
  user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject(1);
  author$: Observable<WithRef<IProfile> | undefined>;
  colour$: Observable<string>;
  @Input() link: RouterLinkData;

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(private _organisation: OrganisationService) {
    const progress = new UserSkillProgress(
      this._organisation.userGroups$,
      this.user$,
      this.skill$
    );

    this.colour$ = progress.current$.pipe(
      map((level) => skillLevelToColor(level))
    );

    this.author$ = this.skill$.pipe(
      switchMap((data) => (data.author ? doc$(data.author) : of(undefined)))
    );
  }
}
