<ng-container *ngIf="skills$ | async as skills">
  <div
    *ngIf="skills.length"
    fxLayout="row"
    fxLayoutAlign="end center"
    class="mat-caption list-headers"
  >
    <span class="author-column max-sm:hidden">Creator</span>
    <span class="action-column max-sm:hidden">Actions</span>
  </div>

  <mat-list class="with-dividers" *ngIf="skills.length; else noSkills">
    <mat-list-item *ngFor="let skill of skills; trackBy: trackBySkill">
      <div
        class="skill-content flex max-w-full flex-col place-content-between sm:flex-row"
      >
        <div class="flex flex-col">
          <div class="flex flex-row items-center gap-2">
            <a [routerLink]="['/explore/skill', skill.ref.id]">
              {{ skill.name }}
            </a>
            <mat-icon
              *ngIf="skill.readOnly"
              matTooltip="Read only"
              class="read-only"
              >lock</mat-icon
            >
            <lu-privacy-icon
              *ngIf="skill.restrictAccessTo.length"
              [item]="skill"
            />
          </div>

          <span class="mat-caption"
            >Last Updated: {{ skill.updatedAt | moment | amTimeAgo }}</span
          >

          <span class="mat-caption" *ngIf="skill.reviewers.length"
            >Reviewers:
            <span
              *ngFor="
                let reviewerRef of skill.reviewers;
                last as isLast;
                trackBy: trackByReviewer
              "
            >
              <ng-container *ngIf="reviewerRef | refItem$ | async as reviewer"
                >{{ reviewer.name }}<span *ngIf="!isLast">,</span></ng-container
              >
            </span>
          </span>

          <lu-tag-display [tagRefs]="skill.tags" />
        </div>

        <div class="flex flex-col sm:flex-row sm:items-center">
          <div
            class="mr-6 max-sm:flex max-sm:gap-4"
            *ngIf="!(skill | isApproved)"
          >
            <span class="mat-caption sm:hidden">Due Date:</span>
            <lu-user-goal-due-date-indicator
              canEdit
              placement="after"
              [dueDate]="skill.dueDate"
              (dateChange)="updateDueDate(skill, $event)"
            />
          </div>

          <ng-container *ngIf="skill.author | refItem$ | async as author">
            <div
              class="mr-4 max-sm:mb-2 max-sm:flex max-sm:items-center max-sm:gap-4"
            >
              <span class="mat-caption sm:hidden">Author:</span>
              <pt-user-icon
                [src]="author | profileImage$ | async"
                [diameter]="30"
                [name]="author.name"
                [matTooltip]="author.name"
              />
            </div>
          </ng-container>

          <ng-container
            *ngIf="skill | map : canReview$ : this | async; else edit"
          >
            <button
              mat-stroked-button
              class="flex max-sm:w-full"
              [routerLink]="['/explore/skill', skill.ref.id]"
            >
              Review
            </button>
          </ng-container>
          <ng-template #edit>
            <button
              mat-stroked-button
              class="flex max-sm:w-full"
              [routerLink]="['/create', skill.ref.id]"
              [disabled]="skill.readOnly"
            >
              Edit
            </button>
          </ng-template>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</ng-container>

<ng-template #noSkills>
  <div
    class="mat-caption layout-margin"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    No Skills
  </div>
</ng-template>
