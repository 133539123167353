import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import {
  BasicMenuButtonComponent,
  cleanUpBlockCommand,
} from '../../../../menu-bar/basic-menu-button/basic-menu-button.component';
import {
  AIImagePromptDialogComponent,
  IAIImagePromptData,
  IAIImagePromptOutput,
} from '../ai-image-prompt-dialog/ai-image-prompt-dialog.component';

@Component({
  selector: 'pt-ai-image-prompt-menu',
  templateUrl: './ai-image-prompt-menu.component.html',
  styleUrls: ['./ai-image-prompt-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AIImagePromptMenuComponent extends BasicMenuButtonComponent {
  override tooltip = 'Generates an image based on prompt and style';

  constructor(private _dialog: BasicDialogService) {
    super();
  }

  async showPromptDialog(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const { from, to } = editor.view.state.selection;
    const initialPrompt = editor.state.doc.textBetween(from, to, '\n');
    const prompt = await this._dialog
      .open<
        AIImagePromptDialogComponent,
        IAIImagePromptData,
        IAIImagePromptOutput
      >(AIImagePromptDialogComponent, this._getDialogConfig(initialPrompt))
      .afterClosed()
      .toPromise();

    if (prompt) {
      editor
        .chain()
        .focus()
        .aiImagePrompt({
          text: prompt.prompt,
          style: prompt.imageStyle ?? 'photorealistic',
          size: '1024x1024',
        })
        .focus()
        .run();
    }
  }

  override async runCommand(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    await this.showPromptDialog();
    editor.view.focus();
  }

  private _getDialogConfig(
    initialPrompt?: string
  ): MatDialogConfig<IAIImagePromptData> {
    return DialogPresets.medium({
      restoreFocus: false,
      hasBackdrop: true,
      autoFocus: true,
      data: {
        initialPrompt,
      },
    });
  }
}
