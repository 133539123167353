import {
  isSameRef,
  toTimestamp,
  type AtLeast,
  type DocumentReference,
  type IReffable,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type IGoals,
  type ISkillAssociation,
  type ISkillLevelRequirement,
  type IUserGroup,
} from '../user-group/user-group';
import { User, type IUser } from '../user/user';
import { type ISkill } from './skill';
import { SkillLevel, skillLevelToNumber } from './skill-level';
import { SkillRefType, type ISkillRef } from './skill-type';

export interface ISkillProgressHistory {
  level: SkillLevel;
  achievedAt: Timestamp;
  accreditor: DocumentReference<IUser>;
}

export interface ISkillProgress {
  skillRef: ISkillRef<ISkill>;
  history: ISkillProgressHistory[];
}

export interface IUserSkillProgressPair {
  progress: WithRef<ISkillProgress>;
  userRef: DocumentReference<IUser>;
}

export class SkillProgress {
  static init(overrides: AtLeast<ISkillProgress, 'skillRef'>): ISkillProgress {
    return {
      history: [],
      ...overrides,
    };
  }
}

export function setProgressLevel(
  progress: ISkillProgress,
  level: SkillLevel,
  userRef: DocumentReference<IUser>
): void {
  progress.history.push({
    level,
    achievedAt: toTimestamp(),
    accreditor: userRef,
  });
}

export function currentSkillLevel(
  progress: ISkillProgress
): ISkillProgressHistory | undefined {
  return progress.history[progress.history.length - 1];
}

export function currentSkillLevelValue(progress: ISkillProgress): SkillLevel {
  const current = currentSkillLevel(progress);
  return current ? current.level : SkillLevel.None;
}

export function hasReachedLevel(
  progress: ISkillProgress,
  level: SkillLevel
): boolean {
  const current = currentSkillLevelValue(progress);
  return skillLevelToNumber(current) >= skillLevelToNumber(level);
}

export function hasReachedGoal(
  goals: IGoals,
  progress: ISkillProgress
): boolean {
  const goalProgress = getGoalProgress(goals, progress);
  const hasGoal = goalProgress.starsGoal > 0;
  const hasAchievedGoal = goalProgress.starsEarned >= goalProgress.starsGoal;
  return hasGoal && hasAchievedGoal;
}

export interface ILevelUp {
  progress: ISkillProgress;
  event: ISkillProgressHistory;
  from: SkillLevel;
  to: SkillLevel;
  difference: number;
}

export interface IUserLevelUpPair {
  userRef: DocumentReference<IUser>;
  levelUp: ILevelUp;
}

export function getLevelUps(
  userProgressPair: IUserSkillProgressPair
): IUserLevelUpPair[] {
  return userProgressPair.progress.history.map((event, index) => {
    const previous: ISkillProgressHistory | undefined =
      userProgressPair.progress.history[index - 1];
    const previousLevel = previous ? previous.level : SkillLevel.None;
    return {
      userRef: userProgressPair.userRef,
      levelUp: {
        progress: userProgressPair.progress,
        event,
        from: previousLevel,
        to: event.level,
        difference:
          skillLevelToNumber(event.level) - skillLevelToNumber(previousLevel),
      },
    };
  });
}

export function getTrainerVerifiedLevelUps(
  user: IReffable<IUser>,
  levelUpPairs: IUserLevelUpPair[]
): ILevelUp[] {
  return levelUpPairs
    .filter(
      (levelUpPair) =>
        !isSameRef(levelUpPair.userRef, levelUpPair.levelUp.event.accreditor) &&
        isLevelUpAccreditor(levelUpPair.levelUp, user.ref)
    )
    .map((levelUpPair) => levelUpPair.levelUp);
}

export function getSelfLevelUps(
  user: IReffable<IUser>,
  levelUpPairs: IUserLevelUpPair[]
): ILevelUp[] {
  return levelUpPairs
    .filter(
      (levelUpPair) =>
        isSameRef(user.ref, levelUpPair.userRef) &&
        isSameRef(levelUpPair.userRef, levelUpPair.levelUp.event.accreditor)
    )
    .map((levelUpPair) => levelUpPair.levelUp);
}

export function getTrainerLevelUps(
  user: IReffable<IUser>,
  levelUpPairs: IUserLevelUpPair[]
): ILevelUp[] {
  return levelUpPairs
    .filter(
      (levelUpPair) =>
        isSameRef(user.ref, levelUpPair.userRef) &&
        !isSameRef(levelUpPair.userRef, levelUpPair.levelUp.event.accreditor)
    )
    .map((levelUpPair) => levelUpPair.levelUp);
}

export function isLevelUpAccreditor(
  levelUp: ILevelUp,
  accreditor: DocumentReference<IUser>
): boolean {
  return levelUp.event.accreditor.path === accreditor.path;
}

export function findAssociationBySkill(
  skill: WithRef<ISkill>,
  skillAssociations: ISkillAssociation[]
): ISkillAssociation | undefined {
  return skillAssociations.find((skillAssocaition) =>
    isSameRef(skillAssocaition.skill, skill)
  );
}

export function findRequirementBySkill(
  skill: WithRef<ISkill> | ISkillRef<ISkill>,
  skillLevels: ISkillLevelRequirement[]
): ISkillLevelRequirement | undefined {
  return skillLevels.find((skillLevel) => isSameRef(skillLevel.skill, skill));
}

export function findProgressBySkill(
  skill: WithRef<ISkill>,
  progress: ISkillProgress[]
): ISkillProgress | undefined {
  return progress.find((item) => isSameRef(item.skillRef, skill));
}

export function updateSkillLevelRequirement(
  skill: WithRef<ISkill> | ISkillRef<ISkill>,
  changes: Partial<
    Pick<ISkillLevelRequirement, 'level' | 'dueDate' | 'relativeDueDate'>
  >,
  skillLevels: ISkillLevelRequirement[]
): ISkillLevelRequirement[] {
  const currentSkill = findRequirementBySkill(skill, skillLevels);
  if (!currentSkill) {
    return [
      ...skillLevels,
      {
        skill: {
          ref: skill.ref,
          type: SkillRefType.Skill,
        },
        level: SkillLevel.None,
        ...changes,
      },
    ];
  }

  return skillLevels.map((skillLevel) => {
    if (!isSameRef(skillLevel.skill, currentSkill.skill)) {
      return skillLevel;
    }

    return {
      ...skillLevel,
      ...changes,
    };
  });
}

export function skillWithoutGoal(
  goals: IGoals,
  skill: WithRef<ISkill>
): boolean {
  const levelRequirement = goals.levelRequirements.find((req) =>
    isSameRef(req.skill, skill)
  );
  return !levelRequirement || levelRequirement.level === SkillLevel.None;
}

export function skillHasGoal(goals: IGoals, skill: WithRef<ISkill>): boolean {
  const levelRequirement = goals.levelRequirements.find((req) =>
    isSameRef(req.skill, skill)
  );
  return levelRequirement && levelRequirement.level !== SkillLevel.None
    ? true
    : false;
}

export function skillGoalAchieved(
  goals: IGoals,
  progress?: ISkillProgress
): boolean {
  if (!progress) {
    return false;
  }
  return hasReachedGoal(goals, progress);
}

export function getGoalProgress(
  goals: IGoals,
  progress: ISkillProgress
): IGoalProgress {
  const levelEarned = currentSkillLevelValue(progress);
  const starsEarned = skillLevelToNumber(levelEarned);

  const progressReq = goals.levelRequirements.find((req) =>
    isSameRef(req.skill, progress.skillRef)
  );
  if (!progressReq) {
    return {
      levelEarned,
      starsEarned,
      levelGoal: SkillLevel.None,
      starsGoal: 0,
    };
  }
  const levelGoal = progressReq.level;
  const starsGoal = skillLevelToNumber(levelGoal);
  return {
    levelEarned,
    starsEarned,
    levelGoal,
    starsGoal,
  };
}

export interface IGoalProgress {
  levelEarned: SkillLevel;
  starsEarned: number;
  levelGoal: SkillLevel;
  starsGoal: number;
}

export function userHasUnmetGoals(
  skill: WithRef<ISkill>,
  user: WithRef<IUser>,
  userGroups: WithRef<IUserGroup>[],
  progress?: ISkillProgress
): boolean {
  const goals = User.getGoals(user, userGroups);
  const hasGoal = skillHasGoal(goals, skill);

  if (hasGoal && !progress) {
    return true;
  }

  if (progress) {
    const goalProgress = getGoalProgress(goals, progress);

    if (goalProgress.starsGoal > goalProgress.starsEarned) {
      return true;
    }
  }

  return false;
}
