import { Injectable, type OnDestroy } from '@angular/core';
import {
  MentionResourceType,
  type IPathway,
  type IRecentlyViewed,
  type ISkill,
} from '@principle-theorem/level-up-core';
import {
  filterUndefined,
  isSameRef,
  patchDoc,
  shareReplayHot,
  sortTimestamp,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { noop, uniqWith } from 'lodash';
import { BehaviorSubject, Subject, combineLatest, type Observable } from 'rxjs';
import {
  auditTime,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root',
})
export class LastViewedService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _recentlyViewedAdded$ = new BehaviorSubject<
    IRecentlyViewed | undefined
  >(undefined);
  recentlyViewed$: Observable<IRecentlyViewed[]>;

  constructor(private _organisation: OrganisationService) {
    const savedRecentlyViewed$ = this._organisation.user$.pipe(
      filterUndefined(),
      map((user) => user.recentlyViewed ?? []),
      distinctUntilChanged(
        (a, b) =>
          a.length === b.length &&
          a.every((item, index) => isSameRef(item.ref, b[index].ref))
      ),
      tap(() => this._recentlyViewedAdded$.next(undefined))
    );

    this.recentlyViewed$ = combineLatest([
      savedRecentlyViewed$,
      this._recentlyViewedAdded$,
    ]).pipe(
      map(([saved, added]) => this._mergeRecentlyViewed(added, saved)),
      startWith([]),
      shareReplayHot(this._onDestroy$)
    );

    this.recentlyViewed$
      .pipe(
        auditTime(1000),
        filter(() => !!this._recentlyViewedAdded$.value),
        withLatestFrom(this._organisation.user$.pipe(filterUndefined())),
        concatMap(([recentlyViewed, user]) =>
          patchDoc(user.ref, {
            recentlyViewed,
          })
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  trackSkillVisit(skill: Pick<WithRef<ISkill>, 'ref' | 'name'>): void {
    this._recentlyViewedAdded$.next({
      title: skill.name,
      ref: skill.ref,
      type: MentionResourceType.Skill,
      viewedAt: toTimestamp(),
    });
  }

  trackPathwayVisit(pathway: Pick<WithRef<IPathway>, 'ref' | 'name'>): void {
    this._recentlyViewedAdded$.next({
      title: pathway.name,
      ref: pathway.ref,
      type: MentionResourceType.Pathway,
      viewedAt: toTimestamp(),
    });
  }

  private _mergeRecentlyViewed(
    newViewed: IRecentlyViewed | undefined,
    allViewed: IRecentlyViewed[]
  ): IRecentlyViewed<object>[] {
    const recentlyViewed = newViewed ? [newViewed, ...allViewed] : allViewed;
    return uniqWith(recentlyViewed, isSameRef)
      .sort((pageA, pageB) => sortTimestamp(pageA.viewedAt, pageB.viewedAt))
      .slice(0, 10);
  }
}
