import {
  ISkill,
  SkillRefType,
  User,
  UserGroup,
  updateSkillLevelRequirement,
  type IGoals,
  type IPathway,
  type ISkillLevelRequirement,
  type ISkillRef,
  type IUser,
  type IUserGroup,
  IPathwayAssociation,
  Pathway,
} from '@principle-theorem/level-up-core';
import {
  getDoc,
  isSameRef,
  multiMap,
  multiSwitchMap,
  patchDoc,
  reduceToSingleArray,
  shareReplayHot,
  snapshot,
  type DocumentReference,
  type Reffable,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, combineLatest, type Observable } from 'rxjs';
import { map, scan, startWith, switchMap, take } from 'rxjs/operators';

export class UserEditBloc {
  private _changes$ = new Subject<Partial<IUser>>();
  user$: Observable<WithRef<IUser>>;
  combinedGoals$: Observable<IGoals>;

  constructor(
    private _initialGroup$: Observable<WithRef<IUser>>,
    private _userGroups$: Observable<WithRef<IUserGroup>[]>,
    destroy$: Observable<void>
  ) {
    this.user$ = this._initialGroup$.pipe(
      take(1),
      switchMap((initialGroup) =>
        this._changes$.pipe(
          scan(
            (accumulated, changes) => ({ ...accumulated, ...changes }),
            initialGroup
          ),
          startWith(initialGroup)
        )
      ),
      shareReplayHot(destroy$)
    );

    this.combinedGoals$ = combineLatest([this.user$, this._userGroups$]).pipe(
      map(([user, userGroup]) => User.getGoals(user, userGroup))
    );
  }

  async updateGoals(goals: IGoals): Promise<void> {
    const user = await snapshot(this.user$);
    const { skillAssociations, levelRequirements, pathwayAssociations } =
      UserGroup.updateNewGoals(user, goals);

    const excludedPathwayAssociations = user.excludedPathwayAssociations.filter(
      (pathwayAssociation) =>
        goals.pathwayAssociations.every(
          (goalPathwayAssociation) =>
            !isSameRef(goalPathwayAssociation, pathwayAssociation)
        )
    );

    const excludedSkillAssociations = user.excludedSkillAssociations.filter(
      (skillAssociation) =>
        goals.skillAssociations.every(
          (goalSkillAssociation) =>
            !isSameRef(goalSkillAssociation.skill, skillAssociation.skill)
        )
    );

    this._changes$.next({
      pathwayAssociations,
      skillAssociations,
      levelRequirements,
      excludedPathwayAssociations,
      excludedSkillAssociations,
    });
    await this.save();
  }

  async addSkill(skill: WithRef<ISkill>): Promise<void> {
    const user = await snapshot(this.user$);
    const { skillAssociations, levelRequirements } =
      UserGroup.addSkillAssociation(user, skill);

    const excludedSkillAssociations = user.excludedSkillAssociations.filter(
      (skillAssociation) => !isSameRef(skillAssociation.skill, skill)
    );

    this._changes$.next({
      skillAssociations,
      levelRequirements,
      excludedSkillAssociations,
    });
  }

  async removeSkill(skillRef: DocumentReference<ISkill>): Promise<void> {
    const user = await snapshot(this.user$);
    const skill = await getDoc(skillRef);
    this._changes$.next({
      levelRequirements: await this._removeSkillLevelRequirements(
        [
          {
            ref: skillRef,
            type: SkillRefType.Skill,
          },
        ],
        user.levelRequirements
      ),
      skillAssociations: UserGroup.removeSkill(user, skillRef),
      excludedSkillAssociations: User.excludeSkillAssociation(user, skill)
        .excludedSkillAssociations,
    });
    await this.save();
  }

  async addPathway(pathway: Reffable<IPathway>): Promise<void> {
    const user = await snapshot(this.user$);
    const { pathwayAssociations, levelRequirements } =
      UserGroup.addPathwayAssociation(user, pathway);

    const excludedPathwayAssociations = user.excludedPathwayAssociations.filter(
      (pathwayAssociation) => !isSameRef(pathwayAssociation, pathway)
    );

    this._changes$.next({
      pathwayAssociations,
      levelRequirements,
      excludedPathwayAssociations,
    });
  }

  async removePathway(pathwayRef: DocumentReference<IPathway>): Promise<void> {
    const user = await snapshot(this.user$);
    const pathwayAssociations = user.pathwayAssociations.filter(
      (pathwayAssociation) => !isSameRef(pathwayAssociation, pathwayRef)
    );

    const pathway = await getDoc(pathwayRef);
    const skillRefs = Pathway.skillRefs(pathway).map((skillRef) => ({
      ref: skillRef,
      type: SkillRefType.Skill,
    }));
    const levelRequirements = await this._removeSkillLevelRequirements(
      skillRefs,
      user.levelRequirements
    );

    const excluded = User.excludePathwayAssociation(user, pathway);

    this._changes$.next({
      pathwayAssociations,
      levelRequirements,
      excludedPathwayAssociations: excluded.excludedPathwayAssociations,
    });
    await this.save();
  }

  async save(): Promise<void> {
    const user = await snapshot(this.user$);
    await patchDoc<IUser>(user.ref, {
      levelRequirements: user.levelRequirements,
      pathwayAssociations: user.pathwayAssociations,
      skillAssociations: user.skillAssociations,
      excludedPathwayAssociations: user.excludedPathwayAssociations,
      excludedSkillAssociations: user.excludedSkillAssociations,
    });
  }

  async updateGoalLevel(
    levelRequirement: ISkillLevelRequirement
  ): Promise<void> {
    const skillLevels = await snapshot(this.user$);
    const levelRequirements = updateSkillLevelRequirement(
      levelRequirement.skill,
      { level: levelRequirement.level },
      skillLevels.levelRequirements
    );
    this._changes$.next({ levelRequirements });
    await this.save();
  }

  async updateSkillLevels(
    levelRequirements: ISkillLevelRequirement[]
  ): Promise<void> {
    this._changes$.next({ levelRequirements });
    await this.save();
  }

  async updatePathwayLevels(
    pathwayAssociations: IPathwayAssociation[]
  ): Promise<void> {
    this._changes$.next({ pathwayAssociations });
    await this.save();
  }

  private async _removeSkillLevelRequirements(
    skillRefs: ISkillRef<ISkill>[],
    userLevelRequirements: ISkillLevelRequirement[]
  ): Promise<ISkillLevelRequirement[]> {
    const pathwaySkills = await snapshot(this._getPathwaySkills$());

    return skillRefs.reduce((filteredLevelRequirements, skillRef) => {
      if (pathwaySkills.includes(skillRef.ref.path)) {
        return filteredLevelRequirements;
      }

      return userLevelRequirements.filter(
        (levelRequirement) => !isSameRef(levelRequirement.skill, skillRef)
      );
    }, userLevelRequirements);
  }

  private _getPathwaySkills$(): Observable<string[]> {
    return this.combinedGoals$.pipe(
      map((goals) => goals.pathwayAssociations),
      multiSwitchMap((pathwayRef) => getDoc(pathwayRef.ref)),
      multiMap((pathway) => Pathway.skillRefs(pathway)),
      map(reduceToSingleArray),
      multiMap((skillRef) => skillRef.path)
    );
  }
}
