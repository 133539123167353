<ng-container *ngIf="subscription$ | async as subscription">
  <ng-container *ngIf="bundle$ | async as bundle">
    <div
      class="grid-rows-12 relative grid cursor-pointer grid-cols-1 overflow-hidden rounded-lg border border-solid border-gray-200 transition-colors hover:border-black sm:max-h-48 sm:grid-cols-12"
      [routerLink]="['/marketplace/subscriptions', subscription.ref.id]"
    >
      <div class="relative row-span-3 md:col-span-3">
        <lu-card-image
          [image]="bundle.imageUrl"
          [placeholderImage]="subscriptionCardPlaceholderImage"
        />
      </div>

      <div
        class="row-span-6 flex flex-col justify-stretch px-3 py-2 sm:col-span-6 sm:border-r sm:border-solid sm:border-gray-200"
      >
        <div class="flex grow flex-col overflow-hidden">
          <div class="title">
            <div class="line-clamp-2 text-[16px] font-bold">
              {{ bundle.name }}
            </div>
          </div>
          <div class="description max-h-20 grow sm:max-h-16">
            <lu-content-editor-view
              *ngIf="bundle.description"
              [content]="bundle.description"
            />
          </div>
        </div>

        <pt-loader-button
          *luIsAdmin
          matTooltip="All Skills and Pathways have been copied"
          color="primary"
          type="stroked"
          [matTooltipDisabled]="(copyButtonDisabled$ | async) === false"
          [loading]="loading$ | async"
          [disabled]="copyButtonDisabled$ | async"
          (click)="$event.stopPropagation(); copyToFolder(bundle)"
        >
          <div class="flex items-center gap-2">
            <mat-icon class="copy-all-icon">drive_file_move</mat-icon>
            <span>Copy to Folder</span>
          </div>
        </pt-loader-button>
      </div>

      <div
        class="row-span-3 flex flex-col justify-between px-3 py-2 sm:col-span-3"
      >
        <div class="flex flex-col">
          <div class="flex self-end"></div>
        </div>

        <div class="mb-2 flex flex-col gap-4">
          <lu-author *ngIf="author$ | async as author" [author]="author" />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
