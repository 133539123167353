import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';
import { type IAchievement } from '@principle-theorem/level-up-core';

@Component({
  selector: 'lu-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementComponent {
  @Input() achievement: IAchievement;

  @Input()
  @HostBinding('class.locked')
  locked = true;
}
