import {
  count,
  multiFilter,
  multiMap,
  multiSort,
  multiSwitchMap,
  safeCombineLatest,
  sortTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { combineLatest, from, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ISkill, Skill, SkillStatus } from '../models/skill/skill';
import { reviewerHasApproved } from '../models/skill/skill-review';
import { type IUser } from '../models/user/user';
import { StatView } from './stat-view';
import { filterByStatus } from './user-author-stats';

export class UserReviewStats {
  lastReviewedAt$: Observable<Timestamp>;
  currentSkillsToReview$: Observable<WithRef<ISkill>[]>;
  reviewedSkills$: Observable<WithRef<ISkill>[]>;
  approved: StatView;
  awaitingReview: StatView;
  contribution: StatView;

  constructor(
    user$: Observable<WithRef<IUser>>,
    skills$: Observable<WithRef<ISkill>[]>
  ) {
    this.currentSkillsToReview$ = combineLatest([user$, skills$]).pipe(
      map(([user, skills]) =>
        skills.filter((skill) => Skill.isReviewer(skill, user.ref))
      )
    );

    this.reviewedSkills$ = combineLatest([user$, skills$]).pipe(
      switchMap(([user, skills]) =>
        safeCombineLatest(
          skills.map((skill) =>
            combineLatest([of(skill), Skill.hasReviewed(skill, user)])
          )
        )
      ),
      multiFilter(([_skill, hasReviewed]) => hasReviewed),
      multiMap(([skill, _hasReviewed]) => skill)
    );

    this.lastReviewedAt$ = this.reviewedSkills$.pipe(
      multiSort((skillA, skillB) =>
        sortTimestamp(skillA.updatedAt, skillB.updatedAt)
      ),
      multiMap((skill) => skill.updatedAt),
      multiFilter((timestamp) => timestamp !== undefined),
      map((skills) => skills[0])
    );

    const hasApproved$ = user$.pipe(
      switchMap((user) =>
        this.reviewedSkills$.pipe(
          multiSwitchMap((skill) =>
            from(Skill.reviews(skill)).pipe(
              map((reviews) => ({ skill, reviews }))
            )
          ),
          multiFilter(({ reviews }) => reviewerHasApproved(reviews, user.ref)),
          multiMap(({ skill }) => skill)
        )
      )
    );

    this.approved = new StatView(
      hasApproved$.pipe(count()),
      this.reviewedSkills$.pipe(count())
    );

    const skillsAwaitingReview$ = user$.pipe(
      switchMap((user) =>
        this.currentSkillsToReview$
          .pipe(multiFilter((skill) => Skill.inReview(skill)))
          .pipe(
            multiSwitchMap((skill) =>
              from(Skill.reviews(skill)).pipe(
                map((reviews) => ({ skill, reviews }))
              )
            ),
            multiFilter(
              ({ reviews }) => !reviewerHasApproved(reviews, user.ref)
            ),
            multiMap(({ skill }) => skill)
          )
      )
    );

    this.awaitingReview = new StatView(
      of(0),
      skillsAwaitingReview$.pipe(count())
    );

    this.contribution = new StatView(
      hasApproved$.pipe(count()),
      skills$.pipe(filterByStatus(SkillStatus.Published), count())
    );
  }
}
