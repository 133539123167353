import { Component, Input } from '@angular/core';

@Component({
    selector: 'lu-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: false
})
export class LogoComponent {
  @Input() colour?: string;
  @Input() width?: number;
  @Input() height = 100;
}
