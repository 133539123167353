import { Pipe, type PipeTransform } from '@angular/core';
import { toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';

@Pipe({
    name: 'fromNow',
    standalone: false
})
export class FromNowPipe implements PipeTransform {
  transform(value: Timestamp | Moment, withoutPrefix?: boolean): string {
    return toMoment(value).fromNow(withoutPrefix);
  }
}
