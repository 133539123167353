import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PathwayAndSkillsListService } from '../pathway-and-skills-list.service';

@Component({
  selector: 'lu-explore-pathways-list',
  templateUrl: './explore-pathways-list.component.html',
  styleUrls: ['./explore-pathways-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExplorePathwaysListComponent {
  constructor(public bloc: PathwayAndSkillsListService) {}
}
