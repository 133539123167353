import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { patchDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { type ITag } from '@principle-theorem/level-up-core';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  EditTagDialogComponent,
  type IEditTagDialogData,
  type TagFormData,
} from '../edit-tag-dialog/edit-tag-dialog.component';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-tag-list-item',
  templateUrl: './tag-list-item.component.html',
  styleUrls: ['./tag-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListItemComponent {
  tag$ = new ReplaySubject<WithRef<ITag>>(1);

  @Output() tagSelected = new EventEmitter<WithRef<ITag>>();

  constructor(private _dialog: MatDialog) {}

  @Input()
  set tag(tag: WithRef<ITag>) {
    if (tag) {
      this.tag$.next(tag);
    }
  }

  async edit(): Promise<void> {
    const updateData = await this._openEditDialog();
    if (!updateData) {
      return;
    }
    const tag = await snapshot(this.tag$);
    await patchDoc(tag.ref, updateData);
  }

  select($event: MouseEvent, tag: WithRef<ITag>): void {
    $event.preventDefault();
    this.tagSelected.next(tag);
  }

  private async _openEditDialog(): Promise<TagFormData | undefined> {
    const tag = await snapshot(this.tag$);
    const config: MatDialogConfig<IEditTagDialogData> = {
      data: {
        action: 'Edit',
        tag,
      },
    };
    return this._dialog
      .open<EditTagDialogComponent, IEditTagDialogData, TagFormData>(
        EditTagDialogComponent,
        DialogPresets.small<IEditTagDialogData>(config)
      )
      .afterClosed()
      .toPromise();
  }
}
