<ng-container *ngIf="item$ | async as item">
  <ng-container
    *ngIf="
      item.ref | map : favourites.isFavourite$ : favourites | async;
      else addItem
    "
  >
    <button
      class="group/item"
      ptIntercomTarget="favourites.remove-button"
      mat-icon-button
      (click)="toggle(item)"
      matTooltip="Remove from favourites"
    >
      <mat-icon
        color="primary"
        class="group/favourite font-bold group-hover/item:!hidden"
        >favorite</mat-icon
      >
      <mat-icon
        class="group/unfavourite ml-[1px] mr-[-1px] !hidden group-hover/item:!block"
        >heart_minus</mat-icon
      >
    </button>
  </ng-container>
  <ng-template #addItem>
    <button
      ptIntercomTarget="favourites.add-button"
      mat-icon-button
      (click)="toggle(item)"
      matTooltip="Add to favourites"
    >
      <mat-icon>favorite</mat-icon>
    </button>
  </ng-template>
</ng-container>
