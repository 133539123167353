<mat-toolbar color="primary">
  <h2>Set Due Date</h2>
</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col justify-stretch gap-4">
    <div class="mat-caption">
      Any user assigned to the group will have a due date set X days afer.
    </div>
    <mat-form-field class="grow">
      <input
        matInput
        type="number"
        min="0"
        step="1"
        matTooltip="Set due date"
        matTooltipShowDelay="300"
        [formControl]="dueDateCtrl"
      />
      <span matTextSuffix class="ml-2">days</span>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="dueDateCtrl.invalid"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
