import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pt-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AlertComponent {
  @Input() alert = '';
}
