import { Injectable } from '@angular/core';
import {
  RootCollection,
  type IManagementUser,
  type IVendor,
} from '@principle-theorem/level-up-core';
import { AuthService } from '@principle-theorem/ng-auth';
import { asColRef, find$, type WithRef } from '@principle-theorem/shared';
import { where, type CollectionReference } from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ManagementService {
  user$: Observable<WithRef<IManagementUser> | undefined>;
  usersCol: CollectionReference<IManagementUser>;
  vendorsCol: CollectionReference<IVendor>;

  constructor(private _auth: AuthService) {
    this.usersCol = asColRef<IManagementUser>(RootCollection.ManagementUsers);
    this.vendorsCol = asColRef<IVendor>(RootCollection.MarketplaceVendors);
    this.user$ = this._auth.claims$.pipe(
      switchMap((claims) =>
        claims && claims.isManagement
          ? this._toManagementUser$()
          : of(undefined)
      )
    );
  }

  private _toManagementUser$(): Observable<
    WithRef<IManagementUser> | undefined
  > {
    return this._authUserEmail$().pipe(
      switchMap((email) => {
        if (!email) {
          return of(undefined);
        }
        return find$(this.usersCol, where('email', '==', email));
      })
    );
  }

  private _authUserEmail$(): Observable<string | undefined> {
    return this._auth.authUser$.pipe(
      map((authUser) =>
        authUser && authUser.email ? authUser.email : undefined
      )
    );
  }
}
