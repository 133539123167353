import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UserGroup,
  type ISkill,
  type IUserGroup,
  SkillLevel,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  multiMap,
  saveDoc,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { isBoolean } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-group-association',
    templateUrl: './group-association.component.html',
    styleUrls: ['./group-association.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GroupAssociationComponent {
  trackByGroup = TrackByFunctions.ref<WithRef<IUserGroup>>();
  skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  requiresTrainerVerification$ = new BehaviorSubject<boolean | undefined>(
    undefined
  );
  groups$: Observable<WithRef<IUserGroup>[]>;
  existingGroups$: Observable<DocumentReference<IUserGroup>[]>;

  @Input()
  set skill(skill: WithRef<ISkill>) {
    this.skill$.next(skill);
  }

  @Input()
  set requiresTrainerVerification(
    requiresTrainerVerification: boolean | undefined
  ) {
    if (isBoolean(requiresTrainerVerification)) {
      this.requiresTrainerVerification$.next(requiresTrainerVerification);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this.groups$ = combineLatest([
      this.skill$,
      this._organisation.userGroups$,
    ]).pipe(
      map(([skill, groups]) => {
        return groups.filter((group: WithRef<IUserGroup>) =>
          UserGroup.hasSkillLevelRequirement(group, skill)
        );
      })
    );

    this.existingGroups$ = combineLatest([
      this.skill$,
      this._organisation.userGroups$,
    ]).pipe(
      map(([skill, groups]) =>
        groups.filter((group) => UserGroup.hasSkillAssociation(group, skill))
      ),
      multiMap((group) => group.ref)
    );
  }

  async addGroup(
    group: WithRef<IUserGroup>,
    skill: WithRef<ISkill>
  ): Promise<void> {
    const level = skill.requiresTrainerVerification
      ? SkillLevel.VerifiedByTrainer
      : SkillLevel.Viewed;
    await saveDoc(UserGroup.addSkillAssociation(group, skill, level));
    this._snackBar.open(`Skill added to ${group.name} Group`);
  }

  async removeGroup(
    group: WithRef<IUserGroup>,
    skill: WithRef<ISkill>
  ): Promise<void> {
    UserGroup.removeSkillAssociation(group, skill);
    await saveDoc(group);
    this._snackBar.open(`Skill removed from ${group.name} Group`);
  }

  getLevelForSkill(data: {
    group: WithRef<IUserGroup>;
    skill: WithRef<ISkill>;
  }): SkillLevel | undefined {
    return UserGroup.getLevelForSkill(data.group, data.skill)?.level;
  }

  async handleLevelChange(
    level: SkillLevel,
    skill: WithRef<ISkill>,
    group: WithRef<IUserGroup>
  ): Promise<void> {
    const currentRequirement = UserGroup.getLevelForSkill(group, skill);

    if (!currentRequirement) {
      await saveDoc(UserGroup.addSkillAssociation(group, skill, level));
    } else {
      await saveDoc(UserGroup.updateSkillAssociation(group, skill, level));
    }

    this._snackBar.open(`Skill level requirements updated`);
  }
}
