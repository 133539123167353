<ng-container *ngIf="dueDate$ | async as dueDate">
  <div fxFill fxLayout="row" fxLayoutAlign="center center">
    <span
      fxFlex
      class="due-date"
      [ngClass]="{
        overdue: isOverdue$ | async,
        'almost-due': ((daysUntilDue$ | async) ?? 0) <= warningDaysUntilDue
      }"
    >
      {{ dueDate | moment | amDateFormat : dateFormat }}
    </span>
  </div>
</ng-container>
