import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'lu-list-heading',
    templateUrl: './list-heading.component.html',
    styleUrls: ['./list-heading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListHeadingComponent {}
