<lu-page-simple marginBottom="16">
  <lu-page-header
    label="Marketplace Subscriptions"
    pageInfo="The library of subscribed Marketplace bundles"
    [pageTour]="tourId"
  />

  <lu-page-toolbar>
    <a *luIsAdmin href="/marketplace/explore" mat-button
      >Explore the Marketplace</a
    >
  </lu-page-toolbar>

  <ng-container
    *ngIf="
      bloc.bloc.filteredSubscriptions$ | async as subscriptions;
      else loadingSubscriptions
    "
  >
    <div class="flex flex-col">
      <lu-subscription-list-card
        class="grow"
        *ngFor="let subscription of subscriptions; trackBy: trackBySubscription"
        [subscription]="subscription"
      />
    </div>
  </ng-container>

  <ng-template #loadingSubscriptions>
    <lu-card-layout>
      <div class="flex flex-col gap-2">
        <lu-card-placeholder />
        <lu-card-placeholder />
        <lu-card-placeholder />
      </div>
    </lu-card-layout>
  </ng-template>
</lu-page-simple>
