import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IFolder,
  type IPathway,
  type IUser,
  type IVendor,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import {
  isSameRef,
  type DocumentReference,
  type INamedDocument,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { OrganisationService } from '../../../services/organisation.service';

export type PathwayFormData = Pick<IPathway, 'name' | 'author' | 'folderRef'>;

export interface IPathwayAddDialogData {
  author: DocumentReference<IUser | IVendor>;
  folderRef?: DocumentReference<IFolder>;
  name?: string;
}

@Component({
    selector: 'lu-pathway-add-dialog',
    templateUrl: './pathway-add-dialog.component.html',
    styleUrls: ['./pathway-add-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PathwayAddDialogComponent {
  form: TypedFormGroup<PathwayFormData> = new TypedFormGroup<PathwayFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    author: new TypedFormControl<DocumentReference<IUser | IVendor>>(
      undefined,
      Validators.required
    ),
    folderRef: new TypedFormControl<DocumentReference<IFolder>>(),
  });
  isDisabled$ = isDisabled$(this.form);
  authors$: Observable<INamedDocument<IUser | IVendor>[]>;
  trackByAuthor = TrackByFunctions.ref<INamedDocument<IUser | IVendor>>();

  constructor(
    private _dialogRef: MatDialogRef<
      PathwayAddDialogComponent,
      PathwayFormData | undefined
    >,
    private _organisation: OrganisationService,
    @Inject(MAT_DIALOG_DATA) data: IPathwayAddDialogData
  ) {
    this.form.patchValue(data);
    this.authors$ = this._organisation.enabledUsers$;
  }

  compareWithFn(refA?: DocumentReference, refB?: DocumentReference): boolean {
    return isSameRef(refA, refB);
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
