import { createAction, props, ActionCreator, Action } from '@ngrx/store';

export enum CoreActionType {
  Cancel = '[Core] Cancel Action / NOOP',
}

export const cancelAction = createAction(CoreActionType.Cancel);

export interface ISelectItem {
  id: string;
}

export interface ISetError {
  error: string;
}

export function getSelectAction<T extends string>(
  actionName: T
): ActionCreator<T, (props: ISelectItem) => ISelectItem & Action<T>> {
  return createAction(actionName, props<ISelectItem>());
}

export function getErrorAction<T extends string>(
  actionName: T
): ActionCreator<T, (props: ISetError) => ISetError & Action<T>> {
  return createAction(actionName, props<ISetError>());
}

export function getResetAction<T extends string>(
  actionName: T
): ActionCreator<T, () => Action<T>> {
  return createAction(actionName);
}
