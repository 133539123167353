import {
  findAssociationBySkill,
  ISkill,
  type IPathway,
  type IPathwayAssociation,
  type ISkillAssociation,
} from '@principle-theorem/level-up-core';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AssociatedPathwayAddFilter {
  filteredItems$: Observable<WithRef<IPathway>[]>;

  constructor(
    public pathways$: Observable<WithRef<IPathway>[]>,
    public associatedPathways$: Observable<IPathwayAssociation[]>
  ) {
    this.filteredItems$ = isAssociatedFilter$(
      this.pathways$,
      this.associatedPathways$,
      (pathways, associatedPathways) =>
        associatedPathways.some((ref) => isSameRef(ref, pathways))
    );
  }
}

export class AssociatedSkillAddFilter {
  filteredItems$: Observable<WithRef<ISkill>[]>;

  constructor(
    public skills$: Observable<WithRef<ISkill>[]>,
    public associatedSkills$: Observable<ISkillAssociation[]>
  ) {
    this.filteredItems$ = isAssociatedFilter$(
      this.skills$,
      this.associatedSkills$,
      (skill, associatedSkills) =>
        findAssociationBySkill(skill, associatedSkills) ? true : false
    );
  }
}

function isAssociatedFilter$<Model, AssociatedItem>(
  models$: Observable<Model[]>,
  associatedItems$: Observable<AssociatedItem[]>,
  isAssociated: (model: Model, associatedItems: AssociatedItem[]) => boolean
): Observable<Model[]> {
  return combineLatest([models$, associatedItems$]).pipe(
    map(([models, associatedItems]) =>
      models.filter((model) => !isAssociated(model, associatedItems))
    )
  );
}
