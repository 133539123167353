import { Overlay, type ScrollStrategy } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Provider,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { BlockNodes, NodeGroup } from '@principle-theorem/editor';
import { CONNECTED_DIALOG_SCROLL_STRATEGY } from '@principle-theorem/ng-core';
import {
  EditorNode,
  EditorNodeComponent,
  IRenderHTMLArguments,
  NodeAttribute,
  type IDomParsing,
  type IDomSerialising,
  type IHasUid,
} from '@principle-theorem/ng-prosemirror';
import {
  BasicDialogService,
  DialogPresets,
  FileManagerService,
} from '@principle-theorem/ng-shared';
import { shareReplayCold, snapshot } from '@principle-theorem/shared';
import { CommandProps, RawCommands } from '@tiptap/core';
import { type DOMOutputSpec, type ParseRule } from '@tiptap/pm/model';
import { isBoolean } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import {
  DragResizeComponent,
  IResizeEvent,
} from '../../../drag-resize/drag-resize.component';
import {
  IImageNodePreviewDialogData,
  ImageNodePreviewDialogComponent,
} from '../image-node-preview-dialog/image-node-preview-dialog.component';

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Commands<ReturnType> {
    image: {
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
      }) => ReturnType;
    };
  }
}

export interface IEditorImageAttributes extends IHasUid {
  src: string;
  alt: string;
  title: string;
  url: string;
  width?: string;
  height?: string;
  fitToWidth?: boolean;
  dataAlign?: 'left' | 'center' | 'right';
  dataFloat?: 'left' | 'right';
}

export function CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER: Provider = {
  provide: CONNECTED_DIALOG_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY,
};

@EditorNode({
  name: BlockNodes.Image,
  group: NodeGroup.Block,
  inline: false,
  draggable: true,
  defining: false,
  selectable: true,
})
@Component({
    selector: 'pt-image-node',
    templateUrl: './image-node.component.html',
    styleUrls: ['./image-node.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER, BasicDialogService],
    standalone: false
})
export class ImageNodeComponent
  extends EditorNodeComponent<IEditorImageAttributes>
  implements
    IHasUid,
    IDomParsing,
    IDomSerialising,
    IEditorImageAttributes,
    OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  private _src$ = new ReplaySubject<string>(1);
  loaded = false;
  loading$ = new BehaviorSubject<boolean>(true);
  fitToWidth$ = new BehaviorSubject<boolean | undefined>(undefined);
  width$ = new BehaviorSubject<string | undefined>(undefined);
  height$ = new BehaviorSubject<string | undefined>(undefined);
  dataAlign$ = new BehaviorSubject<IEditorImageAttributes['dataAlign']>(
    undefined
  );
  dataFloat$ = new BehaviorSubject<IEditorImageAttributes['dataFloat']>(
    undefined
  );
  imageUrl$: Observable<string>;
  canResize$: Observable<boolean>;
  renderWidth$: Observable<string | undefined>;
  renderHeight$: Observable<string | undefined>;
  storagePath = '';
  @ViewChild('resize', { read: DragResizeComponent, static: false })
  resize?: DragResizeComponent;
  @NodeAttribute() @Input() alt = '';
  @NodeAttribute() @Input() title = '';
  @NodeAttribute() @Input() url: string = '';
  @NodeAttribute() @Input() uid: string = uuid();

  constructor(
    elementRef: ElementRef,
    private _fileManager: FileManagerService,
    private _dialog: BasicDialogService,
    private _auth: Auth
  ) {
    super(elementRef);

    this.canResize$ = this.fitToWidth$.pipe(
      map((fitToWidth) => !fitToWidth),
      distinctUntilChanged()
    );

    this.renderWidth$ = combineLatest([
      this.width$.pipe(map((val) => val ?? '400px')),
      this.fitToWidth$,
    ]).pipe(map(([width, fitToWidth]) => (fitToWidth ? 'auto' : width)));

    this.renderHeight$ = combineLatest([this.height$, this.fitToWidth$]).pipe(
      map(([height, fitToWidth]) => (fitToWidth ? 'auto' : height))
    );

    this.imageUrl$ = this._src$.pipe(
      distinctUntilChanged(),
      switchMap((src) => this._getImageUrl(src, this.url)),
      shareReplayCold()
    );

    this.imageUrl$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((url) => void this._update({ url }));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @NodeAttribute()
  @Input()
  set src(src: string) {
    if (src) {
      this.storagePath = src;
      this._src$.next(src);
    }
  }

  @NodeAttribute()
  @Input()
  set fitToWidth(fitToWidth: boolean | undefined) {
    this.fitToWidth$.next(fitToWidth);
  }

  @NodeAttribute()
  @Input()
  set width(width: string) {
    if (width) {
      this.width$.next(width);
    }
  }

  @NodeAttribute()
  @Input()
  set height(height: string) {
    if (height) {
      this.height$.next(height);
    }
  }

  @NodeAttribute()
  @Input()
  set dataAlign(dataAlign: IEditorImageAttributes['dataAlign']) {
    this.dataAlign$.next(dataAlign);
  }

  @NodeAttribute()
  @Input()
  set dataFloat(dataFloat: IEditorImageAttributes['dataFloat']) {
    this.dataFloat$.next(dataFloat);
  }

  updateSize($event: IResizeEvent): void {
    this.width = $event.width;
    this.height = $event.height;
  }

  async resizeEnd(): Promise<void> {
    await this._update();
  }

  override parseHTML(): ParseRule[] {
    return [
      {
        tag: 'img[src]',
        getAttrs: (dom) => {
          if (!(dom instanceof HTMLElement)) {
            return false;
          }

          const width = dom.style.width
            ? dom.style.width
            : dom.getAttribute('width');
          const height = dom.style.height
            ? dom.style.height
            : dom.getAttribute('height');

          return {
            src: dom.getAttribute('storage-path'),
            title: dom.getAttribute('title'),
            url: dom.getAttribute('src'),
            alt: dom.getAttribute('alt'),
            fitToWidth: dom.getAttribute('fit-to-width'),
            dataAlign: dom.getAttribute('data-align'),
            dataFloat: dom.getAttribute('data-float'),
            width,
            height,
          };
        },
      },
    ];
  }

  renderHTML(data: IRenderHTMLArguments): DOMOutputSpec {
    return [
      'img',
      {
        ...data.node.attrs,
        src: String(data.node.attrs.url),
        'storage-path': String(data.node.attrs.src),
        'fit-to-width': isBoolean(data.node.attrs.fitToWidth)
          ? String(data.node.attrs.fitToWidth)
          : undefined,
      },
    ];
  }

  addCommands(): Partial<RawCommands> {
    return {
      setImage: (options) => (props: CommandProps) => {
        return props.commands.insertContent({
          type: BlockNodes.Image,
          attrs: options,
        });
      },
    };
  }

  getHeight(fitToWidth?: boolean): string | undefined {
    return !fitToWidth ? this.height : undefined;
  }

  getWidth(fitToWidth?: boolean): string | undefined {
    return !fitToWidth ? this.width : undefined;
  }

  async openImage(): Promise<void> {
    const url = await snapshot(this.imageUrl$);
    const editable = await snapshot(this.editable$);
    if (editable) {
      return;
    }
    this._dialog.open<
      ImageNodePreviewDialogComponent,
      IImageNodePreviewDialogData
    >(
      ImageNodePreviewDialogComponent,
      DialogPresets.flex({
        data: { url },
      })
    );
  }

  private async _update(data?: Partial<IEditorImageAttributes>): Promise<void> {
    this.update.emit({
      src: await snapshot(this._src$),
      url: await snapshot(this.imageUrl$),
      alt: this.alt,
      title: this.title,
      width: await snapshot(this.width$),
      height: await snapshot(this.height$),
      uid: this.uid,
      fitToWidth: await snapshot(this.fitToWidth$),
      dataAlign: await snapshot(this.dataAlign$),
      dataFloat: await snapshot(this.dataFloat$),
      ...data,
    });
  }

  private async _getImageUrl(
    src: string,
    fallbackUrl: string
  ): Promise<string> {
    if (!this._auth.currentUser) {
      return fallbackUrl;
    }
    try {
      const newUrl = await this._fileManager.getImageUrlFromSrc(src);
      return newUrl ?? fallbackUrl;
    } catch (e) {
      return fallbackUrl;
    }
  }
}
