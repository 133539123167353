import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  type ISkill,
  type ITag,
  type IUser,
  type IVendor,
  Skill,
  IFolder,
} from '@principle-theorem/level-up-core';
import {
  canSave$,
  formControlChanges$,
  SidebarManagerService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  doc$,
  filterNil,
  filterUndefined,
  type IAttachment,
  type INamedDocument,
  isSameRef,
  shareReplayCold,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { omit, uniqWith } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';
import { SkillAttachmentsService } from '../../../services/skill-attachments.service';
import { ContentEditorComponent } from '../../content-editor/content-editor.component';
import {
  type INamedDocsToTags,
  NamedDocsToTags,
} from '../../tags/named-docs-to-tags';
import { type SkillFormData, SkillFormGroup } from './skill-form-group';

@Component({
    selector: 'lu-skill-edit-form',
    templateUrl: './skill-edit-form.component.html',
    styleUrls: ['./skill-edit-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillEditFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _tagDocs$ = new ReplaySubject<DocumentReference<ITag>[]>(1);
  private _users$ = new ReplaySubject<INamedDocument<IUser>[]>(1);
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  trackByAuthor = TrackByFunctions.ref<INamedDocument<IUser | IVendor>>();
  disabled$ = new BehaviorSubject<boolean>(false);
  skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  attachments$: Observable<WithRef<IAttachment>[]>;
  skillForm = new SkillFormGroup();
  namedDocsToTags: INamedDocsToTags;
  author$: Observable<INamedDocument<IUser | IVendor>>;
  authors$: Observable<INamedDocument<IUser | IVendor>[]>;
  @Input() reviewers: WithRef<IUser>[] = [];
  @Output() skillChange = new EventEmitter<Partial<ISkill>>();
  @Output() nameChange = new EventEmitter<string>();
  @ViewChild(ContentEditorComponent)
  editor: ContentEditorComponent;
  canSave$: Observable<boolean>;
  canEnterReview$: Observable<boolean>;

  constructor(
    public skillAttachments: SkillAttachmentsService,
    public sidebar: SidebarManagerService,
    public org: OrganisationService,
    cachedLists: CachedListsService
  ) {
    this.namedDocsToTags = new NamedDocsToTags(
      this._tagDocs$,
      cachedLists.tags$
    );
    this.skillForm.controls.tags.valueChanges
      .pipe(filterNil(), takeUntil(this._onDestroy$))
      .subscribe((tags: DocumentReference<ITag>[]) =>
        this._tagDocs$.next(tags)
      );

    combineLatest([this.disabled$, this.skill$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([disabled, skill]) => {
        if (skill.readOnly || disabled) {
          return this.skillForm.disable();
        }

        this.skillForm.enable();
      });

    this.skill$.pipe(take(1), takeUntil(this._onDestroy$)).subscribe((skill) =>
      this.skillForm.patchValue(
        { content: skill.content },
        {
          emitEvent: false,
        }
      )
    );

    this.skill$.pipe(takeUntil(this._onDestroy$)).subscribe((skill) => {
      this.skillForm.patchValue(omit(skill, 'content'), {
        emitEvent: false,
      });
      this._tagDocs$.next(skill.tags);
    });

    this.canSave$ = combineLatest([
      this.skill$,
      this.disabled$,
      canSave$(this.skillForm),
    ]).pipe(
      map(
        ([skill, disabled, canSave]) => !skill.readOnly && !disabled && canSave
      ),
      shareReplayCold()
    );

    this.canEnterReview$ = formControlChanges$(this.skillForm).pipe(
      map(() => this.skillForm.hasReviewers())
    );

    this.attachments$ = this.skill$.pipe(
      switchMap((skill) => Skill.attachments$(skill))
    );

    this.author$ = this.skill$.pipe(switchMap((skill) => doc$(skill.author)));

    this.authors$ = combineLatest([this.author$, this._users$]).pipe(
      map(([author, users]) => uniqWith([author, ...users], isSameRef))
    );

    this.skillForm.controls.name.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((name) => this.nameChange.emit(name));
  }

  @Input()
  set users(users: WithRef<IUser>[]) {
    if (users) {
      this._users$.next(users.map(toNamedDocument));
    }
  }

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    const skill = this.getFormData();
    this.skillForm.markAsPristine();
    if (skill) {
      this.skillChange.next(skill);
    }
  }

  selectFolder(folderRef: DocumentReference<IFolder>): void {
    this.skillForm.controls.folderRef.markAsDirty();
    this.skillForm.controls.folderRef.setValue(folderRef);
  }

  getFormData(): SkillFormData | undefined {
    if (this.skillForm.invalid || !this.editor.editor) {
      return;
    }
    return this.skillForm.getRawValue();
  }

  compareWithFn(aRef?: DocumentReference, bRef?: DocumentReference): boolean {
    return aRef && bRef && aRef.path === bRef.path ? true : false;
  }

  updateTags(tags: WithRef<ITag>[]): void {
    const refs: DocumentReference<ITag>[] = tags.map(
      (tag: WithRef<ITag>) => tag.ref
    );
    this.skillForm.controls.tags.setValue(refs);
  }

  async handleContentError(): Promise<void> {
    const skill = await snapshot(this.skill$);
    const user = await snapshot(this.org.user$.pipe(filterUndefined()));
    const organisation = await snapshot(
      this.org.organisation$.pipe(filterUndefined())
    );
    throw new Error(
      JSON.stringify({
        error: `Couldn't render content for skill`,
        skill: skill.name,
        path: skill.ref.path,
        workspace: organisation.slug,
        user: user.name,
      })
    );
  }
}
