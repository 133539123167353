import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IBundleSkill,
  type ISkill,
  type IVendorBundle,
  VendorBundle,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface IVendorSkillAssociatedData {
  associatedSkills: DocumentReference<IBundleSkill>[];
  bundle: WithRef<IVendorBundle>;
}

@Component({
    selector: 'lu-vendor-skill-associate-dialog',
    templateUrl: './vendor-skill-associate-dialog.component.html',
    styleUrls: ['./vendor-skill-associate-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VendorSkillAssociateDialogComponent {
  trackBySkill = TrackByFunctions.ref<WithRef<IBundleSkill>>();
  associatedSkills$: BehaviorSubject<DocumentReference<IBundleSkill>[]> =
    new BehaviorSubject<DocumentReference<IBundleSkill>[]>([]);
  skills$: Observable<WithRef<IBundleSkill>[]>;

  inputFilter: TypedFormControl<string | WithRef<IBundleSkill>> =
    new TypedFormControl();
  filteredSkills$: Observable<WithRef<IBundleSkill>[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IVendorSkillAssociatedData,
    private _dialogRef: MatDialogRef<
      VendorSkillAssociateDialogComponent,
      WithRef<IBundleSkill> | undefined
    >
  ) {
    this.associatedSkills$.next(data.associatedSkills);
    this.skills$ = VendorBundle.skills$(data.bundle).pipe(
      map((skills: WithRef<IBundleSkill>[]) => {
        return skills.filter(
          (skill: WithRef<IBundleSkill>) => !this.isAssociated(skill)
        );
      })
    );
    this.filteredSkills$ = combineLatest([
      this.inputFilter.valueChanges.pipe(startWith('')),
      this.skills$,
    ]).pipe(map(([value, skills]) => this._filter(value, skills)));
  }

  displayFn(skill?: ISkill): string {
    return skill ? `${skill.name}` : '';
  }

  isAssociated(skill: WithRef<IBundleSkill>): boolean {
    return this.associatedSkills$.value.find(
      (currentSkillRef: DocumentReference<IBundleSkill>) => {
        return currentSkillRef.path === skill.ref.path;
      }
    )
      ? true
      : false;
  }

  add(skill?: WithRef<IBundleSkill>): void {
    if (!skill) {
      return;
    }
    this._dialogRef.close(skill);
  }

  private _filter(
    value: string | WithRef<IBundleSkill>,
    skills: WithRef<IBundleSkill>[]
  ): WithRef<IBundleSkill>[] {
    if (typeof value !== 'string') {
      return [];
    }

    return skills.filter((skill: WithRef<IBundleSkill>) => {
      return skill.name.toLowerCase().includes(value.toLowerCase());
    });
  }
}
