import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { IsAdminDirective } from './is-admin.directive';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgAuthModule } from '@principle-theorem/ng-auth';

@NgModule({
  imports: [CommonModule, NgFirebaseModule, NgSharedModule, NgAuthModule],
  declarations: [IsAdminDirective],
  exports: [IsAdminDirective],
})
export class AuthModule {}
