import { IFolder } from '@principle-theorem/level-up-core';
import {
  DocumentReference,
  INamedDocument,
  WithRef,
  isSameRef,
} from '@principle-theorem/shared';

export function buildFolderOptionsList(
  folders: WithRef<IFolder>[],
  prefix: string = '',
  parentFolderRef?: DocumentReference<IFolder>
): INamedDocument<IFolder>[] {
  return folders
    .filter((folder) =>
      parentFolderRef
        ? isSameRef(folder.parentFolderRef, parentFolderRef)
        : !folder.parentFolderRef
    )
    .map((folder) => [
      {
        ...folder,
        name: `${prefix} ${folder.name}`,
      },
      ...buildFolderOptionsList(
        folders.filter((subFolder) =>
          isSameRef(subFolder.parentFolderRef, folder)
        ),
        `${prefix}-`,
        folder.ref
      ),
    ])
    .flat();
}
