import { Pipe, type PipeTransform } from '@angular/core';
import {
  Skill,
  type ISkill,
  IPathway,
  isSkill,
  Pathway,
} from '@principle-theorem/level-up-core';
import { map } from 'rxjs/operators';
import { WithRef, filterUndefined } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { OrganisationService } from '../services/organisation.service';

@Pipe({
  name: 'canEdit$',
})
export class CanEditPipe implements PipeTransform {
  constructor(private _organisation: OrganisationService) {}

  transform(item: WithRef<ISkill> | WithRef<IPathway>): Observable<boolean> {
    return this._organisation.user$.pipe(
      filterUndefined(),
      map((user) =>
        isSkill(item) ? Skill.canEdit(item, user) : Pathway.canEdit(item, user)
      )
    );
  }
}
