import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type ISkill } from '../../models/skill/skill';
import { type SkillLevel } from '../../models/skill/skill-level';
import { type IUser } from '../../models/user/user';

export interface ILevelUpResources {
  grower: INamedDocument<IUser>;
  mentor?: INamedDocument<IUser>;
  interactor?: INamedDocument<IUser>;
  skill: INamedDocument<ISkill>;
  skillProgress: {
    level: SkillLevel;
    goal: SkillLevel;
  };
}

export function isLevelUpResource(data: unknown): data is ILevelUpResources {
  return (
    isObject(data) &&
    isINamedDocument(data.grower) &&
    isINamedDocument(data.mentor) &&
    isINamedDocument(data.skill) &&
    'skillProgress' in data &&
    isObject(data.skillProgress)
  );
}
