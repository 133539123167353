<ng-container *ngIf="(disabled$ | async) === false">
  <button
    class="group/item"
    mat-icon-button
    matTooltip="Reset Progress"
    (click)="resetProgress.emit()"
  >
    <mat-icon
      color="primary"
      class="group/completed font-bold group-hover/item:!hidden"
      >check_circle_outline</mat-icon
    >
    <mat-icon class="group/reset !hidden group-hover/item:!block"
      >restart_alt</mat-icon
    >
  </button>
</ng-container>
