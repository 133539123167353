<div
  class="edit-enabled flex justify-center"
  *ngIf="canEdit$ | async; else readOnly"
>
  <ng-container *ngIf="dueDate$ | async as dueDate; else dateSelector">
    <button
      *ngIf="placement === 'before'"
      class="remove-button before"
      mat-icon-button
      color="warn"
      matTooltip="Reset due date"
      matTooltipShowDelay="300"
      (click)="dateChange.emit()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>

    <span
      class="due-date cursor-pointer"
      matTooltip="Change due date"
      matTooltipShowDelay="300"
      (click)="openDueDateDialog()"
      >{{ dueDate$ | async }} days</span
    >

    <button
      *ngIf="placement === 'after'"
      class="remove-button after"
      mat-icon-button
      color="warn"
      matTooltip="Reset due date"
      matTooltipShowDelay="300"
      (click)="dateChange.emit()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
  </ng-container>

  <ng-template #dateSelector>
    <button
      mat-icon-button
      matTooltip="Set due date"
      matTooltipShowDelay="300"
      (click)="openDueDateDialog()"
    >
      <mat-icon>alarm_add</mat-icon>
    </button>
  </ng-template>
</div>

<ng-template #readOnly>
  <span>{{ dueDate$ | async }} days</span>
</ng-template>
