<div class="box-border flex h-full min-h-full w-full flex-col">
  <div
    class="flex flex-1 flex-col gap-2"
    *ngFor="let level of skillGroups$ | async; trackBy: trackByLevel"
    ptIntercomTarget="explore-skill.user-skill-level-list"
  >
    <div class="flex items-center gap-2">
      <lu-level-indicator
        [diameter]="32"
        [level]="level.level"
       />
      <h3 class="subtitle-1">
        {{ level.label | titlecase }}
      </h3>
    </div>
    <div class="users-container flex flex-1 flex-wrap gap-1">
      <div *ngFor="let user of level.users; trackBy: trackByUser">
        <pt-user-icon
          [diameter]="40"
          [matTooltip]="user.name"
          [name]="user.name"
         />
      </div>
    </div>
  </div>
</div>
