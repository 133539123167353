<mat-toolbar color="primary">Team Progress</mat-toolbar>

<mat-dialog-content>
  <lu-users-skill-summary
    fxFlex.gt-sm
    [skill]="data.skill"
    [users]="data.users"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
