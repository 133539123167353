import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISkill, type IUser } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { UserSkillProgress } from '../../models/user-skill-progress';
import { OrganisationService } from '../../services/organisation.service';

@Component({
    selector: 'lu-user-skill-level-indicator',
    templateUrl: './user-skill-level-indicator.component.html',
    styleUrls: ['./user-skill-level-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserSkillLevelIndicatorComponent {
  private _user$ = new ReplaySubject<WithRef<IUser>>(1);
  private _skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  isDisabled$: Observable<boolean>;
  progress: UserSkillProgress;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this._skill$.next(skill);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.progress = new UserSkillProgress(
      this._organisation.userGroups$,
      this._user$,
      this._skill$
    );
  }
}
