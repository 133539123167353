import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-card-layout',
    templateUrl: './card-layout.component.html',
    styleUrls: ['./card-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardLayoutComponent {}
