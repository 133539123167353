import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export interface IVideoEmbedOutput {
  href: string;
}

@Component({
    selector: 'pt-video-embed-dialog',
    templateUrl: './video-embed-dialog.component.html',
    styleUrls: ['./video-embed-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VideoEmbedDialogComponent {
  form = new TypedFormGroup<IVideoEmbedOutput>({
    href: new TypedFormControl<string>(),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      VideoEmbedDialogComponent,
      IVideoEmbedOutput
    >
  ) {}

  save(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
