<mat-toolbar color="primary">Invite Users</mat-toolbar>

<mat-dialog-content>
  <form #form [formGroup]="inviteForm" fxLayout="column" fxLayoutGap="16px">
    <div
      class="add-email"
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="8px"
    >
      <mat-form-field fxFlex subscriptSizing="dynamic" floatLabel="always">
        <mat-label>Emails</mat-label>
        <input
          matInput
          formControlName="emails"
          placeholder="Invite by email (comma separated or multiple lines accepted)"
          (keydown.enter)="addEmails()"
          ptIntercomTarget="invite-user-form.input-emails"
        />
      </mat-form-field>

      <button
        type="button"
        color="primary"
        mat-stroked-button
        (click)="addEmails()"
        ptIntercomTarget="invite-user-form.add-emails-button"
      >
        Add
      </button>
    </div>

    <div
      formArrayName="users"
      fxLayout="column"
      fxLayoutGap="16px"
      *ngIf="usersArray.controls.length"
    >
      <lu-list-heading>
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          class="mat-caption list-headers"
        >
          <span class="admin-column"
            >Is Admin
            <mat-icon
              matTooltip="Admin users can manage other users, groups, pathways, templates and tags."
              >help</mat-icon
            ></span
          >
        </div>
      </lu-list-heading>

      <mat-list>
        <mat-list-item
          *ngFor="
            let control of usersArray.controls;
            trackBy: trackByEmail;
            index as index
          "
        >
          <div
            class="row-wrapper"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between start"
            fxLayoutGap="16px"
            [formGroupName]="index"
          >
            <mat-form-field fxFlex="240px" subscriptSizing="dynamic">
              <mat-label>Name</mat-label>
              <input type="text" matInput formControlName="name" />
            </mat-form-field>

            <mat-form-field fxFlex="320px" subscriptSizing="dynamic">
              <mat-label>Email</mat-label>
              <input
                type="email"
                matInput
                formControlName="email"
                [disabled]="true"
              />
            </mat-form-field>

            <mat-checkbox color="primary" formControlName="isAdmin" />

            <div
              fxFlex="40px"
              fxLayout="row"
              fxLayoutAlign="end"
              class="quantity-buttons"
            >
              <button
                mat-icon-button
                type="button"
                color="warn"
                matTooltip="Remove user"
                (click)="removeEmail(index)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none" fxFlex>
    <pt-loader-button
      type="stroked"
      color="primary"
      ptIntercomTarget="invite-user-form.submit"
      [loading]="loading$ | async"
      [disabled]="isDisabled$ | async"
      (click)="submit()"
    >
      Invite
    </pt-loader-button>
  </pt-buttons-container>
</mat-dialog-actions>
