import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type IUserGroup } from '@principle-theorem/level-up-core';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchInput$,
} from '@principle-theorem/ng-shared';
import {
  isSameRef,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';

@Component({
  selector: 'lu-group-association-selector',
  templateUrl: './group-association-selector.component.html',
  styleUrls: ['./group-association-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupAssociationSelectorComponent {
  trackByGroup = TrackByFunctions.ref<WithRef<IUserGroup>>();
  @Input() placeholder = 'Add a Team';
  @Output() add: EventEmitter<WithRef<IUserGroup>> = new EventEmitter<
    WithRef<IUserGroup>
  >();
  groupFilter: TypedFormControl<string> = new TypedFormControl();
  availableGroups$: Observable<WithRef<IUserGroup>[]>;
  groupSearch: InputSearchFilter<WithRef<IUserGroup>>;
  existingGroups$ = new ReplaySubject<DocumentReference<IUserGroup>[]>(1);

  @Input()
  set existingGroups(existingGroups: DocumentReference<IUserGroup>[]) {
    if (existingGroups) {
      this.existingGroups$.next(existingGroups);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.availableGroups$ = combineLatest([
      this._organisation.userGroups$,
      this.existingGroups$,
    ]).pipe(
      map(([groups, existingGroups]) =>
        groups.filter((group) =>
          existingGroups.every(
            (existingGroup) => !isSameRef(existingGroup, group)
          )
        )
      )
    );

    this.groupSearch = new InputSearchFilter<WithRef<IUserGroup>>(
      this.availableGroups$,
      toSearchInput$(this.groupFilter),
      ['name']
    );
  }

  groupSelected(userGroup?: WithRef<IUserGroup>): void {
    if (!userGroup) {
      return;
    }
    this.groupFilter.reset();
    this.add.emit(userGroup);
  }

  displayFn(group?: IUserGroup): string {
    return group ? group.name : '';
  }
}
