import { initVersionedSchema, MixedSchema } from '@principle-theorem/editor';
import {
  ISoftDelete,
  type AtLeast,
  type DocumentReference,
} from '@principle-theorem/shared';
import { type IBundleTemplate } from '../marketplace/bundle';
import { type IVendor } from '../marketplace/vendor';
import { type IUser } from '../user/user';

export enum PageTemplateStatus {
  Draft = 'draft',
  Released = 'released',
}

export interface IPageTemplate extends ISoftDelete {
  name: string;
  content: MixedSchema;
  author: DocumentReference<IUser | IVendor>;
  status: PageTemplateStatus;
  readOnly: boolean;
  vendorTemplateRef?: DocumentReference<IBundleTemplate>;
}

export class PageTemplate {
  static init(overrides: AtLeast<IPageTemplate, 'author'>): IPageTemplate {
    return {
      name: '',
      content: initVersionedSchema(),
      status: PageTemplateStatus.Draft,
      readOnly: false,
      deleted: false,
      ...overrides,
    };
  }
}
