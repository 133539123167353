import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'lu-stat',
    template: '<ng-content></ng-content>',
    styleUrls: ['./stat.component.scss'],
    standalone: false
})
export class StatComponent {
  @Input()
  @HostBinding('class')
  colour = 'accent';
}
