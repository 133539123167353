import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  type ISkill,
  type IUser,
  Skill,
} from '@principle-theorem/level-up-core';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { ISODateType, saveDoc, type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lu-author-skill-list',
  templateUrl: './author-skill-list.component.html',
  styleUrls: ['./author-skill-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorSkillListComponent {
  profileImage = inject(ProfileImageService);
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  trackByReviewer = TrackByFunctions.uniqueId<DocumentReference<IUser>>();
  skills$: ReplaySubject<WithRef<ISkill>[]> = new ReplaySubject(1);
  user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject(1);

  @Input() set skills(skills: WithRef<ISkill>[]) {
    if (skills) {
      this.skills$.next(skills);
    }
  }

  @Input() set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  canReview$(skill: WithRef<ISkill>): Observable<boolean> {
    return this.user$.pipe(map((user) => Skill.canReview(skill, user)));
  }

  async updateDueDate(
    skill: WithRef<ISkill>,
    dueDate?: ISODateType
  ): Promise<void> {
    await saveDoc({
      ...skill,
      dueDate,
    });
  }
}
