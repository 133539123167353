import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type DocumentReference } from '@principle-theorem/shared';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { findTrainerUsersForSkill$ } from '../../../models/user';
import { OrganisationService } from '../../../services/organisation.service';

interface IMentors {
  mentors: DocumentReference<IUser>[];
}

@Component({
    selector: 'lu-skill-mentor-dialog',
    templateUrl: './skill-mentor-dialog.component.html',
    styleUrls: ['./skill-mentor-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillMentorDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  form: TypedFormGroup<IMentors> = new TypedFormGroup<IMentors>({
    mentors: new TypedFormControl<DocumentReference<IUser>[]>(
      [],
      Validators.required
    ),
  });
  users$: Observable<WithRef<IUser>[]>;

  constructor(
    private _dialogRef: MatDialogRef<
      SkillMentorDialogComponent,
      DocumentReference<IUser>[]
    >,
    private _org: OrganisationService,
    @Inject(MAT_DIALOG_DATA) private _skill: WithRef<ISkill>
  ) {
    this.users$ = this._org.enabledUsers$;

    this.users$
      .pipe(
        findTrainerUsersForSkill$(this._skill),
        map((mentors) =>
          mentors.length
            ? mentors.map((mentor) => mentor.ref)
            : [this._skill.author as DocumentReference<IUser>]
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((mentors) => {
        this.form.controls.mentors.setValue(mentors);
      });
  }

  canSave(): boolean {
    return this.form.valid;
  }

  compareWithFn(aRef?: DocumentReference, bRef?: DocumentReference): boolean {
    return aRef && bRef && isSameRef(aRef, bRef) ? true : false;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue().mentors);
  }
}
