<lu-page-simple *ngIf="user$ | async as user" marginBottom="32">
  <lu-page-header label="Profile" />
  <div
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutGap="30px"
    class="user-profile-container"
  >
    <pt-profile-image-manager
      [storagePath]="org.storagePath$ | async"
      [profile]="user"
    />
    <div fxLayout="column" fxLayoutGap="16px">
      <div>
        <div class="mat-caption">Name</div>
        <div>{{ user.name }}</div>
      </div>
      <div>
        <div class="mat-caption">Email</div>
        <div>{{ user.email }}</div>
      </div>
    </div>
  </div>

  <lu-notification-preferences />
</lu-page-simple>
