import { Component, Input } from '@angular/core';
import { type UserStatistics } from '../../../models/user-statistics';

@Component({
    selector: 'lu-mentor-stat',
    templateUrl: './mentor-stat.component.html',
    styleUrls: ['./mentor-stat.component.scss'],
    standalone: false
})
export class MentorStatComponent {
  @Input() stats: UserStatistics;
  @Input() colour = 'accent';
}
