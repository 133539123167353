import { Injectable } from '@angular/core';
import {
  IBundleSkill,
  IPathway,
  ISkill,
  IVendor,
  IVendorBundle,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { WithRef } from '@principle-theorem/shared';
import {
  ISkillEditDialogData,
  SkillEditDialogComponent,
} from '../pages/skills/skill-edit-dialog/skill-edit-dialog.component';
import {
  ISkillViewDialogData,
  SkillViewDialogComponent,
} from '../pages/skills/skill-view-dialog/skill-view-dialog.component';
import {
  IPathwayViewDialogData,
  PathwayViewDialogComponent,
} from '../pages/skills/pathway-view-dialog/pathway-view-dialog.component';
import {
  IVendorBundleSkillEditDialogData,
  VendorBundleSkillEditDialogComponent,
} from '../pages/marketplace/account/skills/vendor-bundle-skill-edit-dialog/vendor-bundle-skill-edit-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ContentDialogService {
  constructor(private _dialog: BasicDialogService) {}

  async viewSkill(skill: WithRef<ISkill>): Promise<void> {
    return this._dialog.mobileFullscreen<
      SkillViewDialogComponent,
      ISkillViewDialogData,
      void
    >(
      SkillViewDialogComponent,
      DialogPresets.almostFullscreen({
        data: { skill },
      })
    );
  }

  async editSkill(skill: WithRef<ISkill>): Promise<void> {
    return this._dialog.mobileFullscreen<
      SkillEditDialogComponent,
      ISkillEditDialogData,
      void
    >(
      SkillEditDialogComponent,
      DialogPresets.almostFullscreen({
        data: { skill },
      })
    );
  }

  editBundleSkill(
    skill: WithRef<IBundleSkill>,
    vendor: WithRef<IVendor>,
    bundle: WithRef<IVendorBundle>
  ): Promise<void> {
    return this._dialog.mobileFullscreen<
      VendorBundleSkillEditDialogComponent,
      IVendorBundleSkillEditDialogData,
      void
    >(
      VendorBundleSkillEditDialogComponent,
      DialogPresets.almostFullscreen({
        data: { skill, vendor, bundle },
      })
    );
  }

  async viewPathway(pathway: WithRef<IPathway>): Promise<void> {
    return this._dialog.mobileFullscreen<
      PathwayViewDialogComponent,
      IPathwayViewDialogData,
      void
    >(
      PathwayViewDialogComponent,
      DialogPresets.almostFullscreen({
        data: { pathway },
      })
    );
  }
}
