import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pt-page-margin',
    templateUrl: './page-margin.component.html',
    styleUrls: ['./page-margin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageMarginComponent {}
