<ng-container *ngIf="requiresTrainer$ | async; else markAsViewedButton">
  <ng-container *ngIf="hasTrainingRequest$ | async; else request">
    <div class="layout-padding">
      <button mat-flat-button color="warn" (click)="cancelTrainingRequest()">
        Cancel training request
      </button>
    </div>
  </ng-container>

  <ng-template #request>
    <div class="layout-padding">
      <button
        mat-flat-button
        color="primary"
        *ngIf="canRequestTrainer$ | async"
        (click)="requestTrainer()"
      >
        Mark as ready for training
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-template #markAsViewedButton>
  <div *ngIf="isUnviewed$ | async" class="layout-padding">
    <button
      mat-flat-button
      color="primary"
      (click)="markAsViewed()"
      ptIntercomTarget="explore-skill.mark-as-read-button"
    >
      Mark as viewed
    </button>
  </div>
</ng-template>
