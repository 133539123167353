export enum IntercomEvent {
  AddedSkill = 'Added Skill',
  AddedPathway = 'Added Pathway',
  AddedUser = 'Added User',
  AddedGroup = 'Added Group',
  AddedTag = 'Added Tag',
  AddedTemplate = 'Added Template',
  AddedFolder = 'Added Folder',
  ReviewedSkill = 'Reviewed Skill',
  ApprovedSkill = 'Approved Skill',
  SelfLevelledUp = 'Self Levelled Up',
  RequestedLevelUp = 'Requested Level Up',
  ApprovedLevelUp = 'Approved Level Up',
  SignedUp = 'Signed Up',
  ProgressedInSignUp = 'Progressed In Sign Up',
}
