import {
  initRawSchema,
  type RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  getDoc,
  HTMLToEmail,
  type INamedDocument,
} from '@principle-theorem/shared';
import {
  type IEmailNotificationContext,
  type INotification,
  type INotificationContextRenderer,
} from '@principle-theorem/notifications';
import { MentionResourceType, mentionToLink, toMention } from '../../mention';
import {
  type EmailNotificationProvider,
  type INotificationUserPair,
  NotificationType,
} from '../notification';
import { userIsOfRole } from '../notification-helpers';
import { type ISkillResources, isSkillResource } from '../resources/skill';
import { isUserDocRef } from '../../models/user/user';

export class SkillPublishedContextRenderer
  implements
    INotificationContextRenderer<RawSchema, NotificationType, ISkillResources>
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.SkillPublished);
  }

  isResource(data: object): data is ISkillResources {
    return isSkillResource(data);
  }

  render(
    data: ISkillResources,
    currentStaffer: INamedDocument
  ): RawSchema | undefined {
    const isAuthor: boolean = userIsOfRole(currentStaffer, data.author);
    const skillMention = toMention(data.skill, MentionResourceType.Skill);

    if (isAuthor) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`Your skill `),
          toMentionContent(skillMention),
          toTextContent(` has been published`),
        ]),
      ]);
    }
  }
}

export class SkillPublishedEmailContextRenderer
  implements EmailNotificationProvider
{
  canProvide(data: INotificationUserPair): boolean {
    return data.notification.type === String(NotificationType.SkillPublished);
  }

  async execute(
    data: INotificationUserPair
  ): Promise<IEmailNotificationContext | undefined> {
    const resources = data.notification.resources;
    if (!isSkillResource(resources)) {
      throw new Error('Cannot render email context');
    }

    const isAuthor: boolean = userIsOfRole(data.user, resources.author);
    const skillMention = toMention(resources.skill, MentionResourceType.Skill);
    const skillHref = mentionToLink(skillMention);
    const skillName = HTMLToEmail.bold(resources.skill.name);

    if (!resources.author || !isAuthor || !isUserDocRef(resources.author.ref)) {
      return;
    }

    const author = await getDoc(resources.author.ref);
    const message = `Congrats! Your skill ${skillName} has just been published!

    If you haven't yet, be sure to assign it to a group so they can get notified and start learning.`;

    return {
      user: {
        name: author.name,
        email: author.email,
      },
      subject: `Congrats! ${resources.skill.name} has just been published 🎉🎉🎉`,
      preheader: message,
      message,
      action: {
        label: 'View Skill',
        url: skillHref,
      },
      signOffMessage: 'Keep up the great work!',
    };
  }
}
