import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getSchemaText } from '@principle-theorem/editor';
import {
  Skill,
  type IRoutableSkill,
  type ISkill,
  type IUser,
  type ISkillReview,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  TrackByFunctions,
  TypedFormControl,
  type IComponentCanDeactivate,
} from '@principle-theorem/ng-shared';
import {
  HISTORY_DATE_TIME_FORMAT,
  filterUndefined,
  isSameRef,
  shareReplayCold,
  snapshot,
  type ArchivedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { readingTime } from 'reading-time-estimator';
import { ReplaySubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SkillEditFormComponent } from '../../../components/skills/skill-edit-form/skill-edit-form.component';
import { DeleteSkillService } from '../../../services/delete-skill.service';
import { LastViewedService } from '../../../services/last-viewed.service';
import { OrganisationService } from '../../../services/organisation.service';
import { SkillStatusUpdaterService } from '../../../services/skill-status-updater.service';

@Component({
    selector: 'lu-skill-edit',
    templateUrl: './skill-edit.component.html',
    styleUrls: ['./skill-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillEditComponent implements OnDestroy, IComponentCanDeactivate {
  private _routableSkill$ = new ReplaySubject<
    IRoutableSkill & IReffable<ISkill>
  >(1);
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  skill$: Observable<WithRef<ISkill>>;
  skillHistory$: Observable<WithRef<ArchivedDocument<ISkill>>[]>;
  users$: Observable<WithRef<IUser>[]>;
  reviewers$: Observable<WithRef<IUser>[]>;
  currentUser$: Observable<WithRef<IUser>>;
  historySelected$: Observable<boolean>;
  skillName$ = new ReplaySubject<string>(1);
  reviews$: Observable<WithRef<ISkillReview>[]>;
  showReviews$: Observable<boolean>;
  @Output() saved = new EventEmitter<void>();
  @Input() actionRedirectLink = '';

  @ViewChild(SkillEditFormComponent)
  form: SkillEditFormComponent;

  trackByForm = TrackByFunctions.ref<WithRef<ArchivedDocument<ISkill>>>();
  historySelectCtrl = new TypedFormControl<WithRef<ArchivedDocument<ISkill>>>(
    undefined
  );

  @Input()
  set skill(skill: IRoutableSkill & IReffable<ISkill>) {
    this._routableSkill$.next(skill);
    this.skillName$.next(skill.name);
  }

  constructor(
    private _organisation: OrganisationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _skillStatus: SkillStatusUpdaterService,
    private _deleteSkillService: DeleteSkillService,
    private _dialog: BasicDialogService,
    lastViewed: LastViewedService
  ) {
    this.currentUser$ = this._organisation.user$.pipe(filterUndefined());
    this.users$ = this._organisation.enabledUsers$;
    this.reviewers$ = combineLatest([
      this._organisation.enabledUsers$,
      this._organisation.user$.pipe(filterUndefined()),
    ]).pipe(
      map(([users, currentUser]) =>
        users.filter((user) =>
          currentUser.isAdmin
            ? currentUser.isAdmin
            : !isSameRef(user, currentUser)
        )
      )
    );
    this.skill$ = this._routableSkill$.pipe(
      tap((skill) => lastViewed.trackSkillVisit(skill)),
      switchMap((skill) => this._organisation.getSkill$(skill.ref)),
      filterUndefined()
    );

    this.historySelectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((historyItem) => this.form.skillForm.patchValue(historyItem));

    this.historySelected$ = combineLatest([
      this.skill$,
      this.historySelectCtrl.valueChanges.pipe(startWith(undefined)),
    ]).pipe(
      map(
        ([skill, historyItem]) =>
          !!historyItem && !isSameRef(skill, historyItem)
      ),
      shareReplayCold()
    );

    this.skillHistory$ = this.skill$.pipe(
      switchMap((skill) => Skill.archivedSkills$(skill))
    );

    this.reviews$ = this.skill$.pipe(
      switchMap((skill) => Skill.reviews$(skill))
    );

    this.showReviews$ = combineLatest([this.reviews$, this.skill$]).pipe(
      map(([reviews, skill]) => !!reviews.length && Skill.inReview(skill))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    if (this.form.skillForm.pristine) {
      return true;
    }
    return false;
  }

  async delete(skill: WithRef<ISkill>): Promise<void> {
    if (skill.readOnly) {
      return;
    }

    const confirmed = await this._dialog.confirm({
      prompt: 'Are you sure you want to delete this skill?',
      title: 'Delete Skill',
      submitLabel: 'Yes',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
      toolbarColor: 'primary',
    });

    if (!confirmed) {
      return;
    }

    await this._deleteSkillService.deleteSkill(skill);
    this._snackBar.open(`${skill.name} removed`);

    this.saved.emit();
    if (this.actionRedirectLink) {
      await this._router.navigate(['/create']);
    }
  }

  async resetToDraft(skill: WithRef<ISkill>): Promise<void> {
    await this._catchError(async () => {
      await this._skillStatus.setDraft(skill, this.form.getFormData());
      this._snackBar.open(`${skill.name} set to Draft status`);

      this.saved.emit();
      if (this.actionRedirectLink) {
        await this._router.navigate(['/create']);
      }
    });
  }

  async resetToReview(skill: WithRef<ISkill>): Promise<void> {
    await this._catchError(async () => {
      await this._skillStatus.readyForReview(skill, this.form.getFormData());
      this._snackBar.open(`${skill.name} reset to review`);
      this.form.skillForm.markAsPristine();

      this.saved.emit();
      if (this.actionRedirectLink) {
        await this._router.navigate(['/create']);
      }
    });
  }

  async readyForReview(skill: WithRef<ISkill>): Promise<void> {
    await this._catchError(async () => {
      await this._skillStatus.readyForReview(skill, this.form.getFormData());
      this._snackBar.open(`${skill.name} ready for review`);
      this.form.skillForm.markAsPristine();

      this.saved.emit();
      if (this.actionRedirectLink) {
        await this._router.navigate(['/create']);
      }
    });
  }

  async approve(skill: WithRef<ISkill>): Promise<void> {
    await this._catchError(async () => {
      await this._skillStatus.manageTrainersForSkill(skill);
    }, 'Trainers are required to publish a trainer verified skill');

    await this._catchError(async () => {
      await this._skillStatus.approve(skill, this.form.getFormData());
      this._snackBar.open(`${skill.name} has been published`);

      this.saved.emit();
      if (this.actionRedirectLink) {
        await this._router.navigate(['/create']);
      }
    });
  }

  async saveChanges(skill: WithRef<ISkill>): Promise<void> {
    const historySelected = await snapshot(this.historySelected$);
    if (historySelected) {
      const confirmed = await this._dialog.confirm({
        prompt:
          'Do you want to restore the selected version of the skill? Any changes will also be saved.',
        title: 'Restore from History',
        submitLabel: 'Yes',
        submitColor: 'warn',
        toolbarColor: 'primary',
      });

      if (!confirmed) {
        return;
      }

      this.historySelectCtrl.reset(undefined);
    }
    await this._catchError(async () => {
      const data = this.form.getFormData();
      const estimatedReadingTime = readingTime(
        getSchemaText(data?.content ?? [])
      ).minutes;

      await this._skillStatus.save(skill, {
        ...data,
        estimatedReadingTime,
      });
      this.saved.emit();
      this._snackBar.open(`${skill.name} saved`);
    });
  }

  async canPublish(skill: WithRef<ISkill>): Promise<boolean> {
    const user = await snapshot(this.currentUser$);
    if (user.isAdmin && Skill.isDraft(skill)) {
      return true;
    }

    return Skill.inReview(skill);
  }

  historyCompareFn(
    a: WithRef<ArchivedDocument<ISkill>>,
    b: WithRef<ArchivedDocument<ISkill>>
  ): boolean {
    return a && b ? isSameRef(a, b) : false;
  }

  private async _catchError(
    callback: () => Promise<void>,
    message: string = 'Oops! There was a problem saving'
  ): Promise<void> {
    try {
      await callback();
    } catch (error) {
      this._snackBar.open(message);
      throw error;
    }
  }
}
