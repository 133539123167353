import { first, get, isString, last } from 'lodash';
import { EMPTY, from, of, type Observable, type OperatorFunction } from 'rxjs';
import { expand, map, switchMap } from 'rxjs/operators';
import { isObject } from '../../common';
import { safeCombineLatest } from '../../rxjs';
import { type IReffable, type Reffable, type WithRef } from '../interfaces';
import {
  collection as collectionFirestore,
  doc,
  getCountFromServer,
  limit,
  orderBy,
  query as queryFirestore,
  startAfter,
  store,
  where,
  type CollectionReference,
  type DocumentReference,
  type Query,
  type QueryConstraint,
  type QueryDocumentSnapshot,
} from './adaptor';
import { sortedChanges } from './changes';
import {
  doc$,
  getDoc,
  getDocs,
  isReffable,
  snapshotToWithRef,
} from './document';
import { Firestore } from './firestore';
import { FirestoreScheduler } from './firestore-scheduler';
import { type ISoftDelete } from './model';

export function isColRef<T = unknown>(
  item: unknown
): item is CollectionReference<T> {
  return (
    isObject(item) &&
    isString(item.type) &&
    (item.type === 'collection' || 'doc' in item)
  );
}

export function getParentColRef<T>(
  path: string | DocumentReference
): CollectionReference<T> {
  const parentPath = getParentColPath(path);
  return asColRef<T>(parentPath);
}

export function getParentColPath(path: string | DocumentReference): string {
  if (!isString(path)) {
    path = path.path;
  }
  const docPath: string[] = path.split('/');
  docPath.pop();
  return docPath.join('/');
}

/**
 * @deprecated
 * Use asColRef instead
 */
export function getColRef<T>(
  path: string,
  appName?: string
): CollectionReference<T> {
  return collectionFirestore(
    store(appName),
    path
  ) as unknown as CollectionReference<T>;
}

export function asColRef<T>(
  colRef: CollectionReference<unknown> | Query<unknown> | string
): CollectionReference<T> {
  return isString(colRef)
    ? getColRef<T>(colRef, FirestoreScheduler.appName)
    : (colRef as CollectionReference<T>);
}

export function all$<T extends object>(
  col: CollectionReference<T> | Query<T> | string
): Observable<WithRef<T>[]> {
  return sortedChanges<T>(asColRef<T>(col)).pipe(
    map((snapshots) =>
      snapshots.map((docSnapshot) =>
        snapshotToWithRef<T>(
          docSnapshot.payload.doc as unknown as QueryDocumentSnapshot<T>
        )
      )
    )
  );
}

export function getDoc$<T extends object>(
  col: CollectionReference<T>,
  uid: string
): Observable<WithRef<T>> {
  return doc$(doc(col, uid));
}

export function undeletedQuery<T extends ISoftDelete>(
  col: CollectionReference<T> | Query<T>
): Query<T> {
  return queryFirestore(col, where('deleted', '!=', true));
}

export function deletedQuery<T extends ISoftDelete>(
  col: CollectionReference<T>
): Query<T> {
  return queryFirestore(col, where('deleted', '==', true));
}

export function query<T extends object>(
  col: CollectionReference<T> | Query<T> | string,
  ...queryConstraints: QueryConstraint[]
): Promise<WithRef<T>[]> {
  const colRef = asColRef<T>(col);
  return getDocs<T>(queryFirestore(colRef, ...queryConstraints));
}

export function query$<T extends object>(
  col: CollectionReference<T> | Query<T> | string,
  ...queryConstraints: QueryConstraint[]
): Observable<WithRef<T>[]> {
  const colRef = asColRef<T>(col);
  return all$<T>(queryFirestore(colRef, ...queryConstraints));
}

export function bufferedQuery$<T extends object>(
  col: CollectionReference<T> | Query<T>,
  bufferSize: number,
  sortField: string,
  sortOrder: 'desc' | 'asc' = 'asc',
  initialRecord?: WithRef<T>,
  recordLimit?: number
): Observable<WithRef<T>[]> {
  const queryFn = (
    collection: CollectionReference<T> | Query<T>,
    lastRecord?: WithRef<T>
  ): Query<T> => {
    const paginatedQuery = queryFirestore(
      collection,
      limit(bufferSize),
      orderBy(String(sortField), sortOrder)
    );

    if (!lastRecord) {
      return paginatedQuery;
    }
    return queryFirestore(
      paginatedQuery,
      startAfter(get(lastRecord, sortField))
    );
  };

  let totalRecordCount = 0;

  return from(Firestore.getDocs<T>(queryFn(col, initialRecord))).pipe(
    expand((data) => {
      totalRecordCount += data.length;
      const hasReachedLimit = recordLimit
        ? totalRecordCount >= recordLimit
        : false;
      if (hasReachedLimit) {
        return EMPTY;
      }

      const lastRecord = last(data);
      return data.length >= bufferSize
        ? from(Firestore.getDocs<T>(queryFn(col, lastRecord)))
        : EMPTY;
    })
  );
}

export function find$<T extends object>(
  col: CollectionReference<T> | Query<T> | string,
  ...queryConstraints: QueryConstraint[]
): Observable<WithRef<T> | undefined> {
  return query$<T>(col, ...queryConstraints).pipe(map((items) => first(items)));
}

export function firstResult$<T extends object>(
  queryFn: Query<T>,
  ...queryConstraints: QueryConstraint[]
): Observable<WithRef<T> | undefined> {
  return all$<T>(
    queryFirestore(queryFn, ...[...queryConstraints, limit(1)])
  ).pipe(map((results) => first(results)));
}

export async function firstResult<T extends object>(
  queryFn: Query<T>,
  ...queryConstraints: QueryConstraint[]
): Promise<WithRef<T> | undefined> {
  const results = await getDocs(
    queryFirestore(queryFn, ...[...queryConstraints, limit(1)])
  );
  return first(results);
}

export function toQuery<T extends object>(
  col: CollectionReference<T> | Query<T> | string,
  ...queryConstraints: QueryConstraint[]
): Query<T> {
  const colRef = asColRef<T>(col);
  return queryFirestore(colRef, ...queryConstraints);
}

export function subCollection$<T extends object>(
  path: string
): OperatorFunction<Reffable<object>, CollectionReference<T>> {
  return map((item) => asColRef<T>(collectionFirestore(item.ref, path)));
}

export function watch$<T extends object>(
  col$: Observable<CollectionReference<T> | Query<T>>
): Observable<WithRef<T>[]> {
  return col$.pipe(getAll());
}

export function getAll<T extends object>(): OperatorFunction<
  CollectionReference<T> | Query<T>,
  WithRef<T>[]
> {
  return switchMap((collection) => all$<T>(collection));
}

export function getItem<T extends object>(
  itemUid: string
): OperatorFunction<CollectionReference<T>, WithRef<T>> {
  return switchMap((collection) => getDoc$(collection, itemUid));
}

export function find<T extends object>(
  ...queryConstraints: QueryConstraint[]
): OperatorFunction<CollectionReference<T>, WithRef<T> | undefined> {
  return switchMap((collection) => find$(collection, ...queryConstraints));
}

export function subCollection<T extends object>(
  root: IReffable<object> | DocumentReference<object>,
  collectionPath: string
): CollectionReference<T> {
  const docRef = isReffable(root) ? root.ref : root;
  return asColRef<T>(collectionFirestore(docRef, collectionPath));
}

export function resolveDocRefs$<T extends ISoftDelete>(
  docRefs: DocumentReference<T>[]
): Observable<WithRef<T>[]> {
  if (!docRefs.length) {
    return of([]);
  }
  return safeCombineLatest(docRefs.map((ref) => doc$(ref))).pipe(
    map((items) => items.filter((item) => !item.deleted))
  );
}

export async function resolveDocRefs<T extends ISoftDelete>(
  docRefs: DocumentReference<T>[]
): Promise<WithRef<T>[]> {
  const docs = docRefs.map((ref) => getDoc(ref));
  const items = await Promise.all(docs);
  return items.filter((item) => !item.deleted);
}

export interface ICollectionSizeRequest {
  collectionPath: string;
}

export async function getCount(queryFn: Query): Promise<number> {
  try {
    const result = await getCountFromServer(queryFn);
    return result.data().count;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting count', queryFn, error);
    return 0;
  }
}
