import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import {
  Firestore,
  isWithRef,
  snapshot,
  type IProfile,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { ProfileImageService } from './profile-image.service';

@Component({
    selector: 'pt-profile-image-manager',
    templateUrl: './profile-image-manager.component.html',
    styleUrls: ['./profile-image-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProfileImageManagerComponent {
  profileImage = inject(ProfileImageService);
  storagePath$ = new ReplaySubject<string>(1);
  profile$ = new ReplaySubject<WithRef<IProfile> | IProfile>(1);
  imageUrl$: Observable<string | undefined>;
  @Output() captureImage = new EventEmitter<void>();
  @Output() storagePathUrlChange = new EventEmitter<string>();
  @Input() canCaptureImage = false;
  @Input() diameter = 140;

  @Input()
  set profile(profile: WithRef<IProfile>) {
    this.profile$.next(profile);
  }

  @Input()
  set storagePath(path: string) {
    this.storagePath$.next(path);
  }

  async updateImageUrl(storagePath: string): Promise<void> {
    const profile = await snapshot(this.profile$);
    if (!isWithRef(profile)) {
      this.storagePathUrlChange.emit(storagePath);
      return;
    }
    await Firestore.patchDoc(profile.ref, { profileImageURL: storagePath });
  }
}
