import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'lu-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagsComponent {
  @Input() disabled = false;
}
