import { IPathwayAssociation } from '@principle-theorem/level-up-core';
import { ISODateType, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class PathwayDueDateBloc {
  shouldShowDueDate$: Observable<boolean>;
  dueDate$: Observable<ISODateType | undefined>;
  relativeDueDate$: Observable<number | undefined>;
  isOverdue$: Observable<boolean>;
  daysUntilDue$: Observable<number | undefined>;

  constructor(
    public pathwayAssociation$: Observable<IPathwayAssociation | undefined>,
    public hasAchievedGoal$: Observable<boolean>
  ) {
    this.dueDate$ = this.pathwayAssociation$.pipe(
      map((pathwayAssociation) => pathwayAssociation?.dueDate)
    );

    this.relativeDueDate$ = this.pathwayAssociation$.pipe(
      map((pathwayAssociation) => pathwayAssociation?.relativeDueDate)
    );

    this.daysUntilDue$ = this.dueDate$.pipe(
      map((dueDate) => {
        if (!dueDate) {
          return;
        }
        const now = moment();
        const due = toMoment(dueDate);
        return due.diff(now, 'days');
      })
    );

    this.isOverdue$ = combineLatest([
      this.hasAchievedGoal$,
      this.daysUntilDue$,
    ]).pipe(
      map(([hasAchievedGoal, daysUntilDue]) => {
        if (hasAchievedGoal) {
          return false;
        }
        return daysUntilDue ? daysUntilDue < 0 : false;
      })
    );

    this.shouldShowDueDate$ = this.hasAchievedGoal$.pipe(
      map((hasAchievedGoal) => !hasAchievedGoal)
    );
  }
}
