import {
  Directive,
  type OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { filterUndefined } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../services/organisation.service';

@Directive({
    selector: '[luIsAdmin]',
    standalone: false
})
export class IsAdminDirective implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _thenTemplateRef?: TemplateRef<unknown>;

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<unknown>,
    private _organisation: OrganisationService
  ) {
    this._thenTemplateRef = templateRef;
    this._viewContainer.clear();
    this._organisation.user$
      .pipe(
        filterUndefined(),
        first(),
        map((user) => user.isAdmin),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isAdmin) => {
        if (!isAdmin) {
          return;
        }
        if (!this._thenTemplateRef) {
          return;
        }
        this._viewContainer.createEmbeddedView(this._thenTemplateRef);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
