import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateModule,
} from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  RouterModule,
  type ExtraOptions,
  type Route,
  PreloadAllModules,
} from '@angular/router';
import { NOTIFICATION_PROVIDERS } from '@principle-theorem/level-up-core';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgIntercomModule } from '@principle-theorem/ng-intercom';
import {
  ACHIEVEMENTS_FEATURE,
  LevelUpLoggerMetadataResolverService,
  NgLevelUpModule,
  OrganisationService,
} from '@principle-theorem/ng-level-up';
import { NgNotificationsModule } from '@principle-theorem/ng-notifications';
import {
  AppVersionModule,
  GCloudErrorHandlerService,
  LOGGER_METADATA_RESOLVER,
  NgSharedModule,
  ORGANISATION_SERVICE,
} from '@principle-theorem/ng-shared';
import { NgSubscriptionsModule } from '@principle-theorem/ng-subscriptions';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { HotkeyModule } from 'angular2-hotkeys';
import {
  Angulartics2GoogleGlobalSiteTag,
  Angulartics2GoogleTagManager,
  Angulartics2Module,
} from 'angulartics2';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';

const ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('@principle-theorem/ng-level-up').then(
        (mod) => mod.NgLevelUpModule
      ),
  },
];

const ROUTER_CONFIG: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules,
  bindToComponentInputs: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    NgFirebaseModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgSharedModule.forRoot({
      stackdriver: environment.stackdriver,
      appUrl: environment.appUrl,
      production: environment.production,
    }),
    RouterModule.forRoot(ROUTES, ROUTER_CONFIG),
    NgxAuthFirebaseUIModule.forRoot(
      environment.firebase,
      undefined,
      environment.ngxAuthFirebaseUI
    ),
    NgIntercomModule.forRoot(environment.intercom),
    IntercomModule.forRoot({
      appId: environment.intercom.appId,
      updateOnRouterChange: true,
    }),
    NgLevelUpModule.forRoot({
      production: environment.production,
      passwordlessLogin: environment.passwordlessLogin,
      chargebee: environment.chargebee,
      typesense: environment.typesense,
      websiteURL: environment.websiteURL,
      tipTap: environment.tipTap,
    }),
    NgFeatureFlagsModule.forRoot({
      production: environment.production,
      features: [ACHIEVEMENTS_FEATURE],
    }),
    Angulartics2Module.forRoot(environment.analytics),
    AppVersionModule.forRoot(environment.version),
    NgEditorModule.forRoot(),
    NgNotificationsModule.forRoot({
      providers: NOTIFICATION_PROVIDERS,
    }),
    NativeDateModule,
    MatNativeDateModule,
    NgSubscriptionsModule.forRoot(environment.analytics),
    HotkeyModule.forRoot(),
  ],
  providers: [
    {
      provide: LOGGER_METADATA_RESOLVER,
      useClass: LevelUpLoggerMetadataResolverService,
    },
    { provide: ErrorHandler, useClass: GCloudErrorHandlerService },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
      },
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulator ? ['localhost', 8081] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulator ? ['localhost', 5003] : undefined,
    },
    { provide: ORGANISATION_SERVICE, useExisting: OrganisationService },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        stretchTabs: false,
      } as MatTabsConfig,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'auto',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: { fontSet: 'material-symbols-outlined' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    angulartics: Angulartics2GoogleGlobalSiteTag,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    angulartics2GoogleTagManager.startTracking();
    angulartics.startTracking();
  }
}
