import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'lu-card-banner',
    templateUrl: './card-banner.component.html',
    styleUrls: ['./card-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardBannerComponent {
  @Input() colour = '#061a33';
}
