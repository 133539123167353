import { Component, Input } from '@angular/core';

@Component({
  selector: 'lu-page-splash',
  templateUrl: './page-splash.component.html',
  styleUrls: ['./page-splash.component.scss'],
})
export class PageSplashComponent {
  @Input() label?: string;
}
