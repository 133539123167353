import { Component, EventEmitter, Output } from '@angular/core';
import { PasswordSetForm, type IPasswordSet } from './password-form';

@Component({
    selector: 'lu-password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.scss'],
    exportAs: 'luPasswordForm',
    standalone: false
})
export class PasswordFormComponent {
  form: PasswordSetForm = new PasswordSetForm();
  @Output()
  submitted: EventEmitter<IPasswordSet> = new EventEmitter<IPasswordSet>();

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.submitted.next(this.form.getRawValue());
  }
}
