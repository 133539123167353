<pt-user-icon
  [src]="user | profileImage$ | async"
  [diameter]="40"
  [name]="user.name"
  [matMenuTriggerFor]="settingsMenu"
/>
<mat-menu #settingsMenu>
  <div class="layout-padding">
    <div class="mat-subtitle-2">{{ user.name }}</div>
    <div class="mat-caption">{{ user.email }}</div>
  </div>
  <mat-divider />
  <button mat-menu-item [routerLink]="['/', 'user-profile']">Profile</button>
  <button
    *ngIf="canChangeWorkspace$ | async"
    mat-menu-item
    [routerLink]="['/', 'workspaces']"
  >
    <div class="flex flex-col">
      <span>Change Workspace</span>
      <small class="mat-caption">({{ slug() }})</small>
    </div>
  </button>
  <button mat-menu-item (click)="auth.logout()">Logout</button>
</mat-menu>
