import { Inject, Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { type IEstimateResponse } from 'chargebee-js';
import { type ISubscriptionConfig, NG_SUBSCRIPTION_CONFIG } from './config';
import {
  estimateToTransaction,
  GoogleTagManagerActions,
  EcommerceActions,
} from './ecommerce';

@Injectable()
export class SubscriptionService {
  constructor(
    private _angulartics2: Angulartics2,
    @Inject(NG_SUBSCRIPTION_CONFIG) private _config: ISubscriptionConfig
  ) {}

  trackSubscription(estimateResponse: IEstimateResponse): void {
    const transaction = estimateToTransaction(estimateResponse);

    const gtmEvent = {
      event: GoogleTagManagerActions.Subscription,
      gtmCustom: estimateToTransaction(estimateResponse),
    };

    this._angulartics2.eventTrack.next({
      action: EcommerceActions.Purchase,
      properties: gtmEvent,
    });

    const ecommerceTrackers = [
      ...(this._config.gst?.trackingIds ?? []),
      ...(this._config.ecommerceConversion?.trackingIds ?? []),
    ];

    if (ecommerceTrackers.length) {
      this._angulartics2.eventTrack.next({
        action: EcommerceActions.Purchase,
        properties: {
          event: GoogleTagManagerActions.Subscription,
          gtmCustom: {
            ...transaction,
            send_to: ecommerceTrackers,
          },
        },
      });
    }

    const adsConversionTrackers = this._config.adsConversion?.trackingIds ?? [];

    if (adsConversionTrackers.length) {
      this._angulartics2.eventTrack.next({
        action: 'conversion',
        properties: {
          gstCustom: {
            send_to: adsConversionTrackers,
            transaction_id: transaction.ecommerce.purchase.actionField.id,
            value: transaction.ecommerce.purchase.actionField.revenue,
            currency: transaction.ecommerce.currencyCode,
          },
        },
      });
    }
  }
}
