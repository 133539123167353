import { Injectable } from '@angular/core';
import {
  PublicConfig,
  type IPublicConfig,
  ConfigCollection,
} from '@principle-theorem/level-up-core';
import { getDoc$ } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public$: Observable<IPublicConfig>;

  constructor() {
    this.public$ = getDoc$(PublicConfig.col(), ConfigCollection.Public);
  }
}
