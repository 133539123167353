import {
  getMixedContent,
  isMixedSchema,
  MixedSchema,
} from '@principle-theorem/editor';
import { IUploader } from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  firstValueFrom,
  shareReplayCold,
} from '@principle-theorem/shared';
import { AnyExtension, Content, Editor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import {
  combineLatest,
  of,
  ReplaySubject,
  Subject,
  type Observable,
} from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

export class EditorBloc {
  private _onDestroy$: Subject<void> = new Subject();
  private _editor?: Editor;
  extensions$ = new ReplaySubject<AnyExtension[]>(1);
  content$ = new ReplaySubject<MixedSchema | Content>(1);
  editor$: Observable<Editor>;
  view$: Observable<EditorView>;
  state$: Observable<EditorState>;
  editorHasContent$: Observable<boolean>;
  uploader$: ReplaySubject<IUploader> = new ReplaySubject(1);
  contentError: Subject<void> = new Subject();

  constructor(
    _inline: boolean = false,
    private _disabled$: Observable<boolean> = of(false)
  ) {
    this.editor$ = combineLatest([
      this.extensions$,
      this.content$.pipe(
        map((content) => {
          if (this._editor) {
            this._editor
              .chain()
              .setContent(
                isMixedSchema(content) ? getMixedContent(content) : content
              )
              .run();
          }
          return content;
        })
      ),
      this._disabled$.pipe(
        map((disabled) => !disabled),
        startWith(false)
      ),
    ]).pipe(
      map(([extensions, content, editable]) => {
        if (this._editor) {
          return this._editor;
        }

        this._editor = new Editor({
          extensions,
          editable,
          content: isMixedSchema(content) ? getMixedContent(content) : content,
        });
        return this._editor;
      }),
      shareReplayCold(),
      takeUntil(this._onDestroy$)
    );

    this.view$ = this.editor$.pipe(
      map((editor) => editor.view),
      filterUndefined(),
      shareReplayCold()
    );
    this.state$ = this.editor$.pipe(
      map((editor) => editor.state),
      filterUndefined(),
      shareReplayCold()
    );

    this.editor$
      .pipe(
        map((editor) => editor.state),
        filter((state) => !state),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.contentError.next());

    this.editorHasContent$ = this.editor$.pipe(
      map((editor) => editor.state),
      map((editorState) =>
        editorState ? !!editorState.doc.textContent.length : false
      )
    );
  }

  destroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._editor?.destroy();
  }

  async focus(): Promise<void> {
    const editor = await firstValueFrom(this.editor$);
    editor?.commands.focus();
  }
}
