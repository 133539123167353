import { TypedFormGroup, TypedFormControl } from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';

export interface IPasswordSet {
  password: string;
}

export class PasswordSetForm extends TypedFormGroup<IPasswordSet> {
  constructor() {
    super({
      password: new TypedFormControl<string>(undefined, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }
}
