import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreakpointService } from '@principle-theorem/ng-shared';
import { HotkeysService } from '../../services/hotkeys.service';

@Component({
    selector: 'lu-search-button',
    templateUrl: './search-button.component.html',
    styleUrls: ['./search-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchButtonComponent {
  constructor(
    public hotkeys: HotkeysService,
    public breakpoint: BreakpointService
  ) {}
}
