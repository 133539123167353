import {
  initRawSchema,
  type RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  getDoc,
  HTMLToEmail,
  type INamedDocument,
} from '@principle-theorem/shared';
import {
  type IEmailNotificationContext,
  type INotification,
  type INotificationContextRenderer,
} from '@principle-theorem/notifications';
import { MentionResourceType, mentionToLink, toMention } from '../../mention';
import {
  type EmailNotificationProvider,
  type INotificationUserPair,
  NotificationType,
} from '../notification';
import { userIsOfRole } from '../notification-helpers';
import {
  type ISkillReviewRequestResources,
  isSkillReviewResource,
} from '../resources/skill';
import { isUserDocRef } from '../../models/user/user';

export class SkillReviewApprovedContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      ISkillReviewRequestResources
    >
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.SkillReviewApproved);
  }

  isResource(data: object): data is ISkillReviewRequestResources {
    return isSkillReviewResource(data);
  }

  render(
    data: ISkillReviewRequestResources,
    currentStaffer: INamedDocument
  ): RawSchema | undefined {
    const isInteractor: boolean = userIsOfRole(currentStaffer, data.reviewer);
    const isAuthor: boolean = userIsOfRole(currentStaffer, data.author);
    const skillMention = toMention(data.skill, MentionResourceType.Skill);

    if (isAuthor && !isInteractor) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`${data.reviewer.name} has appoved `),
          toMentionContent(skillMention),
        ]),
      ]);
    }
  }
}

export class SkillReviewApprovedEmailContextRenderer
  implements EmailNotificationProvider
{
  canProvide(data: INotificationUserPair): boolean {
    return (
      data.notification.type === String(NotificationType.SkillReviewApproved)
    );
  }

  async execute(
    data: INotificationUserPair
  ): Promise<IEmailNotificationContext | undefined> {
    const resources = data.notification.resources;
    if (!isSkillReviewResource(resources)) {
      throw new Error('Cannot render email context');
    }

    const isInteractor: boolean = userIsOfRole(data.user, resources.reviewer);
    const isAuthor: boolean = userIsOfRole(data.user, resources.author);
    const skillMention = toMention(resources.skill, MentionResourceType.Skill);
    const skillHref = mentionToLink(skillMention);
    const skillName = HTMLToEmail.bold(resources.skill.name);

    if (
      !resources.author ||
      !isAuthor ||
      isInteractor ||
      !isUserDocRef(resources.author.ref)
    ) {
      return;
    }

    const author = await getDoc(resources.author.ref);
    const message = `Great news! Your skill ${skillName} has just been approved by ${resources.reviewer.name}!`;

    return {
      user: {
        name: author.name,
        email: author.email,
      },
      subject: `${resources.skill.name} has just been approved!`,
      preheader: message,
      message,
      action: {
        label: 'View Skill',
        url: skillHref,
      },
      signOffMessage: 'Keep up the great work!',
    };
  }
}
