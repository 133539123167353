import { type IUser, type IUserGroup } from '@principle-theorem/level-up-core';
import {
  type CollectionReference,
  type DocumentReference,
  where,
} from '@principle-theorem/shared';
import {
  query$,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { uniqWith } from 'lodash';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export class UserGroupAssociation {
  constructor(
    private _userGroupsCol$: Observable<CollectionReference<IUserGroup>>
  ) {}

  async setUserAssociations(
    user: WithRef<IUser>,
    associations: WithRef<IUserGroup>[]
  ): Promise<void> {
    const currentGroupAssociations: WithRef<IUserGroup>[] = await snapshot(
      this._userGroupsCol$.pipe(
        switchMap((col) =>
          query$(col, where('users', 'array-contains', user.ref))
        )
      )
    );

    const newAssignedGroupPaths: string[] = associations.map(
      (userGroup: WithRef<IUserGroup>) => userGroup.ref.path
    );

    const groupsToRemove: WithRef<IUserGroup>[] =
      currentGroupAssociations.filter(
        (currentAssociation: WithRef<IUserGroup>) => {
          return !newAssignedGroupPaths.includes(currentAssociation.ref.path);
        }
      );
    await this.remove(user, groupsToRemove);
    await this.add(user, associations);
  }

  async add(
    user: WithRef<IUser>,
    groups: WithRef<IUserGroup>[]
  ): Promise<void> {
    const promises = groups.map((userGroup: WithRef<IUserGroup>) => {
      userGroup.users.push(user.ref);
      userGroup.users = uniqWith(
        userGroup.users,
        (userA: DocumentReference, userB: DocumentReference) => {
          return userA.path === userB.path;
        }
      );
      return saveDoc(userGroup);
    });

    await Promise.all(promises);
  }

  async remove(
    user: WithRef<IUser>,
    groups: WithRef<IUserGroup>[]
  ): Promise<void> {
    const promises = groups.map((group: WithRef<IUserGroup>) => {
      group.users = group.users.filter(
        (groupUser: DocumentReference<IUser>) => {
          return groupUser.path !== user.ref.path;
        }
      );
      return saveDoc(group);
    });
    await Promise.all(promises);
  }
}
