import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  ISkill,
  type IGoals,
  type IPathway,
  type IUser,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  deleteDoc,
  doc$,
  filterUndefined,
  findProp,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  snapshot,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Subject, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  GroupAddPathwayDialogComponent,
  type IPathwayAddData,
} from '../group-add-pathway-dialog/group-add-pathway-dialog.component';
import {
  MemberAddDialogComponent,
  type IMemberAddData,
} from '../member-add-dialog/member-add-dialog.component';
import { GroupEditBloc } from './group-edit-bloc';
import { OrganisationService } from '../../../../services/organisation.service';

export type GroupFormData = Pick<IUserGroup, 'name' | 'description'>;

@Component({
  selector: 'lu-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupEditComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  bloc: GroupEditBloc;
  assignedUsers$: Observable<WithRef<IUser>[]>;
  form: TypedFormGroup<GroupFormData> = new TypedFormGroup<GroupFormData>({
    name: new TypedFormControl<string>(''),
    description: new TypedFormControl<string>(''),
  });

  constructor(
    private _route: ActivatedRoute,
    private _location: Location,
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    const userGroup$ = this._route.data.pipe(
      findProp<WithRef<IUserGroup>>('userGroup'),
      filterUndefined(),
      switchMap((userGroup) => doc$(userGroup.ref))
    );

    this.bloc = new GroupEditBloc(userGroup$, this._onDestroy$);

    this.assignedUsers$ = this.bloc.group$.pipe(
      map((userGroup) => userGroup.users),
      multiSwitchMap((userRef) => this._organisation.getUser$(userRef)),
      map(compact),
      multiSortBy$(nameSorter())
    );

    this.bloc.group$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((userGroup) => this._updateForm(userGroup));

    this.form.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((changes) => {
        this.bloc.updateForm(changes);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('window:beforeunload', ['$event'])
  async unloadNotification($event: BeforeUnloadEvent): Promise<void> {
    const hasUnsavedChanges = await snapshot(this.bloc.hasUnsavedChanges$);
    if (hasUnsavedChanges) {
      $event.returnValue = true;
    }
  }

  async delete(userGroup: WithRef<IUserGroup>): Promise<void> {
    const confirm = await this._basicDialog.confirm({
      title: 'Remove Group',
      prompt: 'Are you sure you want to delete this group?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }
    this._location.back();
    await deleteDoc(userGroup.ref);
  }

  async save(): Promise<void> {
    await this.bloc.save();
    this._snackBar.open('Group Updated');
  }

  async addUser(userGroup: WithRef<IUserGroup>): Promise<void> {
    const user = await this._dialog
      .open<MemberAddDialogComponent, IMemberAddData, WithRef<IUser>>(
        MemberAddDialogComponent,
        DialogPresets.small<IMemberAddData>({
          data: {
            members: userGroup.users,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!user) {
      return;
    }

    await this.bloc.addUser(user.ref);
    this._snackBar.open('User Added');
  }

  async removeUser(user: DocumentReference<IUser>): Promise<void> {
    await this.bloc.removeUser(user);
    this._snackBar.open('User Removed');
  }

  async addGoals(userGroup: WithRef<IUserGroup>): Promise<void> {
    const goalChanges = await this._basicDialog.mobileFullscreen<
      GroupAddPathwayDialogComponent,
      IPathwayAddData,
      IGoals
    >(
      GroupAddPathwayDialogComponent,
      DialogPresets.large<IPathwayAddData>({
        data: {
          pathways: userGroup.pathwayAssociations,
          skills: userGroup.skillAssociations,
          goals: userGroup,
          showDueDate: false,
          showRelativeDueDate: false,
        },
      })
    );

    if (!goalChanges) {
      return;
    }

    await this.bloc.addGoals(goalChanges);
    this._snackBar.open('New Goals Added');
  }

  async removePathway(pathwayRef: DocumentReference<IPathway>): Promise<void> {
    await this.bloc.removePathway(pathwayRef);
    this._snackBar.open('Pathway Removed');
  }

  async removeSkill(skillRef: DocumentReference<ISkill>): Promise<void> {
    await this.bloc.removeSkill(skillRef);
    this._snackBar.open('Skill Removed');
  }

  private _updateForm(userGroup: IUserGroup): void {
    this.form.patchValue(userGroup, { emitEvent: false });
    if (userGroup.readOnly) {
      this.form.disable();
      return;
    }
    this.form.enable();
  }
}
