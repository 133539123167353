import { Directive, Input } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';

@Directive({
    selector: 'a[ptIntercomTour]',
    standalone: false
})
export class IntercomProductTourDirective extends RouterLinkWithHref {
  @Input()
  set ptIntercomTour(productTourId: string) {
    if (productTourId) {
      this._updateQueryParams(productTourId);
    }
  }

  private _updateQueryParams(productTourId: string): void {
    this.queryParams = {
      ...this.queryParams,
      product_tour_id: productTourId,
    };
  }
}
