import {
  ISkillLevelRequirement,
  SkillLevel,
} from '@principle-theorem/level-up-core';
import { ISODateType, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SkillDueDateBloc {
  shouldShowDueDate$: Observable<boolean>;
  dueDate$: Observable<ISODateType | undefined>;
  relativeDueDate$: Observable<number | undefined>;
  isOverdue$: Observable<boolean>;
  daysUntilDue$: Observable<number | undefined>;

  constructor(
    public levelRequirement$: Observable<ISkillLevelRequirement | undefined>,
    public hasAchievedGoal$: Observable<boolean>
  ) {
    this.dueDate$ = this.levelRequirement$.pipe(
      map((levelRequirement) => levelRequirement?.dueDate)
    );

    this.relativeDueDate$ = this.levelRequirement$.pipe(
      map((levelRequirement) => levelRequirement?.relativeDueDate)
    );

    this.daysUntilDue$ = this.dueDate$.pipe(
      map((dueDate) => {
        if (!dueDate) {
          return;
        }
        const now = moment();
        const due = toMoment(dueDate);
        return due.diff(now, 'days');
      })
    );

    this.isOverdue$ = combineLatest([
      this.hasAchievedGoal$,
      this.daysUntilDue$,
    ]).pipe(
      map(([hasAchievedGoal, daysUntilDue]) => {
        if (hasAchievedGoal) {
          return false;
        }
        return daysUntilDue ? daysUntilDue < 0 : false;
      })
    );

    this.shouldShowDueDate$ = combineLatest([
      this.hasAchievedGoal$,
      this.levelRequirement$,
    ]).pipe(
      map(
        ([hasAchievedGoal, levelRequirement]) =>
          !hasAchievedGoal &&
          !!levelRequirement &&
          levelRequirement.level !== SkillLevel.None
      )
    );
  }
}
