import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  User,
  type IGoals,
  type IPathway,
  type ISkill,
  type ISkillLevelRequirement,
  type ISkillRef,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  multiSwitchMap,
  snapshot,
  type DocumentReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { ReplaySubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CachedListsService } from '../../../../services/cached-lists.service';
import { OrganisationService } from '../../../../services/organisation.service';
import {
  GroupAddPathwayDialogComponent,
  type IPathwayAddData,
} from '../../groups/group-add-pathway-dialog/group-add-pathway-dialog.component';
import { UserEditBloc } from '../user-edit/user-edit-bloc';

interface IGroupSkillPair {
  group: string;
  skills: WithRef<ISkill>[];
}

@Component({
  selector: 'lu-user-goal-progress',
  templateUrl: './user-goal-progress.component.html',
  styleUrls: ['./user-goal-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGoalProgressComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _expanded?: string;
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  groupSkillPairs$: Observable<IGroupSkillPair[]>;
  trackBySkill = TrackByFunctions.field<IGroupSkillPair>('group');
  bloc: UserEditBloc;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _dialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _cache: CachedListsService
  ) {
    this.bloc = new UserEditBloc(
      this.user$,
      this._organisation.userGroups$,
      this._onDestroy$
    );

    this.groupSkillPairs$ = combineLatest([
      this.user$,
      this._organisation.userGroups$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([user, userGroups]) =>
        combineLatest([
          User.getIncompleteSkillProgress$(user, userGroups).pipe(
            multiSwitchMap((progress) => this._resolveSkill(progress)),
            map((skills) => ({
              group: 'In Progress',
              skills: sortBy(skills, (skill) => skill.name),
            }))
          ),
          User.getCompletedSkillProgress$(user, userGroups).pipe(
            multiSwitchMap((progress) => this._resolveSkill(progress)),
            map((skills) => ({
              group: 'Completed',
              skills: sortBy(skills, (skill) => skill.name),
            }))
          ),
        ])
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setExpanded(reffable?: IReffable): void {
    this._expanded = reffable ? reffable.ref.id : undefined;
  }

  isExpanded(reffable: IReffable): boolean {
    return this._expanded === reffable.ref.id;
  }

  async addGoals(): Promise<void> {
    const user = await snapshot(this.user$);
    const goalChanges = await this._dialog.mobileFullscreen<
      GroupAddPathwayDialogComponent,
      IPathwayAddData,
      IGoals
    >(
      GroupAddPathwayDialogComponent,
      DialogPresets.large<IPathwayAddData>({
        data: {
          pathways: user.pathwayAssociations,
          skills: user.skillAssociations,
          goals: user,
          initialGoals: await snapshot(this.bloc.combinedGoals$),
          showDueDate: true,
          showRelativeDueDate: false,
        },
      })
    );

    if (!goalChanges) {
      return;
    }

    await this.bloc.updateGoals(goalChanges);
    this._snackBar.open('Goals Updated');
  }

  async removePathway(pathwayRef: DocumentReference<IPathway>): Promise<void> {
    await this.bloc.removePathway(pathwayRef);
    this._snackBar.open('Pathway Removed');
  }

  async removeSkill(skillRef: DocumentReference<ISkill>): Promise<void> {
    await this.bloc.removeSkill(skillRef);
    this._snackBar.open('Skill Removed');
  }

  async updateGoalLevel(
    levelRequirement: ISkillLevelRequirement
  ): Promise<void> {
    await this.bloc.updateGoalLevel(levelRequirement);
  }

  private _resolveSkill(progress: ISkillRef<ISkill>): Promise<WithRef<ISkill>> {
    return snapshot(
      this._cache.getLocalSkill$(progress.ref).pipe(filterUndefined())
    );
  }
}
