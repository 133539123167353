import { CommonModule } from '@angular/common';
import { type ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NG_EDITOR_CONFIG, NG_EDITOR_MIGRATIONS } from './editor-config';
import { EditorViewComponent } from './editor-view/editor-view.component';
import { EditorComponent } from './editor/editor.component';
import { ImageUploadDropzoneDirective } from './extensions/image/image-upload-dropzone.directive';
import { NgEditorExtensionsModule } from './extensions/ng-editor-extensions.module';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { BasicMenuButtonComponent } from './menu-bar/basic-menu-button/basic-menu-button.component';
import { NgProsemirrorModule } from '@principle-theorem/ng-prosemirror';
import { MenuDividerComponent } from './menu-bar/menu-divider/menu-divider.component';
import { MenuSpacerComponent } from './menu-bar/menu-spacer/menu-spacer.component';
import { EditorInputComponent } from './editor-input/editor-input.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { VideoUploadDropzoneDirective } from './extensions/image/video-upload-dropzone.directive';
import { NgxTiptapModule } from 'ngx-tiptap';
import { BubbleMenuComponent } from './menu-bar/bubble-menu/bubble-menu.component';
import { CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER } from '@principle-theorem/ng-core';
import { SubmenuBarComponent } from './menu-bar/submenu-bar/submenu-bar.component';
import { AISubmenuButtonComponent } from './extensions/ai/ai-submenu-button/ai-submenu-button.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    ReactiveFormsModule,
    NgEditorExtensionsModule,
    NgProsemirrorModule,
    NgxTiptapModule,
  ],
  declarations: [
    EditorComponent,
    EditorInputComponent,
    MenuBarComponent,
    MenuDividerComponent,
    MenuSpacerComponent,
    EditorViewComponent,
    ImageUploadDropzoneDirective,
    VideoUploadDropzoneDirective,
    BasicMenuButtonComponent,
    BubbleMenuComponent,
    SubmenuBarComponent,
    AISubmenuButtonComponent,
  ],
  exports: [
    EditorComponent,
    EditorInputComponent,
    EditorViewComponent,
    NgEditorExtensionsModule,
    BasicMenuButtonComponent,
    BubbleMenuComponent,
    AISubmenuButtonComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class NgEditorModule {
  constructor(
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    this._iconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/table-edit-icons.svg'
      )
    );
    this._iconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/image-edit-icons.svg'
      )
    );
  }

  static forRoot(): ModuleWithProviders<NgEditorModule> {
    return {
      ngModule: NgEditorModule,
      providers: [
        {
          provide: NG_EDITOR_CONFIG,
          useValue: {
            migrations: NG_EDITOR_MIGRATIONS,
          },
        },
        CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER,
      ],
    };
  }
}
