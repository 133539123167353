<div fxLayout="column" fxLayoutGap="16px">
  <ng-container *ngIf="allocatedUserCount$ | async as allocatedUserCount">
    <div class="user-count">
      <div class="mat-caption">
        <strong>{{ activatedUserCount$ | async }}</strong>
        of
        <strong>{{ allocatedUserCount }}</strong>
        users activated
      </div>
    </div>
  </ng-container>

  <ng-container *ngrxLet="pendingUsers$ as users">
    <ng-container *ngIf="users.length">
      <h3
        class="disabled-users-heading"
        fxLayoutAlign="start center"
        (click)="pendingUsers.toggle()"
      >
        <span
          class="trailing-badge"
          matBadgeSize="small"
          [matBadge]="users.length"
          [matBadgeColor]="badgeColor"
        >
          Pending Invites
        </span>
        <mat-icon>
          {{ pendingUsers.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h3>
      <pt-collapsible #pendingUsers>
        <ng-template ptCollapsibleContent>
          <mat-list
            class="with-dividers"
            ptIntercomTarget="users.list.disabled"
          >
            <lu-user-list-item
              *ngFor="let user of users; trackBy: trackByUser"
              [routePrefix]="routePrefix"
              [user]="user"
              (toggleUserEnabled)="toggleUserEnabled(user)"
             />
          </mat-list>
        </ng-template>
      </pt-collapsible>
    </ng-container>
  </ng-container>

  <h3>Active Users</h3>
  <mat-list class="with-dividers" ptIntercomTarget="users.list">
    <ng-container *ngIf="activeUsers$ | async as users; else loadingUsers">
      <ng-container *ngFor="let user of users; trackBy: trackByUser">
        <lu-user-list-item
          [routePrefix]="routePrefix"
          [user]="user"
          (toggleUserEnabled)="toggleUserEnabled(user)"
         />
      </ng-container>
    </ng-container>

    <ng-template #loadingUsers>
      <div class="layout-margin flex flex-col gap-2">
        <lu-user-placeholder />
        <lu-user-placeholder />
        <lu-user-placeholder />
      </div>
    </ng-template>
  </mat-list>

  <ng-container *ngrxLet="disabledUsers$ as users">
    <ng-container *ngIf="users.length">
      <h3
        class="disabled-users-heading"
        fxLayoutAlign="start center"
        (click)="disabledUsers.toggle()"
      >
        Disabled Users
        <mat-icon>
          {{ disabledUsers.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h3>
      <pt-collapsible #disabledUsers>
        <ng-template ptCollapsibleContent>
          <mat-list
            class="with-dividers"
            ptIntercomTarget="users.list.disabled"
          >
            <lu-user-list-item
              *ngFor="let user of users; trackBy: trackByUser"
              [routePrefix]="routePrefix"
              [user]="user"
              (toggleUserEnabled)="toggleUserEnabled(user)"
             />
          </mat-list>
        </ng-template>
      </pt-collapsible>
    </ng-container>
  </ng-container>
</div>
