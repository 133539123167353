<mat-toolbar color="primary">Create Pathway</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name"
        [required]="true"
        ptIntercomTarget="pathway-add-dialog.name"
      />
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field
        class="flex-1"
        ptIntercomTarget="pathway-add-dialog.author"
      >
        <mat-label>Creator</mat-label>
        <mat-select formControlName="author" [compareWith]="compareWithFn">
          <mat-option
            *ngFor="let author of authors$ | async; trackBy: trackByAuthor"
            [value]="author.ref"
          >
            {{ author.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          class="help-icon"
          matIconSuffix
          matTooltip="Author this pathway yourself, or click to delegate to another user"
          >help_outline</mat-icon
        >
      </mat-form-field>
    </div>

    <lu-folder-selector
      [selectedRef]="form.controls.folderRef.value"
      (add)="form.controls.folderRef.setValue($event)"
    />
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        color="primary"
        mat-flat-button
        [disabled]="isDisabled$ | async"
        ptIntercomTarget="pathway-add-dialog.save-button"
      >
        Create
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
