import { multiMap, reduce2DArray } from '@principle-theorem/shared';
import { kebabCase, sortBy, uniqBy } from 'lodash';
import {
  combineLatest,
  type MonoTypeOperatorFunction,
  type Observable,
  type OperatorFunction,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { type IFilterOption } from '@principle-theorem/shared';

export class TableFilters {
  static toFilterId(value: string): string {
    return kebabCase(value.toLowerCase());
  }

  static create<T>(
    getFilterLabels: (record: T) => string[]
  ): OperatorFunction<T[], IFilterOption<string>[]> {
    return (source$) =>
      source$.pipe(
        multiMap((item) => getFilterLabels(item)),
        reduce2DArray(),
        multiMap((label) => ({
          id: this.toFilterId(label),
          label,
          filter: () => true,
        })),
        map((options) => uniqBy(options, 'id')),
        map((options) => sortBy(options, 'label'))
      );
  }

  static select<T, D>(
    selected$: Observable<D>,
    filterFn: (record: T, selected: D) => boolean
  ): MonoTypeOperatorFunction<T[]> {
    return (source$) =>
      combineLatest([source$, selected$]).pipe(
        map(([records, selected]) =>
          records.filter((record) => filterFn(record, selected))
        )
      );
  }

  static multiSelect<T, D>(
    selected$: Observable<D[]>,
    filterFn: (record: T, selected: D) => boolean
  ): MonoTypeOperatorFunction<T[]> {
    return this.select<T, D[]>(
      selected$,
      (record, selected) =>
        !selected.length || selected.some((item) => filterFn(record, item))
    );
  }
}
