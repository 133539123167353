import { shareReplayCold, type WithRef } from '@principle-theorem/shared';
import {
  Pathway,
  type IPathway,
  type ITag,
  type IUser,
} from '@principle-theorem/level-up-core';
import { InputSearchFilter } from '@principle-theorem/ng-shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagFilter } from '../../tags/tag-filter';

export class PathwayListBloc {
  searchFilter: InputSearchFilter<WithRef<IPathway>>;
  filteredPathways$: Observable<WithRef<IPathway>[]>;

  constructor(
    pathways$: Observable<WithRef<IPathway>[]>,
    search$: Observable<string>,
    selectedTagFilters$: Observable<WithRef<ITag>[]>,
    public user$: Observable<WithRef<IUser>>
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IPathway>>(
      pathways$.pipe(
        map((pathways: WithRef<IPathway>[]) =>
          pathways.filter((pathway) => Pathway.skillRefs(pathway).length > 0)
        )
      ),
      search$,
      ['name']
    );

    this.filteredPathways$ = new TagFilter(
      this.searchFilter.results$,
      selectedTagFilters$
    ).results$.pipe(shareReplayCold());
  }
}
