<mat-toolbar color="primary">Add Skill/Pathway</mat-toolbar>

<div class="search" fxLayout="column">
  <mat-form-field>
    <mat-label>Select a Skill/Pathway</mat-label>
    <input
      matInput
      #trigger
      [matAutocomplete]="auto"
      [formControl]="search"
      ptIntercomTarget="select-goal.input"
    />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="add($event.option.value); trigger.blur()"
  >
    <ng-container *ngIf="pathwaysFilter.filteredItems$ | async as pathways">
      <mat-optgroup *ngIf="pathways.length" label="Pathways">
        <mat-option
          *ngFor="let pathway of pathways; trackBy: trackByPathway"
          [value]="pathway"
          ptIntercomTarget="select-goal.option-pathway"
        >
          {{ pathway.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container *ngIf="skillsFilter.filteredItems$ | async as skills">
      <mat-optgroup *ngIf="skills.length" label="Skills">
        <mat-option
          *ngFor="let skill of skills; trackBy: trackBySkill"
          [value]="skill"
          ptIntercomTarget="select-goal.option-skill"
        >
          {{ skill.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-autocomplete>
</div>
<mat-dialog-content fxLayout="column">
  <lu-pathway-and-skills-associate
    *ngIf="bloc.goals$ | async as goals"
    [showDueDate]="data.showDueDate"
    [showRelativeDueDate]="data.showRelativeDueDate"
    [pathways]="goals.pathwayAssociations"
    [skills]="goals.skillAssociations"
    [skillLevels]="goals.levelRequirements"
    (pathwayRemove)="bloc.removePathway($event)"
    (skillRemove)="bloc.removeSkill($event)"
    (pathwayLevelsChange)="bloc.updatePathwayLevels($event)"
    (skillLevelsChange)="bloc.updateSkillLevels($event)"
  />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="save()">
      Update Selected
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
