<lu-card-container
  class="grow"
  *ngIf="skill$ | async as skill"
  [title]="skill.name"
  [author]="author$ | async"
  [link]="link"
>
  <lu-card-banner />
  <lu-card-actions />
</lu-card-container>
