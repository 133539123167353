import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { type IUser } from '@principle-theorem/level-up-core';
import { BreakpointService } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  type INgLevelUpConfig,
  NG_LEVEL_UP_CONFIG,
} from '../../ng-level-up-config';
import { OrganisationService } from '../../services/organisation.service';

@Component({
    selector: 'lu-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavbarComponent {
  @Input() lightNavbar = false;
  websiteURL: string;
  user$: Observable<WithRef<IUser> | undefined>;
  showMobileMenu = false;

  get navbarColour(): string {
    return this.lightNavbar ? 'light' : 'dark';
  }

  constructor(
    private _org: OrganisationService,
    public breakpoint: BreakpointService,
    @Inject(NG_LEVEL_UP_CONFIG) private _config: INgLevelUpConfig
  ) {
    this.user$ = this._org.user$;
    this.websiteURL = this._config.websiteURL;
  }

  toggleMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
