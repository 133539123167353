<lu-level-confirm-progress-button
  *ngIf="canEdit$ | async"
  [level]="progress.current$ | async"
  [goal]="progress.goalLevel$ | async"
  [hasRequest]="progress.hasRequest$ | async"
  (progressLevelChange)="setProgressLevel($event)"
 />

<lu-level-progress-reset-button
  *ngIf="canEdit$ | async"
  [level]="progress.current$ | async"
  [goal]="progress.goalLevel$ | async"
  [hasRequest]="progress.hasRequest$ | async"
  (resetProgress)="resetProgress()"
 />
