import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type IUser } from '@principle-theorem/level-up-core';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrganisationService } from '../../../../services/organisation.service';
import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';

export interface IMemberAddData {
  members: DocumentReference<IUser>[];
}

@Component({
  selector: 'lu-member-add-dialog',
  templateUrl: './member-add-dialog.component.html',
  styleUrls: ['./member-add-dialog.component.scss'],
})
export class MemberAddDialogComponent {
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  associatedUsers$: BehaviorSubject<DocumentReference<IUser>[]> =
    new BehaviorSubject<DocumentReference<IUser>[]>([]);
  users$: Observable<WithRef<IUser>[]>;

  inputFilter: TypedFormControl<string | WithRef<IUser>> =
    new TypedFormControl();
  filteredUsers$: Observable<WithRef<IUser>[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IMemberAddData,
    private _organisation: OrganisationService,
    private _dialogRef: MatDialogRef<
      MemberAddDialogComponent,
      WithRef<IUser> | undefined
    >
  ) {
    this.associatedUsers$.next(data.members);

    this.users$ = this._organisation.enabledUsers$.pipe(
      map((users) =>
        users.filter((user: WithRef<IUser>) => !this.isAssociated(user))
      )
    );
    this.filteredUsers$ = combineLatest([
      this.inputFilter.valueChanges.pipe(startWith('')),
      this.users$,
    ]).pipe(map(([value, users]) => this._filter(value, users)));
  }

  displayFn(user?: IUser): string {
    return user ? `${user.name}` : '';
  }

  isAssociated(user: WithRef<IUser>): boolean {
    return this.associatedUsers$.value.find(
      (currentUserRef: DocumentReference<IUser>) => {
        return currentUserRef.path === user.ref.path;
      }
    )
      ? true
      : false;
  }

  add(user?: WithRef<IUser>): void {
    if (!user) {
      return;
    }
    this._dialogRef.close(user);
  }

  private _filter(
    value: string | WithRef<IUser>,
    users: WithRef<IUser>[]
  ): WithRef<IUser>[] {
    if (!isString(value)) {
      return [];
    }
    return users.filter((condition: WithRef<IUser>) => {
      return condition.name.toLowerCase().includes(value.toLowerCase());
    });
  }
}
