import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Timestamp } from '../firebase/firestore/adaptor';
import {
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  ISO_TIME_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_24HR,
} from './date-time-formatting';
import {
  ISOTimeType,
  type ISODateType,
  type Timezone,
  ISODateTimeType,
} from './timezone';
import { type ITimePeriod } from './time-bucket/interfaces';
import { toInt } from '../common';

export const MILLIS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
export const DAYS_IN_WEEK = 7;
export const MILLIS_IN_MINUTE: number = MILLIS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLIS_IN_HOUR: number = MILLIS_IN_MINUTE * SECONDS_IN_MINUTE;
export const MILLIS_IN_DAY: number = MILLIS_IN_HOUR * HOURS_IN_DAY;

// type ISOHourType =
//   | '00'
//   | '01'
//   | '02'
//   | '03'
//   | '04'
//   | '05'
//   | '06'
//   | '07'
//   | '08'
//   | '09'
//   | '10'
//   | '11'
//   | '12'
//   | '13'
//   | '14'
//   | '15'
//   | '16'
//   | '17'
//   | '18'
//   | '19'
//   | '20'
//   | '21'
//   | '22'
//   | '23';

// export type ISOMinuteTenth = '0' | '1' | '2' | '3' | '4' | '5';
// export type ISOMinute = `${ISOMinuteTenth}${DecimalSystemNumber}`;
export type Time24hrType = `${number}${number}:${number}${number}`;

export function isTime24hrType(time: unknown): time is Time24hrType {
  return isString(time) && time.length === 5 && time.charAt(2) === ':';
}

// type HourFormat12Hr = `${number}` | '10' | '11' | '12';
type Meridiem = 'am' | 'pm';
export type Time12hrType =
  | `${number}:${number}${number}${Meridiem}`
  | `${number}${number}:${number}${number}${Meridiem}`;

export function isTime12hrType(value: unknown): value is Time12hrType {
  const expression = new RegExp(
    /(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:[Aa]|[Pp])\.?[Mm]\.?)$/
  );
  return isString(value) && expression.test(value);
}

export function getTimeInRange(
  range: ITimePeriod,
  unitOfTime: moment.unitOfTime.Diff
): number {
  return range.to.diff(range.from, unitOfTime);
}

export const HOUR_DURATION: moment.Duration = moment.duration(60, 'minutes');
export const DAY_DURATION: moment.Duration = moment.duration(24, 'hours');

export function getDayDuration(): moment.Duration {
  return moment.duration(1, 'day');
}

export function getCurrentTime(): moment.Moment {
  return moment();
}

export function timeFromStartOfDay(
  time: moment.Moment,
  unitOfTime: moment.unitOfTime.Diff
): number {
  const startOfDay = time.clone().startOf('day');
  return time.diff(startOfDay, unitOfTime);
}

export function timeFromStartOfRange(
  range: ITimePeriod,
  time: moment.Moment,
  unitOfTime: moment.unitOfTime.Diff
): number {
  return time.diff(range.from, unitOfTime);
}

export function isToday(day: moment.Moment): boolean {
  return day.isSame(getCurrentTime(), 'day');
}

export function isToday$(): OperatorFunction<moment.Moment, boolean> {
  return map((day) => isToday(day));
}

export function momentIsWithinDayBounds(
  from: moment.Moment,
  day: moment.Moment
): boolean {
  return from.isBetween(
    day.clone().startOf('day'),
    day.clone().endOf('day'),
    undefined,
    '[]'
  );
}

export function eventSpansDay(
  eventRange: ITimePeriod,
  day: moment.Moment
): boolean {
  return eventRange.from.isBefore(day) && eventRange.to.isAfter(day);
}

export function toTimeString(
  value: Timestamp | Moment | Date | string,
  format: string = TIME_FORMAT,
  inputFormat: string[] = [TIME_FORMAT, ISO_TIME_FORMAT, TIME_FORMAT_24HR]
): string {
  if (isString(value)) {
    return moment(value, inputFormat).format(format);
  }
  return toMoment(value).format(format);
}

export function isWithinTimeRange(
  now: Time24hrType,
  from: Time24hrType,
  to: Time24hrType
): boolean {
  return now >= from && now <= to;
}

export function floorRangeToTimeUnit(
  eventRange: ITimePeriod,
  unitOfTime: moment.unitOfTime.Diff
): ITimePeriod {
  return {
    from: moment(eventRange.from).startOf(unitOfTime),
    to: moment(eventRange.to),
  };
}

export function ceilRangeToTimeUnit(
  eventRange: ITimePeriod,
  unitOfTime: moment.unitOfTime.Diff
): ITimePeriod {
  return {
    from: moment(eventRange.from),
    to: moment(eventRange.to).endOf(unitOfTime),
  };
}

export function expandRangeToTimeUnit(
  eventRange: ITimePeriod,
  unitOfTime: moment.unitOfTime.Diff
): ITimePeriod {
  const to = moment(eventRange.to);
  return {
    from: moment(eventRange.from).startOf(unitOfTime),
    to: to.isSame(moment(to).startOf(unitOfTime))
      ? to
      : moment(eventRange.to).add(1, unitOfTime).startOf(unitOfTime),
  };
}

export function getEarlierTime(
  timeA: moment.Moment,
  timeB: moment.Moment
): moment.Moment {
  return timeA.isSameOrBefore(timeB) ? timeA : timeB;
}

export function getLaterTime(
  timeA: moment.Moment,
  timeB: moment.Moment
): moment.Moment {
  return timeA.isSameOrAfter(timeB) ? timeA : timeB;
}

export function momentRound(
  time: moment.Moment,
  unit: moment.unitOfTime.Base
): moment.Moment {
  return time.clone().add(0.5, unit).startOf(unit);
}

export function roundToNearestMinuteInterval(
  time: Moment = moment(),
  interval: number = 1
): moment.Moment {
  const rounded = Math.round(moment(time).minute() / interval) * interval;
  return moment(time).minute(rounded).second(0);
}

export function floorToNearestMinuteInterval(
  time: Moment = moment(),
  interval: number = 1
): moment.Moment {
  const rounded = Math.floor(moment(time).minute() / interval) * interval;
  return moment(time).minute(rounded).second(0);
}

export function ceilToNearestMinuteInterval(
  time: Moment = moment(),
  interval: number = 1
): moment.Moment {
  const rounded = Math.ceil(moment(time).minute() / interval) * interval;
  return moment(time).minute(rounded).second(0);
}

export function toDate(date: Date | Timestamp | moment.Moment): Date {
  if (date instanceof Date) {
    return date;
  }
  if (moment.isMoment(date)) {
    return date.toDate();
  }
  return toTimestamp(date).toDate();
}

export function isDate(item: unknown): item is Date {
  return item instanceof Date;
}

export function toISODate(
  date: Timestamp | Date | moment.Moment | ISODateType,
  timezone?: Timezone
): ISODateType {
  if (isISODateType(date)) {
    return date;
  }
  const rawMoment = timezone ? toMomentTz(date, timezone) : toMoment(date);
  return rawMoment.format(ISO_DATE_FORMAT);
}

export function toISODateTime(
  date: Timestamp | Date | moment.Moment | ISODateType,
  timezone?: Timezone
): ISODateType {
  if (isISODateTimeType(date)) {
    return date;
  }
  const rawMoment = timezone ? toMomentTz(date, timezone) : toMoment(date);
  return rawMoment.format(ISO_DATE_TIME_FORMAT);
}

export function to24hrTime(
  date: Timestamp | Date | moment.Moment | Time24hrType | Time12hrType
): Time24hrType {
  return toTimeString(date, TIME_FORMAT_24HR) as Time24hrType;
}

export function to12hrTime(
  date: Timestamp | Date | moment.Moment | Time24hrType | Time12hrType
): Time12hrType {
  return toTimeString(date, TIME_FORMAT) as Time12hrType;
}

export function isISODateType(date: unknown): date is ISODateType {
  if (!isString(date)) {
    return false;
  }

  const parts = date.split('-');

  return (
    isString(date) &&
    date.length === 10 &&
    date.charAt(4) === '-' &&
    date.charAt(7) === '-' &&
    toInt(parts[1]) >= 1 &&
    toInt(parts[1]) <= 12 &&
    toInt(parts[2]) >= 1 &&
    toInt(parts[2]) <= 31
  );
}

export function isISOTimeType(time: unknown): time is ISOTimeType {
  if (!isString(time)) {
    return false;
  }

  const parts = time.split(':');

  return (
    isString(time) &&
    time.length >= 8 &&
    time.charAt(2) === ':' &&
    time.charAt(5) === ':' &&
    toInt(parts[0]) >= 0 &&
    toInt(parts[0]) < 24 &&
    toInt(parts[1]) >= 0 &&
    toInt(parts[1]) < 60 &&
    toInt(parts[2]) >= 0 &&
    toInt(parts[2]) < 60
  );
}

export function isISODateTimeType(
  dateTime: unknown
): dateTime is ISODateTimeType {
  if (!isString(dateTime)) {
    return false;
  }
  return (
    isISODateType(dateTime.slice(0, 10)) && isISOTimeType(dateTime.slice(11))
  );
}

export function toTimestamp(
  date?: Timestamp | Date | moment.Moment
): Timestamp {
  if (!date) {
    return Timestamp.now();
  }
  if (date instanceof Date) {
    return Timestamp.fromDate(date);
  }
  if (moment.isMoment(date)) {
    return Timestamp.fromDate(date.toDate());
  }
  return new Timestamp(date.seconds, date.nanoseconds);
}

/**
 * Number of nanoseconds in a millisecond.
 */
const MS_TO_NANOS = 1000000;

export function toMoment(
  timestamp: Timestamp | moment.Moment | Date | ISODateType
): moment.Moment {
  if (moment.isMoment(timestamp)) {
    return timestamp;
  }
  if (timestamp instanceof Date) {
    return moment(timestamp);
  }
  if (isISODateType(timestamp)) {
    return moment(timestamp);
  }
  return moment(
    timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / MS_TO_NANOS)
  );
}

/**
 * Get a Timezone aware moment. Note: This will return a new moment object
 * @param timestamp
 * @param timezone
 */
export function toMomentTz(
  timestamp: Timestamp | moment.Moment | Date | ISODateType,
  timezone: Timezone
): moment.Moment {
  if (moment.isMoment(timestamp)) {
    return moment(timestamp).tz(timezone);
  }
  if (timestamp instanceof Date) {
    return moment(timestamp).tz(timezone);
  }
  if (isISODateType(timestamp)) {
    return moment.tz(timestamp, ISO_DATE_FORMAT, timezone);
  }
  return moment(
    timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / MS_TO_NANOS)
  ).tz(timezone);
}

/**
 * Sorts in ascending order from now, to the future.
 * Any times in the past will be last in the ordering.
 */
export function sortTimestampNowUntilFuture(
  a?: Timestamp | Moment,
  b?: Timestamp | Moment
): number {
  if (!a || !b) {
    return 0;
  }
  const now = moment();
  const aMoment = toMoment(a);
  const bMoment = toMoment(b);
  if (aMoment.isBefore(now) || bMoment.isBefore(now)) {
    return 0;
  }
  if (aMoment.isBefore(bMoment)) {
    return -1;
  }
  return aMoment.isAfter(bMoment) ? 1 : 0;
}

/**
 * Sorts in ascending order. From the past to the future.
 */
export function sortTimestampAsc(
  a?: Timestamp | Moment,
  b?: Timestamp | Moment
): number {
  if (!a || !b) {
    return 0;
  }
  const aMoment = toMoment(a);
  const bMoment = toMoment(b);
  if (aMoment.isBefore(bMoment)) {
    return -1;
  }
  return aMoment.isAfter(bMoment) ? 1 : 0;
}

export function getDaysInPeriod(
  timezone: Timezone,
  timePeriod?: ITimePeriod
): ISODateType[] {
  if (!timePeriod) {
    return [];
  }
  const start = timePeriod.from.clone().tz(timezone);
  const end = timePeriod.to.clone().tz(timezone);
  const numDays = 1 + end.diff(start, 'days');
  return new Array(numDays).fill(undefined).map((_, index) => {
    const dayIncrement = start.clone().add(index, 'days');
    return toISODate(dayIncrement.tz(timezone));
  });
}
