import { assignWith, get, sum, uniq } from 'lodash';
import { type ObjectOfType } from '@principle-theorem/shared';

export interface IUserStatistics extends ObjectOfType<number> {
  totalStarsEarned: number; // Org/user/skills.OnWrite
  beginnerStarsEarned: number; // Org/user/skills.OnWrite
  apprenticeStarsEarned: number; // Org/user/skills.OnWrite
  proStarsEarned: number; // Org/user/skills.OnWrite
  mentorStarsEarned: number; // Org/user/skills.OnWrite
  totalStarsEarnedFromOthers: number; // Org/user/skills.OnWrite

  levelUpsRequested: number; // Org/skills.OnWrite
  pathwaysComplete: number; // Org/user/skills.OnWrite OR org/pathways.OnWrite

  resetOwnSkillProgress: number; // Org/user/skills.OnWrite
  resetOthersSkillProgress: number; // Org/user/skills.OnWrite

  totalSelfStarsGiven: number; // Org/user/skills.OnWrite
  totalOthersStarsGiven: number; // Org/user/skills.OnWrite

  beginnerStarsGiven: number; // Org/user/skills.OnWrite
  apprenticeStarsGiven: number; // Org/user/skills.OnWrite
  proStarsGiven: number; // Org/user/skills.OnWrite
  mentorStarsGiven: number; // Org/user/skills.OnWrite
  totalStarsGiven: number; // Org/user/skills.OnWrite

  levelUpRequestsPending: number; // Org/skills.OnWrite OR org/user/skills.OnWrite

  skillsDrafted: number; // Org/skills.OnWrite
  skillsMarkedForReview: number; // Org/skills.OnWrite
  skillsApproved: number; // Org/skills.OnWrite

  skillsAsReviewer: number; // Org/skills.OnWrite
  skillsAsReviewerCompleted: number; // Org/skills.OnWrite
  skillsAsReviewerPending: number; // Org/skills.OnWrite

  reviewAndApprovals: number; // Org/skills.OnWrite
  reviewAndChangeRequests: number; // Org/skills.OnWrite
  totalReviewsGiven: number; // Org/skills.OnWrite

  goalStarsEarned: number; // Org/user/skills.OnWrite OR org/user-groups.OnWrite
  nonGoalStarsEarned: number; // Org/user/skills.OnWrite OR org/user-groups.OnWrite
  skillGoalsReached: number; // Org/user/skills.OnWrite OR org/user-groups.OnWrite

  invitedUsers: number; // Org/users.OnWrite
  invitedActivatedUsers: number; // Org/users.OnWrite
}

export function reduceStats(
  stats: Partial<IUserStatistics>[]
): Partial<IUserStatistics> {
  return assignWith({}, ...stats, (objValue: number, srcValue: number) =>
    sum([objValue, srcValue])
  );
}

export function statDiff(
  a: Partial<IUserStatistics>,
  b: Partial<IUserStatistics>
): Partial<IUserStatistics> {
  const keys = uniq([...Object.keys(a), ...Object.keys(b)]);
  return keys.reduce((diff, key) => {
    const aVal: number = get(a, key, 0);
    const bVal: number = get(b, key, 0);
    return {
      ...diff,
      [key]: bVal - aVal,
    };
  }, {});
}
