import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, type UrlTree } from '@angular/router';
import { snapshot, type IUserWorkspace } from '@principle-theorem/shared';
import { isVendorWorkspace } from '@principle-theorem/level-up-core';
import {
  WorkspaceService,
  AuthGuard,
  AuthService,
} from '@principle-theorem/ng-auth';

@Injectable({
  providedIn: 'root',
})
export class VendorRedirectGuard extends AuthGuard {
  constructor(
    private _router: Router,
    auth: AuthService,
    private _workspace: WorkspaceService
  ) {
    super(auth);
  }

  async canActivate(_next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const vendorWorkspace: IUserWorkspace | undefined = await snapshot(
      this._workspace.value$
    );

    if (vendorWorkspace && isVendorWorkspace(vendorWorkspace)) {
      return this._router.createUrlTree(['/marketplace/account']);
    }

    return true;
  }
}
