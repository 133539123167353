import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { Content, VersionedSchema } from '@principle-theorem/editor';
import { shareReplayCold } from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EditorPresetsService } from '../../services/editor-presets.service';

@Component({
    selector: 'lu-content-editor-view',
    templateUrl: './content-editor-view.component.html',
    styleUrls: ['./content-editor-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContentEditorViewComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  content$: ReplaySubject<Content | VersionedSchema> = new ReplaySubject(1);
  extensions$: Observable<AnyExtension[]>;
  contentValid$: Observable<boolean>;
  @Output() contentError: EventEmitter<void> = new EventEmitter<void>();

  constructor(editorPresets: EditorPresetsService) {
    this.extensions$ = of(editorPresets.defaultExtensions());
    this.contentValid$ = this.contentError.pipe(
      map(() => false),
      startWith(true),
      shareReplayCold()
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  @Input()
  set content(content: Content | VersionedSchema) {
    if (content) {
      this.content$.next(content);
    }
  }
}
