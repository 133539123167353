import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  type IMarketplaceSubscription,
  type IPathway,
  type ISkill,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { PathwayAndSkillsListService } from '../pathway-and-skills-list.service';
import { PRODUCT_TOUR_CONFIG } from '../../../../models/product-tour-config';

@Component({
  selector: 'lu-explore-bundles-list',
  templateUrl: './explore-bundles-list.component.html',
  styleUrls: ['./explore-bundles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExploreBundlesListComponent {
  trackBySubscription =
    TrackByFunctions.ref<WithRef<IMarketplaceSubscription>>();
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  tourId = PRODUCT_TOUR_CONFIG.marketplaceSubscriptions;

  constructor(public bloc: PathwayAndSkillsListService) {}
}
