import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isWithRef, type WithRef } from '@principle-theorem/shared';
import {
  type IMarketplaceSubscription,
  type IPathway,
  type ISkill,
  isMarketplaceSubscription,
  isPathway,
  isSkill,
  isVendorBundle,
  type IUser,
  type IVendorBundle,
} from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { type Observable, type OperatorFunction, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

type CardItemType =
  | WithRef<ISkill>
  | WithRef<IPathway>
  | WithRef<IMarketplaceSubscription>
  | WithRef<IVendorBundle>;

@Component({
    selector: 'lu-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardComponent {
  item$: ReplaySubject<CardItemType> = new ReplaySubject(1);
  skill$: Observable<WithRef<ISkill> | undefined>;
  pathway$: Observable<WithRef<IPathway> | undefined>;
  subscription$: Observable<WithRef<IMarketplaceSubscription> | undefined>;
  vendorBundle$: Observable<WithRef<IVendorBundle> | undefined>;

  @Input() user: WithRef<IUser>;
  @Input() link: RouterLinkData;

  @Input()
  set item(item: CardItemType) {
    if (item) {
      this.item$.next(item);
    }
  }

  constructor() {
    this.skill$ = this.item$.pipe(getCardItem(isSkill));
    this.pathway$ = this.item$.pipe(getCardItem(isPathway));
    this.subscription$ = this.item$.pipe(
      getCardItem(isMarketplaceSubscription)
    );
    this.vendorBundle$ = this.item$.pipe(getCardItem(isVendorBundle));
  }
}

function getCardItem<T>(
  guard: (item: unknown) => item is T
): OperatorFunction<CardItemType, WithRef<T> | undefined> {
  return map((item) => (isWithRef<T>(item) && guard(item) ? item : undefined));
}
