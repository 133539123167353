import { Injectable, type OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  type IUser,
  type IVendor,
  type IVendorBundle,
  RootCollection,
  Vendor,
  VendorBundle,
  WorkspaceType,
} from '@principle-theorem/level-up-core';
import { AuthService, WorkspaceService } from '@principle-theorem/ng-auth';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  shareReplayHot,
  type CollectionReference,
} from '@principle-theorem/shared';
import {
  addDoc,
  filterUndefined,
  getAll,
  getDoc$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { combineLatest, type Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AddMarketplaceItemDialogComponent } from '../components/marketplace/add-marketplace-item-dialog/add-marketplace-item-dialog.component';
import { UserSyncBloc } from './user-sync-bloc';
import { ManagementService } from '../auth/management.service';

@Injectable()
export class VendorService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  usersCollection$: Observable<CollectionReference<IUser>>;
  vendor$: Observable<WithRef<IVendor> | undefined>;
  bundles$: Observable<WithRef<IVendorBundle>[]>;
  users$: Observable<WithRef<IUser>[]>;
  user$: Observable<WithRef<IUser> | undefined>;
  storagePath$: Observable<string>;

  constructor(
    private _auth: AuthService,
    private _workspace: WorkspaceService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _management: ManagementService
  ) {
    this.vendor$ = combineLatest([
      this._auth.authUser$,
      this._workspace.value$,
    ]).pipe(
      switchMap(([user, workspace]) => {
        if (
          !user ||
          !workspace ||
          !workspace.uid ||
          workspace.type !== String(WorkspaceType.Vendor)
        ) {
          return of(undefined);
        }

        return getDoc$(Vendor.col(), workspace.uid).pipe(
          catchError(() => of(undefined))
        );
      })
    );

    this.bundles$ = this.vendor$.pipe(
      filterUndefined(),
      switchMap((vendor) => Vendor.bundles$(vendor))
    );

    this.usersCollection$ = this.vendor$.pipe(
      filterUndefined(),
      map((vendor) => Vendor.userCol(vendor))
    );

    this.users$ = this.usersCollection$.pipe(
      getAll(),
      map((users) => sortBy(users, 'name'))
    );

    this.storagePath$ = this.vendor$.pipe(
      filterUndefined(),
      map(
        (vendor: WithRef<IVendor>) =>
          `${RootCollection.MarketplaceVendors}/${vendor.ref.id}`
      )
    );

    const userBloc = new UserSyncBloc(
      this.usersCollection$,
      this._auth.authUser$,
      this._onDestroy$
    );

    this.user$ = combineLatest([this._management.user$, this.users$]).pipe(
      switchMap(([managementUser, users]) => {
        if (
          !managementUser ||
          users.some((user) => user.email === managementUser.email)
        ) {
          return userBloc.user$;
        }

        return of(managementUser);
      }),
      shareReplayHot(this._onDestroy$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addBundle(): Promise<void> {
    const bundle = await this._dialog
      .open<
        AddMarketplaceItemDialogComponent,
        unknown,
        Pick<IVendorBundle, 'name'>
      >(
        AddMarketplaceItemDialogComponent,
        DialogPresets.small({
          data: {
            label: 'Bundle',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!bundle) {
      return;
    }

    const vendor = await snapshot(this.vendor$.pipe(filterUndefined()));
    const bundleRef = await addDoc(
      Vendor.bundleCol(vendor),
      VendorBundle.init(bundle)
    );

    await this._router.navigate([
      'marketplace',
      'account',
      'bundles',
      bundleRef.id,
    ]);
    this._snackBar.open('Bundle Added');
  }
}
