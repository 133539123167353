import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-user-placeholder',
    templateUrl: './user-placeholder.component.html',
    styleUrls: ['./user-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserPlaceholderComponent {}
