import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lu-page-simple',
  templateUrl: './page-simple.component.html',
  styleUrls: ['./page-simple.component.scss'],
})
export class PageSimpleComponent {
  @HostBinding('class.with-background') showWithBackground = false;
  @Input() label = '';
  @Input() width: string | number = '100%';
  @Input() maxWidth: string | number = '100%';
  @Input() splashBorder = 'solid 1px #EEE';
  @Input() marginBottom: string | number = 0;

  @Input()
  set withBackground(withBackground: BooleanInput) {
    this.showWithBackground = coerceBooleanProperty(withBackground);
  }
}
