import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { LevelUpTypesenseCollection } from './typesense';
import { PATHWAY_COLLECTION_SCHEMA } from './typesense-pathway';
import { SKILL_COLLECTION_SCHEMA } from './typesense-skill';

export const LEVEL_UP_TYPESENSE_COLLECTION_MAP: Record<
  LevelUpTypesenseCollection,
  CollectionCreateSchema
> = {
  [LevelUpTypesenseCollection.Skills]: SKILL_COLLECTION_SCHEMA,
  [LevelUpTypesenseCollection.Pathways]: PATHWAY_COLLECTION_SCHEMA,
};
