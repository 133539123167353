<div class="max-w-[400px]">
  <div class="text-sm">
    {{ label }}
    @if (required$ | async) {
      &ast;
    }
  </div>
  <div
    class="overflow-hidden rounded border border-solid border-slate-100 bg-white"
  >
    <canvas #signatureCanvas></canvas>
  </div>
  <div class="flex flex-row gap-2">
    <div class="flex grow text-xs opacity-50">{{ hint }}</div>
    @if (canClear$ | async) {
      <a class="text-xs" [routerLink]="[]" (click)="clear()">Clear</a>
    }
  </div>
</div>
