import {
  Directive,
  EventEmitter,
  Output,
  HostListener,
  ElementRef,
} from '@angular/core';

class DataTransferEvent extends Event {
  dataTransfer: { files: FileList };
}

@Directive({
    selector: '[ptFileUploadDropzone]',
    standalone: false
})
export class FileUploadDropzoneDirective {
  @Output() dropped: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() hovered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('drop', ['$event'])
  onDrop($event: DataTransferEvent): void {
    $event.preventDefault();
    this.dropped.emit($event.dataTransfer.files);
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: DataTransferEvent): void {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: DragEvent): void {
    $event.preventDefault();
    const element = this._elementRef.nativeElement as HTMLElement;
    if (
      $event.offsetX > 0 &&
      $event.offsetX < element.offsetWidth &&
      $event.offsetY > 0 &&
      $event.offsetY < element.offsetHeight
    ) {
      return;
    }
    this.hovered.emit(false);
  }
}
