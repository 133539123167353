import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IPathwaySection,
  ISkill,
  SkillRefType,
  updateSkillLevelRequirement,
  type IPathway,
  type IPathwayAssociation,
  type ISkillAssociation,
  type ISkillLevelRequirement,
  type IUser,
  type SkillLevel,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
  type InputSearchFilter,
} from '@principle-theorem/ng-shared';
import {
  ISODateType,
  asyncForEach,
  getDoc,
  isSameRef,
  multiFind,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  snapshot,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { BehaviorSubject, ReplaySubject, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PathwayDueDateBloc } from '../../../../models/pathway-due-date';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
    selector: 'lu-user-pathway-and-skills-associate',
    templateUrl: './user-pathway-and-skills-associate.component.html',
    styleUrls: ['./user-pathway-and-skills-associate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserPathwayAndSkillsAssociateComponent {
  private _pathwayAssociations$ = new BehaviorSubject<IPathwayAssociation[]>(
    []
  );
  trackBySkillRef = TrackByFunctions.uniqueId<DocumentReference<ISkill>>();
  trackBySection = TrackByFunctions.uniqueId<IPathwaySection>();
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  trackSkillAssociation = TrackByFunctions.ref<ISkillAssociation>('skill.ref');
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  pathways$: Observable<WithRef<IPathway>[]>;
  skills$ = new BehaviorSubject<ISkillAssociation[]>([]);
  skillLevels$ = new BehaviorSubject<ISkillLevelRequirement[]>([]);
  skillsFilter: InputSearchFilter<ISkillAssociation>;
  pathwaysFilter: InputSearchFilter<IPathwayAssociation>;
  search = new TypedFormControl<string>('');

  @Output() pathwayRemove = new EventEmitter<DocumentReference<IPathway>>();
  @Output() skillRemove = new EventEmitter<DocumentReference<ISkill>>();
  @Output() skillGoalChange = new EventEmitter<ISkillLevelRequirement>();
  @Output() skillLevelsChange = new EventEmitter<ISkillLevelRequirement[]>();
  @Output() pathwayLevelsChange = new EventEmitter<IPathwayAssociation[]>();

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set skillLevels(skillLevels: ISkillLevelRequirement[]) {
    if (skillLevels) {
      this.skillLevels$.next(skillLevels);
    }
  }

  @Input()
  set skills(skills: ISkillAssociation[]) {
    if (skills) {
      this.skills$.next(skills);
    }
  }

  @Input()
  set pathways(pathwayAssociations: IPathwayAssociation[]) {
    if (pathwayAssociations) {
      this._pathwayAssociations$.next(pathwayAssociations);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.pathways$ = this._pathwayAssociations$.pipe(
      multiSwitchMap((association) =>
        this._organisation.getPathway$(association.ref)
      ),
      map(compact),
      multiSortBy$(nameSorter())
    );
  }

  handleGoalChange(
    level: SkillLevel,
    skillRef: DocumentReference<ISkill>
  ): void {
    this.skillGoalChange.emit({
      skill: {
        ref: skillRef,
        type: SkillRefType.Skill,
      },
      level,
    });
  }

  handleRemovePathway(pathwayRef: DocumentReference<IPathway>): void {
    this.pathwayRemove.emit(pathwayRef);
  }

  handleRemoveSkill(skillRef: DocumentReference<ISkill>): void {
    this.skillRemove.emit(skillRef);
  }

  async updateDueDate(
    pathway: WithRef<IPathway>,
    dueDate?: ISODateType
  ): Promise<void> {
    const pathways = await snapshot(this._pathwayAssociations$);
    this.pathwayLevelsChange.emit(
      pathways.map((currentPathway) => {
        if (!isSameRef(currentPathway, pathway)) {
          return currentPathway;
        }

        return {
          ref: pathway.ref,
          dueDate,
        };
      })
    );

    const pathwaySkillRefs = pathway.sections.flatMap(
      (section) => section.steps
    );
    await asyncForEach(pathwaySkillRefs, (skill) =>
      this.handleSkillDueDateChange(skill, dueDate)
    );
  }

  async handleSkillDueDateChange(
    skillRef: DocumentReference<ISkill>,
    dueDate?: ISODateType
  ): Promise<void> {
    const skill = await getDoc(skillRef);
    const skillLevels: ISkillLevelRequirement[] =
      this.skillLevels$.value.slice();
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { dueDate },
      skillLevels
    );
    this.skillLevelsChange.emit(updatedSkillLevels);
  }

  getDueDateBloc(pathway: WithRef<IPathway>): PathwayDueDateBloc {
    const pathwayAssociation$ = this._pathwayAssociations$.pipe(
      multiFind((association) => isSameRef(association, pathway))
    );
    return new PathwayDueDateBloc(pathwayAssociation$, of(false));
  }
}
