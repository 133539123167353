import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { SidebarManagerService } from '@principle-theorem/ng-shared';

@Injectable({
  providedIn: 'root',
})
export class WithSidebarMainService {
  sidebar: SidebarManagerService;

  constructor(mediaMatcher: MediaMatcher, mediaObserver: MediaObserver) {
    this.sidebar = new SidebarManagerService(mediaMatcher, mediaObserver);
  }
}
