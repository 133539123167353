import { Injectable } from '@angular/core';
import { Skill, type SkillType } from '@principle-theorem/level-up-core';
import {
  addDoc,
  deleteDoc,
  type IAttachment,
  type WithRef,
} from '@principle-theorem/shared';
import { forkJoin, type Observable, of } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SkillAttachmentsService {
  async addAttachment(
    skill: WithRef<SkillType>,
    attachment: IAttachment
  ): Promise<void> {
    await addDoc(Skill.attachmentCol(skill), attachment);
  }

  async removeAttachment(attachment: WithRef<IAttachment>): Promise<void> {
    await deleteDoc(attachment.ref);
  }

  deleteSkillAttachments$(skill: WithRef<SkillType>): Observable<void[]> {
    return Skill.attachments$(skill).pipe(
      mergeMap((attachments: WithRef<IAttachment>[]) => {
        if (!attachments.length) {
          return of([]);
        }
        return forkJoin(
          attachments.map((attachment: WithRef<IAttachment>) =>
            this.removeAttachment(attachment)
          )
        );
      }),
      startWith([])
    );
  }
}
