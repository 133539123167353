export interface IProductTourConfig {
  grow: number;
  mentor: number;
  author: number;
  explore: number;
  users: number;
  groups: number;
  pathways: number;
  pageTemplates: number;
  tags: number;
  skillView: number;
  marketplaceSubscriptions: number;
  home: number;
}

export const PRODUCT_TOUR_CONFIG: IProductTourConfig = {
  grow: 432140,
  mentor: 432129,
  author: 432145,
  explore: 432157,
  users: 432138,
  groups: 435553,
  pathways: 432151,
  pageTemplates: 435555,
  tags: 432149,
  skillView: 0,
  marketplaceSubscriptions: 432139,
  home: 484562,
};
