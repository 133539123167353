<mat-list-item *ngIf="skill$ | async as skill">
  <lu-user-skill-level-indicator
    matListItemIcon
    class="!h-8 !w-8"
    [user]="user$ | async"
    [skill]="skill"
  />

  <span matListItemTitle>
    <a [routerLink]="['/explore/skill', skill.ref.id]">
      {{ skill.name }}
    </a>
  </span>

  <div matListItemMeta>
    <div class="flex flex-row items-center">
      <ng-container
        *ngIf="skillProgress.dueDateBloc.shouldShowDueDate$ | async"
      >
        <lu-user-goal-due-date-indicator
          canEdit
          [dueDate]="skillProgress.dueDateBloc.dueDate$ | async"
          (dateChange)="updateDueDate($event)"
        />
      </ng-container>

      <lu-user-skill-progress canEdit [user]="user$ | async" [skill]="skill" />
    </div>
  </div>
</mat-list-item>
