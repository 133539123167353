import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IMarketplaceSubscription,
  IVendorBundle,
} from '@principle-theorem/level-up-core';
import { BreakpointService } from '@principle-theorem/ng-shared';
import {
  IProfile,
  WithRef,
  getDoc,
  shareReplayCold,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { FavouritesService } from '../../../services/favourites.service';
import { MarketplaceCopyService } from '../../../services/marketplace-copy.service';
import { ImageCardPlaceholder } from '../../card/card-image/card-image.component';
import { ListCardBloc } from '../skill-list-card/skill-list-card.component';

@Component({
  selector: 'lu-subscription-list-card',
  templateUrl: './subscription-list-card.component.html',
  styleUrls: ['./subscription-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionListCardComponent {
  bloc: ListCardBloc;
  subscription$ = new ReplaySubject<WithRef<IMarketplaceSubscription>>(1);
  bundle$: Observable<WithRef<IVendorBundle>>;
  author$: Observable<IProfile>;
  subscriptionCardPlaceholderImage = ImageCardPlaceholder.Bundle;
  allContentCopied$: Observable<boolean>;
  copyButtonClicked$ = new BehaviorSubject<boolean>(false);
  copyButtonDisabled$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);

  @Input()
  set subscription(subscription: WithRef<IMarketplaceSubscription>) {
    if (subscription) {
      this.subscription$.next(subscription);
    }
  }

  constructor(
    breakpoint: BreakpointService,
    public favourites: FavouritesService,
    private _marketplaceCopy: MarketplaceCopyService
  ) {
    this.bloc = new ListCardBloc(breakpoint);
    this.bundle$ = this.subscription$.pipe(
      switchMap((subscription) => getDoc(subscription.bundleRef)),
      shareReplayCold()
    );
    this.author$ = this.subscription$.pipe(
      switchMap((sub) => getDoc(sub.vendorRef))
    );
    this.allContentCopied$ = this.bundle$.pipe(
      switchMap((bundle) => this._marketplaceCopy.isAllContentCopied(bundle)),
      tap(() => this.loading$.next(false))
    );
    this.copyButtonDisabled$ = combineLatest([
      this.allContentCopied$,
      this.copyButtonClicked$,
    ]).pipe(
      map(([contentCopied, buttonClicked]) => contentCopied || buttonClicked)
    );
  }

  async copyToFolder(bundle: WithRef<IVendorBundle>): Promise<void> {
    this.copyButtonClicked$.next(true);
    await this._marketplaceCopy.copyBundle(bundle);
  }
}
