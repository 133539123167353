import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { doc$, type IProfile, type WithRef } from '@principle-theorem/shared';
import { type IPublicSkill } from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'lu-public-skill-card',
  templateUrl: './public-skill-card.component.html',
  styleUrls: ['./public-skill-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicSkillCardComponent {
  skill$: ReplaySubject<WithRef<IPublicSkill>> = new ReplaySubject(1);
  author$: Observable<WithRef<IProfile> | undefined>;
  @Input() link?: RouterLinkData;

  @Input()
  set skill(skill: WithRef<IPublicSkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  constructor() {
    this.author$ = this.skill$.pipe(
      switchMap((data) => (data.author ? doc$(data.author) : of(undefined)))
    );
  }
}
