import { type INotification } from '@principle-theorem/notifications';
import {
  AtLeast,
  INamedDocument,
  all$,
  collectionGroupQuery,
  getDocs,
  getEnumValues,
  getParentDocRef,
  isDocRef,
  isSameRef,
  limit,
  multiMap,
  query$,
  subCollection,
  where,
  type CollectionReference,
  type DocumentReference,
  type IAuthClaims,
  type IProfile,
  type IReffable,
  type Reffable,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { first, pick, pullAllBy, uniqBy, uniqWith } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type MentionResourceType } from '../../mention';
import { type NotificationType } from '../../notifications/notification';
import { type IAchievementUnlock } from '../achievements/achievement';
import { IFolder } from '../folder';
import { OrganisationCollection } from '../organisation/organisation-collections';
import { type IPathway } from '../pathway/pathway';
import {
  SkillLevelRequestStatus,
  type ISkill,
  type ISkillLevelRequest,
} from '../skill/skill';
import { SkillCollection } from '../skill/skill-collections';
import { SkillLevel } from '../skill/skill-level';
import {
  getGoalProgress,
  hasReachedLevel,
  type ISkillProgress,
} from '../skill/skill-progress';
import {
  SkillRefType,
  isSameSkillRef,
  type ISkillRef,
} from '../skill/skill-type';
import {
  UserGroup,
  type IGoals,
  type IPathwayAssociation,
  type ISkillAssociation,
  type IUserGroup,
} from '../user-group/user-group';
import {
  filterGoals,
  userGroupsToGoals,
} from '../user-group/user-group-operators';
import { type IUserStatistics } from './user-statistics';
import { IVendorBundle } from '../marketplace/vendor-bundle';

export interface IRecentlyViewed<T extends object = object> {
  title: string;
  ref: DocumentReference<T>;
  viewedAt: Timestamp;
  type: MentionResourceType;
}

export interface IFavourite extends INamedDocument {
  type: MentionResourceType;
}

export type FavouriteType = IFolder | IPathway | ISkill;

export interface IUserMetadata {
  firstSignedInAt?: Timestamp;
  lastSignedInAt?: Timestamp;
  lastActiveAt?: Timestamp;
  recentlyViewed?: IRecentlyViewed[];
  favourites?: IFavourite[];
}

export interface IUserSettings {
  notifications: Partial<{ disabled: NotificationType[] }>;
  sidebar: Partial<{
    selectedTab: 'folders' | 'favourites';
    folderState: string[];
  }>;
}

export enum WorkflowType {
  InitialWorkspaceSetup = 'initialWorkspaceSetup',
}

export enum InitialWorkspaceSetupStatus {
  NotStarted = 'notStarted',
  AddWorkspaceName = 'addWorkspaceName',
  AddTeams = 'addTeams',
  AddUsers = 'addUsers',
  AddContent = 'addContent',
  AddFolders = 'addFolders',
  AssignContent = 'assignContent',
  Finished = 'finished',
}

export interface IUserPartial
  extends AtLeast<Pick<IUser, 'name' | 'email'>, 'name'> {
  teams: string[];
}

export interface IPathwayAssignment {
  pathway: string;
  assignedGroups: string[];
}

export interface IInitialSetupWorkflow {
  type: WorkflowType.InitialWorkspaceSetup;
  status: InitialWorkspaceSetupStatus;
  state: Partial<{
    workspaceName: string;
    userName: string;
    teams: string[];
    users: IUserPartial[];
    folders: string[];
    pathwayAssignments: IPathwayAssignment[];
    bundles: DocumentReference<IVendorBundle>[];
  }>;
}

export type Workflow = IInitialSetupWorkflow;

export const INITIAL_WORKFLOW_STATE = [
  {
    type: WorkflowType.InitialWorkspaceSetup,
    status: InitialWorkspaceSetupStatus.NotStarted,
    state: {},
  },
];

export interface IUser extends IUserMetadata, IProfile, IGoals {
  email: string;
  isOwner: boolean;
  isAdmin: boolean;
  isActivated: boolean;
  stats: Partial<IUserStatistics>;
  excludedSkillAssociations: ISkillAssociation[];
  excludedPathwayAssociations: IPathwayAssociation[];
  workflows: Workflow[];
  lastForceTokenRefresh?: Timestamp;
  invitedBy?: DocumentReference<IUser>;
  settings?: Partial<IUserSettings>;
}

export interface IUserSkillLevelRequest extends WithRef<ISkillLevelRequest> {
  skillRef: DocumentReference<ISkill>;
}

export function isUserDocRef(item: unknown): item is DocumentReference<IUser> {
  return item &&
    isDocRef(item) &&
    item.path.includes(`/${OrganisationCollection.Users}/`)
    ? true
    : false;
}

export class User {
  static init(overrides: Partial<IUser>): IUser {
    return {
      name: '',
      email: '',
      isOwner: false,
      isAdmin: false,
      isActivated: true,
      stats: {},
      skillAssociations: [],
      pathwayAssociations: [],
      levelRequirements: [],
      excludedSkillAssociations: [],
      excludedPathwayAssociations: [],
      workflows: [],
      favourites: [],
      ...overrides,
    };
  }

  static canInvite(user: WithRef<IUser>): boolean {
    return user.firstSignedInAt ? false : true;
  }

  static getGoals(user: WithRef<IUser>, userGroups: IUserGroup[]): IGoals {
    const userGoals = pick(user, [
      'skillAssociations',
      'pathwayAssociations',
      'levelRequirements',
    ]);

    const groupGoals = filterGoals(
      userGroupsToGoals(
        userGroups.filter((group) => UserGroup.hasUser(group, user))
      ),
      []
    );

    const levelRequirements = uniqBy(
      [...userGoals.levelRequirements, ...groupGoals.levelRequirements],
      (levelRequirement) => levelRequirement.skill.ref.path
    );

    const skillAssociations = pullAllBy(
      uniqBy(
        [...userGoals.skillAssociations, ...groupGoals.skillAssociations],
        (skillAssociation) => skillAssociation.skill.ref.path
      ),
      user.excludedSkillAssociations,
      (skillAssociation) => skillAssociation.skill.ref.path
    );

    const pathwayAssociations = pullAllBy(
      uniqBy(
        [...userGoals.pathwayAssociations, ...groupGoals.pathwayAssociations],
        (pathwayAssociation) => pathwayAssociation.ref.path
      ),
      user.excludedPathwayAssociations,
      (pathwayAssociation) => pathwayAssociation.ref.path
    );

    return {
      levelRequirements,
      skillAssociations,
      pathwayAssociations,
    };
  }

  static excludePathwayAssociation(
    user: WithRef<IUser>,
    pathway: Reffable<IPathway>
  ): WithRef<IUser> {
    return {
      ...user,
      excludedPathwayAssociations: [
        ...user.excludedPathwayAssociations,
        { ref: pathway.ref },
      ],
    };
  }

  static excludeSkillAssociation(
    user: WithRef<IUser>,
    skill: WithRef<ISkill>
  ): WithRef<IUser> {
    return {
      ...user,
      excludedSkillAssociations: [
        ...user.excludedSkillAssociations,
        {
          skill: {
            ref: skill.ref,
            type: SkillRefType.Skill,
          },
        },
      ],
    };
  }

  static skillProgressCol(
    user: IReffable<IUser>
  ): CollectionReference<ISkillProgress> {
    return subCollection<ISkillProgress>(user.ref, UserCollection.Skills);
  }

  static skillProgress$(
    user: WithRef<IUser>
  ): Observable<WithRef<ISkillProgress>[]> {
    return all$(User.skillProgressCol(user));
  }

  static achievementCol(
    user: WithRef<IUser>
  ): CollectionReference<IAchievementUnlock> {
    return subCollection<IAchievementUnlock>(
      user.ref,
      UserCollection.AchievementUnlocks
    );
  }

  static achievements$(
    user: WithRef<IUser>
  ): Observable<WithRef<IAchievementUnlock>[]> {
    return all$(User.achievementCol(user));
  }

  static notificationCol(
    user: WithRef<IUser>
  ): CollectionReference<INotification> {
    return subCollection<INotification>(user.ref, UserCollection.Notifications);
  }

  static notifications$(
    user: WithRef<IUser>
  ): Observable<WithRef<INotification>[]> {
    return all$(User.notificationCol(user));
  }

  static getIncompleteSkillProgress$(
    user: WithRef<IUser>,
    userGroups: IUserGroup[]
  ): Observable<ISkillRef<ISkill>[]> {
    return User.skillProgress$(user).pipe(
      map((progress) => {
        const goals = User.getGoals(user, userGroups);
        const skillRefs = uniqWith(
          [
            ...goals.levelRequirements.map(
              (levelRequirement) => levelRequirement.skill
            ),
            ...progress.map((skillProgress) => skillProgress.skillRef),
          ],
          isSameSkillRef
        );

        return skillRefs.filter((skillRef) => {
          const levelRequirements = goals.levelRequirements.find(
            (levelRequirement) =>
              isSameRef(levelRequirement.skill, skillRef) &&
              levelRequirement.level !== SkillLevel.None
          );

          const foundProgress = progress.find((skillProgress) =>
            isSameRef(skillProgress.skillRef, skillRef)
          );

          if (levelRequirements && !foundProgress) {
            return true;
          }

          if (foundProgress) {
            const goalProgress = getGoalProgress(goals, foundProgress);

            if (goalProgress.starsGoal > goalProgress.starsEarned) {
              return true;
            }
          }

          return false;
        });
      })
    );
  }

  static getCompletedSkillProgress$(
    user: WithRef<IUser>,
    userGroups: IUserGroup[]
  ): Observable<ISkillRef<ISkill>[]> {
    return User.skillProgress$(user).pipe(
      map((progress) => {
        const goals = User.getGoals(user, userGroups);
        const skillRefs = uniqWith(
          [
            ...goals.levelRequirements.map(
              (levelRequirement) => levelRequirement.skill
            ),
            ...progress.map((skillProgress) => skillProgress.skillRef),
          ],
          isSameSkillRef
        );

        return skillRefs.filter((skillRef) => {
          const levelRequirements = goals.levelRequirements.find(
            (levelRequirement) =>
              isSameRef(levelRequirement.skill, skillRef) &&
              levelRequirement.level !== SkillLevel.None
          );

          if (!levelRequirements) {
            return false;
          }

          const foundProgress = progress.find((skillProgress) =>
            isSameRef(skillProgress.skillRef, skillRef)
          );

          if (!foundProgress) {
            return false;
          }

          const goalProgress = getGoalProgress(goals, foundProgress);

          if (goalProgress.starsEarned < goalProgress.starsGoal) {
            return false;
          }

          return true;
        });
      })
    );
  }

  static getLastActiveAt(user: WithRef<IUser>): Timestamp | undefined {
    return user.lastActiveAt ?? user.firstSignedInAt;
  }

  static trainingRequests$(
    user: IReffable<IUser>,
    statuses: SkillLevelRequestStatus[] = getEnumValues(SkillLevelRequestStatus)
  ): Observable<IUserSkillLevelRequest[]> {
    return query$(
      collectionGroupQuery<ISkillLevelRequest>(
        SkillCollection.TrainingRequests
      ),
      where('userRef', '==', user.ref),
      where('status', 'in', statuses)
    ).pipe(
      multiMap((request) => ({
        ...request,
        skillRef: getParentDocRef<ISkill>(request.ref),
      }))
    );
  }

  static async canApproveTrainingRequest(
    user: Reffable<IUser>,
    skill: WithRef<ISkill>
  ): Promise<boolean> {
    if (user.isAdmin) {
      return true;
    }
    return User.isTrainerForSkill(user, skill);
  }

  static async isTrainerForSkill(
    user: Reffable<IUser>,
    skill: WithRef<ISkill> | ISkillRef
  ): Promise<boolean> {
    const skillProgress = first(
      await getDocs(
        User.skillProgressCol(user),
        where('skillRef.ref', '==', skill.ref),
        limit(1)
      )
    );

    if (!skillProgress) {
      return false;
    }

    return hasReachedLevel(skillProgress, SkillLevel.Trainer);
  }
}

export enum UserCollection {
  Skills = 'skills',
  Notifications = 'notifications',
  AchievementUnlocks = 'achievement-unlocks',
}

export interface ILUAuthClaims extends IAuthClaims {
  vendorUids: string[];
  subscriptionBundleUids: string[];
}
