import {
  DocumentReference,
  IReffable,
  ISoftDelete,
  WithRef,
} from '@principle-theorem/shared';
import { intersection } from 'lodash';
import { ITag } from './tag/tag';
import { IUserGroup } from './user-group/user-group';
import { IUser } from './user/user';
import { Content } from '@principle-theorem/editor';

export interface IFolder extends ISoftDelete {
  name: string;
  description: Content;
  tags: DocumentReference<ITag>[];
  restrictAccessTo: DocumentReference<IUserGroup>[];
  parentFolderRef?: DocumentReference<IFolder>;
  imageUrl?: string;
}

export class Folder {
  static init(overrides?: Partial<IFolder>): IFolder {
    return {
      deleted: false,
      name: 'New Folder',
      description: '',
      tags: [],
      restrictAccessTo: [],
      ...overrides,
    };
  }

  static canView(
    folder: WithRef<IFolder>,
    user: WithRef<IUser>,
    groups: IReffable<IUserGroup>[]
  ): boolean {
    if (user.isAdmin || folder.restrictAccessTo.length === 0) {
      return true;
    }

    return (
      intersection(
        groups.map((group) => group.ref.path),
        folder.restrictAccessTo.map((group) => group.path)
      ).length > 0
    );
  }
}
