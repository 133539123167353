<ng-container *ngIf="vendor$ | async as vendor">
  <lu-page-simple>
    <lu-page-header [label]="vendor.name">
      <lu-page-toolbar>
        <lu-header-button
          buttonIcon="add"
          buttonText="Create"
          luPageToolbarAction
          (click)="add()"
         />
        <pt-search-field fxFlex>
          <input matInput placeholder="Search" [formControl]="search" />
        </pt-search-field>
      </lu-page-toolbar>
    </lu-page-header>

    <div>
      <lu-list-heading>
        <lu-list-title color="primary">Users</lu-list-title>
        <lu-user-invite
          [usersCollection]="userCollection$ | async"
         />
      </lu-list-heading>
      <lu-users-list
        class="with-heading"
        routePrefix="users"
        [users]="users$ | async"
       />
    </div>
  </lu-page-simple>
</ng-container>
