<div class="flex flex-col gap-2">
  <span>
    {{ label }}
    @if (tooltip) {
      <mat-icon [matTooltip]="tooltip">info</mat-icon>
    }
  </span>
  <mat-form-field class="flex-1" [subscriptSizing]="subscriptSizing">
    <input
      matInput
      [placeholder]="placeholder"
      [disabled]="disabled$ | async"
      [formControl]="formCtrl"
    />
    @if (formCtrl.invalid && formCtrl.touched && errorMessage) {
      <mat-error>{{ errorMessage }}</mat-error>
    }
  </mat-form-field>
</div>
