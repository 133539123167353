import { Component, type OnDestroy } from '@angular/core';
import { type Event, NavigationStart, Router } from '@angular/router';
import { SidebarManagerService } from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { noop, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'lu-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: false
})
export class MainComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(router: Router, public sidebar: SidebarManagerService) {
    router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        switchMap(() => this._sidebarCloseOnRouteChange()),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _sidebarCloseOnRouteChange(): Promise<void> {
    if (await snapshot(this.sidebar.isMobile$)) {
      this.sidebar.close();
    }
  }
}
