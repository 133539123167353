<div fxLayout="column" fxFill *ngIf="pageTemplate$ | async as pageTemplate">
  <div fxFlex class="content">
    <lu-page-simple marginBottom="16">
      <lu-page-header label="Edit Template">
        <lu-page-toolbar>
          <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon class="font-bold">more_vert</mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu">
            <button mat-menu-item class="warn" (click)="delete(pageTemplate)">
              <mat-icon color="warn">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </lu-page-toolbar>
      </lu-page-header>
      <form [formGroup]="form" fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>

        <lu-content-editor
          fxFlex
          [storagePath]="org.storagePath$ | async"
          [control]="form.controls.content"
          (contentError)="handleContentError()"
          ptIntercomTarget="edit-page-template.editor"
        />
      </form>
    </lu-page-simple>
  </div>

  <pt-page-footer>
    <pt-buttons-container>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!canSave()"
        (click)="save(pageTemplate)"
      >
        Save
      </button>
    </pt-buttons-container>
  </pt-page-footer>
</div>
