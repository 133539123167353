import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PathwayAndSkillsListService } from '../pathway-and-skills-list.service';

@Component({
  selector: 'lu-explore-skills-list',
  templateUrl: './explore-skills-list.component.html',
  styleUrls: ['./explore-skills-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExploreSkillsListComponent {
  constructor(public list: PathwayAndSkillsListService) {}
}
