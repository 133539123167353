<div
  class="edit-enabled flex justify-center"
  *ngIf="canEdit$ | async; else readOnly"
>
  <input
    class="hidden-date"
    matInput
    [formControl]="dueDateCtrl"
    [matDatepicker]="dueDateSelector"
    (dateChange)="updateDueDate($event)"
  />
  <mat-datepicker #dueDateSelector />

  <ng-container *ngIf="dueDate$ | async as dueDate; else dateSelector">
    <button
      *ngIf="placement === 'before'"
      class="remove-button before"
      mat-icon-button
      color="warn"
      matTooltip="Reset due date"
      matTooltipShowDelay="300"
      (click)="dateChange.emit()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>

    <lu-goal-due-date-indicator
      matTooltip="Change due date"
      matTooltipShowDelay="300"
      [dueDate]="dueDate"
      (click)="dueDateSelector.open()"
    />

    <button
      *ngIf="placement === 'after'"
      class="remove-button after"
      mat-icon-button
      color="warn"
      matTooltip="Reset due date"
      matTooltipShowDelay="300"
      (click)="dateChange.emit()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
  </ng-container>

  <ng-template #dateSelector>
    <mat-datepicker-toggle
      matTooltip="Set due date"
      matTooltipShowDelay="300"
      [disableRipple]="true"
      [for]="dueDateSelector"
    />
  </ng-template>
</div>

<ng-template #readOnly>
  <lu-goal-due-date-indicator [dueDate]="dueDate$ | async" />
</ng-template>
