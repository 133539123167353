import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IPathway,
  ISkill,
  isPathway,
  isSkill,
} from '@principle-theorem/level-up-core';
import { WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { FavouritesService } from '../../services/favourites.service';

@Component({
    selector: 'lu-favourite-indicator',
    templateUrl: './favourite-indicator.component.html',
    styleUrls: ['./favourite-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FavouriteIndicatorComponent {
  item$ = new ReplaySubject<WithRef<IPathway> | WithRef<ISkill>>(1);

  @Input()
  set item(item: WithRef<IPathway> | WithRef<ISkill>) {
    if (item) {
      this.item$.next(item);
    }
  }

  constructor(public favourites: FavouritesService) {}

  toggle(item: WithRef<IPathway> | WithRef<ISkill>): void {
    if (isSkill(item)) {
      this.favourites.toggleSkill(item);
    }
    if (isPathway(item)) {
      this.favourites.togglePathway(item);
    }
  }
}
