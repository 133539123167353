import { Injectable, Injector } from '@angular/core';
import { MentionResourceType } from '@principle-theorem/level-up-core';
import { LoadUserActionService } from '../../services/contextual-actions/routing-actions/load-user-action.service';
import { LoadSkillActionService } from '../../services/contextual-actions/routing-actions/load-skill-action.service';
import { type IRoutingAction } from '@principle-theorem/shared';
import { LoadPathwayActionService } from '../../services/contextual-actions/routing-actions/load-pathway-action.service';

@Injectable()
export class MentionActionResolverService {
  constructor(private _inject: Injector) {}

  resolveActions(resourceType: MentionResourceType): IRoutingAction[] {
    return this.getActions(resourceType);
  }

  getActions(resourceName: string | MentionResourceType): IRoutingAction[] {
    switch (resourceName) {
      case MentionResourceType.User:
        return [this._inject.get(LoadUserActionService)];
      case MentionResourceType.Skill:
        return [this._inject.get(LoadSkillActionService)];
      case MentionResourceType.Pathway:
        return [this._inject.get(LoadPathwayActionService)];
      default:
        return [];
    }
  }
}
