<mat-chip-listbox fxFlex *ngIf="tag$ | async as tag">
  <mat-chip
    [style.--mdc-chip-label-text-color]="tag | tagTextColour"
    [style.background-color]="tag.hexColour"
    (click)="select($event, tag)"
  >
    {{ tag.name }}
  </mat-chip>
</mat-chip-listbox>

<button mat-icon-button (click)="edit()">
  <mat-icon>edit</mat-icon>
</button>
