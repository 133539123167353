import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';

export class AILoader {
  static isConnected$ = new BehaviorSubject<boolean>(true);
  static isRunning$ = new BehaviorSubject<boolean>(false);
  static error$ = new Subject<void>();
}

@Component({
    selector: 'pt-ai-submenu-button',
    templateUrl: './ai-submenu-button.component.html',
    styleUrls: ['./ai-submenu-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class AISubmenuButtonComponent extends BasicMenuButtonComponent {
  loader = AILoader;
  @Input() override compact = true;

  constructor(private _snackBar: MatSnackBar) {
    super();

    AILoader.error$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() =>
        this._snackBar.open(
          'There was an issue processing your request.',
          'OK',
          { duration: 5000 }
        )
      );
  }
}
