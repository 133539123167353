import { Injectable } from '@angular/core';
import { type DocumentReference, where } from '@principle-theorem/shared';
import {
  deleteDoc,
  query$,
  saveDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { type ISkill, type ITag } from '@principle-theorem/level-up-core';
import { pull } from 'lodash';
import { forkJoin, type Observable, of } from 'rxjs';
import { mergeMap, switchMap, take } from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root',
})
export class DeleteTagService {
  constructor(private _organisation: OrganisationService) {}

  async delete(tag: WithRef<ITag>): Promise<void> {
    return this._removeFromSkills(tag)
      .pipe(
        switchMap(() => deleteDoc(tag.ref)),
        take(1)
      )
      .toPromise();
  }

  private _removeFromSkills(
    tag: WithRef<ITag>
  ): Observable<DocumentReference<ISkill>[]> {
    return this._findSkillsByTag$(tag).pipe(
      mergeMap((skills: WithRef<ISkill>[]) => {
        if (!skills.length) {
          return of([]);
        }
        const updated = skills
          .map((skill: WithRef<ISkill>) => {
            pull(skill.tags, tag.ref);
            return skill;
          })
          .map(async (skill: WithRef<ISkill>) => saveDoc(skill));
        return forkJoin(updated);
      })
    );
  }

  private _findSkillsByTag$(tag: WithRef<ITag>): Observable<WithRef<ISkill>[]> {
    return this._organisation.skillCol$.pipe(
      switchMap((collection) =>
        query$(collection, where('tags', 'array-contains', tag.ref))
      )
    );
  }
}
