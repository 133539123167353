<div>
  <h3 ptIntercomTarget="subscription-list.marketplace-bundles-header">
    Marketplace Bundles
  </h3>
  <ng-container
    *ngIf="
      bloc.filteredSubscriptions$ | async as subscriptions;
      else loadingSubscriptions
    "
  >
    <lu-card-layout>
      <lu-card
        *ngFor="let subscription of subscriptions; trackBy: trackBySubscription"
        [item]="subscription"
        [link]="['/marketplace/subscriptions', subscription.ref.id]"
        [user]="bloc.user$ | async"
      />
    </lu-card-layout>
  </ng-container>

  <ng-template #loadingSubscriptions>
    <lu-card-layout>
      <div class="flex flex-col gap-2">
        <lu-card-placeholder />
        <lu-card-placeholder />
        <lu-card-placeholder />
      </div>
    </lu-card-layout>
  </ng-template>
</div>
