<mat-drawer-container fxFill *ngIf="sidebar.sidebar.state$ | async as state">
  <mat-drawer-content class="max-h-screen flex-1 grow bg-white">
    <lu-page-simple withBackground splashBorder="none">
      <div class="my-4 flex grow gap-4">
        <div
          class="grow rounded-lg border border-solid border-gray-200 bg-white pb-4"
        >
          <div class="flex grow flex-col">
            <nav mat-tab-nav-bar [tabPanel]="tabPanel">
              <a
                *ngFor="let link of navLinks; trackBy: trackByLink"
                #rla="routerLinkActive"
                mat-tab-link
                routerLinkActive
                [routerLink]="link.path"
                [active]="rla.isActive"
                [ptIntercomTarget]="link.intercomTarget"
                [matTooltip]="link.name"
                [matTooltipDisabled]="!link.icon"
              >
                <ng-container *ngIf="!link.icon; else iconLink">{{
                  link.name
                }}</ng-container>
                <ng-template #iconLink>
                  <mat-icon>{{ link.icon }}</mat-icon>
                </ng-template>
              </a>
            </nav>
            <mat-tab-nav-panel #tabPanel>
              <router-outlet />
            </mat-tab-nav-panel>
          </div>
        </div>
      </div>
    </lu-page-simple>
  </mat-drawer-content>

  <mat-drawer
    class="my-4 md:w-[400px] md:min-w-[400px]"
    [ngClass]="{
      'w-[280px] min-w-[280px] !bg-transparent':
        (sidebar.sidebar.isMobile$ | async)
    }"
    position="end"
    [disableClose]="sidebar.sidebar.isDesktop$ | async"
    [mode]="state.mode"
    [opened]="state.opened"
    (closedStart)="sidebar.sidebar.close()"
  >
    <div
      class="mr-1 flex h-full grow rounded-lg border border-solid border-gray-200 bg-white md:mr-4"
    >
      <mat-tab-group
        class="grow"
        [animationDuration]="0"
        [selectedIndex]="sidebarTabIndex"
        (selectedIndexChange)="setSelectedSidebarTab($event)"
      >
        <mat-tab>
          <ng-template mat-tab-label>
            <span ptIntercomTarget="dashboard.explore-header">Explore</span>
          </ng-template>
          <ng-template matTabContent>
            <div class="p-2">
              <lu-folders-list />
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span ptIntercomTarget="dashboard.favourites-header"
              >Favourites</span
            >
          </ng-template>
          <ng-template matTabContent>
            <div class="p-4">
              <lu-favourites-list
                ptIntercomTarget="dashboard.favourites-list"
                [user]="user$ | async"
              />
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-drawer>
</mat-drawer-container>
