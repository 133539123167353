<div>
  <lu-stat [colour]="colour">{{
    stats.author.approved.current$ | async
  }}</lu-stat>
  of
  <lu-stat [colour]="colour">{{
    stats.author.approved.total$ | async
  }}</lu-stat>
  created skills approved
  <span class="mat-caption" fxHide.lt-md>
    ({{ stats.author.contribution.percent$ | async | percent }} of all published
    skills)
  </span>
</div>
<mat-progress-bar
  mode="determinate"
  [color]="colour"
  [value]="stats.author.approved.progress$ | async"
 />
