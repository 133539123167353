<mat-form-field fxFlex *ngIf="tagSearch">
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let tag of tagsInput.tags$ | async; trackBy: trackByTag"
      [style.--mdc-chip-label-text-color]="tag | tagTextColour"
      [style.background-color]="tag.hexColour"
      [removable]="true"
      (removed)="tagsInput.remove(tag)"
    >
      <span fxFlex>{{ tag.name }}</span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>

    <input
      #tagInput
      placeholder="Tags"
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="create($event)"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="add($event)">
      <mat-option
        *ngFor="let tag of tagSearch.results$ | async; trackBy: trackByTag"
        [value]="tag"
      >
        <mat-chip-grid>
          <mat-chip-row
            [style.--mdc-chip-disabled-label-text-color]="tag | tagTextColour"
            [style.background-color]="tag.hexColour"
          >
            {{ tag.name }}
          </mat-chip-row>
        </mat-chip-grid>
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
