import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  inject,
  Output,
} from '@angular/core';
import {
  ISkill,
  IUser,
  User,
  filterSkillsWithDueDate,
  getSkillGoalDueDate$,
  sortSkillsByDueDate,
} from '@principle-theorem/level-up-core';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  ISODateType,
  WithRef,
  filterUndefined,
  shareReplayCold,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-mentor-user-item',
    templateUrl: './mentor-user-item.component.html',
    styleUrls: ['./mentor-user-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MentorUserItemComponent {
  profileImage = inject(ProfileImageService);
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  expanded$ = new BehaviorSubject<boolean>(false);
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  skills$ = new ReplaySubject<WithRef<ISkill>[]>(1);
  isAdmin$: Observable<boolean>;
  dueDate$: Observable<ISODateType | undefined>;
  @Output() expand = new EventEmitter<void>();
  @Output() collapse = new EventEmitter<void>();

  @Input()
  set expanded(expanded: BooleanInput) {
    this.expanded$.next(coerceBooleanProperty(expanded));
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set skills(skills: WithRef<ISkill>[]) {
    if (skills) {
      this.skills$.next(skills);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.isAdmin$ = this._organisation.user$.pipe(
      map((user) => user?.isAdmin ?? false)
    );

    const goals$ = combineLatest([
      this.user$,
      this._organisation.userGroups$,
    ]).pipe(
      map(([user, userGroup]) => User.getGoals(user, userGroup)),
      shareReplayCold()
    );

    const progress$ = this.user$.pipe(
      switchMap((user) => User.skillProgress$(user))
    );

    this.dueDate$ = this.skills$.pipe(
      filterSkillsWithDueDate(goals$, progress$),
      sortSkillsByDueDate(goals$),
      map((skills) => first(skills)),
      filterUndefined(),
      switchMap((skill) => getSkillGoalDueDate$(skill, goals$))
    );
  }

  setExpand(): void {
    this.expand.next();
  }

  setCollapse(): void {
    this.collapse.next();
  }
}
