import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IFolder } from '@principle-theorem/level-up-core';
import {
  SelectionListStore,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  DocumentReference,
  INamedDocument,
  asyncForEach,
  patchDoc,
  snapshot,
} from '@principle-theorem/shared';
import { Subject, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../../../services/organisation.service';
import { buildFolderOptionsList } from '../../folder-options-list';
import { IFolderNode } from '../folders-list.component';

interface ISelectableNode extends IFolderNode {}

@Component({
  selector: 'lu-folder-list-multi-action-toolbar',
  templateUrl: './folder-list-multi-action-toolbar.component.html',
  styleUrls: ['./folder-list-multi-action-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderListMultiActionToolbarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByFolder = TrackByFunctions.ref<INamedDocument<IFolder>>();
  folders$: Observable<INamedDocument<IFolder>[]>;
  @Output() closed = new EventEmitter<void>();
  @Output() moved = new EventEmitter<void>();

  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    public selectionList: SelectionListStore<ISelectableNode>
  ) {
    this._router.events
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.dismissSelected());

    this.folders$ = this._organisation.folders$.pipe(
      map((folders) => buildFolderOptionsList(folders))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('document:keydown.escape')
  dismissSelected(): void {
    this.selectionList.resetSelected();
    this.closed.next();
  }

  async moveItems(folderRef: DocumentReference<IFolder>): Promise<void> {
    const selected = await snapshot(this.selectionList.selected$);

    await asyncForEach(selected, (item) =>
      patchDoc(item.ref, {
        folderRef,
      })
    );

    this.moved.next();
    this.dismissSelected();
    this._feedbackMessage(selected.length, 'moved');
  }

  private _feedbackMessage(changed: number, action: string): void {
    const message = changed > 1 ? `Items ${action}` : `Items ${action}`;
    this._snackBar.open(message);
  }
}
