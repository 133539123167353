import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { type IDisplayColour } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackByFunctions } from '../track-by';

@Component({
    selector: 'pt-display-colour-picker',
    templateUrl: './display-colour-picker.component.html',
    styleUrls: ['./display-colour-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DisplayColourPickerComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class DisplayColourPickerComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  private _changed$ = new Subject<IDisplayColour>();
  private _touched$ = new Subject<void>();
  private _selected?: IDisplayColour;
  trackByOption = TrackByFunctions.field<IDisplayColour>('value');
  @Input() label: string = 'Select Colour';
  @Input() options: IDisplayColour[] = [];

  set selected(colour: IDisplayColour | undefined) {
    this._selected = colour;
    this._changed$.next(colour);
    this._touched$.next();
  }

  get selected(): IDisplayColour | undefined {
    return this._selected;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._changed$.complete();
    this._touched$.complete();
  }

  writeValue(colour: IDisplayColour | undefined): void {
    if (colour) {
      this.selected = colour;
    }
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }
}
