import { moveItemInArray, type CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IFolder,
  IPathwaySection,
  PathwaySection,
  PathwayStatus,
  type IPathway,
  type ITag,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  SidebarManagerService,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  deleteDoc,
  doc$,
  filterUndefined,
  findProp,
  isSameRef,
  patchDoc,
  saveDoc,
  snapshot,
  type DocumentReference,
  type INamedDocument,
  type IProfile,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, uniqWith } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  merge,
  of,
  type Observable,
} from 'rxjs';
import {
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CardImageService } from '../../../../components/card/card-image/card-image.service';
import {
  NamedDocsToTags,
  type INamedDocsToTags,
} from '../../../../components/tags/named-docs-to-tags';
import { CachedListsService } from '../../../../services/cached-lists.service';
import { OrganisationService } from '../../../../services/organisation.service';

type PathwayFormData = Pick<
  IPathway,
  'name' | 'description' | 'author' | 'folderRef'
>;

@Component({
  selector: 'lu-pathway-edit',
  templateUrl: './pathway-edit.component.html',
  styleUrls: ['./pathway-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayEditComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _sectionOrderChange$ = new Subject<IPathwaySection[]>();
  trackBySection = TrackByFunctions.uniqueId<IPathwaySection>();
  tagRefs$: ReplaySubject<DocumentReference<ITag>[]> = new ReplaySubject<
    DocumentReference<ITag>[]
  >(1);
  trackByAuthor = TrackByFunctions.ref<INamedDocument<IProfile>>();
  pathway$: Observable<WithRef<IPathway>>;
  sections$: Observable<IPathwaySection[]>;
  tags$: Observable<WithRef<ITag>[]>;
  author$: Observable<INamedDocument<IProfile> | undefined>;
  authors$: Observable<INamedDocument<IProfile>[]>;
  form = new TypedFormGroup<PathwayFormData>({
    name: new TypedFormControl<string>(''),
    description: new TypedFormControl<string>(''),
    author: new TypedFormControl<DocumentReference<IProfile>>(),
    folderRef: new TypedFormControl<DocumentReference<IFolder>>(),
  });
  uploadPath$: Observable<string>;
  image$: Observable<string | undefined>;
  pathwayName$ = new ReplaySubject<string>(1);
  canSave$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _route: ActivatedRoute,
    private _dialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    public org: OrganisationService,
    public sidebar: SidebarManagerService,
    cachedLists: CachedListsService,
    private _cardImageService: CardImageService,
    private _router: Router
  ) {
    this.pathway$ = this._route.data.pipe(
      findProp<WithRef<IPathway>>('pathway'),
      filterUndefined(),
      tap((pathway) => {
        if (this.form.pristine) {
          this.form.patchValue(pathway);
        }
        this.tagRefs$.next(pathway.tags);
        this.pathwayName$.next(pathway.name);
      }),
      switchMap((pathway) => doc$(pathway.ref))
    );

    this.sections$ = merge(
      this.pathway$.pipe(map((pathway) => pathway.sections ?? [])),
      this._sectionOrderChange$
    );

    const namedDocsToTags: INamedDocsToTags = new NamedDocsToTags(
      this.tagRefs$,
      cachedLists.tags$
    );
    this.tags$ = namedDocsToTags.tags$.pipe(startWith([]));
    this.uploadPath$ = this.org.storagePath$;
    this.image$ = this.pathway$.pipe(
      switchMap((pathway) => this._cardImageService.getCardImage$(pathway))
    );

    this.author$ = this.pathway$.pipe(
      switchMap((pathway) =>
        pathway.author ? doc$(pathway.author) : of(undefined)
      )
    );

    this.authors$ = combineLatest([this.author$, this.org.enabledUsers$]).pipe(
      map(([author, users]) => compact(uniqWith([author, ...users], isSameRef)))
    );

    validFormGroupChanges$(this.form)
      .pipe(withLatestFrom(this.pathway$), takeUntil(this._onDestroy$))
      .subscribe(([valid, pathway]) => {
        this.canSave$.next(valid && !pathway.readOnly);
      });

    this.form.controls.name.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((name) => this.pathwayName$.next(name));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async dropSection(
    event: CdkDragDrop<string[]>,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    if (!pathway.sections) {
      pathway.sections = [];
    }
    moveItemInArray(pathway.sections, event.previousIndex, event.currentIndex);
    this._sectionOrderChange$.next(pathway.sections);
    await patchDoc(pathway.ref, {
      sections: pathway.sections,
    });
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  compareWithFn(aRef?: DocumentReference, bRef?: DocumentReference): boolean {
    return aRef && bRef && aRef.path === bRef.path ? true : false;
  }

  async delete(pathway: WithRef<IPathway>): Promise<void> {
    const confirm = await this._dialog.confirm({
      title: 'Remove Pathway',
      prompt: 'Are you sure you want to delete this pathway?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }

    await deleteDoc(pathway.ref);
    await this._router.navigate(['/admin/pathways']);
  }

  async save(pathway: WithRef<IPathway>): Promise<void> {
    if (pathway.readOnly) {
      return;
    }
    await saveDoc({
      ...pathway,
      ...this.form.getRawValue(),
    });
    this.form.markAsPristine();
    this.form.updateValueAndValidity();
    this._snackBar.open('Pathway Updated');
  }

  async addSection(pathway: WithRef<IPathway>): Promise<void> {
    await patchDoc(pathway.ref, {
      sections: [
        ...(pathway.sections ?? []),
        PathwaySection.init({ name: '' }),
      ],
    });
    this._snackBar.open('Section Added');
  }

  async updateSection(
    section: IPathwaySection,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    await patchDoc(pathway.ref, {
      sections: (pathway.sections ?? []).map((currentSection) => {
        if (currentSection.uid !== section.uid) {
          return currentSection;
        }

        return section;
      }),
    });
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  updateTags(tags: WithRef<ITag>[], pathway: IPathway): void {
    pathway.tags = tags.map((tag: WithRef<ITag>) => tag.ref);
    this.tagRefs$.next(pathway.tags);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  async updateImageUrl(storagePath?: string): Promise<void> {
    const pathway = await snapshot(this.pathway$);
    await saveDoc({ ...pathway, imageUrl: storagePath });
  }

  canPublish(pathway: WithRef<IPathway>): boolean {
    return pathway.status !== PathwayStatus.Published;
  }

  async publish(pathway: WithRef<IPathway>): Promise<void> {
    await patchDoc(pathway.ref, { status: PathwayStatus.Published });
    this._snackBar.open('Pathway Published');
  }

  isPublished(pathway: WithRef<IPathway>): boolean {
    return pathway.status === PathwayStatus.Published;
  }

  async revertToDraft(pathway: WithRef<IPathway>): Promise<void> {
    await patchDoc(pathway.ref, { status: PathwayStatus.Draft });
    this._snackBar.open('Pathway Reverted to Draft');
  }
}
