<div fxLayout="row">
  <mat-form-field fxFill>
    <mat-label>{{ placeholder }}</mat-label>
    <input
      matInput
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [formControl]="folderFilter"
    />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="folderSelected($event.option.value)"
  >
    <ng-container *ngIf="folderSearch.results$ | async as results">
      <mat-option
        *ngFor="
          let folder of folderSearch.results$ | async;
          trackBy: trackByFolder
        "
        [value]="folder"
      >
        {{ folder.name }}
      </mat-option>

      <mat-option *luIsAdmin (click)="addNewFolder()">
        <mat-icon>create_new_folder</mat-icon>
        <span>Add New Folder</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
