import { Component, ContentChildren, type QueryList } from '@angular/core';
import { TagFilterComponent } from '../tags/tag-filter/tag-filter.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'lu-page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.scss'],
})
export class PageToolbarComponent {
  @ContentChildren(MatButton, { descendants: true })
  buttons: QueryList<MatButton>;
  @ContentChildren('extraContent') extra: QueryList<unknown>;
  @ContentChildren(TagFilterComponent)
  tagFilters: QueryList<TagFilterComponent>;
}
