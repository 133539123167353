import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  type IOrganisation,
  type IUser,
  User,
} from '@principle-theorem/level-up-core';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import {
  filterUndefined,
  findProp,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
    selector: 'lu-org-user-edit',
    templateUrl: './org-user-edit.component.html',
    styleUrls: ['./org-user-edit.component.scss'],
    standalone: false
})
export class OrgUserEditComponent {
  user$: Observable<WithRef<IUser>>;
  organisation$: Observable<WithRef<IOrganisation>>;

  constructor(
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _functions: AuthFirebaseFunctionsService
  ) {
    this.user$ = this._route.data.pipe(
      findProp<WithRef<IUser>>('user'),
      filterUndefined()
    );

    this.organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined()
    );
  }

  async invite(user: WithRef<IUser>): Promise<void> {
    const organisation: WithRef<IOrganisation> = await snapshot(
      this.organisation$
    );
    await this._functions.sendOrganisationInviteEmail(organisation, user);
    this._snackBar.open('Invitation Email Sent');
  }

  async toggleUserEnabled(user: WithRef<IUser>): Promise<void> {
    await patchDoc(user.ref, {
      isActivated: user.isActivated ? false : true,
    });

    let message = 'User Access ';
    message += user.isActivated ? 'Enabled' : 'Disabled';
    this._snackBar.open(message);
  }

  canInvite(user: WithRef<IUser>): boolean {
    return User.canInvite(user);
  }
}
