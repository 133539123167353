import { Content } from '@principle-theorem/editor';
import {
  isObject,
  isSameRef,
  type AtLeast,
  type DocumentReference,
  type IProfile,
  type IReffable,
  type ISoftDelete,
  type WithRef,
} from '@principle-theorem/shared';
import { intersection } from 'lodash';
import { RootCollection } from '../../root-collection';
import { IFolder } from '../folder';
import {
  BundleReleaseCollection,
  type IBundlePathway,
} from '../marketplace/bundle';
import { VendorBundleCollection } from '../marketplace/vendor-bundle';
import { type IPublicSkill, type ISkill } from '../skill/skill';
import { type ITag } from '../tag/tag';
import { type IUserGroup } from '../user-group/user-group';
import { type IUser } from '../user/user';
import { IPathwaySection, PathwaySectionStep } from './pathway-section';

export enum PathwayStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface IPathway extends ISoftDelete {
  name: string;
  description: Content;
  status: PathwayStatus;
  tags: DocumentReference<ITag>[];
  sections: IPathwaySection[];
  restrictAccessTo: DocumentReference<IUserGroup>[];
  readOnly: boolean;
  author?: DocumentReference<IProfile>;
  vendorPathwayRef?: DocumentReference<IBundlePathway>;
  imageUrl?: string;
  folderRef?: DocumentReference<IFolder>;
  defaultTrainer?: DocumentReference<IUser>[];
}

export interface IPublicPathway extends Omit<IPathway, 'skills'> {
  skills: DocumentReference<IPublicSkill>[];
}

export function isPathway(item: unknown): item is IPathway {
  return (
    isObject(item) &&
    'name' in item &&
    'description' in item &&
    'tags' in item &&
    Array.isArray(item.tags) &&
    'sections' in item &&
    Array.isArray(item.sections)
  );
}

export interface IFilterPathwayByStatus {
  statuses: PathwayStatus[];
  pathways: WithRef<IPathway>[];
}

export type ResolvePathwayFn = (
  skill: DocumentReference<IPathway>
) => Promise<WithRef<IPathway> | undefined>;

export class Pathway {
  static init(overrides: AtLeast<IPathway, 'author' | 'folderRef'>): IPathway {
    return {
      deleted: false,
      name: '',
      description: '',
      status: PathwayStatus.Draft,
      tags: [],
      sections: [],
      restrictAccessTo: [],
      readOnly: false,
      ...overrides,
    };
  }

  static canEdit(pathway: WithRef<IPathway>, user: WithRef<IUser>): boolean {
    return user.isAdmin && !pathway.readOnly;
  }

  static canView(
    pathway: Pick<WithRef<IPathway>, 'restrictAccessTo'>,
    user: WithRef<IUser>,
    groups: IReffable<IUserGroup>[]
  ): boolean {
    if (user.isAdmin || pathway.restrictAccessTo.length === 0) {
      return true;
    }

    return (
      intersection(
        groups.map((group) => group.ref.path),
        pathway.restrictAccessTo.map((group) => group.path)
      ).length > 0
    );
  }

  static skillRefs(pathway: IPathway): DocumentReference<ISkill>[] {
    return pathway.sections.flatMap((section) => section.steps);
  }

  static hasSkillAssociation(
    pathway: IPathway,
    skill: WithRef<ISkill>
  ): boolean {
    return Pathway.skillRefs(pathway).some(
      (skillRef) => skillRef.path === skill.ref.path
    );
  }

  static addSkillAssociation(
    pathway: IPathway,
    skill: WithRef<ISkill>
  ): DocumentReference<PathwaySectionStep>[] {
    return [...Pathway.skillRefs(pathway), skill.ref];
  }

  static removeSkillAssociation(
    pathway: IPathway,
    skill: WithRef<ISkill>
  ): IPathway {
    return {
      ...pathway,
      sections: (pathway.sections ?? []).map((section) => ({
        ...section,
        steps: section.steps.filter((skillRef) => !isSameRef(skill, skillRef)),
      })),
    };
  }

  static isMarketplaceRelease(
    pathway: WithRef<IPathway | IBundlePathway>
  ): boolean {
    if (!('vendorPathwayRef' in pathway)) {
      return false;
    }
    return pathway.vendorPathwayRef &&
      pathway.ref.path.startsWith(RootCollection.MarketplaceVendors) &&
      pathway.ref.path.includes(VendorBundleCollection.Releases) &&
      pathway.ref.path.includes(BundleReleaseCollection.Pathways)
      ? true
      : false;
  }
}
