import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { type MatCheckboxChange } from '@angular/material/checkbox';
import {
  DEFAULT_USER_GROUP_UID,
  UserGroup,
  filterUserGroupsByUser$,
  type IUser,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, Subject, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
  selector: 'lu-user-group-assignment',
  templateUrl: './user-group-assignment.component.html',
  styleUrls: ['./user-group-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGroupAssignmentComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject(1);
  trackByGroup = TrackByFunctions.ref<WithRef<IUserGroup>>();
  userGroups$: Observable<WithRef<IUserGroup>[]>;
  assignedGroups$: BehaviorSubject<WithRef<IUserGroup>[]> = new BehaviorSubject<
    WithRef<IUserGroup>[]
  >([]);
  @Output() groupAssignmentChange: EventEmitter<WithRef<IUserGroup>[]> =
    new EventEmitter<WithRef<IUserGroup>[]>();

  constructor(private _organisation: OrganisationService) {
    this.userGroups$ = this._organisation.userGroups$;

    this.userGroups$
      .pipe(filterUserGroupsByUser$(this._user$), takeUntil(this._onDestroy$))
      .subscribe((assignedGroups: WithRef<IUserGroup>[]) => {
        this.assignedGroups$.next(assignedGroups);
      });

    this.assignedGroups$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((assignedGroups: WithRef<IUserGroup>[]) => {
        this.groupAssignmentChange.emit(assignedGroups);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set user(user: WithRef<IUser>) {
    this._user$.next(user);
  }

  isInGroup$(userGroup: IUserGroup): Observable<boolean> {
    return this._user$.pipe(
      map((user: WithRef<IUser>) => UserGroup.hasUser(userGroup, user))
    );
  }

  isDefaultGroup(userGroup: WithRef<IUserGroup>): boolean {
    return userGroup.ref.id === DEFAULT_USER_GROUP_UID;
  }

  handleGroupChange(
    change: MatCheckboxChange,
    userGroup: WithRef<IUserGroup>
  ): void {
    let assignedGroups: WithRef<IUserGroup>[] =
      this.assignedGroups$.value.slice();

    if (change.checked) {
      assignedGroups.push(userGroup);
      this.assignedGroups$.next(assignedGroups);
      return;
    }

    assignedGroups = assignedGroups.filter(
      (assignedGroup: WithRef<IUserGroup>) => {
        return assignedGroup.ref.path !== userGroup.ref.path;
      }
    );
    this.assignedGroups$.next(assignedGroups);
  }
}
