<lu-page-simple *ngIf="organisation$ | async as organisation">
  <lu-page-header [label]="organisation.name" />
  <div fxLayout="column" fxLayoutGap="16px" class="layout-margin">
    <div fxLayout="row" fxLayoutGap="16px">
      <div fxFlex class="info mat-body-2">
        <p>Workspace: {{ organisation.slug }}</p>
        <p>Joined: {{ organisation.createdAt | toDate | date }}</p>
      </div>
      <div>
        <lu-organisation-status [status]="organisation.status" />
      </div>
    </div>

    <div>
      <lu-list-heading>
        <lu-list-title color="primary">Users</lu-list-title>
        <lu-user-invite [usersCollection]="userCollection$ | async" />
      </lu-list-heading>
      <lu-users-list
        class="with-heading"
        routePrefix="users"
        [users]="users$ | async"
      />
    </div>
  </div>
</lu-page-simple>
