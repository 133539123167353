import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkillStatus } from '@principle-theorem/level-up-core';

@Component({
    selector: 'lu-skill-status',
    templateUrl: './skill-status.component.html',
    styleUrls: ['./skill-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillStatusComponent {
  @Input() status: SkillStatus = SkillStatus.Draft;
  @Input({ transform: coerceBooleanProperty }) dense = false;
  statusColourMap: { [key in SkillStatus]: string } = {
    [SkillStatus.Draft]: 'default',
    [SkillStatus.Review]: 'accent',
    [SkillStatus.Published]: 'primary',
  };
}
