<ng-container *ngIf="skill$ | async as skill">
  <lu-page-simple>
    <lu-page-header [label]="skill.name" />
    <pt-breadcrumbs
      [breadcrumbs]="breadcrumbs$ | async"
      [current]="skill.name"
    />
    <div class="layout-margin">
      <form [formGroup]="form" fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="column">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>Is a Trainer Required?</mat-label>
          <mat-select formControlName="requiresTrainerVerification">
            <mat-option [value]="false"
              >No. A User can verify their own training by marking that they've
              viewed the skill.</mat-option
            >
            <mat-option [value]="true"
              >Yes. A Trainer needs to sign off on a user's training for this
              skill.</mat-option
            >
          </mat-select>
        </mat-form-field>

        <lu-content-editor
          fxFlex
          [storagePath]="storagePath$ | async"
          [control]="form.controls.content"
        />

        <div ptIntercomTarget="skill-edit-form.attachments">
          <h2 class="mat-headline-6">Attachments</h2>
          <pt-attachments
            [uploadPath]="storagePath$ | async"
            [attachments]="attachments$ | async"
            (attachmentAdded)="skillAttachments.addAttachment(skill, $event)"
            (attachmentRemoved)="skillAttachments.removeAttachment($event)"
          />
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          fxLayoutGap="16px"
          class="layout-padding"
        >
          <ng-content></ng-content>
          <button type="button" mat-button color="warn" (click)="delete(skill)">
            Delete
          </button>
          <button
            mat-flat-button
            color="primary"
            [disabled]="isDisabled$ | async"
            (click)="save(skill)"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </lu-page-simple>
</ng-container>
