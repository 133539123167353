import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IVendorBundle } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-vendor-bundle-list',
  templateUrl: './vendor-bundle-list.component.html',
  styleUrls: ['./vendor-bundle-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorBundleListComponent {
  trackByBundle = TrackByFunctions.ref<WithRef<IVendorBundle>>();
  bundles$: ReplaySubject<WithRef<IVendorBundle>[]> = new ReplaySubject(1);
  @Input() routePrefix = '';

  @Input()
  set bundles(bundles: WithRef<IVendorBundle>[]) {
    if (bundles) {
      this.bundles$.next(bundles);
    }
  }
}
