import { isString } from 'lodash';
import { OpenAI } from 'openai';
import { isObject } from './common';
import { TypeGuard } from './type-guards';

export interface IOpenAIQueryData {
  model: OpenAI.ChatModel;
  messages: OpenAI.ChatCompletionMessageParam[];
  jsonSchema: OpenAI.ResponseFormatJSONSchema['json_schema']['schema'];
}

export function isOpenAIQueryRequest(
  data: IOpenAIQueryData
): data is IOpenAIQueryData {
  return TypeGuard.interface<IOpenAIQueryData>({
    model: isString,
    messages: TypeGuard.arrayOf(
      TypeGuard.interface<OpenAI.ChatCompletionMessageParam>({
        role: isString,
        content: isString,
      })
    ),
    jsonSchema: isObject,
  })(data);
}
