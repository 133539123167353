<lu-page-simple
  class="flex h-full grow"
  *ngIf="pathway$ | async as pathway"
  marginBottom="16"
>
  <lu-page-header [label]="pathway.name">
    <lu-favourite-indicator *ngIf="isLocalPathway$ | async" [item]="pathway" />

    <lu-privacy-icon
      *ngIf="pathway.restrictAccessTo.length"
      [item]="pathway"
      size="24px"
    />

    <lu-read-only-icon *ngIf="pathway.readOnly" size="24px" />

    <lu-user-pathway-progress
      *ngIf="isLocalPathway$ | async"
      class="text-lg"
      [user]="user$ | async"
      [pathway]="pathway"
    />
  </lu-page-header>

  <lu-toolbar>
    <div
      fxFlex.gt-xs
      fxFill.lt-sm
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutGap.gt-xs="32px"
    >
      <lu-toolbar-segment>
        {{ pathway.updatedAt | toDate | date }}
        <label>Last Updated</label>
      </lu-toolbar-segment>

      <lu-toolbar-segment
        *ngIf="pathway.status === pathwayStatus.Draft; else otherStatuses"
      >
        <label>Status</label>
        <span
          class="rounded-lg bg-red-100 px-2 py-1 font-extrabold text-red-500"
        >
          {{ pathway.status | titlecase }}
        </span>
      </lu-toolbar-segment>
      <ng-template #otherStatuses>
        <lu-toolbar-segment>
          <label>Status</label>
          {{ pathway.status | titlecase }}
        </lu-toolbar-segment>
      </ng-template>

      <lu-toolbar-segment>
        {{ pathway.updatedAt | toDate | date }}
        <label>Last Updated</label>
      </lu-toolbar-segment>

      <ng-container *ngIf="pathway.author">
        <lu-toolbar-segment *ngIf="pathway.author | refItem$ | async as author">
          {{ author.name || 'Unknown' }}
          <label>Creator</label>
        </lu-toolbar-segment>
      </ng-container>

      <lu-toolbar-segment *ngIf="pathway.tags.length">
        <lu-tag-display [responsive]="false" [tagRefs]="pathway.tags" />
        <label>Tags</label>
      </lu-toolbar-segment>
    </div>

    <ng-container *luIsAdmin>
      <div
        class="flex items-center"
        [matTooltip]="copiedPathwayTooltip$ | async"
        [matTooltipDisabled]="(isAlreadyCopied$ | async) === false"
      >
        <button
          *ngIf="isMarketplaceVersion$ | async"
          mat-flat-button
          color="primary"
          [disabled]="isAlreadyCopied$ | async"
          (click)="copyToFolder(pathway)"
        >
          <mat-icon>drive_file_move</mat-icon>
          <span>Copy to Folder</span>
        </button>
      </div>

      <ng-container *ngIf="canEdit$ | async">
        <button
          mat-stroked-button
          [routerLink]="['/admin/pathways', pathway.ref.id]"
        >
          Edit
        </button>
      </ng-container>
    </ng-container>
  </lu-toolbar>

  <div class="flex grow flex-col gap-6">
    <lu-content-editor-view
      *ngIf="pathway.description"
      [content]="pathway.description"
      (contentError)="handleContentError('Problem showing description')"
    />

    <mat-drawer-container
      *ngIf="sidebar.isDesktop$ | async; else mobileLayout"
      class="skills -mx-4 grow"
    >
      <mat-drawer class="pt-4" mode="side" position="end" opened disableClose>
        <div class="w-[400px]">
          <ng-container
            [ngTemplateOutlet]="pathwayContentList"
            [ngTemplateOutletContext]="{ pathway }"
          />
        </div>
      </mat-drawer>
      <mat-drawer-content class="flex-1 grow bg-white">
        <router-outlet />
      </mat-drawer-content>
    </mat-drawer-container>

    <ng-template #mobileLayout>
      <div class="flex flex-col gap-2">
        <span class="font-bold">Pathway Content</span>
        <div class="rounded-lg bg-gray-100 p-2">
          <ng-container
            [ngTemplateOutlet]="pathwayContentList"
            [ngTemplateOutletContext]="{ pathway }"
          />
        </div>
      </div>
      <router-outlet />
    </ng-template>
  </div>
</lu-page-simple>

<ng-template #pathwayContentList let-pathway="pathway">
  <div class="mx-4">
    <div
      *ngIf="sections$ | async as sections"
      ptIntercomTarget="pathway.sections"
      class="flex flex-col gap-4"
    >
      <ng-container *ngIf="sections.length; else noSections">
        <div
          class="section"
          *ngFor="let section of sections; trackBy: trackBySection"
        >
          <lu-section-view
            [skillPrefix]="skillPrefix"
            [section]="section"
            [pathway]="pathway"
          />
        </div>
      </ng-container>
    </div>

    <ng-template #noSections>
      <div class="p-2 text-center">
        <div>No Sections</div>
      </div>
    </ng-template>
  </div>
</ng-template>
