import {
  type ISkill,
  SkillStatus,
  SKILL_STATUS_ORDER,
} from '../../skill/skill';
import {
  type IUserStatValue,
  reduceStatValues,
  statValue,
  type IUserStatisticsResult,
} from './user-statistics-result';
import {
  boolToNum,
  resolverBuilders,
  modifyUserStats,
  type ISkillPair,
} from './helpers';
import { isUserDocRef } from '../user';

type IBuilderFn = (skillPair: ISkillPair) => IUserStatValue[];

export function skillToStats(skillPair: ISkillPair): IUserStatisticsResult[] {
  return resolverBuilders(
    {
      skillsDrafted,
      skillsMarkedForReview,
      skillsApproved,
      reviewAndApprovals,
      reviewAndChangeRequests,
      totalReviewsGiven,
      requestedLevelUps,
      skillsAsReviewer,
      skillsAsReviewerCompleted,
      skillsAsReviewerPending,
    },
    (builder: IBuilderFn) => builder(skillPair)
  );
}

function skillsDrafted(skillPair: ISkillPair): IUserStatValue[] {
  if (isVendorSkill(skillPair.skill) || !isUserDocRef(skillPair.skill.author)) {
    return [];
  }
  const value = boolToNum(
    hasReachedSkillStatus(skillPair.skill, SkillStatus.Draft)
  );
  return [statValue(skillPair.skill.author, value)];
}

function skillsMarkedForReview(skillPair: ISkillPair): IUserStatValue[] {
  if (isVendorSkill(skillPair.skill) || !isUserDocRef(skillPair.skill.author)) {
    return [];
  }
  const value = boolToNum(
    hasReachedSkillStatus(skillPair.skill, SkillStatus.Review)
  );
  return [statValue(skillPair.skill.author, value)];
}

function skillsApproved(skillPair: ISkillPair): IUserStatValue[] {
  if (isVendorSkill(skillPair.skill) || !isUserDocRef(skillPair.skill.author)) {
    return [];
  }
  const value = boolToNum(
    hasReachedSkillStatus(skillPair.skill, SkillStatus.Published)
  );
  return [statValue(skillPair.skill.author, value)];
}

function reviewAndApprovals(skillPair: ISkillPair): IUserStatValue[] {
  const values = (skillPair.reviews || []).map((review) =>
    statValue(review.reviewer, boolToNum(review.isApproved))
  );
  return reduceStatValues(values);
}

function reviewAndChangeRequests(skillPair: ISkillPair): IUserStatValue[] {
  const values = (skillPair.reviews || []).map((review) =>
    statValue(review.reviewer, boolToNum(!review.isApproved))
  );
  return reduceStatValues(values);
}

function requestedLevelUps(skillPair: ISkillPair): IUserStatValue[] {
  const values = skillPair.levelUpRequests.map((request) =>
    statValue(request.userRef, boolToNum(true))
  );
  return reduceStatValues(values);
}

function totalReviewsGiven(skillPair: ISkillPair): IUserStatValue[] {
  return modifyUserStats(
    reviewAndApprovals(skillPair),
    reviewAndChangeRequests(skillPair),
    (approvals, changes) => approvals + changes
  );
}

function skillsAsReviewer(skillPair: ISkillPair): IUserStatValue[] {
  return skillPair.skill.reviewers.map((reviewer) =>
    statValue(reviewer, boolToNum(true))
  );
}

function skillsAsReviewerCompleted(skillPair: ISkillPair): IUserStatValue[] {
  if (!hasReachedSkillStatus(skillPair.skill, SkillStatus.Published)) {
    return [];
  }
  return skillPair.skill.reviewers.map((reviewer) =>
    statValue(reviewer, boolToNum(true))
  );
}

function skillsAsReviewerPending(skillPair: ISkillPair): IUserStatValue[] {
  return modifyUserStats(
    skillsAsReviewer(skillPair),
    skillsAsReviewerCompleted(skillPair),
    (reviewing, reviewsCompleted) => reviewing - reviewsCompleted
  );
}

function hasReachedSkillStatus(skill: ISkill, status: SkillStatus): boolean {
  const current = SKILL_STATUS_ORDER.indexOf(skill.status);
  const desired = SKILL_STATUS_ORDER.indexOf(status);
  return current >= desired;
}

function isVendorSkill(skill: ISkill): boolean {
  return skill.vendorSkillRef !== undefined;
}
