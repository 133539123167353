<button
  mat-icon-button
  [matMenuTriggerFor]="tags"
  (click)="$event.stopPropagation()"
  matTooltip="Tags"
  aria-label="Tags"
  [disabled]="disabled"
>
  <mat-icon>local_offer</mat-icon>
</button>
<mat-menu #tags>
  <div class="layout-padding tags-wrapper">
    <ng-content select="lu-tag-display"></ng-content>
  </div>
</mat-menu>
