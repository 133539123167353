import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  IFolder,
  IntercomEvent,
  MentionResourceType,
  Skill,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { DocumentReference, addDoc, snapshot } from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { AuthorService } from '../../pages/skills/author/author.service';
import { OrganisationService } from '../../services/organisation.service';
import {
  type ISkillAddDialogData,
  SkillAddDialogComponent,
} from '../skills/skill-add-dialog/skill-add-dialog.component';
import { type SkillFormData } from '../skills/skill-edit-form/skill-form-group';
import { type ISearchAction } from './search-action';

@Injectable()
export class CreateSkillActionService implements ISearchAction {
  icon = 'add';
  name = 'Create Skill';
  type = MentionResourceType.Skill;

  constructor(
    private _dialog: BasicDialogService,
    private _organisation: OrganisationService,
    private _intercom: Intercom,
    private _author: AuthorService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  async do(
    searchValue?: string,
    folderRef?: DocumentReference<IFolder>
  ): Promise<void> {
    const author = await snapshot(this._author.user$);
    const data: MatDialogConfig<ISkillAddDialogData> = {
      data: { author: author.ref, name: searchValue, folderRef },
    };

    const skill = await this._dialog.mobileFullscreen<
      SkillAddDialogComponent,
      ISkillAddDialogData,
      SkillFormData
    >(SkillAddDialogComponent, DialogPresets.small(data));

    if (!skill) {
      return;
    }

    const skillRef = await addDoc(
      await snapshot(this._organisation.skillCol$),
      Skill.init({
        ...skill,
        author: skill.author ?? author.ref,
      })
    );

    this._intercom.trackEvent(IntercomEvent.AddedSkill, {
      name: skill.name,
    });

    await this._router.navigate(['/create', skillRef.id]);
    this._snackBar.open('Skill Added');
  }
}
