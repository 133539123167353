import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  type IOrganisation,
  type IUser,
  Organisation,
} from '@principle-theorem/level-up-core';
import {
  type WithRef,
  filterUndefined,
  findProp,
  getAll,
} from '@principle-theorem/shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lu-organisation-edit',
    templateUrl: './organisation-edit.component.html',
    styleUrls: ['./organisation-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrganisationEditComponent {
  organisation$: Observable<WithRef<IOrganisation>>;
  users$: Observable<WithRef<IUser>[]>;
  userCollection$: Observable<CollectionReference<IUser>>;

  constructor(private _route: ActivatedRoute) {
    this.organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined()
    );

    this.userCollection$ = this.organisation$.pipe(
      map((organisation) => Organisation.userCol(organisation))
    );

    this.users$ = this.userCollection$.pipe(
      getAll(),
      map((users) => sortBy(users, 'name'))
    );
  }
}
