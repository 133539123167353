import { count, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ISkill } from './models/skill/skill';
import {
  findProgressBySkill,
  skillGoalAchieved,
  skillHasGoal,
} from './models/skill/skill-progress';
import { type IUserGroup } from './models/user-group/user-group';
import { User, type IUser } from './models/user/user';
import { StatView } from './stats/stat-view';

export class UserGoalsProgress {
  skills: StatView;

  constructor(
    usersGroups$: Observable<WithRef<IUserGroup>[]>,
    user$: Observable<WithRef<IUser>>,
    filterSkills$: Observable<WithRef<ISkill>[]>
  ) {
    const progress$ = user$.pipe(
      switchMap((user) => User.skillProgress$(user))
    );

    const goals$ = combineLatest([user$, usersGroups$]).pipe(
      map(([user, userGroup]) => User.getGoals(user, userGroup))
    );

    const completedSkills$ = combineLatest([
      filterSkills$,
      goals$,
      progress$,
    ]).pipe(
      map(([skills, goals, progress]) =>
        skills.filter((skill) =>
          skillGoalAchieved(goals, findProgressBySkill(skill, progress))
        )
      ),
      count()
    );

    const totalSkills$ = combineLatest([filterSkills$, goals$]).pipe(
      map(([skills, goals]) =>
        skills.filter((skill) => skillHasGoal(goals, skill))
      ),
      count()
    );

    this.skills = new StatView(completedSkills$, totalSkills$);
  }
}
