import {
  FEATURE_DEFAULTS,
  type IFeature,
  FeatureVisibility,
} from '@principle-theorem/feature-flags';

export const ACHIEVEMENTS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'achievements',
  visibility: FeatureVisibility.Dev,
};
