import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IUser,
  SkillLevel,
  SkillLevelRequestStatus,
  ISkill,
} from '@principle-theorem/level-up-core';
import {
  filterUndefined,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UserSkillProgress } from '../../models/user-skill-progress';
import { OrganisationService } from '../../services/organisation.service';
import { SkillProgressUpdaterService } from '../../services/skill-progress-updater.service';

@Component({
  selector: 'lu-user-skill-progress',
  templateUrl: './user-skill-progress.component.html',
  styleUrls: ['./user-skill-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSkillProgressComponent {
  private _user$ = new ReplaySubject<WithRef<IUser>>(1);
  private _skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  canEdit$ = new BehaviorSubject<boolean>(false);
  isRequest$ = new BehaviorSubject<boolean>(false);
  progress: UserSkillProgress;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this._skill$.next(skill);
    }
  }

  @Input()
  set canEdit(canEdit: BooleanInput) {
    this.canEdit$.next(coerceBooleanProperty(canEdit));
  }

  @Input()
  set isRequest(isRequest: BooleanInput) {
    this.isRequest$.next(coerceBooleanProperty(isRequest));
  }

  constructor(
    private _organisation: OrganisationService,
    private _progressUpdater: SkillProgressUpdaterService
  ) {
    this.progress = new UserSkillProgress(
      this._organisation.userGroups$,
      this._user$,
      this._skill$
    );
  }

  async setProgressLevel(level: SkillLevel): Promise<void> {
    const user = await snapshot(this._user$);
    const skill = await snapshot(this._skill$);
    await this._progressUpdater.upsert(user, skill, level);
    await this._removeSkillLevelRequest();
  }

  async resetProgress(): Promise<void> {
    await this.setProgressLevel(SkillLevel.None);
  }

  private async _removeSkillLevelRequest(): Promise<void> {
    const request = await snapshot(this.progress.request$);
    const mentor = await snapshot(
      this._organisation.user$.pipe(filterUndefined())
    );
    if (!request) {
      return;
    }
    await patchDoc(request.ref, {
      status: SkillLevelRequestStatus.Approved,
      mentorRef: mentor.ref,
    });
  }
}
