<div fxLayout="column" fxLayout.gt-xs="row">
  <lu-user-action
    fxFlex="33"
    heading="Incomplete Drafts"
    [count]="stats.skills.draft.current$ | async"
    routeLabel="Create"
    [route]="['/create/draft']"
   />

  <div class="divider"></div>

  <lu-user-action
    fxFlex="33"
    heading="Awaiting Review"
    [count]="stats.skills.review.current$ | async"
    routeLabel="Review"
    [route]="['/create/review']"
   />

  <div class="divider"></div>

  <lu-user-action
    fxFlex="33"
    heading="Published"
    [count]="stats.skills.approved.current$ | async"
    routeLabel="Show Skills"
    [route]="['/create/published']"
   />
</div>
