import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IPathway,
  UserGroup,
  type IUserGroup,
  SkillLevel,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  multiMap,
  patchDoc,
  saveDoc,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
  selector: 'lu-pathway-group-association',
  templateUrl: './pathway-group-association.component.html',
  styleUrls: ['./pathway-group-association.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayGroupAssociationComponent {
  trackByGroup = TrackByFunctions.ref<WithRef<IUserGroup>>();
  pathway$ = new ReplaySubject<WithRef<IPathway>>(1);
  groups$: Observable<WithRef<IUserGroup>[]>;
  existingGroups$: Observable<DocumentReference<IUserGroup>[]>;

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this.groups$ = combineLatest([
      this.pathway$,
      this._organisation.userGroups$,
    ]).pipe(
      map(([pathway, groups]) => {
        return groups.filter((group) =>
          UserGroup.hasPathwayAssociation(group, pathway)
        );
      })
    );

    this.existingGroups$ = combineLatest([
      this.pathway$,
      this._organisation.userGroups$,
    ]).pipe(
      map(([pathway, groups]) =>
        groups.filter((group) =>
          UserGroup.hasPathwayAssociation(group, pathway)
        )
      ),
      multiMap((group) => group.ref)
    );
  }

  async addGroup(
    group: WithRef<IUserGroup>,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    await saveDoc(
      UserGroup.addPathwayAssociation(group, pathway, SkillLevel.Viewed)
    );
    this._snackBar.open(`Pathway added to ${group.name} Group`);
  }

  async removeGroup(
    group: WithRef<IUserGroup>,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    await patchDoc(group.ref, {
      pathwayAssociations: UserGroup.removePathway(group, pathway.ref),
    });
    this._snackBar.open(`Pathway removed from ${group.name} Group`);
  }
}
