import {
  asyncForEach,
  getDoc,
  isEnumValue,
  toMoment,
  TypeGuard,
  type WithRef,
} from '@principle-theorem/shared';
import { isArray, isBoolean, isNumber, isString, isUndefined } from 'lodash';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { type IVendor } from '../marketplace/vendor';
import { type ISkill, SkillStatus } from '../skill/skill';
import { type IUser } from '../user/user';
import { LevelUpTypesenseCollection } from './typesense';

export const SKILL_COLLECTION_SCHEMA: CollectionCreateSchema = {
  name: LevelUpTypesenseCollection.Skills,
  fields: [
    {
      name: 'id',
      type: 'string',
    },
    {
      name: 'createdAt',
      type: 'int64',
    },
    {
      name: 'name',
      type: 'string',
      sort: true,
    },
    {
      name: 'authorName',
      type: 'string',
      facet: true,
    },
    {
      name: 'readOnly',
      type: 'bool',
    },
    {
      name: 'status',
      type: 'string',
      facet: true,
    },
    {
      name: 'tagNames',
      type: 'string[]',
    },
    {
      name: 'tagRefs',
      type: 'string[]',
    },
    {
      name: 'ref',
      type: 'string',
    },
    {
      name: 'deleted',
      type: 'bool',
      facet: true,
    },
    {
      name: 'restrictAccessTo',
      type: 'string[]',
    },
    {
      name: 'authorProfileImageUrl',
      type: 'string',
      index: false,
      optional: true,
    },
    {
      name: 'authorRef',
      type: 'string',
      index: false,
    },
    {
      name: 'vendorSkillRef',
      type: 'string',
      index: false,
      optional: true,
    },
  ],
  default_sorting_field: 'createdAt',
};

export interface ISkillIndexedProperties {
  id: string;
  name: string;
  createdAt: number;
  authorName: string;
  readOnly: boolean;
  status: SkillStatus;
  tagNames: string[];
  tagRefs: string[];
  restrictAccessTo: string[];
  ref: string;
  deleted: boolean;
}

export interface ITypesenseSkill extends ISkillIndexedProperties {
  authorProfileImageUrl: string | undefined;
  authorRef: string;
  vendorSkillRef: string | undefined;
}

export class TypesenseSkill {
  static async fromSkill(
    skill: WithRef<ISkill>,
    author: WithRef<IUser | IVendor>
  ): Promise<ITypesenseSkill> {
    const tagNames = await asyncForEach(skill.tags, async (tagRef) => {
      const tag = await getDoc(tagRef);
      return tag.name;
    });

    return {
      id: skill.ref.id,
      name: skill.name,
      createdAt: toMoment(skill.createdAt).unix(),
      authorName: author.name,
      readOnly: skill.readOnly,
      status: skill.status,
      deleted: skill.deleted,
      authorProfileImageUrl: author.profileImageURL || '',
      tagNames,
      tagRefs: skill.tags.map((tag) => tag.path),
      restrictAccessTo: skill.restrictAccessTo.map((doc) => doc.path),
      ref: skill.ref.path,
      authorRef: author.ref.path,
      vendorSkillRef: skill.vendorSkillRef
        ? skill.vendorSkillRef.path
        : undefined,
    };
  }
}

export function isTypesenseSkill(item: unknown): item is ITypesenseSkill {
  return TypeGuard.interface<ITypesenseSkill>({
    id: isString,
    name: isString,
    ref: isString,
    createdAt: isNumber,
    authorName: isString,
    readOnly: isBoolean,
    status: (value: unknown): value is SkillStatus =>
      isEnumValue(SkillStatus, value),
    deleted: isBoolean,
    authorProfileImageUrl: isString,
    tagNames: TypeGuard.arrayOf(isString),
    tagRefs: TypeGuard.arrayOf(isString),
    restrictAccessTo: isArray,
    authorRef: [isString, isUndefined],
    vendorSkillRef: [isString, isUndefined],
  })(item);
}
