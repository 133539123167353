import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Signal,
  inject,
} from '@angular/core';
import { type IUser } from '@principle-theorem/level-up-core';
import { AuthService, WorkspaceService } from '@principle-theorem/ng-auth';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { slugify } from '@principle-theorem/shared';

@Component({
    selector: 'lu-user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserProfileMenuComponent {
  profileImage = inject(ProfileImageService);
  canChangeWorkspace$: Observable<boolean>;
  slug: Signal<string | undefined>;
  @Input() user: IUser;

  constructor(
    public auth: AuthService,
    workspace: WorkspaceService,
    public organisation: OrganisationService
  ) {
    this.canChangeWorkspace$ = workspace.workspaces$.pipe(
      map((workspaces) => workspaces.length > 1)
    );

    this.slug = toSignal(
      workspace.workspace$.pipe(
        map((currentWorkspace) => slugify(currentWorkspace ?? ''))
      )
    );
  }
}
