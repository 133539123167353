import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ITag } from '@principle-theorem/level-up-core';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { startWith, takeUntil, map } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { type INamedDocsToTags, NamedDocsToTags } from '../named-docs-to-tags';
import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { MediaMatcher } from '@angular/cdk/layout';
import { matchMedia$, TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'lu-tag-display',
    templateUrl: './tag-display.component.html',
    styleUrls: ['./tag-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagDisplayComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  tags$: Observable<WithRef<ITag>[]>;
  tagRefs$: ReplaySubject<DocumentReference<ITag>[]> = new ReplaySubject(1);
  limit$: Observable<number>;
  @Input() fontSize = 'inherit';
  @Input() responsive = true;
  @Input() compact = true;

  constructor(cachedLists: CachedListsService, media: MediaMatcher) {
    this.limit$ = matchMedia$(media.matchMedia('(max-width: 600px)')).pipe(
      map((isMobile) => (isMobile ? 3 : 5)),
      takeUntil(this._onDestroy$)
    );
    const namedDocsToTags: INamedDocsToTags = new NamedDocsToTags(
      this.tagRefs$,
      cachedLists.tags$
    );
    this.tags$ = namedDocsToTags.tags$.pipe(startWith([]));
  }

  @Input()
  set tagRefs(tagRefs: DocumentReference<ITag>[]) {
    this.tagRefs$.next(tagRefs);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }
}
