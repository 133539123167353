import { type ComponentType } from '@angular/cdk/portal';
import { type ISearchAction } from '../../components/search-dialog/search-action';

export interface ILink {
  name: string;
  path: string[] | string;
  icon?: string;
  links?: ILink[];
  description?: string;
  intercomTarget?: string;
  action?: ComponentType<ISearchAction>;
}

export interface ILinkGroup {
  name: string;
  icon?: string;
  links: ILink[];
}

export const DASHBOARDS_GROUP: ILinkGroup = {
  name: '',
  links: [{ name: 'Home', path: ['/home'] }],
};

export const SKILLS_LINK_GROUP: ILinkGroup = {
  name: '',
  links: [
    {
      name: 'Explore',
      icon: 'menu_book',
      path: ['/explore'],
      description: 'Explore all Skills and Pathways',
    },
    {
      name: 'Marketplace',
      icon: 'storefront',
      path: ['/marketplace'],
      description: 'Explore Marketplace content and make it your own!',
    },
  ],
};

export const ADMIN_LINK_GROUP: ILinkGroup = {
  name: 'Admin',
  icon: 'settings',
  links: [
    { name: 'Users', icon: 'person', path: ['/admin', 'users'] },
    { name: 'Teams', icon: 'group', path: ['/admin', 'teams'] },
    { name: 'Pathways', icon: 'fact_check', path: ['/admin', 'pathways'] },
    { name: 'Templates', icon: 'text_snippet', path: ['/admin', 'templates'] },
    { name: 'Tags', icon: 'sell', path: ['/admin', 'tags'] },
  ],
};

export const MANAGEMENT_LINK_GROUP: ILinkGroup = {
  name: 'Management',
  icon: 'manage_accounts',
  links: [
    { name: 'Organisations', path: ['/management', 'organisations'] },
    { name: 'Marketplace', path: ['/management', 'marketplace'] },
    { name: 'Tools', path: ['/management', 'tools'] },
  ],
};

export const MARKETPLACE_LINK_GROUP: ILinkGroup = {
  name: 'Marketplace',
  icon: 'settings',
  links: [
    { name: 'Home', icon: 'home', path: ['/marketplace/account', 'home'] },
    {
      name: 'Bundles',
      icon: 'storefront',
      path: ['/marketplace/account', 'bundles'],
    },
    { name: 'Users', icon: 'group', path: ['/marketplace/account', 'users'] },
    {
      name: 'Profile',
      icon: 'account_box',
      path: ['/marketplace/account', 'profile'],
    },
  ],
};
