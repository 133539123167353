import { Injectable } from '@angular/core';
import {
  type IPathway,
  type ISkill,
  type ISkillProgress,
  type IUser,
  type IUserGroup,
  Pathway,
  User,
  UserGroup,
} from '@principle-theorem/level-up-core';
import {
  type DocumentReference,
  where,
  patchDoc,
} from '@principle-theorem/shared';
import {
  query$,
  reduceToSingleArray,
  saveDoc,
  type WithRef,
  deleteDoc,
} from '@principle-theorem/shared';
import { combineLatest, forkJoin, type Observable, of, zip } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { CachedListsService } from './cached-lists.service';
import { OrganisationService } from './organisation.service';
import { SkillAttachmentsService } from './skill-attachments.service';

@Injectable({
  providedIn: 'root',
})
export class DeleteSkillService {
  constructor(
    private _organisation: OrganisationService,
    private _skillAttachments: SkillAttachmentsService,
    private _cachedLists: CachedListsService
  ) {}

  async deleteSkill(skill: WithRef<ISkill>): Promise<void> {
    return zip(
      this._removeSkillFromUsers$(skill),
      this._removeSkillFromPathways$(skill),
      this._removeSkillFromUserGroups$(skill),
      this._skillAttachments.deleteSkillAttachments$(skill)
    )
      .pipe(
        switchMap(() => deleteDoc(skill.ref)),
        take(1)
      )
      .toPromise();
  }

  private _removeSkillFromPathways$(
    skill: WithRef<ISkill>
  ): Observable<DocumentReference<Partial<IPathway>>[]> {
    return this._cachedLists.pathways$.pipe(
      mergeMap((pathways) => {
        const filtered = pathways
          .filter((pathway) => Pathway.hasSkillAssociation(pathway, skill))
          .map((pathway) => {
            const { sections } = Pathway.removeSkillAssociation(pathway, skill);
            return patchDoc(pathway.ref, { sections });
          });

        if (!filtered.length) {
          return of([]);
        }
        return forkJoin(filtered);
      })
    );
  }

  private _removeSkillFromUserGroups$(
    skill: WithRef<ISkill>
  ): Observable<DocumentReference<IUserGroup>[]> {
    return this._organisation.userGroups$.pipe(
      mergeMap((groups: WithRef<IUserGroup>[]) => {
        const filtered = groups
          .filter((group: WithRef<IUserGroup>) =>
            UserGroup.hasSkillAssociation(group, skill)
          )
          .map((group: WithRef<IUserGroup>) => {
            UserGroup.removeSkillAssociation(group, skill);
            return saveDoc(group);
          });

        if (!filtered.length) {
          return of([]);
        }
        return forkJoin(filtered);
      })
    );
  }

  private _removeSkillFromUsers$(skill: WithRef<ISkill>): Observable<void[]> {
    return this._cachedLists.users$.pipe(
      switchMap((users: WithRef<IUser>[]) => {
        const userSkills$: Observable<WithRef<ISkillProgress>[]>[] = users.map(
          (user) =>
            query$(
              User.skillProgressCol(user),
              where('skillRef.ref', '==', skill.ref)
            )
        );
        return combineLatest(userSkills$);
      }),
      map(reduceToSingleArray),
      mergeMap((skills) => {
        if (!skills.length) {
          return of([]);
        }
        return forkJoin(skills.map((item) => deleteDoc(item.ref)));
      })
    );
  }
}
