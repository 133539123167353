import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MediaObserver } from 'ng-flex-layout';
import { shareReplayCold } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DesktopExpandedState,
  DesktopMinimisedState,
  type ISidebarState,
  MobileClosedState,
  MobileOpenState,
  type SideBarMode,
} from './sidebar-states';

@Injectable({
  providedIn: 'root',
})
export class SidebarManagerService {
  private readonly _animationDelayMs: number = 400;
  private _lastDesktopState: ISidebarState = new DesktopExpandedState();
  state$: BehaviorSubject<ISidebarState> = new BehaviorSubject<ISidebarState>(
    new MobileClosedState()
  );
  animations$: Observable<Promise<unknown>>;
  isMobile$: Observable<boolean>;
  isDesktop$: Observable<boolean>;
  mode$: Observable<SideBarMode>;
  expanded$: Observable<boolean>;
  opened$: Observable<boolean>;

  constructor(
    _mediaMatcher: MediaMatcher,
    private _mediaObserver: MediaObserver
  ) {
    this.isMobile$ = this.state$.pipe(
      map(
        (state) =>
          state instanceof MobileOpenState || state instanceof MobileClosedState
      ),
      shareReplayCold()
    );

    this.isDesktop$ = this.isMobile$.pipe(
      map((isMobile) => !isMobile),
      shareReplayCold()
    );
    this.animations$ = this.state$.pipe(map(() => this._animationComplete()));
    this._updateSidebar();
    this._mediaObserver.asObservable().subscribe(() => this._updateSidebar());
    this.mode$ = this.state$.pipe(
      map((state) => state.mode),
      shareReplayCold()
    );
    this.expanded$ = this.state$.pipe(
      map((state) => state.expanded),
      shareReplayCold()
    );
    this.opened$ = this.state$.pipe(
      map((state) => state.opened),
      shareReplayCold()
    );
  }

  get state(): ISidebarState {
    return this.state$.value;
  }

  get isMobile(): boolean {
    return (
      this.state instanceof MobileOpenState ||
      this.state instanceof MobileClosedState
    );
  }

  open(): void {
    this.state$.next(new MobileOpenState());
  }

  close(): void {
    this.state$.next(new MobileClosedState());
  }

  expand(): void {
    this._lastDesktopState = new DesktopExpandedState();
    this.state$.next(this._lastDesktopState);
  }

  minimise(): void {
    this._lastDesktopState = new DesktopMinimisedState();
    this.state$.next(this._lastDesktopState);
  }

  toMobile(): void {
    this.close();
  }

  toDesktop(): void {
    this.state$.next(this._lastDesktopState);
  }

  toggleExpanded(): void {
    if (this.state.expanded) {
      return this.minimise();
    }
    this.expand();
  }

  toggleOpen(): void {
    if (this.state.opened) {
      return this.close();
    }
    this.open();
  }

  private _animationComplete(): Promise<void> {
    return new Promise((resolve: () => void) => {
      setTimeout(resolve, this._animationDelayMs);
    });
  }

  private _updateSidebar(): void {
    if (this._mediaObserver.isActive('gt-lg')) {
      return this.expand();
    }
    if (this._mediaObserver.isActive('gt-md')) {
      return this.minimise();
    }
    this.toMobile();
  }
}
