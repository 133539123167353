import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  inject,
  Output,
} from '@angular/core';
import { type IUser } from '@principle-theorem/level-up-core';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  isSameRef,
  multiFilter,
  multiFind,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CachedListsService } from '../../services/cached-lists.service';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'lu-assignee-user-selector',
  templateUrl: './assignee-user-selector.component.html',
  styleUrls: ['./assignee-user-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneeUserSelectorComponent {
  profileImage = inject(ProfileImageService);
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  users$ = new ReplaySubject<WithRef<IUser>[]>(1);
  userName$: Observable<string>;
  userImage$: Observable<string | undefined>;
  selectedUser$ = new ReplaySubject<DocumentReference<IUser> | undefined>(1);
  @Output() userSelected = new EventEmitter<WithRef<IUser> | undefined>();

  @Input() disabled = false;
  @Input() disableTooltip = false;
  @Input() multiSelect = false;
  @Input() selectTooltip = 'Select User';
  @Input() changeTooltip = 'Select User';
  @Input() clearTooltip = 'Clear User';
  @Input() clearSelectedTooltip = 'Clear Selected User';
  @Input() emptyUsersMessage = 'No Users Available';
  @Input() disableClear = false;

  @Input()
  set selectedUser(selectedUser: DocumentReference<IUser> | undefined) {
    this.selectedUser$.next(selectedUser);
  }

  @Input()
  set users(users: WithRef<IUser>[]) {
    if (users) {
      this.users$.next(users);
    }
  }

  constructor(cachedLists: CachedListsService) {
    const resolvedUser$ = this.selectedUser$.pipe(
      switchMap((user) =>
        user
          ? cachedLists.users$.pipe(
              multiFilter((cachedUser) => cachedUser.isActivated),
              multiFind((cachedUser) => isSameRef(cachedUser, user))
            )
          : of(undefined)
      )
    );

    this.userImage$ = resolvedUser$.pipe(
      switchMap((user) =>
        user ? this.profileImage.getUserProfileImage$(user) : of(undefined)
      )
    );
    this.userName$ = resolvedUser$.pipe(map((user) => user?.name ?? 'NA'));
  }

  selectUser(selected: MatListOption[]): void {
    const user = selected[0]?.value as WithRef<IUser> | undefined;
    this.userSelected.emit(user);
    this.selectedUser$.next(user?.ref);
  }

  clearAssignee(): void {
    this.userSelected.emit(undefined);
    this.selectedUser$.next();
  }

  isSelected$(user: WithRef<IUser>): Observable<boolean> {
    return this.selectedUser$.pipe(
      map((selectedUser) => isSameRef(selectedUser, user))
    );
  }
}
