import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { type IWithPermissions } from '@principle-theorem/level-up-core';
import { multiFilter } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';

@Component({
  selector: 'lu-privacy-icon',
  templateUrl: './privacy-icon.component.html',
  styleUrls: ['./privacy-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyIconComponent {
  allowedGroupsSummary$: Observable<string | undefined>;
  item$ = new ReplaySubject<IWithPermissions>(1);
  @Input()
  size: string = '16px';

  @Input()
  @HostBinding('style.color')
  colour: string;

  @Input()
  set item(item: IWithPermissions) {
    if (item) {
      this.item$.next(item);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.allowedGroupsSummary$ = this.item$.pipe(
      switchMap((item) => {
        const groupsWithAccess = item.restrictAccessTo.map(
          (group) => group.path
        );
        return this._organisation.userGroups$.pipe(
          multiFilter((group) => groupsWithAccess.includes(group.ref.path))
        );
      }),
      map((groups) => {
        if (!groups.length) {
          return;
        }
        const groupSummary = groups.map((group) => group.name).join(', ');
        return `Access restricted to: ${groupSummary}`;
      })
    );
  }
}
