import {
  SerialisedMixedSchema,
  isMixedSchema,
  isSerialisedMixedSchema,
  serialiseSchemaContent,
  unserialiseMixedSchemaContent,
} from '@principle-theorem/editor';
import { TransformEvent, type ITransformer } from '@principle-theorem/shared';
import { isSkill, type ISkill } from './skill';

export interface ISerialisedSkill extends Omit<ISkill, 'content'> {
  content: SerialisedMixedSchema;
}

export class SkillSerialiser implements ITransformer<ISkill, ISerialisedSkill> {
  events = [TransformEvent.ToFirestore];

  guard(item: unknown): item is ISkill {
    return isSkill(item) && isMixedSchema(item.content);
  }

  transform(item: ISkill): ISerialisedSkill {
    const content = serialiseSchemaContent(item.content);
    return {
      ...item,
      content,
    };
  }
}

export class SkillUnserialiser
  implements ITransformer<ISerialisedSkill, ISkill>
{
  events = [TransformEvent.FromFirestore];

  guard(item: unknown): item is ISerialisedSkill {
    return isSkill(item) && isSerialisedMixedSchema(item.content);
  }

  transform(item: ISerialisedSkill): ISkill {
    const content = unserialiseMixedSchemaContent(item.content);
    return {
      ...item,
      content,
    };
  }
}
