import {
  type IGoals,
  type IPathway,
  type ISkillLevelRequirement,
  SkillLevel,
  updateSkillLevelRequirement,
  UserGroup,
  ISkill,
  IPathwayAssociation,
  Pathway,
} from '@principle-theorem/level-up-core';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  asyncReduce,
  getDoc,
  isSameRef,
  multiMap,
  multiSwitchMap,
  reduceToSingleArray,
  type Reffable,
  shareReplayHot,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { xorBy } from 'lodash';
import { type Observable, Subject } from 'rxjs';
import { map, scan, startWith, switchMap, take } from 'rxjs/operators';

export class GoalSettingBloc {
  private _changes$ = new Subject<Partial<IGoals>>();
  goals$: Observable<IGoals>;

  constructor(
    private _initialGoals$: Observable<IGoals>,
    destroy$: Observable<void>
  ) {
    this.goals$ = this._initialGoals$.pipe(
      take(1),
      switchMap((initialGroup) =>
        this._changes$.pipe(
          scan(
            (accumulated, changes) => ({ ...accumulated, ...changes }),
            initialGroup
          ),
          startWith(initialGroup)
        )
      ),
      shareReplayHot(destroy$)
    );
  }

  async addSkill(skill: WithRef<ISkill>): Promise<void> {
    const goals = await snapshot(this.goals$);
    const { skillAssociations, levelRequirements } =
      UserGroup.addSkillAssociation(goals, skill);

    const updatedSkillLevels = await this._initialiseLevelRequirements(
      goals.levelRequirements,
      levelRequirements
    );

    this._changes$.next({
      skillAssociations,
      levelRequirements: updatedSkillLevels,
    });
  }

  async removeSkill(skillRef: DocumentReference<ISkill>): Promise<void> {
    const goals = await snapshot(this.goals$);
    this._changes$.next({
      levelRequirements: await this._updateLevelRequirements(skillRef, goals),
      skillAssociations: UserGroup.removeSkill(goals, skillRef),
    });
  }

  async addPathway(pathway: Reffable<IPathway>): Promise<void> {
    const goals = await snapshot(this.goals$);
    const { pathwayAssociations, levelRequirements } =
      UserGroup.addPathwayAssociation(goals, pathway, SkillLevel.Viewed);

    const updatedSkillLevels = await this._initialiseLevelRequirements(
      goals.levelRequirements,
      levelRequirements
    );

    this._changes$.next({
      pathwayAssociations,
      levelRequirements: updatedSkillLevels,
    });
  }

  async removePathway(pathwayRef: DocumentReference<IPathway>): Promise<void> {
    const goals = await snapshot(this.goals$);
    const pathwayAssociations = goals.pathwayAssociations.filter(
      (pathwayAssociation) => !isSameRef(pathwayAssociation, pathwayRef)
    );
    this._changes$.next({ pathwayAssociations });
  }

  updateSkillLevels(levelRequirements: ISkillLevelRequirement[]): void {
    this._changes$.next({ levelRequirements });
  }

  updatePathwayLevels(pathwayAssociations: IPathwayAssociation[]): void {
    this._changes$.next({ pathwayAssociations });
  }

  private async _updateLevelRequirements(
    skillRef: DocumentReference<ISkill>,
    goals: IGoals
  ): Promise<ISkillLevelRequirement[]> {
    const goalSkills = await snapshot(this._getGoalSkills$());
    if (goalSkills.includes(skillRef.path)) {
      return goals.levelRequirements;
    }
    return goals.levelRequirements.filter(
      (levelRequirement) => !isSameRef(levelRequirement.skill, skillRef)
    );
  }

  private _getGoalSkills$(): Observable<string[]> {
    return this.goals$.pipe(
      map((goals) => goals.pathwayAssociations),
      multiSwitchMap((pathwayRef) => getDoc(pathwayRef.ref)),
      multiMap((pathway) => Pathway.skillRefs(pathway)),
      map(reduceToSingleArray),
      multiMap((skillRef) => skillRef.path)
    );
  }

  private async _initialiseLevelRequirements(
    currentLevelRequirements: ISkillLevelRequirement[],
    addedLevelRequirements: ISkillLevelRequirement[]
  ): Promise<ISkillLevelRequirement[]> {
    const newLevelRequirements = xorBy(
      currentLevelRequirements,
      addedLevelRequirements,
      (levelRequirement) => levelRequirement.skill.ref.path
    );

    return asyncReduce(
      newLevelRequirements,
      async (skillLevels, skillLevel) => {
        const skill = await getDoc(skillLevel.skill.ref);
        return updateSkillLevelRequirement(
          skillLevel.skill,
          {
            level: skill.requiresTrainerVerification
              ? SkillLevel.VerifiedByTrainer
              : SkillLevel.Viewed,
          },
          skillLevels
        );
      },
      addedLevelRequirements
    );
  }
}
