import { Injectable } from '@angular/core';
import { type UrlTree } from '@angular/router';
import { type IUser } from '@principle-theorem/level-up-core';
import { filterUndefined, snapshot } from '@principle-theorem/shared';
import { OrganisationService } from '../services/organisation.service';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard extends AuthGuard {
  constructor(private _organisation: OrganisationService, auth: AuthService) {
    super(auth);
  }

  async canActivate(): Promise<boolean | UrlTree> {
    const user: IUser = await snapshot(
      this._organisation.user$.pipe(filterUndefined())
    );
    return user.isAdmin;
  }
}
