<div fxLayout="row">
  <mat-form-field fxFill>
    <input
      matInput
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [formControl]="groupFilter"
    />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="groupSelected($event.option.value)"
  >
    <mat-option
      *ngFor="let group of groupSearch.results$ | async; trackBy: trackByGroup"
      [value]="group"
    >
      {{ group.name }}
    </mat-option>
  </mat-autocomplete>
</div>
