@for (review of reviews$ | async; track review.ref.id) {
  <div
    [ngClass]="{
      'review-comment': !review.isApproved,
      'review-approved': review.isApproved
    }"
  >
    <div fxLayout="column">
      <div
        class="layout-padding review-comment-header"
        [ngClass]="{
          'no-comment-header': !review.comments
        }"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <ng-container *ngIf="review.reviewer | refItem$ | async as reviewerDoc">
          <div>
            <span class="mat-body-strong">
              {{ reviewerDoc.name || 'Unknown' }}
            </span>
            {{ review.isApproved ? 'approved' : 'requested changes' }}
          </div>
          <div class="mat-small">
            {{ review.createdAt | toDate | date }}
          </div>
        </ng-container>
      </div>
      <lu-content-editor-view
        fxFlex
        *ngIf="review.comments"
        class="layout-padding"
        [content]="review.comments"
        (contentError)="
          handleContentError.emit('Issue rendering review comment')
        "
      />
    </div>
  </div>
}
