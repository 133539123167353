<div fxLayout="column" fxLayout.gt-xs="row">
  <lu-user-action
    fxFlex="25"
    heading="In Draft/Review"
    [count]="stats.author.approved.remaining$ | async"
    routeLabel="Create"
    [route]="['/create/draft']"
   />

  <div class="divider"></div>

  <lu-user-action
    fxFlex="25"
    heading="Awaiting Review"
    [count]="stats.reviewer.awaitingReview.remaining$ | async"
    routeLabel="Review"
    [route]="['/create/review']"
   />

  <div class="divider"></div>

  <lu-user-action
    fxFlex="25"
    heading="Skills Remaining"
    [count]="stats.goals.skills.remaining$ | async"
    routeLabel="Learn"
    [route]="['/learn']"
   />

  <div class="divider"></div>

  <lu-user-action
    fxFlex="25"
    heading="Awaiting Training"
    [count]="stats.mentor.requestedLevelUps.remaining$ | async"
    routeLabel="Train"
    [route]="['/teach']"
   />
</div>
