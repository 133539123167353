<div
  class="selector-container"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="8px"
  ptIntercomTarget="goal-level-selector"
>
  <mat-icon
    *ngIf="isReadEnabled$ | async"
    [ngClass]="{ active: (goal$ | async) === type.Viewed }"
    [matTooltip]="type.Viewed | titlecase"
    (click)="levelChange.emit(type.Viewed)"
    ptIntercomTarget="goal-level-selector-viewed"
  >
    check_circle
  </mat-icon>

  <ng-container *ngIf="isTrainEnabled$ | async">
    <div
      class="verified-goal-icon"
      [matTooltip]="type.VerifiedByTrainer | splitCamel | titlecase"
      (click)="levelChange.emit(type.VerifiedByTrainer)"
      ptIntercomTarget="goal-level-selector-verified"
    >
      <mat-icon
        [ngClass]="{ active: (goal$ | async) === type.VerifiedByTrainer }"
      >
        check_circle
      </mat-icon>
      <svg class="verified-badge">
        <use href="/assets/icons/verified-badge.svg#verified-badge" />
      </svg>
    </div>

    <span class="certified" ptIntercomTarget="goal-level-selector-certified">
      <mat-icon
        [ngClass]="{ active: (goal$ | async) === type.Trainer }"
        [matTooltip]="type.Trainer | titlecase"
        (click)="levelChange.emit(type.Trainer)"
      >
        verified
      </mat-icon>
    </span>
  </ng-container>
</div>
