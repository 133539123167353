import { Component, Input } from '@angular/core';
import { SkillStatus } from '@principle-theorem/level-up-core';

@Component({
  selector: 'lu-skill-status',
  templateUrl: './skill-status.component.html',
  styleUrls: ['./skill-status.component.scss'],
})
export class SkillStatusComponent {
  @Input() status: SkillStatus = SkillStatus.Draft;
  @Input() dense = false;
  statusColourMap: { [key in SkillStatus]: string } = {
    [SkillStatus.Draft]: 'default',
    [SkillStatus.Review]: 'accent',
    [SkillStatus.Published]: 'primary',
  };
}
