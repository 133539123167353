<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
  <svg
    class="logo"
    viewBox="0 0 1024 1224"
    x="0"
    y="0"
    [style.width.px]="width"
    [style.height.px]="height"
  >
    <use [style.fill]="colour" xlink:href="/assets/icons/logo.svg#logo" />
  </svg>
  <span [style.color]="colour">Level Up</span>
</div>
