<div
  class="toolbar-inner"
  fxFlex
  fxLayout="column"
  fxLayoutGap="16px"
  fxLayout.gt-xs="row"
  fxLayoutGap.gt-xs="32px"
>
  <ng-content></ng-content>
</div>
