import { Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type ITag } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { HEX_COLOUR_REGEX, randomHexColour } from '@principle-theorem/shared';

export type TagFormData = Pick<ITag, 'name' | 'hexColour'>;

export interface IEditTagDialogData {
  action: string;
  tag?: ITag;
}

@Component({
    selector: 'lu-edit-tag-dialog',
    templateUrl: './edit-tag-dialog.component.html',
    styleUrls: ['./edit-tag-dialog.component.scss'],
    standalone: false
})
export class EditTagDialogComponent {
  action = 'Create';
  tagForm: TypedFormGroup<TagFormData> = new TypedFormGroup<TagFormData>({
    name: new TypedFormControl('', Validators.required),
    hexColour: new TypedFormControl(randomHexColour(), [
      Validators.required,
      Validators.pattern(HEX_COLOUR_REGEX),
    ]),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      EditTagDialogComponent,
      TagFormData | undefined
    >,
    @Inject(MAT_DIALOG_DATA) data: IEditTagDialogData
  ) {
    if (!data) {
      return;
    }
    this.action = data.action;
    if (data.tag) {
      this.tagForm.patchValue(data.tag);
    }
  }

  canSave(): boolean {
    return this.tagForm.valid && this.tagForm.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.tagForm.getRawValue());
  }
}
