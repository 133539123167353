import {
  type IEmailNotificationContext,
  type INotification,
} from '@principle-theorem/notifications';
import { type IProvider, type WithRef } from '@principle-theorem/shared';
import { type IUser } from '../models/user/user';

export enum NotificationType {
  TrainingApproved = 'levelUpApproved',
  TrainingCancelled = 'levelUpCancelled',
  TrainingDeclined = 'levelUpDeclined',
  TrainingRequested = 'levelUpRequested',
  SkillPublished = 'skillPublished',
  SkillReviewApproved = 'skillReviewApproved',
  SkillReviewRequested = 'skillReviewRequested',
  SkillReviewSubmitted = 'skillReviewSubmitted',
  TrainingGoalsChanged = 'userGroupChange',
  WeeklyDigest = 'weeklyDigest',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationResources {}

export interface INotificationUserPair {
  notification: INotification;
  user: WithRef<IUser>;
}

export type EmailNotificationProvider = IProvider<
  INotificationUserPair,
  | IEmailNotificationContext
  | Promise<IEmailNotificationContext | undefined>
  | undefined
>;
