<ng-container *ngIf="contentValid$ | async; else invalidConentError">
  <pt-editor
    *ngIf="formControl$ | async as formControl"
    fxFlex
    [formControl]="formControl"
    [extensions]="extensions$ | async"
    [menuItems]="menuItems"
    [uploader]="uploader"
    [slashMenuItems]="slashMenuItems"
    (contentError)="contentError.next()"
  />
</ng-container>
<ng-template #invalidConentError>
  <lu-content-error />
</ng-template>
