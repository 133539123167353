import { Component, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { ReplaySubject } from 'rxjs';
import { type IMenuComponent } from '../right-click-menu.directive';

@Component({
    selector: 'pt-base-menu',
    templateUrl: './base-menu.component.html',
    styleUrls: ['./base-menu.component.scss'],
    standalone: false
})
export class BaseMenuComponent implements IMenuComponent {
  menu$: ReplaySubject<MatMenu> = new ReplaySubject(1);

  @ViewChild(MatMenu)
  set menu(menu: MatMenu) {
    this.menu$.next(menu);
  }
}
