export enum OrganisationCollection {
  Folders = 'folders',
  Pathways = 'pathways',
  Skills = 'skills',
  PageTemplates = 'pageTemplates',
  Tags = 'tags',
  UserGroups = 'userGroups',
  Users = 'users',
  MarketplaceSubscriptions = 'marketplaceSubscriptions',
  Integrations = 'integrations',
}
