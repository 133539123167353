import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type AtLeast } from '@principle-theorem/shared';
import {
  confirmationDialogData,
  type IConfirmationDialogData,
} from '../confirm-dialog/confirm-dialog.component';
import { ISelectDialogOption } from '../select-dialog/select-dialog.component';

export interface ISelectListDialogOption<T> extends ISelectDialogOption<T> {
  label: string;
  value: T;
  isDisabled?: boolean;
  disabledReason?: string;
  info?: string;
}

export interface ISelectListDialogGroup<T> {
  name: string;
  options: ISelectListDialogOption<T>[];
}
export interface ISelectListDialogData<T> extends IConfirmationDialogData {
  options: ISelectListDialogOption<T>[];
  groups?: ISelectListDialogGroup<T>[];
}

export type ISelectListDialogInput<T> = AtLeast<
  ISelectListDialogData<T>,
  'title' | 'prompt' | 'options'
>;

@Component({
    selector: 'pt-select-list-dialog',
    templateUrl: './select-list-dialog.component.html',
    styleUrls: ['./select-list-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectListDialogComponent {
  data: ISelectListDialogData<unknown>;
  hasInfo: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISelectListDialogInput<unknown>,
    public dialogRef: MatDialogRef<SelectListDialogComponent, unknown>
  ) {
    this.data = {
      ...confirmationDialogData(data),
      options: data.options,
    };
  }

  submit(option: ISelectListDialogOption<unknown>): void {
    if (option.isDisabled) {
      return;
    }
    this.dialogRef.close(option.value);
  }
}
