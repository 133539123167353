import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GoalProgress, SkillLevel } from '@principle-theorem/level-up-core';
import { snapshot } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  getGoalProgress,
  getGoalType,
} from '../level-indicator/level-indicator.component';

@Component({
    selector: 'lu-level-confirm-progress-button',
    templateUrl: './level-confirm-progress-button.component.html',
    styleUrls: ['./level-confirm-progress-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LevelConfirmProgressButtonComponent {
  private _goal$ = new ReplaySubject<SkillLevel>(1);
  private _level$ = new ReplaySubject<SkillLevel>(1);
  private _hasRequest$ = new ReplaySubject<boolean>(1);
  disabled$: Observable<boolean>;
  @Output() progressLevelChange = new EventEmitter<SkillLevel>();

  @Input()
  set goal(goal: SkillLevel | undefined) {
    this._goal$.next(goal ?? SkillLevel.None);
  }

  @Input()
  set level(level: SkillLevel | undefined) {
    this._level$.next(level ?? SkillLevel.None);
  }

  @Input()
  set hasRequest(hasRequest: BooleanInput) {
    this._hasRequest$.next(coerceBooleanProperty(hasRequest));
  }

  constructor() {
    this.disabled$ = combineLatest([
      this._goal$,
      this._level$,
      this._hasRequest$,
    ]).pipe(
      map(([goal, level, hasRequest]) => {
        const goalLevel = getGoalType(level, goal, hasRequest);
        const goalProgress = getGoalProgress(level, goal, hasRequest);

        if (
          goalProgress === GoalProgress.Complete ||
          goalLevel === SkillLevel.None
        ) {
          return true;
        }

        return false;
      })
    );
  }

  async setLevel(): Promise<void> {
    const level = await snapshot(this._goal$);
    this.progressLevelChange.emit(level);
  }
}
