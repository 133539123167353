<lu-card-container
  class="grow"
  *ngIf="skill$ | async as skill"
  [title]="skill.name"
  [author]="author$ | async"
  [link]="link"
  [colour]="colour$ | async"
>
  <lu-card-banner [colour]="colour$ | async">
    <div class="user-skill-progress">
      <lu-user-skill-progress [user]="user$ | async" [skill]="skill$ | async" />
    </div>
  </lu-card-banner>
  <lu-card-actions>
    <div fxLayout="row" fxLayoutAlign="start center">
      <lu-privacy-icon
        *ngIf="skill.restrictAccessTo.length"
        [item]="skill"
        size="24px"
        colour="white"
      />
      <lu-tags [disabled]="!skill.tags.length">
        <lu-tag-display [tagRefs]="skill.tags" [compact]="false" />
      </lu-tags>
    </div>
  </lu-card-actions>
</lu-card-container>
