import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'map',
    standalone: false
})
export class MapPipe implements PipeTransform {
  transform<T, R, S>(value: T, mapFn: (value: T) => R, thisArg?: S): R {
    if (thisArg) {
      mapFn = mapFn.bind(thisArg);
    }
    return mapFn(value);
  }
}
