import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPathway, type IUser } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-pathway-list',
  templateUrl: './pathway-list.component.html',
  styleUrls: ['./pathway-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayListComponent {
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  pathways$ = new ReplaySubject<WithRef<IPathway>[]>(1);
  user$ = new ReplaySubject<WithRef<IUser>>(1);

  @Input()
  set pathways(pathways: WithRef<IPathway>[]) {
    if (pathways) {
      this.pathways$.next(pathways);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }
}
