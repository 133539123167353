import { type WithRef } from '@principle-theorem/shared';
import { type IUser } from '../user';
import {
  type IUserStatValue,
  type IUserStatisticsResult,
  statValue,
} from './user-statistics-result';
import { resolverBuilders, boolToNum } from './helpers';

type IBuilderFn = (user: WithRef<IUser>) => IUserStatValue[];

export function userResults(user: WithRef<IUser>): IUserStatisticsResult[] {
  return resolverBuilders(
    {
      invitedUsers,
      invitedActivatedUsers,
    },
    (builder: IBuilderFn) => builder(user)
  );
}

export function invitedUsers(user: IUser): IUserStatValue[] {
  if (!user.invitedBy) {
    return [];
  }
  return [statValue(user.invitedBy, boolToNum(true))];
}

export function invitedActivatedUsers(user: IUser): IUserStatValue[] {
  if (!user.isActivated) {
    return [];
  }
  return invitedUsers(user);
}
