import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getError, snapshot } from '@principle-theorem/shared';
import { ImageCommandsService } from '../image-commands.service';
import type { IUploader } from '../../uploader';
import {
  BasicMenuButtonComponent,
  cleanUpBlockCommand,
} from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
@Component({
    selector: 'pt-image-upload-menu',
    templateUrl: './image-upload-menu.component.html',
    styleUrls: ['./image-upload-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ImageUploadMenuComponent extends BasicMenuButtonComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  override tooltip = 'Add Image';
  override buttonText = 'Add Image';
  override icon = 'insert_photo';

  @Input()
  uploader: IUploader;

  acceptedTypes: string[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/gif',
  ];

  constructor(
    private _snackBar: MatSnackBar,
    private _imageCommands: ImageCommandsService
  ) {
    super();
  }

  async startUpload(event: Event): Promise<void> {
    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    const element = event.target as HTMLInputElement;
    if (!element.files) {
      return;
    }

    try {
      await this._imageCommands.upload(
        this.uploader,
        editor,
        editor.view.state.selection.from,
        element.files
      );
      this.fileInput.nativeElement.value = '';
    } catch (error) {
      this._snackBar.open('Sorry, failed to upload image', getError(error));
    }
  }

  override async runCommand(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    this.fileInput.nativeElement.click();
    editor.view.focus();
  }
}
