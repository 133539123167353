import { isDocRef, isObject, type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IOrganisation } from '../organisation/organisation';
import { type IVendor } from './vendor';
import { type IVendorBundle } from './vendor-bundle';

export enum MarketplaceSubscriptionStatus {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
}
export interface IMarketplaceSubscription {
  vendorRef: DocumentReference<IVendor>;
  bundleRef: DocumentReference<IVendorBundle>;
  status: MarketplaceSubscriptionStatus;
}

export function isMarketplaceSubscription(
  item: unknown
): item is IMarketplaceSubscription {
  return (
    isObject(item) &&
    'vendorRef' in item &&
    isDocRef<IVendor>(item.vendorRef) &&
    'bundleRef' in item &&
    isDocRef<IVendorBundle>(item.bundleRef) &&
    'status' in item &&
    isString(item.status)
  );
}

export interface IMarketplaceSubscriptionSummary {
  subscriber: WithRef<IOrganisation>;
  subscription: WithRef<IMarketplaceSubscription>;
}
