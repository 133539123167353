import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  isSameRef,
  multiSortBy$,
  nameSorter,
  type WithRef,
} from '@principle-theorem/shared';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import {
  isDisabled$,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type Observable, Subject } from 'rxjs';
import { findTrainerUsersForSkill$ } from '../../../models/user';
import { OrganisationService } from '../../../services/organisation.service';

interface IMentor {
  mentor: DocumentReference<IUser>;
}

@Component({
  selector: 'lu-level-up-request-mentor-dialog',
  templateUrl: './level-up-request-mentor-dialog.component.html',
  styleUrls: ['./level-up-request-mentor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelUpRequestMentorDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  form = new TypedFormGroup<IMentor>({
    mentor: new TypedFormControl<DocumentReference<IUser>>(
      undefined,
      Validators.required
    ),
  });
  users$: Observable<WithRef<IUser>[]>;
  isDisabled$ = isDisabled$(this.form);

  constructor(
    private _dialogRef: MatDialogRef<
      LevelUpRequestMentorDialogComponent,
      DocumentReference<IUser>
    >,
    private _org: OrganisationService,
    @Inject(MAT_DIALOG_DATA) skill: WithRef<ISkill>
  ) {
    this.users$ = this._org.enabledUsers$.pipe(
      findTrainerUsersForSkill$(skill),
      multiSortBy$(nameSorter())
    );
  }

  compareWithFn(aRef?: DocumentReference, bRef?: DocumentReference): boolean {
    return aRef && bRef && isSameRef(aRef, bRef) ? true : false;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue().mentor);
  }
}
