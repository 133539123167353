import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {
  InitialWorkspaceSetupStatus,
  WorkflowType,
} from '@principle-theorem/level-up-core';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../services/organisation.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceSetupRedirectGuard extends AuthGuard {
  constructor(
    private _router: Router,
    auth: AuthService,
    private _organisation: OrganisationService
  ) {
    super(auth);
  }

  canActivate(_next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this._organisation.user$]).pipe(
      map(([user]) => {
        const workflow = (user?.workflows ?? []).find(
          (userWorkflow) =>
            userWorkflow.type === WorkflowType.InitialWorkspaceSetup
        );
        if (
          !user?.isOwner ||
          !workflow ||
          workflow.status === InitialWorkspaceSetupStatus.Finished
        ) {
          return true;
        }

        return this._router.createUrlTree(['/workflows/workspace-setup']);
      })
    );
  }
}
