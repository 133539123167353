import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DEFAULT_USER_GROUP_UID,
  INITIAL_WORKFLOW_STATE,
  Organisation,
  OrganisationStatus,
  User,
  getDefaultGroup,
  type IOrganisation,
} from '@principle-theorem/level-up-core';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import {
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  addDoc,
  multiSortBy$,
  nameSorter,
  type WithRef,
} from '@principle-theorem/shared';
import { startWith } from 'rxjs/operators';
import {
  IOrganisationAddFormData,
  OrganisationAddDialogComponent,
} from '../organisation-add-dialog/organisation-add-dialog.component';

@Component({
    selector: 'lu-organisations',
    templateUrl: './organisations.component.html',
    styleUrls: ['./organisations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrganisationsComponent {
  trackByOrganisation = TrackByFunctions.ref<WithRef<IOrganisation>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IOrganisation>>;

  constructor(
    private _authFunctions: AuthFirebaseFunctionsService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IOrganisation>>(
      Organisation.all$().pipe(multiSortBy$(nameSorter())),
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );
  }

  async addOrganisation(): Promise<void> {
    const organisationData = await this._dialog
      .open<
        OrganisationAddDialogComponent,
        undefined,
        IOrganisationAddFormData
      >(OrganisationAddDialogComponent, DialogPresets.small())
      .afterClosed()
      .toPromise();

    if (!organisationData) {
      return;
    }

    const organisation = Organisation.init({
      ...organisationData.organisation,
      status: OrganisationStatus.Active,
    });

    const user = User.init({
      ...organisationData.owner,
      isOwner: true,
      isAdmin: true,
      workflows: INITIAL_WORKFLOW_STATE,
    });

    const alreadyExists: boolean =
      await this._authFunctions.checkOrganisationExists(organisation.slug);

    if (alreadyExists) {
      this._snackBar.open('Organisation with this name already exists');
      return;
    }

    const orgRef = await addDoc(Organisation.col(), organisation);
    await addDoc(
      Organisation.userGroupCol({ ref: orgRef }),
      getDefaultGroup(),
      DEFAULT_USER_GROUP_UID
    );

    await addDoc(Organisation.userCol({ ref: orgRef }), user);

    this._snackBar.open('Organisation Added');
  }
}
