<mat-toolbar color="primary">{{ action }} Tag</mat-toolbar>

<form [formGroup]="tagForm" (ngSubmit)="submit()">
  <div class="layout-margin" fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <pt-colour-picker formControlName="hexColour" [inputHidden]="true" />
  </div>

  <div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button [disabled]="!canSave()">{{ action }}</button>
  </div>
</form>
