import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  forwardRef,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  type MatAutocomplete,
  type MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { type MatChipInputEvent } from '@angular/material/chips';
import { type ITag } from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { difference } from 'lodash';
import { BehaviorSubject, combineLatest, type Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';
import { type INamedDocsToTags, NamedDocsToTags } from '../named-docs-to-tags';
import { TagSearch } from '../tag-search';
import { RandomTagFactory } from './random-tag-factory';
import { TagsInput } from './tags-input';

@Component({
  selector: 'lu-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true,
    },
  ],
})
export class TagsInputComponent implements ControlValueAccessor, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _docRefs$: BehaviorSubject<DocumentReference<ITag>[]> =
    new BehaviorSubject<DocumentReference<ITag>[]>([]);
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsInput: TagsInput;
  namedDocsToTags: INamedDocsToTags;
  tagSearch: TagSearch;
  tagCtrl: TypedFormControl<string | WithRef<ITag>> = new TypedFormControl('');
  tagFactory$: Observable<RandomTagFactory>;

  @ViewChild('tagInput')
  tagInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto')
  matAutocomplete: MatAutocomplete;

  constructor(
    private _organisation: OrganisationService,
    private _cachedLists: CachedListsService
  ) {
    this.namedDocsToTags = new NamedDocsToTags(
      this._docRefs$,
      this._cachedLists.tags$
    );
    this.tagsInput = new TagsInput();
    this.tagFactory$ = this._organisation.tagCol$.pipe(
      map((tagCol) => new RandomTagFactory(tagCol)),
      shareReplayCold()
    );

    this.tagSearch = this.initTagSearch();

    this.namedDocsToTags.tags$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((tags) => {
        this.tagsInput.tags$.next(tags);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  initTagSearch(): TagSearch {
    return new TagSearch(
      this._getAvailableTags$(this._cachedLists.tags$),
      this.tagCtrl.valueChanges.pipe(startWith(''))
    );
  }

  add(event: MatAutocompleteSelectedEvent): void {
    this.tagsInput.add(event.option.value as WithRef<ITag>);
    this._resetInput();
  }

  async create(event: MatChipInputEvent): Promise<void> {
    if (!event.value || this.matAutocomplete.isOpen) {
      return;
    }
    const factory: RandomTagFactory = await snapshot(this.tagFactory$);
    const tag: WithRef<ITag> = await factory.create(event.value);
    this.tagsInput.add(tag);
    this._resetInput();
  }

  writeValue(docRefs: DocumentReference<ITag>[]): void {
    this._docRefs$.next(docRefs);
  }

  registerOnChange(fn: () => void): void {
    this.tagsInput.namedDocs$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.tagsInput.namedDocs$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      return this.tagCtrl.disable();
    }
    this.tagCtrl.enable();
  }

  private _resetInput(): void {
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.reset();
  }

  private _getAvailableTags$(
    collection: Observable<WithRef<ITag>[]>
  ): Observable<WithRef<ITag>[]> {
    return combineLatest([collection, this.tagsInput.tags$]).pipe(
      map((values: [WithRef<ITag>[], WithRef<ITag>[]]) => difference(...values))
    );
  }
}
