import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  IPathwaySection,
  Organisation,
  Pathway,
  type IPathway,
  type IUser,
  PathwayStatus,
} from '@principle-theorem/level-up-core';
import {
  SidebarManagerService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  firstResult,
  getDoc,
  shareReplayCold,
  snapshot,
  undeletedQuery,
  where,
  type WithRef,
} from '@principle-theorem/shared';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  of,
  type Observable,
} from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { MarketplaceCopyService } from '../../../services/marketplace-copy.service';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-pathway-view',
    templateUrl: './pathway-view.component.html',
    styleUrls: ['./pathway-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PathwayViewComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackBySection = TrackByFunctions.uniqueId<IPathwaySection>();
  sections$: Observable<IPathwaySection[]>;
  @Input() skillPrefix = '/explore/pathway';
  pathway$ = new ReplaySubject<WithRef<IPathway>>(1);
  user$: Observable<WithRef<IUser>>;
  isMarketplaceVersion$: Observable<boolean>;
  isAlreadyCopied$: Observable<boolean>;
  isLocalPathway$: Observable<boolean>;
  copiedPathwayTooltip$: Observable<string | undefined>;
  canEdit$: Observable<boolean>;
  pathwayStatus = PathwayStatus;

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  constructor(
    public organisation: OrganisationService,
    public sidebar: SidebarManagerService,
    private _marketplaceCopy: MarketplaceCopyService,
    private _router: Router
  ) {
    this.sections$ = this.pathway$.pipe(
      map((pathway) => pathway.sections ?? [])
    );

    this.isMarketplaceVersion$ = this.pathway$.pipe(
      map((pathway) => Pathway.isMarketplaceRelease(pathway))
    );

    this.isLocalPathway$ = this.isMarketplaceVersion$.pipe(
      map((isMarketplaceVersion) => !isMarketplaceVersion),
      shareReplayCold()
    );

    const copiedPathway$ = combineLatest([
      this.pathway$,
      this.organisation.organisation$.pipe(filterUndefined()),
      this.isMarketplaceVersion$,
    ]).pipe(
      switchMap(([pathway, org, isMarketplaceVersion]) =>
        isMarketplaceVersion && pathway.vendorPathwayRef
          ? from(
              firstResult(
                undeletedQuery(Organisation.pathwayCol(org)),
                where('vendorPathwayRef', '==', pathway.vendorPathwayRef)
              )
            )
          : of(undefined)
      ),
      shareReplayCold()
    );

    this.copiedPathwayTooltip$ = copiedPathway$.pipe(
      switchMap((pathway) => {
        if (!pathway?.folderRef) {
          return of(undefined);
        }
        return from(getDoc(pathway.folderRef)).pipe(
          map(
            (folder) =>
              `Skill has already been copied to folder: ${folder.name}`
          )
        );
      }),
      shareReplayCold()
    );

    this.isAlreadyCopied$ = copiedPathway$.pipe(
      map((pathway) => !!pathway),
      startWith(true),
      shareReplayCold()
    );

    this.canEdit$ = combineLatest([
      this.pathway$,
      this.organisation.user$.pipe(filterUndefined()),
      this.isMarketplaceVersion$,
    ]).pipe(
      map(
        ([pathway, user, isMarketplaceVersion]) =>
          !isMarketplaceVersion && Pathway.canEdit(pathway, user)
      )
    );

    this.user$ = this.organisation.user$.pipe(filterUndefined());

    this.pathway$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((pathway) => {
        const isStepRoute = this._router.url.includes('/steps/');
        if (isStepRoute) {
          return;
        }
        const firstStep = pathway.sections[0]?.steps[0];
        if (!firstStep) {
          return;
        }
        void this._router.navigate(
          [this.skillPrefix, pathway.ref.id, 'steps', firstStep.id],
          {
            skipLocationChange: true,
          }
        );
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async handleContentError(error: string): Promise<void> {
    const pathway = await snapshot(this.pathway$);
    const user = await snapshot(
      this.organisation.user$.pipe(filterUndefined())
    );
    const organisation = await snapshot(
      this.organisation.organisation$.pipe(filterUndefined())
    );
    throw new Error(
      JSON.stringify({
        error,
        skill: pathway.name,
        path: pathway.ref.path,
        workspace: organisation.slug,
        user: user.name,
      })
    );
  }

  async copyToFolder(pathway: WithRef<IPathway>): Promise<void> {
    await this._marketplaceCopy.copyPathway(pathway);
  }
}
