<ng-container *ngIf="(compact$ | async) === false; else compact">
  <span
    class="flex rounded-xl bg-[#F2EAFF] px-2 py-1 font-bold text-[#7345EC]"
    [ngClass]="{
      completed: isCompleted$ | async
    }"
    >{{ summary$ | async }} Completed</span
  >
</ng-container>

<ng-template #compact>
  <span
    class="compact flex rounded-full bg-[#F2EAFF] p-[6px] font-bold text-[#7345EC]"
    [ngClass]="{
      completed: isCompleted$ | async
    }"
    >{{ summary$ | async }}</span
  >
</ng-template>
