<lu-page-simple marginBottom="16">
  <lu-page-header
    label="Pathways"
    pageInfo="Create and manage your pathways here. Pathways are a group of skills that should be learned together."
    [pageTour]="tourId"
  >
    <lu-page-toolbar>
      <lu-header-button
        buttonIcon="add"
        buttonText="Create"
        (click)="addPathway()"
        luPageToolbarAction
        ptIntercomTarget="pathways.create-button"
      />
    </lu-page-toolbar>
  </lu-page-header>

  <lu-page-toolbar>
    <pt-search-field fxFlex>
      <input matInput placeholder="Filter" [formControl]="search" />
      <lu-tag-filter (selectionChange)="filterResults($event)" />
    </pt-search-field>
  </lu-page-toolbar>

  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    class="mat-caption list-headers"
  >
    <span class="author-column">Creator</span>
    <span fxFlex.gt-xs="43px"></span>
  </div>
  <mat-list class="with-dividers">
    <ng-container *ngIf="filteredPathways$ | async as pathways; else loading">
      <mat-tab-group *ngIf="pathways.length" [animationDuration]="0">
        <mat-tab
          *ngFor="let group of pathways; trackBy: trackByStatus"
          [label]="group.status | titlecase"
        >
          <ng-container *ngIf="group.pathways.length; else noPathways">
            <lu-pathway-list-item
              *ngFor="let pathway of group.pathways; trackBy: trackByPathway"
              [pathway]="pathway"
            />
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <ng-template #loading>
      <div class="layout-margin flex flex-col gap-2">
        <lu-pathway-placeholder />
        <lu-pathway-placeholder />
        <lu-pathway-placeholder />
      </div>
    </ng-template>
  </mat-list>
</lu-page-simple>

<ng-template #noPathways>
  <p class="p-4">No Pathways</p>
</ng-template>
