import { Pipe, type PipeTransform } from '@angular/core';
import { Skill, type ISkill } from '@principle-theorem/level-up-core';

@Pipe({
    name: 'inReview',
    standalone: false
})
export class InReviewPipe implements PipeTransform {
  transform(skill: ISkill): boolean {
    return Skill.inReview(skill);
  }
}
