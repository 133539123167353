<div fxLayout="column" fxFill *ngIf="bloc.group$ | async as userGroup">
  <div fxFlex class="content">
    <lu-page-simple marginBottom="16">
      <lu-page-header label="Edit Team">
        <lu-page-toolbar>
          <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon class="font-bold">more_vert</mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu">
            <button
              mat-menu-item
              class="warn"
              (click)="delete(userGroup)"
              [disabled]="userGroup.readOnly"
            >
              <mat-icon color="warn">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </lu-page-toolbar>
      </lu-page-header>
      <form [formGroup]="form">
        <div fxLayout="column" ptIntercomTarget="group.details">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>

        <lu-list-heading>
          <lu-list-title color="primary">Users</lu-list-title>
        </lu-list-heading>

        <div class="flex flex-col gap-2">
          <mat-list class="with-dividers" ptIntercomTarget="group.users">
            <ng-container *ngIf="assignedUsers$ | async as users">
              <ng-container *ngIf="users.length; else noUsers">
                <ng-container *ngFor="let user of users; trackBy: trackByUser">
                  <mat-list-item *ngIf="user.ref">
                    <span matListItemTitle>
                      <a [routerLink]="['/', 'admin', 'users', user.ref.id]">{{
                        user.name
                      }}</a>
                    </span>
                    <div matListItemMeta>
                      <button
                        mat-icon-button
                        color="warn"
                        [disabled]="userGroup.readOnly"
                        (click)="removeUser(user.ref)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </mat-list-item>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-list>

          <button
            class="grow"
            mat-stroked-button
            [disabled]="userGroup.readOnly"
            (click)="addUser(userGroup)"
          >
            <mat-icon>add</mat-icon>
            Add User
          </button>
        </div>

        <ng-template #noUsers>
          <p class="mat-caption layout-margin">No Users</p>
        </ng-template>

        <h2 class="mat-headline-4">Goals</h2>
        <p>
          Assign skills and pathways for your Teams to learn on their personal
          learning page.
        </p>

        <div ptIntercomTarget="group.goals-list">
          <lu-pathway-and-skills-associate
            showAdd
            showRelativeDueDate
            [pathways]="userGroup.pathwayAssociations"
            [skills]="userGroup.skillAssociations"
            [skillLevels]="userGroup.levelRequirements"
            (pathwayRemove)="removePathway($event)"
            (skillRemove)="removeSkill($event)"
            (skillLevelsChange)="bloc.updateSkillLevels($event)"
            (pathwayLevelsChange)="bloc.updatePathwayLevels($event)"
            (add)="addGoals(userGroup)"
          />
        </div>
      </form>
    </lu-page-simple>
  </div>

  <pt-page-footer>
    <pt-buttons-container>
      <button
        mat-flat-button
        color="primary"
        (click)="save()"
        [disabled]="(bloc.hasUnsavedChanges$ | async) === false"
      >
        Save
      </button>
    </pt-buttons-container>
  </pt-page-footer>
</div>
