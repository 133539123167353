import { type RawSchema } from '@principle-theorem/editor';
import { type INotificationContextRenderer } from '@principle-theorem/notifications';
import {
  LevelUpApprovedContextRenderer,
  LevelUpApprovedEmailContextRenderer,
} from './context-renderers/level-up-approved';
import {
  LevelUpCancelledContextRenderer,
  LevelUpCancelledEmailContextRenderer,
} from './context-renderers/level-up-cancelled';
import {
  LevelUpDeclinedContextRenderer,
  LevelUpDeclinedEmailContextRenderer,
} from './context-renderers/level-up-declined';
import {
  LevelUpRequestedContextRenderer,
  LevelUpRequestedEmailContextRenderer,
} from './context-renderers/level-up-requested';
import {
  SkillPublishedContextRenderer,
  SkillPublishedEmailContextRenderer,
} from './context-renderers/skill-published';
import {
  SkillReviewApprovedContextRenderer,
  SkillReviewApprovedEmailContextRenderer,
} from './context-renderers/skill-review-approved';
import {
  SkillReviewRequestedContextRenderer,
  SkillReviewRequestedEmailContextRenderer,
} from './context-renderers/skill-review-requested';
import {
  SkillReviewSubmittedContextRenderer,
  SkillReviewSubmittedEmailContextRenderer,
} from './context-renderers/skill-review-submitted';
import {
  UserGroupChangeContextRenderer,
  UserGroupChangeEmailContextRenderer,
} from './context-renderers/user-group-change';
import {
  type EmailNotificationProvider,
  type NotificationType,
} from './notification';

export const NOTIFICATION_PROVIDERS: INotificationContextRenderer<
  RawSchema,
  NotificationType
>[] = [
  new LevelUpApprovedContextRenderer(),
  new LevelUpCancelledContextRenderer(),
  new LevelUpDeclinedContextRenderer(),
  new LevelUpRequestedContextRenderer(),
  new SkillPublishedContextRenderer(),
  new SkillReviewApprovedContextRenderer(),
  new SkillReviewRequestedContextRenderer(),
  new SkillReviewSubmittedContextRenderer(),
  new UserGroupChangeContextRenderer(),
];

export const EMAIL_NOTIFICATION_PROVIDERS: EmailNotificationProvider[] = [
  new LevelUpApprovedEmailContextRenderer(),
  new LevelUpCancelledEmailContextRenderer(),
  new LevelUpDeclinedEmailContextRenderer(),
  new LevelUpRequestedEmailContextRenderer(),
  new SkillPublishedEmailContextRenderer(),
  new SkillReviewApprovedEmailContextRenderer(),
  new SkillReviewRequestedEmailContextRenderer(),
  new SkillReviewSubmittedEmailContextRenderer(),
  new UserGroupChangeEmailContextRenderer(),
];
