<div fxLayout="column" fxLayoutGap="32px">
  <div>
    <lu-list-heading>
      <lu-list-title color="primary">Pathways</lu-list-title>
    </lu-list-heading>

    <div class="flex flex-col gap-2" *ngIf="pathways$ | async as pathways">
      <mat-list>
        <ng-container *ngIf="pathways.length; else noPathways">
          <ng-container
            *ngFor="let pathway of pathways; trackBy: trackByPathway"
          >
            <mat-list-item>
              <span matListItemTitle>
                <a [routerLink]="['/explore/pathway', pathway.ref.id]">{{
                  pathway.name
                }}</a>
              </span>
              <div matListItemMeta class="flex items-center">
                <lu-level-selector
                  [goal]="pathway | map : getPathwayLevel$ : this | async"
                  [requiresTrainerVerification]="
                    pathway
                      | map : hasSkillsRequiringTrainerVerification$ : this
                      | async
                  "
                  (levelChange)="updateSkillLevels(pathway, $event)"
                />

                <ng-container
                  *ngIf="pathway | map : getDueDateBloc : this as bloc"
                >
                  <div class="flex" *ngIf="showDueDate$ | async">
                    <lu-user-goal-due-date-indicator
                      [canEdit]="true"
                      [dueDate]="bloc.dueDate$ | async"
                      (dateChange)="updateDueDate(pathway, $event)"
                    />
                  </div>

                  <div class="flex" *ngIf="showRelativeDueDate$ | async">
                    <lu-user-group-goal-due-date-indicator
                      showRelativeDueDate
                      [canEdit]="true"
                      [dueDate]="bloc.relativeDueDate$ | async"
                      (dateChange)="updateRelativeDueDate(pathway, $event)"
                    />
                  </div>
                </ng-container>

                <button
                  mat-icon-button
                  class="remove-button"
                  matTooltip="Remove Pathway"
                  color="warn"
                  [disableRipple]="true"
                  (click)="handleRemovePathway(pathway.ref)"
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>

                <button mat-icon-button (click)="pathwaySkillList.toggle()">
                  <mat-icon>
                    {{
                      (pathwaySkillList.expanded$ | async)
                        ? 'expand_less'
                        : 'expand_more'
                    }}
                  </mat-icon>
                </button>
              </div>
            </mat-list-item>

            <pt-collapsible #pathwaySkillList>
              <ng-template ptCollapsibleContent>
                <mat-list class="with-dividers pathway-skills">
                  <ng-container
                    *ngFor="
                      let section of pathway.sections;
                      trackBy: trackBySection
                    "
                  >
                    <div mat-subheader *ngIf="section.name">
                      {{ section.name }}
                    </div>
                    <ng-container
                      *ngFor="
                        let skillRef of section.steps;
                        trackBy: trackBySkillRef
                      "
                    >
                      <lu-skill-associate
                        [showRelativeDueDate]="showRelativeDueDate$ | async"
                        [showDueDate]="showDueDate$ | async"
                        [skillRef]="skillRef"
                        [skillLevels]="skillLevels$ | async"
                        [removeEnabled]="false"
                        (levelChange)="handleLevelChange($event, skillRef)"
                        (dueDateChange)="handleDueDateChange(skillRef, $event)"
                        (relativeDueDateChange)="
                          handleRelativeDueDateChange(skillRef, $event)
                        "
                      />
                    </ng-container>
                  </ng-container>
                </mat-list>
              </ng-template>
            </pt-collapsible>
          </ng-container>
        </ng-container>
      </mat-list>

      <button
        *ngIf="showAdd$ | async"
        class="grow"
        mat-stroked-button
        ptIntercomTarget="group.add-goal-button"
        (click)="add.emit()"
      >
        <mat-icon>add</mat-icon>
        Add Pathway
      </button>

      <ng-template #noPathways>
        <p class="mat-caption layout-margin">No Pathway goals</p>
      </ng-template>
    </div>
  </div>

  <div>
    <lu-list-heading>
      <lu-list-title color="primary">Skills</lu-list-title>
    </lu-list-heading>

    <div class="flex flex-col gap-2" *ngIf="skills$ | async as skills">
      <mat-list>
        <ng-container *ngIf="skills.length; else noSkills">
          <ng-container
            *ngFor="
              let skillAssociation of skills;
              trackBy: trackSkillAssociation
            "
          >
            <lu-skill-associate
              [showRelativeDueDate]="showRelativeDueDate$ | async"
              [showDueDate]="showDueDate$ | async"
              [skillRef]="skillAssociation.skill.ref"
              [skillLevels]="skillLevels$ | async"
              (removeSkill)="handleRemoveSkill($event)"
              (levelChange)="
                handleLevelChange($event, skillAssociation.skill.ref)
              "
              (dueDateChange)="
                handleDueDateChange(skillAssociation.skill.ref, $event)
              "
              (relativeDueDateChange)="
                handleRelativeDueDateChange(skillAssociation.skill.ref, $event)
              "
            />
          </ng-container>
        </ng-container>
      </mat-list>

      <button
        *ngIf="showAdd$ | async"
        class="grow"
        mat-stroked-button
        ptIntercomTarget="group.add-goal-button"
        (click)="add.emit()"
      >
        <mat-icon>add</mat-icon>
        Add Skill
      </button>

      <ng-template #noSkills>
        <p class="mat-caption layout-margin">No Skill goals</p>
      </ng-template>
    </div>
  </div>
</div>
