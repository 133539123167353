import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pt-drag-box',
    templateUrl: './drag-box.component.html',
    styleUrls: ['./drag-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DragBoxComponent {
  @Input() cdkDragDisabled = false;
}
