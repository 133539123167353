import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IVendor } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'lu-vendors',
    templateUrl: './vendors.component.html',
    styleUrls: ['./vendors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VendorsComponent {
  trackByVendor = TrackByFunctions.ref<WithRef<IVendor>>();
  vendors$: ReplaySubject<WithRef<IVendor>[]> = new ReplaySubject(1);

  @Input()
  set vendors(vendors: WithRef<IVendor>[]) {
    if (vendors) {
      this.vendors$.next(vendors);
    }
  }
}
