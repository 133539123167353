<lu-goals-stat
  class="statistic"
  [stats]="stats"
  [colour]="colour"
 />

<lu-author-stat
  class="statistic"
  [stats]="stats"
  [colour]="colour"
 />

<lu-mentor-stat
  class="statistic"
  [stats]="stats"
  [colour]="colour"
 />

<lu-reviewer-stat
  class="statistic"
  [stats]="stats"
  [colour]="colour"
 />
