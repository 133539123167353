import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { MentionResourceType } from '@principle-theorem/level-up-core';
import { MouseHoverBloc } from '@principle-theorem/ng-shared';
import { ReplaySubject, Subject } from 'rxjs';
import { IFolderNode } from '../folders-list/folders-list.component';

@Component({
  selector: 'lu-folder-leaf-node',
  templateUrl: './folder-leaf-node.component.html',
  styleUrls: ['./folder-leaf-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderLeafNodeComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  hoverBloc: MouseHoverBloc;
  node$ = new ReplaySubject<IFolderNode>(1);

  @Input()
  set node(node: IFolderNode) {
    if (node) {
      this.node$.next(node);
    }
  }

  constructor(elementRef: ElementRef<HTMLElement>) {
    this.hoverBloc = new MouseHoverBloc(elementRef, this._onDestroy$);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  isSkill(node: IFolderNode): boolean {
    return node.type === MentionResourceType.Skill;
  }

  isPathway(node: IFolderNode): boolean {
    return node.type === MentionResourceType.Pathway;
  }
}
