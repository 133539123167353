import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl } from '@principle-theorem/ng-shared';

export interface IRelativeDueDateDialogData {
  dueDate?: number;
}

@Component({
  selector: 'lu-relative-due-date-dialog',
  templateUrl: './relative-due-date-dialog.component.html',
  styleUrls: ['./relative-due-date-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelativeDueDateDialogComponent {
  dueDateCtrl = new TypedFormControl<number>(undefined);

  constructor(
    private _dialogRef: MatDialogRef<RelativeDueDateDialogComponent, number>,
    @Inject(MAT_DIALOG_DATA) data: IRelativeDueDateDialogData
  ) {
    if (data.dueDate) {
      this.dueDateCtrl.setValue(data.dueDate);
    }
  }

  save(): void {
    this._dialogRef.close(this.dueDateCtrl.value);
  }
}
