import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'join',
    standalone: false
})
export class JoinPipe implements PipeTransform {
  transform(array: string[], separator: string): string {
    return array.join(separator);
  }
}
