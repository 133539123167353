import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type IUser, User } from '@principle-theorem/level-up-core';
import {
  isDisabled$,
  TrackByFunctions,
  type TypedAbstractControl,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type CollectionReference, where } from '@principle-theorem/shared';
import {
  asyncForEach,
  filterUndefined,
  find$,
  snapshot,
} from '@principle-theorem/shared';
import { compact, uniq } from 'lodash';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { OrganisationService } from '../../../../services/organisation.service';

type UserFormData = Pick<IUser, 'isAdmin' | 'name' | 'email'>;

interface IInviteFormData {
  emails: string;
  users: UserFormData[];
}

export interface IInviteUserDialogData {
  usersCollection: CollectionReference<IUser>;
}

function getUserFormGroup(): TypedFormGroup<UserFormData> {
  const formGroup = new TypedFormGroup<UserFormData>({
    isAdmin: new TypedFormControl<boolean>(false),
    name: new TypedFormControl<string>('', [Validators.required]),
    email: new TypedFormControl<string>('', [
      Validators.email,
      Validators.required,
    ]),
  });
  return formGroup;
}

@Component({
    selector: 'lu-invite-user-dialog',
    templateUrl: './invite-user-dialog.component.html',
    styleUrls: ['./invite-user-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InviteUserDialogComponent {
  trackByEmail =
    TrackByFunctions.nestedField<TypedAbstractControl<UserFormData>>(
      'value.email'
    );
  inviteForm = new TypedFormGroup<IInviteFormData>({
    emails: new TypedFormControl(),
    users: new TypedFormArray([]),
  });
  isDisabled$: Observable<boolean>;
  willExceedUserCount$: Observable<boolean>;
  isMonthly$ = new BehaviorSubject<boolean>(true);
  loading$ = new BehaviorSubject<boolean>(false);

  get usersArray(): TypedFormArray<UserFormData> {
    return this.inviteForm.controls.users as TypedFormArray<UserFormData>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IInviteUserDialogData,
    private _dialog: MatDialogRef<InviteUserDialogComponent, IUser[]>,
    private _org: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    const activatedUserCount$ = this._org.enabledUsers$.pipe(
      map((users) => users.length)
    );
    const allocatedUserCount$ = this._org.organisation$.pipe(
      filterUndefined(),
      map((org) => org.subscription),
      filterUndefined(),
      tap((subscription) =>
        subscription.id.includes('monthly')
          ? this.isMonthly$.next(true)
          : this.isMonthly$.next(false)
      ),
      map((sub) => parseInt(sub.quantity, 10)),
      startWith(0)
    );

    this.willExceedUserCount$ = combineLatest([
      activatedUserCount$,
      allocatedUserCount$,
    ]).pipe(map(([activated, allotted]) => activated >= allotted));

    this.isDisabled$ = combineLatest([
      isDisabled$(this.inviteForm),
      this.inviteForm.valueChanges,
    ]).pipe(
      map(([isDisabled, value]) => {
        if (!value.users || value.users.length === 0) {
          return true;
        }
        return isDisabled;
      }),
      startWith(true)
    );
  }

  async addEmails(): Promise<void> {
    const emails = compact(
      (this.inviteForm.controls.emails.value ?? '')
        .toLowerCase()
        .split(new RegExp(/,|\s/))
    );

    const duplicateEmails: string[] = [];
    const validEmails: string[] = [];

    await asyncForEach(emails, async (email) => {
      const existingUser = await snapshot(
        find$(this._data.usersCollection, where('email', '==', email))
      );
      if (existingUser) {
        duplicateEmails.push(email);
        return;
      }

      validEmails.push(email);
    });

    if (duplicateEmails.length) {
      this._snackBar.open(
        `A user already exists for the following email addresses: ${duplicateEmails.join(
          ', '
        )}`
      );
    }

    uniq(validEmails).map((email) => {
      const formGroup = getUserFormGroup();
      formGroup.patchValue({
        email,
      });
      this.usersArray.push(formGroup);
    });

    this.inviteForm.controls.emails.reset('');
  }

  removeEmail(index: number): void {
    this.usersArray.removeAt(index);
  }

  async submit(): Promise<void> {
    this.loading$.next(true);
    const invitedBy = await snapshot(this._org.user$.pipe(filterUndefined()));

    const formData = this.inviteForm.getRawValue();
    const users = formData.users.map((user) =>
      User.init({
        ...user,
        email: user.email.toLowerCase(),
        invitedBy: invitedBy.ref,
      })
    );
    this._dialog.close(users);
    this.loading$.next(false);
  }
}
