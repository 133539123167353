<div class="flex gap-1" *ngIf="bundle$ | async as bundle">
  <mat-icon
    *ngIf="bundle.addToNewWorkspaces"
    matTooltip="Automatically subscribes new workspaces"
  >
    autorenew
  </mat-icon>
  <mat-icon *ngIf="bundle.readOnly" matTooltip="Read only">lock</mat-icon>
  <mat-icon
    *ngIf="bundle.assignToDefaultGroup"
    matTooltip="Automatically assigns everything in this bundle to the default group"
  >
    group_add
  </mat-icon>
  <mat-icon
    *ngIf="isPublic$ | async"
    matTooltip="This bundle is publicly visible"
  >
    public
  </mat-icon>
</div>
