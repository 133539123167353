import { InjectionToken } from '@angular/core';
import { type Angulartics2Settings } from 'angulartics2';

export interface ISubscriptionConfig extends Partial<Angulartics2Settings> {
  gst?: Omit<Angulartics2Settings['gst'], 'trackingIds'> & {
    trackingIds?: string[];
  };
  adsConversion?: {
    trackingIds?: string[];
  };
  ecommerceConversion?: {
    trackingIds?: string[];
  };
}

export const NG_SUBSCRIPTION_CONFIG: InjectionToken<ISubscriptionConfig> =
  new InjectionToken('ISubscriptionConfig');
