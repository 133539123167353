import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { type IUserGroup } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export type GroupFormData = Pick<IUserGroup, 'name' | 'description'>;

@Component({
  selector: 'lu-group-add-dialog',
  templateUrl: './group-add-dialog.component.html',
  styleUrls: ['./group-add-dialog.component.scss'],
})
export class GroupAddDialogComponent {
  form: TypedFormGroup<GroupFormData> = new TypedFormGroup<GroupFormData>({
    name: new TypedFormControl<string>(''),
    description: new TypedFormControl<string>(''),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      GroupAddDialogComponent,
      GroupFormData | undefined
    >
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
