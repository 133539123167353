import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  type OnDestroy,
  EventEmitter,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { type VersionedSchema } from '@principle-theorem/editor';
import {
  BundleSkill,
  Skill,
  type IBundleSkill,
  type IBundleTag,
  type IVendor,
  type IVendorBundle,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import {
  deleteDoc,
  saveDoc,
  shareReplayCold,
  type DocumentReference,
  type IAttachment,
  type IMediaRef,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SkillAttachmentsService } from '../../../../../../lib/services/skill-attachments.service';

@Component({
  selector: 'lu-vendor-bundle-skill',
  templateUrl: './vendor-bundle-skill.component.html',
  styleUrls: ['./vendor-bundle-skill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorBundleSkillComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  form = new TypedFormGroup<Omit<IBundleSkill, 'deleted'>>({
    name: new TypedFormControl<string>(''),
    content: new TypedFormControl<VersionedSchema>(),
    tags: new TypedFormControl<DocumentReference<IBundleTag>[]>(),
    media: new TypedFormControl<IMediaRef[]>(),
    requiresTrainerVerification: new TypedFormControl<boolean>(undefined),
  });
  attachments$: Observable<WithRef<IAttachment>[]>;
  isDisabled$: Observable<boolean>;
  storagePath$: Observable<string>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  skill$ = new ReplaySubject<WithRef<IBundleSkill>>(1);
  vendor$ = new ReplaySubject<WithRef<IVendor>>(1);
  bundle$ = new ReplaySubject<WithRef<IVendorBundle>>(1);
  @Output() saved = new EventEmitter<void>();

  @Input()
  set bundle(bundle: WithRef<IVendorBundle>) {
    if (bundle) {
      this.bundle$.next(bundle);
    }
  }

  @Input()
  set vendor(vendor: WithRef<IVendor>) {
    if (vendor) {
      this.vendor$.next(vendor);
    }
  }

  @Input()
  set skill(skill: WithRef<IBundleSkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  constructor(
    private _route: ActivatedRoute,
    private _dialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public skillAttachments: SkillAttachmentsService
  ) {
    this.skill$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((skill) => this.form.patchValue(skill));

    this.isDisabled$ = isDisabled$(this.form);

    this.attachments$ = this.skill$.pipe(
      switchMap((skill) => Skill.attachments$(skill))
    );
    this.storagePath$ = combineLatest([
      this.vendor$,
      this.bundle$,
      this.skill$,
    ]).pipe(
      map(([vendor, bundle, skill]) =>
        BundleSkill.storagePath(vendor.ref, bundle.ref, skill.ref)
      ),
      shareReplayCold()
    );
    this.breadcrumbs$ = this.bundle$.pipe(
      map((bundle) => [
        { label: 'Bundles', path: '../../../' },
        { label: bundle.name, path: '../../' },
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async delete(skill: WithRef<IBundleSkill>): Promise<void> {
    const confirm = await this._dialog.confirm({
      title: 'Remove Skill',
      prompt: 'Are you sure you want to delete this skill?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }
    await deleteDoc(skill.ref);
    this._snackBar.open('Skill Removed');
    await this._router.navigate(['../../'], {
      relativeTo: this._route,
    });
  }

  async save(skill: WithRef<IBundleSkill>): Promise<void> {
    await saveDoc({
      ...skill,
      ...this.form.getRawValue(),
    });
    this.form.markAsPristine();
    this.saved.emit();
    this._snackBar.open('Skill Updated');
  }
}
