<ng-container *ngIf="contentValid$ | async; else invalidConentError">
  <pt-editor-view
    fxFlex
    [content]="content$ | async"
    [extensions]="extensions$ | async"
    (contentError)="contentError.next()"
  />
</ng-container>
<ng-template #invalidConentError>
  <lu-content-error />
</ng-template>
