import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  currentSkillLevelValue,
  findPendingRequest,
  hasReachedLevel,
  IntercomEvent,
  type ISkill,
  type ISkillLevelRequest,
  type IUser,
  Skill,
  SkillLevel,
  SkillLevelRequestStatus,
  skillWithoutGoal,
} from '@principle-theorem/level-up-core';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  addDoc,
  filterUndefined,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  findTrainerUsersForSkill$,
  findUserSkillProgress,
} from '../../../models/user';
import {
  getLatestSkillRequest$,
  UserSkillProgress,
} from '../../../models/user-skill-progress';
import { OrganisationService } from '../../../services/organisation.service';
import { SkillProgressUpdaterService } from '../../../services/skill-progress-updater.service';
import { LevelUpRequestMentorDialogComponent } from '../level-up-request-mentor-dialog/level-up-request-mentor-dialog.component';

@Component({
    selector: 'lu-skill-progress-actions',
    templateUrl: './skill-progress-actions.component.html',
    styleUrls: ['./skill-progress-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillProgressActionsComponent {
  skill$: ReplaySubject<WithRef<ISkill>> = new ReplaySubject(1);
  isUnviewed$: Observable<boolean>;
  requiresTrainer$: Observable<boolean>;
  hasTrainingRequest$: Observable<boolean>;
  canRequestTrainer$: Observable<boolean>;
  skillProgress: UserSkillProgress;

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  constructor(
    public organisation: OrganisationService,
    private _progressUpdater: SkillProgressUpdaterService,
    private _intercom: Intercom,
    private _dialog: MatDialog
  ) {
    this.skillProgress = new UserSkillProgress(
      this.organisation.userGroups$,
      this.organisation.user$.pipe(filterUndefined()),
      this.skill$
    );

    this.isUnviewed$ = combineLatest([
      this.skill$,
      this.skillProgress.goals$,
      this.skill$.pipe(
        switchMap((skill) =>
          findUserSkillProgress(skill)(
            this.organisation.user$.pipe(filterUndefined())
          )
        )
      ),
    ]).pipe(
      map(([skill, goals, progress]) => {
        const canProactivelyLearn =
          skillWithoutGoal(goals, skill) &&
          (!progress || currentSkillLevelValue(progress) === SkillLevel.None);

        const hasAcheivedGoal = progress
          ? hasReachedLevel(progress, SkillLevel.Viewed)
          : false;

        return canProactivelyLearn || !hasAcheivedGoal;
      })
    );

    this.requiresTrainer$ = combineLatest([
      this.skill$,
      this.skillProgress.goalLevel$,
      this.skill$.pipe(
        switchMap((skill) =>
          findUserSkillProgress(skill)(
            this.organisation.user$.pipe(filterUndefined())
          )
        )
      ),
    ]).pipe(
      map(([skill, goal, progress]) => {
        if (skill.requiresTrainerVerification) {
          return true;
        }

        if ([SkillLevel.VerifiedByTrainer, SkillLevel.Trainer].includes(goal)) {
          return true;
        }

        const hasReachedGoal = progress
          ? hasReachedLevel(progress, SkillLevel.VerifiedByTrainer) ||
            hasReachedLevel(progress, SkillLevel.Trainer)
          : false;
        return hasReachedGoal;
      })
    );

    this.canRequestTrainer$ = this.skill$.pipe(
      switchMap((skill) => this._canRequestTrainer$(skill))
    );

    this.hasTrainingRequest$ = combineLatest([
      this.organisation.user$.pipe(filterUndefined()),
      this.skill$,
    ]).pipe(
      getLatestSkillRequest$(SkillLevelRequestStatus.Pending),
      map((trainingRequest) => (trainingRequest ? true : false))
    );
  }

  async markAsViewed(): Promise<void> {
    await this._setSkillLevel(SkillLevel.Viewed);
    const skill: WithRef<ISkill> = await snapshot(this.skill$);

    this._intercom.trackEvent(IntercomEvent.SelfLevelledUp, {
      skill: skill.name,
      goalSkillLevel: await snapshot(this.skillProgress.goalLevel$),
    });
  }

  async requestTrainer(): Promise<void> {
    const user: WithRef<IUser> = await snapshot(
      this.organisation.user$.pipe(filterUndefined())
    );

    const skill: WithRef<ISkill> = await snapshot(this.skill$);
    const mentorRef = await this._getTrainer(skill);

    await addDoc<ISkillLevelRequest>(Skill.trainingRequestCol(skill), {
      userRef: user.ref,
      status: SkillLevelRequestStatus.Pending,
      mentorRef,
    });

    this._intercom.trackEvent(IntercomEvent.RequestedLevelUp, {
      skill: skill.name,
      currentSkillLevel: await snapshot(this.skillProgress.current$),
      goalSkillLevel: await snapshot(this.skillProgress.goalLevel$),
    });
  }

  async cancelTrainingRequest(): Promise<void> {
    const user: WithRef<IUser> = await snapshot(
      this.organisation.user$.pipe(filterUndefined())
    );
    const skill: WithRef<ISkill> = await snapshot(this.skill$);
    const request = await findPendingRequest(skill, user.ref);
    if (!request) {
      return;
    }
    await patchDoc(request.ref, {
      status: SkillLevelRequestStatus.Cancelled,
    });
  }

  private async _getTrainer(
    skill: WithRef<ISkill>
  ): Promise<DocumentReference<IUser> | undefined> {
    const mentors = await snapshot(
      this.organisation.enabledUsers$.pipe(findTrainerUsersForSkill$(skill))
    );

    if (!mentors.length) {
      return;
    }

    if (mentors.length === 1) {
      return mentors[0].ref;
    }

    const config = DialogPresets.small({ data: { ...skill } });
    return this._dialog
      .open<
        LevelUpRequestMentorDialogComponent,
        WithRef<ISkill>,
        DocumentReference<IUser>
      >(LevelUpRequestMentorDialogComponent, config)
      .afterClosed()
      .toPromise();
  }

  private async _setSkillLevel(level: SkillLevel): Promise<void> {
    const user: WithRef<IUser> = await snapshot(
      this.organisation.user$.pipe(filterUndefined())
    );
    const skill: WithRef<ISkill> = await snapshot(this.skill$);
    await this._progressUpdater.upsert(user, skill, level);
  }

  private _canRequestTrainer$(skill: WithRef<ISkill>): Observable<boolean> {
    return this.organisation.user$.pipe(
      filterUndefined(),
      findUserSkillProgress(skill),
      map((progress) =>
        progress ? !hasReachedLevel(progress, SkillLevel.Trainer) : true
      )
    );
  }
}
