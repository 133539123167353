import {
  type IOrganisation,
  type ISkillRef,
  type ITypesenseVendorSkill,
  MarketplaceTypesenseCollection,
  Organisation,
  SkillRefType,
  type SkillType,
} from '@principle-theorem/level-up-core';
import { TypesenseSearchService } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { ITypesenseConfig } from '@principle-theorem/typesense';
import { compact } from 'lodash';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';

export class VendorSkillListBloc {
  results$: Observable<SearchResponse<ITypesenseVendorSkill>>;

  constructor(
    private _typesense: TypesenseSearchService,
    config: ITypesenseConfig,
    organisation$: Observable<WithRef<IOrganisation>>,
    search$: Observable<string>,
    filterSkills$: Observable<ISkillRef<SkillType>[]>,
    page$ = of(1)
  ) {
    const searchParams$: Observable<SearchParams> = combineLatest([
      search$,
      filterSkills$.pipe(map(skillsToVendorRefFilter)),
      page$,
    ]).pipe(
      map(([search, skillsFilter, page]) => ({
        q: search,
        query_by: ['name', 'tagNames'].join(','),
        per_page: 50,
        page,
        filter_by: compact([
          skillsFilter,
          'deleted:false',
          'visibility:public',
        ]).join(' && '),
      }))
    );

    this.results$ = organisation$.pipe(
      switchMap((organisation) =>
        this._typesense.query$<ITypesenseVendorSkill>(
          Organisation.integrationCol(organisation),
          MarketplaceTypesenseCollection.Skills,
          searchParams$,
          config
        )
      )
    );
  }
}

function skillsToVendorRefFilter(
  skills: ISkillRef<SkillType>[]
): string | undefined {
  const filtered = skills.filter(
    (skillRef) => skillRef.type === SkillRefType.BundleSkill
  );
  return filtered.length
    ? `vendorSkillRef:[${skills.map((skill) => skill.ref.path).join(',')}]`
    : undefined;
}
