<ng-container *ngIf="skills$ | async as skills; else loadingSkills">
  <ng-container *ngIf="skills.length; else noSkills">
    <lu-skill-list-card
      *ngFor="let skill of skills; trackBy: trackBySkill"
      [skill]="skill"
      [user]="user$ | async"
      [link]="['/explore/skill', skill.ref.id]"
    />
  </ng-container>
</ng-container>

<ng-template #loadingSkills>
  <div class="flex flex-col gap-2">
    <lu-skill-list-card-placeholder />
    <lu-skill-list-card-placeholder />
    <lu-skill-list-card-placeholder />
  </div>
</ng-template>

<ng-template #noSkills>
  <div class="mat-caption flex items-center p-4">No Skills</div>
</ng-template>
