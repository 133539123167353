import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export interface IMarketplaceItemFormData {
  name: string;
}

export interface IAddMarketplaceItemData {
  label: string;
}

@Component({
    selector: 'lu-add-marketplace-item-dialog',
    templateUrl: './add-marketplace-item-dialog.component.html',
    styleUrls: ['./add-marketplace-item-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddMarketplaceItemDialogComponent {
  form: TypedFormGroup<IMarketplaceItemFormData> =
    new TypedFormGroup<IMarketplaceItemFormData>({
      name: new TypedFormControl<string>('', Validators.required),
    });

  constructor(
    private _dialogRef: MatDialogRef<
      AddMarketplaceItemDialogComponent,
      IMarketplaceItemFormData | undefined
    >,
    @Inject(MAT_DIALOG_DATA) public data: IAddMarketplaceItemData
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
