import { Injectable } from '@angular/core';
import {
  resolveMetadataDocPath,
  type ILoggerMetadataResolver,
} from '@principle-theorem/ng-shared';
import * as LogRocket from 'logrocket';
import { OrganisationService } from './organisation.service';

interface ILevelUpLoggerMetadata {
  logRocketSessionUrl?: string;
  organisation?: string;
  user?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LevelUpLoggerMetadataResolverService
  implements ILoggerMetadataResolver<ILevelUpLoggerMetadata>
{
  constructor(private _organisation: OrganisationService) {}

  async resolveMetadata(): Promise<ILevelUpLoggerMetadata> {
    return {
      logRocketSessionUrl: LogRocket.sessionURL || undefined,
      organisation: await resolveMetadataDocPath(
        this._organisation.organisation$
      ),
      user: await resolveMetadataDocPath(this._organisation.user$),
    };
  }
}
