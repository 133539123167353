import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICSVExportOptions } from '../../csv-exporter.service';
import { TypedFormControl, TypedFormGroup } from '../../forms/typed-form-group';

export type DownloadReportSettingsDialogResult = ICSVExportOptions & {
  fileName: string;
};

export interface IDownloadReportSettingsDialogData {
  defaultFileName: string;
}

@Component({
    selector: 'pt-download-report-settings-dialog',
    templateUrl: './download-report-settings-dialog.component.html',
    styleUrl: './download-report-settings-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DownloadReportSettingsDialogComponent {
  form = new TypedFormGroup<DownloadReportSettingsDialogResult>({
    includeHeaders: new TypedFormControl<boolean>(true),
    includeTotals: new TypedFormControl<boolean>(true),
    fileName: new TypedFormControl<string>(''),
  });

  constructor(
    public dialogRef: MatDialogRef<
      DownloadReportSettingsDialogComponent,
      DownloadReportSettingsDialogResult
    >,
    @Inject(MAT_DIALOG_DATA)
    data: IDownloadReportSettingsDialogData
  ) {
    this.form.controls.fileName.setValue(data.defaultFileName);
  }
}
