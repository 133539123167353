import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  Folder,
  IFolder,
  IOrganisation,
  IPathway,
  ISkill,
  IntercomEvent,
  MentionResourceType,
  Organisation,
  filterPathwaysByStatus,
  pathwayStatusByUserStatus,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import {
  DocumentReference,
  INamedDocument,
  WithRef,
  Firestore,
  addDoc,
  filterUndefined,
  isSameRef,
  multiFilter,
  multiMap,
  snapshot,
  undeletedQuery,
  query,
  where,
  getCount,
  toQuery,
  Query,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { LoadPathwayActionService } from '../../../services/contextual-actions/routing-actions/load-pathway-action.service';
import { LoadSkillActionService } from '../../../services/contextual-actions/routing-actions/load-skill-action.service';
import { OrganisationService } from '../../../services/organisation.service';
import { UserSettingsStoreService } from '../../../services/user-settings-store.service';
import { CreatePathwayActionService } from '../../search-dialog/create-pathway-action.service';
import { CreateSkillActionService } from '../../search-dialog/create-skill-action.service';
import { PathwayLoader } from '../../search-dialog/pathway-loader';
import { SkillLoader } from '../../search-dialog/skill-loader';
import {
  FolderAddDialogComponent,
  FolderFormData,
  IFolderAddDialogData,
} from '../folder-add-dialog/folder-add-dialog.component';
import {
  FolderEditDialogComponent,
  IFolderEditDialogData,
} from '../folder-edit-dialog/folder-edit-dialog.component';

export interface IFolderNode extends INamedDocument {
  type: MentionResourceType;
  parentType: MentionResourceType;
  icon: string;
  url?: string;
  expandable?: boolean;
  canAddFolder?: boolean;
  isLoading?: boolean;
  content$?: Observable<IFolderNode[]>;
  skill?: WithRef<ISkill>;
  pathway?: WithRef<IPathway>;
}

@Component({
  selector: 'lu-folders-list',
  templateUrl: './folders-list.component.html',
  styleUrls: ['./folders-list.component.scss'],
  providers: [SelectionListStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersListComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  treeControl: NestedTreeControl<IFolderNode, string>;
  dataSource: MatTreeNestedDataSource<IFolderNode>;
  selectMode = false;

  constructor(
    private _organisation: OrganisationService,
    private _createSkill: CreateSkillActionService,
    private _createPathway: CreatePathwayActionService,
    private _loadSkill: LoadSkillActionService,
    private _loadPathway: LoadPathwayActionService,
    private _dialog: BasicDialogService,
    private _intercom: Intercom,
    private _snackBar: MatSnackBar,
    private _settings: UserSettingsStoreService,
    public selectionList: SelectionListStore<IFolderNode>,
    private _cache: CachedListsService
  ) {
    this.dataSource = new MatTreeNestedDataSource<IFolderNode>();
    this.treeControl = new NestedTreeControl<IFolderNode, string>(
      this.getChildren,
      {
        trackBy: this.trackByNode,
      }
    );

    this.selectionList.setCompareFn((currentItem, newItem) =>
      isSameRef(currentItem, newItem)
    );

    this._settings.sidebar$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((sidebarSettings) => {
        if (!sidebarSettings.folderState) {
          return;
        }
        this.treeControl.expansionModel.setSelection(
          ...sidebarSettings.folderState
        );
      });

    this._cache.folders$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((folders) => {
        this.dataSource.data = this._buildFolderNodes(folders);
      });

    this.treeControl.expansionModel.changed
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        const selected = this.treeControl.expansionModel.selected;
        this._settings.updateUserSettings({
          sidebar: {
            folderState: selected,
          },
        });
      });
  }

  trackByNode = (item: IFolderNode): string => {
    return item.ref.id;
  };

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addNewFolder(
    parentFolderRef?: DocumentReference<IFolder>
  ): Promise<void> {
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );

    const data: MatDialogConfig<IFolderAddDialogData> = {
      data: { parentFolderRef },
    };

    const folder = await this._dialog.mobileFullscreen<
      FolderAddDialogComponent,
      IFolderAddDialogData,
      FolderFormData
    >(FolderAddDialogComponent, DialogPresets.small(data));

    if (!folder) {
      return;
    }

    await addDoc(Organisation.folderCol(organisation), Folder.init(folder));

    this._intercom.trackEvent(IntercomEvent.AddedFolder, {
      name: folder.name,
    });

    this._snackBar.open('Folder Added');
  }

  async editFolder(folderRef: DocumentReference<IFolder>): Promise<void> {
    const folder = await Firestore.getDoc(folderRef);
    const data: MatDialogConfig<IFolderEditDialogData> = {
      data: { folder },
    };

    const updated = await this._dialog.mobileFullscreen<
      FolderEditDialogComponent,
      IFolderEditDialogData,
      FolderFormData
    >(FolderEditDialogComponent, DialogPresets.small(data));

    if (!updated) {
      return;
    }

    await Firestore.patchDoc(folderRef, {
      name: updated.name,
      parentFolderRef: updated.parentFolderRef,
    });

    await this._handleNestedFolders(folder, updated.parentFolderRef);

    this._snackBar.open('Folder Updated');
  }

  async addNewSkill(folderRef?: DocumentReference<IFolder>): Promise<void> {
    await this._createSkill.do('', folderRef);
  }

  async addNewPathway(folderRef?: DocumentReference<IFolder>): Promise<void> {
    await this._createPathway.do('', folderRef);
  }

  getChildren(node: IFolderNode): Observable<IFolderNode[]> {
    if (!node.content$) {
      return of([]);
    }
    node.isLoading = true;
    return node.content$.pipe(tap(() => (node.isLoading = false)));
  }

  hasChild(_index: number, node: IFolderNode): boolean {
    return node.expandable ?? false;
  }

  isFolder(node: IFolderNode): boolean {
    return node.type === MentionResourceType.Folder;
  }

  isSkill(node: IFolderNode): boolean {
    return node.type === MentionResourceType.Skill;
  }

  isPathway(node: IFolderNode): boolean {
    return node.type === MentionResourceType.Pathway;
  }

  collapseAllFolders(): void {
    this.treeControl.collapseAll();
  }

  toolbarClosed(): void {
    if (this.selectMode) {
      this.selectMode = false;
    }
  }

  toggleSelected(node: IFolderNode): void {
    this.selectionList.toggleSelected(node);
  }

  async refreshTree(): Promise<void> {
    const folders = await snapshot(this._cache.folders$);
    this.dataSource.data = this._buildFolderNodes(folders);
  }

  private _buildFolderNodes(
    folders: WithRef<IFolder>[],
    parentFolderRef?: DocumentReference<IFolder>
  ): IFolderNode[] {
    return [
      ...folders
        .filter((folder) =>
          parentFolderRef
            ? isSameRef(folder.parentFolderRef, parentFolderRef)
            : !folder.parentFolderRef
        )
        .map((folder) => {
          const subFolders$ = of(
            this._buildFolderNodes(
              folders.filter((subFolder) =>
                isSameRef(subFolder.parentFolderRef, folder)
              ),
              folder.ref
            )
          );

          const pathways$ = this._getFolderPathways$(folder.ref);
          const skills$ = this._getFolderSkills$(folder.ref);

          return {
            ...folder,
            canAddFolder: parentFolderRef ? false : true,
            expandable: true,
            icon: 'folder',
            type: MentionResourceType.Folder,
            parentType: MentionResourceType.Folder,
            content$: combineLatest([subFolders$, pathways$, skills$]).pipe(
              map(
                ([subFolders, skills, pathways]) =>
                  [...subFolders, ...skills, ...pathways] as IFolderNode[]
              )
            ),
          };
        }),
    ];
  }

  private _getFolderSkills$(
    folderRef?: DocumentReference<IFolder>
  ): Observable<IFolderNode[]> {
    return this._cache.skills$
      .pipe(multiFilter((skill) => isSameRef(skill.folderRef, folderRef)))
      .pipe(
        multiMap((skill) => {
          const loader = new SkillLoader(skill, this._loadSkill);
          return {
            ...loader,
            parentType: MentionResourceType.Folder,
            url: loader.url(),
          };
        })
      );
  }

  private _getFolderPathways$(
    folderRef?: DocumentReference<IFolder>
  ): Observable<IFolderNode[]> {
    return combineLatest([
      this._organisation.user$,
      this._cache.pathways$,
    ]).pipe(
      pathwayStatusByUserStatus(),
      filterPathwaysByStatus(),
      multiFilter((pathway) => isSameRef(pathway.folderRef, folderRef)),
      multiMap((pathway) => {
        const loader = new PathwayLoader(
          pathway,
          this._loadPathway,
          this._cache
        );
        return {
          ...loader,
          parentType: MentionResourceType.Folder,
          url: loader.url(),
          expandable: false,
          content$: loader.steps$().pipe(
            multiMap((skill) => {
              const skillLoader = new SkillLoader(skill, this._loadSkill);
              return {
                ...skillLoader,
                parentType: MentionResourceType.Pathway,
                url: skillLoader.url(pathway.ref),
              };
            })
          ),
        };
      })
    );
  }

  private async _handleNestedFolders(
    folder: WithRef<IFolder>,
    updatedParentFolderRef?: DocumentReference<IFolder>
  ): Promise<void> {
    if (!updatedParentFolderRef) {
      return;
    }

    const confirmed = await this._dialog.confirm({
      title: 'Confirm Move Folder',
      prompt:
        'This folder contains sub-folders and will be merged with the target folder. Are you sure you want to proceed?',
      submitLabel: 'Move Folder',
    });
    if (!confirmed) {
      return;
    }

    const orgRef = Firestore.getParentDocRef<IOrganisation>(folder.ref);
    const hasChildren = await getCount(
      this._getNestedFolderQuery(orgRef, folder.ref)
    );
    const isUpdatingParentFolder =
      updatedParentFolderRef &&
      !isSameRef(folder.parentFolderRef, updatedParentFolderRef);

    if (hasChildren && isUpdatingParentFolder) {
      await this._updateNestedFolders(
        folder.ref,
        updatedParentFolderRef,
        orgRef
      );
    }
  }

  private async _updateNestedFolders(
    folderRef: DocumentReference<IFolder>,
    updatedParentFolderRef: DocumentReference<IFolder>,
    orgRef: DocumentReference<IOrganisation>
  ): Promise<void> {
    const childFolders = await query(
      this._getNestedFolderQuery(orgRef, folderRef)
    );

    for (const folder of childFolders) {
      await Firestore.patchDoc(folder.ref, {
        parentFolderRef: updatedParentFolderRef,
      });
    }
  }

  private _getNestedFolderQuery(
    orgRef: DocumentReference<IOrganisation>,
    folderRef: DocumentReference<IFolder>
  ): Query<IFolder> {
    return toQuery(
      undeletedQuery(Organisation.folderCol({ ref: orgRef })),
      where('parentFolderRef', '==', folderRef)
    );
  }
}
