import { chunk, get, has, isEqual, omitBy, sortBy, xorWith } from 'lodash';
import {
  Observable,
  combineLatest,
  from,
  of,
  type MonoTypeOperatorFunction,
  type ObservableInput,
  type OperatorFunction,
  type Subscriber,
} from 'rxjs';
import {
  auditTime,
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  // eslint-disable-next-line no-restricted-imports
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isObject } from './common';
import { type IReffable } from './firebase/interfaces';
import { type TypeGuardFn, type UnwrapArray } from './utility-types';

export function firstValueFrom<T, D = undefined>(
  source: Observable<T>
): Promise<T | D> {
  return source.pipe(take(1)).toPromise();
}

export function snapshot<T>(item$: Observable<T>): Promise<T> {
  return item$.pipe(take(1)).toPromise();
}

export async function snapshotDefined<T>(
  item$: Observable<T | undefined>
): Promise<T> {
  return snapshot(item$.pipe(filterUndefined()));
}

export function filterUndefined<T>(): OperatorFunction<T | undefined, T> {
  return filter((item): item is T => item !== undefined);
}

export function omitUndefined<T extends object>(data: T): Partial<T> {
  return omitBy(data, (value) => value === undefined);
}

export function filterNil<T>(): OperatorFunction<T | undefined | null, T> {
  return filter(
    // eslint-disable-next-line no-null/no-null
    (item): item is T => item !== undefined && item !== null
  );
}

/**
 * This should only be used in cases where you want the observable stream to error.
 * In most cases `filterNil`/`filterUndefined` should be used instead.
 */
export function errorNil<T>(
  message: string = 'Value is nil'
): OperatorFunction<T | undefined | null, T> {
  return filter((item): item is T => {
    // eslint-disable-next-line no-null/no-null
    if (item === undefined || item === null) {
      throw new Error(message);
    }
    return true;
  });
}

export function filterNull<T>(): OperatorFunction<T | null, T> {
  // eslint-disable-next-line no-null/no-null
  return filter((item: T | null): item is T => item !== null);
}

export function asBoolean<T>(): OperatorFunction<T | undefined, boolean> {
  return map((item) => !!item);
}

export function count<T>(): OperatorFunction<T[], number> {
  return map((items) => items.length);
}

export function distinctUntilKeysChanged<T, K extends keyof T>(
  ...keys: K[]
): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged((a, b) => {
    return keys.every((key) => a[key] === b[key]);
  });
}

export function distinctUntilPathsChange<T>(
  ...paths: string[]
): OperatorFunction<T, T> {
  return distinctUntilChanged<T>((before, after) => {
    return paths.every((path) => get(before, path) === get(after, path));
  });
}

export function isChanged$<T>(
  isSameFn: (before: UnwrapArray<T>, after: UnwrapArray<T>) => boolean = isEqual
): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged<T>((before, after) => {
    if (Array.isArray(before) && Array.isArray(after)) {
      return (
        before.length === after.length &&
        xorWith(before, after, isSameFn).length === 0 &&
        (before as UnwrapArray<T>[]).every((item, index) => {
          const afterArray = after as UnwrapArray<T>[];
          return isSameFn(item, afterArray[index]);
        })
      );
    }

    if (!Array.isArray(before) && !Array.isArray(after)) {
      return isSameFn(before as UnwrapArray<T>, after as UnwrapArray<T>);
    }

    return false;
  });
}

export function isPathChanged$<T>(
  path: string,
  isSameFn?: (before: unknown, after: unknown) => boolean
): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged<T>((before, after) => {
    if (isSameFn) {
      return isSameFn(get(before, path) as T, get(after, path) as T);
    }
    return get(before, path) === get(after, path);
  });
}

export function isRefChanged$<
  T extends IReffable,
>(): MonoTypeOperatorFunction<T> {
  return isPathChanged$('ref.path');
}

export function multiFind<T>(
  predicate: (item: T) => boolean
): OperatorFunction<T[], T | undefined> {
  return map((items) => items.find(predicate));
}

export function multiMap<T, R>(
  mapItem: (item: T) => R
): OperatorFunction<T[], R[]> {
  return map((items) => items.map((item) => mapItem(item)));
}

export function multiFilter<T>(
  filterFn: (item: T, index: number) => boolean
): MonoTypeOperatorFunction<T[]> {
  return map((items) =>
    items.filter((item, index): item is T => filterFn(item, index))
  );
}

export function multiSort<T>(
  sortFn?: (a: T, b: T) => number
): MonoTypeOperatorFunction<T[]> {
  if (!sortFn) {
    return map((items) => items.sort());
  }
  return map((items) => items.sort((a, b) => sortFn(a, b)));
}

export function multiSortBy$<T>(
  iteratee: (item: T) => string | number
): MonoTypeOperatorFunction<T[]> {
  return map((items) => sortBy(items, iteratee));
}

export function multiLimit<T>(limit: number): MonoTypeOperatorFunction<T[]> {
  return map((items) => items.slice(0, limit));
}

export function multiLimit$<T>(
  limit$: Observable<number | undefined>
): OperatorFunction<T[], T[]> {
  return (src$: Observable<T[]>) =>
    limit$.pipe(
      switchMap((limit) =>
        limit === undefined ? src$ : src$.pipe(multiLimit(limit))
      )
    );
}

export function multiReduce<T, R>(
  callbackFn: (
    previousValue: R,
    currentValue: T,
    currentIndex: number,
    array: T[]
  ) => R,
  initialValue: R
): OperatorFunction<T[], R> {
  return map((items) =>
    !items.length
      ? initialValue
      : items.reduce(
          (acc, item, index, all) => callbackFn(acc, item, index, all),
          initialValue
        )
  );
}

export function combineReduce<T>(items: Observable<T[]>[]): Observable<T[]> {
  return safeCombineLatest(items).pipe(reduce2DArray());
}

export function debug<T>(
  label: string,
  metadata?: object
): MonoTypeOperatorFunction<T> {
  return tap<T>((data) =>
    // eslint-disable-next-line no-console
    console.log(label, metadata ? { metadata, data } : data)
  );
}

export function multiSwitchMap<T, R>(
  callback: (item: T) => ObservableInput<R>
): OperatorFunction<T[], R[]> {
  return switchMap((items: T[]) => safeCombineLatest(items.map(callback)));
}

export function multiConcatMap<T, R>(
  callback: (item: T) => ObservableInput<R>
): OperatorFunction<T[], R[]> {
  return concatMap((items: T[]) => safeCombineLatest(items.map(callback)));
}

export function multiMergeMap<T, R>(
  concurrency: number,
  callback: (item: T) => ObservableInput<R>
): OperatorFunction<T[], R> {
  return (source$: Observable<T[]>) =>
    source$.pipe(
      mergeMap((items) => from(items), 1),
      mergeMap(callback, concurrency)
    );
}

export function reduce2DArray<T>(): OperatorFunction<T[][], T[]> {
  return map((items: T[][]) =>
    items.reduce((previous: T[], current: T[]) => previous.concat(current), [])
  );
}

export function switchMapNew<T, R>(
  callback: (item: T, sub: Subscriber<R>) => void
): OperatorFunction<T, R> {
  return switchMap(
    (item: T) =>
      new Observable<R>((sub: Subscriber<R>) => {
        callback(item, sub);
      })
  );
}

export function findProp<T>(
  property: string
): OperatorFunction<unknown, T | undefined> {
  return (source$: Observable<unknown>) =>
    source$.pipe(
      map((item) =>
        isObject(item) && has(item, property)
          ? (get(item, property) as T)
          : undefined
      )
    );
}

export function guardFilter<T>(
  typeGuardFn: TypeGuardFn<T>
): OperatorFunction<unknown, T> {
  return filter((item): item is T => typeGuardFn(item));
}

export function errorGuard<T>(
  typeGuardFn: TypeGuardFn<T>
): OperatorFunction<unknown, T> {
  return filter((item): item is T => {
    if (!typeGuardFn(item)) {
      throw new GuardError('Guard failed');
    }
    return true;
  });
}

export class GuardError extends Error {}

export const UNIVERSAL_USER_INPUT_DEBOUNCE = 250;

export function debounceUserInput<T>(
  debounceMs: number = UNIVERSAL_USER_INPUT_DEBOUNCE
): MonoTypeOperatorFunction<T> {
  return debounceTime<T>(debounceMs);
}

export function auditUserInput<T>(): MonoTypeOperatorFunction<T> {
  return auditTime<T>(UNIVERSAL_USER_INPUT_DEBOUNCE);
}

export function safeCombineLatest<T>(
  items: ObservableInput<T>[]
): Observable<T[]> {
  if (!items.length) {
    return of([]);
  }
  return combineLatest(items);
}

export function safeChunk<T>(items: T[], chunkSize: number): Observable<T[]> {
  if (items.length) {
    return from(chunk(items, chunkSize));
  }
  return of([]);
}

export function some(): OperatorFunction<boolean[], boolean> {
  return map((items) => items.some((item) => item));
}

export function every(): OperatorFunction<boolean[], boolean> {
  return map((items) => items.every((item) => item));
}

export function shareReplayCold<T>(): MonoTypeOperatorFunction<T> {
  return shareReplay({ bufferSize: 1, refCount: true });
}

export function shareReplayHot<T>(
  destroy$: Observable<unknown>
): MonoTypeOperatorFunction<T> {
  return (source$: Observable<T>) =>
    source$.pipe(
      takeUntil(destroy$),
      shareReplay({ bufferSize: 1, refCount: false })
    );
}

export function defaultOnError<T, R>(
  defaultValue: R,
  shouldLogError: boolean = false
): OperatorFunction<T, T | R> {
  return catchError((error) => {
    if (shouldLogError) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return of(defaultValue);
  });
}

export function singleValue<T>(): OperatorFunction<T | T[], T> {
  return map((item) => (Array.isArray(item) ? item[0] : item));
}

/**
 * This is a snapshot version of combineLatest that completes after the first emission.
 */
export function snapshotCombineLatest<
  T extends readonly ObservableInput<unknown>[],
>(
  sources: [...T]
): Observable<{
  [K in keyof T]: T[K] extends ObservableInput<infer U> ? U : never;
}> {
  return combineLatest(sources).pipe(
    take(1),
    map(
      (values) =>
        values as {
          [K in keyof T]: T[K] extends ObservableInput<infer U> ? U : never;
        }
    )
  );
}
