<lu-page-splash>
  <div fxLayout="column" fxLayoutAlign="center center">
    <img src="/assets/icons/not-found.svg" />

    <h1 class="mat-headline-4">Sorry...</h1>

    <p class="mat-caption">
      The page you were trying to reach isn't available. It may have been moved
      or the address may be incorrect.
    </p>

    <button mat-flat-button color="primary" (click)="location.back()">
      Go back
    </button>
  </div>
</lu-page-splash>
