import { reduceToSingleArray } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { sum } from 'lodash';
import { type IUser } from '../user';
import { type IUserStatistics, reduceStats } from '../user-statistics';

export interface IUserStatisticsResult {
  userRef: DocumentReference<IUser>;
  stats: Partial<IUserStatistics>;
}

export function createResult(
  userRef: DocumentReference<IUser>,
  stats: Partial<IUserStatistics>
): IUserStatisticsResult {
  return { userRef, stats };
}

export interface IUserStatValue {
  userRef: DocumentReference<IUser>;
  value: number;
}

export function statValue(
  userRef: DocumentReference<IUser>,
  value: number
): IUserStatValue {
  return { userRef, value };
}

export function statValueToResult(
  key: string,
  stat: IUserStatValue
): IUserStatisticsResult {
  return {
    userRef: stat.userRef,
    stats: {
      [key]: stat.value,
    },
  };
}

export function upsertStatResult(
  currentResults: IUserStatisticsResult[],
  result: IUserStatisticsResult
): IUserStatisticsResult[] {
  const existing = currentResults.find(
    (item) => item.userRef.path === result.userRef.path
  );
  if (!existing) {
    return [...currentResults, result];
  }
  existing.stats = reduceStats([existing.stats, result.stats]);
  return currentResults;
}

export function reduce2DStatResults(
  results: IUserStatisticsResult[][]
): IUserStatisticsResult[] {
  return reduceStatResults(reduceToSingleArray(results));
}

export function reduceStatResults(
  results: IUserStatisticsResult[]
): IUserStatisticsResult[] {
  return results.reduce(upsertStatResult, []);
}

export function reduceStatValues(results: IUserStatValue[]): IUserStatValue[] {
  return results.reduce((all: IUserStatValue[], result: IUserStatValue) => {
    const existing = results.find(
      (item) => item.userRef.path === result.userRef.path
    );
    if (!existing) {
      return [...all, result];
    }
    existing.value = sum([existing.value, result.value]);
    return results;
  }, []);
}

export function findResultStats(
  currentResults: IUserStatisticsResult[],
  userRef: DocumentReference<IUser>
): Partial<IUserStatistics> {
  const result = currentResults.find(
    (stat) => stat.userRef.path === userRef.path
  );
  return result ? result.stats : {};
}
