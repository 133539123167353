import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  type OnDestroy,
} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  type ControlValueAccessor,
} from '@angular/forms';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import {
  FloatLabelType,
  MatFormFieldAppearance,
  SubscriptSizing,
} from '@angular/material/form-field';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  Time24hrType,
  mergeDayAndTime,
  to24hrTime,
} from '@principle-theorem/shared';
import { MatTimepickerModule } from 'mat-timepicker';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';

@Component({
    selector: 'pt-iso-time-selector',
    templateUrl: './iso-time-selector.component.html',
    styleUrls: ['./iso-time-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IsoTimeSelectorComponent),
            multi: true,
        },
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatTimepickerModule,
        NgMaterialModule,
    ]
})
export class IsoTimeSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  private _changed$ = new Subject<Time24hrType | undefined>();
  private _touched$ = new Subject<void>();
  timeControl = new TypedFormControl<Date | undefined>(undefined);
  showOnDirtyErrorStateMatcher = new ShowOnDirtyErrorStateMatcher();

  @Input() subscriptSizing: SubscriptSizing = 'dynamic';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() floatLabel: FloatLabelType = 'auto';

  constructor() {
    this.timeControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((newDate) => {
        const as24HrTime = newDate ? to24hrTime(newDate) : undefined;
        this._changed$.next(as24HrTime);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value?: Time24hrType): void {
    const asDate = value
      ? mergeDayAndTime(moment(), value).toDate()
      : undefined;
    this.timeControl.setValue(asDate, { emitEvent: false });
    this.timeControl.updateValueAndValidity();
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeControl.disable();
      return;
    }
    this.timeControl.enable();
  }

  clear(): void {
    this.timeControl.reset(undefined);
  }
}
