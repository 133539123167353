<lu-page-simple marginBottom="16">
  <lu-page-header
    label="Tags"
    pageInfo="Tags help categorise your content so it's easy to find"
    [pageTour]="tourId"
  >
    <lu-page-toolbar>
      <lu-header-button
        buttonIcon="add"
        buttonText="Create"
        (click)="add()"
        ptIntercomTarget="tags.create-button"
        luPageToolbarAction
      />
    </lu-page-toolbar>
  </lu-page-header>

  <lu-page-toolbar>
    <pt-search-field fxFlex>
      <input matInput [formControl]="searchInput" placeholder="Filter" />
    </pt-search-field>
  </lu-page-toolbar>

  <mat-list class="with-dividers">
    <ng-container *ngIf="tagSearch.results$ | async as tags; else loading">
      <div
        *ngFor="let tag of tags; trackBy: trackByTag"
        class="mat-mdc-list-item"
      >
        <div class="my-2 flex items-center justify-between">
          <mat-chip-listbox matListItemTitle class="compact">
            <mat-chip
              [style.--mdc-chip-label-text-color]="tag | tagTextColour"
              [style.background-color]="tag.hexColour"
              ptIntercomTarget="tags.tag.name"
              (click)="edit(tag)"
            >
              {{ tag.name }}
            </mat-chip>
          </mat-chip-listbox>
          <div class="flex items-center gap-4">
            <button
              mat-stroked-button
              ptIntercomTarget="tags.edit-button"
              (click)="edit(tag)"
            >
              Edit
            </button>
            <button
              mat-icon-button
              ptIntercomTarget="tags.delete-button"
              color="warn"
              (click)="remove(tag)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="layout-margin flex flex-col gap-2">
        <lu-tag-placeholder />
        <lu-tag-placeholder />
        <lu-tag-placeholder />
        <lu-tag-placeholder />
        <lu-tag-placeholder />
        <lu-tag-placeholder />
      </div>
    </ng-template>
  </mat-list>
</lu-page-simple>
