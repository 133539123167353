<ng-container *ngIf="image$ | async; else placeholder">
  <img *ngIf="imageSrc$ | async as imageSrc" [src]="imageSrc" />
</ng-container>
<ng-template #placeholder>
  <div
    class="flex h-[180px] grow items-center justify-center border-8 border-solid border-gray-200"
  >
    <mat-icon class="!h-20 !w-20 rounded-full text-[80px] text-gray-300"
      >school</mat-icon
    >
  </div>
</ng-template>
