import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { IsAdminGuard } from './auth/is-admin.guard';
import { IsAuthorisedGuard } from './auth/is-authorised.guard';
import { IsManagementGuard } from './auth/is-management.guard';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import {
  IsAuthenticatedGuard,
  HasWorkspaceGuard,
} from '@principle-theorem/ng-auth';
import { UserProfileComponent } from './pages/auth/user-profile/user-profile.component';
import { AccountNotificationsComponent } from './pages/auth/account-notifications/account-notifications.component';
import { VendorRedirectGuard } from './auth/vendor-redirect.guard';
import { WorkspaceSetupRedirectGuard } from './auth/workspace-setup-redirect.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [
      IsAuthenticatedGuard,
      VendorRedirectGuard,
      IsAuthorisedGuard,
      HasWorkspaceGuard,
      WorkspaceSetupRedirectGuard,
    ],
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'user-profile',
        data: { title: 'User Profile' },
        component: UserProfileComponent,
      },
      {
        path: 'notifications',
        data: { title: 'Notifications' },
        component: AccountNotificationsComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/skills/skills.module').then(
            (mod) => mod.SkillsModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/dashboards/dashboards.module').then(
            (mod) => mod.DashboardsModule
          ),
      },
      {
        path: 'admin',
        canActivate: [IsAdminGuard],
        loadChildren: () =>
          import('./pages/admin/admin-routing.module').then(
            (mod) => mod.AdminRoutingModule
          ),
      },
    ],
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/auth/auth-pages.module').then(
        (mod) => mod.AuthPagesModule
      ),
  },
  {
    path: 'marketplace',
    loadChildren: () =>
      import('./pages/marketplace/marketplace-routing.module').then(
        (mod) => mod.MarketplaceRoutingModule
      ),
  },
  {
    path: 'management',
    canActivate: [IsAuthenticatedGuard, IsManagementGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/management/management-routing.module').then(
            (mod) => mod.ManagementRoutingModule
          ),
      },
    ],
  },
  {
    path: 'workflows',
    loadChildren: () =>
      import('./pages/workflows/workflows.module').then(
        (mod) => mod.WorkflowsModule
      ),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LevelUpRoutingModule {}
