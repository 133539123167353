import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { type IPathway, type IUser } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPathwayProgress } from '../../../models/user-pathway-progress';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
    selector: 'lu-user-pathway-goals-indicator',
    templateUrl: './user-pathway-goals-indicator.component.html',
    styleUrls: ['./user-pathway-goals-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserPathwayGoalsIndicatorComponent {
  private _pathway$ = new ReplaySubject<WithRef<IPathway>>(1);
  private _user$ = new ReplaySubject<WithRef<IUser>>(1);
  tooltip$: Observable<string>;
  progress: UserPathwayProgress;
  @Input() diameter = 24;

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  @HostBinding('style.font-size.px')
  get size(): number {
    return this.diameter;
  }

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this._pathway$.next(pathway);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }

  constructor(organisation: OrganisationService, cache: CachedListsService) {
    this.progress = new UserPathwayProgress(
      organisation.userGroups$,
      this._user$,
      this._pathway$,
      cache
    );

    this.tooltip$ = combineLatest([
      this.progress.inProgress$,
      this.progress.isComplete$,
      this.progress.hasGoal$,
    ]).pipe(
      map(([inProgress, isComplete, hasGoal]) => {
        if (!hasGoal) {
          return 'No Goal';
        }

        if (isComplete) {
          return 'Complete';
        }

        if (inProgress) {
          return 'In Progress';
        }

        return 'Not Started';
      })
    );
  }
}
