import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  type OnDestroy,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type TitleStyle = 'flat' | 'stroked';

@Component({
  selector: 'lu-list-title',
  templateUrl: './list-title.component.html',
  styleUrls: ['./list-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListTitleComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  color$ = new BehaviorSubject<string>('default');
  listStyle$ = new BehaviorSubject<string>('flat');
  @HostBinding('class') elementClasses: string;

  @Input()
  set listStyle(listStyle: TitleStyle) {
    if (listStyle) {
      this.listStyle$.next(listStyle);
    }
  }

  @Input()
  set color(color: string) {
    if (color) {
      this.color$.next(color);
    }
  }

  constructor() {
    combineLatest([this.color$, this.listStyle$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([color, style]) => {
        return (this.elementClasses = `${color} ${style}`);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
