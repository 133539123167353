import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Skill,
  filterPathwaysBySkill$,
  type IPathway,
  type ISkill,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import {
  ReplaySubject,
  combineLatest,
  type Observable,
  BehaviorSubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PRODUCT_TOUR_CONFIG } from '../../../models/product-tour-config';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { CachedListsService } from '../../../services/cached-lists.service';
import { OrganisationService } from '../../../services/organisation.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'lu-skill-view',
    templateUrl: './skill-view.component.html',
    styleUrls: ['./skill-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillViewComponent {
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  pathways$: Observable<WithRef<IPathway>[]>;
  tourId: number = PRODUCT_TOUR_CONFIG.skillView;
  skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  skillProgress: UserSkillProgress;
  isMarketplaceVersion$: Observable<boolean>;
  isLocalSkill$: Observable<boolean>;
  isSubPage$ = new BehaviorSubject<boolean>(false);

  @Input()
  set isSubPage(isSubPage: boolean) {
    this.isSubPage$.next(coerceBooleanProperty(isSubPage));
  }

  constructor(
    public organisation: OrganisationService,
    private _cachedLists: CachedListsService
  ) {
    this.pathways$ = combineLatest([
      this.skill$,
      this._cachedLists.pathways$,
    ]).pipe(
      switchMap(([skill, pathways]) => filterPathwaysBySkill$(skill, pathways))
    );
    this.skillProgress = new UserSkillProgress(
      this.organisation.userGroups$,
      this.organisation.user$.pipe(filterUndefined()),
      this.skill$
    );

    this.isMarketplaceVersion$ = this.skill$.pipe(
      map((skill) => Skill.isMarketplaceRelease(skill)),
      shareReplayCold()
    );

    this.isLocalSkill$ = this.isMarketplaceVersion$.pipe(
      map((isMarketplaceVersion) => !isMarketplaceVersion),
      shareReplayCold()
    );
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  isApproved(skill: WithRef<ISkill>): boolean {
    return Skill.isApproved(skill);
  }
}
