<div fxLayout="row">
  <mat-form-field fxFill>
    <mat-chip-grid #chipList>
      <ng-container
        *ngFor="let tag of selectedTags$ | async; trackBy: trackByTag"
      >
        <mat-chip-row
          [removable]="true"
          [style.--mdc-chip-label-text-color]="tag | tagTextColour"
          [style.background-color]="tag.hexColour"
          (removed)="remove(tag)"
        >
          {{ tag.name }}
          <mat-icon
            matChipRemove
            [style.--mdc-chip-label-text-color]="tag | tagTextColour"
            >close</mat-icon
          >
        </mat-chip-row>
      </ng-container>
      <input
        placeholder="Add Tags"
        #tagInput
        [formControl]="inputFilter"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        (matChipInputTokenEnd)="createTag($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectTag($event.option.value)"
    >
      <mat-option
        *ngFor="let tag of filteredTags$ | async; trackBy: trackByTag"
        [value]="tag"
        #option
      >
        <lu-tag-list-item [tag]="tag" (tagSelected)="selectTag($event)" />
      </mat-option>
    </mat-autocomplete>
    <mat-icon matIconSuffix matTooltip="Type and hit enter to save a tag"
      >help_outline</mat-icon
    >
  </mat-form-field>
</div>
