import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IPathway, type IUser } from '@principle-theorem/level-up-core';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPathwayProgress } from '../../../models/user-pathway-progress';
import { OrganisationService } from '../../../services/organisation.service';
import { CachedListsService } from '../../../services/cached-lists.service';

@Component({
    selector: 'lu-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProgressBarComponent {
  private _pathway$: ReplaySubject<WithRef<IPathway>> = new ReplaySubject(1);
  private _user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject(1);
  progress: UserPathwayProgress;
  completedPercentage$: Observable<number>;
  goalMarkerPercentage$: Observable<string>;
  goalMarkerColour$: Observable<string>;
  progressTooltip$: Observable<string>;

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this._pathway$.next(pathway);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }

  constructor(organisation: OrganisationService, cache: CachedListsService) {
    this.progress = new UserPathwayProgress(
      organisation.userGroups$,
      this._user$,
      this._pathway$,
      cache
    );

    this.completedPercentage$ = combineLatest([
      this.progress.completed$,
      this.progress.total$,
    ]).pipe(map(([completed, total]) => (completed / total) * 100));

    this.goalMarkerColour$ = combineLatest([
      this.progress.goal$,
      this.progress.completed$,
    ]).pipe(
      map(([goal, completed]) => (completed >= goal ? '#3ec26b' : '#061a33'))
    );

    this.goalMarkerPercentage$ = combineLatest([
      this.progress.goal$,
      this.progress.total$,
    ]).pipe(
      map(([goal, total]) => (goal / total) * 100),
      map((goal) => `${goal.toString()}%`)
    );

    this.progressTooltip$ = combineLatest([
      this.progress.completed$,
      this.progress.goal$,
      this.progress.total$,
    ]).pipe(
      map(
        ([completed, goal, total]) =>
          `Assigned Skills
          ${completed}/${goal} skills completed.

          All Skills
          ${completed}/${total} skills completed.`
      )
    );
  }
}
