<mat-toolbar color="primary">Set Trainers</mat-toolbar>
<form
  [formGroup]="form"
  (ngSubmit)="submit()"
  class="layout-margin"
  fxLayout="column"
  fxLayoutGap="16px"
  *ngIf="users$ | async as users"
>
  <div>
    <mat-form-field fxFill>
      <mat-select
        formControlName="mentors"
        [compareWith]="compareWithFn"
        multiple
        disableOptionCentering
      >
        <mat-option
          *ngFor="let user of users; trackBy: trackByUser"
          [value]="user.ref"
        >
          {{ user.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.mentors.hasError('required')">
        A skill must have at least <strong>one</strong> mentor.
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button [disabled]="!canSave()">Save</button>
  </div>
</form>
