import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IUser, User } from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogLayoutType,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import {
  IEditUserDialogData,
  UserEditDialogComponent,
} from '../../pages/admin/users/user-edit-dialog/user-edit-dialog.component';

@Component({
  selector: 'lu-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListItemComponent {
  readonly dateFormat = DATE_TIME_WITH_YEAR_FORMAT;
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  @Input() routePrefix = '';
  @Output() toggleUserEnabled = new EventEmitter<void>();

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(private _dialog: BasicDialogService) {}

  getLastActiveAt(user: WithRef<IUser>): Timestamp | undefined {
    return User.getLastActiveAt(user);
  }

  async addEmail(user: WithRef<IUser>): Promise<void> {
    const data = DialogPresets.small<IEditUserDialogData>(
      { data: { user } },
      DialogLayoutType.Material
    );

    await this._dialog.mobileFullscreen<
      UserEditDialogComponent,
      IEditUserDialogData,
      boolean
    >(UserEditDialogComponent, data);
  }
}
