<ng-container *ngIf="item$ | async as item">
  <div>
    <h2 class="mat-headline-6">
      Sharing & Privacy
      <mat-icon
        matTooltip="By default all skills are visible to all users from the explore page. Use this to restrict the skill to a specific team."
        >info</mat-icon
      >
    </h2>
    <p>Add teams here to restrict who can search/view this.</p>
  </div>

  <lu-group-association-selector
    [existingGroups]="item.restrictAccessTo"
    (add)="addGroup($event, item)"
  />

  <ng-container *ngIf="groups$ | async as groups">
    <mat-list *ngIf="groups.length">
      <mat-list-item *ngFor="let group of groups; trackBy: trackByGroup">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <div>
            <a [routerLink]="['/admin/teams', group.ref.id]">
              {{ group.name }}
            </a>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button
              mat-icon-button
              color="warn"
              (click)="removeGroup(group, item)"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ng-container>
