import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IVendorBundle } from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import {
  doc$,
  getParentDocPath,
  type DocumentReference,
  type IProfile,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'lu-vendor-bundle-card',
    templateUrl: './vendor-bundle-card.component.html',
    styleUrls: ['./vendor-bundle-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VendorBundleCardComponent {
  @Input() link: RouterLinkData;
  vendorBundle$: ReplaySubject<WithRef<IVendorBundle>> = new ReplaySubject(1);
  bundle$: Observable<IVendorBundle>;
  author$: Observable<WithRef<IProfile>>;

  @Input()
  set vendorBundle(vendorBundle: WithRef<IVendorBundle>) {
    if (vendorBundle) {
      this.vendorBundle$.next(vendorBundle);
    }
  }

  constructor() {
    this.bundle$ = this.vendorBundle$.pipe(
      switchMap((bundle) => doc$(bundle.ref))
    );

    this.author$ = this.vendorBundle$.pipe(
      switchMap((bundle) => this.getBundleAuthor$(bundle.ref))
    );
  }

  getBundleAuthor$(
    bundleRef: DocumentReference<IVendorBundle>
  ): Observable<WithRef<IProfile>> {
    const vendorPath = getParentDocPath(bundleRef.path);
    return doc$(vendorPath);
  }
}
