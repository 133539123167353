import { Pipe, type PipeTransform } from '@angular/core';
import { type ISkill, Skill } from '@principle-theorem/level-up-core';
import { type Observable } from 'rxjs';
import { OrganisationService } from '../services/organisation.service';
import { filterUndefined } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'canReview$',
    standalone: false
})
export class CanReviewPipe implements PipeTransform {
  constructor(private _organisation: OrganisationService) {}

  transform(skill: ISkill): Observable<boolean> {
    return this._organisation.user$.pipe(
      filterUndefined(),
      map((user) => Skill.canReview(skill, user))
    );
  }
}
