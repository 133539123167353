import { Component, Input } from '@angular/core';
import { type UserStatistics } from '../../../models/user-statistics';
import { type ThemePalette } from '@angular/material/core';

@Component({
  selector: 'lu-goals-stat',
  templateUrl: './goals-stat.component.html',
  styleUrls: ['./goals-stat.component.scss'],
})
export class GoalsStatComponent {
  @Input() stats: UserStatistics;
  @Input() colour: ThemePalette = 'accent';
}
