import { AnyExtension, Extension } from '@tiptap/core';
import { Slice } from '@tiptap/pm/model';
import { NodeSelection, Plugin } from '@tiptap/pm/state';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { __serializeForClipboard, EditorView } from '@tiptap/pm/view';
import { isNil } from 'lodash';
import { findParentNodeOfType } from '../../core/helpers';
import { BlockNodes } from '../available-extensions';

export interface IDragHandleOptions {
  /**
   * The width of the drag handle
   */
  dragHandleWidth: number;
}

interface IRectSize {
  top: number;
  left: number;
  width: number;
}

function absoluteRect(node: Element): IRectSize {
  const data = node.getBoundingClientRect();

  return {
    top: data.top,
    left: data.left,
    width: data.width,
  };
}

function nodeDOMAtCoords(coords: {
  x: number;
  y: number;
}): Element | undefined {
  return document
    .elementsFromPoint(coords.x, coords.y)
    .find(
      (elem: Element) =>
        elem.parentElement?.matches?.('.ProseMirror') ||
        elem.matches(
          [
            'li',
            'p:not(:first-child)',
            'pre',
            'blockquote',
            'h1, h2, h3, h4, h5, h6',
          ].join(', ')
        )
    );
}

function nodePosAtDOM(node: Element, view: EditorView): number | undefined {
  const boundingRect = node.getBoundingClientRect();

  return view.posAtCoords({
    left: boundingRect.left + 1,
    top: boundingRect.top + 1,
  })?.inside;
}

class DragHandle {
  dragHandleElement: HTMLElement | undefined;

  constructor(public options: IDragHandleOptions) {}

  build(): Plugin {
    return new Plugin({
      view: (view) => {
        this.dragHandleElement = document.createElement('div');
        this.dragHandleElement.draggable = true;
        this.dragHandleElement.dataset.dragHandle = '';
        this.dragHandleElement.classList.add('block-drag-handle');
        this.dragHandleElement.addEventListener('dragstart', (e) => {
          this._handleDragStart(e, view);
        });
        this.dragHandleElement.addEventListener('click', (e) => {
          this._handleClick(e, view);
        });

        this._hideDragHandle();

        view?.dom?.parentElement?.appendChild(this.dragHandleElement);

        return {
          destroy: () => {
            this.dragHandleElement?.remove?.();
            this.dragHandleElement = undefined;
          },
        };
      },
      props: {
        handleDOMEvents: {
          mousemove: (view, event) => {
            if (!view.editable) {
              return;
            }

            let node = nodeDOMAtCoords({
              x: event.clientX + 50 + this.options.dragHandleWidth,
              y: event.clientY,
            });

            const tableNode =
              node?.closest('table') ??
              node?.querySelector('table') ??
              undefined;
            if (tableNode) {
              node =
                node?.closest('table') ??
                node?.querySelector('table') ??
                undefined;
            }

            if (!node || node?.matches('ul, ol, .no-drag')) {
              this._hideDragHandle();
              return;
            }

            const compStyle = window.getComputedStyle(node);
            const lineHeight = parseInt(compStyle.lineHeight, 10);
            const paddingTop = parseInt(compStyle.paddingTop, 10);

            const rect = absoluteRect(node);

            rect.top += (lineHeight - 24) / 2;
            rect.top += paddingTop;

            const isList = node.matches(
              'ul:not([data-type=taskList]) li, ol li'
            );
            if (isList) {
              rect.left -= this.options.dragHandleWidth;
            }
            rect.width = this.options.dragHandleWidth;

            if (!this.dragHandleElement) {
              return;
            }

            this.dragHandleElement.style.left = `${rect.left - rect.width}px`;
            this.dragHandleElement.style.top = `${rect.top}px`;

            const isMedia = node.matches('pt-image-node, pt-video-node');
            if (isMedia) {
              this.dragHandleElement.style.top = `${rect.top + 8}px`;
            }

            this._showDragHandle();
          },
          keydown: () => {
            this._hideDragHandle();
          },
          mousewheel: () => {
            this._hideDragHandle();
          },
          // dragging class is used for CSS
          dragstart: (view) => {
            view.dom.classList.add('dragging');
          },
          drop: (view) => {
            view.dom.classList.remove('dragging');
          },
          dragend: (view) => {
            view.dom.classList.remove('dragging');
          },
        },
      },
    });
  }

  private _handleDragStart(event: DragEvent, view: EditorView): void {
    view.focus();

    if (!event.dataTransfer) {
      return;
    }

    let node = nodeDOMAtCoords({
      x: event.clientX + 50 + this.options.dragHandleWidth,
      y: event.clientY,
    });

    const tableNode =
      node?.closest('table') ?? node?.querySelector('table') ?? undefined;
    if (tableNode) {
      node = tableNode;
    }

    if (!(node instanceof Element)) {
      return;
    }

    const nodePos = nodePosAtDOM(node, view);
    if (isNil(nodePos) || nodePos < 0) {
      return;
    }

    if (tableNode) {
      const selection = view.state.tr.setSelection(
        NodeSelection.create(view.state.doc, nodePos)
      );
      const parentNode = findParentNodeOfType(BlockNodes.Table)(
        selection.selection
      );
      if (parentNode) {
        view.dispatch(
          view.state.tr.setSelection(
            NodeSelection.create(view.state.doc, parentNode.pos)
          )
        );
      }
    } else {
      view.dispatch(
        view.state.tr.setSelection(
          NodeSelection.create(view.state.doc, nodePos)
        )
      );
    }

    const slice = view.state.selection.content();
    const { dom, text } = (
      __serializeForClipboard as (
        view: EditorView,
        slice: Slice
      ) => {
        dom: HTMLElement;
        text: string;
      }
    )(view, slice);

    event.dataTransfer.clearData();
    event.dataTransfer.setData('text/html', dom.innerHTML);
    event.dataTransfer.setData('text/plain', text);
    event.dataTransfer.effectAllowed = 'copyMove';
    event.dataTransfer.setDragImage(node, 0, 0);

    view.dragging = { slice, move: event.ctrlKey };
  }

  private _handleClick(event: MouseEvent, view: EditorView): void {
    view.focus();

    view.dom.classList.remove('dragging');

    let node = nodeDOMAtCoords({
      x: event.clientX + 50 + this.options.dragHandleWidth,
      y: event.clientY,
    });

    const tableNode =
      node?.closest('table') ?? node?.querySelector('table') ?? undefined;
    if (tableNode) {
      node = tableNode;
    }

    if (!(node instanceof Element)) {
      return;
    }

    const nodePos = nodePosAtDOM(node, view);
    if (!nodePos) {
      return;
    }

    if (tableNode) {
      const selection = view.state.tr.setSelection(
        NodeSelection.create(view.state.doc, nodePos)
      );
      const parentNode = findParentNodeOfType(BlockNodes.Table)(
        selection.selection
      );
      if (parentNode) {
        view.dispatch(
          view.state.tr.setSelection(
            NodeSelection.create(view.state.doc, parentNode.pos)
          )
        );
        return;
      }
    }

    view.dispatch(
      view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos))
    );
  }

  private _hideDragHandle(): void {
    if (this.dragHandleElement) {
      this.dragHandleElement.classList.add('hidden');
    }
  }

  private _showDragHandle(): void {
    if (this.dragHandleElement) {
      this.dragHandleElement.classList.remove('hidden');
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDragAndDropOptions {}

export function createDragAndDropExtension(): AnyExtension {
  return Extension.create<IDragAndDropOptions>({
    name: 'dragAndDrop',

    addProseMirrorPlugins() {
      return [
        new DragHandle({
          dragHandleWidth: 24,
        }).build(),
      ];
    },
  });
}
