import { Directive, TemplateRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-template[ptCollapsibleContent]',
    standalone: false
})
export class CollapsibleContentDirective {
  constructor(public template: TemplateRef<unknown>) {}
}
