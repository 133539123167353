<div fxLayout="column" fxLayoutGap="8px">
  <mat-form-field
    fxFlex
    class="toolbar-search no-underline"
    subscriptSizing="dynamic"
  >
    <mat-label>Search all Skills, Pathways & Users</mat-label>
    <input
      type="text"
      matInput
      #searchInput
      autocomplete="off"
      [formControl]="searchCtrl"
      (keyup.arrowdown)="selectionList.focus()"
      (keyup.enter)="selectFirstOption()"
    />

    <button
      matIconSuffix
      mat-icon-button
      *ngIf="searchCtrl.value"
      (click)="clearResults()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-selection-list
    class="toolbar-search-list"
    hideSingleSelectionIndicator
    [multiple]="false"
    (compareWith)="(displayFn)"
    #selectionList
  >
    <ng-container
      *ngFor="let group of filteredGroups$ | async; trackBy: trackByGroup"
    >
      <div mat-subheader *ngIf="group.options.length" class="dense">
        {{ group.name }}
      </div>

      <mat-list-item
        *ngFor="let option of group.options; trackBy: trackByOption"
        (click)="optionSelected(option)"
        (keyup.enter)="optionSelected(option)"
      >
        <lu-content-type-icon
          class="!mr-4"
          *ngIf="option.type; else autoIcon"
          matListItemIcon
          [contentType]="option.type"
        />
        <ng-template #autoIcon>
          <pt-user-icon
            matListItemAvatar
            [name]="option.type | map : toInitial"
            [diameter]="iconSize"
          />
        </ng-template>

        <div class="flex flex-col">
          <span class="name">{{ option.name }}</span>
          <span
            class="flex"
            class="details mat-caption truncate"
            *ngIf="option.details"
          >
            {{ option.details }}
          </span>
          <lu-tag-display
            *ngIf="option.tags?.length"
            [responsive]="false"
            [tagRefs]="option.tags"
          />
        </div>
      </mat-list-item>
    </ng-container>
  </mat-selection-list>

  <div *ngIf="search.loading$ | async" class="layout-margin">
    <lu-search-placeholder />
  </div>
</div>
