<ng-container *ngIf="recentlyViewed$ | async as recentlyViewed">
  <ng-container *ngIf="recentlyViewed.length; else noRecentlyViewed">
    <ng-container
      *ngFor="let item of recentlyViewed; trackBy: trackByRecentlyViewed"
    >
      <ng-container *ngIf="item | map : isSkill">
        <lu-skill-list-card
          *ngIf="item | map : asSkill as skill"
          [compact]="true"
          [skill]="skill"
          [user]="user$ | async"
          [link]="['/explore/skill', item.ref.id]"
        />
      </ng-container>

      <ng-container *ngIf="item | map : isPathway">
        <lu-pathway-list-card
          *ngIf="item | map : asPathway as pathway"
          compact
          [pathway]="pathway"
          [user]="user$ | async"
          [link]="['/explore/pathway', item.ref.id]"
        />
      </ng-container>
    </ng-container>

    <div class="flex justify-end">
      <span
        class="cursor-pointer p-2 transition-colors hover:text-indigo-600"
        (click)="hotkeys.openSearch()"
      >
        See More
      </span>
    </div>
  </ng-container>

  <ng-template #noRecentlyViewed>
    <div class="mat-caption p-4">No recently viewed content</div>
  </ng-template>
</ng-container>
