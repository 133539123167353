import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Skill,
  type ISkill,
  type IUser,
} from '@principle-theorem/level-up-core';
import { BreakpointService } from '@principle-theorem/ng-shared';
import { shareReplayCold, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { OrganisationService } from '../../../services/organisation.service';
import { FavouritesService } from '../../../services/favourites.service';

export class ListCardBloc {
  isMobile$: Observable<boolean>;
  isNotMobile$: Observable<boolean>;

  constructor(breakpoint: BreakpointService) {
    this.isMobile$ = breakpoint.isMobile$;
    this.isNotMobile$ = this.isMobile$.pipe(map((isMobile) => !isMobile));
  }
}

@Component({
    selector: 'lu-skill-list-card',
    templateUrl: './skill-list-card.component.html',
    styleUrls: ['./skill-list-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkillListCardComponent {
  bloc: ListCardBloc;
  progress: UserSkillProgress;
  skill$ = new ReplaySubject<WithRef<ISkill>>(1);
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  hasAttachments$: Observable<boolean>;
  showInfo$: Observable<boolean>;
  isLocalSkill$: Observable<boolean>;

  @Input() compact = false;
  @Input() link: string[] | string;

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this.skill$.next(skill);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(
    breakpoint: BreakpointService,
    public favourites: FavouritesService,
    private _organisation: OrganisationService
  ) {
    this.bloc = new ListCardBloc(breakpoint);

    this.progress = new UserSkillProgress(
      this._organisation.userGroups$,
      this.user$,
      this.skill$
    );

    // TODO: We don't want to load all attachment subcollections here.
    // https://app.clickup.com/t/860qkqx0d
    this.hasAttachments$ = this.skill$.pipe(
      // switchMap((skill) => Skill.attachments$(skill)),
      // map((attachments) => attachments.length > 0),
      map(() => false)
    );

    this.showInfo$ = combineLatest([this.skill$, this.hasAttachments$]).pipe(
      map(([skill, hasAttachments]) => skill.tags.length > 0 || hasAttachments)
    );

    this.isLocalSkill$ = this.skill$.pipe(
      map((skill) => !Skill.isMarketplaceRelease(skill)),
      shareReplayCold()
    );
  }
}
