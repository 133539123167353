import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IUser } from '@principle-theorem/level-up-core';
import {
  InputSearchFilter,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { PRODUCT_TOUR_CONFIG } from '../../../models/product-tour-config';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
  selector: 'lu-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent {
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IUser>>;
  currentUser$: Observable<WithRef<IUser>>;
  tourId: number = PRODUCT_TOUR_CONFIG.users;

  constructor(public organisation: OrganisationService) {
    this.currentUser$ = this.organisation.user$.pipe(filterUndefined());

    this.searchFilter = new InputSearchFilter<WithRef<IUser>>(
      this.organisation.users$,
      this.search.valueChanges.pipe(startWith('')),
      ['name', 'email']
    );
  }
}
