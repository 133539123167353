import { Injectable } from '@angular/core';
import { type IOrganisation } from '@principle-theorem/level-up-core';
import {
  httpsCallable,
  serialise,
  snapshot,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';

export interface ICreateOrganisationRequest {
  userName: string;
  userEmail: string;
  companyName: string;
  subscriptionId: string;
  subscriptionCustomerId: string;
  subscriptionQuantity: string;
}

interface IUpdateSubscriptionTaskRequest {
  organisation: WithRef<IOrganisation>;
  numberOfUsers: number;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseFunctionsService {
  async createOrganisation(data: ICreateOrganisationRequest): Promise<boolean> {
    return this._call<ICreateOrganisationRequest, boolean>(
      'http-auth-createOrganisation',
      data
    );
  }

  async updateSubscriptionTask(
    data: IUpdateSubscriptionTaskRequest
  ): Promise<boolean> {
    return this._call<SerialisedData<IUpdateSubscriptionTaskRequest>, boolean>(
      'http-updateSubscriptionTask',
      serialise(data)
    );
  }

  private async _call<T = unknown, R = unknown>(
    name: string,
    requestData: T
  ): Promise<R> {
    return snapshot(httpsCallable<T, R>(name)(requestData));
  }
}
