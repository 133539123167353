<mat-toolbar color="primary">Add Vendor</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="layout-margin" fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>
  </div>

  <div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button [disabled]="!canSave()">Save</button>
  </div>
</form>
