import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import { httpsCallable, snapshot } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'lu-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolsComponent {
  revokeAuthSessionsLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  seedingKeys$ = new BehaviorSubject<boolean>(false);
  seedingSkills$ = new BehaviorSubject<boolean>(false);
  seedingPathways$ = new BehaviorSubject<boolean>(false);
  seedingMarketplaceSkills$ = new BehaviorSubject<boolean>(false);

  constructor(private _authFunctions: AuthFirebaseFunctionsService) {}

  async revokeAuthSessions(): Promise<void> {
    this.revokeAuthSessionsLoading$.next(true);
    await this._authFunctions.revokeAuthSessions();
    this.revokeAuthSessionsLoading$.next(false);
  }

  async seedTypesenseSkills(): Promise<void> {
    this.seedingSkills$.next(true);
    await snapshot(httpsCallable('http-typesense-seedSkills')({}));
    this.seedingSkills$.next(false);
  }

  async seedTypesensePathways(): Promise<void> {
    this.seedingPathways$.next(true);
    await snapshot(httpsCallable('http-typesense-seedPathways')({}));
    this.seedingPathways$.next(false);
  }

  async seedTypesenseKeys(): Promise<void> {
    this.seedingKeys$.next(true);
    await snapshot(httpsCallable('http-typesense-seedKeys')({}));
    this.seedingKeys$.next(false);
  }

  async seedMarketplaceSkills(): Promise<void> {
    this.seedingMarketplaceSkills$.next(true);
    await snapshot(httpsCallable('http-typesense-seedVendorSkills')({}));
    this.seedingMarketplaceSkills$.next(false);
  }
}
