import {
  moveItemInArray,
  transferArrayItem,
  type CdkDragDrop,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IPathwaySection,
  IntercomEvent,
  Organisation,
  Pathway,
  PathwaySectionStep,
  Skill,
  type IPathway,
  type ISkill,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  IReffable,
  addDoc,
  filterUndefined,
  getDoc,
  getDocs,
  isSameRef,
  multiSwitchMap,
  patchDoc,
  reduceToSingleArray,
  snapshot,
  where,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  merge,
  type Observable,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ISkillAssociatedData,
  SkillAssociateDialogComponent,
} from '../../../pages/admin/pathways/skill-associate-dialog/skill-associate-dialog.component';
import { AuthorService } from '../../../pages/skills/author/author.service';
import { ContentDialogService } from '../../../services/content-dialog.service';
import { DeleteSkillService } from '../../../services/delete-skill.service';
import { OrganisationService } from '../../../services/organisation.service';
import {
  ISkillAddDialogData,
  SkillAddDialogComponent,
} from '../../skills/skill-add-dialog/skill-add-dialog.component';
import { SkillFormData } from '../../skills/skill-edit-form/skill-form-group';

@Component({
    selector: 'lu-section-edit',
    templateUrl: './section-edit.component.html',
    styleUrls: ['./section-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SectionEditComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _loadSteps$ = new BehaviorSubject<void>(undefined);
  private _stepOrderChange$ = new Subject<
    DocumentReference<PathwaySectionStep>[]
  >();
  trackByStep = TrackByFunctions.ref<WithRef<PathwaySectionStep>>();
  steps$: Observable<WithRef<PathwaySectionStep>[]>;
  stepOrder$: Observable<DocumentReference<PathwaySectionStep>[]>;
  section$ = new ReplaySubject<IPathwaySection>(1);
  pathway$ = new ReplaySubject<WithRef<IPathway>>(1);
  nameCtrl = new TypedFormControl<string>('', {
    updateOn: 'blur',
  });
  @Output() update = new EventEmitter<IPathwaySection>();

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  @Input()
  set section(section: IPathwaySection) {
    if (section) {
      this.section$.next(section);
    }
  }

  constructor(
    private _dialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _org: OrganisationService,
    private _deleteSkill: DeleteSkillService,
    private _author: AuthorService,
    private _intercom: Intercom,
    private _content: ContentDialogService
  ) {
    this.stepOrder$ = merge(
      this.section$.pipe(map((section) => section.steps ?? [])),
      this._stepOrderChange$
    );

    this.steps$ = this._loadSteps$.pipe(
      switchMap(() =>
        this.stepOrder$.pipe(multiSwitchMap((stepRef) => getDoc(stepRef)))
      )
    );

    this.section$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((section) => this.nameCtrl.setValue(section.name));

    this.nameCtrl.valueChanges
      .pipe(withLatestFrom(this.section$), takeUntil(this._onDestroy$))
      .subscribe(([name, section]) =>
        this.update.emit({
          ...section,
          name,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async deleteSection(
    section: IPathwaySection,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt: 'Are you sure you want to delete this section?',
      title: 'Delete Section',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
      toolbarColor: 'primary',
    });

    if (!confirmed) {
      return;
    }

    const sections = pathway.sections.filter((currentSection) => {
      return currentSection.uid !== section.uid;
    });

    await patchDoc(pathway.ref, {
      sections: [...sections],
    });
    this._snackBar.open('Section Deleted');
  }

  async drop(
    event: CdkDragDrop<IPathwaySection>,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(
        event.container.data.steps,
        event.previousIndex,
        event.currentIndex
      );
      this._stepOrderChange$.next(event.container.data.steps);
      this.update.emit(event.container.data);
      return;
    }

    transferArrayItem(
      event.previousContainer.data.steps,
      event.container.data.steps,
      event.previousIndex,
      event.currentIndex
    );

    await patchDoc(pathway.ref, {
      sections: pathway.sections
        .map((currentSection) => {
          if (currentSection.uid !== event.previousContainer.data.uid) {
            return currentSection;
          }
          return event.previousContainer.data;
        })
        .map((currentSection) => {
          if (currentSection.uid !== event.container.data.uid) {
            return currentSection;
          }
          return event.container.data;
        }),
    });
  }

  async addNewStep(
    pathway: WithRef<IPathway>,
    section: IPathwaySection
  ): Promise<void> {
    const author = await snapshot(this._author.user$);
    const data: MatDialogConfig<ISkillAddDialogData> = {
      data: { author: author.ref, folderRef: pathway.folderRef },
    };

    const skill = await this._dialog.mobileFullscreen<
      SkillAddDialogComponent,
      ISkillAddDialogData,
      SkillFormData
    >(SkillAddDialogComponent, DialogPresets.small(data));

    if (!skill) {
      return;
    }

    const skillRef = await addDoc(
      await snapshot(this._org.skillCol$),
      Skill.init({
        ...skill,
        author: skill.author ?? author.ref,
      })
    );

    this._intercom.trackEvent(IntercomEvent.AddedSkill, {
      name: skill.name,
    });

    this.update.emit({
      ...section,
      steps: [...section.steps, skillRef],
    });
    this._loadSteps$.next();

    this._snackBar.open('Skill Added');
  }

  async editStep(skill: WithRef<ISkill>): Promise<void> {
    await this._content.editSkill(skill);
    this._loadSteps$.next();
  }

  async addExistingSkill(
    pathway: WithRef<IPathway>,
    section: IPathwaySection
  ): Promise<void> {
    const skill: WithRef<ISkill> | undefined = await this._dialog
      .open<
        SkillAssociateDialogComponent,
        ISkillAssociatedData,
        WithRef<ISkill>
      >(
        SkillAssociateDialogComponent,
        DialogPresets.small<ISkillAssociatedData>({
          data: {
            associatedContent: reduceToSingleArray(
              (pathway.sections ?? []).map(
                (currentSection) => currentSection.steps
              )
            ),
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!skill) {
      return;
    }

    this.update.emit({
      ...section,
      steps: [...(section.steps ?? []), skill.ref],
    });
    this._snackBar.open('Skill Added');
  }

  async deleteStep(
    step: IReffable<PathwaySectionStep>,
    pathway: WithRef<IPathway>,
    section: IPathwaySection
  ): Promise<void> {
    if (section) {
      await patchDoc(pathway.ref, {
        sections: (pathway.sections ?? []).map((currentSection) => {
          if (currentSection.uid !== section.uid) {
            return currentSection;
          }

          return {
            ...currentSection,
            steps: currentSection.steps.filter((currentStepRef) => {
              return !isSameRef(currentStepRef, step);
            }),
          };
        }),
      });
      this._snackBar.open('Step Removed');
    }
  }

  async removeSkill(
    skill: WithRef<ISkill>,
    pathway: WithRef<IPathway>
  ): Promise<void> {
    const { sections } = Pathway.removeSkillAssociation(pathway, skill);
    await patchDoc(pathway.ref, {
      sections,
    });

    const organisation = await snapshot(
      this._org.organisation$.pipe(filterUndefined())
    );
    const pathwaysWithSkill = await getDocs(
      Organisation.pathwayCol(organisation),
      where('skills', 'array-contains', skill.ref)
    );

    const isAssociatedWithOtherSkills = pathwaysWithSkill.some(
      (pathwayWithSkill) => !isSameRef(pathwayWithSkill, pathway)
    );

    if (!isAssociatedWithOtherSkills) {
      const confirm = await this._dialog.confirm({
        title: 'Remove Skill',
        prompt: `This skill is only used in this pathway. Would you like to permenantly delete it?`,
        submitLabel: 'Yes',
        submitColor: 'warn',
        toolbarColor: 'primary',
      });

      if (confirm) {
        await this._deleteSkill.deleteSkill(skill);
      }
    }

    this._snackBar.open('Skill Removed');
  }
}
