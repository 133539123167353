import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { type IProfile } from '@principle-theorem/shared';

@Component({
  selector: 'lu-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardContainerComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() link?: RouterLinkData;
  @Input() author?: IProfile;
  @Input() colour?: string;
}
