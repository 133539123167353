import { Directive, HostBinding } from '@angular/core';

export const TABLE_OF_CONTENTS_CLASS = 'toc-heading';

@Directive({
    selector: '[ptTableOfContentsHeading]',
    standalone: false
})
export class TableOfContentsHeadingDirective {
  @HostBinding(`class.${TABLE_OF_CONTENTS_CLASS}`) isTocHeading = true;
}
