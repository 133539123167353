import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  type IUser,
  type Organisation,
  User,
} from '@principle-theorem/level-up-core';
import {
  AuthFirebaseFunctionsService,
  AuthService,
} from '@principle-theorem/ng-auth';
import {
  BasicDialogService,
  BasicSnackBarService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  doc$,
  errorNil,
  filterUndefined,
  findProp,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type UserGroupAssociation } from '../../../../models/user-group-associations';
import { OrganisationService } from '../../../../services/organisation.service';
import {
  type IEditUserDialogData,
  UserEditDialogComponent,
} from '../user-edit-dialog/user-edit-dialog.component';

const WRITE_REQUEST_TIMEOUT = 2000;

@Component({
  selector: 'lu-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditComponent {
  user$: Observable<WithRef<IUser>>;
  groupAssociations: UserGroupAssociation;
  isLoggedInUser$: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    private _snackBar: BasicSnackBarService,
    private _organisation: OrganisationService,
    private _functions: AuthFirebaseFunctionsService,
    private _dialog: BasicDialogService,
    private _auth: AuthService
  ) {
    this.user$ = this._route.data.pipe(
      findProp<WithRef<IUser>>('user'),
      errorNil(),
      switchMap((data) => doc$(data.ref))
    );

    this.isLoggedInUser$ = combineLatest([
      this._organisation.user$.pipe(filterUndefined()),
      this.user$,
    ]).pipe(
      map(([loggedInUser, user]: [WithRef<IUser>, WithRef<IUser>]) =>
        isSameRef(loggedInUser, user)
      )
    );
  }

  async invite(user: WithRef<IUser>): Promise<void> {
    this._snackBar.loading('Sending Invitation');
    const organisation: WithRef<Organisation> = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );
    await this._functions.sendOrganisationInviteEmail(organisation, user);
    this._snackBar.open('Invitation Email Sent');
  }

  async toggleUserEnabled(user: WithRef<IUser>): Promise<void> {
    await patchDoc(user.ref, { isActivated: !user.isActivated });
    this._snackBar.open(
      `User Access ${user.isActivated ? 'Enabled' : 'Disabled'}`
    );
  }

  canInvite(user: WithRef<IUser>): boolean {
    return User.canInvite(user);
  }

  async editUser(user: WithRef<IUser>): Promise<void> {
    const isLoggedInUser: boolean = await snapshot(this.isLoggedInUser$);
    const data = DialogPresets.small<IEditUserDialogData>({ data: { user } });
    const userEmailChanged = await this._dialog.mobileFullscreen<
      UserEditDialogComponent,
      IEditUserDialogData,
      boolean
    >(UserEditDialogComponent, data);

    if (userEmailChanged && isLoggedInUser) {
      setTimeout(() => {
        this._snackBar.open('Please Login with New Email');
        void this._auth.logout();
      }, WRITE_REQUEST_TIMEOUT);
    }
  }
}
