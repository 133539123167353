import { count, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { SkillStatus, type ISkill } from '../models/skill/skill';
import { StatView } from './stat-view';
import { filterByStatus } from './user-author-stats';

export class TeamSkillStats {
  draft: StatView;
  review: StatView;
  approved: StatView;

  constructor(allSkills$: Observable<WithRef<ISkill>[]>) {
    const allSkillsCount$ = allSkills$.pipe(count());
    this.draft = new StatView(
      this._numOfStatus(allSkills$, SkillStatus.Draft),
      allSkillsCount$
    );
    this.review = new StatView(
      this._numOfStatus(allSkills$, SkillStatus.Review),
      allSkillsCount$
    );
    this.approved = new StatView(
      this._numOfStatus(allSkills$, SkillStatus.Published),
      allSkillsCount$
    );
  }

  private _numOfStatus(
    skills$: Observable<WithRef<ISkill>[]>,
    status: SkillStatus
  ): Observable<number> {
    return skills$.pipe(filterByStatus(status), count());
  }
}
