import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ISkill,
  isSkill,
  type IGoals,
  type IPathway,
  type IPathwayAssociation,
  type ISkillAssociation,
} from '@principle-theorem/level-up-core';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, combineLatest, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CachedListsService } from '../../../../services/cached-lists.service';
import {
  AssociatedPathwayAddFilter,
  AssociatedSkillAddFilter,
} from './associated-pathway-add-filter';
import { GoalSettingBloc } from './goal-setting-bloc';

export interface IPathwayAddData {
  pathways: IPathwayAssociation[];
  skills: ISkillAssociation[];
  goals: IGoals;
  showDueDate: boolean;
  showRelativeDueDate: boolean;
  initialGoals?: IGoals;
}

@Component({
  selector: 'lu-group-add-pathway-dialog',
  templateUrl: './group-add-pathway-dialog.component.html',
  styleUrls: ['./group-add-pathway-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupAddPathwayDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  skillsFilter: AssociatedSkillAddFilter;
  pathwaysFilter: AssociatedPathwayAddFilter;
  bloc: GoalSettingBloc;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPathwayAddData,
    private _cachedLists: CachedListsService,
    private _dialogRef: MatDialogRef<GroupAddPathwayDialogComponent, IGoals>
  ) {
    this.bloc = new GoalSettingBloc(
      of(
        data.initialGoals ?? {
          levelRequirements: [],
          skillAssociations: [],
          pathwayAssociations: [],
        }
      ),
      this._onDestroy$
    );
    this._initSkills(data);
    this._initPathways(data);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  displayFn(item?: INamedDocument): string {
    return item ? `${item.name}` : '';
  }

  async save(): Promise<void> {
    const goals = await snapshot(this.bloc.goals$);
    this._dialogRef.close(goals);
  }

  async add(skill?: WithRef<ISkill> | WithRef<IPathway>): Promise<void> {
    this.search.setValue('');

    if (!skill) {
      return;
    }

    if (isSkill(skill)) {
      await this.bloc.addSkill(skill);
      return;
    }
    await this.bloc.addPathway(skill);
  }

  private _initPathways(data: IPathwayAddData): void {
    const pathwaysInputFilter: InputSearchFilter<WithRef<IPathway>> =
      new InputSearchFilter<WithRef<IPathway>>(
        this._cachedLists.pathways$,
        this.search.valueChanges.pipe(startWith('')),
        ['name']
      );
    this.pathwaysFilter = new AssociatedPathwayAddFilter(
      pathwaysInputFilter.results$,
      combineLatest([of(data.pathways), this.bloc.goals$]).pipe(
        map(([pathways, goals]) => [...pathways, ...goals.pathwayAssociations])
      )
    );
  }

  private _initSkills(data: IPathwayAddData): void {
    const skillsInputFilter: InputSearchFilter<WithRef<ISkill>> =
      new InputSearchFilter<WithRef<ISkill>>(
        this._cachedLists.skills$,
        this.search.valueChanges.pipe(startWith('')),
        ['name']
      );
    this.skillsFilter = new AssociatedSkillAddFilter(
      skillsInputFilter.results$,
      combineLatest([of(data.skills), this.bloc.goals$]).pipe(
        map(([skills, goals]) => [...skills, ...goals.skillAssociations])
      )
    );
  }
}
