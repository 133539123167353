import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { type ISkill, type IUser } from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'lu-users-skill-list',
    templateUrl: './users-skill-list.component.html',
    styleUrls: ['./users-skill-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UsersSkillListComponent {
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  users$: BehaviorSubject<WithRef<IUser>[]> = new BehaviorSubject<
    WithRef<IUser>[]
  >([]);

  @Input() skill: WithRef<ISkill>;

  @Input()
  set users(users: WithRef<IUser>[]) {
    if (users) {
      this.users$.next(users);
    }
  }
}
