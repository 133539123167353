import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IntercomEvent,
  type ISkill,
  type ISkillReview,
  type IUser,
  Skill,
  SkillLevel,
  SkillStatus,
} from '@principle-theorem/level-up-core';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  addDoc,
  asyncForEach,
  getDoc,
  isSameRef,
  multiMap,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { differenceWith } from 'lodash';
import { Intercom } from '@supy-io/ngx-intercom';
import { type SkillFormData } from '../components/skills/skill-edit-form/skill-form-group';
import { SkillMentorDialogComponent } from '../components/skills/skill-mentor-dialog/skill-mentor-dialog.component';
import { findTrainerUsersForSkill$ } from '../models/user';
import { OrganisationService } from './organisation.service';
import { SkillProgressUpdaterService } from './skill-progress-updater.service';

@Injectable({
  providedIn: 'root',
})
export class SkillStatusUpdaterService {
  constructor(
    private _progressUpdater: SkillProgressUpdaterService,
    private _org: OrganisationService,
    private _intercom: Intercom,
    private _dialog: MatDialog
  ) {}

  async setDraft(skill: WithRef<ISkill>, data?: SkillFormData): Promise<void> {
    await this._updateSkill(skill, data, {
      status: SkillStatus.Draft,
    });
  }

  async readyForReview(
    skill: WithRef<ISkill>,
    data?: SkillFormData
  ): Promise<void> {
    await this._updateSkill(skill, data, {
      status: SkillStatus.Review,
    });
  }

  async approve(skill: WithRef<ISkill>, data?: Partial<ISkill>): Promise<void> {
    await this._updateSkill(skill, data, {
      status: SkillStatus.Published,
    });

    this._intercom.trackEvent(IntercomEvent.ApprovedSkill, {
      skill: skill.name,
    });
  }

  async save(skill: WithRef<ISkill>, data?: Partial<ISkill>): Promise<void> {
    const updatedSkill = { ...skill, ...data };

    if (
      Skill.inReview(updatedSkill) &&
      (await Skill.approvedByAllReviewers(updatedSkill))
    ) {
      return this.approve(updatedSkill);
    }
    await Skill.archive(skill);
    await patchDoc(skill.ref, updatedSkill);
  }

  async addReview(skill: WithRef<ISkill>, review: ISkillReview): Promise<void> {
    await addDoc<ISkillReview>(Skill.reviewCol(skill), review);

    if (review.isApproved) {
      this._intercom.trackEvent(IntercomEvent.ApprovedSkill, {
        skill: skill.name,
      });
      return;
    }

    this._intercom.trackEvent(IntercomEvent.ReviewedSkill, {
      skill: skill.name,
    });
  }

  async manageTrainersForSkill(skill: WithRef<ISkill>): Promise<void> {
    const trainers = await this.selectTrainers(skill);
    if (!trainers || !trainers.length) {
      if (skill.requiresTrainerVerification) {
        throw new Error(
          'You must select at least one trainer for a trainer verified skill'
        );
      }
      return;
    }
    await this.associateTrainers(skill, trainers);
  }

  async associateTrainers(
    skill: WithRef<ISkill>,
    selectedTrainers: DocumentReference<IUser>[]
  ): Promise<void> {
    const currentTrainers = await snapshot<DocumentReference<IUser>[]>(
      this._org.enabledUsers$.pipe(
        findTrainerUsersForSkill$(skill),
        multiMap((trainer) => trainer.ref)
      )
    );

    const trainersToReset = differenceWith(
      currentTrainers,
      selectedTrainers,
      (trainerA, trainerB) => isSameRef(trainerA, trainerB)
    );

    const trainersToAdd = differenceWith(
      selectedTrainers,
      currentTrainers,
      (trainerA, trainerB) => isSameRef(trainerA, trainerB)
    );

    if (trainersToReset.length) {
      await this._setTrainerSkillLevel(trainersToReset, skill, SkillLevel.None);
    }

    if (trainersToAdd.length) {
      await this._setTrainerSkillLevel(
        trainersToAdd,
        skill,
        SkillLevel.Trainer
      );
    }
  }

  async selectTrainers(
    skill: WithRef<ISkill>
  ): Promise<DocumentReference<IUser>[] | undefined> {
    const config: MatDialogConfig = DialogPresets.small({ data: { ...skill } });

    return this._dialog
      .open<
        SkillMentorDialogComponent,
        WithRef<ISkill>,
        DocumentReference<IUser>[]
      >(SkillMentorDialogComponent, config)
      .afterClosed()
      .toPromise();
  }

  private async _updateSkill(
    skill: WithRef<ISkill>,
    ...partials: (Partial<ISkill> | undefined)[]
  ): Promise<void> {
    const updatedSkill = {
      ...skill,
      ...partials.reduce(
        (finalData, partial) => ({ ...finalData, ...partial }),
        {}
      ),
    };
    await Skill.archive(skill);
    await patchDoc(skill.ref, updatedSkill);
  }

  private async _setTrainerSkillLevel(
    trainers: DocumentReference<IUser>[],
    skill: WithRef<ISkill>,
    skillLevel: SkillLevel
  ): Promise<void> {
    await asyncForEach(trainers, async (trainer) =>
      this._progressUpdater.upsert(await getDoc(trainer), skill, skillLevel)
    );
  }
}
