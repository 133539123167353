import { type Observable, combineLatest, type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export class StatView {
  percent$: Observable<number>;
  progress$: Observable<number>;
  remaining$: Observable<number>;

  constructor(
    public current$: Observable<number>,
    public total$: Observable<number>
  ) {
    this.percent$ = combineLatest([this.current$, this.total$]).pipe(
      toPercent$()
    );
    this.progress$ = this.percent$.pipe(map((percent) => percent * 100));
    this.remaining$ = combineLatest([this.current$, this.total$]).pipe(
      map(([completed, total]) => total - completed)
    );
  }
}

export function toPercent(completed: number, total: number): number {
  if (!total || !completed) {
    return 0;
  }
  return completed / total;
}

export function toPercent$(): OperatorFunction<[number, number], number> {
  return map(([completed, total]: [number, number]) =>
    toPercent(completed, total)
  );
}
