import {
  type IOrganisation,
  type ITypesensePathway,
  LevelUpTypesenseCollection,
  Organisation,
} from '@principle-theorem/level-up-core';
import { TypesenseSearchService } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { ITypesenseConfig, Typesense } from '@principle-theorem/typesense';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';

export class TypesensePathwayListBloc {
  results$: Observable<SearchResponse<ITypesensePathway>>;

  constructor(
    private _typesense: TypesenseSearchService,
    config: ITypesenseConfig,
    organisation$: Observable<WithRef<IOrganisation>>,
    search$: Observable<string>,
    page$ = of(1)
  ) {
    const searchParams$: Observable<SearchParams> = combineLatest([
      search$,
      page$,
    ]).pipe(
      map(([search, page]) => ({
        q: search,
        query_by: ['name', 'tagNames'].join(','),
        per_page: 50,
        page,
        filter_by: ['deleted:false'].join(' && '),
      }))
    );

    this.results$ = organisation$.pipe(
      switchMap((organisation) =>
        this._typesense.query$<ITypesensePathway>(
          Organisation.integrationCol(organisation),
          Typesense.getScopedCollectionName(
            [organisation.ref],
            LevelUpTypesenseCollection.Pathways
          ),
          searchParams$,
          config
        )
      )
    );
  }
}
