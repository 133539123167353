<div
  class="large-row flex cursor-pointer items-center gap-2 overflow-hidden rounded-lg border border-solid border-gray-200 bg-white p-2 pl-4 transition-colors hover:border-black"
  *ngIf="skill$ | async as skill"
  [routerLink]="link"
>
  <lu-content-type-icon contentType="skill" />

  <lu-user-skill-level-indicator
    *ngIf="isLocalSkill$ | async"
    class="prefix"
    [user]="user$ | async"
    [skill]="skill"
  />

  <div
    class="flex min-w-0 grow items-start gap-1 sm:items-center sm:gap-2"
    [ngClass]="{
      'flex-col': bloc.isMobile$ | async,
      'flex-row': bloc.isNotMobile$ | async
    }"
  >
    <span
      class="line-clamp-2"
      [ngClass]="{
        'basis-7/12': (bloc.isNotMobile$ | async) && skill.tags.length,
        truncate: compact
      }"
      [ptTruncateTooltip]="skill.name"
    >
      {{ skill.name }}
    </span>

    <div
      class="info gap:1 flex w-full sm:gap-2"
      [ngClass]="{
        'basis-5/12': (bloc.isNotMobile$ | async) && skill.tags.length,
        hidden: compact
      }"
      *ngIf="showInfo$ | async"
    >
      <ng-container *ngIf="bloc.isMobile$ | async">
        <ng-container *ngTemplateOutlet="mediaIcon" />
      </ng-container>

      <div class="flex grow items-center justify-between">
        <lu-tag-display
          *ngIf="skill.tags.length"
          [tagRefs]="skill.tags"
          [compact]="true"
        />
        <div
          class="flex shrink-0 items-center justify-between"
          [ngClass]="{
            'mr-2': !compact,
            'mr-0': compact
          }"
          *ngIf="progress.dueDateBloc.shouldShowDueDate$ | async"
        >
          <lu-user-goal-due-date-indicator
            (click)="$event.stopPropagation()"
            [dueDate]="progress.dueDateBloc.dueDate$ | async"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="suffix" *ngIf="hasAttachments$ | async">
    <ng-container *ngIf="bloc.isNotMobile$ | async">
      <ng-container *ngTemplateOutlet="mediaIcon" />
    </ng-container>
  </div>

  <ng-template #mediaIcon>
    <mat-icon *ngIf="hasAttachments$ | async">perm_media</mat-icon>
  </ng-template>

  <lu-favourite-indicator
    *ngIf="isLocalSkill$ | async"
    [ngClass]="{ hidden: compact }"
    [item]="skill"
    (click)="$event.stopPropagation()"
  />

  <ng-container
    *ngIf="(compact || (skill | canEdit$ | async)) && (isLocalSkill$ | async)"
  >
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
    >
      <mat-icon class="font-bold">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        [ngClass]="{ hidden: !compact }"
        *ngIf="
          skill.ref | map: favourites.isFavourite$ : favourites | async;
          else addFavourite
        "
        (click)="favourites.toggleSkill(skill)"
        mat-menu-item
      >
        <mat-icon>heart_minus</mat-icon>
        <span>Remove from favourites</span>
      </button>

      <ng-template #addFavourite>
        <button
          [ngClass]="{ hidden: !compact }"
          (click)="favourites.toggleSkill(skill)"
          mat-menu-item
        >
          <mat-icon>favorite</mat-icon>
          <span>Add to favourites</span>
        </button>
      </ng-template>

      <button
        *ngIf="skill | canEdit$ | async"
        [routerLink]="['/explore', 'skill', skill.ref.id]"
        mat-menu-item
      >
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
    </mat-menu>
  </ng-container>
</div>
