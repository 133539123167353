import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IPathway, type IUser } from '@principle-theorem/level-up-core';
import { ReplaySubject, type Subject } from 'rxjs';
import { UserPathwayProgress } from '../../models/user-pathway-progress';
import { OrganisationService } from '../../services/organisation.service';
import { CachedListsService } from '../../services/cached-lists.service';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'lu-user-pathway-progress',
  templateUrl: './user-pathway-progress.component.html',
  styleUrls: ['./user-pathway-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserPathwayProgressComponent {
  private _pathway$: Subject<WithRef<IPathway>> = new ReplaySubject(1);
  private _user$: Subject<WithRef<IUser>> = new ReplaySubject(1);
  compact$ = new ReplaySubject<boolean>(1);
  progress: UserPathwayProgress;

  @Input()
  set compact(compact: BooleanInput) {
    this.compact$.next(coerceBooleanProperty(compact));
  }

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this._pathway$.next(pathway);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }

  constructor(organisation: OrganisationService, cache: CachedListsService) {
    this.progress = new UserPathwayProgress(
      organisation.userGroups$,
      this._user$,
      this._pathway$,
      cache
    );
  }
}
