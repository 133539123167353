<div class="flex h-full flex-col" [style.max-width]="maxWidth">
  <lu-page-wrapper
    [width]="width"
    [style.border-bottom]="splashBorder"
    [style.margin-bottom.px]="marginBottom"
  >
    <h1 *ngIf="label" class="mat-headline-2">{{ label }}</h1>
    <ng-content select="header"></ng-content>
    <ng-content select="lu-page-header"></ng-content>
    <ng-content select="pt-breadcrumbs"></ng-content>
  </lu-page-wrapper>

  <lu-page-wrapper [width]="width" class="flex grow">
    <ng-content></ng-content>
  </lu-page-wrapper>
</div>
