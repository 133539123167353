import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { type ITag } from '@principle-theorem/level-up-core';
import { compact } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface INamedDocsToTags {
  tags$: Observable<WithRef<ITag>[]>;
}

export class NamedDocsToTags implements INamedDocsToTags {
  tags$: Observable<WithRef<ITag>[]>;

  constructor(
    docRefs$: Observable<DocumentReference<ITag>[]>,
    all$: Observable<WithRef<ITag>[]>
  ) {
    this.tags$ = combineLatest([docRefs$, all$]).pipe(
      map(([refs, all]: [DocumentReference<ITag>[], WithRef<ITag>[]]) =>
        this._docRefsToItems(refs, all)
      )
    );
  }

  private _docRefsToItems<T>(
    docRefs: DocumentReference<T>[],
    items: WithRef<T>[]
  ): WithRef<T>[] {
    return compact(
      docRefs.map((ref: DocumentReference<T>) =>
        items.find((item: WithRef<T>) => item.ref.path === ref.path)
      )
    );
  }
}
