<ng-container *ngIf="bundles$ | async as bundles; else loading">
  <ng-container *ngIf="bundles.length; else noBundles">
    <mat-list class="with-dividers">
      <mat-list-item *ngFor="let bundle of bundles; trackBy: trackByBundle">
        <span matListItemTitle class="flex items-center gap-2">
          <a [routerLink]="['./' + routePrefix, bundle.ref.id]">{{
            bundle.name
          }}</a>
          <lu-vendor-bundle-icons [bundle]="bundle" />
        </span>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mat-list>
    <div class="layout-margin flex flex-col gap-2">
      <lu-template-placeholder />
      <lu-template-placeholder />
      <lu-template-placeholder />
    </div>
  </mat-list>
</ng-template>

<ng-template #noBundles>
  <mat-list>
    <div class="layout-margin">
      <div class="mat-caption" fxLayout="row" fxLayoutAlign="start center">
        No Bundles
      </div>
    </div>
  </mat-list>
</ng-template>
