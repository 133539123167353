import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  type IUser,
  type IVendor,
  type IVendorBundle,
  VendorBundle,
  Vendor,
} from '@principle-theorem/level-up-core';
import {
  DialogPresets,
  InputSearchFilter,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  filterUndefined,
  findProp,
  getAll,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import {
  AddMarketplaceItemDialogComponent,
  type IAddMarketplaceItemData,
  type IMarketplaceItemFormData,
} from '../../../../../components/marketplace/add-marketplace-item-dialog/add-marketplace-item-dialog.component';

@Component({
  selector: 'lu-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorComponent {
  vendor$: Observable<WithRef<IVendor>>;
  users$: Observable<WithRef<IUser>[]>;
  userCollection$: Observable<CollectionReference<IUser>>;
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IVendorBundle>>;

  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) {
    this.vendor$ = this._route.data.pipe(
      findProp<WithRef<IVendor>>('vendor'),
      filterUndefined()
    );
    this.searchFilter = new InputSearchFilter<WithRef<IVendorBundle>>(
      this.vendor$.pipe(switchMap((vendor) => Vendor.bundles$(vendor))),
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );
    this.userCollection$ = this.vendor$.pipe(
      map((vendor) => Vendor.userCol(vendor))
    );

    this.users$ = this.userCollection$.pipe(
      getAll(),
      map((users) => sortBy(users, 'name'))
    );
  }

  async add(): Promise<void> {
    const bundle = await this._dialog
      .open<
        AddMarketplaceItemDialogComponent,
        IAddMarketplaceItemData,
        IMarketplaceItemFormData
      >(
        AddMarketplaceItemDialogComponent,
        DialogPresets.small({
          data: {
            label: 'Bundle',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!bundle) {
      return;
    }

    const vendor = await snapshot(this.vendor$);
    const bundleRef = await addDoc(
      Vendor.bundleCol(vendor),
      VendorBundle.init(bundle)
    );

    await this._router.navigate(['./', 'bundles', bundleRef.id], {
      relativeTo: this._route,
    });
    this._snackBar.open('Bundle Added');
  }
}
