import { type ComparableValue } from '@principle-theorem/shared';
import { mapKeys, snakeCase } from 'lodash';
import { type IUserStatistics } from './user-statistics';

export interface ICustomUserData {
  [key: string]: ComparableValue | undefined;
  workspace: string;
  lu_is_admin: boolean;
  lu_is_owner: boolean;

  // Should be phased out
  lu_author_total_skills: number;
  lu_author_total_draft_skills: number;
  lu_author_total_review_skills: number;
  lu_author_total_approved_skills: number;
  lu_reviewer_current_reviews: number;
  lu_reviewer_total_reviewed: number;
  lu_grower_current_level_up_requests: number;
  lu_grower_total_level_ups: number;
  lu_grower_total_self_level_ups: number;
  lu_grower_total_mentored_level_ups: number;
  lu_mentor_total_level_ups_approved: number;
}

export function toIntercomUserCustomData(
  userData: Partial<IUserStatistics>
): Partial<ICustomUserData> {
  const basic = mapKeys(userData, (_, key) => `lu_${snakeCase(key)}`);
  const existingKeys: Partial<ICustomUserData> = {
    lu_author_total_skills: userData.skillsDrafted,
    lu_author_total_draft_skills: userData.skillsDrafted,
    lu_author_total_review_skills: userData.skillsMarkedForReview,
    lu_grower_current_level_up_requests: userData.levelUpRequestsPending,
    lu_grower_total_level_ups: userData.totalStarsEarned,
    lu_grower_total_self_level_ups: userData.totalSelfStarsGiven,
    lu_mentor_total_level_ups_approved: userData.totalOthersStarsGiven,
    lu_author_total_approved_skills: userData.skillsApproved,
    lu_reviewer_current_reviews: userData.skillsAsReviewerPending,
    lu_reviewer_total_reviewed: userData.totalReviewsGiven,
    lu_grower_total_mentored_level_ups: userData.totalStarsEarnedFromOthers,
  };
  return { ...basic, ...existingKeys };
}
