import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgSharedModule, AppVersionModule } from '@principle-theorem/ng-shared';
import { AuthModule } from '../auth/auth.module';
import { ComponentsModule } from '../components/components.module';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgCoreModule } from '@principle-theorem/ng-core';
import { WithSidebarMainComponent } from './with-sidebar-main/with-sidebar-main.component';

@NgModule({
  declarations: [
    MainComponent,
    SidebarComponent,
    NotFoundComponent,
    WithSidebarMainComponent,
  ],
  imports: [
    CommonModule,
    NgSharedModule,
    NgCoreModule,
    RouterModule,
    AuthModule,
    ComponentsModule,
    NgIntercomComponentsModule,
    AppVersionModule,
  ],
  exports: [MainComponent],
})
export class LayoutModule {}
