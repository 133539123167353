import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { Editor } from '@tiptap/core';
import { isNil } from 'lodash';

export interface ILinkEditDialogData {
  editor: Editor;
  nodeType: string;
  href: string;
}

@Component({
    selector: 'pt-link-hover-menu',
    templateUrl: './link-hover-menu.component.html',
    styleUrls: ['./link-hover-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LinkHoverMenuComponent implements OnDestroy {
  form = new TypedFormGroup<Omit<ILinkEditDialogData, 'editor' | 'nodeType'>>({
    href: new TypedFormControl<string>(),
  });

  constructor(
    @Inject(DIALOG_DATA)
    public data: ILinkEditDialogData
  ) {
    this.form.patchValue({
      href: this.data.href,
    });
  }

  ngOnDestroy(): void {
    const data = this.form.getRawValue();
    if (isNil(data.href)) {
      return;
    }
    this.data.editor.chain().updateAttributes(this.data.nodeType, data).run();
  }

  removeLink(): void {
    this.data.editor.chain().focus().toggleLink({}).run();
  }
}
