import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { NgxMoveableComponent } from 'ngx-moveable';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface IResizeEvent {
  width: string;
  height: string;
}
@Component({
    selector: 'pt-drag-resize',
    exportAs: 'ptDragResize',
    templateUrl: './drag-resize.component.html',
    styleUrls: ['./drag-resize.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DragResizeComponent implements OnDestroy {
  private _onDestroy$ = new Subject<ElementRef<HTMLElement>>();
  disabled$ = new BehaviorSubject<boolean>(false);
  enabled$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);
  @Input() fixedRatio = true;
  @Output()
  resizeUpdate = new EventEmitter<IResizeEvent>();
  @Output()
  resizeEnd = new EventEmitter<void>();

  @ViewChild('moveable', {
    read: NgxMoveableComponent,
    static: false,
  })
  moveable: NgxMoveableComponent;

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  @Input()
  set loading(loading: BooleanInput) {
    this.loading$.next(coerceBooleanProperty(loading));
  }

  constructor() {
    combineLatest([this.loading$, this.disabled$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([loading, disabled]) => {
        if (this.moveable && !loading) {
          disabled ? this.moveable.destroy() : this.moveable.updateRect();
        }
      });

    this.enabled$ = combineLatest([this.disabled$, this.loading$]).pipe(
      map(([disabled, loading]) => !disabled && !loading)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  resized(width: number, height: number): void {
    this.resizeUpdate.next({
      width: `${width}px`,
      height: `${height}px`,
    });
  }
}
