<div
  class="card-container"
  class="flex grow flex-col items-stretch justify-stretch"
>
  <div class="card flex grow flex-col" [ngStyle]="{ borderColor: colour }">
    <div class="card-header">
      <ng-content select="lu-card-image"></ng-content>
      <ng-content select="lu-card-banner"></ng-content>
      <ng-content select="lu-card-actions"></ng-content>
    </div>

    <div class="card-info flex grow flex-col gap-3">
      <div class="card-info--title grow">
        <ng-container *ngIf="link; else viewOnly">
          <a [routerLink]="link">{{ title }}</a>
        </ng-container>
        <ng-template #viewOnly>
          <p>{{ title }}</p>
        </ng-template>
      </div>
      <div class="card-info--description mat-caption" *ngIf="description">
        {{ description }}
      </div>
      <div class="card-author">
        <lu-author *ngIf="author" [author]="author" />
      </div>
    </div>
  </div>

  <div class="progress">
    <ng-content select="lu-progress-bar"></ng-content>
  </div>
  <div class="buttons-container">
    <ng-content select="card-actions"></ng-content>
  </div>
</div>
