<mat-form-field class="w-full" floatLabel="always">
  @if (label(); as label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-chip-grid #chipGrid [formControl]="formCtrl">
    @for (item of formCtrl.value; track item) {
      <mat-chip-row
        [editable]="true"
        (removed)="remove(item)"
        (edited)="edit($event, item)"
      >
        {{ item }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
    <input
      placeholder="Add {{ itemName() }}..."
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      [matChipInputFor]="chipGrid"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-hint>
    <ng-content select="[matHintContent]" />
  </mat-hint>
</mat-form-field>
