import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InitialsIconComponent } from '../initials-icon/initials-icon.component';
import { ProfilePicComponent } from '../profile-pic/profile-pic.component';

@Component({
  selector: 'pt-user-icon',
  imports: [
    CommonModule,
    ProfilePicComponent,
    InitialsIconComponent,
    MatTooltipModule,
  ],
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconComponent {
  @Input() diameter = 40;
  @Input() name = '';
  @Input() src?: string;
}
