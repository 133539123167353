import {
  count,
  getDocs,
  multiMap,
  multiReduce,
  multiSwitchMap,
  toQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type ISkill,
  type ISkillLevelRequest,
  Skill,
  SkillLevelRequestStatus,
} from '../models/skill/skill';
import {
  getLevelUps,
  getTrainerLevelUps,
  getSelfLevelUps,
  type IUserLevelUpPair,
  type IUserSkillProgressPair,
} from '../models/skill/skill-progress';
import { type IUser } from '../models/user/user';
import { StatView } from './stat-view';
import { where } from '@principle-theorem/shared';

export class UserGrowStats {
  levelUps: StatView;
  selfLevelUps: StatView;
  mentorLevelUps: StatView;
  requestedLevelUps: StatView;

  constructor(
    user$: Observable<WithRef<IUser>>,
    allSkills$: Observable<WithRef<ISkill>[]>,
    userSkillProgress$: Observable<IUserSkillProgressPair[]>
  ) {
    const myLevelUps$ = this._myLevelUps(userSkillProgress$);
    const levelUpCount$ = myLevelUps$.pipe(count());
    this.levelUps = new StatView(myLevelUps$.pipe(count()), levelUpCount$);

    this.selfLevelUps = new StatView(
      combineLatest([user$, myLevelUps$]).pipe(
        map(([user, levelUpPairs]) => getSelfLevelUps(user, levelUpPairs)),
        count()
      ),
      levelUpCount$
    );
    this.mentorLevelUps = new StatView(
      combineLatest([user$, myLevelUps$]).pipe(
        map(([user, levelUpPairs]) => getTrainerLevelUps(user, levelUpPairs)),
        count()
      ),
      levelUpCount$
    );

    const tainingRequests$ = this._tainingRequests(
      allSkills$,
      userSkillProgress$
    );
    this.requestedLevelUps = new StatView(tainingRequests$, tainingRequests$);
  }

  private _myLevelUps(
    allUserSkills$: Observable<IUserSkillProgressPair[]>
  ): Observable<IUserLevelUpPair[]> {
    return allUserSkills$.pipe(
      multiMap(getLevelUps),
      map((items) =>
        items
          .reduce((acc, item) => [...acc, ...item], [])
          .filter((levelUpPair) => levelUpPair.levelUp.difference > 0)
      )
    );
  }

  private _tainingRequests(
    allSkills$: Observable<WithRef<ISkill>[]>,
    allUserProgress$: Observable<IUserSkillProgressPair[]>
  ): Observable<number> {
    const userTrainerRefs$ = allUserProgress$.pipe(
      multiMap(
        (userProgressPair) => userProgressPair.progress.skillRef.ref.path
      )
    );
    return combineLatest([allSkills$, userTrainerRefs$]).pipe(
      map(([allSkills, mentorRefs]) =>
        allSkills.filter((skill) => mentorRefs.includes(skill.ref.path))
      ),
      multiSwitchMap((skill) =>
        getDocs<ISkillLevelRequest>(
          toQuery(
            Skill.trainingRequestCol(skill),
            where('status', '==', SkillLevelRequestStatus.Pending)
          )
        )
      ),
      multiReduce((total, skillLevelRequests) => {
        return total + skillLevelRequests.length;
      }, 0)
    );
  }
}
