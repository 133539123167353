<mat-list-item class="compact" *ngIf="skill$ | async as skill">
  <div class="flex items-center">
    <lu-user-skill-level-indicator
      class="mr-4 mt-2"
      [user]="user$ | async"
      [skill]="skill"
    />

    <div class="flex grow flex-col">
      <a [routerLink]="['/explore/skill', skill.ref.id]">{{ skill.name }}</a>
      <div *ngIf="withTags && skill.tags.length">
        <lu-tag-display [tagRefs]="skill.tags" />
      </div>
    </div>

    <div class="flex flex-row items-center">
      <ng-container *ngIf="canEdit$ | async">
        <lu-user-goal-due-date-indicator
          [canEdit]="canEdit$ | async"
          [dueDate]="progress.dueDateBloc.dueDate$ | async"
          (dateChange)="updateDueDate($event)"
        />
      </ng-container>

      <lu-user-skill-progress
        [canEdit]="canEdit$ | async"
        [user]="user$ | async"
        [skill]="skill"
      />

      <ng-container *ngIf="skill.author && showAuthor">
        <ng-container *ngIf="skill.author | refItem$ | async as author">
          <div class="user-icon-container">
            <pt-user-icon
              [src]="author | profileImage$ | async"
              [diameter]="30"
              [name]="author.name"
              [matTooltip]="author.name"
            />
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</mat-list-item>
