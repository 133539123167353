<div [matTooltip]="tooltip$ | async" [style.height]="size + 'px'">
  <mat-icon
    *ngIf="progress.hasGoal$ | async; else noGoal"
    [ngClass]="{
      'in-progress': progress.inProgress$ | async,
      complete: progress.isComplete$ | async,
    }"
    >check_circle</mat-icon
  >

  <ng-template #noGoal>
    <mat-icon class="no-goal">radio_button_unchecked</mat-icon>
  </ng-template>
</div>
