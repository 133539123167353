import { type IMention } from '@principle-theorem/editor';
import { type INamedDocument } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export type ILUMention = IMention<MentionResourceType>;

export function toMention(
  data: INamedDocument,
  type: MentionResourceType
): ILUMention {
  return {
    uid: uuid(),
    key: data.name,
    resource: {
      uid: data.ref.id,
      ref: data.ref,
      type,
    },
  };
}

export function mentionToLink(mention: ILUMention): string {
  switch (mention.resource.type) {
    case MentionResourceType.User:
      return ['/', 'admin', 'users', mention.resource.ref.id].join('/');
    case MentionResourceType.Skill:
      return ['/', 'explore', 'skill', mention.resource.ref.id].join('/');
    case MentionResourceType.Pathway:
      return ['/', 'explore', 'pathway', mention.resource.ref.id].join('/');
    case MentionResourceType.Folder:
      return ['/', 'folders', mention.resource.ref.id].join('/');
    default:
      return '';
  }
}

export enum MentionResourceType {
  Bundle = 'bundle',
  Folder = 'folder',
  Pathway = 'pathway',
  Skill = 'skill',
  User = 'user',
  Vendor = 'vendor',
}

export const MENTION_RESOURCE_TYPES: MentionResourceType[] = [
  MentionResourceType.User,
  MentionResourceType.Skill,
  MentionResourceType.Pathway,
  MentionResourceType.Bundle,
  MentionResourceType.Vendor,
  MentionResourceType.Folder,
];
