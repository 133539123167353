<mat-toolbar color="primary">Edit User</mat-toolbar>

<form [formGroup]="userEditForm" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" cdkFocusInitial />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>

    <ng-container *ngIf="canChangeAdminStatus$ | async">
      <mat-checkbox formControlName="isAdmin">Is Administrator</mat-checkbox>
    </ng-container>

    <div class="groups" ptIntercomTarget="user.groups">
      <strong>Groups</strong>
      <lu-user-group-assignment
        [user]="data.user"
        (groupAssignmentChange)="assignedGroups$.next($event)"
      />
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-button mat-dialog-close color="warn">
        Cancel
      </button>
      <button
        *ngIf="data.user.email; else sendAndInvite"
        mat-stroked-button
        [disabled]="userEditForm.invalid || submitting"
        color="primary"
      >
        Save
      </button>
      <ng-template #sendAndInvite>
        <button
          type="button"
          mat-stroked-button
          [disabled]="userEditForm.invalid || submitting"
          color="primary"
          (click)="submit(true)"
        >
          Save & Send Invite
        </button>
      </ng-template>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
