<lu-page-simple marginBottom="16">
  <lu-page-header
    label="Teams"
    pageInfo="Create teams that your users belong to and assign them their skills or pathways here"
    [pageTour]="tourId"
  >
    <lu-page-toolbar>
      <lu-header-button
        buttonIcon="add"
        buttonText="Create"
        (click)="addGroup()"
        ptIntercomTarget="groups.create-button"
        luPageToolbarAction
      />
    </lu-page-toolbar>
  </lu-page-header>

  <lu-page-toolbar>
    <pt-search-field fxFlex>
      <input matInput placeholder="Filter" [formControl]="search" />
    </pt-search-field>
  </lu-page-toolbar>

  <mat-list class="with-dividers" ptIntercomTarget="groups.list">
    <ng-container
      *ngIf="searchFilter.results$ | async as userGroups; else loading"
    >
      <mat-list-item
        *ngFor="let userGroup of userGroups; trackBy: trackByGroup"
      >
        <div class="flex flex-col">
          <div class="flex flex-row items-center gap-2">
            <a
              [routerLink]="['./', userGroup.ref.id]"
              ptIntercomTarget="groups.group.name"
              >{{ userGroup.name }}</a
            >
            <mat-icon
              *ngIf="userGroup.readOnly"
              matTooltip="Read only"
              class="read-only"
            >
              lock
            </mat-icon>
          </div>

          <span class="mat-caption">{{ userGroup.description }}</span>
        </div>

        <div matListItemMeta class="flex flex-row items-center gap-4">
          <p class="mat-body-2 members-count">
            {{ userGroup.users.length }} members
          </p>
          <button
            fxHide.lt-sm
            mat-icon-button
            color="warn"
            [disabled]="userGroup.readOnly"
            (click)="remove(userGroup)"
          >
            <mat-icon>delete</mat-icon>
          </button>

          <div fxHide.gt-xs>
            <button mat-icon-button [matMenuTriggerFor]="moreMenu">
              <mat-icon class="font-bold">more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
              <button
                mat-menu-item
                class="warn"
                (click)="remove(userGroup)"
                [disabled]="userGroup.readOnly"
              >
                <mat-icon color="warn">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-list-item>
    </ng-container>

    <ng-template #loading>
      <div class="layout-margin flex flex-col gap-2">
        <lu-group-placeholder />
        <lu-group-placeholder />
        <lu-group-placeholder />
      </div>
    </ng-template>
  </mat-list>
</lu-page-simple>
