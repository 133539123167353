import { NgModule } from '@angular/core';
import { PathwayPlaceholderComponent } from './pathway-placeholder/pathway-placeholder.component';
import { SkillPlaceholderComponent } from './skill-placeholder/skill-placeholder.component';
import { UserPlaceholderComponent } from './user-placeholder/user-placeholder.component';
import { GroupPlaceholderComponent } from './group-placeholder/group-placeholder.component';
import { TagPlaceholderComponent } from './tag-placeholder/tag-placeholder.component';
import { TemplatePlaceholderComponent } from './template-placeholder/template-placeholder.component';
import { CardPlaceholderComponent } from './card-placeholder/card-placeholder.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';

@NgModule({
  imports: [NgSharedModule],
  declarations: [
    PathwayPlaceholderComponent,
    SkillPlaceholderComponent,
    UserPlaceholderComponent,
    GroupPlaceholderComponent,
    TagPlaceholderComponent,
    TemplatePlaceholderComponent,
    CardPlaceholderComponent,
  ],
  exports: [
    PathwayPlaceholderComponent,
    SkillPlaceholderComponent,
    UserPlaceholderComponent,
    GroupPlaceholderComponent,
    TagPlaceholderComponent,
    TemplatePlaceholderComponent,
    CardPlaceholderComponent,
  ],
})
export class PlaceholdersModule {}
