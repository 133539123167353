/* eslint-disable no-console */
import {
  DocumentReference,
  IReffable,
  Reffable,
  WithRef,
  addDoc,
  asyncForEach,
  firstResult,
  getDoc,
  getDocs,
  patchDoc,
  snapshot,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { Folder, IFolder } from '../folder';
import { IOrganisation, Organisation } from '../organisation/organisation';
import { IPathway } from '../pathway/pathway';
import { ISkill, Skill } from '../skill/skill';
import { IBundleSkill, VendorPathway } from './bundle';
import { IVendorBundle, VendorBundle } from './vendor-bundle';
import { VendorBundleRelease } from './vendor-bundle-release';
import { compact } from 'lodash';

interface ICopyBundleResult {
  skillRefs: DocumentReference<ISkill>[];
  pathwayRefs: DocumentReference<IPathway>[];
}

export class MarketplaceContentCopier {
  static async isAllCopied(
    bundle: WithRef<IVendorBundle>,
    organisation: WithRef<IOrganisation>
  ): Promise<boolean> {
    const latestRelease = await VendorBundle.resolveLatestRelease(bundle);
    if (!latestRelease) {
      return false;
    }

    const skills = await getDocs(VendorBundleRelease.skillCol(latestRelease));
    const pathways = await getDocs(
      VendorBundleRelease.pathwayCol(latestRelease)
    );

    const skillsInWorkspace = await asyncForEach(skills, (releaseSkill) =>
      MarketplaceContentCopier.isSkillInWorkspace(releaseSkill, organisation)
    );

    const pathwaysInWorkspace = await asyncForEach(pathways, (releasePathway) =>
      MarketplaceContentCopier.isPathwayInWorkspace(
        releasePathway,
        organisation
      )
    );

    return (
      skillsInWorkspace.filter((skill) => !!skill).length === skills.length &&
      pathwaysInWorkspace.filter((pathway) => !!pathway).length ===
        pathways.length
    );
  }

  static async copyBundle(
    bundle: WithRef<IVendorBundle>,
    organisation: WithRef<IOrganisation>,
    createSubFolders: boolean = false,
    folderRef?: DocumentReference<IFolder>
  ): Promise<ICopyBundleResult | undefined> {
    const latestRelease = await VendorBundle.resolveLatestRelease(bundle);
    if (!latestRelease) {
      return;
    }

    const skills = await getDocs(VendorBundleRelease.skillCol(latestRelease));
    const pathways = await getDocs(
      VendorBundleRelease.pathwayCol(latestRelease)
    );

    const skillRefs = await asyncForEach(skills, (releaseSkill) =>
      MarketplaceContentCopier.copySkill(
        releaseSkill,
        organisation,
        createSubFolders,
        folderRef
      )
    );

    const pathwayRefs = await asyncForEach(pathways, async (releasePathway) => {
      let pathwayFolderRef = folderRef;
      if (createSubFolders) {
        pathwayFolderRef = await addDoc(
          Organisation.folderCol(organisation),
          Folder.init({
            name: releasePathway.name,
            parentFolderRef: folderRef,
          })
        );
      }

      return MarketplaceContentCopier.copyPathway(
        releasePathway,
        organisation,
        createSubFolders,
        pathwayFolderRef
      );
    });

    return {
      skillRefs,
      pathwayRefs,
    };
  }

  static async copySkillAttachments(
    skill: Reffable<IBundleSkill> | Reffable<ISkill>,
    releaseSkillRef: DocumentReference<ISkill>
  ): Promise<void> {
    const attachments = await getDocs(Skill.attachmentCol(skill));
    await asyncForEach(attachments, (attachment) =>
      addDoc(Skill.attachmentCol({ ref: releaseSkillRef }), attachment)
    );
  }

  static async isSkillInWorkspace(
    skill: WithRef<ISkill>,
    organisation: IReffable<IOrganisation>
  ): Promise<WithRef<ISkill> | undefined> {
    const skillCol = Organisation.skillCol(organisation);
    return firstResult(
      undeletedQuery(skillCol),
      where('vendorSkillRef', '==', skill.vendorSkillRef)
    );
  }

  static async copySkill(
    skill: WithRef<ISkill>,
    organisation: IReffable<IOrganisation>,
    changeFolderIfExists: boolean = false,
    folderRef?: DocumentReference<IFolder>
  ): Promise<DocumentReference<ISkill>> {
    if (!skill.vendorSkillRef) {
      return skill.ref;
    }

    const isSkillInWorkspace =
      await MarketplaceContentCopier.isSkillInWorkspace(skill, organisation);

    if (isSkillInWorkspace) {
      if (changeFolderIfExists) {
        await patchDoc(isSkillInWorkspace.ref, { folderRef });
      }
      return isSkillInWorkspace.ref;
    }

    const skillCol = Organisation.skillCol(organisation);
    const newSkillRef = await addDoc(skillCol, {
      ...skill,
      folderRef,
    });
    await MarketplaceContentCopier.copySkillAttachments(skill, newSkillRef);
    return newSkillRef;
  }

  static async isPathwayInWorkspace(
    pathway: WithRef<IPathway>,
    organisation: IReffable<IOrganisation>
  ): Promise<WithRef<IPathway> | undefined> {
    if (!pathway.vendorPathwayRef) {
      return;
    }
    const pathwayCol = Organisation.pathwayCol(organisation);
    return snapshot(
      VendorPathway.resolveLocalPathway$(pathway.vendorPathwayRef, pathwayCol)
    );
  }

  static async copyPathway(
    pathway: WithRef<IPathway>,
    organisation: IReffable<IOrganisation>,
    changeFolderIfExists: boolean = false,
    folderRef?: DocumentReference<IFolder>
  ): Promise<DocumentReference<IPathway>> {
    if (!pathway.vendorPathwayRef) {
      return pathway.ref;
    }

    const isPathwayInWorkspace =
      await MarketplaceContentCopier.isPathwayInWorkspace(
        pathway,
        organisation
      );

    const latestPathway = isPathwayInWorkspace ?? pathway;

    const sections = await asyncForEach(
      latestPathway.sections,
      async (section) => ({
        ...section,
        steps: compact(
          await asyncForEach(section.steps, async (stepRef) => {
            try {
              const step = await getDoc(stepRef);
              return await MarketplaceContentCopier.copySkill(
                step,
                organisation,
                changeFolderIfExists,
                latestPathway.folderRef ?? folderRef
              );
            } catch (error) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              console.error(error);
            }
          })
        ),
      })
    );

    if (isPathwayInWorkspace) {
      if (changeFolderIfExists) {
        await patchDoc(isPathwayInWorkspace.ref, { sections, folderRef });
      } else {
        await patchDoc(isPathwayInWorkspace.ref, { sections });
      }
      return isPathwayInWorkspace.ref;
    }

    return addDoc(Organisation.pathwayCol(organisation), {
      ...pathway,
      sections,
      folderRef,
    });
  }
}
