import { MixedSchema } from '@principle-theorem/editor';
import {
  isSameRef,
  sortByUpdatedAt,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { flow } from 'lodash';
import { type IUser } from '../user/user';

export interface ISkillReview {
  comments?: MixedSchema;
  isApproved: boolean;
  reviewer: DocumentReference<IUser>;
}

export function latestReviewIsApproved(
  reviews: WithRef<ISkillReview>[]
): boolean {
  const orderedReviews = reviews.sort(sortByUpdatedAt).reverse();
  return orderedReviews.length
    ? orderedReviews[orderedReviews.length - 1].isApproved
    : false;
}

export function filterReviewsByReviewer(
  reviews: WithRef<ISkillReview>[],
  reviewer: DocumentReference<IUser>
): WithRef<ISkillReview>[] {
  return reviews.filter((review: WithRef<ISkillReview>) =>
    isSameRef(review.reviewer, reviewer)
  );
}

export const reviewerHasApproved = flow(
  filterReviewsByReviewer,
  latestReviewIsApproved
);
