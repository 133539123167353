import {
  type IMarketplaceSubscription,
  type IUser,
  MarketplaceSubscriptionStatus,
} from '@principle-theorem/level-up-core';
import { InputSearchFilter } from '@principle-theorem/ng-shared';
import { multiFilter, multiMap, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { type IBundleWithSubscription } from '../../../services/cached-lists.service';

export class SubscriptionListBloc {
  searchFilter: InputSearchFilter<IBundleWithSubscription>;
  filteredSubscriptions$: Observable<WithRef<IMarketplaceSubscription>[]>;

  constructor(
    subscriptionBundlePair$: Observable<IBundleWithSubscription[]>,
    search$: Observable<string>,
    public user$: Observable<WithRef<IUser>>
  ) {
    this.searchFilter = new InputSearchFilter<IBundleWithSubscription>(
      subscriptionBundlePair$,
      search$,
      ['bundle.name']
    );

    this.filteredSubscriptions$ = this.searchFilter.results$.pipe(
      multiFilter(
        (bundle) =>
          bundle.subscription.status ===
          MarketplaceSubscriptionStatus.Subscribed
      ),
      multiMap((bundle) => bundle.subscription)
    );
  }
}
