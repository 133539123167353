import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  GoalProgress,
  SkillLevel,
  skillLevelToNumber,
} from '@principle-theorem/level-up-core';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'lu-level-indicator',
    templateUrl: './level-indicator.component.html',
    styleUrls: ['./level-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LevelIndicatorComponent {
  private _level$ = new ReplaySubject<SkillLevel>(1);
  private _goal$ = new BehaviorSubject<SkillLevel>(SkillLevel.None);
  private _isRequest$ = new BehaviorSubject(false);
  readonly type = SkillLevel;
  goalType$: Observable<SkillLevel>;
  goalProgress$: Observable<GoalProgress>;
  tooltip$: Observable<string>;
  @Input() diameter = 24;

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  @HostBinding('style.font-size.px')
  get size(): number {
    return this.diameter;
  }

  @Input()
  set goal(goal: SkillLevel | undefined) {
    this._goal$.next(goal ?? SkillLevel.None);
  }

  @Input()
  set level(level: SkillLevel | undefined) {
    this._level$.next(level ?? SkillLevel.None);
  }

  @Input()
  set isRequest(isRequest: BooleanInput) {
    this._isRequest$.next(coerceBooleanProperty(isRequest));
  }

  constructor() {
    this.goalType$ = combineLatest([
      this._level$,
      this._goal$,
      this._isRequest$,
    ]).pipe(
      map(([level, goal, isRequest]) => getGoalType(level, goal, isRequest))
    );
    this.goalProgress$ = combineLatest([
      this._level$,
      this._goal$,
      this._isRequest$,
    ]).pipe(
      map(([level, goal, isRequest]) => getGoalProgress(level, goal, isRequest))
    );

    this.tooltip$ = combineLatest([this.goalType$, this.goalProgress$]).pipe(
      map(([goalType, goalProgress]) => {
        if (goalType === SkillLevel.None) {
          return 'No Goal';
        }

        if (goalProgress === GoalProgress.Complete) {
          const prefix = 'Complete - ';
          switch (goalType) {
            case SkillLevel.Viewed:
              return prefix + 'Viewed';
            case SkillLevel.VerifiedByTrainer:
              return prefix + 'Verified by Trainer';
            case SkillLevel.Trainer:
              return prefix + 'Trainer';
            default:
              return '';
          }
        }

        if (goalProgress === GoalProgress.Request) {
          const prefix = 'In Progress - ';
          switch (goalType) {
            case SkillLevel.VerifiedByTrainer:
              return prefix + 'Get verified by Trainer to complete';
            case SkillLevel.Trainer:
              return prefix + 'Become a Trainer to complete';
            default:
              return '';
          }
        }

        const prefix = 'Not Started - ';
        switch (goalType) {
          case SkillLevel.Viewed:
            return prefix + 'View Skill to complete';
          case SkillLevel.VerifiedByTrainer:
            return prefix + 'Get verified by Trainer to complete';
          case SkillLevel.Trainer:
            return prefix + 'Become a Trainer to complete';
          default:
            return '';
        }
      })
    );
  }
}

export function getGoalType(
  level: SkillLevel,
  goal: SkillLevel,
  isRequest: boolean
): SkillLevel {
  if (level === SkillLevel.Trainer) {
    return SkillLevel.Trainer;
  }

  if (isRequest && goal === SkillLevel.None) {
    return SkillLevel.VerifiedByTrainer;
  }

  if (level !== SkillLevel.None && goal === SkillLevel.None) {
    return level;
  }

  return goal;
}

export function getGoalProgress(
  level: SkillLevel,
  goal: SkillLevel,
  isRequest: boolean
): GoalProgress {
  if (isRequest) {
    return GoalProgress.Request;
  }

  if (
    level !== SkillLevel.None &&
    skillLevelToNumber(level) >= skillLevelToNumber(goal)
  ) {
    return GoalProgress.Complete;
  }

  if (level !== SkillLevel.None && goal === SkillLevel.None) {
    return GoalProgress.Complete;
  }

  return level === goal ? GoalProgress.Complete : GoalProgress.Incomplete;
}
