import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type AtLeast } from '@principle-theorem/shared';
import {
  confirmationDialogData,
  type IConfirmationDialogData,
} from '../confirm-dialog/confirm-dialog.component';
import { TypedFormControl, TypedFormGroup } from '../forms/typed-form-group';

export interface ITextPromptDialogData extends IConfirmationDialogData {
  defaultValue: string;
}

export type ITextPromptDialogInput = AtLeast<
  ITextPromptDialogData,
  'title' | 'prompt'
>;

export function textPromptDialogData(
  data: ITextPromptDialogInput
): ITextPromptDialogData {
  return {
    ...confirmationDialogData(data),
    defaultValue: '',
    ...data,
  };
}

interface ITextPromptFormData {
  result: string;
}

class TextPromptFormGroup extends TypedFormGroup<ITextPromptFormData> {
  constructor() {
    super({
      result: new TypedFormControl<string>('', [Validators.required]),
    });
  }
}

@Component({
    selector: 'pt-text-prompt-dialog',
    templateUrl: './text-prompt-dialog.component.html',
    styleUrls: ['./text-prompt-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextPromptDialogComponent {
  formGroup: TextPromptFormGroup = new TextPromptFormGroup();
  data: ITextPromptDialogData;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: ITextPromptDialogInput,
    public dialogRef: MatDialogRef<
      TextPromptDialogComponent,
      string | undefined
    >
  ) {
    this.data = textPromptDialogData(data);
    this.formGroup.patchValue({ result: this.data.defaultValue ?? '' });
  }

  submit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.dialogRef.close(this.formGroup.value.result);
  }
}
