import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getError, snapshot } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { NgFireMediaUploader } from '../ng-firestore-uploader';

@Component({
  selector: 'pt-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ImageUploadComponent {
  private _uploadPath$: ReplaySubject<string> = new ReplaySubject(1);
  @Input() iconButton = false;
  @Input() menuButton = false;
  @Input() label = 'Upload Image';
  @Output() imageUpload: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  imageAllowedTypes: string = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/gif',
  ].join(', ');

  @Input()
  set uploadPath(path: string) {
    if (path) {
      this._uploadPath$.next(path);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _storage: Storage
  ) {}

  async upload(event: Event): Promise<void> {
    const element = event.target as HTMLInputElement;
    if (!element.files) {
      return;
    }

    try {
      const uploader = new NgFireMediaUploader(
        this._storage,
        this._uploadPath$
      );
      const file = element.files[0];
      const upload = uploader.upload(file);
      const uploadComplete = await snapshot(upload.isUploadComplete$);
      if (!uploadComplete) {
        throw new Error('Failed to upload image');
      }

      const attachment = await snapshot(upload.attachment$);
      this.imageUpload.emit(attachment.path);
      this._snackBar.open('Image uploaded');
      this.fileInput.nativeElement.value = '';
    } catch (error) {
      this._snackBar.open(getError(error));
    }
  }
}
