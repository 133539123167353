import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import {
  FeatureVisibility,
  type IFeature,
} from '@principle-theorem/feature-flags';
import { filterUndefined, findProp } from '@principle-theorem/shared';

@Component({
    selector: 'pt-feature-info',
    imports: [CommonModule, MatIconModule],
    templateUrl: './feature-info.component.html',
    styleUrls: ['./feature-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureInfoComponent {
  route = inject(ActivatedRoute);

  feature = toSignal(
    this.route.data.pipe(findProp<IFeature>('feature'), filterUndefined())
  );

  isAlpha = computed(
    () => this.feature()?.visibility === FeatureVisibility.Alpha
  );
}
