import {
  capitalize,
  chunk,
  compact,
  find,
  first,
  flow,
  isEqual,
  kebabCase,
  noop,
  partialRight,
  property,
  sortBy,
  tail,
  toPairs,
  trim,
} from 'lodash';
import ShortUniqueId from 'short-unique-id';
import { v4 as uuid } from 'uuid';
import { isArray, isObject } from './common';
import { isSameRef } from './firebase/doc-ref';
import { DocumentReference } from './firebase/firestore/adaptor';
import { WithRef } from './firebase/interfaces';
import { type PropertyNames } from './utility-types';

/**
 * @deprecated
 * Use array.flat() instead.
 */
export function reduceToSingleArray<T>(collection: T[][]): T[] {
  return collection.reduce(
    (previous: T[], current: T[]) => previous.concat(current),
    []
  );
}

export function reduceToSingleArrayFn<T>(previous: T[], current: T[]): T[] {
  return [...previous, ...current];
}

export function reduceToSingleObjectByKey<T>(
  collection: { [key: string]: T }[]
): { [key: string]: T } {
  return collection.reduce((acc, current) => {
    return { ...acc, ...current };
  }, {});
}

export function slugify(value: string): string {
  return kebabCase(value);
}

export function uid(long: boolean = false): string {
  return long ? uuid() : new ShortUniqueId({ length: 6 }).rnd();
}

export function removeAdditionalSpaces(text: string): string {
  return text
    .split(' ')
    .filter((segment) => segment.length > 0)
    .join(' ');
}

export function splitName(name: string): string[] {
  const trimmed = trim(name);
  const divider = trimmed.indexOf(' ');
  if (divider === -1) {
    return [trimmed];
  }

  const firstName = trimmed.substring(0, divider);
  const lastNames = trimmed.substring(divider + 1);
  return [trim(firstName), trim(lastNames)];
}

export async function asyncForEach<R, T>(
  items: T[],
  operatorFn: (item: T, index: number, list: T[]) => Promise<R>
): Promise<R[]> {
  return Promise.all(
    items.map((item, index, list) => operatorFn(item, index, list))
  );
}

/**
 * Asynchronously iterate over all items in the list. This will only pass if all of the items pass. This is contrary to asyncForEach, which will pass as long as one item passes.
 */
export async function asyncForAll<R, T>(
  items: T[],
  operatorFn: (item: T, index: number, list: T[]) => Promise<R>
): Promise<R[]> {
  const results = await Promise.allSettled(
    items.map((item, index, list) => operatorFn(item, index, list))
  );
  const allPassed = results.every((result) => result.status === 'fulfilled');
  if (allPassed) {
    return compact(
      results.map((result) =>
        result.status === 'fulfilled' ? result.value : undefined
      )
    );
  }

  throw new Error(
    compact(
      results.map((result) =>
        result.status === 'rejected' ? String(result.reason) : undefined
      )
    ).join('\n')
  );
}

export async function asyncReduce<R, T>(
  items: R[],
  asyncFn: (acc: T, item: R) => Promise<T>,
  initialValue: T
): Promise<T> {
  let accumulator = initialValue;
  await resolveSequentially(items, async (item) => {
    accumulator = await asyncFn(accumulator, item);
  });
  return accumulator;
}

export async function resolveSequentially<R, T>(
  items: R[],
  asyncFn: (item: R, index: number) => Promise<T>
): Promise<T[]> {
  const results: T[] = [];
  for (let index = 0; index < items.length; index++) {
    results.push(await asyncFn(items[index], index));
  }
  return results;
}

export async function resolveParallel<T>(promises: Promise<T>[]): Promise<T[]> {
  return Promise.all(promises);
}

export async function resolveWithConcurrency<T, R>(
  items: T[],
  concurrency: number,
  operatorFn: (item: T, chunkIndex: number, itemIndex: number) => Promise<R>
): Promise<R[]> {
  const chunks = chunk(items, concurrency);
  const nestedResults = await resolveSequentially(
    chunks,
    (chunkItems: T[], chunkIndex: number) =>
      asyncForEach(chunkItems, (item, itemIndex) =>
        operatorFn(item, chunkIndex, itemIndex)
      )
  );
  return nestedResults.flat();
}

export async function asyncFind<T>(
  items: Promise<T>[],
  predicateFn: (item: T) => boolean = (item: T) => item !== undefined
): Promise<T | undefined> {
  for (const item of items) {
    const result = await item;
    if (predicateFn(result)) {
      return result;
    }
  }
}

export function isEven(n: number): boolean {
  return n % 2 === 0;
}

export function isOdd(n: number): boolean {
  return !isEven(n);
}

export function minutesToMilliseconds(minutes: number): number {
  return minutes * 1000 * 60;
}

/**
 * Reverse the "polarity" of a number, turning:
 * - positive into negative values
 * - negative into positive values
 */
export function invertNumber(num: number): number {
  return num * -1;
}

/**
 * Build a function to ensure a string has at least {amount} many characters
 * by prepending 'missing' leading charcters with {character}.
 * Intended primarily for use with ada codes.
 */
export function prefixCharacters(
  character: string,
  amount: number
): (value: string | number) => string {
  const base = Array(amount).fill(character).join('');
  return (value) => {
    const valueStr = `${value}`;
    if (valueStr.length >= amount) {
      return valueStr;
    }
    return `${base}${value}`.slice(-amount);
  };
}

export function suffixCharacters(
  character: string,
  amount: number
): (value: string | number) => string {
  const base = Array(amount).fill(character).join('');
  return (value) => {
    const valueStr = `${value}`;
    if (valueStr.length >= amount) {
      return valueStr;
    }
    return `${value}${base}`.substring(0, amount);
  };
}

export function getInheritedPropertyDescriptors<T extends object>(
  obj: T
): { [x: string]: TypedPropertyDescriptor<unknown> } {
  const objDescriptors = Object.getOwnPropertyDescriptors(obj);
  const proto: unknown = Object.getPrototypeOf(obj);
  if (!isObject(proto)) {
    return objDescriptors;
  }
  const protoDescriptors = getInheritedPropertyDescriptors(proto);
  return { ...protoDescriptors, ...objDescriptors };
}

export function findByProperty<T extends object, K extends PropertyNames<T>>(
  key: K,
  value: string,
  items: T[]
): T | undefined {
  return find(items, flow(property(key), partialRight(isEqual, value)));
}

export function mapDocRefs<Model extends object>(
  docRefs: DocumentReference<Model>[],
  items: WithRef<Model>[]
): WithRef<Model>[] {
  return compact(docRefs.map((docRef) => mapDocRef(docRef, items)));
}

export function mapDocRef<Model extends object>(
  docRef: DocumentReference<Model>,
  items: WithRef<Model>[]
): WithRef<Model> | undefined {
  return items.find((item) => isSameRef(docRef, item));
}

export function findByPropertyRecursive<
  T extends object,
  K extends PropertyNames<T>,
>(key: K, valuePath: string, items: T[], nestedPath: K): T | undefined {
  const values = valuePath.split('.');
  const firstValue = first(values);
  const found = firstValue ? findByProperty(key, firstValue, items) : undefined;
  if (values.length === 1 || !found) {
    return found;
  }

  const subPath = tail(values).join('.');
  return findByPropertyRecursive(
    key,
    subPath,
    found[nestedPath] as unknown as T[],
    nestedPath
  );
}

export interface IGroup<T, G> {
  group: G;
  items: T[];
}

export function customGroupBy<T, G>(
  items: T[],
  getGroupFn: (item: T) => G,
  compareGroupFn: (aGroup: G, bGroup: G) => boolean,
  sortGroupKey?: keyof G | string
): IGroup<T, G>[] {
  return items.reduce((acc: IGroup<T, G>[], item: T) => {
    const itemGroup: G = getGroupFn(item);
    const existing = acc.find((group) =>
      compareGroupFn(group.group, itemGroup)
    );
    if (existing) {
      existing.items.push(item);
      return acc;
    }
    const newGroup: IGroup<T, G> = {
      group: itemGroup,
      items: [item],
    };

    const groups = [...acc, newGroup];
    if (sortGroupKey) {
      return sortBy(groups, [`group.${sortGroupKey.toString()}`]);
    }
    return groups;
  }, []);
}

/**
 * Iterates over the given items, sorting them into groups based on the key/s
 * they return. Also gives the ability to transform the item as it goes into
 * each group. Returned object will be a map of the keys returned and an array
 * of transformed items for that key.
 */
export function createGroupMap<K extends string, T, R>(
  items: T[],
  getKeys: (item: T) => string[] | string,
  transformFn: (item: T, currentKey: string) => R | undefined
): Record<K, R[]> {
  return items.reduce((groups: Record<string, R[]>, item) => {
    const key = getKeys(item);
    const keys = isArray(key) ? key : [key];
    keys.map((currentKey) => {
      if (!groups[currentKey]) {
        groups[currentKey] = [];
      }
      const value = transformFn(item, currentKey);
      if (value) {
        groups[currentKey].push(value);
      }
    });
    return groups;
  }, {});
}

export function titlecase(value: string): string {
  return value
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

export function splitCamel(value: string): string {
  return value
    .split('')
    .reduce(
      (
        output: string,
        character: string,
        index: number,
        chartacters: string[]
      ) => {
        if (
          !new RegExp(/[A-Z]$/).exec(output) &&
          new RegExp(/^[A-Z]/).exec(character)
        ) {
          return output + ` ${character}`;
        }

        if (
          new RegExp(/^[A-Z]/).exec(character) &&
          chartacters[index + 1] &&
          !new RegExp(/[A-Z]$/).exec(chartacters[index + 1])
        ) {
          return output.concat(` ${character}`);
        }

        return output.concat(character);
      },
      ''
    )
    .trim();
}

export function splitSnake(value: string): string {
  if (!value) {
    return '';
  }

  return value.split('_').join(' ');
}

export type IsSameFn<T> = (currentItem: T, newItem: T) => boolean;

export function listToSentence(
  items: string[],
  lastItemJoiner: string = 'and'
): string {
  if (items.length === 0) {
    return '';
  }
  if (items.length === 1) {
    return `${items[0]}`;
  }
  const lastItem = items[items.length - 1];
  const firstItems = items.slice(0, -1);
  return `${firstItems
    .map((item) => `${item}`)
    .join(', ')} ${lastItemJoiner} ${lastItem}`;
}

export interface IKeyValue<T = unknown> {
  key: string;
  value: T;
}

export function getNestedProperties<T extends object>(
  obj: T,
  rootKey: string = ''
): IKeyValue[] {
  return toPairs(obj).reduce((acc: IKeyValue[], [key, value]) => {
    const propertyKey = compact([rootKey, key]).join('.');
    if (isObject(value)) {
      const nestedProperties = getNestedProperties(value, propertyKey);
      return [...acc, ...nestedProperties];
    }
    const propertyDef = { key: propertyKey, value: value as unknown };
    return [...acc, propertyDef];
  }, []);
}

export class Deferred<T = unknown> extends Promise<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
  resolve: (value: T | PromiseLike<T>) => void;

  constructor() {
    let internalResolve = noop;
    let internalReject = noop;
    super((resolve, reject) => {
      internalResolve = resolve;
      internalReject = reject;
    });
    this.resolve = internalResolve;
    this.reject = internalReject;
  }
}

type SortFn<T> = (a: T, b: T) => number;

export function sortByArrayIndex<Item, SortItem>(
  sortArray: SortItem[],
  getKey: (item: Item) => SortItem
): SortFn<Item> {
  return (a: Item, b: Item) => {
    const aIndex = sortArray.indexOf(getKey(a));
    const bIndex = sortArray.indexOf(getKey(b));
    if (aIndex === bIndex) {
      return 0;
    }
    return aIndex > bIndex ? 1 : -1;
  };
}
