import { initVersionedSchema, MixedSchema } from '@principle-theorem/editor';
import {
  all$,
  DocumentArchive,
  firstResult$,
  getDocs,
  isDocRef,
  isObject,
  ISODateType,
  isSameRef,
  multiFilter,
  orderBy,
  patchDoc,
  query$,
  snapshot,
  sortByCreatedAt,
  subCollection,
  undeletedQuery,
  where,
  type ArchivedDocument,
  type AtLeast,
  type CollectionReference,
  type DocumentReference,
  type IAttachment,
  type IMediaRef,
  type IReffable,
  type ISoftDelete,
  type Reffable,
  type WithRef,
} from '@principle-theorem/shared';
import { intersection, omit } from 'lodash';
import { of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootCollection } from '../../root-collection';
import { IFolder } from '../folder';
import {
  BundleReleaseCollection,
  isBundleSkill,
  type IBundleSkill,
} from '../marketplace/bundle';
import { type IVendor } from '../marketplace/vendor';
import { VendorBundleCollection } from '../marketplace/vendor-bundle';
import { type ITag } from '../tag/tag';
import { type IUserGroup } from '../user-group/user-group';
import { type IUser } from '../user/user';
import { SkillCollection } from './skill-collections';
import { reviewerHasApproved, type ISkillReview } from './skill-review';
import { type SkillType } from './skill-type';

export enum SkillStatus {
  Draft = 'draft',
  Review = 'review',
  Published = 'approved',
}

export const SKILL_STATUS_ORDER: SkillStatus[] = [
  SkillStatus.Draft,
  SkillStatus.Review,
  SkillStatus.Published,
];

export interface ISkill extends ISoftDelete {
  name: string;
  content: MixedSchema;
  author: DocumentReference<IUser | IVendor>;
  status: SkillStatus;
  readOnly: boolean;
  tags: DocumentReference<ITag>[];
  media: IMediaRef[];
  folderRef?: DocumentReference<IFolder>;
  reviewers: DocumentReference<IUser>[];
  restrictAccessTo: DocumentReference<IUserGroup>[];
  vendorSkillRef?: DocumentReference<IBundleSkill>;
  amendmentOf?: DocumentReference<ArchivedDocument<ISkill>>;
  requiresTrainerVerification?: boolean;
  dueDate?: ISODateType;
  estimatedReadingTime?: number;
  estimatedWatchingTime?: number;
}

export type IRoutableSkill = Pick<
  ISkill,
  'name' | 'readOnly' | 'author' | 'restrictAccessTo'
>;
export type IPublicSkill = Omit<ISkill, 'content' | 'media'>;

export function isSkill(item: unknown): item is ISkill {
  return (
    isObject(item) &&
    'name' in item &&
    'content' in item &&
    'author' in item &&
    isDocRef(item.author) &&
    'status' in item &&
    'tags' in item &&
    Array.isArray(item.tags) &&
    'media' in item &&
    Array.isArray(item.media) &&
    'reviewers' in item &&
    Array.isArray(item.reviewers)
  );
}

export enum SkillLevelRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
  Cancelled = 'cancelled',
}

export interface ISkillLevelRequest {
  userRef: DocumentReference<IUser>;
  status: SkillLevelRequestStatus;
  mentorRef?: DocumentReference<IUser>;
}

export type ResolveSkillFn = (
  skill: DocumentReference<ISkill>
) => Promise<WithRef<ISkill> | undefined>;

export async function findPendingRequest(
  skill: IReffable<ISkill>,
  userRef: DocumentReference<IUser>
): Promise<WithRef<ISkillLevelRequest> | undefined> {
  const requests = await snapshot(Skill.trainingRequests$(skill));
  return requests.find((request) => {
    const isPending = request.status === SkillLevelRequestStatus.Pending;
    const isUser = userRef.path === request.userRef.path;
    return isPending && isUser;
  });
}

export class Skill {
  static init(
    overrides: AtLeast<ISkill, 'name' | 'author' | 'folderRef'>
  ): ISkill {
    return {
      content: initVersionedSchema(),
      status: SkillStatus.Draft,
      readOnly: false,
      tags: [],
      media: [],
      reviewers: [],
      restrictAccessTo: [],
      deleted: false,
      ...overrides,
    };
  }

  static findPendingRequest$(
    skill: WithRef<ISkill>
  ): Observable<WithRef<ISkillLevelRequest>[]> {
    return Skill.trainingRequests$(skill).pipe(
      multiFilter(
        (request) => request.status === SkillLevelRequestStatus.Pending
      )
    );
  }

  static trainingRequestCol(
    skill: IReffable<ISkill>
  ): CollectionReference<ISkillLevelRequest> {
    return subCollection<ISkillLevelRequest>(
      skill.ref,
      SkillCollection.TrainingRequests
    );
  }

  static trainingRequests$(
    skill: IReffable<ISkill>
  ): Observable<WithRef<ISkillLevelRequest>[]> {
    return all$(Skill.trainingRequestCol(skill));
  }

  static async reviews(
    skill: Reffable<ISkill>
  ): Promise<WithRef<ISkillReview>[]> {
    return getDocs<ISkillReview>(Skill.reviewCol(skill));
  }

  static reviewCol(skill: Reffable<ISkill>): CollectionReference<ISkillReview> {
    return subCollection<ISkillReview>(skill.ref, SkillCollection.Reviews);
  }

  static reviews$(
    skill: Reffable<ISkill>
  ): Observable<WithRef<ISkillReview>[]> {
    return all$(Skill.reviewCol(skill));
  }

  static attachmentCol(
    skill: IReffable<SkillType>
  ): CollectionReference<IAttachment> {
    return subCollection<IAttachment>(skill.ref, SkillCollection.Attachments);
  }

  static attachments$(
    skill: WithRef<SkillType>
  ): Observable<WithRef<IAttachment>[]> {
    return all$(undeletedQuery(Skill.attachmentCol(skill)));
  }

  static archiveCol(
    form: WithRef<ISkill>
  ): CollectionReference<ArchivedDocument<ISkill>> {
    return subCollection<ArchivedDocument<ISkill>>(
      form.ref,
      SkillCollection.SkillHistory
    );
  }

  static archivedSkills$(
    skill: WithRef<ISkill>
  ): Observable<WithRef<ArchivedDocument<ISkill>>[]> {
    return query$(undeletedQuery(Skill.archiveCol(skill))).pipe(
      map((skills) => skills.sort(sortByCreatedAt))
    );
  }

  static async hasReviewed(
    skill: Reffable<ISkill>,
    user: Reffable<IUser>
  ): Promise<boolean> {
    const reviews = await Skill.reviews(skill);
    return reviews.some((review: ISkillReview) =>
      isSameRef(review.reviewer, user.ref)
    );
  }

  static async approvedByAllReviewers(
    skill: Reffable<ISkill>
  ): Promise<boolean> {
    const reviews = await Skill.reviews(skill);
    if (!skill.reviewers.length) {
      return true;
    }

    return skill.reviewers.every((reviewer: DocumentReference<IUser>) =>
      reviewerHasApproved(reviews, reviewer)
    );
  }

  static async pendingReviewers(
    skill: Reffable<ISkill>
  ): Promise<DocumentReference<IUser>[]> {
    const reviews = await Skill.reviews(skill);
    return skill.reviewers.filter(
      (reviewer: DocumentReference<IUser>) =>
        !reviewerHasApproved(reviews, reviewer)
    );
  }

  static isDraft(skill: ISkill): boolean {
    return skill.status === SkillStatus.Draft;
  }

  static inReview(skill: ISkill): boolean {
    return skill.status === SkillStatus.Review;
  }

  static isApproved(skill: ISkill): boolean {
    return skill.status === SkillStatus.Published;
  }

  static isAuthor(
    skill: Pick<ISkill, 'author'>,
    user: WithRef<IUser>
  ): boolean {
    return skill.author.path === user.ref.path;
  }

  static isReviewer(skill: ISkill, userRef: DocumentReference<IUser>): boolean {
    return skill.reviewers.some((reviewer: DocumentReference<IUser>) =>
      isSameRef(reviewer, userRef)
    );
  }

  static canReview(skill: ISkill, user: WithRef<IUser>): boolean {
    return (
      !Skill.isAuthor(skill, user) &&
      Skill.inReview(skill) &&
      Skill.isReviewer(skill, user.ref) &&
      !skill.readOnly
    );
  }

  static canEdit(
    skill: Pick<ISkill, 'readOnly' | 'author'>,
    user: WithRef<IUser>
  ): boolean {
    return (user.isAdmin || Skill.isAuthor(skill, user)) && !skill.readOnly;
  }

  static canView(
    skill: Pick<ISkill, 'author' | 'restrictAccessTo'>,
    user: WithRef<IUser>,
    groups: IReffable<IUserGroup>[]
  ): boolean {
    if (
      user.isAdmin ||
      Skill.isAuthor(skill, user) ||
      skill.restrictAccessTo.length === 0
    ) {
      return true;
    }

    return (
      intersection(
        groups.map((group) => group.ref.path),
        skill.restrictAccessTo.map((group) => group.path)
      ).length > 0
    );
  }

  static isMarketplaceRelease(skill: Reffable<ISkill>): boolean {
    return skill.vendorSkillRef &&
      skill.ref.path.startsWith(RootCollection.MarketplaceVendors) &&
      skill.ref.path.includes(VendorBundleCollection.Releases) &&
      skill.ref.path.includes(BundleReleaseCollection.Skills)
      ? true
      : false;
  }

  static resolveLocalSkill$(
    skill: WithRef<SkillType>,
    orgSkillCol: CollectionReference<ISkill>
  ): Observable<WithRef<ISkill> | undefined> {
    if (isBundleSkill(skill)) {
      return firstResult$(
        orgSkillCol,
        where('vendorSkillRef', '==', skill.ref),
        orderBy('updatedAt', 'desc')
      ).pipe(
        map((localSkill) => (localSkill?.deleted ? undefined : localSkill))
      );
    }

    if (Skill.isMarketplaceRelease(skill)) {
      return firstResult$(
        orgSkillCol,
        where(
          'vendorSkillRef',
          '==',
          (skill as WithRef<ISkill>).vendorSkillRef
        ),
        orderBy('updatedAt', 'desc')
      ).pipe(
        map((localSkill) => (localSkill?.deleted ? undefined : localSkill))
      );
    }

    return of(skill as WithRef<ISkill>);
  }

  static async archive(skill: WithRef<ISkill>): Promise<void> {
    const historyRef = await DocumentArchive.snapshotToArchive(
      omit(skill, ['createdAt', 'updatedAt']),
      Skill.archiveCol(skill)
    );

    await patchDoc(skill.ref, {
      amendmentOf: historyRef,
    });
  }
}

export interface ISkillStatusGroup {
  name: string;
  skills: WithRef<ISkill>[];
}

export function groupSkillsByStatus(
  skills: WithRef<ISkill>[]
): ISkillStatusGroup[] {
  return [SkillStatus.Draft, SkillStatus.Review, SkillStatus.Published].map(
    (status: SkillStatus): ISkillStatusGroup => ({
      name: status,
      skills: skills.filter(
        (skill: WithRef<ISkill>) => skill.status === status
      ),
    })
  );
}

export interface ISkillContent {
  content: MixedSchema;
  attachments: IAttachment[];
}
