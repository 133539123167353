import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { asyncForEach, snapshot } from '@principle-theorem/shared';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { IMediaPath, getDownloadURL$ } from './media-manager';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  constructor(
    private _httpClient: HttpClient,
    private _storage: Storage
  ) {}

  async getImageUrlFromSrc(src: string): Promise<string | undefined> {
    return getDownloadURL(ref(this._storage, src));
  }

  async downloadFile(media: IMediaPath): Promise<void> {
    const file = await this.getFileBlob(media);
    saveAs(file, decodeURI(media.name));
  }

  async downloadFiles(zipName: string, media: IMediaPath[]): Promise<void> {
    if (!media.length) {
      return;
    }

    if (media.length === 1) {
      await this.downloadFile(media[0]);
      return;
    }
    const zip = new JSZip();

    await asyncForEach(media, async (mediaItem) => {
      const file = await this.getFileBlob(mediaItem);
      zip.file(decodeURI(mediaItem.name), file);
    });

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, zipName + '.zip');
  }

  async getFileBlob(media: IMediaPath): Promise<Blob> {
    const url = await snapshot(getDownloadURL$(this._storage, media));
    const file = await this._httpClient
      .get(url, {
        responseType: 'blob',
      })
      .toPromise();
    return new Blob([file], { type: file.type });
  }
}
