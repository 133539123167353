<ng-container *ngIf="user$ | async as user">
  <mat-list-item>
    <div matListItemTitle fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button (click)="userSkillList.toggle()">
        <mat-icon>
          {{ userSkillList.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>

      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        {{ label }}
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span *ngIf="skills$ | async as skills"
          >{{ skills.length }} skills</span
        >
      </div>
    </div>
  </mat-list-item>
  <pt-collapsible
    #userSkillList
    [expanded]="expanded$ | async"
    (opened)="setExpand()"
    (closed)="setCollapse()"
  >
    <ng-template ptCollapsibleContent>
      <mat-list>
        <ng-container
          *ngFor="let skill of skills$ | async; trackBy: trackBySkill"
        >
          <lu-skill-list-item
            [showAuthor]="false"
            [skill]="skill"
            [user]="user"
            [canEdit]="true"
           />
        </ng-container>
      </mat-list>
    </ng-template>
  </pt-collapsible>
</ng-container>
