<ng-container *ngIf="pathway$ | async as pathway">
  <lu-page-simple class="h-full">
    <lu-page-header label="Edit Pathway - {{ pathwayName$ | async }}">
      <lu-page-toolbar>
        <button
          *ngIf="pathway | map : canPublish"
          mat-flat-button
          color="primary"
          (click)="publish(pathway)"
        >
          Publish Pathway
        </button>
        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
          <mat-icon class="font-bold">more_vert</mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu">
          <button
            *ngIf="pathway | map : isPublished"
            mat-menu-item
            (click)="revertToDraft(pathway)"
          >
            Revert to Draft
          </button>
          <button
            mat-menu-item
            class="warn"
            [disabled]="pathway.readOnly"
            (click)="delete(pathway)"
          >
            <mat-icon color="warn">remove_circle_outline</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </lu-page-toolbar>
    </lu-page-header>

    <!-- Desktop -->
    <mat-drawer-container
      *ngIf="sidebar.isDesktop$ | async; else mobileLayout"
      fxFill
    >
      <mat-drawer mode="side" position="end" opened disableClose>
        <div class="mb-20 w-96 max-w-sm">
          <ng-container
            [ngTemplateOutlet]="pathwayEditForm"
            [ngTemplateOutletContext]="{ pathway }"
          />
        </div>
      </mat-drawer>
      <mat-drawer-content class="max-h-screen flex-1 grow bg-white">
        <ng-container
          [ngTemplateOutlet]="sectionsEdit"
          [ngTemplateOutletContext]="{ pathway }"
        />
      </mat-drawer-content>
    </mat-drawer-container>

    <!-- Mobile -->
    <ng-template #mobileLayout>
      <div class="flex h-full grow flex-col gap-4">
        <ng-container
          [ngTemplateOutlet]="pathwayEditForm"
          [ngTemplateOutletContext]="{ pathway }"
        />
        <ng-container
          [ngTemplateOutlet]="sectionsEdit"
          [ngTemplateOutletContext]="{ pathway }"
        />
      </div>
    </ng-template>
  </lu-page-simple>
</ng-container>

<ng-template #sectionsEdit let-pathway="pathway">
  <div class="mb-20 flex h-full flex-1 grow flex-col overflow-auto p-4">
    <div>
      <div>
        <h2 class="mat-headline-6">Sections</h2>
        <p>
          Use sections to break down the training pathway into manageable
          chunks. If you don't need any sections, you can leave the name blank.
        </p>
      </div>

      <div
        *ngIf="sections$ | async as sections"
        cdkDropList
        (cdkDropListDropped)="dropSection($event, pathway)"
        ptIntercomTarget="pathway.sections"
      >
        <div
          *ngIf="sections.length; else noSections"
          class="flex flex-col gap-4"
          cdkDropListGroup
        >
          <div
            class="section rounded-lg border border-solid border-gray-200 bg-white p-2"
            *ngFor="let section of sections; trackBy: trackBySection"
            cdkDrag
            cdkDragLockAxis="y"
          >
            <lu-section-edit
              [section]="section"
              [pathway]="pathway"
              (update)="updateSection($event, pathway)"
            />
          </div>
        </div>
      </div>

      <ng-template #noSections>
        <div class="p-2 text-center">
          <div>No Sections</div>
        </div>
      </ng-template>

      <div class="my-4">
        <button mat-stroked-button class="w-full" (click)="addSection(pathway)">
          <mat-icon>add</mat-icon>
          <span>Add New Section</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pathwayEditForm let-pathway="pathway">
  <form [formGroup]="form" class="flex flex-col">
    <div class="flex flex-col">
      <div class="content flex grow flex-col lg:pl-4">
        <div class="my-2 flex flex-col items-center">
          <ng-container *ngIf="image$ | async as image; else addImage">
            <div class="group/item relative">
              <pt-image-preview [src]="image" class="relative self-stretch" />
              <div
                class="remove-image group/image-upload invisible absolute left-0 top-0 flex h-full w-full items-center justify-center group-hover/item:visible"
                matTooltip="Remove Image"
                (click)="updateImageUrl()"
              >
                <button mat-icon-button>
                  <mat-icon>do_not_disturb_on</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>

          <ng-template #addImage>
            <pt-image-upload
              class="mb-1 flex self-stretch"
              label="Upload Cover Image"
              [uploadPath]="uploadPath$ | async"
              (imageUpload)="updateImageUrl($event)"
            />
          </ng-template>
        </div>

        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <lu-folder-selector
          placeholder="Select a Folder"
          [selectedRef]="form.controls.folderRef.value"
          (add)="form.controls.folderRef.setValue($event)"
        />

        <mat-form-field>
          <mat-label>Creator</mat-label>
          <mat-select formControlName="author" [compareWith]="compareWithFn">
            <mat-option
              *ngFor="let author of authors$ | async; trackBy: trackByAuthor"
              [value]="author.ref"
            >
              {{ author.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="relative" ptIntercomTarget="pathway-edit.tag-input">
          <ng-container
            [ngTemplateOutlet]="inputLabel"
            [ngTemplateOutletContext]="{
              label: 'Tags',
              focus: tagAutocomplete.isFocused
            }"
          />
          <lu-tag-autocomplete
            #tagAutocomplete
            [tags]="tags$ | async"
            (tagsChange)="updateTags($event, pathway)"
          />
        </div>

        <div class="relative" ptIntercomTarget="pathway-edit.description">
          <ng-container
            [ngTemplateOutlet]="inputLabel"
            [ngTemplateOutletContext]="{
              label: 'Description',
              focus: false
            }"
          />

          <lu-content-editor
            class="pb-4"
            fxFlex
            #editor
            [storagePath]="org.storagePath$ | async"
            [control]="form.controls.description"
          />
        </div>

        <div ptIntercomTarget="pathway-edit.group-association" class="pb-4">
          <lu-pathway-group-association [pathway]="pathway" />
        </div>

        <ng-container *luIsAdmin>
          <div ptIntercomTarget="pathway-edit.permission-selector">
            <lu-permission-selector [item]="pathway" />
          </div>
        </ng-container>
      </div>

      <pt-page-footer class="!fixed bottom-0 right-0 w-full sm:absolute">
        <pt-buttons-container>
          <button
            mat-flat-button
            color="primary"
            [disabled]="(canSave$ | async) === false"
            (click)="save(pathway)"
          >
            Save
          </button>
        </pt-buttons-container>
      </pt-page-footer>
    </div>
  </form>
</ng-template>

<ng-template #inputLabel let-label="label" let-focus="focus">
  <span
    class="absolute -top-2.5 left-3.5 z-10 bg-white px-1 text-[11px] text-gray-500"
    [ngClass]="{ 'text-violet-500': focus }"
  >
    {{ label }}
  </span>
</ng-template>
