import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Editor } from '@tiptap/core';
import { TableCommandsService } from '../table-commands.service';

export interface ITableEditMenuData {
  editor: Editor;
}

@Component({
    selector: 'pt-table-edit-menu',
    templateUrl: './table-edit-menu.component.html',
    styleUrls: ['./table-edit-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TableEditMenuComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: ITableEditMenuData,
    private _dialogRef: DialogRef<void, TableEditMenuComponent>,
    public tableCommands: TableCommandsService
  ) {}

  close(): void {
    this._dialogRef.close();
  }
}
