export enum RootCollection {
  Organisations = 'organisations',
  ManagementUsers = 'managementUsers',
  MarketplaceVendors = 'marketplaceVendors',
  Config = 'config',
  Achievements = 'achievements',
}

export enum ConfigCollection {
  Public = 'public',
}

export interface IPublicConfig {
  version: string;
}
