import {
  ISODateType,
  WithRef,
  doc$,
  getDoc,
  getEnumValues,
  isSameRef,
  multiFilter,
  multiMap,
  multiSwitchMap,
  safeCombineLatest,
  toISODate,
  toMoment,
  type DocumentReference,
} from '@principle-theorem/shared';
import { compact, differenceWith, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, OperatorFunction, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type IVendor } from '../marketplace/vendor';
import {
  ResolveSkillFn,
  Skill,
  SkillStatus,
  type IPublicSkill,
  type ISkill,
} from '../skill/skill';
import { SkillLevel } from '../skill/skill-level';
import {
  currentSkillLevelValue,
  findProgressBySkill,
  getGoalProgress,
  skillGoalAchieved,
  skillHasGoal,
  skillWithoutGoal,
  type ISkillProgress,
} from '../skill/skill-progress';
import { WARNING_DAYS_UNTIL_DUE, type IGoals } from '../user-group/user-group';
import { type IUser, type IUserSkillLevelRequest } from '../user/user';
import {
  type IPathway,
  type IPublicPathway,
  Pathway,
  IFilterPathwayByStatus,
  PathwayStatus,
} from './pathway';

export function filterPathwaysByGoals(
  goals$: Observable<IGoals>
): OperatorFunction<WithRef<IPathway>[], WithRef<IPathway>[]> {
  return (pathways$: Observable<WithRef<IPathway>[]>) =>
    combineLatest([pathways$, goals$]).pipe(
      map(([pathways, goals]) => {
        const pathwayGoals = goals.pathwayAssociations.map(
          (assoc) => assoc.ref.path
        );

        return pathways.filter((pathway) =>
          compact([pathway.vendorPathwayRef?.path, pathway.ref.path]).some(
            (ref) => pathwayGoals.includes(ref)
          )
        );
      })
    );
}

export function filterSkillsByGoals(
  goals$: Observable<IGoals>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$: Observable<WithRef<ISkill>[]>) =>
    combineLatest([skills$, goals$]).pipe(
      map(([skills, goals]: [WithRef<ISkill>[], IGoals]) => {
        const skillGoals: string[] = goals.levelRequirements
          .filter(
            (levelRequirement) => levelRequirement.level !== SkillLevel.None
          )
          .map((levelRequirement) => levelRequirement.skill.ref.path);

        return skills.filter((skill) => skillGoals.includes(skill.ref.path));
      })
    );
}

export function filterSkillsWithDueDate(
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$: Observable<WithRef<ISkill>[]>) =>
    combineLatest([
      skills$.pipe(filterIncompleteSkills(goals$, progress$)),
      goals$,
    ]).pipe(
      map(([skills, goals]) => {
        const skillGoals = goals.levelRequirements.filter(
          (levelRequirement) => {
            if (!levelRequirement.dueDate) {
              return false;
            }

            const now = moment();
            const due = toMoment(levelRequirement.dueDate);
            return due.diff(now, 'days') <= WARNING_DAYS_UNTIL_DUE;
          }
        );

        return compact(
          sortBy(skillGoals, (skillGoal) => skillGoal.dueDate)
            .map((levelRequirement) => levelRequirement.skill.ref.path)
            .map((levelRequirementSkillRef) =>
              skills.find((skill) =>
                levelRequirementSkillRef.includes(skill.ref.path)
              )
            )
        );
      })
    );
}

export function sortSkillsByDueDate(
  goals$: Observable<IGoals>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$: Observable<WithRef<ISkill>[]>) =>
    combineLatest([skills$, goals$]).pipe(
      map(([skills, goals]) =>
        compact(
          sortBy(goals.levelRequirements, (skillGoal) => skillGoal.dueDate)
            .map((levelRequirement) => levelRequirement.skill.ref.path)
            .map((levelRequirementSkillRef) =>
              skills.find((skill) =>
                levelRequirementSkillRef.includes(skill.ref.path)
              )
            )
        )
      )
    );
}

export function getSkillGoalDueDate$(
  skill: WithRef<ISkill>,
  goals$: Observable<IGoals>
): Observable<ISODateType | undefined> {
  return goals$.pipe(
    map((goals) =>
      goals.levelRequirements.find((goal) => isSameRef(goal.skill, skill))
    ),
    map((skillGoal) => skillGoal?.dueDate)
  );
}

export function sortSkillsByGoalAcheivedDate(
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$: Observable<WithRef<ISkill>[]>) =>
    combineLatest([skills$, progress$]).pipe(
      map(([skills, progress]) =>
        compact(
          sortBy(progress, (skillProgress) =>
            toISODate(skillProgress.updatedAt)
          )
            .map((skillProgress) => skillProgress.skillRef.ref.path)
            .map((skillProgressSkillRef) =>
              skills.find((skill) =>
                skillProgressSkillRef.includes(skill.ref.path)
              )
            )
        )
      )
    );
}

export function filterIncompleteSkills(
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$) =>
    combineLatest([
      skills$.pipe(filterSkillsByGoals(goals$)),
      goals$,
      progress$,
    ]).pipe(
      map(([skills, goals, progress]) =>
        skills.filter(
          (skill) =>
            !skillGoalAchieved(goals, findProgressBySkill(skill, progress))
        )
      )
    );
}

export function filterCompletedSkills(
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$) =>
    combineLatest([
      skills$.pipe(filterSkillsByGoals(goals$)),
      goals$,
      progress$,
    ]).pipe(
      map(([skills, goals, progress]) =>
        skills.filter((skill) =>
          skillGoalAchieved(goals, findProgressBySkill(skill, progress))
        )
      )
    );
}

export function filterProactivelyLearntSkills(
  trainingRequests$: Observable<IUserSkillLevelRequest[]>,
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$) =>
    combineLatest([
      skills$,
      skills$.pipe(filterSkillsByGoals(goals$)),
      trainingRequests$,
      goals$,
      progress$,
    ]).pipe(
      map(([allSkills, skillsWithGoals, trainingRequests, goals, progress]) => {
        const skills = differenceWith(allSkills, skillsWithGoals, isSameRef);
        return skills.filter((skill) => {
          const hasRequest = trainingRequests.some((request) =>
            isSameRef(request.skillRef, skill)
          );

          const skillProgress = findProgressBySkill(skill, progress);

          if (!skillWithoutGoal(goals, skill)) {
            return false;
          }

          if (hasRequest) {
            return true;
          }

          return (
            skillProgress &&
            currentSkillLevelValue(skillProgress) !== SkillLevel.None
          );
        });
      })
    );
}

export function filterNotStartedSkills(
  trainingRequests$: Observable<IUserSkillLevelRequest[]>,
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$) =>
    combineLatest([
      skills$.pipe(filterSkillsByGoals(goals$)),
      trainingRequests$,
      progress$,
      goals$,
    ]).pipe(
      map(([skills, trainingRequests, progress, goals]) =>
        skills.filter((skill) => {
          const hasRequest = trainingRequests.some((request) =>
            isSameRef(request.skillRef, skill)
          );

          if (hasRequest) {
            return false;
          }

          const skillProgress = findProgressBySkill(skill, progress);
          if (!skillProgress) {
            return true;
          }

          const goalProgress = getGoalProgress(goals, skillProgress);

          if (
            (goalProgress.starsGoal >= 2 && goalProgress.starsEarned < 2) ||
            (goalProgress.starsGoal >= 3 && goalProgress.starsEarned < 3)
          ) {
            return true;
          }

          if (goalProgress.levelEarned !== SkillLevel.None) {
            return false;
          }

          return true;
        })
      )
    );
}

export function filterInProgressSkills(
  trainingRequests$: Observable<IUserSkillLevelRequest[]>,
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return (skills$) =>
    combineLatest([
      skills$.pipe(
        filterNotStartedSkills(trainingRequests$, goals$, progress$)
      ),
      skills$.pipe(filterCompletedSkills(goals$, progress$)),
      skills$.pipe(filterSkillsByGoals(goals$)),
    ]).pipe(
      map(([notStarted, completed, skills]) => {
        const omittedSkills = [...notStarted, ...completed].map(
          (skill) => skill.ref.path
        );
        return skills.filter(
          (skill) => !omittedSkills.includes(skill.ref.path)
        );
      })
    );
}

export function filterCompletedPathways(
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<IPathway>[], WithRef<IPathway>[]> {
  return (pathways$) =>
    combineLatest([
      pathways$.pipe(
        multiSwitchMap((pathway) =>
          resolvePathwayWithSkills(pathway, [SkillStatus.Published])
        )
      ),
      goals$,
      progress$,
    ]).pipe(
      map(([pathways, goals, progress]) =>
        pathways
          .filter((pathway) => pathwayComplete(pathway, goals, progress))
          .map((pathway) => pathway.pathway)
      )
    );
}

export function filterProactivelyLearntPathways(
  trainingRequests$: Observable<IUserSkillLevelRequest[]>,
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<IPathway>[], WithRef<IPathway>[]> {
  return (pathways$) =>
    pathways$.pipe(
      multiSwitchMap((pathway) =>
        resolvePathwayWithSkills(pathway, [SkillStatus.Published])
      ),
      switchMap((pathways) =>
        safeCombineLatest(
          pathways.map((pathway) =>
            of(pathway.skills).pipe(
              filterProactivelyLearntSkills(
                trainingRequests$,
                goals$,
                progress$
              ),
              map((filteredSkills) =>
                pathway.skills.length > 0 &&
                filteredSkills.length === pathway.skills.length
                  ? pathway
                  : undefined
              )
            )
          )
        )
      ),
      map(compact),
      multiMap((pathway) => pathway.pathway)
    );
}

export function filterPathwaysBySkill$(
  skill: WithRef<ISkill>,
  pathways: WithRef<IPathway>[]
): Observable<WithRef<IPathway>[]> {
  return safeCombineLatest(
    pathways.map((pathway) => resolvePathwayWithSkills(pathway))
  ).pipe(
    map((pathwayPairs) =>
      pathwayPairs
        .filter((pathwayPair) =>
          pathwayPair.skills.some((pathwaySkill) =>
            isSameRef(pathwaySkill, skill)
          )
        )
        .map((pathwayPair) => pathwayPair.pathway)
    )
  );
}

export function filterNotStartedPathways(
  trainingRequests$: Observable<IUserSkillLevelRequest[]>,
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<IPathway>[], WithRef<IPathway>[]> {
  return (pathways$) =>
    combineLatest([
      pathways$.pipe(
        multiSwitchMap((pathway) => resolvePathwayWithSkills(pathway))
      ),
      goals$,
    ]).pipe(
      switchMap(([pathways, goals]) =>
        safeCombineLatest(
          pathways.map((pathway) => {
            const skillsWithGoals = pathway.skills.filter((skill) =>
              skillHasGoal(goals, skill)
            );
            return of(skillsWithGoals).pipe(
              filterNotStartedSkills(trainingRequests$, goals$, progress$),
              map((filteredSkills) =>
                skillsWithGoals.length > 0 &&
                filteredSkills.length === skillsWithGoals.length
                  ? pathway
                  : undefined
              )
            );
          })
        )
      ),
      map(compact),
      multiMap((pathway) => pathway.pathway)
    );
}

export function filterInProgressPathways(
  trainingRequests$: Observable<IUserSkillLevelRequest[]>,
  goals$: Observable<IGoals>,
  progress$: Observable<WithRef<ISkillProgress>[]>
): OperatorFunction<WithRef<IPathway>[], WithRef<IPathway>[]> {
  return (pathways$) =>
    combineLatest([
      pathways$.pipe(
        filterNotStartedPathways(trainingRequests$, goals$, progress$)
      ),
      pathways$.pipe(filterCompletedPathways(goals$, progress$)),
      pathways$.pipe(
        multiSwitchMap((pathway) =>
          resolvePathwayWithSkills(pathway, [SkillStatus.Published])
        ),
        multiFilter((pathway) => pathway.skills.length > 0)
      ),
    ]).pipe(
      map(([notStarted, completed, pathways]) => {
        const omittedPathways = [...notStarted, ...completed].map(
          (pathway) => pathway.ref.path
        );
        return pathways
          .filter(
            (pathway) => !omittedPathways.includes(pathway.pathway.ref.path)
          )
          .map((pathway) => pathway.pathway);
      })
    );
}

export function filterNotAssignedPathways(
  goals$: Observable<IGoals>
): OperatorFunction<WithRef<IPathway>[], WithRef<IPathway>[]> {
  return (pathways$) =>
    combineLatest([pathways$, goals$]).pipe(
      map(([pathways, goals]) =>
        pathways.filter(
          (pathway) =>
            !goals.pathwayAssociations.find((pathwayAssociation) =>
              isSameRef(pathwayAssociation, pathway)
            )
        )
      )
    );
}

export function filterPathwaysByStatus(): OperatorFunction<
  IFilterPathwayByStatus,
  WithRef<IPathway>[]
> {
  return map(({ statuses, pathways }) =>
    pathways.filter((pathway) => statuses.includes(pathway.status))
  );
}

export function pathwayStatusByUserStatus(): OperatorFunction<
  [WithRef<IUser> | undefined, WithRef<IPathway>[]],
  IFilterPathwayByStatus
> {
  return map(([user, pathways]) => ({
    statuses: user?.isAdmin
      ? [PathwayStatus.Published, PathwayStatus.Draft]
      : [PathwayStatus.Published],
    pathways,
  }));
}

export function filterSkillsByStatus(
  status: SkillStatus
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return map((skills: WithRef<ISkill>[]) =>
    skills.filter((skill) => skill.status === status)
  );
}

export function filterSkillsByStatuses(
  statuses: SkillStatus[] = getEnumValues(SkillStatus)
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return map((skills: WithRef<ISkill>[]) =>
    skills.filter((skill) =>
      statuses.length ? statuses.includes(skill.status) : true
    )
  );
}

export function filterSkillsWithAuthor(
  author$: Observable<DocumentReference<IUser | IVendor>>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return switchMap((skills: WithRef<ISkill>[]) =>
    author$.pipe(
      map((author) => skills.filter((skill) => isSameRef(skill.author, author)))
    )
  );
}

export function filterSkillsWithoutAuthor(
  author$: Observable<DocumentReference<IUser | IVendor>>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return switchMap((skills: WithRef<ISkill>[]) =>
    author$.pipe(
      map((author) =>
        skills.filter((skill) => !isSameRef(skill.author, author))
      )
    )
  );
}

export function filterSkillsWithReviewer(
  reviewer$: Observable<DocumentReference<IUser>>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return switchMap((skills: WithRef<ISkill>[]) =>
    reviewer$.pipe(
      map((reviewer) =>
        skills.filter((skill) => Skill.isReviewer(skill, reviewer))
      )
    )
  );
}

export function filterSkillsWithoutReviewer(
  reviewer$: Observable<DocumentReference<IUser>>
): OperatorFunction<WithRef<ISkill>[], WithRef<ISkill>[]> {
  return switchMap((skills: WithRef<ISkill>[]) =>
    reviewer$.pipe(
      map((reviewer) =>
        skills.filter((skill) => !Skill.isReviewer(skill, reviewer))
      )
    )
  );
}

export function resolvePathwaySkills(
  resolveSkillFn: ResolveSkillFn = getDoc
): OperatorFunction<WithRef<IPathway>, WithRef<ISkill>[]> {
  return switchMap((pathway) =>
    PathwayOperators.resolvePathwaySkills$(pathway, resolveSkillFn)
  );
}

export function resolvePublicPathwaySkills(): OperatorFunction<
  WithRef<IPublicPathway>,
  WithRef<IPublicSkill>[]
> {
  return switchMap((pathway: WithRef<IPublicPathway>) =>
    safeCombineLatest(pathway.skills.map((ref) => doc$<IPublicSkill>(ref)))
  );
}

export function resolvePathwayWithSkills(
  pathway: WithRef<IPathway>,
  statuses?: SkillStatus[],
  resolveSkillFn: ResolveSkillFn = getDoc
): Observable<IPathwaySkillPair> {
  return PathwayOperators.resolvePathwaySkills$(pathway, resolveSkillFn).pipe(
    filterSkillsByStatuses(statuses),
    map((skills) => ({
      pathway,
      skills,
    }))
  );
}

export interface IPathwaySkillPair {
  pathway: WithRef<IPathway>;
  skills: WithRef<ISkill>[];
}

export function pathwayComplete(
  pathwayPair: IPathwaySkillPair,
  goals: IGoals,
  progress: ISkillProgress[]
): boolean {
  return (
    pathwayPair.skills.length > 0 &&
    pathwayPair.skills.every(
      (skill) =>
        skillWithoutGoal(goals, skill) ||
        skillGoalAchieved(goals, findProgressBySkill(skill, progress))
    )
  );
}

export class PathwayOperators {
  static resolvePathwaySkills$(
    pathway: IPathway,
    resolveSkillFn: ResolveSkillFn = getDoc
  ): Observable<WithRef<ISkill>[]> {
    return safeCombineLatest(
      Pathway.skillRefs(pathway).map((ref) => resolveSkillFn(ref))
    ).pipe(map((docs) => compact(docs)));
  }
}
