import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IPathway,
  IRecentlyViewed,
  ISkill,
  IUser,
  MentionResourceType,
  isPathway,
  isSkill,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  WithRef,
  getDoc,
  multiConcatMap,
  multiFilter,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { LastViewedService } from '../../services/last-viewed.service';
import { map } from 'rxjs/operators';
import { HotkeysService } from '../../services/hotkeys.service';

@Component({
    selector: 'lu-recently-viewed-list',
    templateUrl: './recently-viewed-list.component.html',
    styleUrls: ['./recently-viewed-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecentlyViewedListComponent {
  trackByRecentlyViewed = TrackByFunctions.ref<WithRef<IRecentlyViewed>>();
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  recentlyViewed$: Observable<WithRef<ISkill | IPathway>[]>;
  @Input() limit = 0;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(
    public lastViewed: LastViewedService,
    public hotkeys: HotkeysService
  ) {
    this.recentlyViewed$ = this.lastViewed.recentlyViewed$.pipe(
      map((recentlyViewed) =>
        this.limit > 0 ? recentlyViewed.slice(0, this.limit) : recentlyViewed
      ),
      multiFilter(
        (recentlyViewed) =>
          recentlyViewed.type === MentionResourceType.Skill ||
          recentlyViewed.type === MentionResourceType.Pathway
      ),
      multiConcatMap(async (recentlyViewed) => {
        return (await getDoc(recentlyViewed.ref)) as unknown as
          | WithRef<ISkill>
          | WithRef<IPathway>;
      }),
      multiFilter((recentlyViewed) => !recentlyViewed.deleted)
    );
  }

  isSkill(recentlyViewed: WithRef<ISkill | IPathway>): boolean {
    return isSkill(recentlyViewed);
  }

  asSkill(recentlyViewed: WithRef<ISkill | IPathway>): WithRef<ISkill> {
    return recentlyViewed as WithRef<ISkill>;
  }

  isPathway(recentlyViewed: WithRef<ISkill | IPathway>): boolean {
    return isPathway(recentlyViewed);
  }

  asPathway(recentlyViewed: WithRef<ISkill | IPathway>): WithRef<IPathway> {
    return recentlyViewed as WithRef<IPathway>;
  }
}
