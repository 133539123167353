import { Pipe, type PipeTransform } from '@angular/core';
import { type ISkill, Skill } from '@principle-theorem/level-up-core';

@Pipe({
    name: 'isDraft',
    standalone: false
})
export class IsDraftPipe implements PipeTransform {
  transform(skill: ISkill): boolean {
    return Skill.isDraft(skill);
  }
}
