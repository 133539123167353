import { type WithRef } from '@principle-theorem/shared';
import { SkillLevel, skillLevelToNumber } from '../../skill/skill-level';
import {
  currentSkillLevelValue,
  type ISkillProgress,
  type ISkillProgressHistory,
  hasReachedLevel,
} from '../../skill/skill-progress';
import { type IUser } from '../user';
import { boolToNum, modifyUserStats, resolverBuilders } from './helpers';
import {
  type IUserStatisticsResult,
  type IUserStatValue,
  reduceStatValues,
  statValue,
} from './user-statistics-result';

type ISkillProgressStatBuilder = (
  progress: ISkillProgress,
  owner: WithRef<IUser>
) => IUserStatValue[];

export function skillProgressResults(
  owner: WithRef<IUser>,
  progress: ISkillProgress
): IUserStatisticsResult[] {
  return resolverBuilders(
    {
      totalStarsEarned,
      viewedStarsEarned,
      verifiedByTrainerStarsEarned,
      trainerStarsEarned,
      resetOwnSkillProgress,
      viewedStarsGiven,
      verifiedByTrainerStarsGiven,
      trainerStarsGiven,
      totalStarsGiven,
      resetOthersSkillProgress,
      totalSelfStarsGiven,
      totalOthersStarsGiven,
      totalStarsEarnedFromOthers,
    },
    (builder: ISkillProgressStatBuilder) => builder(progress, owner)
  );
}

function totalOthersStarsGiven(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  return modifyUserStats(
    totalStarsGiven(progress, owner),
    totalSelfStarsGiven(progress, owner),
    (totalGiven, selfGiven) => totalGiven - selfGiven
  );
}

function totalStarsEarned(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  const value = skillLevelToNumber(currentSkillLevelValue(progress));
  return [statValue(owner.ref, value)];
}

function totalStarsEarnedFromOthers(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  return modifyUserStats(
    totalStarsEarned(progress, owner),
    totalSelfStarsGiven(progress, owner),
    (total, selfGiven) => total - selfGiven
  );
}

function viewedStarsEarned(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  const value = boolToNum(hasReachedLevel(progress, SkillLevel.Viewed));
  return [statValue(owner.ref, value)];
}

function verifiedByTrainerStarsEarned(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  const value = boolToNum(
    hasReachedLevel(progress, SkillLevel.VerifiedByTrainer)
  );
  return [statValue(owner.ref, value)];
}

function trainerStarsEarned(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  const value = boolToNum(hasReachedLevel(progress, SkillLevel.Trainer));
  return [statValue(owner.ref, value)];
}

function resetOwnSkillProgress(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  const values = progress.history.map((history) =>
    statValue(
      history.accreditor,
      boolToNum(
        isSelfAction(history, owner) && history.level === SkillLevel.None
      )
    )
  );
  return reduceStatValues(values);
}

function viewedStarsGiven(
  progress: ISkillProgress,
  _: WithRef<IUser>
): IUserStatValue[] {
  return historyStats(progress, (history) =>
    boolToNum(history.level === SkillLevel.Viewed)
  );
}

function verifiedByTrainerStarsGiven(
  progress: ISkillProgress,
  _: WithRef<IUser>
): IUserStatValue[] {
  return historyStats(progress, (history) =>
    boolToNum(history.level === SkillLevel.VerifiedByTrainer)
  );
}

function trainerStarsGiven(
  progress: ISkillProgress,
  _: WithRef<IUser>
): IUserStatValue[] {
  return historyStats(progress, (history) =>
    boolToNum(history.level === SkillLevel.Trainer)
  );
}

/**
 * At the moment this doesn't take into account the previous value.
 * If you take take a star from someone and it will count as 1.
 * See skillProgressHistoryDiff
 */
function totalStarsGiven(
  progress: ISkillProgress,
  _: WithRef<IUser>
): IUserStatValue[] {
  return historyStats(progress, (history) =>
    boolToNum(skillLevelToNumber(history.level) >= 1)
  );
}

/**
 * At the moment this doesn't take into account the previous value.
 * If you take take a star from someone and it will count as 1.
 * See skillProgressHistoryDiff
 */
function totalSelfStarsGiven(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  return historyStats(progress, (history) => {
    const isSkillUp = skillLevelToNumber(history.level) >= 1;
    return boolToNum(isSkillUp && isSelfAction(history, owner));
  });
}

function resetOthersSkillProgress(
  progress: ISkillProgress,
  owner: WithRef<IUser>
): IUserStatValue[] {
  const values = progress.history.map((history) =>
    statValue(
      history.accreditor,
      boolToNum(
        !isSelfAction(history, owner) && history.level === SkillLevel.None
      )
    )
  );
  return reduceStatValues(values);
}

function historyStats(
  progress: ISkillProgress,
  calc: (history: ISkillProgressHistory) => number
): IUserStatValue[] {
  const values = progress.history.map((history) =>
    statValue(history.accreditor, calc(history))
  );
  return reduceStatValues(values);
}

function isSelfAction(
  history: ISkillProgressHistory,
  owner: WithRef<IUser>
): boolean {
  return owner.ref.path === history.accreditor.path;
}
