import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  isVendorWorkspace,
  type WorkspaceType,
} from '@principle-theorem/level-up-core';
import { type IWorkspaceSelector } from '@principle-theorem/ng-auth';
import {
  InputSearchFilter,
  TypedFormControl,
  toSearchInput$,
} from '@principle-theorem/ng-shared';
import { type IUserWorkspace } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-vendor-workspace-selector',
  templateUrl: './vendor-workspace-selector.component.html',
  styleUrls: ['./vendor-workspace-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorWorkspaceSelectorComponent implements IWorkspaceSelector {
  searchCtrl = new TypedFormControl<string>('');
  workspaceSearch: InputSearchFilter<IUserWorkspace<WorkspaceType.Vendor>>;
  workspaces$: ReplaySubject<IUserWorkspace<WorkspaceType.Vendor>[]> =
    new ReplaySubject(1);
  @Output()
  workspaceSelected = new EventEmitter<IUserWorkspace>();

  @Input()
  set workspaces(workspaces: IUserWorkspace[]) {
    if (workspaces) {
      this.workspaces$.next(workspaces.filter(isVendorWorkspace));
    }
  }

  constructor() {
    this.workspaceSearch = new InputSearchFilter<
      IUserWorkspace<WorkspaceType.Vendor>
    >(this.workspaces$, toSearchInput$(this.searchCtrl), ['workspace']);
  }
}
