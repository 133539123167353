import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type IVendor } from '../../models/marketplace/vendor';
import { type ISkill } from '../../models/skill/skill';
import { type ISkillReview } from '../../models/skill/skill-review';
import { type IUser } from '../../models/user/user';

export interface ISkillResources {
  author: INamedDocument<IUser | IVendor>;
  interactor?: INamedDocument<IUser>;
  skill: INamedDocument<ISkill>;
}

export interface ISkillReviewRequestResources extends ISkillResources {
  reviewer: INamedDocument<IUser>;
}

export interface ISkillReviewResources extends ISkillReviewRequestResources {
  review: ISkillReview;
}

export function isSkillResource(data: unknown): data is ISkillResources {
  return (
    isObject(data) &&
    isINamedDocument(data.author) &&
    isINamedDocument(data.skill)
  );
}

export function isSkillReviewResource(
  data: unknown
): data is ISkillReviewRequestResources {
  return (
    isObject(data) && isINamedDocument(data.reviewer) && isSkillResource(data)
  );
}
