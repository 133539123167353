import { Injectable } from '@angular/core';
import { getApp } from 'firebase/app';
import {
  FirebasePerformance,
  initializePerformance,
  PerformanceTrace,
  trace,
} from 'firebase/performance';

@Injectable({
  providedIn: 'root',
})
export class FirebasePerformanceService {
  private _perf?: FirebasePerformance;

  init(): void {
    this._perf = initializePerformance(getApp());
  }

  trace(traceName: string): PerformanceTrace {
    if (!this._perf) {
      throw new Error('The service must be initialised before using');
    }
    return trace(this._perf, traceName);
  }
}
