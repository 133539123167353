import { Component, Input } from '@angular/core';
import { type IEvaluatedLeaderboardEntry } from '../leaderboard';

@Component({
    selector: 'lu-leaderboard-entry',
    templateUrl: './leaderboard-entry.component.html',
    styleUrls: ['./leaderboard-entry.component.scss'],
    standalone: false
})
export class LeaderboardEntryComponent {
  @Input() entry: IEvaluatedLeaderboardEntry;
  @Input() rank?: number;
}
