import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { type INotification } from '@principle-theorem/notifications';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export interface INotificationDialogData {
  notifications$: Observable<WithRef<INotification>[]>;
}

@Component({
    selector: 'lu-notification-dialog',
    templateUrl: './notification-dialog.component.html',
    styleUrls: ['./notification-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: INotificationDialogData,
    public dialogRef: DialogRef<void, NotificationDialogComponent>
  ) {}
}
