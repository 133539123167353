<div
  fxFlex
  fxLayout.gt-sm="row"
  fxLayout="row wrap"
  fxLayoutAlign="space-between center"
  fxLayoutGap="16px"
>
  <ng-container *ngIf="sidebar.isMobile$ | async; else desktop">
    <div fxLayout="column" fxLayoutGap="8px" fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <button mat-icon-button matTooltip="Open Menu" (click)="sidebar.open()">
          <mat-icon>menu</mat-icon>
        </button>

        <ng-container *ngTemplateOutlet="levelIndicator" />
        <ng-container *ngTemplateOutlet="favouriteIndicator" />

        <mat-icon *ngIf="pageInfo" [matTooltip]="pageInfo">info</mat-icon>

        <pt-intercom-product-tour-trigger
          *ngIf="pageTour"
          [tourId]="pageTour"
        />

        <ng-content></ng-content>

        <span fxFlex></span>

        <button
          *ngIf="hasSidebarMain$ | async"
          mat-icon-button
          (click)="contentSidebar.sidebar.open()"
          matTooltip="Open Side Menu"
        >
          <mat-icon>right_panel_open</mat-icon>
        </button>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="8px"
      >
        <h2 class="mat-headline-6">{{ label }}</h2>
        <div>
          <ng-container *ngTemplateOutlet="pageToolbar" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #desktop>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <ng-container *ngTemplateOutlet="levelIndicator" />

      <h1 class="mat-headline-6">{{ label }}</h1>

      <ng-container *ngTemplateOutlet="favouriteIndicator" />
      <ng-container *ngTemplateOutlet="readOnlyIcon" />

      <mat-icon *ngIf="pageInfo" [matTooltip]="pageInfo">info</mat-icon>

      <pt-intercom-product-tour-trigger *ngIf="pageTour" [tourId]="pageTour" />

      <ng-content></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px" fxFlex>
      <ng-container *ngTemplateOutlet="pageToolbar" />
    </div>
  </ng-template>
</div>

<ng-template #pageToolbar>
  <ng-content select="lu-page-toolbar"></ng-content>
</ng-template>

<ng-template #levelIndicator>
  <ng-container ngProjectAs="lu-level-indicator">
    <ng-content select="lu-level-indicator"></ng-content>
  </ng-container>
</ng-template>

<ng-template #favouriteIndicator>
  <ng-container ngProjectAs="lu-favourite-indicator">
    <ng-content select="lu-favourite-indicator"></ng-content>
  </ng-container>
</ng-template>

<ng-template #readOnlyIcon>
  <ng-container ngProjectAs="lu-read-only-icon">
    <ng-content select="lu-read-only-icon"></ng-content>
  </ng-container>
</ng-template>
