import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'lu-content-type-icon',
    templateUrl: './content-type-icon.component.html',
    styleUrls: ['./content-type-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContentTypeIconComponent {
  icon: string = '';
  tooltip: string = '';

  @Input({ required: true })
  set contentType(
    contentType: 'folder' | 'pathway' | 'skill' | 'bundle' | 'user' | 'vendor'
  ) {
    switch (contentType) {
      case 'folder':
        this.icon = 'folder';
        this.tooltip = 'Folder';
        break;
      case 'pathway':
        this.icon = 'fact_check';
        this.tooltip = 'Pathway';
        break;
      case 'skill':
        this.icon = 'task';
        this.tooltip = 'Skill';
        break;
      case 'bundle':
        this.icon = 'library_books';
        this.tooltip = 'Bundle';
        break;
      case 'user':
        this.icon = 'person';
        this.tooltip = 'User';
        break;
      case 'vendor':
        this.icon = 'storefront';
        this.tooltip = 'Vendor';
        break;
      default:
        return;
    }
  }
}
