import { ChangeDetectionStrategy, Component } from '@angular/core';
import { User } from '@principle-theorem/level-up-core';
import { type INotification } from '@principle-theorem/notifications';
import {
  type WithRef,
  filterUndefined,
  query$,
  shareReplayCold,
  toTimestamp,
} from '@principle-theorem/shared';
import { where } from '@principle-theorem/shared';
import { orderBy } from 'lodash';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
  selector: 'lu-account-notifications',
  templateUrl: './account-notifications.component.html',
  styleUrls: ['./account-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountNotificationsComponent {
  notifications$: Observable<WithRef<INotification>[]>;

  constructor(organisation: OrganisationService) {
    this.notifications$ = organisation.user$.pipe(
      filterUndefined(),
      switchMap((user) =>
        query$(
          User.notificationCol(user),
          where('createdAt', '>=', toTimestamp(moment().subtract(1, 'year')))
        )
      ),
      map((notifications) =>
        orderBy(notifications, (notification) => notification.createdAt, 'desc')
      ),
      shareReplayCold()
    );
  }
}
