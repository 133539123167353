import {
  type IOrganisation,
  type ISkillRef,
  type ITag,
  type ITypesenseSkill,
  LevelUpTypesenseCollection,
  Organisation,
  SkillRefType,
  type SkillType,
} from '@principle-theorem/level-up-core';
import { TypesenseSearchService } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { ITypesenseConfig, Typesense } from '@principle-theorem/typesense';
import { compact } from 'lodash';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';

export class TypesenseSkillListBloc {
  results$: Observable<SearchResponse<ITypesenseSkill>>;

  constructor(
    private _typesense: TypesenseSearchService,
    config: ITypesenseConfig,
    organisation$: Observable<WithRef<IOrganisation>>,
    search$: Observable<string>,
    selectedTagFilters$: Observable<WithRef<ITag>[]>,
    filterSkills$: Observable<ISkillRef<SkillType>[]>,
    page$ = of(1)
  ) {
    const searchParams$: Observable<SearchParams> = combineLatest([
      search$,
      selectedTagFilters$.pipe(map(tagsToRefFilter)),
      filterSkills$.pipe(map(skillsToRefFilter)),
      page$,
    ]).pipe(
      map(([search, tagsFilter, skillsFilter, page]) => ({
        q: search,
        query_by: ['name', 'tagNames'].join(','),
        per_page: 50,
        page,
        filter_by: compact([skillsFilter, tagsFilter, 'deleted:false']).join(
          ' && '
        ),
      }))
    );

    this.results$ = organisation$.pipe(
      switchMap((organisation) =>
        this._typesense.query$<ITypesenseSkill>(
          Organisation.integrationCol(organisation),
          Typesense.getScopedCollectionName(
            [organisation.ref],
            LevelUpTypesenseCollection.Skills
          ),
          searchParams$,
          config
        )
      )
    );
  }
}

function skillsToRefFilter(skills: ISkillRef<SkillType>[]): string | undefined {
  const filtered = skills.filter(
    (skillRef) => skillRef.type === SkillRefType.Skill
  );
  return filtered.length
    ? `ref:[${skills.map((skill) => skill.ref.path).join(',')}]`
    : undefined;
}

function tagsToRefFilter(tags: WithRef<ITag>[]): string | undefined {
  return tags.length
    ? `tags:[${tags.map((tag) => tag.ref.path).join(',')}]`
    : undefined;
}
