<ng-container *ngIf="tags$ | async as tags">
  <mat-chip-listbox fxFlex *ngIf="tags.length" class="compact mat-typography">
    <ng-container *ngIf="responsive; else limitedDisplay">
      <ng-container *ngIf="limit$ | async as limit">
        <ng-container *ngFor="let tag of tags; index as i; trackBy: trackByTag">
          <mat-chip
            [ngClass]="{ compact: compact }"
            *ngIf="i < limit"
            [style.--mdc-chip-label-text-color]="tag | tagTextColour"
            [style.background-color]="tag.hexColour"
          >
            {{ tag.name }}
          </mat-chip>
        </ng-container>
        <mat-chip [ngClass]="{ compact: compact }" *ngIf="tags.length > limit">
          + {{ tags.length - limit }} more</mat-chip
        >
      </ng-container>
    </ng-container>
    <ng-template #limitedDisplay>
      <mat-chip
        *ngFor="let tag of tags; trackBy: trackByTag"
        [ngClass]="{ compact: compact }"
        [style.--mdc-chip-label-text-color]="tag | tagTextColour"
        [style.background-color]="tag.hexColour"
      >
        {{ tag.name }}
      </mat-chip>
    </ng-template>
  </mat-chip-listbox>
</ng-container>
