import {
  type BlockNodes,
  getSchemaText,
  initRawSchema,
  type ITextNodeSchema,
  type NodeSchema,
  type RawSchema,
  type RawSchemaNodes,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  type IEmailNotificationContext,
  type INotification,
  type INotificationContextRenderer,
} from '@principle-theorem/notifications';
import {
  getDoc,
  type INamedDocument,
  isSameRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { MentionResourceType, toMention } from '../../mention';
import {
  type EmailNotificationProvider,
  type INotificationUserPair,
  NotificationType,
} from '../notification';
import {
  isUserGroupChangeData,
  type IUserGroupChangeResources,
} from '../resources/group';

export class UserGroupChangeContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      IUserGroupChangeResources
    >
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.TrainingGoalsChanged);
  }

  isResource(data: object): data is IUserGroupChangeResources {
    return isUserGroupChangeData(data);
  }

  render(
    data: IUserGroupChangeResources,
    currentStaffer: INamedDocument
  ): RawSchema | undefined {
    const foundUser = data.addedUsers.find((addedUser) =>
      isSameRef(currentStaffer, addedUser)
    );
    if (foundUser) {
      const message = getUserAssignedToGroupMessage(data);
      if (!message) {
        return;
      }
      return initRawSchema([
        toParagraphContent([...message, toTextContent(` to learn`)]),
      ]);
    }

    const content = compact([
      getPathwayAssociationMessage(data),
      getSkillAssignmentMessage(data),
      getIncreasedSkillLevelMessage(data),
    ]);
    return content ? initRawSchema(content) : undefined;
  }
}

export class UserGroupChangeEmailContextRenderer
  implements EmailNotificationProvider
{
  canProvide(data: INotificationUserPair): boolean {
    return (
      data.notification.type === String(NotificationType.TrainingGoalsChanged)
    );
  }

  async execute(
    data: INotificationUserPair
  ): Promise<IEmailNotificationContext | undefined> {
    const resources = data.notification.resources;
    if (!isUserGroupChangeData(resources)) {
      throw new Error('Cannot render email context');
    }

    const foundUser = resources.addedUsers.find((addedUser) =>
      isSameRef(data.user, addedUser)
    );
    if (foundUser) {
      const groupMessages = getUserAssignedToGroupMessage(resources);
      if (!groupMessages) {
        throw new Error('Cannot render email context');
      }

      const user = await getDoc(foundUser.ref);
      const assignedGoalSummary = groupMessages
        .map((groupMessage) => getSchemaText(groupMessage))
        .join('\r\n');
      const message = `It's time to start learning!

      ${assignedGoalSummary}

      Have a read through and when you're ready, organise training with a Trainer.`;

      return {
        user: {
          name: user.name,
          email: user.email,
        },
        subject: `You have been assigned new Skills`,
        preheader: message,
        message,
        action: {
          label: 'Review New Goals',
          url: 'grow',
        },
        signOffMessage: 'Good luck!',
      };
    }

    const groupMessages = compact([
      getPathwayAssociationMessage(resources),
      getSkillAssignmentMessage(resources),
      getIncreasedSkillLevelMessage(resources),
    ]);

    if (!groupMessages.length) {
      throw new Error('Cannot render email context');
    }

    const assignedGoalSummary = groupMessages
      .map((groupMessage) => getSchemaText(groupMessage))
      .join('\r\n');
    const message = `It's time to start learning!

    ${assignedGoalSummary}

    Have a read through and when you're ready, organise training with a Trainer.`;

    return {
      user: {
        name: data.user.name,
        email: data.user.email,
      },
      subject: `You have been assigned new Skills`,
      preheader: message,
      message,
      action: {
        label: 'Review New Goals',
        url: 'grow',
      },
      signOffMessage: 'Good luck!',
    };
  }
}

function getUserAssignedToGroupMessage(
  data: IUserGroupChangeResources
): ITextNodeSchema[] | undefined {
  if (!data.groupPathwayAssociationCount && !data.groupSkillAssociationCount) {
    return;
  }

  const messages = [toTextContent(`You've been assigned:`)];
  if (data.groupPathwayAssociationCount > 0) {
    messages.push(
      toTextContent(`${data.groupPathwayAssociationCount} new pathways`)
    );
  }
  if (data.groupSkillAssociationCount > 0) {
    const skillMessage =
      messages.length > 1
        ? ` & ${data.groupSkillAssociationCount} new skills`
        : `${data.groupSkillAssociationCount} new skills`;
    messages.push(toTextContent(skillMessage));
  }

  return messages;
}

function getSkillAssignmentMessage(
  data: IUserGroupChangeResources
): NodeSchema<BlockNodes, RawSchemaNodes> | undefined {
  if (!data.addedSkills.length) {
    return;
  }

  if (data.addedSkills.length > 1) {
    return toParagraphContent([
      toTextContent(
        `You've been assigned ${data.addedSkills.length} new skills`
      ),
    ]);
  }

  return toParagraphContent([
    toTextContent(`You've been assigned a new skill: `),
    toMentionContent(toMention(data.addedSkills[0], MentionResourceType.Skill)),
  ]);
}

function getPathwayAssociationMessage(
  data: IUserGroupChangeResources
): NodeSchema<BlockNodes, RawSchemaNodes> | undefined {
  if (!data.addedPathways.length) {
    return;
  }

  if (data.addedPathways.length > 1) {
    return toParagraphContent([
      toTextContent(
        `You've been assigned ${data.addedPathways.length} new pathways`
      ),
    ]);
  }

  return toParagraphContent([
    toTextContent(`You've been assigned a new pathway: `),
    toMentionContent(
      toMention(data.addedPathways[0], MentionResourceType.Pathway)
    ),
  ]);
}

function getIncreasedSkillLevelMessage(
  data: IUserGroupChangeResources
): NodeSchema<BlockNodes, RawSchemaNodes> | undefined {
  if (!data.increasedSkillLevels.length) {
    return;
  }

  if (data.increasedSkillLevels.length > 1) {
    return toParagraphContent([
      toTextContent(
        `You have new goals for ${data.increasedSkillLevels.length} skills`
      ),
    ]);
  }

  return toParagraphContent([
    toTextContent(`You have a new goal for skill `),
    toMentionContent(
      toMention(data.increasedSkillLevels[0].skill, MentionResourceType.Skill)
    ),
  ]);
}
