import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { type IFeature } from '@principle-theorem/feature-flags';
import { isString } from 'lodash';
import { FeatureFlagsService } from './feature-flags.service';

@Directive({
    selector: '[ptFeatureEnabled]',
    standalone: false
})
export class FeatureEnabledDirective {
  private _thenTemplateRef: TemplateRef<unknown>;

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<unknown>,
    private _featureFlags: FeatureFlagsService
  ) {
    this._thenTemplateRef = templateRef;
    this._viewContainer.clear();
  }

  @Input('ptFeatureEnabled')
  set featureLabel(label: IFeature | string | undefined) {
    if (!label) {
      this._viewContainer.createEmbeddedView(this._thenTemplateRef);
      return;
    }
    if (
      !this._featureFlags.isFeatureEnabledByLabel(
        isString(label) ? label : label.label
      )
    ) {
      return;
    }
    this._viewContainer.createEmbeddedView(this._thenTemplateRef);
  }
}
