<mat-toolbar color="primary">Create Page Template</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="layout-margin" fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>

  <div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-stroked-button
      [disabled]="!canSave()"
      ptIntercomTarget="page-template-form.create-button"
    >
      Create
    </button>
  </div>
</form>
