import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  IntercomEvent,
  PageTemplate,
  type IPageTemplate,
} from '@principle-theorem/level-up-core';
import {
  BasicDialogService,
  DialogPresets,
  InputSearchFilter,
  ProfileImageService,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  addDoc,
  deleteDoc,
  snapshot,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { startWith } from 'rxjs/operators';
import {
  PageTemplateAddDialogComponent,
  type TemplateFormData,
} from '../../../../components/page-templates/page-template-add-dialog/page-template-add-dialog.component';
import { PRODUCT_TOUR_CONFIG } from '../../../../models/product-tour-config';
import { OrganisationService } from '../../../../services/organisation.service';
import { AuthorService } from '../../../skills/author/author.service';

@Component({
    selector: 'lu-page-templates',
    templateUrl: './page-templates.component.html',
    styleUrls: ['./page-templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageTemplatesComponent {
  profileImage = inject(ProfileImageService);
  trackByTemplate = TrackByFunctions.ref<WithRef<IPageTemplate>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IPageTemplate>>;
  tourId: number = PRODUCT_TOUR_CONFIG.pageTemplates;

  constructor(
    private _organisation: OrganisationService,
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _intercom: Intercom,
    private _author: AuthorService
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IPageTemplate>>(
      this._organisation.pageTemplates$,
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );
  }

  async addPageTemplate(): Promise<void> {
    const template = await this._dialog
      .open<PageTemplateAddDialogComponent, undefined, TemplateFormData>(
        PageTemplateAddDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();

    if (!template) {
      return;
    }

    const author = await snapshot(this._author.user$);
    const templateRef: DocumentReference<IPageTemplate> = await addDoc(
      await snapshot(this._organisation.pageTemplatesCol$),
      PageTemplate.init({
        ...template,
        author: author.ref,
      })
    );

    this._intercom.trackEvent(IntercomEvent.AddedTemplate, {
      name: template.name,
    });

    await this._router.navigate(['/admin', 'templates', templateRef.id]);
    this._snackBar.open('Page Template Added');
  }

  async remove(pageTemplate: WithRef<IPageTemplate>): Promise<void> {
    const confirm = await this._basicDialog.confirm({
      title: 'Remove Template',
      prompt: 'Are you sure you want to delete this template?',
      submitLabel: 'Yes',
      submitColor: 'warn',
      toolbarColor: 'primary',
    });

    if (!confirm) {
      return;
    }

    await deleteDoc(pageTemplate.ref);
    this._snackBar.open('Page Template Removed');
  }
}
