<div
  class="items-between my-2 flex grow flex-col items-center justify-center gap-4"
>
  <mat-nav-list>
    <a
      mat-list-item
      class="text-center"
      routerLinkActive="active"
      ptIntercomTarget="sidebar.home"
      matTooltip="Home"
      [routerLink]="['/home']"
    >
      <mat-icon>home</mat-icon>
    </a>
    <a
      href="javascript:void(0);"
      class="text-center"
      mat-list-item
      matTooltip="Search Everything"
      ptIntercomTarget="sidebar.search"
    >
      <lu-search-button />
    </a>
    @if (notificationsCount$ | async; as notificationsCount) {
      <div
        [matBadge]="notificationsCount >= 10 ? '10+' : notificationsCount"
        matBadgeColor="warn"
      >
        <a
          href="javascript:void(0);"
          class="text-center"
          mat-list-item
          matTooltip="Notifications"
          ptIntercomTarget="sidebar.notifications"
        >
          <button mat-icon-button (click)="openNotifications($event)">
            <mat-icon>notifications</mat-icon>
          </button>
        </a>
      </div>
    } @else {
      <a
        href="javascript:void(0);"
        class="text-center"
        mat-list-item
        ptIntercomTarget="sidebar.notifications"
      >
        <button mat-icon-button (click)="openNotifications($event)">
          <mat-icon>notifications</mat-icon>
        </button>
      </a>
    }
  </mat-nav-list>

  <span class="grow"></span>

  <div class="flex flex-col items-center justify-center">
    <mat-nav-list>
      @for (group of groups$ | async; track group.name) {
        @if (group.icon) {
          <a
            href="javascript:void(0);"
            mat-list-item
            class="text-center"
            routerLinkActive="active"
            ptIntercomTarget="sidebar.{{ group.name }}"
            [matTooltip]="group.name"
            [matMenuTriggerFor]="moveItemsMenu"
          >
            <mat-icon>{{ group.icon }}</mat-icon>
          </a>
          <mat-menu
            class="sidebar-menu"
            #moveItemsMenu="matMenu"
            xPosition="after"
          >
            <ng-template matMenuContent>
              @for (link of group.links; track link.name) {
                <a
                  mat-menu-item
                  class="text-center"
                  routerLinkActive="active"
                  ptIntercomTarget="sidebar.{{ link.name }}"
                  [routerLink]="link.path"
                  [matTooltip]="link.description"
                >
                  <mat-icon *ngIf="link.icon">{{ link.icon }}</mat-icon>
                  <span>{{ link.name }}</span>
                </a>
              }
            </ng-template>
          </mat-menu>
        } @else {
          <h2 class="mat-headline-6" *ngIf="group.name">{{ group.name }}</h2>
          @for (link of group.links; track link.name) {
            <a
              mat-list-item
              class="text-center"
              routerLinkActive="active"
              ptIntercomTarget="sidebar.{{ link.name }}"
              [routerLink]="link.path"
              [matTooltip]="link.description"
            >
              @if (link.icon) {
                <mat-icon>{{ link.icon }}</mat-icon>
              } @else {
                {{ link.name }}
              }

              <div *ngIf="link.action" matListItemMeta>
                <button
                  mat-icon-button
                  (click)="runAction($event, link.action)"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </a>
          }
        }
      }

      @if (orgUser$ | async; as user) {
        <a
          class="profile"
          href="javascript:void(0);"
          mat-list-item
          ptIntercomTarget="sidebar.profile-settings"
        >
          <lu-user-profile-menu [user]="user" />
        </a>
      }
    </mat-nav-list>
    <button
      mat-mini-fab
      class="!min-h-[40px]"
      id="intercom-launcher"
      aria-label="Customer Support Button"
      matTooltip="Customer Support"
      ptIntercomTarget="sidebar.support"
    >
      <mat-icon class="intercom-icon">question_answer</mat-icon>
    </button>
  </div>
</div>
