import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  NgModuleRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  EditorMenuItemComponent,
  MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { shareReplayCold } from '@principle-theorem/shared';
import { Editor } from '@tiptap/core';
import { set } from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

export interface IBubbleMenuData {
  menuItems: MenuButtonLoaderFn<EditorMenuItemComponent>[];
  editor: Editor;
}

@Component({
    selector: 'pt-bubble-menu',
    templateUrl: './bubble-menu.component.html',
    styleUrls: ['./bubble-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BubbleMenuComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _menuContainer$ = new ReplaySubject<ViewContainerRef>(1);
  buttons$: Observable<ComponentRef<EditorMenuItemComponent>[]>;

  @ViewChild('menuContainer', { static: false, read: ViewContainerRef })
  set menuContainer(menuContainer: ViewContainerRef) {
    if (menuContainer) {
      this._menuContainer$.next(menuContainer);
    }
  }

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _moduleRef: NgModuleRef<unknown>,
    private _cdr: ChangeDetectorRef,
    @Inject(DIALOG_DATA) private _data: IBubbleMenuData
  ) {
    this.buttons$ = this._menuContainer$.pipe(
      tap((menuContainer) => menuContainer.clear()),
      map((menuContainer) =>
        this._data.menuItems.map((menuItemFn) => {
          const menuItem = menuItemFn(this._data.editor);
          const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
              menuItem.component
            );
          const componentRef =
            menuContainer.createComponent<EditorMenuItemComponent>(
              componentFactory,
              undefined,
              undefined,
              undefined,
              this._moduleRef
            );

          Object.entries(menuItem.data || {}).map(([key, value]) => {
            set(componentRef.instance, key, value);
          });
          return componentRef;
        })
      ),
      shareReplayCold()
    );

    this.buttons$.pipe(takeUntil(this._onDestroy$)).subscribe((buttons) => {
      buttons.map((button) => {
        button.instance.editor = this._data.editor;
      });
      this._cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
