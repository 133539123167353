import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgAuthModule } from '@principle-theorem/ng-auth';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../components/components.module';
import { AdminModule } from '../admin/admin.module';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { OrganisationAddDialogComponent } from './organisation-add-dialog/organisation-add-dialog.component';
import { OrganisationEditComponent } from './organisation-edit/organisation-edit.component';
import { OrganisationsComponent } from './organisations/organisations.component';
import { ToolCardComponent } from './tools/tool-card/tool-card.component';
import { ToolsComponent } from './tools/tools.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    OrganisationsComponent,
    OrganisationAddDialogComponent,
    OrganisationEditComponent,
    ToolsComponent,
    ToolCardComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgSharedModule,
    NgMaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    AdminModule,
    NgAuthModule,
    MarketplaceModule,
  ],
})
export class ManagementModule {}
