<lu-page-simple marginBottom="16">
  <lu-page-header
    label="Users"
    pageInfo="Add and edit user details here"
    [pageTour]="tourId"
  >
    <lu-page-toolbar>
      <lu-user-invite
        #extraContent
        luPageToolbarAction
        [usersCollection]="organisation.usersCol$ | async"
      />
    </lu-page-toolbar>
  </lu-page-header>

  <lu-page-toolbar>
    <pt-search-field fxFlex>
      <input matInput placeholder="Filter" [formControl]="search" />
    </pt-search-field>
  </lu-page-toolbar>

  <lu-users-list [users]="searchFilter.results$ | async" />
</lu-page-simple>
