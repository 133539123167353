import { Component, EventEmitter, type OnDestroy, Output } from '@angular/core';
import {
  type MatListOption,
  type MatSelectionListChange,
} from '@angular/material/list';
import { type ITag } from '@principle-theorem/level-up-core';
import { uniqWith } from 'lodash';
import { BehaviorSubject, type Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CachedListsService } from '../../../services/cached-lists.service';
import { type WithRef } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'lu-tag-filter',
    templateUrl: './tag-filter.component.html',
    styleUrls: ['./tag-filter.component.scss'],
    standalone: false
})
export class TagFilterComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  filters$: Observable<WithRef<ITag>[]>;
  selectedFilters$: BehaviorSubject<WithRef<ITag>[]> = new BehaviorSubject<
    WithRef<ITag>[]
  >([]);
  @Output() selectionChange: EventEmitter<WithRef<ITag>[]> = new EventEmitter<
    WithRef<ITag>[]
  >();

  constructor(cachedLists: CachedListsService) {
    this.filters$ = cachedLists.tags$.pipe(
      map((tags: WithRef<ITag>[]) => {
        return uniqWith(tags, (tagA: WithRef<ITag>, tagB: WithRef<ITag>) => {
          return tagA.name === tagB.name;
        }).sort((tagA: WithRef<ITag>, tagB: WithRef<ITag>) => {
          return tagA.name.toLowerCase() > tagB.name.toLowerCase() ? 1 : -1;
        });
      })
    );

    this.selectedFilters$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((tags: WithRef<ITag>[]) => this.selectionChange.next(tags));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateSelected(event: MatSelectionListChange): void {
    this.selectedFilters$.next(
      event.source.selectedOptions.selected.map(
        (option: MatListOption) => option.value as WithRef<ITag>
      )
    );
  }

  isSelected(filter: WithRef<ITag>): boolean {
    return this.selectedFilters$.value
      .map((tag: WithRef<ITag>) => tag.ref.path)
      .includes(filter.ref.path);
  }
}
