<mat-toolbar color="primary">Associate Skills</mat-toolbar>

<div class="layout-margin" fxLayout="column">
  <mat-form-field>
    <mat-label>Skill</mat-label>
    <input
      matInput
      placeholder="Select a Skill"
      [matAutocomplete]="auto"
      [formControl]="inputFilter"
    />
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="add($event.option.value)"
  >
    <mat-option
      *ngFor="let skill of filteredSkills$ | async; trackBy: trackBySkill"
      [value]="skill"
    >
      <div class="flex items-center gap-4">
        <span>{{ skill.name }}</span>
        <lu-skill-status
          *ngIf="skill | isDraft"
          [dense]="true"
          [status]="skill.status"
        />
      </div>
    </mat-option>
  </mat-autocomplete>
</div>

<div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
  <button mat-stroked-button mat-dialog-close>Close</button>
</div>
