import { Validators } from '@angular/forms';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  IVendor,
  type ISkill,
  type ITag,
  type IUser,
  IFolder,
} from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';

export type SkillFormData = Pick<
  ISkill,
  | 'name'
  | 'content'
  | 'author'
  | 'folderRef'
  | 'tags'
  | 'reviewers'
  | 'requiresTrainerVerification'
>;

export class SkillFormGroup extends TypedFormGroup<SkillFormData> {
  constructor() {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      content: new TypedFormControl<VersionedSchema>(
        initVersionedSchema(),
        Validators.required
      ),
      author: new TypedFormControl<DocumentReference<IUser | IVendor>>(),
      folderRef: new TypedFormControl<DocumentReference<IFolder>>(),
      tags: new TypedFormControl<DocumentReference<ITag>[]>([]),
      reviewers: new TypedFormControl<DocumentReference<IUser>[]>([]),
      requiresTrainerVerification: new TypedFormControl<boolean>(undefined),
    });
  }

  hasReviewers(): boolean {
    return (this.controls.reviewers.value?.length ?? 0) > 0;
  }
}
