<div fxLayout="column" fxFill *ngIf="user$ | async as user; else loader">
  <div fxFlex class="content">
    <lu-page-simple marginBottom="16">
      <lu-page-header label="User">
        <lu-page-toolbar>
          <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon class="font-bold">more_vert</mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu">
            <button
              mat-menu-item
              class="warn"
              (click)="toggleUserEnabled(user)"
            >
              {{ user.isActivated ? 'Disable' : 'Enable' }}
            </button>
          </mat-menu>
        </lu-page-toolbar>
      </lu-page-header>
      <div
        fxLayout="column"
        fxLayoutGap="16px"
        fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between start"
      >
        <div fxLayout="column">
          <h1 class="mat-headline-5">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
              <span>{{ user.name }}</span>
              <mat-icon *ngIf="user.isOwner" matTooltip="Owner">
                verified_user
              </mat-icon>
              <mat-icon *ngIf="user.isAdmin" matTooltip="Admin User">
                security
              </mat-icon>
            </div>
          </h1>
          <div>{{ user.email }}</div>
        </div>

        <div fxLayout="row" fxLayoutGap="16px">
          <button
            *ngIf="user | map : canInvite"
            mat-stroked-button
            color="accent"
            (click)="invite(user)"
          >
            Resend Invitation
          </button>
          <button
            mat-stroked-button
            (click)="editUser(user)"
            ptIntercomTarget="user.edit-button"
          >
            Edit
          </button>
        </div>
      </div>

      <div class="mb-4 flex flex-col gap-8">
        <div ptIntercomTarget="user.statistics">
          <h2 class="mat-headline-6">User Statistics</h2>
          <lu-user-statistics [user]="user" />
        </div>

        <lu-user-goal-progress
          ptIntercomTarget="user.goal-progress"
          [user]="user"
        />

        <lu-user-level-up-requests
          ptIntercomTarget="user.level-up-requests"
          [user]="user"
        />
      </div>
    </lu-page-simple>
  </div>
</div>

<ng-template #loader>
  <mat-progress-bar mode="indeterminate" />
</ng-template>
