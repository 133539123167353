import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  Input,
} from '@angular/core';
import { getSchemaText } from '@principle-theorem/editor';
import {
  type IUser,
  type NotificationType,
} from '@principle-theorem/level-up-core';
import { angularEditorExtensions } from '@principle-theorem/ng-editor';
import {
  type INotificationConfig,
  NG_NOTIFICATION_CONFIG,
} from '@principle-theorem/ng-notifications';
import {
  InputSearchFilter,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type INotification,
  renderNotification,
} from '@principle-theorem/notifications';
import {
  filterUndefined,
  multiMap,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../../services/organisation.service';
import { MENTION_BUTTON_PROVIDER } from '../mention/mention-buttons/mention-buttons.component';
import { NotificationTypeFilter } from './notification-type-filter';
import { AnyExtension } from '@tiptap/core';

interface INotificationFilterOptions {
  content: string;
  notification: WithRef<INotification>;
}

@Component({
  selector: 'lu-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  notifications$: BehaviorSubject<WithRef<INotification>[]> =
    new BehaviorSubject<WithRef<INotification>[]>([]);
  filteredNotifications$: Observable<WithRef<INotification>[]>;
  selectedTypeFilters$: BehaviorSubject<NotificationType[]> =
    new BehaviorSubject<NotificationType[]>([]);

  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<INotificationFilterOptions>;
  notificationExtensions: AnyExtension[];

  @Input()
  set notifications(notifications: WithRef<INotification>[]) {
    if (notifications) {
      this.notifications$.next(notifications);
    }
  }

  constructor(
    private _injector: Injector,
    public organisation: OrganisationService,
    @Inject(NG_NOTIFICATION_CONFIG) private _config: INotificationConfig
  ) {
    this.notificationExtensions = [
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
    ];

    this.searchFilter = new InputSearchFilter<INotificationFilterOptions>(
      this.organisation.user$.pipe(
        filterUndefined(),
        switchMap((user) => this._getRenderedNotifications(user)),
        map((notifications) => compact(notifications))
      ),
      this.search.valueChanges.pipe(startWith('')),
      ['content']
    );

    this.filteredNotifications$ = new NotificationTypeFilter(
      this.searchFilter.results$.pipe(
        multiMap((result) => result.notification)
      ),
      this.selectedTypeFilters$
    ).results$;
  }

  filterResults(selectedTagFilters: NotificationType[]): void {
    this.selectedTypeFilters$.next(selectedTagFilters);
  }

  private _getRenderedNotifications(
    user: WithRef<IUser>
  ): Observable<(INotificationFilterOptions | undefined)[]> {
    return this.notifications$.pipe(
      multiMap((notification) => {
        const rendered = renderNotification(
          notification,
          this._config.providers,
          user
        );
        if (!rendered) {
          return;
        }
        return {
          content: getSchemaText(rendered),
          notification,
        };
      })
    );
  }
}
