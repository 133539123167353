import { Observable } from 'rxjs';
import { Reffable, SystemActors, WithRef } from '../interfaces';
import {
  CollectionReference,
  DocumentReference,
  Query,
  QueryConstraint,
  SetOptions,
  Transaction,
  getDoc as getDocFirestore,
} from './adaptor';
import {
  IDocUpdateOptions,
  UnsavedDocument,
  doc$,
  getDoc,
  getDocs,
  getParentDocRef,
  patchDoc,
  safeSnapshotToWithRef,
  saveDoc,
} from './document';
import { DatabaseUsageTracker } from './usage-tracking';

export class Firestore {
  static getParentDocRef<T extends object>(
    path: string | DocumentReference
  ): DocumentReference<T> {
    return getParentDocRef(path);
  }

  static getDoc<T extends object>(
    ref: DocumentReference<T>,
    transaction?: Transaction
  ): Promise<WithRef<T>> {
    return getDoc(ref, transaction);
  }

  static async safeGetDoc<T extends object>(
    ref: DocumentReference<T>,
    transaction?: Transaction
  ): Promise<WithRef<T> | undefined> {
    const snapshot = await (transaction
      ? transaction.get(ref)
      : getDocFirestore(ref));
    DatabaseUsageTracker.track(snapshot.ref.parent.path, 'get', 'document');
    return safeSnapshotToWithRef<T>(snapshot);
  }

  static getDocs<T extends object>(
    col: CollectionReference<T> | Query<T>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<WithRef<T>[]> {
    return getDocs(col, ...queryConstraints);
  }

  static async patchDoc<T>(
    ref: DocumentReference<T>,
    data: Partial<T>,
    transaction?: Transaction,
    options?: IDocUpdateOptions,
    updatedBy: SystemActors | string = SystemActors.Unknown
  ): Promise<DocumentReference<Partial<T>>> {
    return patchDoc(ref, data, transaction, options, updatedBy);
  }

  static async saveDoc<T extends object>(
    item: Reffable<T> | UnsavedDocument<T>,
    options?: SetOptions,
    transaction?: Transaction,
    updateOptions?: IDocUpdateOptions,
    updatedBy: SystemActors | string = SystemActors.Unknown
  ): Promise<DocumentReference<T>> {
    return saveDoc(item, options, transaction, updateOptions, updatedBy);
  }

  static doc$<T extends object>(
    ref: DocumentReference<T> | string
  ): Observable<WithRef<T>> {
    return doc$(ref);
  }

  static isCreatedByMigration<T extends object>(item: WithRef<T>): boolean {
    return item.createdBy === SystemActors.Migration;
  }

  static isUpdatedByMigration<T extends object>(item: WithRef<T>): boolean {
    return item.updatedBy === SystemActors.Migration;
  }
}
