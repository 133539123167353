import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IUser, type IOrganisation } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export interface IOrganisationAddFormData {
  organisation: Pick<IOrganisation, 'name'>;
  owner: Pick<IUser, 'name' | 'email'>;
}

@Component({
    selector: 'lu-organisation-add-dialog',
    templateUrl: './organisation-add-dialog.component.html',
    styleUrls: ['./organisation-add-dialog.component.scss'],
    standalone: false
})
export class OrganisationAddDialogComponent {
  form = new TypedFormGroup<IOrganisationAddFormData>({
    organisation: new TypedFormGroup({
      name: new TypedFormControl<string>(''),
    }),
    owner: new TypedFormGroup({
      name: new TypedFormControl<string>(''),
      email: new TypedFormControl<string>(''),
    }),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      OrganisationAddDialogComponent,
      IOrganisationAddFormData
    >
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
