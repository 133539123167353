import { type ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionService } from './subscription.service';
import { Angulartics2Module } from 'angulartics2';
import { type ISubscriptionConfig, NG_SUBSCRIPTION_CONFIG } from './config';

@NgModule({
  imports: [CommonModule, Angulartics2Module],
  providers: [SubscriptionService],
})
export class NgSubscriptionsModule {
  static forRoot(
    config: ISubscriptionConfig
  ): ModuleWithProviders<NgSubscriptionsModule> {
    return {
      ngModule: NgSubscriptionsModule,
      providers: [
        {
          provide: NG_SUBSCRIPTION_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
