import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IPathwaySection,
  Pathway,
  updateSkillLevelRequirement,
  type IPathway,
  type IPathwayAssociation,
  type ISkill,
  type ISkillAssociation,
  type ISkillLevelRequirement,
  type SkillLevel,
} from '@principle-theorem/level-up-core';
import {
  TrackByFunctions,
  TypedFormControl,
  type InputSearchFilter,
} from '@principle-theorem/ng-shared';
import {
  ISODateType,
  asyncForEach,
  getDoc,
  isSameRef,
  multiFind,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  safeCombineLatest,
  snapshot,
  type DocumentReference,
  type WithRef,
  multiMap,
} from '@principle-theorem/shared';
import { compact, isBoolean } from 'lodash';
import { BehaviorSubject, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PathwayDueDateBloc } from '../../../../models/pathway-due-date';
import { OrganisationService } from '../../../../services/organisation.service';

@Component({
  selector: 'lu-pathway-and-skills-associate',
  templateUrl: './pathway-and-skills-associate.component.html',
  styleUrls: ['./pathway-and-skills-associate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayAndSkillsAssociateComponent {
  private _pathwayAssociations$ = new BehaviorSubject<IPathwayAssociation[]>(
    []
  );
  trackBySkillRef = TrackByFunctions.uniqueId<DocumentReference<ISkill>>();
  trackBySection = TrackByFunctions.uniqueId<IPathwaySection>();
  trackByPathway = TrackByFunctions.ref<WithRef<IPathway>>();
  trackSkillAssociation = TrackByFunctions.ref<ISkillAssociation>('skill.ref');
  pathways$: Observable<WithRef<IPathway>[]>;
  showAdd$ = new BehaviorSubject<boolean>(false);
  skills$ = new BehaviorSubject<ISkillAssociation[]>([]);
  skillLevels$ = new BehaviorSubject<ISkillLevelRequirement[]>([]);
  showDueDate$ = new BehaviorSubject<boolean>(false);
  showRelativeDueDate$ = new BehaviorSubject<boolean>(false);
  skillsFilter: InputSearchFilter<ISkillAssociation>;
  pathwaysFilter: InputSearchFilter<IPathwayAssociation>;
  search = new TypedFormControl<string>('');

  @Output() add = new EventEmitter<void>();
  @Output() pathwayRemove = new EventEmitter<DocumentReference<IPathway>>();
  @Output() skillRemove = new EventEmitter<DocumentReference<ISkill>>();
  @Output() skillLevelsChange = new EventEmitter<ISkillLevelRequirement[]>();
  @Output() pathwayLevelsChange = new EventEmitter<IPathwayAssociation[]>();

  @Input()
  set skillLevels(skillLevels: ISkillLevelRequirement[]) {
    this.skillLevels$.next(skillLevels || []);
  }

  @Input()
  set skills(skills: ISkillAssociation[]) {
    this.skills$.next(skills || []);
  }

  @Input()
  set pathways(pathwayAssociations: IPathwayAssociation[]) {
    this._pathwayAssociations$.next(pathwayAssociations || []);
  }

  @Input()
  set showAdd(showAdd: BooleanInput) {
    this.showAdd$.next(coerceBooleanProperty(showAdd));
  }

  @Input()
  set showDueDate(showDueDate: BooleanInput) {
    this.showDueDate$.next(coerceBooleanProperty(showDueDate));
  }

  @Input()
  set showRelativeDueDate(showRelativeDueDate: BooleanInput) {
    this.showRelativeDueDate$.next(coerceBooleanProperty(showRelativeDueDate));
  }

  constructor(private _organisation: OrganisationService) {
    this.pathways$ = this._pathwayAssociations$.pipe(
      multiSwitchMap((association) =>
        this._organisation.getPathway$(association.ref)
      ),
      map(compact),
      multiSortBy$(nameSorter())
    );
  }

  getPathwayLevel$(
    pathway: WithRef<IPathway>
  ): Observable<SkillLevel | undefined> {
    return this._pathwayAssociations$.pipe(
      multiFind((association) => isSameRef(association, pathway)),
      map((association) => association?.level)
    );
  }

  async handleLevelChange(
    level: SkillLevel,
    skillRef: DocumentReference<ISkill>
  ): Promise<void> {
    const skill = await getDoc(skillRef);
    const skillLevels: ISkillLevelRequirement[] =
      this.skillLevels$.value.slice();
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { level },
      skillLevels
    );
    this.skillLevelsChange.emit(updatedSkillLevels);
  }

  async handleDueDateChange(
    skillRef: DocumentReference<ISkill>,
    dueDate?: ISODateType
  ): Promise<void> {
    const skill = await getDoc(skillRef);
    const skillLevels: ISkillLevelRequirement[] =
      this.skillLevels$.value.slice();
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { dueDate },
      skillLevels
    );
    this.skillLevelsChange.emit(updatedSkillLevels);
  }

  async handleRelativeDueDateChange(
    skillRef: DocumentReference<ISkill>,
    relativeDueDate?: number
  ): Promise<void> {
    const skill = await getDoc(skillRef);
    const skillLevels: ISkillLevelRequirement[] =
      this.skillLevels$.value.slice();
    const updatedSkillLevels = updateSkillLevelRequirement(
      skill,
      { relativeDueDate },
      skillLevels
    );
    this.skillLevelsChange.emit(updatedSkillLevels);
  }

  handleRemovePathway(pathwayRef: DocumentReference<IPathway>): void {
    this.pathwayRemove.emit(pathwayRef);
  }

  handleRemoveSkill(skillRef: DocumentReference<ISkill>): void {
    this.skillRemove.emit(skillRef);
  }

  async updateDueDate(
    pathway: WithRef<IPathway>,
    dueDate?: ISODateType
  ): Promise<void> {
    const pathways = await snapshot(this._pathwayAssociations$);
    this.pathwayLevelsChange.emit(
      pathways.map((currentPathway) => {
        if (!isSameRef(currentPathway, pathway)) {
          return currentPathway;
        }

        return {
          ...currentPathway,
          ref: pathway.ref,
          dueDate,
        };
      })
    );

    const pathwaySkillRefs = pathway.sections.flatMap(
      (section) => section.steps
    );
    await asyncForEach(pathwaySkillRefs, (skill) =>
      this.handleDueDateChange(skill, dueDate)
    );
  }

  async updateSkillLevels(
    pathway: WithRef<IPathway>,
    level: SkillLevel
  ): Promise<void> {
    const pathways = await snapshot(this._pathwayAssociations$);
    this.pathwayLevelsChange.emit(
      pathways.map((currentPathway) => {
        if (!isSameRef(currentPathway, pathway)) {
          return currentPathway;
        }

        return {
          ...currentPathway,
          ref: pathway.ref,
          level,
        };
      })
    );

    const pathwaySkillRefs = pathway.sections.flatMap(
      (section) => section.steps
    );
    await asyncForEach(pathwaySkillRefs, (skill) =>
      this.handleLevelChange(level, skill)
    );
  }

  async updateRelativeDueDate(
    pathway: WithRef<IPathway>,
    relativeDueDate?: number
  ): Promise<void> {
    const pathways = await snapshot(this._pathwayAssociations$);
    this.pathwayLevelsChange.emit(
      pathways.map((currentPathway) => {
        if (!isSameRef(currentPathway, pathway)) {
          return currentPathway;
        }

        return {
          ...currentPathway,
          ref: pathway.ref,
          relativeDueDate,
        };
      })
    );

    const pathwaySkillRefs = pathway.sections.flatMap(
      (section) => section.steps
    );
    await asyncForEach(pathwaySkillRefs, (skill) =>
      this.handleRelativeDueDateChange(skill, relativeDueDate)
    );
  }

  getDueDateBloc(pathway: WithRef<IPathway>): PathwayDueDateBloc {
    const pathwayAssociation$ = this._pathwayAssociations$.pipe(
      multiFind((association) => isSameRef(association, pathway))
    );
    return new PathwayDueDateBloc(pathwayAssociation$, of(false));
  }

  hasSkillsRequiringTrainerVerification$(
    pathway: WithRef<IPathway>
  ): Observable<boolean | undefined> {
    return safeCombineLatest(
      Pathway.skillRefs(pathway).map((skillRef) =>
        this._organisation.getSkill$(skillRef)
      )
    ).pipe(
      map(compact),
      multiMap((skill) => skill.requiresTrainerVerification),
      multiFind((requiresTrainerVerification) =>
        isBoolean(requiresTrainerVerification)
      ),
      map(
        (requiresTrainerVerification) =>
          requiresTrainerVerification ?? undefined
      )
    );
  }
}
