<ng-container *ngIf="skill$ | async as skill">
  <lu-page-simple class="h-full">
    <lu-page-header label="Edit Skill - {{ skillName$ | async }}">
      <lu-page-toolbar>
        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
          <mat-icon class="font-bold">more_vert</mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu">
          <ng-container *ngIf="currentUser$ | async as user">
            <ng-container
              *ngIf="(skill | isApproved) && user.isAdmin && !skill.readOnly"
            >
              <button mat-menu-item (click)="resetToDraft(skill)">
                <mat-icon>insert_drive_file</mat-icon>
                <span>Reset to Draft</span>
              </button>
              <button mat-menu-item (click)="resetToReview(skill)">
                <mat-icon>find_in_page</mat-icon>
                <span>Reset to Review</span>
              </button>
              <mat-divider />
            </ng-container>
          </ng-container>
          <button
            mat-menu-item
            class="warn"
            [disabled]="skill.readOnly"
            (click)="delete(skill)"
          >
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </lu-page-toolbar>
    </lu-page-header>

    <lu-toolbar>
      <div
        fxFlex.gt-xs
        fxFill.lt-sm
        fxLayout="column"
        fxLayoutGap="8px"
        fxLayout.gt-xs="row"
        fxLayoutGap.gt-xs="32px"
      >
        <lu-toolbar-segment>
          {{ skill.status | titlecase }}
          <label>Status</label>
        </lu-toolbar-segment>

        <span fxFlex></span>

        <div
          class="flex items-stretch gap-4"
          ptIntercomTarget="skill-edit-form.review-and-save-buttons"
          *ngIf="skillHistory$ | async as skillHistory"
        >
          <div
            class="flex items-stretch"
            [matTooltipDisabled]="skillEditForm.canEnterReview$ | async"
            matTooltip="To mark a skill as ready for review it MUST have reviewers"
          >
            <button
              *ngIf="skill | isDraft"
              mat-flat-button
              color="primary"
              [disabled]="(skillEditForm.canEnterReview$ | async) === false"
              (click)="readyForReview(skill)"
            >
              Ready for Review
            </button>
          </div>

          <ng-container *luIsAdmin>
            <button
              *ngIf="skill | map: canPublish : this | async"
              mat-flat-button
              color="primary"
              (click)="approve(skill)"
            >
              Approve & Publish
            </button>
          </ng-container>

          <mat-form-field fxFlex.lt-sm subscriptSizing="dynamic">
            <mat-label>Version History</mat-label>
            <mat-select
              [formControl]="historySelectCtrl"
              [compareWith]="historyCompareFn"
            >
              <mat-option [value]="skill">Latest</mat-option>
              <mat-option
                *ngFor="let historyItem of skillHistory; trackBy: trackByForm"
                [value]="historyItem"
              >
                {{ historyItem.archivedAt | moment | amDateFormat: dateFormat }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </lu-toolbar>

    @if (showReviews$ | async) {
      <lu-skill-reviews [reviews]="reviews$ | async" />
    }

    <lu-skill-edit-form
      #skillEditForm
      class="relative flex-1 grow"
      [users]="users$ | async"
      [reviewers]="reviewers$ | async"
      [skill]="skill"
      [disabled]="skill.readOnly"
      (skillChange)="saveChanges(skill); saved.next()"
      (nameChange)="skillName$.next($event)"
    >
      <pt-buttons-container
        padding="none"
        ptIntercomTarget="skill-edit-form.review-and-save-buttons"
      >
        <ng-container *ngIf="historySelected$ | async; else saveButton">
          <button
            mat-flat-button
            type="submit"
            color="primary"
            matTooltip="This will restore the selected version of the skill. Any changes will also be saved."
            [disabled]="(skillEditForm.canSave$ | async) === false"
            (click)="skillEditForm.submit(); saved.next()"
          >
            Restore & Save
          </button>
        </ng-container>

        <ng-template #saveButton>
          <ng-content />
          <button
            mat-flat-button
            type="submit"
            color="primary"
            [disabled]="(skillEditForm.canSave$ | async) === false"
            (click)="skillEditForm.submit(); saved.next()"
          >
            Save
          </button>
        </ng-template>
      </pt-buttons-container>
    </lu-skill-edit-form>
  </lu-page-simple>
</ng-container>
