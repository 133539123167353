import { Pipe, type PipeTransform } from '@angular/core';
import { Tag, type ITag } from '@principle-theorem/level-up-core';

@Pipe({
  name: 'tagTextColour',
})
export class TagTextColourPipe implements PipeTransform {
  transform(tag: ITag): string {
    return Tag.textColour(tag);
  }
}
