<lu-card-container
  class="grow"
  *ngIf="pathway$ | async as pathway"
  [title]="pathway.name"
  [author]="author$ | async"
  [link]="link"
>
  <lu-card-image
    [image]="pathway.imageUrl"
    [placeholderImage]="pathwayCardPlaceholderImage"
  />
  <lu-card-actions>
    <div fxLayout="row" fxLayoutAlign="start center">
      <lu-privacy-icon
        *ngIf="pathway.restrictAccessTo.length"
        [item]="pathway"
        size="24px"
        colour="white"
      />
      <lu-tags [disabled]="!pathway.tags.length">
        <lu-tag-display [tagRefs]="pathway.tags" [compact]="false" />
      </lu-tags>
    </div>
  </lu-card-actions>
  <lu-progress-bar [user]="user" [pathway]="pathway" />
</lu-card-container>
