<lu-page-simple>
  <lu-page-header
    label="Templates"
    pageInfo="Create a preset format for your skills so they all have the same look"
    [pageTour]="tourId"
  >
    <lu-page-toolbar>
      <lu-header-button
        buttonIcon="add"
        buttonText="Create"
        ptIntercomTarget="templates.create-button"
        luPageToolbarAction
        (click)="addPageTemplate()"
      />
    </lu-page-toolbar>
  </lu-page-header>

  <lu-page-toolbar class="filter-bar">
    <pt-search-field fxFlex>
      <input matInput placeholder="Filter" [formControl]="search" />
    </pt-search-field>
  </lu-page-toolbar>

  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    class="mat-caption list-headers"
  >
    <span class="author-column">Creator</span>
    <span fxFlex.gt-xs="55px"></span>
  </div>
  <ng-container
    *ngIf="searchFilter.results$ | async as pageTemplates; else loading"
  >
    <mat-list
      class="with-dividers"
      *ngIf="pageTemplates.length; else noTemplates"
    >
      <mat-list-item
        *ngFor="let pageTemplate of pageTemplates; trackBy: trackByTemplate"
      >
        <span matListItemTitle>
          <ng-container *ngIf="!pageTemplate.readOnly; else readOnly">
            <a
              ptIntercomTarget="templates.template.name"
              [routerLink]="['./', pageTemplate.ref.id]"
              >{{ pageTemplate.name }}</a
            >
          </ng-container>
          <ng-template #readOnly>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <span>{{ pageTemplate.name }}</span>
              <mat-icon matTooltip="Read only" class="read-only">
                lock
              </mat-icon>
            </div>
          </ng-template>
        </span>

        <div matListItemMeta class="flex flex-row items-center gap-4">
          <ng-container *ngIf="pageTemplate.author">
            <ng-container
              *ngIf="pageTemplate.author | refItem$ | async as author"
            >
              <pt-user-icon
                [src]="author | profileImage$ | async"
                [diameter]="30"
                [name]="author.name"
                [matTooltip]="author.name"
              />
            </ng-container>
          </ng-container>

          <button
            mat-icon-button
            fxHide.lt-sm
            color="warn"
            [disabled]="pageTemplate.readOnly"
            (click)="remove(pageTemplate)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>

  <ng-template #loading>
    <mat-list>
      <div class="flex flex-col gap-2">
        <lu-template-placeholder />
        <lu-template-placeholder />
        <lu-template-placeholder />
      </div>
    </mat-list>
  </ng-template>

  <ng-template #noTemplates>
    <mat-list>
      <div class="layout-margin">
        <div class="mat-caption" fxLayout="row" fxLayoutAlign="start center">
          No Templates
        </div>
      </div>
    </mat-list>
  </ng-template>
</lu-page-simple>
