import {
  initRawSchema,
  type RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  getDoc,
  HTMLToEmail,
  type INamedDocument,
} from '@principle-theorem/shared';
import {
  type IEmailNotificationContext,
  type INotification,
  type INotificationContextRenderer,
} from '@principle-theorem/notifications';
import { MentionResourceType, mentionToLink, toMention } from '../../mention';
import {
  type EmailNotificationProvider,
  type INotificationUserPair,
  NotificationType,
} from '../notification';
import { userIsOfRole } from '../notification-helpers';
import {
  type ILevelUpResources,
  isLevelUpResource,
} from '../resources/level-up';

export class LevelUpDeclinedContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      ILevelUpResources
    >
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.TrainingDeclined);
  }

  isResource(data: object): data is ILevelUpResources {
    return isLevelUpResource(data);
  }

  render(
    data: ILevelUpResources,
    currentStaffer: INamedDocument
  ): RawSchema | undefined {
    const isInteractor: boolean = userIsOfRole(currentStaffer, data.mentor);
    const isGrower: boolean = userIsOfRole(currentStaffer, data.grower);
    const skillMention = toMention(data.skill, MentionResourceType.Skill);

    if (data.mentor && !isInteractor && isGrower) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(
            `${data.mentor.name} declined your training request for `
          ),
          toMentionContent(skillMention),
        ]),
      ]);
    }
  }
}

export class LevelUpDeclinedEmailContextRenderer
  implements EmailNotificationProvider
{
  canProvide(data: INotificationUserPair): boolean {
    return data.notification.type === String(NotificationType.TrainingDeclined);
  }

  async execute(
    data: INotificationUserPair
  ): Promise<IEmailNotificationContext | undefined> {
    const resources = data.notification.resources;
    if (!isLevelUpResource(resources)) {
      throw new Error('Cannot render email context');
    }

    const isInteractor: boolean = userIsOfRole(data.user, resources.mentor);
    const isGrower: boolean = userIsOfRole(data.user, resources.grower);
    const skillMention = toMention(resources.skill, MentionResourceType.Skill);
    const skillHref = mentionToLink(skillMention);
    const skillName = HTMLToEmail.bold(resources.skill.name);

    if (!resources.mentor || !isGrower || isInteractor) {
      return;
    }

    const grower = await getDoc(resources.grower.ref);
    const message = `Unfortunately your training request for ${skillName} has been declined.

    Try reaching out to ${resources.mentor.name} to for some feedback.`;

    return {
      user: {
        name: grower.name,
        email: grower.email,
      },
      subject: `Your training request for ${resources.skill.name} has been declined`,
      preheader: message,
      message,
      action: {
        label: 'View Skill',
        url: skillHref,
      },
      signOffMessage: `You've got this!`,
    };
  }
}
