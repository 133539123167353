import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IBundleSkill,
  IVendor,
  IVendorBundle,
} from '@principle-theorem/level-up-core';
import { type WithRef } from '@principle-theorem/shared';

export interface IVendorBundleSkillEditDialogData {
  skill: WithRef<IBundleSkill>;
  bundle: WithRef<IVendorBundle>;
  vendor: WithRef<IVendor>;
}

@Component({
    selector: 'lu-vendor-bundle-skill-edit-dialog',
    templateUrl: './vendor-bundle-skill-edit-dialog.component.html',
    styleUrls: ['./vendor-bundle-skill-edit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VendorBundleSkillEditDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IVendorBundleSkillEditDialogData,
    public dialogRef: MatDialogRef<VendorBundleSkillEditDialogComponent>
  ) {}
}
