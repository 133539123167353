<mat-toolbar color="primary">Create Folder</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name"
        [required]="true"
        ptIntercomTarget="skill-add-dialog.name"
      />
    </mat-form-field>

    <lu-folder-selector
      placeholder="Select a Parent Folder"
      parentOnly
      [selectedRef]="form.controls.parentFolderRef.value"
      (add)="form.controls.parentFolderRef.setValue($event)"
    />
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        color="primary"
        mat-flat-button
        [disabled]="isDisabled$ | async"
        ptIntercomTarget="skill-add-dialog.save-button"
      >
        Create
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
