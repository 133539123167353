import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IMarketplaceSubscription,
  type IUser,
  type IVendorBundle,
} from '@principle-theorem/level-up-core';
import { type RouterLinkData } from '@principle-theorem/ng-shared';
import { doc$, type IProfile, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'lu-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionCardComponent {
  @Input() link: RouterLinkData;
  @Input() user: IUser;
  subscription$: ReplaySubject<WithRef<IMarketplaceSubscription>> =
    new ReplaySubject(1);
  bundle$: Observable<WithRef<IVendorBundle>>;
  author$: Observable<WithRef<IProfile>>;

  @Input()
  set subscription(subscription: WithRef<IMarketplaceSubscription>) {
    if (subscription) {
      this.subscription$.next(subscription);
    }
  }

  constructor() {
    this.bundle$ = this.subscription$.pipe(
      switchMap((sub) => doc$(sub.bundleRef))
    );
    this.author$ = this.subscription$.pipe(
      switchMap((sub) => doc$(sub.vendorRef))
    );
  }
}
