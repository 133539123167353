import {
  count,
  multiFilter,
  multiMap,
  multiSort,
  sortTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { combineLatest, type Observable, type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { type ISkill, Skill, SkillStatus } from '../models/skill/skill';
import { type IUser } from '../models/user/user';
import { StatView } from './stat-view';

export class UserAuthorStats {
  lastAuthoredAt$: Observable<Timestamp>;
  authoredSkills$: Observable<WithRef<ISkill>[]>;
  approved: StatView;
  contribution: StatView;

  constructor(
    user$: Observable<WithRef<IUser>>,
    skills$: Observable<WithRef<ISkill>[]>
  ) {
    this.authoredSkills$ = combineLatest([user$, skills$]).pipe(
      map(([user, skills]) =>
        skills.filter((skill) => Skill.isAuthor(skill, user))
      )
    );
    const approved$ = this.authoredSkills$.pipe(
      filterByStatus(SkillStatus.Published)
    );

    this.lastAuthoredAt$ = this.authoredSkills$.pipe(
      multiSort((skillA, skillB) =>
        sortTimestamp(skillA.updatedAt, skillB.updatedAt)
      ),
      multiMap((skill) => skill.updatedAt),
      multiFilter((timestamp) => timestamp !== undefined),
      map((skills) => skills[0])
    );

    this.approved = new StatView(
      approved$.pipe(count()),
      this.authoredSkills$.pipe(count())
    );

    this.contribution = new StatView(
      approved$.pipe(count()),
      skills$.pipe(filterByStatus(SkillStatus.Published), count())
    );
  }
}

export function filterByStatus<T extends ISkill>(
  status: SkillStatus
): OperatorFunction<T[], T[]> {
  return multiFilter((skill) => skill.status === status);
}
