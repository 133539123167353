import {
  Money,
  TaxRate,
  calculateTaxFromTotal,
} from '@principle-theorem/accounting';
import {
  type IChargebeeInvoiceEstimate,
  type IEstimateResponse,
} from 'chargebee-js';

export enum GoogleTagManagerActions {
  Subscription = 'Subscription',
}

export enum EcommerceActions {
  Purchase = 'purchase',
}

export interface IEcommercePurchase {
  ecommerce: {
    purchase: {
      actionField: {
        id: string;
        revenue: number;
      };
      products: IEcommerceProduct[];
    };
    currencyCode: string;
  };
}

export interface IEcommerceProduct {
  id: string;
  name: string;
  price: number;
  tax?: number;
}

export function estimateToTransaction(
  estimate: IEstimateResponse
): IEcommercePurchase {
  const transactionId = estimate.estimate.created_at.toString();
  const invoiceEstimate = getInvoiceEstimate(estimate);
  const currencyCode = invoiceEstimate?.currency_code || 'AUD';
  const price = invoiceEstimate ? Money.fromCents(invoiceEstimate.total) : 0;
  const products = invoiceEstimate
    ? getLineItemsFromEstimate(invoiceEstimate)
    : [];

  return {
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          revenue: price,
        },
        products,
      },
      currencyCode,
    },
  };
}

export function getLineItemsFromEstimate(
  estimate: IChargebeeInvoiceEstimate
): IEcommerceProduct[] {
  return estimate.line_items.map((lineItem) => ({
    name: lineItem.description,
    id: lineItem.entity_id,
    price: Money.fromCents(lineItem.amount),
    tax: calculateTaxFromTotal(Money.fromCents(lineItem.amount), TaxRate.GST),
  }));
}

export function getInvoiceEstimate(
  estimate: IEstimateResponse
): IChargebeeInvoiceEstimate | undefined {
  return estimate.estimate.invoice_estimate
    ? estimate.estimate.invoice_estimate
    : estimate.estimate.next_invoice_estimate;
}
