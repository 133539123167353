import { Component, Input, inject } from '@angular/core';
import {
  SkillStatus,
  filterSkillsByStatus,
  resolvePathwaySkills,
  type IPathway,
  type ISkill,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { CachedListsService } from '../../../services/cached-lists.service';

@Component({
    selector: 'lu-pathway-display',
    templateUrl: './pathway-display.component.html',
    styleUrls: ['./pathway-display.component.scss'],
    standalone: false
})
export class PathwayDisplayComponent {
  profileImage = inject(ProfileImageService);
  trackBySkill = TrackByFunctions.ref<WithRef<ISkill>>();
  user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject(1);
  pathway$: ReplaySubject<WithRef<IPathway>> = new ReplaySubject(1);
  pathwaySkills$: Observable<WithRef<ISkill>[]>;

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  constructor(cache: CachedListsService) {
    this.pathwaySkills$ = this.pathway$.pipe(
      resolvePathwaySkills((skillRef) => cache.getLocalSkill(skillRef)),
      filterSkillsByStatus(SkillStatus.Published)
    );
  }
}
