import { Pipe, type PipeTransform } from '@angular/core';
import { MediaObserver } from 'ng-flex-layout';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'mediaQueryActive$',
  standalone: false,
})
export class MediaQueryActivePipe implements PipeTransform {
  constructor(private _media: MediaObserver) {}

  transform(mediaQuery: string): Observable<boolean> {
    return this._media
      .asObservable()
      .pipe(
        map((changes) =>
          changes.some(
            (change) =>
              change.mqAlias === mediaQuery || change.mediaQuery === mediaQuery
          )
        )
      );
  }
}
