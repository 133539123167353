import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-toolbar-segment',
    templateUrl: './toolbar-segment.component.html',
    styleUrls: ['./toolbar-segment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolbarSegmentComponent {}
