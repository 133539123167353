import { Component } from '@angular/core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { MatDialogRef } from '@angular/material/dialog';
import { type IPageTemplate } from '@principle-theorem/level-up-core';

export type TemplateFormData = Pick<IPageTemplate, 'name'>;

@Component({
    selector: 'lu-page-template-add-dialog',
    templateUrl: './page-template-add-dialog.component.html',
    styleUrls: ['./page-template-add-dialog.component.scss'],
    standalone: false
})
export class PageTemplateAddDialogComponent {
  form: TypedFormGroup<TemplateFormData> = new TypedFormGroup<TemplateFormData>(
    {
      name: new TypedFormControl<string>(''),
    }
  );

  constructor(
    private _dialogRef: MatDialogRef<
      PageTemplateAddDialogComponent,
      TemplateFormData | undefined
    >
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
