<div [ngSwitch]="goalType$ | async" [style.height]="size + 'px'">
  <ng-container *ngSwitchCase="type.Viewed">
    <mat-icon
      [matTooltip]="tooltip$ | async"
      matTooltipShowDelay="300"
      [ngClass]="goalProgress$ | async"
    >
      check_circle
    </mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="type.VerifiedByTrainer">
    <div
      [matTooltip]="tooltip$ | async"
      matTooltipShowDelay="300"
      class="verified-goal-icon"
    >
      <mat-icon [ngClass]="goalProgress$ | async">check_circle</mat-icon>
      <svg
        class="verified-badge"
        [ngStyle]="{ width: diameter / 2 + 'px', height: diameter / 2 + 'px' }"
      >
        <use href="/assets/icons/verified-badge.svg#verified-badge" />
      </svg>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="type.Trainer">
    <span class="certified">
      <mat-icon
        [matTooltip]="tooltip$ | async"
        matTooltipShowDelay="300"
        [ngClass]="goalProgress$ | async"
      >
        verified
      </mat-icon>
    </span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-icon [matTooltip]="tooltip$ | async" class="no-goal">
      radio_button_unchecked
    </mat-icon>
  </ng-container>
</div>
