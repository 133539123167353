import {
  WithRef,
  all$,
  asColRef,
  shareReplayCold,
} from '@principle-theorem/shared';
import { IPublicConfig, RootCollection } from '../root-collection';
import { CollectionReference } from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export class PublicConfig {
  static col(): CollectionReference<IPublicConfig> {
    return asColRef<IPublicConfig>(RootCollection.Config);
  }

  static all$(): Observable<WithRef<IPublicConfig>[]> {
    return all$(this.col()).pipe(shareReplayCold());
  }
}
