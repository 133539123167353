import {
  ISkill,
  ISkillLevelRequirement,
  Skill,
  SkillLevel,
  SkillLevelRequestStatus,
  User,
  currentSkillLevelValue,
  getSkillLevelGoal$,
  getSkillLevelRequirement$,
  skillGoalAchieved,
  type IGoals,
  type ISkillLevelRequest,
  type ISkillProgress,
  type IUser,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import {
  filterUndefined,
  find$,
  shareReplayCold,
  where,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, type Observable, type OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SkillDueDateBloc } from './skill-due-date';
import { findUserSkillProgress } from './user';

export class UserSkillProgress {
  doc$: Observable<WithRef<ISkillProgress> | undefined>;
  current$: Observable<SkillLevel>;
  goals$: Observable<IGoals>;
  levelRequirement$: Observable<ISkillLevelRequirement | undefined>;
  goalLevel$: Observable<SkillLevel>;
  hasAchievedGoal$: Observable<boolean>;
  hasRequest$: Observable<boolean>;
  request$: Observable<WithRef<ISkillLevelRequest> | undefined>;
  resolvedSkill$: Observable<WithRef<ISkill>>;
  dueDateBloc: SkillDueDateBloc;

  constructor(
    usersGroups$: Observable<WithRef<IUserGroup>[]>,
    user$: Observable<WithRef<IUser>>,
    skill$: Observable<WithRef<ISkill>>
  ) {
    this.resolvedSkill$ = skill$.pipe(filterUndefined(), shareReplayCold());
    this.goals$ = combineLatest([usersGroups$, user$]).pipe(
      map(([groups, user]) => User.getGoals(user, groups))
    );

    this.levelRequirement$ = this.goals$.pipe(
      getSkillLevelRequirement$(this.resolvedSkill$)
    );

    this.goalLevel$ = this.goals$.pipe(getSkillLevelGoal$(this.resolvedSkill$));

    this.doc$ = this.resolvedSkill$.pipe(
      switchMap((skill) => user$.pipe(findUserSkillProgress(skill))),
      shareReplayCold()
    );

    this.current$ = this.doc$.pipe(
      map((progress) =>
        progress ? currentSkillLevelValue(progress) : SkillLevel.None
      )
    );

    this.hasAchievedGoal$ = combineLatest([this.goals$, this.doc$]).pipe(
      map(([goals, progress]) => skillGoalAchieved(goals, progress))
    );

    this.dueDateBloc = new SkillDueDateBloc(
      this.levelRequirement$,
      this.hasAchievedGoal$
    );

    this.request$ = combineLatest([user$, skill$]).pipe(
      getLatestSkillRequest$(SkillLevelRequestStatus.Pending)
    );

    this.hasRequest$ = this.request$.pipe(
      map((trainingRequest) => !!trainingRequest)
    );
  }
}

export function getLatestSkillRequest$(
  status?: SkillLevelRequestStatus
): OperatorFunction<
  [IReffable<IUser>, WithRef<ISkill>],
  WithRef<ISkillLevelRequest> | undefined
> {
  return (source$: Observable<[IReffable<IUser>, WithRef<ISkill>]>) =>
    source$.pipe(
      switchMap(([user, skill]) =>
        find$(
          Skill.trainingRequestCol(skill),
          ...compact([
            where('userRef', '==', user.ref),
            status ? where('status', '==', status) : undefined,
          ])
        )
      )
    );
}
