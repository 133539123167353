import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntercomEvent, type IUser } from '@principle-theorem/level-up-core';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { addDoc, asyncForEach } from '@principle-theorem/shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import {
  type IInviteUserDialogData,
  InviteUserDialogComponent,
} from '../../pages/admin/users/invite-user-dialog/invite-user-dialog.component';

@Component({
  selector: 'lu-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInviteComponent {
  @Input() usersCollection: CollectionReference<IUser>;

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _intercom: Intercom
  ) {}

  async invite(): Promise<void> {
    const data: IInviteUserDialogData = {
      usersCollection: this.usersCollection,
    };

    const users = await this._dialog
      .open<InviteUserDialogComponent, IInviteUserDialogData, IUser[]>(
        InviteUserDialogComponent,
        DialogPresets.large({ data })
      )
      .afterClosed()
      .toPromise();

    if (users?.length) {
      await this._handleInvite(users);
    }
  }

  private async _handleInvite(users: IUser[]): Promise<void> {
    const usersCol: CollectionReference<IUser> = this.usersCollection;
    await asyncForEach(users, async (user) => {
      await addDoc(usersCol, user);

      this._intercom.trackEvent(IntercomEvent.AddedUser, {
        name: user.name,
        email: user.email,
        isAdmin: user.isAdmin,
      });
    });

    if (users.length > 1) {
      this._snackBar.open(`Invites Sent`);
      return;
    }
    this._snackBar.open(`Invite Sent`);
  }
}
