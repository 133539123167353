import { Dinero } from './dinero';
import { isNumber, sum } from 'lodash';
import { roundTo2Decimals, roundToDecimals } from './helpers';

export type MoneyInput = number | Dinero.Dinero;

export class Money {
  static from(
    amount: MoneyInput,
    currency: Dinero.Currency = 'AUD'
  ): Dinero.Dinero {
    if (isNumber(amount)) {
      if (isNaN(amount)) {
        // eslint-disable-next-line no-console
        console.warn('NaN passed to Money.from');
        return Dinero({ amount: 0, currency });
      }
      return Dinero({ amount: this.toCents(amount), currency });
    }
    if (amount.getCurrency() !== currency) {
      throw new Error('Currency mismatch');
    }
    return amount;
  }

  static amount(amount: MoneyInput): number {
    const cents = this.from(amount).getAmount();
    return this.fromCents(cents);
  }

  /**
   * Used to convert from dollars to cents
   * Rounds to numberOfDecimals, defaults to 2
   */
  static toCents(
    amountInDollars: number,
    numberOfDecimals: number = 2
  ): number {
    if (numberOfDecimals === 2) {
      const cents = roundTo2Decimals(amountInDollars) * 100;
      return Math.round(cents);
    }
    const cents = roundToDecimals(amountInDollars, numberOfDecimals) * 100;
    return parseFloat(cents.toFixed(numberOfDecimals - 2));
  }

  /**
   * Used to convert from cents to dollars
   */
  static fromCents(amountInCents: number): number {
    return roundTo2Decimals(amountInCents / 100);
  }

  static allocate(amount: MoneyInput, proportions: number[]): Dinero.Dinero[] {
    const safeProportions = toSafeProportions(proportions);
    if (!amount || !safeProportions.length) {
      return [];
    }
    try {
      return this.from(amount).allocate(safeProportions);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error allocating', { amount, proportions });
      throw error;
    }
  }

  static sum(amounts: MoneyInput[]): Dinero.Dinero {
    return amounts
      .map((amount) => this.from(amount))
      .reduce((acc, amount) => acc.add(amount), this.from(0));
  }
}

function toSafeProportions(proportions: number[]): number[] {
  const noNegatives = proportions.map((proportion) =>
    proportion < 0 ? 0 : proportion
  );
  const total = sum(noNegatives);
  if (total === 0) {
    return noNegatives.map(() => 1);
  }
  return noNegatives;
}
