import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type ISkill,
  type IUser,
  SkillLevel,
  skillLevelToLabel,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { multiSwitchMap, type WithRef } from '@principle-theorem/shared';
import { isUndefined } from 'lodash';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { OrganisationService } from '../../../services/organisation.service';

interface IUserWithLevel {
  user: WithRef<IUser>;
  level: SkillLevel;
}

interface ISkillLevelGroup {
  label: string;
  level: SkillLevel;
  users: WithRef<IUser>[];
}

@Component({
  selector: 'lu-users-skill-summary',
  templateUrl: './users-skill-summary.component.html',
  styleUrls: ['./users-skill-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSkillSummaryComponent {
  private _skill$: ReplaySubject<WithRef<ISkill>> = new ReplaySubject<
    WithRef<ISkill>
  >(1);
  private _users$: ReplaySubject<WithRef<IUser>[]> = new ReplaySubject<
    WithRef<IUser>[]
  >(1);
  trackByLevel = TrackByFunctions.label<ISkillLevelGroup>();
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  skillGroups$: Observable<ISkillLevelGroup[]>;

  constructor(private _organisation: OrganisationService) {
    this.skillGroups$ = combineLatest([
      this._skill$,
      this._users$.pipe(multiSwitchMap((user) => this._getUserLevel$(user))),
    ]).pipe(map(([skill, items]) => this._groupByLevel(skill, items)));
  }

  @Input()
  set skill(skill: WithRef<ISkill>) {
    if (skill) {
      this._skill$.next(skill);
    }
  }

  @Input()
  set users(users: WithRef<IUser>[]) {
    if (users) {
      this._users$.next(users);
    }
  }

  private _getUserLevel$(user: WithRef<IUser>): Observable<IUserWithLevel> {
    const progress = new UserSkillProgress(
      this._organisation.userGroups$,
      of(user),
      this._skill$
    );
    return progress.current$.pipe(map((level) => ({ user, level })));
  }

  private _groupByLevel(
    skill: WithRef<ISkill>,
    items: IUserWithLevel[]
  ): ISkillLevelGroup[] {
    const levels = this._getSkillLevels(skill);
    return levels.map((level) => ({
      label: skillLevelToLabel(level),
      level,
      users: items
        .filter((item) => item.level === level)
        .map((item) => item.user),
    }));
  }

  private _getSkillLevels(skill: WithRef<ISkill>): SkillLevel[] {
    if (isUndefined(skill.requiresTrainerVerification)) {
      return [
        SkillLevel.Trainer,
        SkillLevel.VerifiedByTrainer,
        SkillLevel.Viewed,
      ];
    }

    if (skill.requiresTrainerVerification) {
      return [SkillLevel.Trainer, SkillLevel.VerifiedByTrainer];
    }

    return [SkillLevel.Viewed];
  }
}
