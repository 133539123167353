<lu-page-simple *ngIf="vendor$ | async as vendor" marginBottom="32">
  <lu-page-header label="Profile" />
  <div fxLayout="row" fxLayoutGap="30px" class="vendor-profile-container">
    <pt-profile-image-manager
      [storagePath]="storagePath$ | async"
      [profile]="vendor"
     />
    <div fxLayout="column" fxLayoutGap="16px">
      <div>
        <div class="mat-caption">Name</div>
        <div class="mat-body-2">{{ vendor.name }}</div>
      </div>
    </div>
  </div>
</lu-page-simple>
