import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IPathway,
  IPathwaySection,
  ISkill,
  PathwaySectionStep,
  isSkill,
} from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  WithRef,
  filterUndefined,
  getDoc,
  isWithRef,
  multiSwitchMap,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSkillProgress } from '../../../models/user-skill-progress';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
  selector: 'lu-section-view',
  templateUrl: './section-view.component.html',
  styleUrls: ['./section-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionViewComponent {
  trackByStep = TrackByFunctions.ref<WithRef<PathwaySectionStep>>();
  steps$: Observable<WithRef<PathwaySectionStep>[]>;
  section$ = new ReplaySubject<IPathwaySection>(1);
  pathway$ = new ReplaySubject<WithRef<IPathway>>(1);
  @Input() skillPrefix: string;

  @Input()
  set section(section: IPathwaySection) {
    if (section) {
      this.section$.next(section);
    }
  }

  @Input()
  set pathway(pathway: WithRef<IPathway>) {
    if (pathway) {
      this.pathway$.next(pathway);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.steps$ = this.section$.pipe(
      map((section) => section.steps ?? []),
      multiSwitchMap((stepRef) => getDoc(stepRef))
    );
  }

  getSkillProgress(
    skill: WithRef<PathwaySectionStep>
  ): UserSkillProgress | undefined {
    if (!isSkill(skill) || !isWithRef<ISkill>(skill)) {
      return;
    }
    return new UserSkillProgress(
      this._organisation.userGroups$,
      this._organisation.user$.pipe(filterUndefined()),
      of(skill)
    );
  }
}
