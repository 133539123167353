<div
  class="block h-full overflow-auto print:overflow-visible"
  *ngIf="skill$ | async as skill"
>
  <div class="mb-20 bg-white">
    <lu-page-simple marginBottom="16">
      <lu-page-header [label]="skill.name" [pageTour]="tourId">
        <lu-level-indicator
          *ngIf="isLocalSkill$ | async"
          [diameter]="30"
          [level]="skillProgress.current$ | async"
          [goal]="skillProgress.goalLevel$ | async"
          [isRequest]="skillProgress.hasRequest$ | async"
        />

        <lu-favourite-indicator *ngIf="isLocalSkill$ | async" [item]="skill" />

        <lu-privacy-icon
          *ngIf="skill.restrictAccessTo.length"
          [item]="skill"
          size="24px"
        />
        <lu-read-only-icon *ngIf="skill.readOnly" size="24px" />
      </lu-page-header>

      <lu-skill-display [skill]="skill" />

      <ng-container *ngIf="skill | isApproved">
        <div *ngIf="pathways$ | async as pathways">
          <h3 class="mat-headline-6">Pathways</h3>

          <lu-pathway-list
            [pathways]="pathways"
            [user]="organisation.user$ | async"
          />
        </div>
      </ng-container>
    </lu-page-simple>
  </div>

  <pt-page-footer
    class="fixed bottom-0 right-0 pr-0"
    [ngClass]="{ 'lg:pr-[400px]': isSubPage$ | async }"
    [padding]="false"
    *ngIf="isLocalSkill$ | async"
  >
    <pt-buttons-container padding="none">
      <lu-skill-progress-actions
        #actions
        *ngIf="skill | map: isApproved"
        [skill]="skill"
      />
    </pt-buttons-container>
  </pt-page-footer>
</div>
