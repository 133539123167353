import { Injectable } from '@angular/core';
import {
  ISkill,
  IntercomEvent,
  SkillProgress,
  SkillRefType,
  User,
  setProgressLevel,
  type ISkillProgress,
  type IUser,
  type SkillLevel,
} from '@principle-theorem/level-up-core';
import {
  addDoc,
  doc,
  getDoc,
  isSameRef,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { Intercom } from '@supy-io/ngx-intercom';
import { last } from 'lodash';
import { of } from 'rxjs';
import { findUserSkillProgress } from '../models/user';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root',
})
export class SkillProgressUpdaterService {
  constructor(
    private _organisation: OrganisationService,
    private _intercom: Intercom
  ) {}

  async upsert(
    user: WithRef<IUser>,
    skill: WithRef<ISkill>,
    level: SkillLevel
  ): Promise<void> {
    const accreditor = await this._getAccreditor();
    const progress = await this._getUserSkillProgress(user, skill);
    const oldLevel = last(progress.history)?.level;

    setProgressLevel(progress, level, accreditor.ref);
    await saveDoc(progress);

    if (isSameRef(user, accreditor)) {
      return;
    }

    this._intercom.trackEvent(IntercomEvent.ApprovedLevelUp, {
      skill: skill.name,
      user: user.name,
      oldLevel,
      newLevel: level,
    });
  }

  private async _getUserSkillProgress(
    user: WithRef<IUser>,
    skill: WithRef<ISkill>
  ): Promise<WithRef<ISkillProgress>> {
    const progress: WithRef<ISkillProgress> | undefined =
      await this._findUserSkillProgress(user, skill);
    return progress ? progress : this._createUserSkillProgress(user, skill);
  }

  private async _findUserSkillProgress(
    user: WithRef<IUser>,
    skill: WithRef<ISkill>
  ): Promise<WithRef<ISkillProgress> | undefined> {
    return snapshot(of(user).pipe(findUserSkillProgress(skill)));
  }

  private async _createUserSkillProgress(
    user: WithRef<IUser>,
    skill: WithRef<ISkill>
  ): Promise<WithRef<ISkillProgress>> {
    const progress = SkillProgress.init({
      skillRef: {
        ref: skill.ref,
        type: SkillRefType.Skill,
      },
    });
    const col = User.skillProgressCol(user);
    const docRef = await addDoc(col, progress);
    return getDoc(doc(col, docRef.id));
  }

  private async _getAccreditor(): Promise<WithRef<IUser>> {
    const accreditor: WithRef<IUser> | undefined = await snapshot(
      this._organisation.user$
    );
    if (!accreditor) {
      throw new Error('Unknown Auth User');
    }
    return accreditor;
  }
}
