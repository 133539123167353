import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pt-file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FileUploadDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<FileUploadDialogComponent, File[]>
  ) {}

  fileUploaded(file: File[]): void {
    this._dialogRef.close(file);
  }
}
