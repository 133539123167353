import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { UploadTaskSnapshot } from 'firebase/storage';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'pt-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FileUploadComponent {
  @Output() fileUploaded = new EventEmitter<File[]>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  uploaderId: string = 'uploader-' + uuid();
  isHovering: boolean;

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  startUpload(event: Event): void {
    const element = event.target as HTMLInputElement;
    if (!element.files) {
      return;
    }
    this.fileDropped(element.files);
    this.fileInput.nativeElement.value = '';
  }

  fileDropped(fileList: FileList): void {
    this.fileUploaded.next(Array.from(fileList));
  }

  isActive(snapshot: UploadTaskSnapshot): boolean {
    const isRunning: boolean = snapshot.state === 'running';
    const bytesRemaining: number =
      snapshot.totalBytes - snapshot.bytesTransferred;
    return isRunning && bytesRemaining > 0;
  }
}
