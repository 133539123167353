<div fxLayout="column" fxLayoutGap="32px">
  <div>
    <lu-list-heading>
      <lu-list-title color="primary">Pathways</lu-list-title>
    </lu-list-heading>
    <div *ngIf="pathways$ | async as pathways">
      <mat-list>
        <ng-container *ngIf="pathways.length; else noPathways">
          <ng-container
            *ngFor="let pathway of pathways; trackBy: trackByPathway"
          >
            <mat-list-item>
              <a
                matListItemTitle
                [routerLink]="['/explore/pathway', pathway.ref.id]"
                >{{ pathway.name }}</a
              >
              <div matListItemMeta class="flex items-center gap-4">
                <lu-user-pathway-progress
                  [user]="user$ | async"
                  [pathway]="pathway"
                />

                <lu-user-goal-due-date-indicator
                  *ngIf="pathway | map : getDueDateBloc : this as bloc"
                  [canEdit]="true"
                  [dueDate]="bloc.dueDate$ | async"
                  (dateChange)="updateDueDate(pathway, $event)"
                />

                <button mat-icon-button (click)="pathwaySkillList.toggle()">
                  <mat-icon>
                    {{
                      (pathwaySkillList.expanded$ | async)
                        ? 'expand_less'
                        : 'expand_more'
                    }}
                  </mat-icon>
                </button>
              </div>
            </mat-list-item>

            <pt-collapsible #pathwaySkillList>
              <ng-template ptCollapsibleContent>
                <mat-list class="with-dividers pathway-skills">
                  <ng-container
                    *ngFor="
                      let section of pathway.sections;
                      trackBy: trackBySection
                    "
                  >
                    <div mat-subheader *ngIf="section.name">
                      {{ section.name }}
                    </div>
                    <ng-container
                      *ngFor="
                        let skillRef of section.steps;
                        trackBy: trackBySkillRef
                      "
                    >
                      <lu-user-skill-associate
                        [user]="user$ | async"
                        [skillRef]="skillRef"
                        [skillLevels]="skillLevels$ | async"
                        (goalChange)="handleGoalChange($event, skillRef)"
                      />
                    </ng-container>
                  </ng-container>
                </mat-list>
              </ng-template>
            </pt-collapsible>
          </ng-container>
        </ng-container>
      </mat-list>

      <ng-template #noPathways>
        <p class="mat-caption layout-margin">No Pathway goals</p>
      </ng-template>
    </div>
  </div>

  <div>
    <lu-list-heading>
      <lu-list-title color="primary">Skills</lu-list-title>
    </lu-list-heading>

    <div *ngIf="skills$ | async as skills">
      <mat-list>
        <ng-container *ngIf="skills.length; else noSkills">
          <ng-container
            *ngFor="
              let skillAssociation of skills;
              trackBy: trackSkillAssociation
            "
          >
            <lu-user-skill-associate
              [user]="user$ | async"
              [skillRef]="skillAssociation.skill.ref"
              [skillLevels]="skillLevels$ | async"
              (removeSkill)="handleRemoveSkill($event)"
              (goalChange)="
                handleGoalChange($event, skillAssociation.skill.ref)
              "
            />
          </ng-container>
        </ng-container>
      </mat-list>

      <ng-template #noSkills>
        <p class="mat-caption layout-margin">No Skill goals</p>
      </ng-template>
    </div>
  </div>
</div>
