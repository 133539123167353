import { Component, Input } from '@angular/core';
import { type UserStatistics } from '../../../models/user-statistics';

@Component({
    selector: 'lu-reviewer-stat',
    templateUrl: './reviewer-stat.component.html',
    styleUrls: ['./reviewer-stat.component.scss'],
    standalone: false
})
export class ReviewerStatComponent {
  @Input() stats: UserStatistics;
  @Input() colour = 'accent';
}
