import { Pipe, type PipeTransform } from '@angular/core';
import {
  type SkillLevel,
  skillLevelToNumber,
} from '@principle-theorem/level-up-core';

@Pipe({
    name: 'skillLevelToNumber',
    standalone: false
})
export class SkillLevelToNumberPipe implements PipeTransform {
  transform(value: SkillLevel): number {
    return skillLevelToNumber(value);
  }
}
