import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[ptIntercomTarget]',
    standalone: false
})
export class IntercomTargetDirective {
  @HostBinding('attr.data-intercom-target') target: string = '';

  @Input('ptIntercomTarget')
  set intercomTarget(target: string) {
    if (target) {
      this.target = target.toLowerCase();
    }
  }
}
