import { Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IUser } from '@principle-theorem/level-up-core';
import { ReplaySubject } from 'rxjs';
import { OrganisationService } from '../../services/organisation.service';
import { UserStatistics } from '../../models/user-statistics';
import { type ThemePalette } from '@angular/material/core';
import { CachedListsService } from '../../services/cached-lists.service';

@Component({
    selector: 'lu-user-statistics',
    templateUrl: './user-statistics.component.html',
    styleUrls: ['./user-statistics.component.scss'],
    standalone: false
})
export class UserStatisticsComponent {
  private _user$: ReplaySubject<WithRef<IUser>> = new ReplaySubject<
    WithRef<IUser>
  >(1);
  stats: UserStatistics;
  @Input() colour: ThemePalette = 'accent';

  constructor(
    organisation: OrganisationService,
    cachedLists: CachedListsService
  ) {
    this.stats = new UserStatistics(
      this._user$.asObservable(),
      organisation,
      cachedLists
    );
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this._user$.next(user);
    }
  }
}
