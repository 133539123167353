<mat-list class="with-dividers">
  <ng-container *ngIf="vendors$ | async as vendors; else loading">
    <ng-container *ngIf="vendors.length; else noVendors">
      <mat-list-item *ngFor="let vendor of vendors; trackBy: trackByVendor">
        <span matListItemTitle>
          <a [routerLink]="['./', vendor.ref.id]">{{ vendor.name }}</a>
        </span>
      </mat-list-item>
    </ng-container>
  </ng-container>
</mat-list>

<ng-template #loading>
  <mat-list>
    <div class="layout-margin flex flex-col gap-2">
      <lu-template-placeholder />
      <lu-template-placeholder />
      <lu-template-placeholder />
    </div>
  </mat-list>
</ng-template>

<ng-template #noVendors>
  <mat-list>
    <div class="layout-margin">
      <div class="mat-caption" fxLayout="row" fxLayoutAlign="start center">
        No Vendors
      </div>
    </div>
  </mat-list>
</ng-template>
