import {
  initRawSchema,
  type RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  type IEmailNotificationContext,
  type INotification,
  type INotificationContextRenderer,
} from '@principle-theorem/notifications';
import {
  getDoc,
  HTMLToEmail,
  type INamedDocument,
} from '@principle-theorem/shared';
import { MentionResourceType, toMention } from '../../mention';
import {
  type EmailNotificationProvider,
  type INotificationUserPair,
  NotificationType,
} from '../notification';
import { userIsOfRole } from '../notification-helpers';
import {
  type ILevelUpResources,
  isLevelUpResource,
} from '../resources/level-up';

export class LevelUpRequestedContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      ILevelUpResources
    >
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.TrainingRequested);
  }

  isResource(data: object): data is ILevelUpResources {
    return isLevelUpResource(data);
  }

  render(
    data: ILevelUpResources,
    currentStaffer: INamedDocument
  ): RawSchema | undefined {
    const isInteractor: boolean = userIsOfRole(currentStaffer, data.grower);
    const isTrainer: boolean = userIsOfRole(currentStaffer, data.mentor);
    const skillMention = toMention(data.skill, MentionResourceType.Skill);

    if (isTrainer && data.grower && !isInteractor) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`${data.grower.name} has requested a trainer for `),
          toMentionContent(skillMention),
        ]),
      ]);
    }
  }
}

export class LevelUpRequestedEmailContextRenderer
  implements EmailNotificationProvider
{
  canProvide(data: INotificationUserPair): boolean {
    return (
      data.notification.type === String(NotificationType.TrainingRequested)
    );
  }

  async execute(
    data: INotificationUserPair
  ): Promise<IEmailNotificationContext | undefined> {
    const resources = data.notification.resources;
    if (!isLevelUpResource(resources)) {
      throw new Error('Cannot render email context');
    }

    const isInteractor: boolean = userIsOfRole(data.user, resources.grower);
    const isTrainer: boolean = userIsOfRole(data.user, resources.mentor);
    const skillName = HTMLToEmail.bold(resources.skill.name);

    if (!resources.mentor || !isTrainer || !resources.grower || isInteractor) {
      return;
    }

    const mentor = await getDoc(resources.mentor.ref);
    const message = `${resources.grower.name} has requested a trainer for ${skillName}.`;

    return {
      user: {
        name: mentor.name,
        email: mentor.email,
      },
      subject: `${resources.grower.name} has requested a trainer!`,
      preheader: message,
      message,
      action: {
        label: 'View Request',
        url: 'mentor',
      },
      signOffMessage: 'Keep up the great work!',
    };
  }
}
