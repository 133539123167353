<lu-list-heading>
  <lu-list-title *ngIf="showTitle" color="primary"
    >Training Requests</lu-list-title
  >
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    class="mat-caption list-headers"
  >
    <span class="trainer-column">Trainer</span>
  </div>
</lu-list-heading>

<mat-list
  class="level-up-requests with-dividers"
  [ngClass]="{ 'with-title': showTitle }"
>
  <ng-container
    *ngIf="trainingRequests$ | async as trainingRequests; else loadingSkills"
  >
    <ng-container *ngIf="trainingRequests.length; else noTrainingRequests">
      <mat-list-item
        *ngFor="
          let trainingRequest of trainingRequests;
          trackBy: trackByRequest
        "
      >
        <ng-container
          *ngIf="trainingRequest.skillRef | map : getSkill | async as skill"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
            <lu-user-skill-level-indicator
              [user]="user$ | async"
              [skill]="skill"
            />
            <div class="flex flex-col">
              <a [routerLink]="['/explore/skill', skill.ref.id]">
                {{ skill.name }}
              </a>
              <small class="mat-caption">{{
                trainingRequest.createdAt | moment | amTimeAgo
              }}</small>
            </div>
          </div>

          <span fxFlex></span>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <lu-user-skill-progress
              class="request-column"
              isRequest
              [user]="user$ | async"
              [skill]="skill"
              [canEdit]="skill | map : canApproveTrainingRequest : this | async"
            />

            <div class="cancel-icon">
              <button
                mat-icon-button
                matTooltip="Cancel Request"
                (click)="cancelTrainingRequest(trainingRequest)"
              >
                <mat-icon>highlight_off</mat-icon>
              </button>
            </div>

            <lu-assignee-user-selector
              selectTooltip="Select Trainer"
              changeTooltip="Change Trainer"
              emptyUsersMessage="No Trainers Available"
              [disableClear]="true"
              [selectedUser]="trainingRequest.mentorRef"
              [users]="skill | map : getTrainersForSkill$ : this | async"
              (userSelected)="
                reassignTrainingRequest(trainingRequest.ref, $event)
              "
            />
          </div>
        </ng-container>
      </mat-list-item>
    </ng-container>
  </ng-container>
</mat-list>

<ng-template #loadingSkills>
  <mat-list>
    <div class="layout-margin flex flex-col gap-2">
      <lu-pathway-placeholder />
      <lu-pathway-placeholder />
      <lu-pathway-placeholder />
    </div>
  </mat-list>
</ng-template>

<ng-template #noTrainingRequests>
  <div
    class="mat-caption layout-margin"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    No Training Requests
  </div>
</ng-template>
