import { InjectionToken } from '@angular/core';
import { ITypesenseConfig } from '@principle-theorem/typesense';
import { ActionCodeSettings } from 'firebase/auth';

export interface IChargebeeConfig {
  accountId: string;
  apiKey: string;
  planIds: {
    monthly: string;
    yearly: string;
  };
}

export interface ITipTapConfig {
  appId: string;
}

export interface INgLevelUpConfig {
  production: boolean;
  passwordlessLogin: ActionCodeSettings;
  chargebee: IChargebeeConfig;
  typesense: ITypesenseConfig;
  websiteURL: string;
  tipTap: ITipTapConfig;
}

export const NG_LEVEL_UP_CONFIG: InjectionToken<INgLevelUpConfig> =
  new InjectionToken('ILevelUpModuleConfig');
