import {
  Component,
  Inject,
  NgZone,
  inject,
  type OnDestroy,
} from '@angular/core';
import { keepUnstableUntilFirst, ɵAngularFireSchedulers } from '@angular/fire';
import { MatIconRegistry } from '@angular/material/icon';
import {
  DomSanitizer,
  Title,
  type SafeResourceUrl,
} from '@angular/platform-browser';
import { LEVEL_UP_SERIALISERS } from '@principle-theorem/level-up-core';
import { AuthService } from '@principle-theorem/ng-auth';
import { FirebasePerformanceService } from '@principle-theorem/ng-firebase';
import {
  AppConfigService,
  HotkeysService,
  IntercomSyncService,
  OrganisationService,
} from '@principle-theorem/ng-level-up';
import {
  APP_VERSION,
  AppVersionService,
  DynamicTitleService,
} from '@principle-theorem/ng-shared';
import {
  FUNCTIONS_REGION,
  FirebaseFunctionsScheduler,
  FirestoreScheduler,
  SerialisationProvider,
  filterUndefined,
} from '@principle-theorem/shared';
import { getApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import * as LogRocket from 'logrocket';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'lu-root',
  template: '<router-outlet></router-outlet>',
  standalone: false,
})
export class AppComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    performance: FirebasePerformanceService,
    intercomSync: IntercomSyncService,
    version: AppVersionService,
    dynamicTitle: DynamicTitleService,
    title: Title,
    org: OrganisationService,
    private _hotkeysService: HotkeysService,
    private _authService: AuthService,
    zone: NgZone,
    @Inject(APP_VERSION) appVersion: string
  ) {
    // eslint-disable-next-line no-console
    console.log(`Running version: ${appVersion}`);
    LogRocket.init(environment.logRocketKey, {
      console: {
        shouldAggregateConsoleErrors: true,
      },
    });
    performance.init();
    intercomSync.init();
    if (environment.useEmulator) {
      connectFunctionsEmulator(getFunctions(), 'localhost', 5003);
    }

    const scheduler = new ɵAngularFireSchedulers(zone);

    FirestoreScheduler.init(
      scheduler.outsideAngular,
      keepUnstableUntilFirst,
      getApp().name,
      environment.firebase
    );

    FirebaseFunctionsScheduler.init(
      scheduler.outsideAngular,
      FUNCTIONS_REGION,
      environment.firebase,
      environment.useEmulator ? ['localhost', 5003] : false
    );

    SerialisationProvider.init(LEVEL_UP_SERIALISERS);

    this._registerSvgIconSets([`table-edit-icons.svg`]);
    this._registerSvgIconSets([`image-edit-icons.svg`]);

    dynamicTitle.default = 'Level Up';
    dynamicTitle.title$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => title.setTitle(value));

    if (environment.production) {
      const appConfig = inject(AppConfigService);
      const logoutFn = (): Promise<void> => this._authService.logout();
      version.watchForUpdates(
        appConfig.public$.pipe(map((config) => config.version)),
        logoutFn
      );
    }

    combineLatest([
      org.user$.pipe(filterUndefined()),
      org.organisation$.pipe(filterUndefined()),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([user, organisation]) => {
        LogRocket.identify(user.ref.path, {
          name: user.name,
          email: user.email,
          organisation: organisation.name,
        });
      });

    this._hotkeysService.registerHotkeys();

    this._iconRegistry.registerFontClassAlias(
      'material-symbols',
      'material-symbols-outlined'
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _registerSvgIconSets(iconSetFiles: string[]): void {
    iconSetFiles.map((file: string) =>
      this._iconRegistry.addSvgIconSet(this._iconUrl(file))
    );
  }

  private _iconUrl(file: string): SafeResourceUrl {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(
      `assets/icons/${file}`
    );
  }
}
