<div
  class="group/skill flex grow items-center gap-4 truncate"
  *ngIf="node$ | async as node"
>
  <ng-content></ng-content>

  <mat-icon class="shrink-0">{{ node.icon }}</mat-icon>

  <ng-container *ngIf="node.url; else simpleLabel">
    <a
      [routerLink]="[node.url]"
      class="truncate"
      [ptTruncateTooltip]="node.name"
    >
      {{ node.name }}
    </a>
  </ng-container>

  <ng-template #simpleLabel>
    <span class="truncate">{{ node.name }}</span>
  </ng-template>

  <ng-container *ngIf="(hoverBloc.hover$ | async) && (node | map: isSkill)">
    <div class="actions absolute right-0 flex bg-white">
      <lu-favourite-indicator [item]="node.skill" />
      <button
        [routerLink]="['/create', node.ref.id]"
        mat-icon-button
        matTooltip="Edit Skill"
        matTooltipShowDelay="300"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="(hoverBloc.hover$ | async) && (node | map: isPathway)">
    <ng-container *luIsAdmin>
      <div class="actions absolute right-0 flex bg-white">
        <lu-favourite-indicator [item]="node.pathway" />
        <button
          [routerLink]="['/admin/pathways', node.ref.id]"
          mat-icon-button
          matTooltip="Edit Pathway"
          matTooltipShowDelay="300"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
