import { type ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  Input,
  ViewChild,
  ViewContainerRef,
  type OnDestroy,
} from '@angular/core';
import {
  InlineNodes,
  NodeGroup,
  type IEditorMentionAttributes,
} from '@principle-theorem/editor';
import {
  EditorNode,
  EditorNodeComponent,
  NodeAttribute,
  type IConfigurable,
  type IDomParsing,
  type IDomSerialising,
} from '@principle-theorem/ng-prosemirror';
import { getProviderResult, type IProvider } from '@principle-theorem/shared';
import {
  type DOMOutputSpec,
  type ParseRule,
  type Node as ProsemirrorNode,
} from '@tiptap/pm/model';
import { get } from 'lodash';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MENTION_KEYMAP } from '../mention-keymap';

export type IMentionRenderComponent = IEditorMentionAttributes;

export interface IMentionNodeConfig {
  providers: IProvider<
    IEditorMentionAttributes,
    ComponentType<IMentionRenderComponent>
  >[];
}

@EditorNode({
  name: InlineNodes.Mention,
  group: NodeGroup.Inline,
  inline: true,
  defining: true,
  atom: true,
  selectable: true,
  isolating: true,
})
@Component({
    selector: 'pt-mention-node',
    templateUrl: './mention-node.component.html',
    styleUrls: ['./mention-node.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MentionNodeComponent
  extends EditorNodeComponent<IEditorMentionAttributes>
  implements
    OnDestroy,
    IDomParsing,
    IDomSerialising,
    IEditorMentionAttributes,
    IConfigurable<IMentionNodeConfig>
{
  private _containerRef$: ReplaySubject<ViewContainerRef> = new ReplaySubject(
    1
  );
  private _config$: ReplaySubject<IMentionNodeConfig> = new ReplaySubject(1);
  private _onDestroy$: Subject<void> = new Subject();
  mentionSymbol: string = MENTION_KEYMAP;
  @NodeAttribute() @Input() key: string;
  @NodeAttribute() @Input() path: string;
  @NodeAttribute() @Input() type: string;

  constructor(
    elementRef: ElementRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector
  ) {
    super(elementRef);
    combineLatest([this._containerRef$, this._config$])
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(([containerRef, config]) =>
        this._renderMenu(containerRef, config)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @ViewChild('containerRef', { static: true, read: ViewContainerRef })
  set containerRef(containerRef: ViewContainerRef) {
    this._containerRef$.next(containerRef);
  }

  configure(config: IMentionNodeConfig): void {
    this._config$.next(config);
  }

  override parseHTML(): ParseRule[] {
    return [
      {
        tag: 'span[role="mention"]',
        getAttrs: (dom) => {
          if (!(dom instanceof HTMLElement)) {
            return false;
          }

          return {
            key: dom.getAttribute('key'),
            path: dom.getAttribute('path'),
            type: dom.getAttribute('type'),
          };
        },
      },
    ];
  }

  renderHTML(data: { node: ProsemirrorNode }): DOMOutputSpec {
    return ['span', { ...data.node.attrs, role: 'mention' }];
  }

  toText(node: ProsemirrorNode): string {
    return String(get(node.attrs, 'key'));
  }

  private _renderMenu(
    containerRef: ViewContainerRef,
    config: IMentionNodeConfig
  ): void {
    const data: IEditorMentionAttributes = {
      key: this.key,
      path: this.path,
      type: this.type,
    };

    if (this.path.includes('managementStaff')) {
      return;
    }

    const mentionProviderComponent = getProviderResult(data, config.providers);
    if (!mentionProviderComponent) {
      return;
    }

    const componentRef = containerRef.createComponent(
      this._componentFactoryResolver.resolveComponentFactory(
        mentionProviderComponent
      ),
      undefined,
      this._injector
    );

    componentRef.instance.type = this.type;
    componentRef.instance.key = this.key;
    componentRef.instance.path = this.path;

    componentRef.injector.get(ChangeDetectorRef).markForCheck();
  }
}
