import { Injectable, type OnDestroy } from '@angular/core';
import {
  ITypesensePathway,
  ITypesenseSkill,
  MentionResourceType,
  type IPathway,
  type IRecentlyViewed,
  type ISkill,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  asDocRef,
  filterUndefined,
  shareReplayCold,
  toMoment,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isString } from 'lodash';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CachedListsService } from '../../services/cached-lists.service';
import { LoadPathwayActionService } from '../../services/contextual-actions/routing-actions/load-pathway-action.service';
import { LoadSkillActionService } from '../../services/contextual-actions/routing-actions/load-skill-action.service';
import { LoadUserActionService } from '../../services/contextual-actions/routing-actions/load-user-action.service';
import {
  buildPathwayOptions,
  buildSkillOptions,
} from '../../services/editor-presets.service';
import { LastViewedService } from '../../services/last-viewed.service';
import { OrganisationService } from '../../services/organisation.service';
import { TypesenseSearchService } from '../../typesense/typesense-search.service';
import { CreateSkillActionService } from './create-skill-action.service';
import { PathwayLoader } from './pathway-loader';
import { type SearchOptionGroup } from './search.component';
import { SkillLoader } from './skill-loader';
import { UserLoader } from './user-loader';

interface ISearchResults {
  skills: ITypesenseSkill[];
  pathways: ITypesensePathway[];
}

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  search$ = new BehaviorSubject<string>('');
  loading$ = new BehaviorSubject<boolean>(false);
  groups$: Observable<SearchOptionGroup[]>;
  baseGroups: SearchOptionGroup[];

  constructor(
    private _organisation: OrganisationService,
    private _createSkillActionService: CreateSkillActionService,
    private _loadSkillActionService: LoadSkillActionService,
    private _loadPathwayActionService: LoadPathwayActionService,
    private _loadUserActionService: LoadUserActionService,
    private _lastViewed: LastViewedService,
    private _search: TypesenseSearchService,
    private _cachedLists: CachedListsService
  ) {
    this.baseGroups = [
      {
        name: 'Actions',
        options: [this._createSkillActionService],
        skipFilter: true,
      },
    ];

    const results$ = combineLatest([
      this._organisation.user$.pipe(filterUndefined()),
      this._organisation.associatedGroups$,
      this._search
        .query$(
          this.search$.pipe(
            filter((value) => isString(value)),
            startWith('')
          ),
          of([]),
          of([])
        )
        .pipe(startWith([])),
    ]).pipe(
      map(([user, groups, results]) => {
        const skills = buildSkillOptions(results, user, groups);
        const pathways = buildPathwayOptions(results, user, groups);

        return {
          skills,
          pathways,
        };
      })
    );
    this.groups$ = this.loadAsyncOptions$(results$);
  }

  setValue(searchControl: string): void {
    this.search$.next(searchControl);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  loadAsyncOptions$(
    results$: Observable<ISearchResults>
  ): Observable<SearchOptionGroup[]> {
    return combineLatest([
      results$.pipe(map((results) => results.skills)),
      results$.pipe(map((results) => results.pathways)),
      this._organisation.enabledUsers$,
      this._organisation.user$.pipe(map((user) => user?.isAdmin)),
      this._lastViewed.recentlyViewed$,
    ]).pipe(
      map(([skills, pathways, users, isAdmin, recentlyViewed]) =>
        compact([
          ...this.baseGroups,
          this.mockRecentlyViewedWithService(recentlyViewed),
          this.mockPathwayWithService(pathways),
          this.mockSkillWithService(skills),
          isAdmin ? this.mockUsersWithService(users) : undefined,
        ])
      ),
      shareReplayCold()
    );
  }

  mockRecentlyViewedWithService(
    recentlyViewed: IRecentlyViewed[]
  ): SearchOptionGroup {
    const options = compact(
      recentlyViewed.map((recentItem) => {
        if (recentItem.type === MentionResourceType.Skill) {
          const loader = new SkillLoader(
            {
              name: recentItem.title,
              ref: recentItem.ref as DocumentReference<ISkill>,
            },
            this._loadSkillActionService
          );
          loader.details = toMoment(recentItem.viewedAt).from(moment());
          return loader;
        }

        if (recentItem.type === MentionResourceType.Pathway) {
          const loader = new PathwayLoader(
            {
              name: recentItem.title,
              ref: recentItem.ref as DocumentReference<IPathway>,
            },
            this._loadPathwayActionService,
            this._cachedLists
          );
          loader.details = toMoment(recentItem.viewedAt).from(moment());
          return loader;
        }
      })
    );

    return {
      name: 'Recently Viewed',
      options,
      skipFilter: false,
    };
  }

  mockSkillWithService(skills: ITypesenseSkill[]): SearchOptionGroup {
    const options = skills.map(
      (skill) =>
        new SkillLoader(
          { ...skill, ref: asDocRef<ISkill>(skill.ref) },
          this._loadSkillActionService
        )
    );

    return {
      name: 'Skills',
      options,
      skipFilter: true,
    };
  }

  mockPathwayWithService(pathways: ITypesensePathway[]): SearchOptionGroup {
    const options = pathways.map(
      (pathway) =>
        new PathwayLoader(
          { ...pathway, ref: asDocRef<IPathway>(pathway.ref) },
          this._loadPathwayActionService,
          this._cachedLists
        )
    );

    return {
      name: 'Pathways',
      options,
      skipFilter: true,
    };
  }

  mockUsersWithService(users: WithRef<IUser>[]): SearchOptionGroup {
    const options = users.map(
      (user) => new UserLoader(user, this._loadUserActionService)
    );

    return {
      name: 'Users',
      options,
      skipFilter: false,
    };
  }
}
