import { type DocumentReference } from '@principle-theorem/shared';
import {
  asyncForEach,
  type ObjectOfType,
  reduceToSingleArray,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { mapValues, uniqBy } from 'lodash';
import {
  type IOrganisation,
  Organisation,
} from '../../organisation/organisation';
import { type ISkill, type ISkillLevelRequest, Skill } from '../../skill/skill';
import { type ISkillProgress } from '../../skill/skill-progress';
import { type ISkillReview } from '../../skill/skill-review';
import { type IGoals } from '../../user-group/user-group';
import { type IUser } from '../user';
import {
  type IUserStatisticsResult,
  type IUserStatValue,
  reduceStatResults,
  statValue,
  statValueToResult,
} from './user-statistics-result';

export function boolToNum(value: boolean): number {
  return value ? 1 : 0;
}

type IStatBuilderResolver<T> = (builder: T) => IUserStatValue[];

export function resolverBuilders<T>(
  builders: ObjectOfType<T>,
  resolve: IStatBuilderResolver<T>
): IUserStatisticsResult[] {
  const resultsObj = mapValues(builders, (builder, key) => {
    const resolved = resolve(builder).map((stat) =>
      statValueToResult(key, stat)
    );
    return reduceStatResults(resolved);
  });
  const values = Object.values(resultsObj);
  return reduceStatResults(reduceToSingleArray(values));
}

export interface ISkillPair {
  skill: WithRef<ISkill>;
  levelUpRequests: ISkillLevelRequest[];
  reviews: ISkillReview[];
}

export interface IUserProgressPair {
  user: WithRef<IUser>;
  progress: ISkillProgress;
}

export interface IUserGoalsPair {
  user: WithRef<IUser>;
  goals: IGoals;
}

export function modifyUserStats(
  aList: IUserStatValue[],
  bList: IUserStatValue[],
  modifierFn: (a: number, b: number) => number
): IUserStatValue[] {
  const owners = uniqBy(
    [...aList, ...bList].map((stat) => stat.userRef),
    (owner) => owner.path
  );
  return owners.map((owner) => {
    const aItem = aList.find((item) => item.userRef.path === owner.path);
    const bItem = bList.find((item) => item.userRef.path === owner.path);
    const value = modifierFn(aItem ? aItem.value : 0, bItem ? bItem.value : 0);
    return statValue(owner, value);
  });
}

export async function getSkillPairs(
  ref: DocumentReference<IOrganisation>
): Promise<ISkillPair[]> {
  const skills = await snapshot(Organisation.skills$({ ref }));
  return asyncForEach(skills, getSkillPair);
}

export async function getSkillPair(
  skill: WithRef<ISkill>
): Promise<ISkillPair> {
  const reviews = await Skill.reviews(skill);
  const levelUpRequests = await snapshot(Skill.trainingRequests$(skill));
  return { skill, levelUpRequests, reviews };
}
