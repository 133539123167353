import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'lu-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    styleUrls: ['./page-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageWrapperComponent {
  @Input() width: string | number = 800;
  get widthInPixels(): string {
    return typeof this.width === 'string' ? this.width : `${this.width}px`;
  }
}
