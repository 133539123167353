<mat-toolbar color="primary">Create Team</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="layout-margin" fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name"
        ptIntercomTarget="add-group-form.name"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea
        matInput
        ptIntercomTarget="add-group-form.description"
        formControlName="description"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-stroked-button
      [disabled]="!canSave()"
      ptIntercomTarget="add-group-form.submit"
    >
      Create
    </button>
  </div>
</form>
