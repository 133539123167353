<mat-list-item *ngIf="skill$ | async as skill">
  <span matListItemTitle>
    <a [routerLink]="['/explore/skill', skill.ref.id]">
      {{ skill.name }}
    </a>
  </span>

  <div matListItemMeta>
    <div class="flex flex-row items-center">
      <div class="flex items-center" *ngIf="showRelativeDueDate$ | async">
        <lu-level-selector
          [requiresTrainerVerification]="skill.requiresTrainerVerification"
          [goal]="currentLevel$ | async"
          (levelChange)="levelChange.emit($event)"
        />

        <lu-user-group-goal-due-date-indicator
          showRelativeDueDate
          [canEdit]="true"
          [dueDate]="dueDateBloc.relativeDueDate$ | async"
          (dateChange)="updateRelativeDueDate($event)"
        />
      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <ng-container *ngIf="showDueDate$ | async">
          <span class="divider"></span>
          <lu-user-goal-due-date-indicator
            canEdit
            [dueDate]="dueDateBloc.dueDate$ | async"
            (dateChange)="updateDueDate($event)"
          />
        </ng-container>

        <button
          *ngIf="removeEnabled"
          mat-icon-button
          class="remove-button"
          matTooltip="Remove Skill"
          color="warn"
          (click)="removeSkill.emit(skill.ref)"
        >
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-list-item>
