import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'lu-no-goals-state',
    templateUrl: './no-goals-state.component.html',
    styleUrls: ['./no-goals-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NoGoalsStateComponent {}
