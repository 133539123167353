import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  type IVendorBundle,
  BundleVisibility,
} from '@principle-theorem/level-up-core';
import { ReplaySubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lu-vendor-bundle-icons',
  templateUrl: './vendor-bundle-icons.component.html',
  styleUrls: ['./vendor-bundle-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorBundleIconsComponent {
  bundle$: ReplaySubject<IVendorBundle> = new ReplaySubject(1);
  isPublic$: Observable<boolean>;

  constructor() {
    this.isPublic$ = this.bundle$.pipe(
      map((bundle) => bundle.visibility === BundleVisibility.Public)
    );
  }

  @Input()
  set bundle(bundle: IVendorBundle) {
    if (bundle) {
      this.bundle$.next(bundle);
    }
  }
}
