<ng-container *ngIf="user$ | async as user">
  <div
    fxLayout="column"
    fxLayoutGap="16px"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center"
  >
    <h2
      class="mat-headline-6"
      ptIntercomTarget="group.header-user-goal-progress"
    >
      User Goal Progress
    </h2>

    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
      fxLayout.gt-xs="row"
    >
      <button
        mat-stroked-button
        ptIntercomTarget="group.add-goal-button"
        (click)="addGoals()"
      >
        <mat-icon>add</mat-icon>
        Update Goals
      </button>
    </div>
  </div>

  <lu-user-pathway-and-skills-associate
    *ngIf="bloc.combinedGoals$ | async as goals"
    ptIntercomTarget="user.progress"
    [user]="user"
    [pathways]="goals.pathwayAssociations"
    [skills]="goals.skillAssociations"
    [skillLevels]="goals.levelRequirements"
    (pathwayRemove)="removePathway($event)"
    (skillRemove)="removeSkill($event)"
    (skillGoalChange)="updateGoalLevel($event)"
    (pathwayLevelsChange)="bloc.updatePathwayLevels($event)"
    (skillLevelsChange)="bloc.updateSkillLevels($event)"
  />
</ng-container>
