import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IVendor, Vendor } from '@principle-theorem/level-up-core';
import {
  doc$,
  filterUndefined,
  findProp,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'lu-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorProfileComponent {
  vendor$: Observable<WithRef<IVendor>>;
  storagePath$: Observable<string>;

  constructor(private _route: ActivatedRoute) {
    this.vendor$ = this._route.data.pipe(
      findProp<WithRef<IVendor>>('vendor'),
      filterUndefined(),
      switchMap((vendor) => doc$(vendor.ref)),
      filterUndefined(),
      shareReplayCold()
    );

    this.storagePath$ = this.vendor$.pipe(
      map((vendor) => Vendor.storagePath(vendor.ref)),
      shareReplayCold()
    );
  }
}
