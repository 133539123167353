import { reduceToSingleArray } from '@principle-theorem/shared';
import { first, groupBy } from 'lodash';
import {
  type IPathwaySkillPair,
  pathwayComplete,
} from '../../pathway/pathway-operators';
import { type IUserGroup } from '../../user-group/user-group';
import { User } from '../user';
import { boolToNum, type IUserProgressPair, resolverBuilders } from './helpers';
import {
  type IUserStatisticsResult,
  type IUserStatValue,
  statValue,
} from './user-statistics-result';

type IBuilderFn = (
  pathwayPair: IPathwaySkillPair,
  progressPairs: IUserProgressPair[],
  userGroups: IUserGroup[]
) => IUserStatValue[];

export function skillProgressPathwayResults(
  pathwayPair: IPathwaySkillPair,
  progressPairs: IUserProgressPair[],
  userGroups: IUserGroup[]
): IUserStatisticsResult[] {
  return resolverBuilders<IBuilderFn>(
    {
      pathwaysComplete,
    },
    (builder: IBuilderFn) => builder(pathwayPair, progressPairs, userGroups)
  );
}

function pathwaysComplete(
  pathwayPair: IPathwaySkillPair,
  progressPairs: IUserProgressPair[],
  userGroups: IUserGroup[]
): IUserStatValue[] {
  const pairStats = groupPairsByUser(progressPairs).map((pairs) => {
    const firstPair = first(pairs);
    if (!firstPair) {
      return [];
    }
    const goals = User.getGoals(firstPair.user, userGroups);
    const isComplete = pathwayComplete(
      pathwayPair,
      goals,
      progressPairs.map((progressPair) => progressPair.progress)
    );
    return [statValue(firstPair.user.ref, boolToNum(isComplete))];
  });
  return reduceToSingleArray(pairStats);
}

function groupPairsByUser(
  progressPairs: IUserProgressPair[]
): IUserProgressPair[][] {
  return Object.values(groupBy(progressPairs, (pair) => pair.user.ref.path));
}
