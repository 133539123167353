import {
  isDocRef,
  isObject,
  isSameRef,
  type DocumentReference,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IBundleSkill } from '../marketplace/bundle';
import { type ISkill } from './skill';

export type SkillType = ISkill | IBundleSkill;

export interface ISkillRef<T = SkillType> {
  type: SkillRefType;
  ref: DocumentReference<T>;
}

export enum SkillRefType {
  BundleSkill = 'bundleSkill',
  Skill = 'skill',
}

export function isSkillRef(data: unknown): data is ISkillRef {
  return isObject(data) && isString(data.type) && isDocRef(data.ref);
}

export function isBundleSkillRef(
  skillRef: ISkillRef
): skillRef is ISkillRef<IBundleSkill> {
  return skillRef.type === SkillRefType.BundleSkill;
}

export function isBaseSkillRef(
  skillRef: ISkillRef
): skillRef is ISkillRef<ISkill> {
  return skillRef.type === SkillRefType.Skill;
}

export function isSameSkillRef(
  skillRef1: ISkillRef,
  skillRef2: ISkillRef
): boolean {
  return isSameRef(skillRef1, skillRef2) && skillRef1.type === skillRef2.type;
}
