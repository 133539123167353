import { Inject, Injectable } from '@angular/core';
import {
  type ISkillRef,
  type ITag,
  type ITypesensePathway,
  type ITypesenseSkill,
  type ITypesenseVendorSkill,
  type SkillType,
} from '@principle-theorem/level-up-core';
import { TypesenseSearchService as SharedTypesenseSearchService } from '@principle-theorem/ng-shared';
import {
  asDocRef,
  asyncForEach,
  filterUndefined,
  getDoc,
  multiMap,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../services/organisation.service';
import { TypesensePathwayListBloc } from './typesense-pathway-list-bloc';
import { TypesenseSkillListBloc } from './typesense-skill-list-bloc';
import { VendorSkillListBloc } from './vendor-skill-list-bloc';
import { INgLevelUpConfig, NG_LEVEL_UP_CONFIG } from '../ng-level-up-config';

@Injectable({
  providedIn: 'root',
})
export class TypesenseSearchService {
  results$: Observable<
    (ITypesensePathway | ITypesenseSkill | ITypesenseVendorSkill)[]
  >;

  constructor(
    private _typesense: SharedTypesenseSearchService,
    private _organisation: OrganisationService,
    @Inject(NG_LEVEL_UP_CONFIG) private _config: INgLevelUpConfig
  ) {}

  query$(
    search$: Observable<string>,
    selectedTagFilters$: Observable<WithRef<ITag>[]>,
    filterSkills$: Observable<ISkillRef<SkillType>[]>
  ): Observable<
    (ITypesensePathway | ITypesenseSkill | ITypesenseVendorSkill)[]
  > {
    const organisation$ = this._organisation.organisation$.pipe(
      filterUndefined()
    );
    const pathwayBloc = new TypesensePathwayListBloc(
      this._typesense,
      this._config.typesense,
      organisation$,
      search$
    );
    const skillBloc = new TypesenseSkillListBloc(
      this._typesense,
      this._config.typesense,
      organisation$,
      search$,
      selectedTagFilters$,
      filterSkills$
    );
    const vendorSkillBloc = new VendorSkillListBloc(
      this._typesense,
      this._config.typesense,
      organisation$,
      search$,
      filterSkills$
    );

    return combineLatest([
      pathwayBloc.results$,
      skillBloc.results$,
      vendorSkillBloc.results$,
    ]).pipe(
      map(([pathwayResponse, skillResponse, vendorSkillResponse]) => [
        ...(pathwayResponse.hits ?? []),
        ...(skillResponse.hits ?? []),
        ...(vendorSkillResponse.hits ?? []),
      ]),
      multiMap((skill) => skill.document)
    );
  }

  async resolveDocs<T extends object>(
    results: (ITypesensePathway | ITypesenseSkill | ITypesenseVendorSkill)[]
  ): Promise<WithRef<T>[]> {
    return asyncForEach(results, (result) => getDoc(asDocRef<T>(result.ref)));
  }
}
