import {
  User,
  type ISkill,
  type ISkillProgress,
  type IUser,
} from '@principle-theorem/level-up-core';
import {
  find$,
  multiSwitchMap,
  where,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  type MonoTypeOperatorFunction,
  type Observable,
  type OperatorFunction,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export function findUserSkillProgress(
  skill: WithRef<ISkill>
): OperatorFunction<WithRef<IUser>, WithRef<ISkillProgress> | undefined> {
  return (user$: Observable<WithRef<IUser>>) =>
    user$.pipe(
      switchMap((user) =>
        find$(
          User.skillProgressCol(user),
          where('skillRef.ref', '==', skill.ref)
        )
      )
    );
}

export function findTrainerUsersForSkill$(
  skill: WithRef<ISkill>
): MonoTypeOperatorFunction<WithRef<IUser>[]> {
  return (users$: Observable<WithRef<IUser>[]>) =>
    users$.pipe(
      multiSwitchMap(async (user) => {
        const isTrainer = await User.isTrainerForSkill(user, skill);
        return isTrainer ? user : undefined;
      }),
      map(compact)
    );
}
