import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import {
  BasicMenuButtonComponent,
  cleanUpBlockCommand,
} from '../../../../menu-bar/basic-menu-button/basic-menu-button.component';
import {
  AITextPromptDialogComponent,
  IAITextPromptData,
  IAITextPromptOutput,
} from '../ai-text-prompt-dialog/ai-text-prompt-dialog.component';

@Component({
    selector: 'pt-ai-text-prompt-menu',
    templateUrl: './ai-text-prompt-menu.component.html',
    styleUrls: ['./ai-text-prompt-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AITextPromptMenuComponent extends BasicMenuButtonComponent {
  override tooltip = 'Run your custom prompt';

  constructor(private _dialog: BasicDialogService) {
    super();
  }

  async showPromptDialog(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const { from, to } = editor.view.state.selection;
    const initialPrompt = editor.state.doc.textBetween(from, to, '\n');
    const prompt = await this._dialog
      .open<
        AITextPromptDialogComponent,
        IAITextPromptData,
        IAITextPromptOutput
      >(AITextPromptDialogComponent, this._getDialogConfig(initialPrompt))
      .afterClosed()
      .toPromise();

    if (prompt) {
      editor
        .chain()
        .focus()
        .aiTextPrompt({
          text: prompt.prompt,
        })
        .focus()
        .run();
    }
  }

  override async runCommand(): Promise<void> {
    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    await this.showPromptDialog();
    editor.view.focus();
  }

  private _getDialogConfig(
    initialPrompt?: string
  ): MatDialogConfig<IAITextPromptData> {
    return DialogPresets.medium({
      restoreFocus: false,
      hasBackdrop: true,
      autoFocus: true,
      data: {
        initialPrompt,
      },
    });
  }
}
