<lu-card-container
  class="grow"
  *ngIf="pathway$ | async as pathway"
  [title]="pathway.name"
  [author]="author$ | async"
  [link]="link"
>
  <lu-card-image
    [image]="pathway.imageUrl"
    [placeholderImage]="pathwayCardPlaceholderImage"
  />
  <lu-card-actions />
</lu-card-container>
