import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { ImageSize, getImageAtSize } from '@principle-theorem/ng-shared';
import { shareReplayCold, type ObjectOfType } from '@principle-theorem/shared';
import { from, of, type Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface IWithImageUrl {
  imageUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CardImageService {
  private _imageCache: ObjectOfType<Observable<string>> = {};

  constructor(private _storage: Storage) {}

  getCardImage$(item: IWithImageUrl): Observable<string | undefined> {
    if (!item.imageUrl) {
      return of(undefined);
    }

    if (this._imageCache[item.imageUrl]) {
      return this._imageCache[item.imageUrl];
    }

    this._imageCache[item.imageUrl] = from(
      getDownloadURL(
        ref(this._storage, getImageAtSize(item.imageUrl, ImageSize.Card))
      )
    ).pipe(
      catchError(() => getDownloadURL(ref(this._storage, item.imageUrl))),
      shareReplayCold()
    );

    return this._imageCache[item.imageUrl];
  }
}
