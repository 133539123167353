import { Injectable, inject } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { type IUserSettings } from '@principle-theorem/level-up-core';
import { filterUndefined, patchDoc } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  auditTime,
  concatMap,
  map,
  skip,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

export interface IUserSettingsState extends IUserSettings {
  notifications: Required<IUserSettings['notifications']>;
  sidebar: Required<IUserSettings['sidebar']>;
}

@Injectable({
  providedIn: 'root',
})
export class UserSettingsStoreService extends ComponentStore<IUserSettingsState> {
  private _organisation = inject(OrganisationService);
  readonly notifications$ = this.select((state) => state.notifications);
  readonly sidebar$ = this.select((state) => state.sidebar);

  readonly loadUserSettings = this.effect(
    (settings$: Observable<Partial<IUserSettings>>) =>
      settings$.pipe(
        filterUndefined(),
        tapResponse(
          (settings) =>
            this.patchState((state) =>
              this._deepMergeSettings(state, settings)
            ),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  readonly updateUserSettings = this.effect(
    (settings$: Observable<Partial<IUserSettings>>) =>
      settings$.pipe(
        tapResponse(
          (settings) =>
            this.patchState((state) =>
              this._deepMergeSettings(state, settings)
            ),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  readonly saveUserSettings = this.effect(
    (settings$: Observable<Partial<IUserSettings>>) =>
      settings$.pipe(
        auditTime(1000),
        withLatestFrom(this._organisation.user$.pipe(filterUndefined())),
        concatMap(([settings, user]) =>
          patchDoc(user.ref, {
            settings,
          })
        )
      )
  );

  constructor() {
    super({
      notifications: {
        disabled: [],
      },
      sidebar: {
        selectedTab: 'folders',
        folderState: [],
      },
    });

    this.loadUserSettings(
      this._organisation.user$.pipe(
        map((user) => user?.settings),
        filterUndefined(),
        take(1)
      )
    );

    this.saveUserSettings(this.state$.pipe(skip(1)));
  }

  private _deepMergeSettings(
    state: IUserSettingsState,
    settings: Partial<IUserSettings>
  ): Partial<IUserSettingsState> {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        ...settings.notifications,
      },
      sidebar: {
        ...state.sidebar,
        ...settings.sidebar,
      },
    };
  }
}
