import {
  NgModule,
  Optional,
  SkipSelf,
  type ModuleWithProviders,
} from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AuthModule } from './auth/auth.module';
import { ComponentsModule } from './components/components.module';
import { LayoutModule } from './layout/layout.module';
import { LevelUpRoutingModule } from './level-up-routing.module';
import {
  NG_LEVEL_UP_CONFIG,
  type INgLevelUpConfig,
} from './ng-level-up-config';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { ManagementModule } from './pages/management/management.module';
import { NgAuthModule } from '@principle-theorem/ng-auth';
import { EditorPresetsService } from './services/editor-presets.service';
import { LoadSkillActionService } from './services/contextual-actions/routing-actions/load-skill-action.service';
import { LoadUserActionService } from './services/contextual-actions/routing-actions/load-user-action.service';
import { VendorService } from './services/vendor.service';
import { LoadPathwayActionService } from './services/contextual-actions/routing-actions/load-pathway-action.service';

@NgModule({
  imports: [
    NgSharedModule,
    NgFirebaseModule,
    LevelUpRoutingModule,
    AuthModule,
    ManagementModule,
    LayoutModule,
    ComponentsModule,
    NgEditorModule,
    NgAuthModule,
  ],
  providers: [
    EditorPresetsService,
    LoadSkillActionService,
    LoadPathwayActionService,
    LoadUserActionService,
    VendorService,
  ],
})
export class NgLevelUpModule {
  constructor(@Optional() @SkipSelf() parentModule: NgLevelUpModule) {
    if (parentModule) {
      throw new Error(
        'NgLevelUpModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(
    config: INgLevelUpConfig
  ): ModuleWithProviders<NgLevelUpModule> {
    return {
      ngModule: NgLevelUpModule,
      providers: [
        {
          provide: NG_LEVEL_UP_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
