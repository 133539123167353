import type { Buffer, Cell, Column, Row } from 'exceljs';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { mapValues } from 'lodash';
import { asyncForEach } from './helpers';
import { type AtLeast } from './utility-types';

export type XSLXCell = AtLeast<Pick<Cell, 'dataValidation' | 'value'>, 'value'>;

export interface IXSLXExport<RecordFormat, RowFormat> {
  headers: AtLeast<Column, 'key' | 'header'>[];
  translate(
    records: RecordFormat[]
  ):
    | Promise<Record<keyof RowFormat, XSLXCell>[]>
    | Record<keyof RowFormat, XSLXCell>[];
}

export interface IWorkbookSheet<RecordFormat, RowFormat> {
  name: string;
  records: RecordFormat[];
  translator: IXSLXExport<RecordFormat, RowFormat>;
}

export interface IXSLXImport<RecordFormat> {
  headers: AtLeast<Column, 'key' | 'header'>[];
  translate(row: Row): Promise<RecordFormat> | RecordFormat;
}

export interface IBlobFilenamePair {
  fileName: string;
  blob: Blob;
}

export class XSLXImporterExporter {
  worksheetName = 'Sheet 1';
  mimeType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  async getBlob<RecordFormat, RowFormat>(
    fileName: string,
    records: RecordFormat[],
    translator: IXSLXExport<RecordFormat, RowFormat>
  ): Promise<IBlobFilenamePair> {
    const workbook = await this.getWorkbook([
      { name: this.worksheetName, records, translator },
    ]);
    const buffer = await workbook.xlsx.writeBuffer();
    return {
      fileName: decodeURI(`${fileName}.xlsx`),
      blob: this._build(buffer),
    };
  }

  async download<RecordFormat, RowFormat>(
    fileName: string,
    records: RecordFormat[],
    translator: IXSLXExport<RecordFormat, RowFormat>
  ): Promise<void> {
    const { blob } = await this.getBlob(fileName, records, translator);
    this._save(blob, fileName);
  }

  async getWorkbook(
    sheets: IWorkbookSheet<unknown, unknown>[]
  ): Promise<Workbook> {
    const workbook = new Workbook();

    await asyncForEach(sheets, async (sheetDef) => {
      const sheet = workbook.addWorksheet(sheetDef.name);
      sheet.columns = sheetDef.translator.headers;

      const rows = await sheetDef.translator.translate(sheetDef.records);

      rows.map((cells: Record<string, XSLXCell>) => {
        const row = sheet.addRow(mapValues(cells, (cell) => cell.value));

        mapValues(cells, (cell, key) => {
          if (cell.dataValidation) {
            row.getCell(key).dataValidation = cell.dataValidation;
          }
        });
      });
    });

    return workbook;
  }

  async parse<RecordFormat>(
    file: Blob,
    translator: IXSLXImport<RecordFormat>
  ): Promise<RecordFormat[]> {
    const workbook = new Workbook();
    await workbook.xlsx.load(await file.arrayBuffer());
    const sheet = workbook.getWorksheet(this.worksheetName);
    sheet.columns = translator.headers;

    const rows: Row[] = [];

    sheet.eachRow((row) => {
      rows.push(row);
    });

    return asyncForEach(rows, async (row) => translator.translate(row));
  }

  private _build(buffer: Buffer): Blob {
    return new Blob([buffer], {
      type: this.mimeType,
    });
  }

  private _save(fileBlob: Blob, fileName: string): void {
    saveAs(fileBlob, decodeURI(`${fileName}.xlsx`));
  }
}
