import {
  ISkill,
  MentionResourceType,
  Pathway,
  type IPathway,
  type ITag,
} from '@principle-theorem/level-up-core';
import {
  WithRef,
  filterUndefined,
  multiSwitchMap,
  type AtLeast,
  type DocumentReference,
  type IReffable,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CachedListsService } from '../../services/cached-lists.service';
import { LoadPathwayActionService } from '../../services/contextual-actions/routing-actions/load-pathway-action.service';
import { type ISearchAction } from './search-action';

export class PathwayLoader<
  T extends IReffable<IPathway> &
    AtLeast<Pick<IPathway, 'name' | 'tags'>, 'name'>
> implements ISearchAction
{
  icon = 'fact_check';
  name: string;
  details: string;
  type = MentionResourceType.Pathway;
  ref: DocumentReference<IPathway>;
  tags?: DocumentReference<ITag>[];

  constructor(
    public pathway: T,
    public loader: LoadPathwayActionService,
    private _cachedLists: CachedListsService
  ) {
    this.name = pathway.name;
    this.tags = pathway.tags;
    this.ref = pathway.ref;
    this.details = '';
  }

  steps$(): Observable<WithRef<ISkill>[]> {
    const pathwaySkills$ = this._cachedLists.getPathway$(this.pathway.ref).pipe(
      filterUndefined(),
      map((pathway) => Pathway.skillRefs(pathway))
    );

    return pathwaySkills$.pipe(
      multiSwitchMap((pathwaysSkill) =>
        this._cachedLists.getLocalSkill$(pathwaysSkill)
      ),
      map(compact)
    );
  }

  async do(): Promise<void> {
    await this.loader.do(this.pathway.ref);
  }

  url(): string {
    return this.loader.url(this.pathway.ref);
  }
}
