import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { type IPageTemplate } from '@principle-theorem/level-up-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
  selector: 'lu-page-template-selector',
  templateUrl: './page-template-selector.component.html',
  styleUrls: ['./page-template-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTemplateSelectorComponent {
  trackByTemplate = TrackByFunctions.ref<WithRef<IPageTemplate>>();
  pageTemplates$: Observable<WithRef<IPageTemplate>[]>;
  @Output() templateSelected: EventEmitter<WithRef<IPageTemplate> | undefined> =
    new EventEmitter<WithRef<IPageTemplate> | undefined>();

  constructor(private _organisation: OrganisationService) {
    this.pageTemplates$ = this._organisation.pageTemplates$;
  }
}
